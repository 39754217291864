import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../App';

function Countdown(props) {

    const classes = useStyles(theme)();
    const [countdown, setCountdown] = React.useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });
    const [showCountdownFinish, setShowCountdownFinish] = React.useState(false);
    React.useEffect(() => {

        const countDownDate = (props.date && props.date.date) ? moment(props.date.date) : moment(props.date);
        //const countDownDate = moment('2023-04-04 11:07:00.000000');
        const interval = setInterval(() => {
            const now = moment();
            const diff = countDownDate.diff(now);

            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setCountdown({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds
            });

            if (diff <= 0) {
                clearInterval(interval);
                setShowCountdownFinish(true);
                setCountdown({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
                if (props.callbackCountdown) { props.callbackCountdown() }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        !showCountdownFinish ?
            <>
                <p className={classes.intro}>Votre live débutera dans :</p>
                <div className={classes.countdown}>
                    <div className={classes.card}>
                        <div className={classes.value}>{countdown.days}</div>
                        <div className={classes.unit}>Jours</div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.value}>{countdown.hours}</div>
                        <div className={classes.unit}>Heures</div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.value}>{countdown.minutes}</div>
                        <div className={classes.unit}>Minutes</div>
                    </div>
                    <div className={classes.card}>
                        <div className={classes.value}>{countdown.seconds}</div>
                        <div className={classes.unit}>Secondes</div>
                    </div>
                </div>
                <p className={classes.info}>À la suite du compte à rebours, vous pourrez accéder au live.</p>
            </>
            :
            <></>
    );
}

const useStyles = (theme) => makeStyles({
    intro: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#555',
        marginBottom: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    countdown: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: 20,
    },
    card: {
        width: 90,
        height: 90,
        borderRadius: '50%',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 10px',
    },
    value: {
        fontSize: 25,
        fontWeight: 'bold',
        color: theme.colorPrimary,
    },
    unit: {
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.colorPrimary,
        marginTop: 10,
        textTransform: 'uppercase',
    },
    info: {
        fontSize: 14,
        fontWeight: 'normal',
        color: '#555',
        textAlign: 'center',
        marginTop: 20,
    }
});

export default Countdown;
