import React from 'react';
import { Fade, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Grid from "@material-ui/core/Grid";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import CheckboxComponent from '../../../Component/CheckboxComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import { inArray } from '../../../Tool/ArrayCommon';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { Alert, AlertTitle } from '@material-ui/lab';

function InvoiceV2(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [sessionFormation, setSessionFormation] = React.useState();
    const [total, setTotal] = React.useState(0);
    const [countRegistration, setCountRegistration] = React.useState(0);
    const [form, setForm] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: props.sessionFormation.invoiceAt,
            options: { validation: ['required', 'date'] }
        }
    });
    const handler = formHandlerInit(form, setForm);

    const formatTime = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}h${formattedMinutes}`;
    };
    const calcTotal = () => {
        let data = handler.getData();
        let total = 0;
        let countRegistration = 0;
        
        for (let index in data) {
            let split = index.split("_");
            if (split && split[0] === 'registration') {
                total += parseFloat(data[index]);
                countRegistration++;
            }
        }
        
        setTotal((Number(total) === total) ? total.toFixed(2) : 0);
        setCountRegistration(countRegistration);
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (Object.keys(props.sessionFormation).length > 0) {
            setLoading(false);
            setSessionFormation(props.sessionFormation);

            if (props.sessionFormation.units.length > 0) {

                let units = props.sessionFormation.units;
                let registrations = props.sessionFormation.registrations;
                let newForm = {};
                for (let index in units) {
                    newForm['unit_' + units[index].id] = {
                        name: 'unit_' + units[index].id,
                        label: units[index].type,
                        textHelper: '',
                        type: 'boolean',
                        defaultValue: false,
                        value: false,
                        options: {},
                        unit: units[index]
                    };
                }

                for (let key in registrations) {
                    if ((registrations[key].state === 2 || registrations[key].state === 3 || registrations[key].state === 7)) {
                    }
                    else {
                        newForm['registration_' + registrations[key].id] = {
                            name: 'registration_' + registrations[key].id,
                            label: 'Prix',
                            textHelper: '',
                            type: 'float',
                            defaultValue: '',
                            value: '0',
                            options: {},
                            registration: registrations[key]
                        };
                    }
                }
                setForm({ ...form, ...newForm });
            }
        }

    }, [props.sessionFormation]);
    React.useEffect(calcTotal, [handler.getData()]);

    const unitCheckboxChange = (unit, value) => {
        let registrations = sessionFormation.registrations
        let data = handler.getData();
        for (let index in registrations) {
            if ((registrations[index].state === 2 || registrations[index].state === 3 || registrations[index].state === 7)) {
            }
            else {
                
                let log = registrations[index].completionByUnit[unit.orderBy - 1].log;
                let logHour = log / 3600;
                let priceByLog = parseFloat(Math.round(logHour) * unit.price);
                let dataIndex = parseFloat(data['registration_' + registrations[index].id]);
    
                if (value) {
                    let result = dataIndex + priceByLog;
                    handler.setValue('registration_' + registrations[index].id, result.toFixed(2));
                }
                else {
                    let result = dataIndex - priceByLog;
                    handler.setValue('registration_' + registrations[index].id, result.toFixed(2));
                }
            }
        }
    };
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = handler.getData();
            let lines = [];
            let units = [];
            for (let index in data) {
                let split = index.split('_');
                if (split && split[0] === 'unit') {
                    units.push({ 'id': form[index].unit.id, 'type': form[index].unit.type, 'isInvoice': form[index].value });
                }
                if (split && split[0] === 'registration') {
                    lines.push({ 'price': form[index].value, 'id': form[index].registration.id });
                }
            }

            Api.post({
                route: 'financial_management_billing_v2_session_formation_invoice',
                params: { id: props.sessionFormation.id },
                data: {
                    lines: lines,
                    invoiceAt: data.invoiceAt,
                    units: units
                }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        props.reloadList();
                        dispatch(
                            SnackbarOpen({
                                text: response.data.isInvoice ? 'Session formation facturée.' : 'Session formation facturée partielle.',
                                variant: 'success',
                            })
                        );
                        const url = Api.route({
                            route: 'financial_management_billing_v2_invoice_download',
                            params: { id: response.data.id },
                        });
                        window.open(url, '_blank');
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }

    };
    const getUnitCheckbox = (units) => {
        let isSpanRendered = false;
        return (
            <div className={classes.containerCheckbox}>
                {
                    units?.map((unit, i) => {

                        return (
                            <div key={i} style={{ position: 'relative', display: 'inline-flex' }}>
                                {
                                    (unit.chapter && unit.chapter.length > 0 && (unit.durationChapters + unit.durationPrePost) < unit.duration && !isSpanRendered) &&
                                    <span style={{ fontSize: 10, color: '#fd1e1e', position: 'absolute', top: -10, width: 300 }}>
                                        Problème de temps entre les unités et les chapitres
                                    </span>
                                }
                                {unit.chapter && unit.chapter.length > 0 && (unit.durationChapters + unit.durationPrePost) < unit.duration && (isSpanRendered = true)}

                                <CheckboxComponent
                                    name={'unit_' + unit.id}
                                    handler={handler}
                                    direction={'left'}
                                    disabled={(unit.isInvoice || (unit.chapter && unit.chapter.length > 0 && (unit.durationChapters + unit.durationPrePost) < unit.duration))}
                                    onChange={(val) => unitCheckboxChange(unit, val)}
                                />
                                {unit.isInvoice && <span style={{ fontSize: 10, color: '#ad732c', position: 'absolute', bottom: 0 }}>Unité Facturé</span>}
                            </div>
                        );
                    })
                }

            </div>
        )
    };
    const getRenderRegistrations = () => {
        if (sessionFormation && sessionFormation.registrations) {
            let render = [];
            let registrations = sessionFormation.registrations
            for (let index in registrations) {

                if ((registrations[index].state === 2 || registrations[index].state === 3 || registrations[index].state === 7)) {
                }
                else {
                    render.push(
                        <TableRow key={index}>
                            <TableCell className={classes.td}>{registrations[index].id}</TableCell>
                            <TableCell className={classes.td}>{registrations[index].firstname} {registrations[index].lastname}</TableCell>
                            {
                                registrations[index].completionByUnit.map((completion, i) => (
                                    form['unit_' + completion.id].value === true && (
                                        <Fade in={form['unit_' + completion.id].value} key={i}>
                                            <TableCell style={{ color: '#3f51b5' }} className={classes.td}>
                                                {formatTime(completion.log)}
                                                <br />
                                                {(completion.error && completion.error.length > 0 && inArray('Temps manquants', completion.error)) &&
                                                    <span style={{ color: '#dc3545', fontSize: 9 }}>Temps manquants</span>
                                                }
                                            </TableCell>
                                        </Fade>
                                    )
                                ))
                            }
                            <TableCell className={classes.td}>
                                <TextFieldComponent name={'registration_' + registrations[index].id} handler={handler} />
                            </TableCell>

                        </TableRow>
                    );
                }
            }
            return render;
        }
        else {
            return <></>;
        }
    };

    return (
        <>
            <Dialog open={props.open} maxWidth={'xl'}>
                <DialogTitle style={{ fontSize: 15 }}>Facture</DialogTitle>
                <DialogContent>
                    {
                        (sessionFormation && sessionFormation.units && sessionFormation.units.length > 0) ?

                            <div style={{ width: '70vw', maxWidth: 1240 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <DatePickerComponent name={'invoiceAt'} handler={handler} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <p>Choix des unitées à facturer</p>
                                        {sessionFormation.units && getUnitCheckbox(sessionFormation.units)}
                                    </Grid>
                                </Grid>
                                <br />
                                <hr />
                                <br />
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.th}>Référence</TableCell>
                                                <TableCell className={classes.th}>Nom</TableCell>
                                                {sessionFormation.units &&
                                                    sessionFormation.units.map((unit, i) => (
                                                        (form['unit_' + unit.id].value === true) &&
                                                        <Slide
                                                            direction="down"
                                                            in={form['unit_' + unit.id].value}
                                                            key={i}
                                                        >
                                                            <TableCell key={i} className={classes.th}>
                                                                Temps {unit.type}
                                                            </TableCell>
                                                        </Slide>
                                                    ))
                                                }
                                                <TableCell className={classes.th}>Prix</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {getRenderRegistrations()}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <br />
                                <hr />
                                <div style={{ textAlign: 'end' }}>
                                Nombre total participant : <span style={{fontWeight: 600}}>{countRegistration}</span> -- Total : <span style={{fontWeight: 600}}>{total} €</span>
                                </div>
                               
                            </div>

                            :

                            <div style={{ width: '70vw', maxWidth: 1240 }}>
                                <Alert severity="warning">
                                    <AlertTitle>Attention !</AlertTitle>
                                    La formation : <strong>{sessionFormation ? sessionFormation.formationName : '...'}</strong> ne contient pas d'unités. Ce mode de facturation est impossible.
                                </Alert>
                            </div>
                    }
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'} />
                    {(sessionFormation && sessionFormation.units && sessionFormation.units.length > 0) &&
                        <ButtonComponent disabled={loading} onClick={save} label={'Générer la facture'} />
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center'
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center'
    },
    containerCheckbox: {
        display: 'flex',
        justifyContent: 'space-around'
    }
});

export default InvoiceV2;
