import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';

export default function ProspectForm(props = {}) {
    const columnsProspectForms = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'lastname',
            label: 'Nom',
            options: {filter: true, sort: true}
        },
        {
            name: 'firstname',
            label: 'Prénom',
            options: {filter: true, sort: true}
        },
        {
            name: 'ips',
            label: 'IPS',
            options: {filter: true, sort: true}
        },
        {
            name: 'email',
            label: 'Email',
            options: {filter: true, sort: true}
        },
        {
            name: 'phone',
            label: 'Téléphone',
            options: {filter: true, sort: true}
        },
        {
            name: 'formation',
            label: 'Formation',
            options: {filter: true, sort: true}
        },
        {
            name: 'message',
            label: 'Message',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        }
    ];

    return (
        <TableComponent
            noCache={true}
            sortOrder={{name: 'id', direction: 'desc'}}
            id={'crm_prospect_form_list'}
            title={'Formulaires provenant du site internet'}
            columns={columnsProspectForms}
            promiseData={(resolve) => {
                if (props.match.params.id) {
                    Api.get({route: 'crm_prospect_form_list', params: {id: props.match.params.id}}, (response) => {
                        if (response.data) {
                            let data = response.data;
                            for (let index in data) data[index].createdAt = moment(data[index].createdAt).fromNow();
                            resolve(data);
                        }
                        else {
                            resolve([]);
                        }
                    })
                }
                else {
                    resolve([]);
                }
            }}
        />
    )
}
