import React from 'react'
import Api from '../../../Api';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Box, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import EditorComponent from '../../../Component/EditorComponent';
import TemplatesComponent from '../../../Component/DraggableListComponent/TemplatesComponent';
import DraggableListComponent from '../../../Component/DraggableListComponent/DraggableListComponent';

const HandlerDraggableList = () => {
    const [formTemplate1, setFormTemplate1] = React.useState({
        question: {
            name: 'question',
            title: 'Question',
            textHelper: 'Saisissez une question',
            type: 'text',
            typeInput: 'inputText',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const [formTemplate2, setFormTemplate2] = React.useState({
        recommendation: {
            name: 'recommendation',
            title: 'Recommandation',
            textHelper: 'Saisissez une recommandation',
            type: 'text',
            typeInput: 'inputEditor',
            defaultValue: '',
        },
        indication: {
            name: 'indication',
            title: 'Indicateur',
            textHelper: 'Saisissez un indicateur',
            type: 'text',
            typeInput: 'inputText',
            defaultValue: '',
        },
    });
    const handlerTemplate1 = formHandlerInit(formTemplate1, setFormTemplate1);
    const handlerTemplate2 = formHandlerInit(formTemplate2, setFormTemplate2);

    React.useEffect(() => { handlerTemplate1.start(); handlerTemplate2.start() }, []);

    return { handlerTemplate1, handlerTemplate2 }
}

const Templates = ({ item, dragHandleProps }) => {
    const draggableListHandlers = HandlerDraggableList();
    return (
        <TemplatesComponent
            item={item}
            dragHandleProps={dragHandleProps}
            handlerTemplate={{
                template1: {
                    handler: draggableListHandlers.handlerTemplate1,
                },
                template2: {
                    handler: draggableListHandlers.handlerTemplate2,
                }
            }}
            alertMessage={'Êtes-vous sur de vouloir supprimer cette question ?'}
        />
    )
}

function FormationQuestionEpp({ props = {}, setIsOpen }) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const draggableListHandlers = HandlerDraggableList();
    const classes = useStyles(screenReducer)();
    const [listQuestionDeleted, setListQuestionDeleted] = React.useState([]);
    const [listQuestion, setListQuestion] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const [formIntroduction, setFormIntroduction] = React.useState({
        introductionEpp: {
            name: 'introductionEpp',
            label: 'Introduction questionnaire EPP',
            textHelper: 'Saisissez une introduction pour le questionnaire EPP.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handlerIntroduction = formHandlerInit(formIntroduction, setFormIntroduction);
    React.useEffect(handlerIntroduction.start, []);

    const save = (error) => {
        const listDeleted = listQuestionDeleted.filter((value) => !listQuestion.includes(value));
        if (!error) {
            handlerIntroduction.setFormLoading(true);
            setLoading(true)
            Api.post({
                route: 'content_manager_formation_formation_question_epp_action',
                data: {
                    list: listQuestion,
                    listDeleted: listDeleted,
                    introductionEpp: handlerIntroduction.getData().introductionEpp
                },
                params: { formation: props.match.params.id }
            }, (response) => {
                handlerIntroduction.setFormLoading(false);
                if (response.status === 200) {
                    getQuestion()
                    dispatch(
                        SnackbarOpen({
                            text: 'Questionnaire enregistrée.',
                            variant: 'success',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const getQuestion = () => {
        if (props.match.params.id) {
            Api.get({
                route: 'content_manager_formation_formation_question_epp_list',
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    handlerIntroduction.setDataApi(response.data);
                    let dataQuestions = response.data.dataQuestions;
                    setListQuestion(dataQuestions);
                    setListQuestionDeleted(dataQuestions);
                } else {
                    setListQuestion([]);
                }
            })
        }
    }

    React.useEffect(() => getQuestion(), [])
    return (
        <>
            <Box style={{ background: '#FFF', width: screenReducer.screen === 'XL' ? '65%' : '100%', height: '100%', margin: 'auto', display: 'grid', gridTemplateRows: '78px auto' }}>
                <Box style={{
                    fontSize: 18, color: 'rgb(94, 110, 130)', margin: 0, fontWeight: 'bold',
                    borderBottom: '1px solid rgb(206, 200, 200)',
                    display: 'flex',
                    overflow: 'hidden',
                }}><p style={{ margin: 'auto 25px', height: 'fit-content' }}>Questionnaire EPP</p></Box>
                <Box className={classes.containerEditor}>
                    <EditorComponent name={'introductionEpp'} handler={handlerIntroduction} disabled={loading} />
                </Box>
            </Box>

            <DraggableListComponent
                list={listQuestion}
                templates={Templates}
                loading={loading}
                onListChange={(newList) => { setListQuestion(newList) }}
                setAction={{
                    setList: (newList) => { setListQuestion(newList) },
                    setIsOpen: (isOpen) => { setIsOpen(isOpen) }
                }}
                handlerTemplates={{
                    template1: draggableListHandlers.handlerTemplate1,
                    template2: draggableListHandlers.handlerTemplate2
                }}
                titleButtonAdd={'Ajouter une question'}
                errorListMessage={'Aucune question n\'a été ajoutée'}
                buttonAllDelete={{
                    title: 'supprimer les questions',
                    alertMessage: 'Êtes-vous sur de vouloir supprimer toute les questions ?',
                }}
                saveAction={save}
                className={classes.DraggableStyle}
            />

        </>
    )
}

const useStyles = (screenReducer) => makeStyles({
    containerEditor: {
        padding: 20,
        display: 'flex',
        overflow: 'auto',
        '& .rdw-editor-main': {
            height: '100%',
            padding: 10,
            background: 'rgb(247, 247, 247)',
            borderRadius: 5,
            border: '1px solid #F1F1F1',
            margin: '0 0 10px 0',
        },
        '& .MuiFormControl-root': {
            width: '100%',
            height: '100%',
            margin: 'auto',
        },
        '& .rdw-editor-wrapper': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '0 10px !important',
        },
        '& .rdw-editor-toolbar': {
            margin: '10px 0',
        }
    },
    DraggableStyle: {
        '& .ActionButton-root': {
            borderRadius: '0 !important'
        },
    }
});

export default FormationQuestionEpp;