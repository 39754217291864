import React from 'react';
import activity from "react-useanimations/lib/activity";
import { Box, Fade, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import TitleComponent from '../../../Component/TitleComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { getRoutePathname } from '../../../Config/Route';
import LaunchIcon from '@material-ui/icons/Launch';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ButtonComponent from '../../../Component/ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch, theme } from '../../../App';
import Api from "../../../Api";
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import moment from 'moment';
import NoteIcon from '@material-ui/icons/Note';
import UseAnimations from 'react-useanimations';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import SelectComponent from '../../../Component/SelectComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';

const Popup = (props = {
    input: '',
    title: '',
    load: false,
    setIsOpen: () => { },
    save: () => { }
}) => {
    return (
        <Fade in={true} {...{ timeout: 1000 }}>
            <Box style={{ position: 'absolute', height: '100%', width: '100%', left: 0 }}>
                <ShadowBoxComponent style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
                    <TitleComponent title={<p style={{ margin: 0, fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{props.title}</p>} />
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {props.input}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 0, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                            <ButtonComponent style={{ margin: '0 0 0 auto', display: 'block' }} color={'#5E6E82'} label={'Annuler'} onClick={() => { props.setIsOpenPopup(false) }} />
                            <ButtonComponent style={{ display: 'block' }} label={'Enregistrer'} onClick={() => { props.save() }} disabled={props.load} />
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
                <Box style={{ position: 'absolute', height: '100%', width: '100%', background: 'rgba(0, 0, 0, 0.5)', left: 0 }} onClick={() => { props.setIsOpenPopup(false) }}></Box>
            </Box>
        </Fade>
    )
}

const RelaunchTask = (props) => {
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);
    const [isNote, setIsNote] = React.useState(false);
    const [isDone, setIsDone] = React.useState(false);
    const [taskId, setTaskId] = React.useState(null);
    const [task, setTask] = React.useState([]);
    const [form, setForm] = React.useState({
        resolutionText: {
            name: 'resolutionText',
            label: 'Resolution',
            textHelper: 'Sélectionnez un texte de resolution',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const [formNote, setFormNote] = React.useState({
        note: {
            name: 'note',
            label: 'Note',
            textHelper: 'Saisissez une note',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handlerNote = formHandlerInit(formNote, setFormNote);
    React.useEffect(handlerNote.start, []);

    const save = (type) => {
        if (taskId) {
            dispatch(LinearProgressMainLayoutActivate());
            setLoad(true)
            if (type === 'valid') {
                if (handler.checkError()) {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoad(false);
                    console.log('Error');
                } else {
                    Api.post({
                        route: 'webcoach_relaunch_valid',
                        params: { id: taskId },
                        data: handler.getData(),
                    }, (response) => {
                        dispatch(LinearProgressMainLayoutDeactivate());
                        setLoad(false);
                        setIsValid(false)
                        if (response.status === 200) {
                            props.getLoadList();
                            setIsDone(true)
                            dispatch(
                                SnackbarOpen({
                                    text: 'Tâche valider',
                                    variant: 'success',
                                })
                            );
                        } else if (response.status === 400) {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error,
                                    variant: 'error',
                                })
                            );
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                Api.post({
                    route: 'webcoach_relaunch_edit',
                    params: { id: taskId },
                    data: handlerNote.getData(),
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoad(false);
                    if (response.status === 200) {
                        props.getLoadList()
                        dispatch(
                            SnackbarOpen({
                                text: 'Note enregistrer',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error,
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        if (taskId) {
            dispatch(LinearProgressMainLayoutActivate());
            setReadyContent(false);
            setLoad(true)
            Api.get({
                route: 'webcoach_relaunch_get',
                params: { id: taskId }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoad(false)
                setReadyContent(true);
                if (response.status === 200) {
                    let data = response.data;
                    setTask(data);
                    setIsDone(data.doneAt)
                    handlerNote.setValue('note', data.note);
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error,
                            variant: 'error',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [taskId]);

    React.useEffect(() => {
        setTaskId(props.taskId);
    }, [props.taskId]);

    return (
        !isReadyContent ?
            <Fade in={true} {...{ timeout: 1000 }}>
                <div className={classes.loader}>
                    <div className={classes.loaderContent}>
                        <Box>
                            <div className={classes.loaderActivity}>
                                <UseAnimations animation={activity} size={35} />
                            </div>
                        </Box>
                    </div>
                </div>
            </Fade>
            :
            <Grid container spacing={4} style={{ margin: 0, width: '100%', display: 'grid', gridTemplateRows: 'max-content auto' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '15px 0px' }}>
                    <Box style={{ display: 'grid', gridTemplateRows: 'max-content max-content auto', color: '#5E6E82' }}>
                        <TitleComponent title={<p style={{ margin: 0, fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{task.label}</p>} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Box style={{ padding: '0 0 20px 0px' }}>
                                    <p style={{ margin: 0, display: 'flex', alignItems: 'end', gap: 4, fontSize: 20 }}>
                                        <span style={{ fontSize: 15 }}>
                                            <b>Type de tâche : {task.type}</b> | {moment(task.todoAt).format('ll')}
                                        </span>
                                        <Tooltip title={'Note'} placement="left">
                                            <IconButton style={{ padding: 5, background: '#5E6E82' }} onClick={() => setIsNote(true)}>
                                                <NoteIcon style={{ color: '#FFF', fontSize: 13 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </p>
                                    <Box style={{ margin: '0 10px', display: 'flex', alignItems: 'top', gap: 4 }}>
                                        <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0px, 10%) scaleX(-1)" }} />
                                        <p style={{ margin: 0 }}>Client(e) : {task.prospectName}
                                            <LaunchIcon
                                                className={classes.icon}
                                                onClick={() => window.open(getRoutePathname('crm_prospect', { id: task.prospect }), '_blank')}
                                            />
                                            <br />
                                            {task.prospectMobile && <>Mobile : {task.prospectMobile}</>} <br /> {task.prospectPhone && <>Fix : {task.prospectPhone}</>}
                                        </p>
                                    </Box>
                                </Box>
                                <h1 style={{ margin: '3px 0px', fontSize: 20 }}>Inscription en cours :</h1>
                                <Box style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-around', width: 'fit-content', lineHeight: '22px' }} >
                                    {(task && task.registration) &&
                                        <>
                                            <Box style={{ margin: '0 10px' }}>
                                                <p style={{ margin: 0, fontSize: 17, fontWeight: 'bold' }}>{task.registration.formationName}</p>
                                                <Box style={{ margin: '10px 0' }}>
                                                    {task.registration.isPersonal && task.registration.hasToPay  ? <p style={{ margin: 0 }}><b>La formation n'a toujours pas été payée</b></p> : null}
                                                    <p style={{ margin: 0 }}><b>Session :</b> du {task.registration.sessionStartAt} au {task.registration.sessionEndAt}</p>
                                                    <p style={{ margin: 0 }}><b>Taux d'avancement :</b> {task.registration.completion}</p>
                                                    <p style={{ margin: 0 }}><b>Temps effectué:</b> {task.registration.completionDuration}</p>
                                                    <p style={{ margin: 0 }}><b>N° d'action :</b> {task.registration.numberDPC}</p>
                                                </Box>
                                            </Box>
                                        </>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ margin: 0, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                                <ButtonComponent style={{ margin: '0 0 0 auto', display: 'block' }} color={'#5E6E82'} label={'Annuler'} onClick={() => { props.setIsOpen(false) }} />
                                <ButtonComponent style={{ display: 'block' }} label={'Valider la tâche'} onClick={() => { setIsValid(true) }} disabled={load || isDone} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
                    <Box style={{ minHeight: '100%', width: '100%', gridTemplateRows: 'max-content auto', display: 'grid', }} className={classes.shadowBox}>
                        <h1 style={{ margin: '4px 10px', fontSize: 20, fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: 4, color: '#5E6E82' }} ><EventNoteIcon />Tâches à venir</h1>
                        <Box style={{ position: 'relative', width: '100%', margin: 'auto', height: '93%' }}>
                            <Box style={{ overflow: 'auto', background: '#EFF2F4', borderRadius: 10, position: 'absolute', height: '100%', width: '100%', }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '14px 20px', }}>
                                    {(task && task.tasks && task.tasks.listLateTasks) ?
                                        task.tasks.listLateTasks.map((task, i) => {
                                            return ((parseInt(taskId) !== parseInt(task.id)) &&
                                                <ShadowBoxComponent style={{ margin: 10, background: "rgb(255, 238, 218)"}} key={i}>
                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <p style={{ margin: '5px 0px' }}><b>{task.type}</b> | {moment(task.todoAt).format('ll')}</p>
                                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 4 }}><EventNoteIcon /><b>{task.label}</b></p>
                                                            <p style={{ margin: '0 10px', display: 'flex', alignItems: 'baseline', gap: 4 }}>
                                                                <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0px, 10%) scaleX(-1)" }} />
                                                                <span><b>Formation :</b> {task.formation}</span>
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <ButtonComponent style={{ width: '100%', margin: 0 }} label={'Aller à la tâche'} onClick={() => setTaskId(task.id)} disabled={load} />
                                                        </Grid>
                                                    </Grid>
                                                </ShadowBoxComponent>
                                            )
                                        }) : <p className={classes.error}>{'Aucune tâche'}</p>
                                    }
                                    {(task && task.tasks && task.tasks.listComingTasks) ?
                                        task.tasks.listComingTasks.map((task, i) => {
                                            return ((parseInt(taskId) !== parseInt(task.id)) &&
                                                <ShadowBoxComponent style={{ margin: 10 }} key={i}>
                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <p style={{ margin: '5px 0px' }}><b>{task.type}</b> | {moment(task.todoAt).format('ll')}</p>
                                                            <p style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 4 }}><EventNoteIcon /><b>{task.label}</b></p>
                                                            <p style={{ margin: '0 10px', display: 'flex', alignItems: 'baseline', gap: 4 }}>
                                                                <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0px, 10%) scaleX(-1)" }} />
                                                                <span><b>Formation :</b> {task.formation}</span>
                                                            </p>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                                            <ButtonComponent style={{ width: '100%', margin: 0 }} label={'Aller à la tâche'} onClick={() => setTaskId(task.id)} disabled={load} />
                                                        </Grid>
                                                    </Grid>
                                                </ShadowBoxComponent>
                                            )
                                        }) : <p className={classes.error}>{'Aucune tâche'}</p>
                                    }
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                {isValid &&
                    <>
                        <Popup
                            title={'Valider la tâche'}
                            save={() => save('valid')}
                            input={<SelectComponent name={'resolutionText'} handler={handler} options={props.options} />}
                            setIsOpenPopup={setIsValid}
                            load={load}
                            {...props}
                        />
                    </>
                }
                {isNote &&
                    <>
                        <Popup
                            title={'Note'}
                            save={() => save('note')}
                            input={<TextFieldComponent name={'note'} handler={handlerNote} multiline={true} />}
                            setIsOpenPopup={setIsNote}
                            load={load}
                            {...props}
                        />
                    </>
                }
            </Grid>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center',
        margin: 'auto'
    },
    loaderContent: {
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            height: '60px !important',
            width: '60% !important'
        }
    },
    error: {
        margin: 'auto',
        fontSize: 24,
        fontWeight: 'bold',
        opacity: 0.2,
        width: 'fit-content',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    img: {
        width: 150,
        background: '#e6e6e6',
        height: 150,
        borderRadius: 15,
        overflow: 'hidden',
    },
    title: {
        fontSize: 18,
        margin: 0,
        fontWeight: 600,
        color: 'rgb(94, 110, 130)',
    },
    container: {
        margin: '10px 0'
    },
    icon: {
        cursor: 'pointer',
        verticalAlign: 'bottom',
        fontSize: 'large'
    },
});

export default RelaunchTask;
