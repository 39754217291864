import React from 'react';
import TableComponent from '../../../Component/TableComponent';
import moment from 'moment';
import { Box, Fade, makeStyles } from '@material-ui/core';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";

function Activity(props = {
    reloadList: () => {
    }
}) {
    const classes = useStyles();
    const [propsReady, setPropsReady] = React.useState(false);
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'description',
            label: 'Description',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAtText',
            label: '',
            options: { filter: true, sort: true }
        }
    ];

    React.useEffect(() => {
        if (Object.keys(props.sessionFormation).length > 0) {
            setPropsReady(true);
        }
    }, [props.sessionFormation]);

    return (
        propsReady ?
            <div style={{ padding: 4 }}>
                {props.sessionFormation && props.sessionFormation.activities &&
                    <TableComponent
                        noCache={true}
                        search={false}
                        sortOrder={{ name: 'id', direction: 'desc' }}
                        id={'financial_management_billing_v2_session_formation_activity_list'}
                        title={'Historique des activités'}
                        columns={columns}
                        onBeforeRefresh={props.reloadList}
                        promiseData={(resolve) => {
                            if (props.sessionFormation && props.sessionFormation.activities) {
                                let data = props.sessionFormation.activities;
                                for (let index in data) {
                                    data[index].createdAtText = moment(data[index].createdAt).format('lll');
                                };
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        }}
                    />}
            </div>

            :

            <Fade in={true} {...{ timeout: 1000 }}>
                <Box className={classes.loader}>
                    <Box className={classes.loaderContent}>
                        <Box className={classes.loaderActivity}>
                            <UseAnimations animation={activity} size={70} />
                        </Box>
                    </Box>
                </Box>
            </Fade>
    );
};

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%', 
        height: '100%', 
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    }
});

export default Activity;