export function hasRight(user = {}, right = '') {
    if (user && user.roles) {
        for (let index in user.roles) {
            if (right === user.roles[index]) {
                return true;
            }
        }
    }

    return false;
}
