import React, { Fragment } from 'react';
import Fade from "@material-ui/core/Fade";
import ButtonComponent from "../../../Component/ButtonComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import UseAnimations from "react-useanimations";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Avatar, Box, Drawer as DrawerMUI, makeStyles, Typography } from "@material-ui/core";
import activity from "react-useanimations/lib/activity";
import PaginationComponent from '../../../Component/PaginationComponent';
import FilterPaginationComponent from '../../../Component/FilterPaginationComponent';
import Badge from '@material-ui/core/Badge';
import PeopleIcon from '@material-ui/icons/People';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ForumIcon from '@material-ui/icons/Forum';
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import Popover from '@material-ui/core/Popover';
import Grid from "@material-ui/core/Grid";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import CardCommunityFormation from "./Component/CardCommunityFormation";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import { theme } from "../../../App";
import moment from "moment";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { TableReload } from '../../../Action/TableAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import EditorComponent from '../../../Component/EditorComponent';
import CardCommunityPostV2 from './Component/CardCommunityPostV2';

function Community(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const [communityPostData, setCommunityPostData] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [communityGroup, setCommunityGroup] = React.useState([]);
    const [communityAllGroup, setCommunityAllGroup] = React.useState([]);
    const [postByCommentProspect, setPostByCommentProspect] = React.useState([]);
    const [actionRow, setActionRow] = React.useState({});
    const [postProspect, setPostProspect] = React.useState([]);
    const [isBanAllGroup, setIsBanAllGroup] = React.useState(false);

    const filter = [
        {
            title: 'Groupe',
            data: 'group',
            type: 'button'
        },
        {
            data: 'idLockbutton',
            type: 'button'
        },
        {
            title: 'Thématique',
            data: 'thematic',
            type: 'button'
        },
        {
            data: 'name',
            type: 'textField'
        },
    ];

    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez le titre du posts.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subTitle: {
            name: 'subTitle',
            label: 'Sujet de discussion',
            textHelper: 'Saisissez un sujet de discussion.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);

    const edit = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoad(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'learner_community_post_edit',
                params: { communityPost: communityPostData.id, communityGroup: communityPostData.communityGroupId },
                data: handler.getData()
            }, (response) => {
                handler.setFormLoading(false);
                setIsOpen(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                loadData();
                if (response.status === 201) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Les modifications ont bien été pris en compte.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_community'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const editAction = (row) => {
        if (row.id && row.communityGroupId) {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'learner_community_post_get',
                params: { communityPost: row.id, communityGroup: row.communityGroupId }
            },
                (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }

    const openPost = (postProspect) => {
        Api.post({
            route: 'learner_community_group_add',
            data: { communityGroup: postProspect.communityGroupId }
        }, (response) => {
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                props.history.push(getRoutePathname('learner_community_post', { communityGroup: postProspect.communityGroupId, communityPost: postProspect.id }))
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'error',
                    })
                );
            } else if (response.status === 404) {
                props.history.push(getRoutePathname('learner_community_post', { communityGroup: postProspect.communityGroupId, communityPost: postProspect.id }))
            } else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const getAction = (row, handleClose) => {
        return (
            <Typography style={{ padding: 8 }}>
                <IconButton className={classes.buttonPostProspect}
                    onClick={() => {
                        editAction(row);
                        handler.reset();
                        setIsOpen(true)
                        handleClose();
                        setCommunityPostData(row);
                    }}>
                    <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Modifier</span>
                </IconButton>
                <IconButton
                    className={classes.buttonPostProspect}
                    onClick={() => { setActionRow(row); setShowConfirm(true); handleClose(); }}>
                    <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Supprimer</span>
                </IconButton>
            </Typography>
        );
    };

    const viewPostList = (dataList) => {
        return (
            <CardCommunityPostV2
                getAction={getAction}
                openPost={openPost}
                data={dataList}
            />
        )
    }

    const viewPostByCommentList = (postByCommentProspect) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box style={{ color: "#5E6E82", borderBottom: "7px solid rgb(223, 223, 223)", textAlign: "left", padding: '22px 0px' }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                                {postByCommentProspect.status === 'Admin' ?
                                    <Badge color="secondary" badgeContent={'A'} showZero >
                                        <Avatar className={classes.avatar}>{postByCommentProspect.firstname.charAt().toUpperCase()}{postByCommentProspect.lastname.charAt().toUpperCase()}</Avatar>
                                    </Badge> : (!postByCommentProspect.pseudo) ?
                                        <Avatar className={classes.avatar}>{postByCommentProspect.firstname.charAt().toUpperCase()}{postByCommentProspect.lastname.charAt().toUpperCase()}</Avatar> :
                                        <Avatar className={classes.avatar}>{postByCommentProspect.pseudo.charAt().toUpperCase()}</Avatar>
                                }
                                <Box style={{ padding: '0 10px' }}>
                                    <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{postByCommentProspect.title}</p>
                                    <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                        <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par
                                        {postByCommentProspect.isPostProspect ?
                                            <span className={classes.nameProspect}> vous </span> :
                                            <span className={classes.nameProspect}> {postByCommentProspect.status === 'Admin' ? 'un administrateur' : !postByCommentProspect.pseudo ? postByCommentProspect.firstname.charAt().toUpperCase() + '.' + postByCommentProspect.lastname : postByCommentProspect.pseudo} </span>}
                                        le {moment(postByCommentProspect.createdAt).format("lll")}
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "0px", display: 'flex' }} >
                                    <p style={{ margin: 0, textAlign: "left", alignContent: 'center', display: 'grid' }}>{postByCommentProspect.numComment > 99 ? '99+' : postByCommentProspect.numComment} Commentaires</p>
                                    <ButtonComponent
                                        small={true}
                                        color='#03a9f4'
                                        style={{ margin: "10px  56px", display: "block" }}
                                        className={classes.buttonPost}
                                        label={'Ouvrir la discussion'}
                                        onClick={() => { props.history.push(getRoutePathname('learner_community_post', { communityGroup: postByCommentProspect.communityGroupId, communityPost: postByCommentProspect.id })) }}
                                    />

                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <br />
                </Grid>
            </Fade>
        )
    }

    const viewProspectGroupList = (communityGroup) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CardCommunityFormation
                    show={true}
                    titleButton={'Ouvrir'}
                    styleButton={'buttonV2'}
                    image={learnerHomeFormationImageBanner + communityGroup.image}
                    data={communityGroup}
                    title={communityGroup.name}
                    onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: communityGroup.id })); }}
                />
            </Grid>
        )
    }

    const viewAllGroupList = (communityAllGroup) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <CardCommunityFormation
                    show={true}
                    titleButton={'Visualiser'}
                    styleButton={'buttonV1'}
                    image={learnerHomeFormationImageBanner + communityAllGroup.image}
                    data={communityAllGroup}
                    title={communityAllGroup.name}
                    onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: communityAllGroup.id })); }}
                />
            </Grid>
        )
    }
    const loadData = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoad(true);
        Api.get({
            route: 'learner_community_group_list'
        },
            (response) => {
                setLoad(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    setCommunityGroup(response.data.formationGroupProspect);
                    let data = response.data.formationAllGroup;
                    for (let index in data) {
                        data[index].idLockbutton = data[index].isLock === true ? 'Ouvert' : 'Fermer';
                    }
                    setCommunityAllGroup(data);
                    setPostByCommentProspect(response.data.postByCommentProspect)
                    setPostProspect(response.data.postProspect)
                    setIsBanAllGroup(response.data.isBanAllGroup)
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        document.querySelector('#root').style.filter = isBanAllGroup ? 'blur(3px)' : 'blur(0px)';
    }, [isBanAllGroup]);
    React.useEffect(() => {
        loadData()
        setBreadcrumbs({
            currentPage: 'Forum',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });
    }, []);
    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <Box style={{ textAlign: 'center', maxWidth: postProspect.length != 0 ? 1400 : 1000, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box>
                            <Grid container spacing={2}>

                                {postProspect.length != 0 &&
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <br />
                                        <Box style={{
                                            color: '#5E6E82',
                                            margin: 'auto',
                                            padding: 12,
                                            borderRadius: 5,
                                            backgroundColor: '#FFFFFF',
                                            border: '1px solid rgba(0, 0, 0, 0.15)',
                                            position: 'sticky',
                                            top: 30,
                                            width: '90%',
                                        }}>
                                            <FilterPaginationComponent
                                                data={postProspect}
                                                title={'Mes discussions'}
                                                icon={<ForumIcon />}
                                                pageNumber={3}
                                                viewList={viewPostList}
                                                backgroundColor={'0, 0, 0, 0'}
                                                className={classes.postByCommentProspect}
                                            />
                                        </Box>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={12} md={12} lg={postProspect.length != 0 ? 9 : 12} xl={postProspect.length != 0 ? 9 : 12}>
                                    <Box style={{ width: '95%', margin: 'auto', }}>
                                        {postByCommentProspect.length != 0 &&
                                            <>
                                                <br />
                                                <FilterPaginationComponent
                                                    data={postByCommentProspect}
                                                    title={'Discussion dans lesquelles vous avez intéragit'}
                                                    icon={<ForumIcon />}
                                                    pageNumber={3}
                                                    viewList={viewPostByCommentList}
                                                    backgroundColor={'0, 0, 0, 0'}
                                                    className={classes.postByCommentProspect}
                                                />
                                            </>
                                        }
                                        {communityGroup && communityGroup.length != 0 &&
                                            <>
                                                <br />
                                                <FilterPaginationComponent
                                                    data={communityGroup}
                                                    title={'Groupe dont vous faîtes parti'}
                                                    filter={filter}
                                                    icon={<PeopleIcon />}
                                                    error={'Aucun groupe'}
                                                    pageNumber={6}
                                                    viewList={viewProspectGroupList}
                                                />
                                            </>
                                        }
                                        <br />
                                        <br />
                                        <FilterPaginationComponent
                                            data={communityAllGroup}
                                            title={'Trouver un groupe'}
                                            filter={filter}
                                            icon={<GroupAddIcon />}
                                            pageNumber={6}
                                            viewList={viewAllGroupList}
                                            loading={load}
                                            loadData={loadData}
                                            error={'Aucun groupe'}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <br />
                            <br />
                            <Fragment>
                                <SweetAlert
                                    show={isBanAllGroup}
                                    title={'Vous avez été banni'}
                                    text={'Vous faites l\'objet d\'un bannissement pour la raison suivante :\n Mauvais comportement'}
                                    type={'warning'}
                                    confirmButtonText={'Retour au tableau de bord'}
                                    onConfirm={() => {
                                        setIsBanAllGroup(false)
                                        props.history.push(getRoutePathname('learner_home'));
                                    }}
                                />
                            </Fragment>
                            <br />
                            <Fragment>
                                <DrawerMUI className={classes.subMenu} anchor={'right'} open={isOpen} onClose={() => setIsOpen(false)}>
                                    <br />
                                    <Grid container spacing={3} style={{ width: '80%', margin: '0 auto', }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <p style={{ color: "#5E6E82", fontSize: 33, margin: "auto", fontWeight: "bold", borderLeft: "10px solid", padding: " 10px 30px", }}>{'Édition de votre groupe de Discussion'}</p>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextFieldComponent name={'title'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <EditorComponent name={'subTitle'} handler={handler} />
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ width: '100%' }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <ButtonComponent color={'#5E6E82'} style={{ width: '50%', padding: 15, borderRadius: 0 }} label={'Annuler'} onClick={() => setIsOpen(false)} disabled={load} />
                                            <ButtonComponent label={'Enregistrer'} style={{ width: '50%', padding: 15, borderRadius: 0, backgroundColor: theme.colorPrimary }} onClick={() => { edit() }} disabled={load} />
                                        </Grid>
                                    </Grid>
                                </DrawerMUI>
                            </Fragment>
                            <SweetAlert
                                show={showConfirm}
                                title={'Supprimer'}
                                text={'Êtes-vous sûr de vouloir supprimer ce poste ?'}
                                type={'warning'}
                                cancelButtonText={'Non'}
                                confirmButtonText={'Oui'}
                                showCancelButton={true}
                                className={classes.sweetAlert}
                                onConfirm={() => {
                                    setShowConfirm(false);
                                    Api.delete({
                                        route: 'learner_community_post_delete',
                                        params: { communityPost: actionRow.id, communityGroup: actionRow.communityGroupId }
                                    },
                                        (response) => {
                                            if (response.status === 200) {
                                                loadData()
                                            } else {
                                                props.history.push(getRoutePathname('learner_home'));
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: 'Une erreur inattendue s\'est produite.',
                                                        variant: 'error',
                                                    })
                                                );
                                            }
                                        });
                                }}
                                onCancel={() => setShowConfirm(false)}
                            />
                        </Box>
                }
            </Box>
        </>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    titleGroup: {
        fontWeight: 600,
        textAlign: "left",
        margin: 0,
        background: theme.colorPrimary,
        padding: 12,
        fontSize: '1rem',
    },
    boxGroup: {
        background: '#E7157B4a',
        margin: 0,
        width: '100%',
    },
    boxComponent: {
        border: "1px solid #A1A2A9",
        borderRadius: 8,
        overflow: "hidden"
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    avatarPost: {
        backgroundColor: theme.colorPrimary,
        fontSize: 11,
        width: 25,
        height: 25,
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    buttonPostProspect: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            boxShadow: '0px 1px 3px rgba(0,0,0,0.2)',
        },
    },
    ul: {
        "& .MuiPaginationItem-root": {
            color: "#fff"
        },
        "& .MuiPaginationItem-page:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.04)"
        },
        "& .MuiPaginationItem-outlined": {
            border: '1px solid rgba(255, 255, 255, 0.23)'
        },
        "& .MuiPaginationItem-page.Mui-selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        },
        "& .MuiPaginationItem-page.Mui-selected.Mui-focusVisible": {
            backgroundColor: "rgba(0, 0, 0, 0.12)"
        }
    },
    postByCommentProspect: {
        color: '#5E6E82',
        border: "0px !important",
        borderRadius: 8,
        overflow: "hidden",
        '& .MuiGrid-spacing-xs-3': {
            background: "transparent !important"
        },
        '& .MuiGrid-grid-lg-12': {
            background: "transparent !important"
        },
        '& .MuiButton-text': {
            color: '#5E6E82F',
        },
        '& .MuiGrid-item': {
            padding: '0px 0px'
        },
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        }
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 600,
        }
    },
}));

export default Community;
