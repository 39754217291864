import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import { Pagination } from "@material-ui/lab";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import { Box } from '@material-ui/core';

export default function PaginationComponent(props = {
    dataList: [],
    dataKey: null,
    pageNumber: 0,
    offset: () => { },
    count: 0,
    viewList: null,
    error: '',
    backgroundColor: '',
    color: '',
    classe: ''
}) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const classes = useStyles();
    const listLength = props.count ? props.count : props.dataList.length;
    let counterPage = props.pageNumber;
    let [page, setPage] = React.useState(1);
    const PER_PAGE = counterPage;
    const count = Math.ceil((props.dataKey ? props.dataKey.length : props.dataList ? listLength : 0) / PER_PAGE);

    const usePagination = (data, itemsPerPage) => {
        if (data && itemsPerPage) {

            const maxPage = Math.ceil(data.length / itemsPerPage);

            function currentData() {
                const begin = (currentPage - 1) * itemsPerPage;
                const end = begin + itemsPerPage;
                return data.slice(begin, end);
            }

            function next() {
                setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
            }

            function prev() {
                setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
            }

            function offset(page) {
                if (page && count && props.offset) {
                    props.offset((page * counterPage) - counterPage)
                }
            }

            function jump(page) {
                const pageNumber = Math.max(1, page);
                setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
            }
            return { next, prev, jump, currentData, offset, currentPage, maxPage };
        }
    }

    const _DATA = usePagination((props.dataKey ? props.dataKey : props.dataList), PER_PAGE);

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        _DATA.offset(p);
    };

    React.useEffect(() => {
        if (listLength) {
            setPage(1);
            setCurrentPage(1);
            props.offset && props.offset(counterPage - counterPage)
        }
    }, [listLength]);
    return (
        <>
            <Grid container spacing={3} className={`${classes.boxGroup} listPagination-root`} style={{ background: 'rgba(' + props.backgroundColor + ',0.5)' }}>
                {(_DATA.currentData() && _DATA.currentData().length === 0) ?
                    <p className={`${classes.error} error-root`}><b>{props.error ? props.error : "Aucun résultat"}</b></p>

                    : _DATA.currentData().map((dataList, index) =>
                        <Fade in={true} {...{ timeout: 500 }} key={index}>
                            {props.viewList(dataList)}
                        </Fade>
                    )
                }
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ background: props.backgroundColor, padding: 7 }}>
                    {(listLength > PER_PAGE) && <Pagination
                        style={{ margin: '0 0 0 auto', width: 'fit-content' }}
                        count={count}
                        color={props.color}
                        size="medium"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        className={props.classe}
                    />}
                </Grid>
            </Grid>
        </>

    )
}

const useStyles = makeStyles({
    error: {
        color: "#666",
        textTransform: "uppercase",
        fontSize: '4vw',
        opacity: 0.1,
        width: "100%",
        textAlign: 'center'
    },
    boxGroup: {
        margin: 0,
        width: '100%',
    },
});
