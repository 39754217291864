import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import { useDispatch } from "react-redux";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Delete, Edit } from "@material-ui/icons";
import SweetAlert from "sweetalert-react";
import { getRoutePathname } from "../../../Config/Route";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";

export default function ChapterCourse(props = {}) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionCourses, setOptionCourses] = React.useState([false]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    // FORM
    const [form, setForm] = React.useState({
        course: {
            id: '',
            name: 'course',
            label: 'Cours',
            textHelper: 'Choisissez un cours.',
            type: 'integer',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        order: {
            name: 'order',
            label: 'Ordre',
            textHelper: 'Saisissez l\'order du cours dans le chapitre.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez un nom surchargé.',
            type: 'text',
            defaultValue: ''
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez une durée surchargé (en seconde).',
            type: 'integer',
            defaultValue: ''
        }
    });
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);
    React.useEffect(() => {
        Api.get({
            route: 'select_courses'
        },
            (response) => {
                if (response && response.data) {
                    setOptionCourses(response.data);
                }
            });
    }, []);

    const save = (id) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_chapter_chapter_course_edit',
                    data: datas,
                    params: { chapter: props.match.params.id, id: id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Relation cours modifiée.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('content_manager_chapter_chapter_course_list'));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'content_manager_chapter_chapter_course_add',
                    params: { chapter: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Relation cours enregistré.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('content_manager_chapter_chapter_course_list'));
                        setDialog(false);
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'order',
            label: 'Order',
            options: { filter: true, sort: true }
        },
        {
            name: 'courseName',
            label: 'Nom du cours',
            options: { filter: true, sort: true }
        },
        {
            name: 'name',
            label: 'Nom du cours surchargé',
            options: { filter: true, sort: true }
        },
        {
            name: 'duration',
            label: 'Durée du cours surchargé',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: ' ',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    React.useEffect(handler.start, []);
    const editAction = (id) => {
        setReadyContent(false);
        if (id) {
            setIsEdition(true);
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'content_manager_chapter_chapter_course_get',
                params: { id: id, chapter: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data[0]);
                        handler.form.course.id = data[0].id;
                    } else if (response.status === 404) {
                        //props.history.push(getRoutePathname('content_manager_question_question_answer_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            setIsEdition(false);
            setReadyContent(true);
        }
    }
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Edit'} placement="left">
                    <IconButton onClick={() => {
                        editAction(row.id);
                        handler.reset();
                        setDialog(true);
                        setIsEdition(true);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Cours'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('content_manager_course', { id: row.courseId }));
                    }}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'content_manager_chapter_chapter_course_list'}
                title={'Chapitre lié aux cours suivants : '}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une relation cours',
                    onClick: () => {
                        handler.reset();
                        setDialog(true);
                        setIsEdition(false);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_chapter_chapter_course_list',
                            params: { chapter: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog loading={!isReadyContent} open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{isEdition ? <>Édite un cours</> : <>Ajouter un cours</>}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectAutocompleteComponent name={'course'} handler={handler} options={optionCourses} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'order'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'duration'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(handler.form.course.id)} disabled={loading} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_chapter_chapter_course_delete',
                        params: { id: actionRow.id, chapter: props.match.params.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Cours supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_chapter_chapter_course_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
