import React from 'react';
import ContentViewComponent from "../../Component/ContentViewComponent";
import ShadowBoxComponent from "../../Component/ShadowBoxComponent";

function RoadmapSI(props) {
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Roadmap',
            context: 'À propos de l\'ERP',
            description: 'Roadmap Groupe'
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <ShadowBoxComponent style={{overflow: 'scroll', width: '100%', height: 'calc(100vh - 190px)'}}>
                <img style={{width: 2000}} src={'/asset/images/roadmap_si.png'} alt="logo"/>
            </ShadowBoxComponent>
        </ContentViewComponent>
    );
}

export default RoadmapSI;
