import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid, IconButton, Tooltip } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TableComponent from '../../../Component/TableComponent';
import { Edit, Mouse } from '@material-ui/icons';
import { TableReload } from '../../../Action/TableAction';
import SelectComponent from "../../../Component/SelectComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";

const EquipmentTypeList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [isEdition, setIsEdition] = React.useState(false);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "abbrev",
            label: "Acronyme",
            options: { filter: true, sort: true }
        },
        {
            name: "equipmentCount",
            label: "Nombre d'équipement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        acronym: {
            name: 'acronym',
            label: 'Acronyme',
            textHelper: 'Saisissez un acronyme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        isPeripheral: {
            name: 'isPeripheral',
            label: 'Type périphérique',
            type: 'boolean',
            defaultValue: false,
        },
    });

    const handler = formHandlerInit(form, setForm);
    const save = (row) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'it_infrastructure_equipment_type_edit',
                    data: handler.getData(),
                    params: { id: row.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(TableReload('it_infrastructure_equipment_type_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Type modifié.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'it_infrastructure_equipment_type_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        dispatch(TableReload('it_infrastructure_equipment_type_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Type enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { setIsEdition(true); setRow(row); setDialog(true) }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des types d\'équipements',
            context: 'Parc informatique',
            description: ''
        });

        setReadyContent(true);

        if (row.id) {
            handler.setDataApi(row);
        }
    }, [row]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'it_infrastructure_equipment_type_list'}
                title={'Liste des types d\'équipement'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un type d\'équipement',
                    onClick: () => { setDialog(true); setIsEdition(false); }
                }}
                promiseData={(resolve) => {
                    Api.get({
                        route: 'it_infrastructure_equipment_type_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].abbrev = (<>{data[index].acronym} {data[index].isPeripheral && <Mouse/>}</>)
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => { setIsEdition(false); setDialog(false); handler.reset() }}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{isEdition ? 'Editer un type' : 'Ajouter un type'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'acronym'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CheckboxComponent name={'isPeripheral'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setIsEdition(false); setDialog(false) }} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(row)} disabled={loading} />
                </DialogActions>
            </Dialog>
        </ContentViewComponent>

    );
};
const useStyles = makeStyles({});

export default EquipmentTypeList;


