import React from 'react';
import moment from 'moment';
import {theme} from "../../../../App";
import {makeStyles} from '@material-ui/core/styles';

const Countdown = (props) => {

    const initCountdownTimer = {
        days: '',
        hours: '',
        minutes: '',
        seconds: ''
    };
    const classes = useStyles(theme)();
    const [countdownSettings, setCountdownSettings] = React.useState(props.unixEndDate);
    const [countdownTimer, setCountdownTimer] = React.useState({ ...initCountdownTimer });
    const [countdownFinish, setCountdownFinish] = React.useState(false);

    React.useEffect(() => {
        let timer = null;
        if (countdownSettings) {
            timer = setInterval(() => playTimer(countdownSettings), 1000);
        }

        return () => {
            clearInterval(timer);
            timer = null;
        }
    }, [countdownSettings]);

    function playTimer(currentUnixEndDate) {
        const distance = currentUnixEndDate - moment().format('X');

        if (distance > 0) {
            setCountdownTimer(prevCountdownTimer => {
                return {
                    ...prevCountdownTimer,
                    days: parseInt(distance / (60 * 60 * 24), 10),
                    hours: parseInt(distance % (60 * 60 * 24) / (60 * 60), 10),
                    mins: parseInt(distance % (60 * 60) / (60), 10),
                    secs: parseInt(distance % 60, 10)
                };
            });
        }
        else {
            setCountdownFinish(true);
        }
    }

    const sendData = () => {
        props.callback(countdownFinish);
    };

    React.useEffect(() => {
        if (countdownFinish) {
            sendData();
        }
    }, [countdownFinish]);

    return (
        <>
            <p className={classes.intro}>Votre classe virtuelle débutera dans :</p>
            <div className={classes.card}>
                <div className={classes.value}>{countdownTimer.days}</div>
                <div className={classes.unit}>Jours</div>
            </div>
            <div className={classes.card}>
                <div className={classes.value}>{countdownTimer.hours}</div>
                <div className={classes.unit}>Heures</div>
            </div>
            <div className={classes.card}>
                <div className={classes.value}>{countdownTimer.mins}</div>
                <div className={classes.unit}>Minutes</div>
            </div>
            <div className={classes.card}>
                <div className={classes.value}>{countdownTimer.secs}</div>
                <div className={classes.unit}>Secondes</div>
            </div>
            <p className={classes.info}>À la suite du compte à rebours, un lien vous donnera accès à la salle de cours virtuelle.</p>
        </>
    );
}

const useStyles = (theme) => makeStyles({
    intro: {
        margin: 0,
        fontWeight: 'bold'
    },
    card: {
        display: 'inline-block',
        margin: '10px',
        minWidth: '100px',
        textAlign: 'center'
    },
    value: {
        color: 'black',
        fontWeight: 'bold',
        fontSize: '2rem',
        marginBottom: '10px'
    },
    unit: {
        textTransform: 'capitalize'
    },
    info: {
        margin: 0,
        fontSize: '12px'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    }
});

export default Countdown;