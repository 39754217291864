import React from 'react';
import Api from "../../../Api";

function Iframe(props) {

    const url = Api.route({
        route: 'learner_registration_course_view',
        params: {registration: props.registration, course: props.course}
    });

    if (props.courseType) {
        if (props.courseType === 4 || props.courseType === 6) {
            return (
                <iframe
                    style={{
                        border: 0,
                        height: 'calc(100vh - 150px)',
                        width: '100%',
                        marginTop: 90,
                        textAlign: 'center',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)'
                    }}
                    src={url}
                />
            );
        } else if(props.courseType === 7) {
            return (
                <iframe
                    style={{
                        border: 0,
                        width: '80%',
                        height: 'calc(70vh - 150px)',
                        marginTop: 50,
                        textAlign: 'center',
                        padding: 20
                    }}
                    src={url}
                />
            );
        } else {
            return (
                <iframe
                    style={{
                        border: 0,
                        height: 640,
                        width: 920,
                        textAlign: 'center',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)'
                    }}
                    src={url}
                />
            );
        }
    } else {
        return (<></>);
    }
}

export default Iframe;
