import React from 'react';
import {Switch, Route} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from "./Component/AppBar";
import {useSelector} from "react-redux";

export default function LearnerLayout(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);

    return (
        <div className={classes.learnerLayout}>
            {/* <== AppBar */}
            {!screenReducer.full && <AppBar {...props}/>}
            {/* AppBar ==> */}

            <div style={screenReducer.full ?
                {paddingTop: 0, height: '100%', overflow: 'none'} :
                {paddingTop: 58, height: 'calc(100% - 58px)', overflow: 'scroll'}}>

                <Switch>
                    {
                        props.routes.map((route) => (
                            <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes}/>}/>
                        ))
                    }
                </Switch>
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    learnerLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    }
});
