import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {getRoutePathname} from "../../../Config/Route";
import {Assignment, Edit} from "@material-ui/icons";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";
import {useDispatch} from "react-redux";

function ProspectHotlineList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'contact',
            label: 'Contact',
            options: {filter: true, sort: true}
        },
        {
            name: 'email',
            label: 'Email du contact',
            options: {filter: true, sort: true}
        },
        {
            name: 'organismName',
            label: 'Organisme',
            options: {filter: true, sort: true}
        },
        {
            name: 'authorComment',
            label: 'Commentaire',
            options: {filter: true, sort: true}
        },
        {
            name: 'hotlineTypeName',
            label: 'Demande',
            options: {filter: true, sort: true}
        },
        {
            name: 'author',
            label: 'Créé par',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            options: {filter: true, sort: true}
        },
        {
            name: 'supportComment',
            label: 'Commentaire du support',
            options: {filter: true, sort: true}
        },
        {
            name: 'treatedAt',
            label: 'Suivi',
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        isTreated: {
            name: 'isTreated',
            label: 'Hotline traitée',
            textHelper: 'Hotline traitée.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const [hotlineSelected, setHotlineSelected] = React.useState(null);
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);

            let datas = handler.getData();

            Api.post({
                    route: 'support_prospect_hotline_edit',
                    data: datas,
                    params: {
                        id: hotlineSelected
                    }
                },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);

                    if (response.status === 200) {
                        setDialog(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Hotline Modifiée.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        if (response.error.message) {
                            dispatch(SnackbarOpen({text: response.error.message, variant: 'error'}));
                        }
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(TableReload('support_prospect_hotline_list'));
                });
        }
    };
    const getAction = (row) => {
        return (
            <div style={{width: 90}}>
                <Tooltip title={'Modifier la hotline'} placement="left">
                    <IconButton onClick={() => {
                        setHotlineSelected(row.id);
                        handler.setValue('comment',  row.supportComment);
                        handler.setValue('isTreated', !!row.treatedAt);
                        setDialog(true);
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', {id: row.prospectId}))}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Hotlines',
            context: 'Support',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'support_prospect_hotline_list'}
                title={'Hotlines'}
                columns={columns}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'support_prospect_hotline_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction({...data[index]});
                                data[index].createdAt = moment(data[index].createdAt).format('ll');
                                data[index].treatedAt = data[index].treatedAt ? moment(data[index].treatedAt).format('ll') : 'En cours...';
                                data[index].supportComment = data[index].supportComment ? data[index].supportComment : '-';
                            }
                            resolve(data);
                        });
                }}
            />

            <br/>

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>Modification de la hotline</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <TextFieldComponent name={'comment'} handler={handler} multiline={true}/>
                    <CheckboxComponent name={'isTreated'} handler={handler} direction={'right'}/>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

export default ProspectHotlineList;
