import React from "react";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { getRoutePathname } from "../../../Config/Route";

export default function FormerVirtualClassroomLearners(props) {
  const [isReadyContent, setReadyContent] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState({});
  const dispatch = useDispatch();

  // LIST
  const columns = [
    {
      name: 'id',
      label: 'Référence',
      options: { filter: true, sort: true }
    },
    {
      name: 'lastname',
      label: 'Nom',
      options: { filter: true, sort: true }
    },
    {
      name: 'firstname',
      label: 'Prénom',
      options: { filter: true, sort: true }
    },
    {
      name: 'email',
      label: 'Email',
      options: { filter: true, sort: true }
    },
    {
      name: "phone",
      label: "Numéro de téléphone",
      options: { filter: true, sort: true }
    },
    {
      name: "action",
      label: "Action",
      options: { filter: false, sort: false }
    }
  ];

  React.useEffect(() => {
    setBreadcrumbs({
      title: 'Liste des participants',
      context: 'Formateur',
      description: '',
      links: [
        { path: getRoutePathname('former_virtual_classroom_list'), label: 'Liste des classes virtuelles' }
      ]
    });
    setReadyContent(true);
  }, []);

  const getAction = (row) => {
    return (
      <Checkbox
        key={row.id + '-' + new Date().getTime()}
        defaultChecked={row.isPresent}
        onChange={() => {
          Api.post({
            route: 'former_virtual_classroom_learner_present_edit',
            params: {
              id: props.match.params.id,
              registration: row.registrationId
            }
          },
            (response) => {
              if (response.status === 200) {
                if (response.data[0]) {
                  dispatch(
                    SnackbarOpen({
                      text: row.lastname + ' ' + row.firstname + ' est présent(e)',
                      variant: 'success',
                    })
                  );
                } else {
                  dispatch(
                    SnackbarOpen({
                      text: row.lastname + ' ' + row.firstname + ' est absent(e)',
                      variant: 'success',
                    })
                  );
                }

              } else {
                dispatch(
                  SnackbarOpen({
                    text: "Une erreur s'est produite",
                    variant: 'error',
                  })
                );
              }
            })
        }}
      />
    );
  };

  return (
    <ContentViewComponent
      loading={!isReadyContent}
      breadcrumbs={breadcrumbs}
      {...props}
    >
      <TableComponent
        id={'former_virtual_classroom_learners'}
        title={'Participants'}
        columns={columns}
        promiseData={(resolve) => {
          Api.get({
            route: 'former_virtual_classroom_learner_present_list',
            params: { id: props.match.params.id }
          },
            (response) => {
              if (response.data) {
                let data = response.data;
                for (let index in data) {
                  data[index].action = getAction(data[index]);
                }
                resolve(data);
              } else {
                resolve([]);
              }
            });
        }}
        search={false}
      />
    </ContentViewComponent>
  );
}
