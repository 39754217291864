import React from 'react'
import SchedulerComponent from "../../../../Component/SchedulerComponent";
import Api from "../../../../Api";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import { getRoutePathname } from "../../../../Config/Route";
import { cacheTask } from "../../../../Cache";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";

export default function ProspectTask(props = {}) {
    const dispatch = useDispatch();
    const [datas, setDatas] = React.useState(cacheTask.commercial ? cacheTask.commercial : []);
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({ route: 'institution_health_crm_commercial_task_list' }, (response) => {
            let datasTmp = [];
            console.log(props.match.params);
            for (let index in response.data) {
                datasTmp.push({
                    id: response.data[index].id,
                    prospectId: response.data[index].prospectId,
                    title: response.data[index].title,
                    comment: response.data[index].comment,
                    startDate: response.data[index].startAt,
                    endDate: response.data[index].endAt,
                    type: response.data[index].commercialTaskTypeId,
                    infoHeader: (response.data[index].prospectId && props.match.params.id && props.match.params.institution) && <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: props.match.params.institution, id: props.match.params.id }))}
                    >
                        {response.data[index].prospectName}
                    </a>
                });
            }
            cacheTask.commercial = [...datasTmp];
            setDatas(datasTmp);
            dispatch(LinearProgressMainLayoutDeactivate());
        });
        Api.get({ route: 'select_commercial_task_types' }, (response) => setOptions(response.data));
    }, []);

    return (
        <SchedulerComponent
            title={'Tâche'}
            datas={datas}
            options={options}
            onEdit={(data, resolve) => {
                Api.post({
                    route: data.id ? 'institution_health_crm_commercial_task_edit' : 'institution_health_crm_commercial_task_add',
                    params: data.id ? { id: data.id } : {},
                    data: {
                        prospectId: data.prospectId ? data.prospectId : (props.prospectId ? props.prospectId : null),
                        title: data.title,
                        commercialTaskType: data.type,
                        startAt: data.startDate,
                        endAt: data.endDate,
                        comment: data.comment
                    }
                },
                    (response) => {
                        if (response.status === 200) {
                            if (data.id) {
                                let newDatas = [...datas];
                                for (let index in newDatas) {
                                    if (newDatas[index].id === data.id) {
                                        newDatas[index] = data;
                                        break;
                                    }
                                }
                                setDatas(newDatas);
                            } else {
                                data.id = response.data.id;
                                setDatas([...datas, data]);
                            }
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                        resolve();
                    });
            }}
            onDelete={(id, resolve) => {
                Api.delete({
                    route: 'institution_health_crm_commercial_task_delete',
                    params: { id: id }
                },
                    (response) => {
                        if (response.status === 200) {
                            let newDatas = [];
                            for (let index in datas) {
                                if (datas[index].id === id) continue;
                                newDatas.push(datas[index]);
                            }
                            setDatas(newDatas);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                        resolve();
                    });
                resolve();
            }}
        />
    )
}
