import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";
import { useDispatch } from "react-redux";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../Action/TableAction";

function FormationList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Types de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupName",
            label: "Groupe",
            options: { filter: true, sort: true }
        },
        {
            name: "duration",
            label: "Durée",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('content_manager_formation', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des formations',
            context: 'Gestion de contenu',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'content_manager_formation_list'}
                title={'Formations'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une formation', onClick: () => props.history.push(getRoutePathname('content_manager_formation', { id: null })),
                }}
                actionSecond={{
                    label: 'Télécharger en PDF', onClick: () => {
                        const url = Api.route({ route: 'content_manager_formation_list_download' });
                        window.open(url, '_blank');
                    },
                }}
                promiseData={(resolve) => {
                    Api.get({
                        route: 'content_manager_formation_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].duration = (data[index].duration / 60 / 60) + 'h';
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />
            <br />
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet formation ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_formation_delete',
                        params: { id: actionRow.id }
                    },
                        () => {
                            dispatch(TableReload('content_manager_formation_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default FormationList;
