import React from 'react';
import {makeStyles} from "@material-ui/core";
import CountUp from 'react-countup';
import ShadowBoxComponent from "./../../../../Component/ShadowBoxComponent";

function Card(props = {
    title: '',
    number: 100,
    percentage: 100,
    turnover: 100,
    icon: '',
    color: '',
    disabled: false
}) {
    const classes = useStyles();

    return (
        <ShadowBoxComponent disabled={props.disabled}>
            <props.icon className={classes.icon} style={{color: props.color}}/>
            <div className={classes.percentage}>{props.percentage} %</div>
            <div className={classes.number}>
                {props.number > 0 ? <>{props.number} <span style={{fontSize: 12}}>formulaires Générés</span></> : <span style={{fontSize: 12}}>Aucun formulaire Généré</span>}
            </div>
            <div className={classes.turnover}><CountUp separator=" " end={props.turnover}/> € <sup style={{fontSize: 10}}>HT</sup></div>
            <hr style={{marginTop: 15}}/>
            <span className={classes.title}>{props.title}</span>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    number: {
        textAlign: 'end',
        fontSize: 15,
    },
    turnover: {
        textAlign: 'end',
        fontWeight: 500,
        fontSize: 25,
        marginTop: 5,
        color: '#5E6E82'
    },
    percentage: {
        position: 'absolute',
        top: 15,
        left: 15,
        fontSize: 15,
    },
    icon: {
        position: 'absolute',
        top: 40,
        left: 15,
        fontSize: 30
    }
});

export default Card;
