import React from 'react';
import { Box, Button, IconButton, Popover, Tooltip, Typography, makeStyles } from "@material-ui/core";
import MoreIcon from '@material-ui/icons/More';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { theme } from "../App";
import ShadowBoxComponent from './ShadowBoxComponent';

function BoxPopupActionComponent(props = {
    title: '',
    template: null,
    textButton: '',
    isBoxPopup: false,
    direction: 'top',
    className: '',
    closedBoxActive: false,
    dimensionsButton: { width: 45, height: 45, },
    getActionButton: [{
        title: '',
        icon: '',
        onclick: () => { },
        color: 'primary',
        disabled: false,
        style: {},
        className: ''
    }]
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isTimeOpen, setIsTimeOpen] = React.useState(false);
    const [scrolling, setScrolling] = React.useState(false);
    const divRef = React.useRef(null);
    const buttonRef = React.useRef(null);
    const iconButtonRef = React.useRef(null);
    const getActionButtonRefs = [];
    const [actionButton, setActionButton] = React.useState([]);
    const [isBoxPopupTransition, setIsBoxPopupTransition] = React.useState(false);
    const refTemplate = React.useRef(null);
    const refBoxTemplate = React.useRef(null);
    const refPopup = React.useRef(null);
    const [showBox, setShowBox] = React.useState(false);
    const classes = useStyles(theme, props, scrolling, isTimeOpen)();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const dropActionPopup = (isOffAction) => {
        if(refTemplate.current){
            if (isOpen || isOffAction) {
                refTemplate.current.style.opacity = 0;
                refTemplate.current.style.visibility = 'hidden';
                refPopup.current.style.transform = 'translate(150%)';
                refPopup.current.style.visibility = 'hidden';
                if (iconButtonRef.current) iconButtonRef.current.style.transform = 'scale(0)';
                for (let index in getActionButtonRefs) {
                    if (getActionButtonRefs[index]) getActionButtonRefs[index].style.transform = 'scale(0)';
                }
                setTimeout(() => { setIsTimeOpen(false) }, 300)
            }
            else {
                setTimeout(() => {
                    refTemplate.current.style.opacity = 1;
                    refTemplate.current.style.visibility = 'visible';
                }, 400);
                setTimeout(() => {
                    refPopup.current.style.transform = 'translate(0%)';
                    refPopup.current.style.visibility = 'visible';
                }, 100);
                if (iconButtonRef.current) iconButtonRef.current.style.transform = 'scale(1)';
                for (let index in getActionButtonRefs) {
                    if (getActionButtonRefs[index]) getActionButtonRefs[index].style.transform = 'scale(1)';
                }
                setIsTimeOpen(true);
            }
        }
    }

    const viewAction = () => {
        return (
            (props.getActionButton && actionButton.length > 4) &&
            <>
                <IconButton ref={iconButtonRef} className={classes.styleGetActionButton + ' root-BoxPopupAction-MoreIconButton'} onClick={handleClick}>
                    <MoreIcon style={{ color: '#FFF', margin: 'auto', transform: 'translate(-6%, 0%)', width: 20, height: 'auto' }} />
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {actionButton.slice(4).map((actionButton, index) => (
                            <IconButton
                                key={index}
                                variant="contained"
                                className={classes.buttonPopover}
                                onClick={() => actionButton.onclick()}
                                disabled={actionButton.disabled && actionButton.disabled}>
                                {actionButton.title ? actionButton.title : 'Bouton'}
                            </IconButton>
                        ))}
                    </Typography>
                </Popover>
            </>

        )
    }
    React.useEffect(() => {
        const actionButtonArray = [];
        for (let index in props.getActionButton) {
            if (props.getActionButton[index]) {
                actionButtonArray.push(props.getActionButton[index]);
            }
        }
        setActionButton(actionButtonArray);
    }, [props.getActionButton])

    React.useEffect(() => {

        let scrollTimeout;
        if (props.isBoxPopup) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setIsBoxPopupTransition(false);
                            dropActionPopup(true); setIsOpen(false);
                        } else setIsBoxPopupTransition(true);
                    });
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.3,
                }
            );

            if (divRef.current) observer.observe(divRef.current);

            const handleScroll = () => {
                if (divRef.current) {
                    const containerElement = divRef.current.offsetParent.parentElement;
                    const newScrollPosition = containerElement.scrollTop + 58;
                    const isScrolling = newScrollPosition > 0;
                    setScrolling(isScrolling);
                    clearTimeout(scrollTimeout);
                    scrollTimeout = setTimeout(() => {
                        setScrolling(false);
                    }, 300);
                }
            };

            const containerElement = divRef.current.offsetParent.parentElement;
            containerElement.addEventListener('scroll', handleScroll);

            return () => {
                containerElement.removeEventListener('scroll', handleScroll);
                clearTimeout(scrollTimeout);
                if (divRef.current) observer.unobserve(divRef.current);
            };
        }
    }, []);
    return (
        <div className={props.className}>
            <div ref={divRef} className='root-BoxAction'>
                <ShadowBoxComponent>
                    <div style={{ display: 'flex' }}>
                        {props.closedBoxActive &&
                            <IconButton style={{ margin: '0 10px', padding: 0, width: 30, height: 30, backgroundColor: 'rgba(0, 0, 0, 0.1)', }}
                                onClick={() => {
                                    setShowBox(!showBox);
                                    if (!showBox) {
                                        refBoxTemplate.current.style.height = refBoxTemplate.current.firstChild.offsetHeight + 'px';
                                        setTimeout(() => refBoxTemplate.current.style.height = 'auto', 400)
                                    } else {
                                        refBoxTemplate.current.style.height = refBoxTemplate.current.firstChild.offsetHeight + 'px';
                                        setTimeout(() => refBoxTemplate.current.style.height = '0px', 200);
                                    }
                                }}>
                                {showBox ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>
                        }
                        <div style={{ width: '100%' }} >{props.title && props.title}</div>
                    </div>
                    <div style={{ overflow: 'hidden', transition: 'all .4s', height: props.closedBoxActive ? '0px' : 'auto', }} ref={refBoxTemplate}>
                        <div className={'root-styleTemplate'}
                            style={{
                                transform: props.closedBoxActive ? (showBox ? 'translate(0%)' : 'translate(100%)') : 'translate(0%)',
                                opacity: props.closedBoxActive ? (showBox ? '1' : '0') : '1',
                                visibility: props.closedBoxActive ? (showBox ? 'visible' : 'hidden') : 'visible',
                                transition: 'all .4s',
                                padding: '10px 0',
                            }}>
                            {props.template && props.template}
                        </div>
                    </div>
                    {(props.getActionButton && actionButton.length > 0) &&
                        <div className='root-BoxButton' style={{ padding: '0', width: 'fit-content', margin: '0 0 0 auto', textAlign: 'end', }}>
                            {actionButton.map((actionButton, index) => (
                                <Button
                                    key={index}
                                    variant="contained"
                                    style={actionButton.style && actionButton.style}
                                    className={actionButton.className}
                                    color={actionButton.color && actionButton.color}
                                    onClick={() => actionButton.onclick()}
                                    disabled={actionButton.disabled && actionButton.disabled}>
                                    {actionButton.title ? actionButton.title : 'Bouton'}
                                </Button>
                            ))}
                        </div>
                    }
                </ShadowBoxComponent>
            </div>

            {(props.template && props.isBoxPopup) &&
                <Box className='root-BoxPopupAction'
                    style={{
                        position: 'fixed',
                        bottom: (props.direction && props.direction === 'bottom') && 34,
                        right: 23,
                        top: (props.direction && props.direction === 'top') && 77,
                        zIndex: 10, transition: 'all .5s',
                        opacity: isBoxPopupTransition ? 1 : 0,
                        visibility: isBoxPopupTransition ? 'visible' : 'hidden',
                        display: 'flex',
                        flexDirection: (props.direction && props.direction === 'bottom') ? 'column-reverse' : 'column',
                    }}>
                    <div style={{ display: 'flex', margin: '0 0 0 auto', width: 'fit-content', alignItems: 'center', }}>
                        <div className='root-getActionButton' style={{ padding: '0 10px 0 0', width: 'fit-content', display: 'flex' }}>
                            {viewAction()}
                            {(props.getActionButton && actionButton.length > 0) && actionButton.slice(0, 4).map((actionButton, index) => (
                                <Tooltip key={index} title={actionButton.title} placement="bottom">
                                    <Button
                                        variant="contained"
                                        ref={(button) => (getActionButtonRefs[index] = button)}
                                        className={classes.styleGetActionButton + ' root-BoxPopupAction-getActionButton' + index}
                                        style={actionButton.style && actionButton.style}
                                        color={actionButton.color && actionButton.color}
                                        onClick={() => actionButton.onclick()}
                                        disabled={actionButton.disabled && actionButton.disabled}>
                                        {actionButton.icon && actionButton.icon}
                                    </Button>
                                </Tooltip>
                            ))}
                        </div>
                        <Box
                            className={classes.styleButtonV1 + ' root-BoxPopupAction-Button'}
                            onClick={() => { setIsOpen(!isOpen); dropActionPopup() }}
                            ref={buttonRef}
                        >{props.textButton &&
                            <div style={props.textButton.style} className={classes.styleTextButton}>
                                {props.textButton.text && props.textButton.text}
                                <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '100%', position: 'relative', }}>
                                    <div style={{ opacity: isOpen ? 0 : 1, transition: 'all .3s', position: 'absolute', transform: 'translate(-50%,-50%)', left: '50%', top: isOpen ? '-50%' : '50%', display: 'flex', }}>{props.textButton.icon}</div>
                                    <CloseIcon style={{ color: '#FFF', margin: 'auto', transform: isOpen ? 'translate(0%, 0%)' : 'translate(0%, 150%)', width: 20, height: 'auto', opacity: isOpen ? 1 : 0, transition: 'all .3s', }} />
                                </div>
                            </div>
                            }
                        </Box>
                    </div>
                    <Box className={classes.popupV1 + ' root-BoxPopupAction-Popup'} style={{ display: isTimeOpen ? 'block' : 'none' }} ref={refPopup}>
                        <Box style={{ transition: 'all .5s', display: 'flex', opacity: 0, visibility: 'hidden' }} ref={refTemplate} >{props.template}</Box>
                    </Box>
                </Box>
            }
        </div>
    );
};

const useStyles = (theme, props, scrolling, isTimeOpen) => makeStyles(({
    popupV1: {
        transition: 'all .5s', overflow: 'hidden',
        display: 'flex',
        background: '#FFF',
        border: '2px solid #FFF',
        boxShadow: '0 0px 5px 0px rgba(0,0,0,0.3)',
        opacity: scrolling ? 0.7 : 1,
        margin: (props.direction && props.direction === 'bottom') ? '0 0 10px 0' : '10px 0 0 0',
        padding: '20px',
        borderRadius: 10,
        transform: 'translate(150%)',
        visibility: 'hidden',
        '& >div>div': {
            width: '100%',
            margin: 'auto',
            padding: 0,
            border: 'none',
            '& .rdw-editor-main': {
                minHeight: 100,
                background: '#FFFFFF',
            },
            '& .MuiGrid-item': {
                width: '100%',
                maxWidth: '100%',
                flexBasis: '100%',
            }
        },
    },
    styleButtonV1: {
        width: props.dimensionsButton.width, height: props.dimensionsButton.height,
        background: '#5E6E82',
        borderRadius: (props.direction && props.direction === 'top') ? '100px 30px 100px 100px' : '100px 100px 30px 100px',
        border: '2px solid #5E6E82',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 0px 5px 0px rgba(0,0,0,0.3)',
        transition: 'all .4s',
        '&:hover': {
            background: '#485464',
            border: '2px solid #485464',
        }
    },
    styleGetActionButton: {
        width: 'auto', height: props.dimensionsButton.height,
        minWidth: 'initial',
        background: '#5E6E82',
        borderRadius: 100,
        display: isTimeOpen ? 'flex' : 'none',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0 0px 5px 0px rgba(0,0,0,0.3) !important',
        opacity: '1 !important',
        transition: 'all .4s',
        animation: 'showSweetAlert 0.3s',
        transform: 'scale(0)',
        padding: '0 12px',
        margin: '0 5px auto !important',
        '&:hover': {
            background: '#485464',
        }
    },
    '@keyframes showSweetAlert': {
        '0%': {
            transform: 'scale(0.7)'
        },
        ' 45%': {
            transform: 'scale(1.05) '
        },
        '80%': {
            transform: 'scale(0.95)'
        },
        '100%': {
            transform: 'scale(1)'
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            boxShadow: '0 0px 5px 0px rgba(0,0,0,0.3) !important',
            marginLeft: -6,
        }
    },
    buttonPopover: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
    styleTextButton: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
}));

export default BoxPopupActionComponent;
