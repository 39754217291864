import React from 'react';
import { Switch, Route, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from "./Component/AppBar";
import { useSelector } from "react-redux";
import Footer from './Component/Footer';
import Optin from './Component/Optin';
import Api from "../../Api";

export default function LearnerLayout(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const history = useHistory();
    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            Api.cancelAll();
        });

        return () => {
            unlisten();
        };
    }, []);

    return (
        <div className={classes.learnerLayout}>

            {(authenticationReducer && !authenticationReducer.cgConsent && !authenticationReducer.isLoginAs) && <Optin />}

            {/* <== AppBar */}
            {!screenReducer.full && <AppBar {...props} />}
            {/* AppBar ==> */}

            <div id='layout' style={screenReducer.full ?
                { overflow: 'initial', position: 'absolute', width: '100%', display: 'block' } :
                { overflow: 'scroll' }}
            >
                <Switch>
                    {
                        props.routes.map((route) => (
                            <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes} />} />
                        ))
                    }
                </Switch>
                {!props.match.params.course && <Footer {...props} />}
            </div>
        </div>
    );
}

const useStyles = makeStyles({
    learnerLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'grid',
        gridTemplateRows: 'max-content',
        '& #layout': {
            background: '#FCFCFC ',
            scrollBehavior: 'smooth',
            display: 'flex',
            flexDirection: ' column',
            height: '100%',
            '& >div': {
                width: '100%',
                margin: '0 auto'
            }
        }
    }
});
