import React from 'react';
import { Breadcrumbs as BreadcrumbsMUI, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { NavigateNext } from "@material-ui/icons";


export default function BreadcrumbsComponent(props = {
    breadcrumbs: { currentPage: '', links: [] }
}) {

    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);


    return (
        <div className={`${classes.breadcrumbs} ${isSmallView && classes.breadcrumbsSmall}`}>
            <BreadcrumbsMUI separator={<NavigateNext className={'icon-bc'} style={{ margin: 0, cursor: 'default' }} />} aria-label="breadcrumb">

                {
                    props.breadcrumbs.links && props.breadcrumbs.links.map((link, key) => {
                        return (
                            <Typography key={key} onClick={() => props.history.push(link.path)} style={{ cursor: 'pointer', fontWeight: 700, fontSize: isSmallView ? 14 : 16 }}>
                                {link.label}
                            </Typography>
                        )
                    })
                }
                <Typography style={{ fontWeight: 500, fontSize: isSmallView ? 14 : 16 }}>
                    {props.breadcrumbs.currentPage}
                </Typography>
            </BreadcrumbsMUI>
        </div>

    );
}

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        color: '#5E6E82',
        width: '100%',
        boxSizing: 'border-box',
        background: '#c8dfe5',
        display: 'flex',
        alignItems: 'center',
        height: 'initial !important',
        '& .icon-bc': {
            color: '#5E6E82',
            fontSize: 18,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
            width: '80%',
            margin: 'auto',
            height: 60,
            display: 'flex',
        }
    },
    breadcrumbsSmall: {
        padding: 5,
        height: 100,
        '& .icon-bc': {
            color: '#5E6E82',
            fontSize: 14,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
            width: '80%',
            margin: '15px auto'
        }
    }
}));
