import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment } from "@material-ui/icons";
import { getRoutePathname } from '../../../Config/Route';

function CommercialUsersManagementList(props) {
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "username",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    // Action
    const getAction = (row) => {
        return (
            <div style={{ minWidth: 90 }}>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('crm_commercial_users_management', { id: row.id }));
                    }}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestion utilisateurs commercial',
            context: 'CRM',
            description: 'Liste des utilisateurs.'
        });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'crm_commercial_users_management_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Commerciaux'}
                columns={columns}
                promiseData={(resolve) => {
                    Api.get({
                        route: 'crm_commercial_users_management_list',
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />
        </ContentViewComponent>
    );
}

export default CommercialUsersManagementList;
