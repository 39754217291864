import React, { useRef } from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Drawer as DrawerMUI } from "@material-ui/core";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../../Component/SelectComponent";
import { TableReload } from "../../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import { inArray } from "../../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import moment from 'moment';
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from "@material-ui/core";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import DateTimePickerComponent from "../../../../Component/DateTimePickerComponent";
import { hasRight } from "../../../../Tool/UserTool";
import Button from "@material-ui/core/Button";
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import FlipCardComponent from '../../../../Component/FlipCardComponent';
import RangeComponent from '../../../../Component/RangeComponent';
import SendIcon from '@material-ui/icons/Send';
import { getRoutePathname } from '../../../../Config/Route';

function LinearProgressWithLabel(props) {

    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function RelaunchList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const screenReducer = useSelector(state => state.ScreenReducer);

    const [isTall, setIsTall] = React.useState(true);
    const [idEdit, setIdEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [loadDownload, setLoadDownload] = React.useState(false);
    const [showFiltre, setShowFiltre] = React.useState(false);
    const [flip, setFlip] = React.useState(false);
    const [buttonflipText, setButtonFlipText] = React.useState('Choisir par mois');
    const [relaunchType, setRelaunchType] = React.useState(null);
    const [checkBoxDisable, setCheckBoxDisable] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionFinanceTypes, setFinanceTypes] = React.useState([]);
    //const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionRegistrationOrigins, setOptionRegistrationOrigins] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);

    const optionStates = [
        { value: 1, label: 'Terminer' },
        { value: 2, label: 'Annulée' },
        { value: 3, label: 'Supprimée' },
        { value: 4, label: 'Facturée' },
        { value: 5, label: 'Non terminée' },
        { value: 6, label: 'Pré-inscription' },
        { value: 7, label: 'Facturée partiellement' },
    ];
    const [optionEndMonth, setOptionEndMonth] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Janvier' },
        { value: 2, label: 'Février' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Avril' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juin' },
        { value: 7, label: 'Juillet' },
        { value: 8, label: 'Août' },
        { value: 9, label: 'Septembre' },
        { value: 10, label: 'Octobre' },
        { value: 11, label: 'Novembre' },
        { value: 12, label: 'Décembre' },
    ]);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        // {
        //     name: "groupFormationName",
        //     label: "Groupe de formation",
        //     options: { filter: false, sort: true }
        // },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "code",
            label: "Numéro de programme",
            options: { filter: true, sort: true }
        },
        {
            name: "commercialName",
            label: "Commercial",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeName",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: false, sort: false }
        },
        {
            name: "stateRule",
            label: "État régule",
            options: { filter: false, sort: false }
        },
        {
            name: "lastRelaunchAt",
            label: "Relance",
            options: { filter: true, sort: true }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "origin",
            label: "Origine de la registration",
            options: { filter: true, sort: true }
        },
        {
            name: "billingPrice",
            label: "Prix facturé",
            options: { filter: true, sort: true }
        },
        {
            name: "originalPrice",
            label: "Prix initial",
            options: { filter: true, sort: true }
        },
        {
            name: "fcEndAt",
            label: "Date de fin formation continue",
            options: { filter: true, sort: true }
        },
        {
            name: "eppLastStageAt",
            label: "Date de début Epp 2",
            options: { filter: true, sort: true }
        },
        {
            name: "hasDocument",
            label: "Documents fournis",
            options: { filter: false, sort: false }
        },
        {
            name: "hasPaid",
            label: "Paiement effectué",
            options: { filter: false, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <div style={{ minWidth: 90 }}>
                <Tooltip title={'Relance marketing'} placement="left">
                    <IconButton onClick={() => {
                        handler.reset();
                        handler.setValue('relaunchAt', moment())
                        setIdEdit(row.id);
                        setRelaunchType(2);
                    }}>
                        <SendIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };
    const getCheckBox = (key, row) => {
        const checkBoxTemplate =
            (row[key] !== null) ?
                <Checkbox checked={row[key]} onChange={(e, val) => {
                    let payload = {};
                    payload[key] = val;
                    Api.post({
                        route: 'institution_health_crm_relaunch_registration_conditions',
                        data: payload,
                        params: { id: row.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            dispatch(TableReload('institution_health_crm_relaunch_registration_list'));
                            setLoading(false);

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.data.message,
                                        variant: 'success',
                                    })
                                );
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }} />
                :
                '-';
        return checkBoxTemplate;
    };

    // Form
    const [form, setForm] = React.useState({
        relaunchAt: {
            name: 'relaunchAt',
            label: 'Date de la relance',
            textHelper: 'Choisissez une date de la relance.',
            type: 'datetime',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Ajouter un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const [conditionForm, setConditionForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    const conditionHandler = formHandlerInit(conditionForm, setConditionForm);
    React.useEffect(conditionHandler.start, []);
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            let route = '';
            switch (relaunchType) {
                case 1:
                    route = 'crm_relaunch_registration_phone';
                    break;
                case 2:
                    route = 'institution_health_crm_relaunch_registration_marketing';
                    break;
            }
            let data = handler.getData();
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                route: route,
                data: { relaunchAt: data['relaunchAt'], comment: data['comment'] },
                params: { id: idEdit }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('institution_health_crm_relaunch_registration_list'));
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        setIdEdit(null);
                        setRelaunchType(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Relance enregistré.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'array',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].commercial ? cacheTableServer['crm_prospect_list'].commercial : '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].organism ? cacheTableServer['institution_health_crm_relaunch_registration_list'].organism : 'all',
            options: {}
        },
        // formationGroup: {
        //     name: 'formationGroup',
        //     label: 'Groupe de formation',
        //     textHelper: 'Trier par groupe de formation.',
        //     type: 'text',
        //     defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].formationGroup ? cacheTableServer['institution_health_crm_relaunch_registration_list'].formationGroup : 'all',
        //     options: {}
        // },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Trier par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['crm_prospect_list'] && cacheTableServer['crm_prospect_list'].pole ? cacheTableServer['crm_prospect_list'].pole : 'all',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].startAt ? cacheTableServer['institution_health_crm_relaunch_registration_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].endAt ? cacheTableServer['institution_health_crm_relaunch_registration_list'].endAt : '',
            options: {}
        },
        monthEndAt: {
            name: 'monthEndAt',
            label: 'Mois de fin de session.',
            textHelper: 'Choisissez un mois.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].monthEndAt ? cacheTableServer['institution_health_crm_relaunch_registration_list'].monthEndAt : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].year ? cacheTableServer['institution_health_crm_relaunch_registration_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].financeType ? cacheTableServer['institution_health_crm_relaunch_registration_list'].financeType : 'all',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].andpc ? cacheTableServer['institution_health_crm_relaunch_registration_list'].andpc : '',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez un email.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].email ? cacheTableServer['institution_health_crm_relaunch_registration_list'].email : '',
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez un prénom.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].firstname ? cacheTableServer['institution_health_crm_relaunch_registration_list'].firstname : '',
            options: {}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez un nom.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].lastname ? cacheTableServer['institution_health_crm_relaunch_registration_list'].lastname : '',
            options: {}
        },
        origin: {
            name: 'origin',
            label: 'Origine',
            textHelper: 'Trier par origine.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].origin ? cacheTableServer['institution_health_crm_relaunch_registration_list'].origin : 'all',
            options: {}
        },
        eppLastStageAt: {
            name: 'eppLastStageAt',
            label: 'Date de début EPP 2.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].eppLastStageAt ? cacheTableServer['institution_health_crm_relaunch_registration_list'].eppLastStageAt : '',
            options: {}
        },
        fcEndAt: {
            name: 'fcEndAt',
            label: 'Date de fin de formation continue.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].fcEndAt ? cacheTableServer['institution_health_crm_relaunch_registration_list'].fcEndAt : '',
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'array',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].state ? cacheTableServer['institution_health_crm_relaunch_registration_list'].state : '',
            options: {}
        },
        minCompletion: {
            name: 'minCompletion',
            label: 'Complétion Min',
            textHelper: 'Trier par completion.',
            type: 'integer',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].minCompletion ? cacheTableServer['institution_health_crm_relaunch_registration_list'].minCompletion : 0,
            options: { rangeMin: 0, rangeMax: 100 }
        },
        maxCompletion: {
            name: 'maxCompletion',
            label: 'Complétion Max',
            textHelper: 'Trier par completion.',
            type: 'integer',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].maxCompletion ? cacheTableServer['institution_health_crm_relaunch_registration_list'].maxCompletion : 100,
            options: { rangeMin: 0, rangeMax: 100 }
        },
        noRelaunchSince: {
            name: 'noRelaunchSince',
            label: 'Pas de relance marketing depuis le :',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['institution_health_crm_relaunch_registration_list'] && cacheTableServer['institution_health_crm_relaunch_registration_list'].noRelaunchSince ? cacheTableServer['institution_health_crm_relaunch_registration_list'].noRelaunchSince : '',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_crm_relaunch_registration_list'] = 0;
        cacheTableServer['institution_health_crm_relaunch_registration_list'].page = 0;
        cacheTableServer['institution_health_crm_relaunch_registration_list'][index] = value;
        dispatch(TableReload('institution_health_crm_relaunch_registration_list'))
    };

    // Height filters content
    const gridRef = useRef(null);
    const getHeightFilters = () => {
        if (gridRef.current) {
            return gridRef.current.clientHeight
        }
    };

    const getDownloadExcel = () => {
        let data = handlerSearch.getData();
        setLoadDownload(true);
        Api.getDownload({
            route: 'institution_health_crm_relaunch_registration_list', filename: 'export_liste_relance.xls', data: {
                andpc: data.andpc ? data.andpc : '',
                commercial: (data.commercial === 'all' || !data.commercial) ? '' : data.commercial,
                email: data.email ? data.email : '',
                firstname: data.firstname ?? '',
                lastname: data.lastname ?? '',
                endAt: data.endAt ? data.endAt : '',
                monthEndAt: (data.monthEndAt === 'all') ? '' : data.monthEndAt,
                //formationGroup: (data.formationGroup === 'all') ? '' : data.formationGroup,
                organism: selectOrganismReducer.organism.value,
                pole: (data.pole === 'all') ? '' : data.pole,
                financeType: (data.financeType === 'all') ? '' : data.financeType,
                startAt: data.startAt ? data.startAt : '',
                year: data.year ? data.year : 2022,
                origin: (data.origin === 'all') ? '' : data.origin,
                eppLastStageAt: data.eppLastStageAt ? data.eppLastStageAt : '',
                fcEndAt: data.fcEndAt ? data.fcEndAt : '',
                state: (data.state === '' || !data.state) ? '' : data.state,
                minCompletion: data.minCompletion === null ? '0' : data.minCompletion ? data.minCompletion : '',
                maxCompletion: data.maxCompletion === null ? '0' : data.maxCompletion ? data.maxCompletion : '',
                noRelaunchSince: data.noRelaunchSince ?? '',
            }
        }, (response) => {
            if (response.status === 200) {
                setLoadDownload(false)
            }
        })
    }
    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0, position: 'relative', zIndex: 23, background: '#FFFFFF', }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceTypes} onChange={(val) => inputSearchChange('financeType', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MultipleSelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => inputSearchChange('commercial', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'origin'} handler={handlerSearch} options={optionRegistrationOrigins} onChange={(val) => inputSearchChange('origin', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MultipleSelectComponent name={'state'} handler={handlerSearch} options={optionStates} onChange={(val) => inputSearchChange('state', val)} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'fcEndAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('fcEndAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'eppLastStageAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('eppLastStageAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'noRelaunchSince'} handler={handlerSearch} onChange={(val) => inputSearchChange('noRelaunchSince', val)} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'andpc'} handler={handlerSearch} onChange={(val) => inputSearchChange('andpc', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'firstname'} handler={handlerSearch} onChange={(val) => inputSearchChange('firstname', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'lastname'} handler={handlerSearch} onChange={(val) => inputSearchChange('lastname', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'email'} handler={handlerSearch} onChange={(val) => inputSearchChange('email', val)} />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <RangeComponent name={'minCompletion'} handler={handlerSearch} onChange={(val) => inputSearchChange('minCompletion', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <RangeComponent name={'maxCompletion'} handler={handlerSearch} onChange={(val) => inputSearchChange('maxCompletion', val)} />
                    </Grid>

                    {/*<Grid item xs={12} sm={12} md={12} lg={12}>*/}
                    {/*    <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />*/}
                    {/*</Grid>*/}
                </Grid>

                <Grid container spacing={2} style={{ width: '100%', margin: 0 }} className={'root-flipCard'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ position: 'relative', zIndex: 23, background: '#FFFFFF', }}>
                        <Button
                            color="primary"
                            onClick={
                                () => {
                                    if (flip) {
                                        handlerSearch.setValue('monthEndAt', 'all')
                                        setButtonFlipText('Choisir par mois')

                                    }
                                    else {
                                        handlerSearch.setValue('startAt', null)
                                        handlerSearch.setValue('endAt', null)
                                        setButtonFlipText('Choisir par periode')
                                    }
                                    dispatch(TableReload('institution_health_crm_relaunch_registration_list'));
                                    setFlip(!flip);
                                }
                            }
                        >{buttonflipText}</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FlipCardComponent
                            flip={flip}
                            frontCard={
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                                    </Grid>
                                </Grid>
                            }
                            backCard={
                                <SelectComponent name={'monthEndAt'} handler={handlerSearch} options={optionEndMonth} onChange={(val) => inputSearchChange('monthEndAt', val)} />
                            }
                        />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const table = () => {
        return (
            <>
                <TableComponent
                    id={'institution_health_crm_relaunch_registration_list'}
                    minHeight={'calc(100vh - 515px)'}
                    title={'Inscriptions'}
                    columns={columns}
                    actionFirst={(hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_ADMIN')) && {
                        label: 'Télécharger au format Excel',
                        disabled: loadDownload,
                        onClick: () => getDownloadExcel()
                    }}
                    search={false}
                    promiseServerData={(resolve, options) => {
                        let datas = handlerSearch.getData();
                        let filters = {
                            limit: options.rowsPerPage,
                            offset: options.page * options.rowsPerPage,
                            sortName: options.sortOrder.name,
                            sortDirection: options.sortOrder.direction,
                            year: datas.year ?? currentYear,
                            startAt: datas.startAt ?? '',
                            andpc: datas.andpc ?? '',
                            endAt: datas.endAt ?? '',
                            monthEndAt: (datas.monthEndAt === 'all') ? '' : datas.monthEndAt,
                            commercial: (datas.commercial === '' || !datas.commercial) ? '' : datas.commercial,
                            pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                            organism: selectOrganismReducer.organism.value,
                            formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                            financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                            email: datas.email ?? '',
                            firstname: datas.firstname ?? '',
                            lastname: datas.lastname ?? '',
                            origin: (datas.origin === 'all') ? '' : datas.origin,
                            eppLastStageAt: datas.eppLastStageAt ? datas.eppLastStageAt : '',
                            fcEndAt: datas.fcEndAt ? datas.fcEndAt : '',
                            state: (datas.state === '' || !datas.state) ? '' : datas.state,
                            minCompletion: datas.minCompletion === null ? '0' : datas.minCompletion ? datas.minCompletion : '',
                            maxCompletion: datas.maxCompletion === null ? '0' : datas.maxCompletion ? datas.maxCompletion : '',
                            noRelaunchSince: datas.noRelaunchSince ?? '',
                        };
                        Api.get({
                            route: 'institution_health_crm_relaunch_registration_list',
                            data: filters
                        },
                            (response) => {
                                let data = response.data.data;
                                setCheckBoxDisable(false)
                                for (let index in data) {
                                    data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                    data[index].billingPrice = data[index].billingPrice ? data[index].billingPrice + ' €' : '-';
                                    data[index].originalPrice = data[index].originalPrice ? data[index].originalPrice + ' €' : '-';
                                    data[index].lastRelaunchAt = data[index].relaunchs.length > 0 ? <div style={{ width: 140 }}>
                                        <Tooltip title={
                                            <span>{data[index].relaunchs.map((relaunch, i) =>
                                                <div key={i}>
                                                    <p style={{ marginBottom: 0 }}>{relaunch.by} : Relance {relaunch.type} le {moment(relaunch.date).format('lll')}</p>
                                                    {relaunch.comment && <p style={{ margin: 0, marginLeft: 10 }}>*Commentaire : {relaunch.comment}</p>}
                                                </div>)}
                                            </span>} placement="left">
                                            <Info style={{ display: 'inline-block', verticalAlign: 'bottom', margin: '0 5px', color: '#bfc5cb' }} />
                                        </Tooltip>
                                        <span>{data[index].lastRelaunchAt ? moment(data[index].lastRelaunchAt).format('lll') : ''}</span>
                                    </div> : '-';
                                    switch (data[index]['state']) {
                                        case 1:
                                            data[index]['stateText'] = <span style={{ color: '#28A745' }}>Validée</span>;
                                            break;
                                        case 2:
                                            data[index]['stateText'] = <span style={{ color: data[index].billingPrice === '-' ? '#AD732C' : 'rgb(220, 53, 69)' }}>Annulée</span>;
                                            break;
                                        case 3:
                                            data[index]['stateText'] = <span style={{ color: '#DC3545' }}>Supprimée</span>;
                                            break;
                                        case 4:
                                            data[index]['stateText'] = <span style={{ color: '#35A2EB' }}>Facturée</span>;
                                            break;
                                        case 5:
                                            data[index]['stateText'] = <span style={{ color: '#000000' }}>Non terminée</span>;
                                            break;
                                        case 6:
                                            data[index]['stateText'] = <span style={{ color: '#67327b' }}>Temps manquant</span>;
                                            break;
                                        case 7:
                                            data[index]['stateText'] = <span style={{ color: '#000000' }}>Pré-inscription</span>;
                                            break;
                                        case 8:
                                            data[index]['stateText'] = <span style={{ color: '#AD732C' }}>Facturée partiellement</span>;
                                            break;
                                    }
                                    data[index].action = getAction(data[index]);
                                    data[index].hasDocument = getCheckBox('hasDocument', data[index]);
                                    data[index].hasPaid = getCheckBox('hasPaid', data[index]);
                                    data[index].code = data[index].code ? data[index].code : '-';
                                    data[index].eppLastStageAt = data[index].eppLastStageAt ? moment(data[index].eppLastStageAt).format('ll') : '-';
                                    data[index].fcEndAt = data[index].fcEndAt ? moment(data[index].fcEndAt).format('ll') : '-';
                                }
                                resolve(data, response.data.count);
                            });
                    }}
                />
            </>
        )
    }

    const GetAction = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Suivi des inscriptions',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des inscriptions.',
            html: <GetAction />
        });
    }, [isOpen]);

    React.useEffect(() => {
        // Year
        let yearsTMP = [];
        yearsTMP.push({ label: (currentYear + 1), value: (currentYear + 1) });
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
                route: 'institution_health_crm_commercial_list',
                data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([{ value: 0, label: 'Aucun' }, ...response.data]);
                }
            });
        Api.get({
            route: 'select_poles'
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        data.push(response.data[index]);
                    }
                    setFinanceTypes(data);
                }
            });

        // Api.get({
        //         route: 'select_formation_groups'
        //     },
        //     (response) => {
        //         if (response && response.data) {
        //             let data = [{ value: 'all', label: 'Tous' }, ...response.data];
        //             setOptionFormationGroup(data);
        //         }
        //     });

        Api.get({
            route: 'select_registration_origins'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionRegistrationOrigins(data);
                }
            });

        setReadyContent(true);
    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <>
            <Box className={classes.styleContentView}>
                <DrawerMUI
                    variant="permanent"
                    className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                    style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                    open={isOpen}>
                    {filter()}
                </DrawerMUI>
                <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                    <Box className={classes.TableComponent}>
                        {table()}
                    </Box>
                </ContentViewComponent>
            </Box>
            <Dialog open={idEdit}>
                <DialogTitle style={{ fontSize: 15 }}>Inscription - {idEdit}</DialogTitle>
                <DialogContent>
                    <div>
                        <DateTimePickerComponent name={'relaunchAt'} handler={handler} />
                        <TextFieldComponent name={'comment'} handler={handler} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setIdEdit(null)} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>
        </>
    );
}
const drawerOpenWidth = 340;
const drawerCloseWidth = 0;

const useStyles = makeStyles(style => ({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    iconToggle: {
        marginRight: 10,
        marginBottom: 10,
        padding: 0,
        fontSize: '1.1rem',
        color: '#5E6E82',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#fff',
        },
    },
    boxPopupAction: {
        '& .root-BoxPopupAction-Popup': {
            maxWidth: 700,
            height: 'auto',
            '& .MuiGrid-item': {
                maxWidth: 'initial',
                flexBasis: '33%',
            },
            '& .root-flipCard': {
                '& >div:nth-of-type(1)': {
                    flexBasis: '75%',
                    '& >div>div>div>div>div': {
                        flexBasis: '50%',
                    }
                },
                '& >div:nth-of-type(2)': {
                    flexBasis: '25%',
                    padding: '8px 0',
                    textAlign: 'center',
                }
            }
        }
    },
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
}))

export default RelaunchList;