export function hourBySecond(s, type = '') {
    let hours = Math.floor(s / 60 / 60);
    let minutes = Math.floor(s / 60 % 60);
    let seconds = Math.round(s % 60);

    return <>
        {hours > 0 && (hours + (type === 'long' ? (hours > 1 ? ' heures' : ' heure') : 'h'))}
        {minutes > 0 && (minutes + (type === 'long' ? (minutes > 1 ? ' minutes' : ' minute') : 'm'))}
        {seconds > 0 && (seconds + (type === 'long' ? (seconds > 1 ? ' secondes' : ' seconde') : 's'))}
    </>
}

export function hourBySecondClassic(s) {
    let hours = Math.floor(s / 60 / 60);
    let minutes = Math.floor(s / 60 % 60);
    let seconds = Math.round(s % 60);

    return <>{hours < 10 ? ('0' + hours ) : hours}:{minutes < 10 ? ('0' + minutes) : minutes}:{seconds < 10 ? ('0' + seconds) : seconds}</>
}
