import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import SelectComponent from "../../../Component/SelectComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import SweetAlert from 'sweetalert-react';
import { TableReload } from "../../../Action/TableAction";
import { getRoutePathname } from "../../../Config/Route";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import moment from "moment";




export default function HubspotQueueList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dialogLog, setDialogLog] = React.useState(false);
    const [logsInfo, setLogsInfo] = React.useState('');
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [optionProspectOrigin, setOptionProspectOrigin] = React.useState([]);
    const [optionRegistrationOrigin, setOptionRegistrationOrigin] = React.useState([]);
    const [actionRow, setActionRow] = React.useState({});
    const [optionYears, setOptionYears] = React.useState([]);

    const optionEvent = [
        { value: 'all', label: 'Tous' },
        { value: 'create', label: 'Création' },
        { value: 'update', label: 'Mise à jour' }
    ];

    const optionStatus = [
        { value: 'all', label: 'Tous' },
        { value: 'pending', label: 'Non traité' },
        { value: 'inProgress', label: 'En cours' },
        { value: 'success', label: 'Succès' },
        { value: 'error', label: 'Erreur' }
    ];

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "nature",
            label: "Prospect / registration",
            options: { filter: true, sort: true }
        },
        {
            name: "event",
            label: "Évenement",
            options: { filter: true, sort: true }
        },
        {
            name: "origin",
            label: "Origine",
            options: { filter: true, sort: true }
        },
        {
            name: "status",
            label: "Status",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        let logsBtn = null;
        if (row.logs) {
            logsBtn = (
                <Tooltip title={'Logs'} placement="left">
                  <IconButton onClick={() => {
                    setLogsInfo(row.logs);
                    setDialogLog(true);
                  }}>
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
              );
        }
        return (
            <>
                <Tooltip title={'Accéder'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', {id: row.prospectId}))}>
                        <AccountBoxIcon />
                    </IconButton>
                </Tooltip>
                {logsBtn}
            </>
        );
    };
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].year ? cacheTableServer['marketing_manager_hubspot_queue_list'].year : currentYear,
            options: {}
        },
        prospect: {
            name: 'prospect',
            label: 'Email du prospect',
            textHelper: "Saisissez l'e-mail du prospect.",
            type: 'email',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].prospect ? cacheTableServer['marketing_manager_hubspot_queue_list'].prospect : '',
            options: {}
        },
        registration: {
            name: 'registration',
            label: 'Registration',
            textHelper: 'Saisissez une référence de registration.',
            type: 'integer',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].registration ? cacheTableServer['marketing_manager_hubspot_queue_list'].registration : '',
            options: {}
        },
        prospectOrigin: {
            name: 'prospectOrigin',
            label: 'Origine du prospect',
            textHelper: "choisissez l'Origine",
            type: 'integer',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].prospectOrigin ? cacheTableServer['marketing_manager_hubspot_queue_list'].prospectOrigin : 'all',
            options: {}
        },
        registrationOrigin: {
            name: 'registrationOrigin',
            label: 'Origine de la registration',
            textHelper: "choisissez l'Origine",
            type: 'integer',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].registrationOrigin ? cacheTableServer['marketing_manager_hubspot_queue_list'].registrationOrigin : 'all',
            options: {}
        },
        event: {
            name: 'event',
            label: 'Évenement',
            textHelper: 'Trier par évenement.',
            type: 'text',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].event ? cacheTableServer['marketing_manager_hubspot_queue_list'].event : 'all',
            options: {}
        },
        createdAt: {
            name: 'createdAt',
            label: 'Date de création',
            textHelper: 'Trier par date de création.',
            type: 'date',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].createdAt ? cacheTableServer['marketing_manager_hubspot_queue_list'].createdAt : 'all',
            options: {}
        },
        status: {
            name: 'status',
            label: 'Status',
            textHelper: 'Trier par status.',
            type: 'text',
            defaultValue: cacheTableServer['marketing_manager_hubspot_queue_list'] && cacheTableServer['marketing_manager_hubspot_queue_list'].status ? cacheTableServer['marketing_manager_hubspot_queue_list'].status : 'all',
            options: {}
        }
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['marketing_manager_hubspot_queue_list'] = 0;
        cacheTableServer['marketing_manager_hubspot_queue_list'].page = 0;
        cacheTableServer['marketing_manager_hubspot_queue_list'][index] = value;
        dispatch(TableReload('marketing_manager_hubspot_queue_list'))
    };

    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: "Liste d'attente Hubspot",
            context: 'Gestion marketing',
            description: ''
        });
        
        // Year
        let yearsTMP = [];
        const nextYear = currentYear + 1;
        yearsTMP.push({ label: nextYear, value: nextYear });
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'select_registration_origins'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionRegistrationOrigin(data);
                }
            });

        Api.get({
            route: 'select_prospect_origins'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionProspectOrigin(data);
                }
            });

        setReadyContent(true);
    }, []);

    const getDatas = (resolve, filters) => {
        Api.get({
            route: 'marketing_manager_hubspot_queue_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {
                    data[index].action = getAction(data[index]);
                    data[index].event =  getLabelByValueInArray(optionEvent, data[index].event);
                    data[index].status =  getLabelByValueInArray(optionStatus, data[index].status);
                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                }
                resolve(data, response.data.count);
            });
    }

    const getLabelByValueInArray = (array ,value) => {
        let result = '';
        array.forEach(el => {
            if (el.value === value) {
                result = el.label;
            }
        })
        return result;
    }

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                        <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'event'} handler={handlerSearch} options={optionEvent} onChange={(val) => inputSearchChange('event', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'status'} handler={handlerSearch} options={optionStatus} onChange={(val) => inputSearchChange('status', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextFieldComponent name={'prospect'} handler={handlerSearch} onChange={(val) => inputSearchChange('prospect', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <TextFieldComponent name={'registration'} handler={handlerSearch} onChange={(val) => inputSearchChange('registration', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'prospectOrigin'} handler={handlerSearch} options={optionProspectOrigin} onChange={(val) => inputSearchChange('prospectOrigin', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <SelectComponent name={'registrationOrigin'} handler={handlerSearch} options={optionRegistrationOrigin} onChange={(val) => inputSearchChange('registrationOrigin', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>
            <br />
            <TableComponent
                id={'marketing_manager_hubspot_queue_list'}
                title={"Liste d'attente Hubspot"}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        event: (datas.event === 'all') ? null : datas.event,
                        status: (datas.status === 'all') ? null : datas.status,
                        prospect: (datas.prospect === '') ? null : datas.prospect,
                        registration: (datas.registration === '') ? null : datas.registration,
                        prospectOrigin: (datas.prospectOrigin === 'all') ? null : datas.prospectOrigin,
                        registrationOrigin: (datas.registrationOrigin === 'all') ? null : datas.registrationOrigin,
                    };

                    getDatas(resolve, filters)
                }}
                search={false}
            />

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.name + '\nÊtes-vous sur de vouloir supprimer cet session ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'administrator_session_delete',
                        params: { id: actionRow.id }
                    },
                        () => {
                            dispatch(TableReload('marketing_manager_hubspot_queue_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

            <br />

            <Dialog open={dialogLog} maxWidth={'lg'} className={classes.DialogBox} onClose={() => setDialogLog(false)}>
                <p style={{ fontSize: '1.25rem', color: '#5E6E82', margin: 0, padding: '16px 24px 0px 24px', fontWeight: 'bold' }}>Logs</p>
                <div className={classes.Box}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p style={{ padding: '1rem' }}>{logsInfo}</p> 
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => setDialogLog(false)}/>
                </DialogActions>
            </Dialog>

        </ContentViewComponent>
    );
}
const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    DialogBox: {
        '& .MuiDialog-paper': {
            width: '700px',
        },
    },
    Box: {
        padding: '0 37px 20px 37px',
        color: '#5E6E82',
        '& span': {
            fontWeight: 'bold',
        },
        '& p': {
            margin: '0px',
        },
    },
});