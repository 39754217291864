import React, {useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import { Fade, makeStyles } from '@material-ui/core';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";

const colors = [
    'rgba(57, 181, 224, 0.4)',
    'rgba(0, 255, 221, 0.4)',
    'rgba(93, 156, 89, 0.4)',
    'rgba(249, 76, 16, 0.4)',
    'rgba(197, 22, 5, 0.4)',
];

const borderColor = [
    'rgb(57, 181, 224)',
    'rgb(0, 255, 221)',
    'rgb(93, 156, 89)',
    'rgb(249, 76, 16)',
    'rgb(197, 22, 5)',
];

const BarChartComponent = (props) => {
    const classes = useStyles();
    const [dataSets, setDataSets] = React.useState([]);
    const [allDataSets, setAllDataSets] = React.useState([]);
    const [callbackData, setCallbackData] = React.useState([]);
    const [dataLabels, setDataLabels] = React.useState();

    React.useEffect(() => {
        if ((props.data && props.data.length > 0)) {
            const dataLabelsTMP = Object.keys(props.data[0]).filter((key) => {
                return key !== "callback" && key !== "label";
            });

            let datasetsTMP = [];
            props.data.forEach(data => {
                let dataValue = []
                Object.keys(data).forEach(key => {
                    if ((key !== "callback" && key !== "label")) {
                        dataValue.push(data[key]);
                    }
                });
                datasetsTMP.push({
                    label: data['label'],
                    data: dataValue,
                    backgroundColor: colors,
                    borderColor: borderColor,
                    borderWidth: 1,
                });
            });
            const displayData = datasetsTMP.find(e => e.label === props.group)
            displayData ? setDataSets([displayData]) : setDataSets([])

            setAllDataSets(datasetsTMP);
            setDataLabels(dataLabelsTMP);

            const callbackTMP = props.data.find(e => e.label === props.group)
            callbackTMP ?  setCallbackData(callbackTMP.callback) :  setCallbackData([])
        }
        else {
            setDataLabels(['Aucune données trouvé.']);
        }
    }, [props.data]);

    useEffect(() => {
        if ((allDataSets && allDataSets.length > 0)) {
            const displayData = allDataSets.find(e => e.label === props.group)
            displayData ? setDataSets([displayData]) : setDataSets([])
            const callbackTMP = props.data.find(e => e.label === props.group)
            callbackTMP ?  setCallbackData(callbackTMP.callback) :  setCallbackData([])
        }
    }, [allDataSets, props.group]);

    const chartData = {
        responsive: true,
        maintainAspectRatio: false,
        labels: dataLabels,
        datasets: dataSets,
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: props.title ? props.title : '',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 15
            },
            tooltips: {
                enabled: (dataSets && dataSets.length > 0) ? true : false,
                titleAlign: 'center',
                callbacks: (dataSets && dataSets.length > 0) ? {
                    title: (item) => {
                        let index = item[0].index;
                        return dataSets.label;
                    },
                    beforeBody: (item) => {
                        let index = item[0].index;
                        let datasetIndex = item[0].datasetIndex;
                        return callbackData[index];
                    }
                } : {},
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
        }
    }

return (
    <>
        <div className={classes.container} style={{ transition: 'background-color 1.5s ease', background: props.loading ? '#DADADA81' : '' }}>
            {(props.loading) &&
                <Fade in={props.loading} {...{ timeout: 1000 }}>
                    <div className={classes.loader}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 600 }}>chargement</span>
                            <UseAnimations animation={activity} size={30} />
                        </div>
                    </div>
                </Fade>
            }
            <Bar data={chartData} options={chartData.options} />
        </div>
    </>
);
};

const useStyles = makeStyles({
container: {
    backgroundColor: '#fff',
    borderRadius: 5,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    color: '#000000',
    position: 'relative',
},
loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%',
    height: '100%'
}
});

export default BarChartComponent;
