const SnackbarReducer = (state = {}, action) => {
    switch (action.type) {
        case 'SnackbarOpen':
            return {
                ...state,
                isOpen: true,
                text: action.text,
                variant: action.variant,
                duration: action.duration
            };
        case 'SnackbarClose':
            return {
                ...state,
                isOpen: false
            };
        default:
            return state
    }
};

export default SnackbarReducer;
