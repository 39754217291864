import React from 'react'
import TableComponent from '../../../../Component/TableComponent';
import Api from '../../../../Api';
import moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { TableReload } from "../../../../Action/TableAction";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import SelectComponent from "../../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";

export default function ProspectHotline(props = {}) {
    const dispatch = useDispatch();

    const [formProspectHotline, setFormProspectHotline] = React.useState({
        hotlineType: {
            name: 'hotlineType',
            label: 'Type',
            textHelper: 'Choisissez un type de problème.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 1000
            }
        }
    });
    const [dialogProspectHotline, setDialogProspectHotline] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [optionsHotlineType, setOptionsHotlineType] = React.useState([]);
    const handlerProspectHotline = formHandlerInit(formProspectHotline, setFormProspectHotline);

    React.useEffect(handlerProspectHotline.start, []);
    React.useEffect(() => {
        Api.get({
            route: 'select_prospect_hotline_types',
        },
            (response) => {
                if (response && response.data) {
                    setOptionsHotlineType(response.data);
                }
            });
    }, []);

    const prospectHotline = () => {
        dispatch(LinearProgressMainLayoutActivate());
        if (handlerProspectHotline.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerProspectHotline.setFormLoading(true);
            setLoading(true);
            let data = handlerProspectHotline.getData();

            Api.post({
                route: 'institution_health_crm_prospect_prospect_hotline_add',
                data: { ...data, employee: props.match.params.id },
                params: { prospect: props.prospectId }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setDialogProspectHotline(false);
                    handlerProspectHotline.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                        dispatch(TableReload('institution_health_crm_prospect_prospect_hotline_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Hotline ajouté.',
                                variant: 'success',
                            })
                        );
                        handlerProspectHotline.reset();
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }
    const columnsProspectHotlines = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'authorComment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'hotlineTypeName',
            label: 'Demande',
            options: { filter: true, sort: true }
        },
        {
            name: 'author',
            label: 'Créé par',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            options: { filter: true, sort: true }
        },
        {
            name: 'supportComment',
            label: 'Commentaire du support',
            options: { filter: true, sort: true }
        },
        {
            name: 'treatedAt',
            label: 'Suivi',
            options: { filter: true, sort: true }
        }
    ];

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'id', direction: 'desc' }}
                id={'institution_health_crm_prospect_prospect_hotline_list'}
                title={'Commentaires'}
                columns={columnsProspectHotlines}
                actionFirst={{
                    label: 'Ajouter une hotline',
                    onClick: () => setDialogProspectHotline(true)
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({ route: 'institution_health_crm_prospect_prospect_hotline_list', params: { prospect: props.prospectId } }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).format('ll');
                                    data[index].treatedAt = data[index].treatedAt ? moment(data[index].treatedAt).format('ll') : 'En cours...';
                                    data[index].supportComment = data[index].supportComment ? data[index].supportComment : '-';
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />


            <Dialog open={dialogProspectHotline} maxWidth={'xl'} onClose={() => setDialogProspectHotline(false)}>
                <DialogTitle style={{ fontSize: 15 }}>Ajouter une hotline</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <SelectComponent name={'hotlineType'} handler={handlerProspectHotline} options={optionsHotlineType} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent name={'comment'} handler={handlerProspectHotline} multiline={true} nullable={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogProspectHotline(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => prospectHotline()} disabled={loading} />
                </DialogActions>
            </Dialog>
        </>
    )
}