import React from 'react';
import { Box, Popover, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import UseAnimations from "react-useanimations";
import menu from "react-useanimations/lib/menu";
import LinearProgress from "./LinearProgress";
import { AppBar as AppBarMUI } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { DrawerMainLayoutClose, DrawerMainLayoutOpen } from "../../../Action/DrawerMainLayoutAction";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Api from "../../../Api";
import { AuthenticationLogout } from "../../../Action/AuthenticationAction";
import { theme } from "../../../App";
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@material-ui/icons/Apartment';
import GroupIcon from '@material-ui/icons/Group';
import ConnectedLearnerCount from './ConnectedLearnerCount';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getRoutePathname, getRoutes } from '../../../Config/Route';
import { SelectOrganism } from '../../../Action/SelectOrganismAction';

export default function AppBar(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [connectedCount, setConnectedCount] = React.useState(null);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const changeDrawer = (isOpenDrawer) => {
        if (isOpenDrawer) dispatch(DrawerMainLayoutOpen());
        else dispatch(DrawerMainLayoutClose());
    };
    const logout = () => {
        dispatch(AuthenticationLogout());
        dispatch(SelectOrganism({ organism: {} }));
        Api.get({ route: 'authentication_logout' }, () => { });
    };

    const getSwitchPage = () => {
        let route = getRoutes();
        const newRoute = Object.keys(route).filter(key => route[key].roles.some(role => role.startsWith("ROLE_INSTITUTION")));
        props.history.push(getRoutePathname(newRoute[0]))
    }

    const getInfos = () => {
        Api.get({
            route: 'connected_user_counter_get'
        },
            (response) => {
                setConnectedCount(response.data);
            });
    }

    const seconds = 30;
    React.useEffect(() => {
        getInfos();
        const interval = setInterval(() => {
            getInfos();
        }, seconds * 1000);

        return () => clearInterval(interval);
    }, []);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        if (authenticationReducer.roles) {
            let roles = authenticationReducer.roles;
            let isRole = false;
            const filteredRoles = roles.filter(role => role.startsWith("ROLE_INSTITUTION"))
            if (filteredRoles.length !== 0) isRole = true;
            setIsSuperAdmin(authenticationReducer.roles && (authenticationReducer.roles.includes("ROLE_SUPER_ADMIN") || isRole));
        }
    }, [authenticationReducer]);

    return (
        <AppBarMUI position="fixed" className={`${classes.appBar}`}>
            <Toolbar>
                <UseAnimations
                    className={classes.appBarButton}
                    animation={menu}
                    size={27}
                    onClick={() => changeDrawer(!drawerMainLayoutReducer.isOpen)}
                    reverse={!drawerMainLayoutReducer.isOpen}
                />

                <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo} />

                {isSuperAdmin &&
                    <Box style={{ background: 'rgba(94, 110, 130, 0.87)', borderRadius: 20, margin: '0px 13px', padding: '0px 5px' }}>
                        <Tooltip title="Portail Établissement" aria-label="Établissement">
                            <IconButton component="span" className={classes.appBarLogout} onClick={() => { getSwitchPage() }}>
                                <ApartmentIcon className={classes.appBarLogoutIcon} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }

                {!isSmallView &&
                    <div style={{ fontSize: 11, position: 'relative', marginLeft: isSuperAdmin ? 0 : 10, color: '#5E6E82' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <Tooltip title="Nombre d'apprenants connectés">
                                <span className={classes.before} style={{ display: 'flex', alignItems: 'center' }}><PersonIcon /> : {connectedCount ? connectedCount.onlineCount : 0}</span>
                            </Tooltip>
                            <Tooltip title="Nombre d'apprenants connectés aujourd'hui">
                                <span style={{ display: 'flex', alignItems: 'center' }}><GroupIcon /> : {connectedCount ? connectedCount.dailyCount : 0}</span>
                            </Tooltip>

                            <Tooltip title="Voir Plus de détail.">

                                <IconButton size="small" id={'popover'} onClick={(event) => { setAnchorEl(event.currentTarget); }}>
                                    <ExpandMoreIcon fontSize="inherit" />
                                </IconButton>

                            </Tooltip>

                            <Popover
                                id={'popover'}
                                className={classes.popover}
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <ConnectedLearnerCount />
                            </Popover>

                        </div>
                    </div>
                }

                <Box style={{ background: 'rgba(94, 110, 130, 0.87)', borderRadius: 20, margin: '0px 13px 0px auto', padding: '0px 5px' }}>
                    <Tooltip title="Déconnexion" aria-label="Déconnexion">
                        <IconButton component="span" className={classes.appBarLogout} onClick={logout}>
                            <PowerSettingsNewIcon className={classes.appBarLogoutIcon} />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
            <LinearProgress />
        </AppBarMUI>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: 2,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 58,
        '& .MuiToolbar-regular': {
            height: 58,
            minHeight: '58px !important',
            paddingLeft: 23,
            paddingRight: 23,
        }
    },
    appBarButton: {
        padding: 0,
        cursor: 'pointer',
    },
    appBarLogo: {
        height: 25,
        margin: '0 0 0 16px ',
    },
    appBarLogout: {
        padding: 5,
        margin: '0',
        color: '#FFF'
    },
    appBarLogoutIcon: {
        fontSize: 17
    },
    before: {
        '&:before': {
            content: '" "',
            width: 5,
            height: 5,
            background: 'green',
            borderRadius: 50,
            position: 'absolute',
            top: 4,
            left: 18
        }
    },
    popover: {
        '& .MuiPopover-paper': {
            minHeight: 330,
            width: '50%'
        }
    }
}));
