import React from 'react'
import TableComponent from '../../../../Component/TableComponent';
import Api from '../../../../Api';
import moment from 'moment';
import {formHandlerInit} from "../../../../Tool/FormHandlerCommon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {getRoutePathname} from "../../../../Config/Route";
import {Assignment} from "@material-ui/icons";

export default function ChapterCourse(props = {}) {

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'order',
            label: 'Order',
            options: {filter: true, sort: true}
        },
        {
            name: 'chapterName',
            label: 'Nom du chapitre',
            options: {filter: true, sort: true}
        },
        {
            name: 'name',
            label: 'Nom du cours surchargé',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Chapitre'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('institution_health_content_manager_chapter', {id: row.chapterId}));
                    }}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'order', direction: 'desc'}}
                id={'institution_health_content_manager_course_chapter_course_list'}
                title={'Cours lié aux chapitres suivants : '}
                columns={columns}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'institution_health_content_manager_course_chapter_course_list',
                            params: {course: props.match.params.id}
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />
        </>
    )
}
