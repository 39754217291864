import React from 'react';
import TitleComponent from '../../../Component/TitleComponent';
import { Box, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import { dispatch } from '../../../App';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import Api from '../../../Api';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import { Alert } from '@material-ui/lab';

function Info(props = { reloadList: () => { } }) {

    const classes = useStyles();
    const [isBillable, setIsBillable] = React.useState(true);
    const [units, setUnits] = React.useState();
    const [infoANDPC, setInfoANDPC] = React.useState();
    const [isLock, setIsLock] = React.useState(true);
    const [propsReady, setPropsReady] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        numberDPC: {
            name: 'numberDPC',
            label: 'Numéro de session',
            textHelper: 'Saisissez un numéro de session.',
            type: 'text',
            defaultValue: props.sessionFormation.numberDPC,
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    React.useEffect(() => {
        handler.setDataApi(props.sessionFormation);
        setIsBillable(props.sessionFormation.isBillable);
        setUnits(props.sessionFormation.units);
        setInfoANDPC(props.sessionFormation.infoANDPC);

        if (Object.keys(props.sessionFormation).length > 0) {
            setPropsReady(true);
        }
    }, [props.sessionFormation]);

    const edit = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            let data = handler.getData();
            Api.post({
                route: 'financial_management_billing_v2_session_formation_edit',
                params: { id: props.match.params.id },
                data: { numberDPC: data.numberDPC }
            },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    props.reloadList();
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Numéro de session enregistrée.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    const billable = (isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        handler.setFormLoading(true);
        setLoading(true);
        Api.post({
            route: 'financial_management_billing_v2_session_formation_billable',
            params: { id: props.match.params.id },
            data: { isBillable: isBillable }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                handler.setFormLoading(false);
                setLoading(false);

                if (!isBillable) setIsLock(true);
                setIsBillable(isBillable);
                props.reloadList();
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: isBillable ? 'Session formation facturable.' : 'Session formation non facturable.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    const formatTime = (duration) => {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}h${formattedMinutes}`;
    };

    return (
        propsReady ?
            <div style={{ padding: 4, position: 'relative' }}>
                <ShadowBoxComponent>
                    <div className={classes.logoContainer}>
                        {props.sessionFormation.organism === 1 && <img src="/asset/images/logo_santeformapro.png" alt="logo" style={{ height: 50 }} />}
                        {props.sessionFormation.organism === 2 && <img src="/asset/images/logo_revuedupraticiendpc.png" alt="logo" style={{ height: 50 }} />}
                        {props.sessionFormation.organism === 3 && <img src="/asset/images/logo_actionsante.png" alt="logo" style={{ height: 50 }} />}
                        {props.sessionFormation.organism === 4 && <img src="/asset/images/logo_timelia.png" alt="logo" style={{ height: 50 }} />}
                    </div>
                    <TitleComponent title={<span className={classes.bold}>{props.sessionFormation.formationName}</span>} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <p><span className={classes.bold}>Numéro de programme :</span> {props.sessionFormation.andpc}</p>
                            <p><span className={classes.bold}>Durée de la formation :</span> {props.sessionFormation.formationDuration}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <p><span className={classes.bold}>Début de session :</span> {props.sessionFormation.startAt}</p>
                            <p><span className={classes.bold}>Fin de session :</span> {props.sessionFormation.endAt}</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'numberDPC'} handler={handler} />
                        </Grid>
                    </Grid>
                    <div className={classes.btnContainer}>
                        <ButtonComponent
                            label={'Enregistrer le numéro de session'}
                            onClick={edit}
                            disabled={loading || !isBillable || props.sessionFormation.isInvoice}
                        />
                        <ButtonComponent
                            label={isBillable ? 'Passer en non facturable' : 'Passer en facturable'}
                            onClick={() => { billable(isBillable ? false : true) }}
                            disabled={isBillable ? (loading || props.sessionFormation.state === 2 || props.sessionFormation.state === 4) : loading}
                            color={isBillable ? '#DC3545' : '#35A2EB'}
                        />
                    </div>
                    <br />
                    {units &&
                        <div style={{ padding: 25 }}>
                            <TitleComponent title={<span className={classes.bold}>Détail des unitées</span>} />
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.th}>Ordre</TableCell>
                                            <TableCell className={classes.th}>Type</TableCell>
                                            <TableCell className={classes.th}>Durée en ligne</TableCell>
                                            <TableCell className={classes.th}>Durée hors-ligne</TableCell>
                                            <TableCell className={classes.th}>Chapitres</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(units && units.length > 0) ?
                                            units.map((unit) => {
                                                return <TableRow key={unit.id}>
                                                    <TableCell className={classes.td}>{unit.orderBy}</TableCell>
                                                    <TableCell className={classes.td}>{unit.type}</TableCell>
                                                    <TableCell className={classes.td}>{formatTime(unit.duration)} ({unit.duration} secondes)</TableCell>
                                                    <TableCell className={classes.td}>{formatTime(unit.durationOffline)} ({unit.durationOffline} secondes)</TableCell>
                                                    <TableCell className={classes.td}>
                                                        {
                                                            (unit.chapter && unit.chapter.length > 0) &&
                                                            <span style={{ color: '#0090ff' }}>
                                                                {unit.type === 'FC' ?
                                                                    (
                                                                        'Temps total chapitre(cours + pre/post) : ' + formatTime((unit.durationChapters + unit.durationPrePost)) + ' (' + (unit.durationChapters + unit.durationPrePost) + 'secondes) = Temps FC : ' + 
                                                                        formatTime((unit.durationChapters)) + ' (' + (unit.durationChapters) + ' secondes) + Temps PRE/POST : '  + 
                                                                        formatTime(unit.durationPrePost) + ' (' + unit.durationPrePost + ' secondes)'
                                                                    )
                                                                    :
                                                                    'Temps total chapitre(cours cumulés) : ' + formatTime(unit.durationChapters) + ' (' + unit.durationChapters + 'secondes)'
                                                                }
                                                            </span>
                                                        }
                                                        {
                                                            (unit.chapter && unit.chapter.length > 0) ?
                                                                unit.chapter.map((chapter, i) => {
                                                                    return <div key={i}><span>{i + 1}/ {chapter.name}</span><br /></div>
                                                                })
                                                                :
                                                                '--'
                                                        }
                                                        {
                                                            (unit.chapter && unit.chapter.length > 0 && (unit.durationChapters + unit.durationPrePost) < unit.duration) &&
                                                            <p style={{ margin: 5, color: '#dc3545' }}>Attention ! Le temps des cours est inferieur au temps de l'unité.</p>
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            })
                                            :
                                            <TableRow>
                                                <TableCell className={classes.td}></TableCell>
                                                <TableCell className={classes.td}></TableCell>
                                                <TableCell className={classes.td}><Alert severity="warning">Attention, aucune unité n'a été remplie pour cette formation.</Alert></TableCell>
                                                <TableCell className={classes.td}></TableCell>
                                                <TableCell className={classes.td}></TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    }
                    <br />
                    {infoANDPC &&
                        <div style={{ padding: 25 }}>
                            <TitleComponent title={<span className={classes.bold}>Détail date, temps et prix ANDPC</span>} />
                            <div className={classes.andpcContainer}>
                                <div>
                                    <p>Date</p>
                                    <div>
                                        <p><span>Fin EPP 1 : </span>{infoANDPC.epp_first_stage_end_at}</p>
                                        <p><span>Début EPP 2 : </span>{infoANDPC.epp_last_stage_at}</p>
                                        <p><span>Fin FC : </span>{infoANDPC.fc_end_at}</p>
                                    </div>
                                </div>
                                <div style={{ borderLeft: '1px solid #DADADA', borderRight: '1px solid #DADADA' }}>
                                    <p>Temps</p>
                                    <div>
                                        <p><span>FC : </span>{infoANDPC.duration_fc !== '-' ? formatTime(infoANDPC.duration_fc) + ' (' + infoANDPC.duration_fc + ' secondes)' : '-'}</p>
                                        <p><span>Questionnaire pre : </span>{infoANDPC.duration_pre !== '-' ? formatTime(infoANDPC.duration_pre) + ' (' + infoANDPC.duration_pre + ' secondes)' : '-'}</p>
                                        <p><span>Questionnaire post : </span>{infoANDPC.duration_post !== '-' ? formatTime(infoANDPC.duration_post) + ' (' + infoANDPC.duration_post + ' secondes)' : '-'}</p>
                                        <p><span>EPP 1 : </span>{infoANDPC.duration_epp1 !== '-' ? formatTime(infoANDPC.duration_epp1) + ' (' + infoANDPC.duration_epp1 + ' secondes)' : '-'}</p>
                                        <p><span>EPP 2 : </span>{infoANDPC.duration_epp2 !== '-' ? formatTime(infoANDPC.duration_epp2) + ' (' + infoANDPC.duration_epp2 + ' secondes)' : '-'}</p>
                                        <p><span>Hors ligne : </span>{infoANDPC.duration_offline !== '-' ? formatTime(infoANDPC.duration_offline) + ' (' + infoANDPC.duration_offline + ' secondes)' : '-'}</p>
                                        <p><span>CV : </span>{infoANDPC.duration_cv !== '-' ? formatTime(infoANDPC.duration_cv) + ' (' + infoANDPC.duration_cv + ' secondes)' : '-'}</p>
                                    </div>
                                </div>
                                <div>
                                    <p>Prix ANDPC (par heure)</p>
                                    <div>
                                        <p><span>FC : </span>{infoANDPC.price_fc_per_hour !== '-' ? infoANDPC.price_fc_per_hour + ' €' : '-'}</p>
                                        <p><span>EPP : </span>{infoANDPC.price_epp_per_hour !== '-' ? infoANDPC.price_epp_per_hour + ' €' : '-'}</p>
                                        <p><span>CV : </span>{infoANDPC.price_cvper_hour !== '-' ? infoANDPC.price_cvper_hour + ' €' : '-'}</p>
                                        <p><span>Hors ligne : </span>{infoANDPC.price_offline_per_hour !== '-' ? infoANDPC.price_offline_per_hour + ' €' : '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </ShadowBoxComponent>
            </div>

            :

            <Fade in={true} {...{ timeout: 1000 }}>
                <Box className={classes.loader}>
                    <Box className={classes.loaderContent}>
                        <Box className={classes.loaderActivity}>
                            <UseAnimations animation={activity} size={70} />
                        </Box>
                    </Box>
                </Box>
            </Fade>
    );
};

const useStyles = makeStyles({
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20
    },
    bold: {
        fontWeight: 600
    },
    btnContainer: {
        marginTop: 20,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'flex-end',
        '& > button': {
            margin: 3
        }
    },
    td: { fontSize: 13, padding: 12 },
    th: { fontWeight: 900, fontSize: 13, padding: 12 },
    andpcContainer: {
        margin: 10,
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > div': {
            padding: 15,
            width: '30%',
            '& > p': {
                textAlign: 'center',
                fontWeight: 600,
                fontSize: 19,
                margin: 15
            },
            '& > div': {
                display: 'flex',
                justifyContent: 'space-evenly',
                flexWrap: 'wrap',
                flexDirection: 'column',
                '& > p': {
                    fontSize: 15,
                    margin: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: '1px dotted #DADADA',
                    '& > span': {
                        fontWeight: 600,
                    }
                }
            }
        }
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
});

export default Info;