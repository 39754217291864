import React from "react";
import BreadcrumbsComponent from "../../../../Component/BreadcrumbsComponent";
import { getRoutePathname } from "../../../../Config/Route";
import { useDispatch, useSelector } from "react-redux";
import { Box, Fade, Grid, makeStyles } from "@material-ui/core";
import ContactsIcon from '@material-ui/icons/Contacts';
import { theme } from "../../../../App";
import moment from "moment";
import Api from "../../../../Api";
import activity from "react-useanimations/lib/activity";
import UseAnimations from "react-useanimations";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import FilterPaginationComponent from "../../../../Component/FilterPaginationComponent";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import Card from "./Component/Card";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";

const InstitutionEmpoyeeList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [employee, setEmployee] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(true);

    const viewEmployeeList = (data) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <Card
                    show={true}
                    image={''}
                    data={data}
                    title={data.name}
                />
            </Grid>
        )
    }

    const list = () => {
        setLoad(true);
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'institution_health_crm_institution_employee_list',
            params: { institution: props.match.params.institution },
            data: { organismId: authenticationReducer.organism,}
        },
            (response) => {
                setLoad(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    let data = response.data.data;
                    for (let index in data) {
                        data[index].assign = data[index].commercialName ?? '-';
                        data[index].email = data[index].email ? data[index].email : '-';
                        data[index].function = data[index].function ? data[index].function : '-';
                        data[index].mobile = data[index].mobile ? data[index].mobile : '-';
                        data[index].phone = data[index].phone ? data[index].phone : '-';
                        data[index].rpps = data[index].rpps ? data[index].rpps : '-';
                        data[index].pole = data[index].pole ? data[index].pole : '-';
                        data[index].startAt = data[index].startAt ? moment(data[index].startAt).format('lll') : '-';
                        data[index].endAt = data[index].endAt ? moment(data[index].endAt).format('lll') : '-';
                    }
                    setEmployee(data);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    React.useEffect(() => {
        list()
        setBreadcrumbs({
            currentPage: 'Contacts',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_institution_list'), label: 'Mes établissements' },
                { path: getRoutePathname('learner_institution_manager', { institution: props.match.params.institution }), label: 'Mon établissement' },
            ]
        });
    }, []);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <Box style={{ textAlign: 'center', maxWidth: 992, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box className={classes.pagination}>
                            <FilterPaginationComponent
                                data={employee}
                                title={'Contacts'}
                                icon={<ContactsIcon />}
                                pageNumber={6}
                                viewList={viewEmployeeList}
                                loading={load}
                                loadData={list}
                                className={classes.Employee}
                            />
                        </Box>
                }
            </Box>
        </>
    )
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    pagination: {
        padding: '30px 0',
        '& .pageError': {
            //fontSize: '30px !important'
        }
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    cardStyle: {
        position: 'relative',
        overflow: 'initial',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        boxShadow: 'initial',
        filter: 'initial',
        opacity: 1,
        borderRadius: 5,
    }
}));

export default InstitutionEmpoyeeList;