import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box, makeStyles } from "@material-ui/core";
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ButtonComponent from "../../../../Component/ButtonComponent";
import LockIcon from '@material-ui/icons/Lock';


const CardDashboardManagerComponent = (props) => {

    const classes = useStyles();
    return (
        <ShadowBoxComponent style={{ height: '100%', background: '#5E6E82', display: 'grid', padding: 20, color: '#FFFFFF' }}>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 20, display: 'flex', alignItems: 'center', gap: 2 }}><BarChartIcon style={{ width: 25, height: 25 }} />Tableau Manager</h1>
                <p style={{ margin: 0, fontSize: 15 }}>Suivi des inscriptions.</p>
            </Box>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 20, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 'lighter' }}>Meilleur commercial :</h1>
                <p style={{ margin: 0, fontSize: 15, fontWeight: 600, padding: 0 }}>{props.data.reporting && (props.data.reporting.length != 0) ? props.data.reporting.name : '-'}</p>
            </Box>
            <Box style={{ height: '100%', display: 'flex', }}>
                <Box style={{ height: '100%', width: '100%', textAlign: 'center', position: 'relative' }}>
                    <TrendingUpIcon style={{ height: '100%', width: '100%', textAlign: 'center', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} />
                </Box>
                <Box style={{ height: '100%', width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', }}>
                    <p style={{ margin: '5px auto', display: 'block', fontSize: 15 }}>
                        <span style={{ fontSize: 45 }}>{props.data.reporting && (props.data.reporting.length != 0) ? props.data.reporting.sells : 0}</span><br />Formations vendu
                    </p>
                    {props.button.isAccess &&
                        <ButtonComponent
                            style={props.button.style}
                            color={props.button.background} label={props.button.title}
                            onClick={() => { props.button.onClick() }}
                        />
                    }
                </Box>
            </Box>
        </ShadowBoxComponent>
    )
}

const useStyles = makeStyles({})

export default CardDashboardManagerComponent;