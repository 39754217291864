import React from 'react';
import { Box, Checkbox, Grid, makeStyles } from "@material-ui/core"
import TableComponent from "../../../../Component/TableComponent";
import ButtonComponent from '../../../../Component/ButtonComponent';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../../Api';
import { TableReload } from '../../../../Action/TableAction';
import { inArray } from '../../../../Tool/ArrayCommon';

let institutionChecked = [];
let datas = [];

export default function InstitutionAddGroup(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [loading, setLoading] = React.useState(false);
    const columns = [
        {
            name: "value",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Établissements",
            options: { filter: true, sort: true }
        },
        {
            name: "_action",
            label: "Action",
        }
    ];
    const [isReadyTable, setReadyTable] = React.useState(false);

    const getAction = (row, checked) => {
        return (
            <Checkbox
                key={row.value + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(row.value, institutionChecked)) {
                        let index = institutionChecked.indexOf(row.value);
                        if (index > -1) {
                            institutionChecked.splice(index, 1);
                        }
                    } else {
                        institutionChecked.push(row.value);
                    }

                    dispatch(TableReload('group_institutions_list'));
                }}
            />
        );
    };

    const getDatas = (resolve) => {
        setLoading(true);
        if (datas.length > 0 && isReadyTable) {
            setLoading(false);
            for (let index in datas) {
                datas[index]._action = getAction(datas[index], inArray(datas[index].value, institutionChecked));
            }

            resolve([...datas]);
            datas = [...datas];
        } else {
            if (props.match.params.id) {
                Api.get({
                    route: 'select_group_institutions',
                    params: { organismId: selectOrganismReducer.organism.value }
                },
                    (response) => {
                        setLoading(false);
                        if (response && response.data) {
                            let data = response.data;

                            for (let index in data) {
                                data[index].label = data[index].label ? data[index].label : '-';
                                data[index]._action = getAction(data[index], false);
                            }

                            resolve([]);
                            resolve([...data]);
                            datas = [...data];
                        } else {
                            resolve([]);
                            datas = [];
                        }
                    });
            } else {
                resolve([]);
                datas = [];
            }
            setReadyTable(true);
        }
    }

    const save = () => {
        if (!institutionChecked.length) {
            dispatch(
                SnackbarOpen({
                    text: 'Vous n\'avez sélectionné aucun établissement.',
                    variant: 'error',
                })
            );
        } else {
            if (props.match.params.id) {
                setLoading(true);
                Api.post({
                    route: 'institution_health_crm_institution_group_add_institutions',
                    data: { institutionsId: institutionChecked },
                    params: { id: props.match.params.id }
                }, (response) => {
                    if (response.status === 200) {
                        props.setIsOpen(false);
                        setLoading(false);
                        dispatch(TableReload('institution_health_crm_institution_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Un ou plusieurs établissements ont été ajoutés.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error,
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    React.useEffect(() => {
        datas = [];
        institutionChecked = [];
    }, [])

    return (
        <Box style={{ height: '100%' }}>
            <Grid container spacing={2} style={{ width: '100%', margin: 'auto', height: '100%', display: 'grid', gridTemplateRows: 'auto max-content' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.TableComponent}>
                    <TableComponent
                        id={'group_institutions_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}>
                            <PersonAddIcon style={{ width: 20, height: 20 }} />Ajouter des établissements | Groupe {'-'}
                        </h1>}
                        columns={columns}
                        promiseData={(resolve) => getDatas(resolve)}
                        onBeforeRefresh={() => {
                            datas = [];
                            institutionChecked = [];
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box style={{ display: 'flex', gap: 10, margin: '0 0 0 auto', width: 'fit-content', }}>
                        <ButtonComponent onClick={() => {
                            props.setIsOpen(false);
                            datas = [];
                            institutionChecked = [];
                        }} color={'#5E6E82'} label={'Annuler'} />
                        <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles({
    TableComponent: {
        height: '100%',
        width: '100%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                boxShadow: 'none',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
})