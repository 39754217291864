import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Alert from '@material-ui/lab/Alert';
import DatePickerComponent from "../../../Component/DatePickerComponent";

function InvoiceFIFPL(props) {
    const dispatch = useDispatch();
    const [countRegistration, setCountRegistration] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [formInvoice, setFormInvoice] = React.useState({
        conventionNumber: {
            name: 'conventionNumber',
            label: 'Numéro de convention',
            textHelper: 'Saisissez le numéro de convention',
            type: 'string',
            defaultValue: '',
            options: {validation: ['required']}
        },
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: '',
            options: {validation: ['required', 'date']}
        }
    });

    const handler = formHandlerInit(formInvoice, setFormInvoice);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let datas = handler.getData();
            Api.post({
                    route: 'financial_management_billing_fifpl_session_formation_invoice',
                    params: {id: props.sessionFormation.id},
                    data: { conventionNumber: datas.conventionNumber, invoiceAt: datas.invoiceAt}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        handler.reset();
                        props.close();
                        dispatch(TableReload('billing_registration_list'));

                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );
                        const url = Api.route({
                            route: 'financial_management_billing_fifpl_invoice_download',
                            params: {id: response.data.id},
                        });
                        window.open(url, '_blank');
                        dispatch(TableReload('billing_registration_list'));
                        
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(handler.start, []);
    
    React.useEffect(() => {
        handler.setValue('invoiceAt', props.sessionFormation.invoiceAt);
        handler.setValue('conventionNumber', props.sessionFormation.conventionNumber);
    }, [props]);

    React.useEffect(() => {
        let count = 0;
        for (let index in props.sessionFormation.registrations) {
            let state = props.sessionFormation.registrations[index].state;
            if (state === 1 || state === 5 || state === 6) {
                count++;
            }
        }
        setCountRegistration(count);
    }, [props.sessionFormation.registrations]);

    return (
        <>
            <Dialog open={props.open} maxWidth={'lg'}>
                <DialogTitle style={{fontSize: 15}}>Facture</DialogTitle>
                {props.sessionFormation.countInvoice !== countRegistration && <>
                    <Alert severity="warning">Attention, vous allez facturer {props.sessionFormation.countInvoice} personne(s) sur {countRegistration}.</Alert><br/><br/>
                </>}
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <DatePickerComponent name={'invoiceAt'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldComponent name={'conventionNumber'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Générer la facture'}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default InvoiceFIFPL;
