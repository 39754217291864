import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box } from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";


const CardWelcomeComponent = (props) => {
    return (
        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, overflow: 'hidden', gap: 15 }}>
            <Box style={{ position: 'absolute', height: 500, width: 500, opacity: '0.1', transform: 'translate(-100%, -40%)', top: '50%', left: '50%', }}>
                {props.icon}
            </Box>
            <Box style={{ height: '100%', textAlign: 'center', display: 'grid', zIndex: 1 }}>
                <Box style={{ margin: 'auto', width: 60, height: 60 }}>
                    {props.icon}
                </Box>
                <p style={{ margin: 'auto', fontSize: 40, fontWeight: 'bold', display: 'flex', alignItems: 'center', width: 'fit-content', }}>
                    Bienvenue sur<br /> Portail
                </p>
                <hr style={{ margin: '0px auto', color: '#FFF', width: '30%', height: 0, borderRadius: 100, }} />
                <p style={{ margin: 0, fontSize: 15 }}>Simplifiez votre gestion client dès maintenant!</p>
            </Box>
            <Box style={{ height: '100%', display: 'flex', flexDirection: 'column', zIndex: 1 }}>
                <ButtonComponent onClick={() => { props.onClick() }} style={{ margin: 'auto auto 0', width: 'fit-content', padding: '6px 40px' }} color={'#5E6E82'} label={'Espase personnel'} />
                <p style={{ margin: '10px auto auto', fontSize: 10 }}>Accéder a votre espace personnel.</p>
            </Box>
        </ShadowBoxComponent>
    )
}

export default CardWelcomeComponent;