import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Grid, Table, TableCell, TableContainer, TableRow, TableBody, TableHead, Tooltip, Zoom, makeStyles, Box } from '@material-ui/core';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DirectionsIcon from '@material-ui/icons/Directions';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CachedIcon from '@material-ui/icons/Cached';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Api from '../../../Api';
import DatePickerPeriodeComponent from './Component/DatePickerPeriodeComponent';
import Card from './Component/Card';
import LaunchIcon from '@material-ui/icons/Launch';
import TitleComponent from '../../../Component/TitleComponent';
import moment from 'moment';
import ButtonComponent from '../../../Component/ButtonComponent';
import ManagerOffUser from './ManagerOffUser';
import { useSelector } from 'react-redux';
import { getRoutePathname } from '../../../Config/Route';
import DetailTable from "./Component/DetailTable";

const Manager = (props) => {
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [date, setDate] = React.useState({});
    const [info, setInfo] = React.useState({});
    const [webcoachs, setWebcoachs] = React.useState({});
    const [optionWebcoachs, setOptionWebcoachs] = React.useState([]);
    const [webCoachId, setWebCoachId] = React.useState(null);
    const [isOpenOffuser, setIsOpenOffuser] = React.useState(false);
    const [isAllUser, setIsAllUser] = React.useState(false);


    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => { props.history.push(getRoutePathname('webcoach_relaunch')) }}
                    label={<>Interface de relance</>}
                />
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => { setIsOpenOffuser(true); setWebCoachId(null); setIsAllUser(true) }}
                    label={<>Gestionnaire d'absences</>}
                />
            </Box>
        )
    }
    //TODO: FIX BUG FORM
    const getData = () => {
        if (date.startAt && date.endAt) {
            let data = {
                startAt: date.startAt,
                endAt: date.endAt,
            }
            if (date.endingMonth !== 'none'){
                data.endingMonth = parseInt(date.endingMonth);
            }
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'webcoach_manager_info',
                data: {...data}
            }, (response) => {
                if (response && response.data) {
                    let data = response.data;
                    let webcoachsList = [];
                    setInfo(data);
                    setWebcoachs(data.webcoachs);
                    for (let index in data.webcoachs.user) {
                        webcoachsList.push({ label: data.webcoachs.user[index].name, value: data.webcoachs.user[index].id });
                    }
                    setOptionWebcoachs(webcoachsList)
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
        }
    }
    React.useEffect(() => {
        getData();
    }, [date])

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestion des webcoach',
            context: 'Webcoach',
            description: '',
            html: <GetActionMore />
        });
    }, [isOpenOffuser]);

    React.useEffect(() => {
        setTimeout(() => {
            setReadyContent(true);
        }, 3000);
    }, []);
    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Grid container spacing={0} style={{ width: '100%', height: '100%', margin: 'auto' }}>
                    <Grid item xs={12} sm={12} md={9} lg={9} style={(screenReducer.size <= 965) ? {} : { overflow: 'hidden auto', height: '100%', position: 'relative' }}>
                        <Grid container spacing={2} style={(screenReducer.size <= 965) ? { padding: '10px 20px' } : { width: '100%', margin: 'auto', position: 'absolute', padding: '10px 20px' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ShadowBoxComponent style={{ overflow: 'hidden' }}>
                                    <DatePickerPeriodeComponent
                                        onHandler={(valHandler) => {
                                            setDate(valHandler)
                                        }}
                                    />
                                </ShadowBoxComponent>
                            </Grid>
                            {info.allTasks && <>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Tâches totales prévues.'}
                                            number={info.allTasks.countTasks}
                                            turnover={info.allTasks.countTasks}
                                            color={'#000'}
                                            icon={<AssignmentIcon className={classes.icon} />}
                                            label={'Nombre total de tâches prévues'}
                                            labelEmpty={'Aucune tâche prévues.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Tâches totales effectuées.'}
                                            number={info.allTasks.countDoneTasks}
                                            turnover={info.allTasks.countDoneTasks}
                                            color={'#000'}
                                            icon={<AssignmentTurnedInIcon className={classes.icon} />}
                                            label={'Nombre total de tâches effectuées.'}
                                            labelEmpty={'Aucune tâche effectuée.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Tâches totales ignorées.'}
                                            number={info.allTasks.countIgnoredTasks}
                                            turnover={info.allTasks.countIgnoredTasks}
                                            color={'#000'}
                                            icon={<AssignmentReturnIcon className={classes.icon} />}
                                            label={'Nombre total de tâches ignorées.'}
                                            labelEmpty={'Aucune tâche ignorée.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Card
                                            title={'Inscirptions totales concernées.'}
                                            number={info.allTasks.registrationCount}
                                            turnover={info.allTasks.registrationCount}
                                            color={'#000'}
                                            icon={<MonetizationOnIcon className={classes.icon} />}
                                            label={'Nombre total des inscriptions liées aux tâches'}
                                            labelEmpty={'Aucune registration trouvée.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Card
                                            title={'Inscirptions finalisées.'}
                                            number={info.allTasks.finishingRate}
                                            turnover={info.allTasks.finishingRate}
                                            color={'#000'}
                                            isRate={true}
                                            icon={<DoneAllIcon className={classes.icon} />}
                                            label={'Taux de finition'}
                                            labelEmpty={'Aucune inscription finalisé.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Session se terminant.'}
                                            number={info.allTasks.sessionEnding}
                                            turnover={info.allTasks.sessionEnding}
                                            color={'#000'}
                                            icon={<TimerOffIcon className={classes.icon} />}
                                            label={'Nombre total des sessions se terminant'}
                                            labelEmpty={'Aucune session se termine.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Formation continue se terminant.'}
                                            number={info.allTasks.fcEnding}
                                            turnover={info.allTasks.fcEnding}
                                            color={'#000'}
                                            icon={<CachedIcon className={classes.icon} />}
                                            label={'Nombre total des formations continues se terminant'}
                                            labelEmpty={'Aucune formation continue se termine.'}
                                        />
                                    </Grid>
                                </Zoom>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Card
                                            title={'Inscirptions reportées.'}
                                            number={info.allTasks.reportedSession}
                                            turnover={info.allTasks.reportedSession}
                                            color={'#000'}
                                            icon={<DirectionsIcon className={classes.icon} />}
                                            label={'Nombre total des inscriptions reportée'}
                                            labelEmpty={'Aucune inscription reportée.'}
                                        />
                                    </Grid>
                                </Zoom>
                            </>}
                            {(webcoachs && webcoachs.taskByUser && webcoachs.user) && <>
                                <Zoom in={isReadyContent}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <ShadowBoxComponent >
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '90%', margin: 'auto', padding: '12px 0' }}>
                                                    <TitleComponent
                                                        title={
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                Tâches par Webcoachs
                                                            </div>
                                                        } />
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className={classes.thSize}>WebCoachs</TableCell>
                                                                    <TableCell className={classes.th}>En retard</TableCell>
                                                                    <TableCell className={classes.th}>À faire</TableCell>
                                                                    <TableCell className={classes.th}>À venir</TableCell>
                                                                    <TableCell className={classes.th}>Effectuée</TableCell>
                                                                    <TableCell className={classes.th}>Ignorée</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Object.keys(webcoachs.taskByUser).map((id, index) => {
                                                                    const user = webcoachs.taskByUser[id];
                                                                    return (
                                                                        <TableRow key={id}>
                                                                            <TableCell className={classes.thSize}>{webcoachs.user[id].name}</TableCell>
                                                                            <TableCell className={classes.td}>{user.countTasks.countLateTasks}</TableCell>
                                                                            <TableCell className={classes.td}>{user.countTasks.countTodayTasks}</TableCell>
                                                                            <TableCell className={classes.td}>{user.countTasks.countComingTasks}</TableCell>
                                                                            <TableCell className={classes.td}>{user.countTasks.countDoneTasks}</TableCell>
                                                                            <TableCell className={classes.td}>{user.countTasks.countIgnoredTasks}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </ShadowBoxComponent>
                                        <br />
                                        <ShadowBoxComponent >
                                            <Grid container spacing={0}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '90%', margin: 'auto', padding: '12px 0' }}>
                                                    <TitleComponent
                                                        title={
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                                Statistique par Webcoach
                                                            </div>
                                                        } />
                                                    <DetailTable data={webcoachs.taskByUser}/>
                                                </Grid>
                                            </Grid>
                                        </ShadowBoxComponent>
                                    </Grid>
                                </Zoom>
                            </>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <ShadowBoxComponent style={{ height: '100%', borderRadius: 0, boxShadow: 'initial', display: 'grid', gridTemplateRows: 'max-content' }}>
                            <p style={{ display: 'flex', alignItems: 'center', gap: 10, margin: '0 14px' }}>
                                <span style={{ fontSize: 20 }}><b>Webcoachs</b></span>
                                <hr style={{ width: '90%' }} />
                            </p>
                            <Box style={{ overflow: 'hidden auto', position: 'relative' }}>
                                {webcoachs.user &&
                                    <Box style={(screenReducer.size <= 965) ? {} : { position: 'absolute', width: '100%', height: '100%' }}>
                                        {
                                            Object.keys(webcoachs.user).map((id, index) => (
                                                <Box key={id} style={{ padding: '10px 0', textAlign: 'left', margin: 14, display: 'flex', gap: 10 }}>
                                                    <Box>
                                                        <AccountCircleIcon style={{ fontSize: 30 }} />
                                                    </Box>
                                                    <Box style={{ width: '100%' }}>
                                                        <Box style={{ display: 'flex', alignItems: 'center', gap: 3, flexWrap: ' wrap' }}>
                                                            <p style={{ margin: 0, fontWeight: 'bold', fontSize: 17 }}>{webcoachs.user[id].name}</p>
                                                            <Box style={{ display: 'flex', alignItems: 'center', height: 'max-content', gap: 5, margin: '0px 0 0 auto' }}>
                                                                <div style={{ width: 11, height: 11, background: webcoachs.user[id].currentlyOff ? 'rgb(220, 53, 69)' : 'rgb(40, 167, 69)', borderRadius: 100, transform: 'translate(0,14%)' }} />
                                                                <p style={{ margin: 0, display: 'block', fontSize: 12 }}>{webcoachs.user[id].currentlyOff ? 'absent(e)' : 'present(e)'}</p>
                                                            </Box>
                                                        </Box>

                                                        {webcoachs.user[id].absence.length > 0 ?
                                                            <>
                                                                <p style={{ margin: 0, fontSize: 13, display: 'flex', alignItems: 'center', gap: 5 }}><DateRangeIcon style={{ fontSize: 15 }} /> Absence :</p>
                                                                {webcoachs.user[id].absence.map((date, index) => (
                                                                    <p key={index} style={{ margin: 0, fontSize: 12, textAlign: 'left' }}>du <b>{moment(date.startAt).format('DD-MM-YYYY')}</b> au <b>{moment(date.endAt).format('DD-MM-YYYY')}</b></p>
                                                                ))}
                                                            </> : <p style={{ margin: 0, fontSize: 13 }} >Aucune absence prévue.</p>
                                                        }
                                                        <div onClick={() => { setIsOpenOffuser(true); setWebCoachId(id); setIsAllUser(false) }} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: 5, margin: '5px 0 0 0' }}>
                                                            <p style={{ margin: 0, fontSize: 13 }} >Gérer ses absences</p>
                                                            <LaunchIcon style={{ fontSize: 13 }} />
                                                        </div>
                                                    </Box>
                                                </Box>
                                            ))}
                                    </Box>
                                }
                            </Box>
                        </ShadowBoxComponent>
                    </Grid>
                </Grid>
                <ManagerOffUser
                    options={optionWebcoachs}
                    userId={webCoachId}
                    setUserId={setWebCoachId}
                    isOpen={isOpenOffuser}
                    setIsOpen={setIsOpenOffuser}
                    isAllUser={isAllUser}
                    loadingData={getData}
                    {...props}
                />
            </ContentViewComponent>
        </Box >
    );
}

const useStyles = makeStyles({
    td: { width: '8%', fontSize: 12, padding: 12 },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    thSize: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            padding: 0,
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                padding: '15px 55px',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    h1: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 400,
        backgroundColor: '#FFF',
        margin: '0 0 20px 0',
        padding: 20,
        borderBottom: 'solid 1px #ccc'
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    },
    iconH3: {
        fontSize: 25,
        verticalAlign: 'sub',
        float: 'right'
    },
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    datepicker: {
        margin: 0,
        width: '100%',
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    textSearchSlider: {
        fontSize: 16,
        textAlign: 'center',
        height: 40,
        lineHeight: '40px',
        textTransform: 'capitalize'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    },
    icon: {
        position: 'absolute',
        top: 40,
        left: 15,
        fontSize: 30
    },
})

export default Manager;
