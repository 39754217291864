import React from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import moment from "moment";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Doughnut } from 'react-chartjs-2';
import { getRoutePathname } from "../../../Config/Route";
import ChartColorOpacity from "../../../Constant/ChartColorOpacity";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Card from "./Component/Card";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import CardV2 from './Component/CardV2';
import { set } from 'date-fns';


const ExpandableTableRow = ({ name, children, expandComponent, ...otherProps }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.thSize}>{name}</TableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <>
                    {expandComponent}
                </>
            )}
        </>
    );
};

function Dashboard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [textSearchSlider, setTextSearchSlider] = React.useState(moment().year());
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [numberHotlines, setNumberHotlines] = React.useState({});
    const [hotlinesByOrganism, setHotlinesByOrganism] = React.useState({});
    const [info, setInfo] = React.useState({});
    const [isReady, setIsReady] = React.useState(false);
    const [numberHotlinesNoTreated, setNumberHotlinesNoTreated] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());

    const changeSearchSlider = (direction = null) => {
        let newStartAt;

        if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
        else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
        else newStartAt = startAt;

        newStartAt = moment(newStartAt.startOf('year').format());

        setTextSearchSlider(newStartAt.format('YYYY'));
        setStartAt(newStartAt);
    };

    const getInfo = () => {
        if (startAt) {
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'support_hotline_dashboard_info_hotline',
                data: { year: startAt.format('YYYY') }
            },
                (response) => {
                    if (response && response.data) {
                        setNumberHotlines(response.data ? response.data : {});
                        setHotlinesByOrganism(response.data.hotlinesByOrganism ? response.data.hotlinesByOrganism : {});
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setReadyContent(true);
                });

            Api.get({
                route: 'support_hotline_dashboard_info_hotline_type',
                data: { year: startAt.format('YYYY') }
            },
                (response) => {
                    if (response && response.data) {
                        setInfo(response.data);
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setReadyContent(true);
                });

            Api.get({
                route: 'support_hotline_dashboard_no_treated',
                data: { year: startAt.format('YYYY') }
            },
                (response) => {
                    if (response && response.data) {
                        setNumberHotlinesNoTreated(response.data ? response.data : {});
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setReadyContent(true);
                });
        }
    };

    const getDataPercentage = (value) => {
        let tmpData = { 'data': [], 'labels': [] };
        if (info) {
            for (let hotlineType in info[value]) {
                tmpData['labels'].push(info[value][hotlineType].name);
                tmpData['data'].push(info[value][hotlineType].hotlineTypeTotal);
            }
        }
        return tmpData;
    };

    const dataPercentageUserType = {
        datasets: [{
            data: getDataPercentage('int')['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataPercentage('int')['labels'],
        options: {
            legend: {
                display: false,
                position: 'left',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                position: 'top',
                text: 'Type de hotline interne utiliser',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return 'Type utiliser ' + data['datasets'][0]['data'][tooltipItem['index']] + ' fois';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let datas = dataset['data'];
                        let reducer = (accumulator, curr) => accumulator + curr;
                        let percent = Math.round((dataset['data'][tooltipItem['index']] / datas.reduce(reducer)) * 100)
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };
    const dataPercentageProspectType = {
        datasets: [{
            data: getDataPercentage('ext')['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataPercentage('ext')['labels'],
        options: {
            legend: {
                display: false,
                position: 'left',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                position: 'top',
                text: 'Type de hotline externe utiliser',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return 'Type utiliser ' + data['datasets'][0]['data'][tooltipItem['index']] + ' fois';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let datas = dataset['data'];
                        let reducer = (accumulator, curr) => accumulator + curr;
                        let percent = Math.round((dataset['data'][tooltipItem['index']] / datas.reduce(reducer)) * 100)
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };
    const onClickButtonInternal = () => {
        props.history.push(getRoutePathname('support_user_hotline'))
    }
    const onClickButtonExternal = () => {
        props.history.push(getRoutePathname('support_prospect_hotline'))
    }

    React.useEffect(changeSearchSlider, []);
    React.useEffect(getInfo, [startAt]);

    React.useEffect(() => {
        if ((numberHotlines && numberHotlines.total && numberHotlines.hotlinesByOrganism)
            && (numberHotlinesNoTreated && numberHotlinesNoTreated.int >= 0 && numberHotlinesNoTreated.ext >= 0)
            && (dataPercentageProspectType && dataPercentageUserType)) {
            setIsReady(true);
        }
    }, [numberHotlines, numberHotlinesNoTreated, info]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Tableau de bord',
            context: 'Support',
            description: 'Hotlines.'
        });
    }, []);

    const renderHotlineType = (organism, id) => {
        if (info && info[id]) {
            let RenderTableHotlineType = Object.keys(info[id]).map((hotline, index) => {
                let RenderCellHotlineTypeTotal = Object.keys(hotlinesByOrganism[organism]['hotlines'][id]['year']).sort((a, b) => a < b ? -1 : 1).map((idMonth) => {
                    return (
                        <TableCell className={classes.td}>{hotlinesByOrganism[organism]['hotlines'][id]['year'][idMonth][info[id][hotline]['id']]['hotlineTypeTotal']}</TableCell>
                    )
                });
                return (
                    <TableRow>
                        <TableCell padding="checkbox" />
                        <TableCell key={index} className={classes.thSize}>{info[id][hotline]['name']}</TableCell>
                        {RenderCellHotlineTypeTotal}
                    </TableRow>
                )
            });
            return RenderTableHotlineType;
        }
    }

    const renderTableRow = (organism) => {
        let RenderTableRow = Object.keys(hotlinesByOrganism[organism]['hotlines']).map((id, index) => {
            let RenderCellHotlineTotal = Object.keys(hotlinesByOrganism[organism]['hotlines'][id]['year']).sort((a, b) => a < b ? -1 : 1).map((idMonth) => {
                return (
                    <TableCell className={classes.td}>{hotlinesByOrganism[organism]['hotlines'][id]['year'][idMonth]['total']}</TableCell>
                )
            });
            return (
                <ExpandableTableRow key={index} name={hotlinesByOrganism[organism]['hotlines'][id]['name']} children={RenderCellHotlineTotal} expandComponent={renderHotlineType(organism, id)} />
            );
        });
        return RenderTableRow;
    }


    let renderTable = Object.keys(hotlinesByOrganism).map((id, index) => {
        return (
            <>
                <ShadowBoxComponent>
                    <TitleComponent title={hotlinesByOrganism[id].organism + ' / Nombre d\'hotline par mois'} />

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell className={classes.thSize}>Hotlines</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTableRow(id)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>
                <br />
            </>
        );
    });

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <br />

            {isReady && <>

                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <div>
                                <ShadowBoxComponent>
                                    <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12} md={8} lg={12} style={{ position: 'relative' }}>
                                            <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')} />
                                            <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                            <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')} />
                                        </Grid>

                                    </Grid>
                                </ShadowBoxComponent>
                            </div>
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Card
                                title={'Hotlines Externe'}
                                number={numberHotlines.total.ext}
                                turnover={numberHotlines.total.ext}
                                color={'#000'}
                                label={'Nombre d’hotlines externe cette année'}
                                labelEmpty={'Aucune hotlines externe cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <Card
                                title={'Hotlines interne'}
                                number={numberHotlines.total.int}
                                turnover={numberHotlines.total.int}
                                color={'#000'}
                                label={'Nombre d’hotlines interne cette année'}
                                labelEmpty={'Aucune hotlines interne cette année'}
                            />
                        </Grid>
                    </Zoom>
                </Grid>

                <br />
                {renderTable}
                <br />


                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <CardV2
                                title={'Hotlines Externe'}
                                number={numberHotlinesNoTreated.ext}
                                turnover={numberHotlinesNoTreated.ext}
                                color={'#000'}
                                label={'Nombre d’hotlines externe en attente…'}
                                labelEmpty={'Toutes les hotlines externe on était traité'}
                                button={[{
                                    nameButton: 'ACCÉDER',
                                    onClick: onClickButtonExternal
                                }]}
                            />
                        </Grid>
                    </Zoom>
                    <br />
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <CardV2
                                title={'Hotlines interne'}
                                number={numberHotlinesNoTreated.int}
                                turnover={numberHotlinesNoTreated.int}
                                color={'#000'}
                                label={'Nombre d’hotlines interne en attente…'}
                                labelEmpty={'Toutes les hotlines interne on était traité'}
                                button={[{
                                    nameButton: 'ACCÉDER',
                                    onClick: onClickButtonInternal
                                }]}
                            />
                        </Grid>
                    </Zoom>
                </Grid>
                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <ShadowBoxComponent>
                                <Doughnut data={dataPercentageProspectType} options={dataPercentageProspectType.options} />
                            </ShadowBoxComponent>
                            <br />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <ShadowBoxComponent>
                                <Doughnut data={dataPercentageUserType} options={dataPercentageUserType.options} />
                            </ShadowBoxComponent>
                            <br />
                        </Grid>
                    </Zoom>
                </Grid>
                <br />
            </>}
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    td: { width: '8%', fontSize: 12, padding: 12 },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    thSize: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    up: { fontSize: 16, verticalAlign: 'bottom', color: '#008000' },
    upText: { fontSize: 11, verticalAlign: 'bottom', color: '#008000' },
    down: { fontSize: 16, verticalAlign: 'bottom', color: '#dc3545' },
    downText: { fontSize: 11, verticalAlign: 'bottom', color: '#dc3545' },
    equalText: { fontSize: 9, verticalAlign: 'middle', color: '#000' },
    textSearchSlider: { fontSize: 16, textAlign: 'center', height: 40, lineHeight: '40px', textTransform: 'capitalize' },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    },
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }

});

export default Dashboard;
