import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../Config/Route';
import { Box, Fade, Grid, makeStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import Api from '../../../Api';
import { listCard } from './Component/ListCard';


export default function Home(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [homeData, setHomeData] = React.useState([]);
    const [cardList, setCardList] = React.useState([]);
    const [cardView, setCardView] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [screen, setScreen] = React.useState(true);


    const User = (props = { onClick: () => { } }) => {
        return (
            <Box style={{ display: 'flex', alignItems: 'center', gap: 5, cursor: 'pointer' }} onClick={() => props.onClick()}>
                <Box style={{ textAlign: 'right' }}>
                    <p style={{ fontWeight: 'bold', margin: 0 }}>{authenticationReducer.firstname + ' ' + authenticationReducer.lastname}</p>
                    <p style={{ margin: 0 }}>{authenticationReducer.roles.includes("ROLE_SUPER_ADMIN") ? 'Super admin' : 'admin'}</p>
                </Box>
                <Box style={{ display: 'flex', background: '#5E6E82', borderRadius: 100, padding: 1 }}>
                    <AccountCircleIcon style={{ color: '#FFFFFF', fontSize: 25 }} />
                </Box>
            </Box>
        )
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Portail',
            path: getRoutePathname('institution_health_home'),
            context: 'Portail',
            description: 'Votre gestion client.',
            links: [],
            html: <User onClick={() => props.history.push(getRoutePathname('institution_health_admin_user', { id: authenticationReducer.id }))} />
        });
        if (selectOrganismReducer.organism) {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'institution_health_home',
                data: { organismId: selectOrganismReducer.organism.value }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setLoading(false);
                        let data = response.data;
                        setHomeData(data);
                    }
                    else if (response.status === 404) {
                        setLoading(true);
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        setLoading(true);
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                });
        } else setLoading(true);
        const card = listCard.find(item => item.roles.some(role => authenticationReducer.roles.includes(role)));
        if (card) {
            setCardList(card.card_list);
            setCardView(card.view);
        }
    }, [selectOrganismReducer.organism]);
    React.useEffect(() => { setScreen(screenReducer.screen !== 'LG' && screenReducer.screen !== 'XL') }, [screenReducer.screen])
    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
                <Fade in={true} {...{ timeout: 1000 }}>
                    <Box style={{ margin: 'auto', width: '95%', display: screen ? 'flex' : 'grid', flexDirection: 'column', gridTemplateAreas: cardView, gap: 20, padding: '20px 0' }}>
                        {Object.keys(cardList).map((index) => {
                            const CardComponent = cardList[index];
                            return (
                                <Box style={{ gridArea: index }} key={index} >
                                    <CardComponent data={homeData} authenticationReducer={authenticationReducer} {...props} />
                                </Box>
                            )
                        })}
                    </Box>
                </Fade>
            </ContentViewComponent >
        </Box >
    );
}

const useStyles = makeStyles({
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
})