import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Delete} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert-react';
import {TableReload} from "../../../Action/TableAction";
import {getRoutePathname} from "../../../Config/Route";

function AdministratorFormationGroupPropertyList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "year",
            label: "Année",
            options: {filter: true, sort: true}
        },
        {
            name: "formationGroupName",
            label: "Groupe de formation",
            options: {filter: true, sort: true}
        },
        {
            name: "timeYear",
            label: "Temps de formation",
            options: {filter: true, sort: true}
        },
        {
            name: "priceByHour",
            label: "Prix par heure",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('administrator_formation_group_property', {id: row.id}))}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des propriétés de groupe de formation',
            context: 'Administrateur',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'administrator_formation_group_property_list'}
                title={'Propriétés de groupe de formation'}
                columns={columns}
                actionFirst={{
                    label: 'Ajout de propriétés de groupe de formation',
                    onClick: () => props.history.push(getRoutePathname('administrator_formation_group_property', {id: null}))
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'administrator_formation_group_property_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <br/>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer les propriétés de ' + actionRow.formationGroupName + ' sur l\'année ' + actionRow.year + ' ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                            route: 'administrator_formation_group_property_delete',
                            params: {id: actionRow.id}
                        },
                        () => {
                            dispatch(TableReload('administrator_formation_group_property_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default AdministratorFormationGroupPropertyList;
