import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {Button, makeStyles} from "@material-ui/core";
import {getRoutePathname} from "../../../Config/Route";
import Fade from "@material-ui/core/Fade";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {TableReload} from "../../../Action/TableAction";
import {hourBySecondClassic} from "../../../Tool/convert";
import moment from 'moment';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Assignment, CloudDownload, PersonAddDisabled, Timer} from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import {cacheTableRowsPerPage} from "../../../Cache";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextFieldComponent from "../../../Component/TextFieldComponent";
import CreditFIFPL from './CreditFIFPL';
import InvoiceFIFPL from './InvoiceFIFPL';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ActivityFIFPL from './ActivityFIFPL';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow'

function LinearProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        } else if (value >= 33 && value < 66) {
            return '#db770a';
        } else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': {backgroundColor: getColor(props.value)}
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function BillingFIFPL(props) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isTall, setIsTall] = React.useState(true);
    const [sessionFormation, setSessionFormation] = React.useState({});
    const [isLock, setIsLock] = React.useState(true);
    const [showActivity, setShowActivity] = React.useState(false);
    const [registrationCancel, setRegistrationCancel] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [showInvoice, setShowInvoice] = React.useState(false);
    const [showCredit, setShowCredit] = React.useState(false);
    const [isBillable, setIsBillable] = React.useState(true);
    const [isBillableLock, setIsBillableLock] = React.useState(true);
    const [isRegistrationFinished, setIsRegistrationFinished] = React.useState(false);
    const [displayPrice, setDisplayPrice] = React.useState('');

    // SUPER VISION
    const [countSuperVision, setCountSuperVision] = React.useState(0);
    const [isSuperVision, setIsSuperVision] = React.useState(false);
    const superVision = () => {
        let count = countSuperVision + 1;
        if (count === 3) {
            setIsSuperVision(!isSuperVision);
            setCountSuperVision(0);
            dispatch(TableReload('billing_registration_list'));
        } else {
            setCountSuperVision(count);
        }
    };

    // FORM
    const [form, setForm] = React.useState({
        organism: {
            name: 'organism',
            label: ' ',
            textHelper: ' ',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        numberDPC: {
            name: 'numberDPC',
            label: 'Numéro de session',
            textHelper: 'Saisissez un numéro de session.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        durationOffline: {
            name: 'durationOffline',
            label: 'Durée en seconde',
            textHelper: 'Saisissez la durée de la formation hors connexion (en second).',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        fifpl: {
            name: 'fifpl',
            label: 'Numéro de programme',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {validation: ['required']}
        },
        formationDuration: {
            name: 'formationDuration',
            label: 'Durée de la formation',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {validation: ['required']}
        },
        formationName: {
            name: 'formationName',
            label: 'Nom de la formation',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {validation: ['required']}
        },
        startAt: {
            name: 'startAt',
            label: 'Début de session',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de session',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    // TABLE
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "finishAt",
            label: "Formation terminée le",
            options: {filter: true, sort: true}
        },
        {
            name: "durationConnectedText",
            label: "Temps de connexion",
            options: {filter: true, sort: true}
        },
        {
            name: "durationVirtualClassText",
            label: "Temps classe virtuelle",
            options: {filter: true, sort: true}
        },
        {
            name: "durationTotalSessionText",
            label: "Temps Total",
            options: {filter: true, sort: true}
        },
        {
            name: "stateText",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "completion",
            label: "Complétion",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    const getDataTable = (registrations, sessionFormation) => {
        let r = registrations;
        for (let index in r) {
            r[index]['finishAt'] = r[index]['finishAt'] !== '-' ? moment(r[index]['finishAt']).format('lll') : '-';
            r[index]['durationConnectedText'] = <span style={{color: (r[index]['durationConnected'] < r[index]['durationConnectedExpected']) ? '#AD732C' : '#28A745'}}>{hourBySecondClassic(r[index]['durationConnected'])}</span>;
            r[index]['durationVirtualClassText'] = <span style={{color: (r[index]['durationVirtualClass'] < r[index]['durationVirtualClassExpected']) ? '#AD732C' : '#28A745'}}>{hourBySecondClassic(r[index]['durationVirtualClass'])}</span>;
            r[index]['durationTotalSessionText'] = <span>{hourBySecondClassic(r[index]['durationTotalSession'])}</span>;
            r[index].completion = <div style={{width: 150}}><LinearProgressWithLabel value={r[index].completion}/></div>;
            switch (r[index]['state']) {
                case 1:
                    r[index]['stateText'] = <span style={{color: '#28A745'}}>Validée</span>;
                    break;
                case 2:
                    r[index]['stateText'] = <span style={{color: '#AD732C'}}>Annulée</span>;
                    break;
                case 3:
                    r[index]['stateText'] = <span style={{color: '#DC3545'}}>Supprimée</span>;
                    break;
                case 4:
                    r[index]['stateText'] = <span style={{color: '#35A2EB'}}>Facturée</span>;
                    break;
                case 5:
                    r[index]['stateText'] = <span style={{color: '#000000'}}>Non terminée</span>;
                    break;
                case 6:
                    r[index]['stateText'] = <span style={{color: '#67327b'}}>Temps manquant</span>;
                    break;
                case 7:
                    r[index]['stateText'] = <span style={{color: '#ad732c'}}>Non Facturable</span>;
                    break;
            }
            r[index]['action'] = getAction(r[index], sessionFormation);

            if (r[index]['state'] === 1 || r[index]['state'] === 4 || r[index]['state'] === 6) {
                setIsRegistrationFinished(true);
            }
        }

        return r;
    };
    const getAction = (row, sessionFormation) => {
        return (
            <div style={{width: 132}}>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <IconButton onClick={() => {
                        window.open(getRoutePathname('crm_prospect', {id: row.prospect}), '_blank').focus();
                    }} disabled={row.state === 3}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Désinscription'} placement="left">
                    <IconButton onClick={() => setRegistrationCancel(row.id)} disabled={row.state === 2 || row.state === 3 || moment().isBefore(moment(sessionFormation.startAt, 'DD/MM/YYYY'))}>
                        <PersonAddDisabled/>
                    </IconButton>
                </Tooltip>
                {isSuperVision && <Tooltip title={'Valider la formation et équilibrer le temps'} placement="left">
                    <IconButton onClick={() => {
                        dispatch(LinearProgressMainLayoutActivate());
                        Api.get({
                                route: 'financial_management_billing_fifpl_registration_time_balancing',
                                params: {id: row.id},
                            },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                dispatch(TableReload('billing_registration_list'));
                                if (response.status === 200) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Formation validée et temps équilibré.',
                                            variant: 'success',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }} disabled={(row.state !== 5 && row.state !== 6) || row.state === 7 || sessionFormation.isInvoice}>
                        <Timer/>
                    </IconButton>
                </Tooltip>}
            </div>
        );
    };

    const cancel = () => {
        setRegistrationCancel(0);
        Api.get({
                route: 'financial_management_relaunch_registration_cancel',
                params: {
                    registration: registrationCancel
                }
            },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );

                    dispatch(TableReload('billing_registration_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const billable = (isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        handler.setFormLoading(true);
        setLoading(true);
        Api.post({
                route: 'financial_management_billing_fifpl_session_formation_billable',
                params: {id: props.match.params.id},
                data: {isBillable: isBillable}
            },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                handler.setFormLoading(false);
                setLoading(false);
                if (!isBillable) setIsLock(true);
                setIsBillable(isBillable);
                dispatch(TableReload('billing_registration_list'));
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: isBillable ? 'Session formation facturable.' : 'Session formation non facturable.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    // INIT
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Facturation FIFPL',
            context: 'Gestion financière',
            description: 'Session formation.',
            links: [
                {path: getRoutePathname('financial_management_billing_fifpl_list'), label: 'Liste des sessions formation'}
            ]
        });
        setReadyContent(true);
        cacheTableRowsPerPage['billing_registration_list'] = 100;
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);
    React.useEffect(() => {
        if (sessionFormation) {
            if (isLock || !!(sessionFormation && sessionFormation.isInvoice)) {
                handler.setFormLoading(true);
            } else {
                handler.setFormLoading(false);
            }
        }
    }, [isLock, sessionFormation]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={isSuperVision} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div hidden={!isSuperVision} style={{
                    position: 'absolute',
                    top: 38,
                    background: '#dd3444ad',
                    right: 26,
                    padding: 5,
                    color: '#FFF',
                    borderRadius: 5,
                }}>Supervision
                </div>
            </Slide>
            <Fade in={true} {...{timeout: 1000}}>
                <ShadowBoxComponent>
                    <TitleComponent title={
                        <div onClick={superVision}>
                            {form.organism.value === 1 && <img src="/asset/images/logo_santeformapro.png" alt="logo" style={{height: 30, marginBottom: -8}}/>}
                            {form.organism.value === 2 && <img src="/asset/images/logo_revuedupraticiendpc.png" alt="logo" style={{height: 30, marginBottom: -8}}/>}
                            {form.organism.value === 3 && <img src="/asset/images/logo_actionsante.png" alt="logo" style={{height: 30, marginBottom: -8}}/>}
                            {form.organism.value === 4 && <img src="/asset/images/logo_timelia.png" alt="logo" style={{height: 30, marginBottom: -8}}/>}
                        </div>
                    }/>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{margin: 'auto'}}>
                            <TextFieldComponent name={'formationName'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={2} md={3} lg={3} xl={3}>
                            <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                <p style={{textAlign: 'center'}}>
                                    Prix: {displayPrice ? <span>{displayPrice} €</span> : '-'}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={5} md={3} lg={3} xl={3} style={{margin: 'auto'}}>
                            <TextFieldComponent name={'fifpl'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={5} md={3} lg={3} xl={3} style={{margin: 'auto'}}>
                            <TextFieldComponent name={'formationDuration'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'startAt'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'endAt'} handler={handler}/>
                        </Grid>
                    </Grid>
                    <div style={isTall ? {height: 35, marginTop: 10} : {marginTop: 10}}>
                        {isBillable ? <Button variant="contained"
                                              disabled={loading || sessionFormation.isInvoice}
                                              style={
                                                  isTall ?
                                                      {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9, color: '#fff', backgroundColor: (sessionFormation.isInvoice) ? '#959595' : '#DC3545'} :
                                                      {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, color: '#fff', backgroundColor: (sessionFormation.isInvoice) ? '#959595' : '#DC3545'}
                                              }
                                              onClick={() => billable(false)}>
                            Passer en non facturable
                        </Button> : <Button variant="contained"
                                            disabled={loading}
                                            style={
                                                isTall ?
                                                    {float: 'right', marginLeft: 10, fontSize: 12, opacity: 0.9, color: '#fff', backgroundColor: '#35A2EB'} :
                                                    {width: 'calc(100% - 20px)', marginLeft: 10, fontSize: 12, opacity: 0.9, color: '#fff', backgroundColor: '#35A2EB'}
                                            }
                                            onClick={() => billable(true)}>
                            Passer en facturable
                        </Button>}
                    </div>
                </ShadowBoxComponent>
            </Fade>
            <br/>
            <Fade in={true} {...{timeout: 1000}}>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <ShadowBoxComponent>
                                    <TitleComponent title={'Actions'} style={{marginTop: 10}}/>

                                    {(sessionFormation && sessionFormation.isInvoice) ?
                                        <Button
                                            onClick={() => setShowCredit(true)}
                                            disabled={isLock}
                                            variant="contained"
                                            className={classes.action}
                                            style={{color: '#fff', backgroundColor: (isLock) ? '#959595' : '#a54ec6'}}
                                            >
                                                Editer un avoir
                                        </Button>
                                        :
                                        <Button
                                            onClick={() => setShowInvoice(true)}
                                            disabled={isBillableLock || sessionFormation.isInvoice || !isRegistrationFinished}
                                            variant="contained"
                                            className={classes.action}
                                            style={{color: '#fff', backgroundColor: (isBillableLock || sessionFormation.isInvoice || !isRegistrationFinished) ? '#959595' : '#28A745'}}
                                            >
                                                Editer une facture
                                        </Button>
                                    }

                                    <Button
                                        onClick={() => {
                                                const url = Api.route({
                                                route: 'financial_management_billing_fifpl_session_formation_download_participation_certificate',
                                                params: {id: props.match.params.id},
                                                });
                                                window.open(url);
                                            }}
                                            style={{color: '#fff', backgroundColor: (isLock || !isRegistrationFinished) ? '#959595' : '#3f51b5'}}
                                            className={classes.action}
                                            disabled={isLock || !isRegistrationFinished}
                                            variant="contained"
                                            >
                                            Télécharger les attestations de participations
                                    </Button>

                                    <Button
                                        onClick={() => {
                                            const url = Api.route({
                                            route: 'financial_management_billing_fifpl_session_formation_download_follow_up_certificate',
                                            params: {id: props.match.params.id},
                                            });
                                            window.open(url);
                                        }}
                                        style={{color: '#fff', backgroundColor: (isLock || !isRegistrationFinished) ? '#959595' : '#3f51b5'}}
                                        className={classes.action}
                                        disabled={isLock || !isRegistrationFinished}
                                        variant="contained"
                                        >
                                            Télécharger les justificatifs de suivi
                                    </Button>

                                    <Button
                                        onClick={() => setShowActivity(true)}
                                        disabled={isLock}
                                        variant="contained"
                                        className={classes.action}
                                        style={{color: '#fff', backgroundColor: isLock ? '#959595' : '#747474'}}>
                                            Voir l'historique
                                    </Button>
                                </ShadowBoxComponent>
                            </Grid>

                            <Grid item xs={12}>
                                <ShadowBoxComponent style={{flex: 1, height: '100%'}}>
                                    <TitleComponent title={'Factures / Avoirs'} style={{marginTop: 10}}/>
                                    <TableContainer>
                                        <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                                            <TableBody>
                                                {
                                                    (sessionFormation && sessionFormation.invoicesAndCredits && sessionFormation.invoicesAndCredits.length > 0) ? sessionFormation.invoicesAndCredits.map((ic, i) => {
                                                        return <TableRow key={i}>
                                                            <TableCell style={{fontSize: 10}} component="th" scope="row">{ic.type}</TableCell>
                                                            <TableCell style={{fontSize: 10}}>{ic.id}</TableCell>
                                                            <TableCell align="right"><IconButton style={{padding: 5}} onClick={() => {
                                                                window.open(Api.route({
                                                                    route: ic.type === 'Facture' ? 'financial_management_billing_fifpl_invoice_download' : 'financial_management_billing_fifpl_credit_download',
                                                                    params: {id: ic.id},
                                                                }), '_blank');
                                                            }}>
                                                                <CloudDownload style={{fontSize: 20}}/>
                                                            </IconButton></TableCell>
                                                        </TableRow>;
                                                    }) : <div style={{textAlign: 'center', fontSize: 12}}>Aucun résultat.</div>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </ShadowBoxComponent>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TableComponent
                            id={'billing_registration_list'}
                            minHeight={'calc(100vh - 515px)'}
                            title={'Liste des inscriptions'}
                            columns={columns}
                            search={false}
                            promiseData={(resolve) => {
                                Api.get({
                                        route: 'financial_management_billing_fifpl_session_formation_get',
                                        params: {id: props.match.params.id}
                                    },
                                    (response) => {
                                        if (response.status === 200) {
                                            setDisplayPrice(response.data.price)
                                            handler.setDataApi(response.data);
                                            setSessionFormation(response.data);
                                            setIsLock(response.data.isLock);
                                            setIsBillableLock(response.data.isBillableLock);
                                            resolve(getDataTable(response.data.registrations, response.data));
                                        } else if (response.status === 404) {
                                            props.history.push(getRoutePathname('financial_management_billing_fifpl_list'));
                                            dispatch(
                                                SnackbarOpen({
                                                    text: response.error.message,
                                                    variant: 'warning',
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                SnackbarOpen({
                                                    text: 'Une erreur inattendue s\'est produite.',
                                                    variant: 'error',
                                                })
                                            );
                                        }
                                    });
                            }}
                            small={true}
                        />
                    </Grid>
                </Grid>
            </Fade>
            {showInvoice && <InvoiceFIFPL sessionFormation={sessionFormation} open={showInvoice} close={() => setShowInvoice(false)}/>}
            {showCredit && <CreditFIFPL sessionFormation={sessionFormation} open={showCredit} close={() => setShowCredit(false)}/>}
            <ActivityFIFPL sessionFormation={sessionFormation} open={showActivity} close={() => setShowActivity(false)}/>

            <SweetAlert
                show={registrationCancel > 0}
                title={'Désinscription'}
                text={'Vous êtes sur le point de désinscrire définitivement l\'inscription numéro ' + registrationCancel + '. \n\r Êtes-vous sûr de vouloir continuer ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={cancel}
                onCancel={() => setRegistrationCancel(0)}
            />
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    action: {
        fontSize: 10,
        width: '100%',
        marginBottom: 6
    }
});

export default BillingFIFPL;
