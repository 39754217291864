import React from 'react';
import {makeStyles} from "@material-ui/core";
import moment from "moment";
import {DateTimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";

function DateTimePickerComponent(props = {
    name: '',
    disabled: false,
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? moment(data.value) : null);
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;

    const onChange = (date) => {
        let val = null;
        if (date) {
            val = moment(date);
            if (val.isValid()) {
                let newForm = {...props.handler.form};
                newForm[props.name].value = val.format('YYYY-MM-DD HH:mm:ss');
                newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
                setError(newForm[props.name].error);
                props.handler.set(newForm);
            }
        }
        else {
            let newForm = {...props.handler.form};
            newForm[props.name].value = '';
            newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
            setError(newForm[props.name].error);
            props.handler.set(newForm);
        }
        setFocus(false);
        setValue(val);
    }
    const onBlur = () => {
    }
    const onFocus = () => {
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <DateTimePicker
            className={`
                ${classes.dateTimePicker}
                ${isFocus ? classes.dateTimePickerFocus : error ? classes.dateTimePickerError : value && !disabled && !props.disabled ? classes.dateTimePickerValid : ''}
            `}
            autoOk
            clearable
            animateYearScrolling
            inputVariant="outlined"
            margin="normal"
            clearLabel='Réinit'
            okLabel='Ok'
            cancelLabel='Annuler'
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="delete" disabled={disabled || props.disabled} style={{width: 16, height: 16, margin: 10}}>
                            <EventIcon style={disabled || props.disabled ? {color: 'rgba(117,117,117, 0.5)', width: 16} : {color: '#757575', width: 16}}/>
                        </IconButton>
                    </InputAdornment>
                )
            }}
            id={props.name}
            label={data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}
            format="dd/MM/yyyy - HH:mm"
            value={value}
            helperText={error && !isFocus ? error : data.textHelper}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled || props.disabled}
            ampm={false}
        />
    );
}

const useStyles = makeStyles({
    dateTimePicker: {
        width: '100%',
        marginTop: 0,
        '& input': {
            padding: '10px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    dateTimePickerFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    dateTimePickerValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& label': {
            color: '#008000 !important'
        }
    },
    dateTimePickerError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    }
});

export default DateTimePickerComponent;
