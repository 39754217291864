import {combineReducers, createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import LinearProgressMainLayoutReducer from "./Reducer/LinearProgressMainLayoutReducer";
import DrawerMainLayoutReducer from "./Reducer/DrawerMainLayoutReducer";
import ScreenReducer from "./Reducer/ScreenReducer";
import TableReducer from "./Reducer/TableReducer";
import AuthenticationReducer from "./Reducer/AuthenticationReducer";
import SelectOrganismReducer from "./Reducer/SelectOrganismReducer";
import SnackbarReducer from "./Reducer/SnackbarReducer";
import LoginPageReducer from "./Reducer/LoginPageReducer";
import MemoryReducer from "./Reducer/MemoryReducer";
import MediaReducer from "./Reducer/MediaReducer";
import FireworksReducer from './Reducer/FireworksReducer';

const rootReducer = combineReducers({
    LinearProgressMainLayoutReducer: LinearProgressMainLayoutReducer,
    DrawerMainLayoutReducer: DrawerMainLayoutReducer,
    ScreenReducer: ScreenReducer,
    TableReducer: TableReducer,
    AuthenticationReducer: AuthenticationReducer,
    SelectOrganismReducer: SelectOrganismReducer,
    SnackbarReducer: SnackbarReducer,
    LoginPageReducer: LoginPageReducer,
    MemoryReducer: MemoryReducer,
    MediaReducer: MediaReducer,
    FireworksReducer: FireworksReducer
});

const persistConfig = {
    key: 'root',
    storage,

    blacklist: ['LinearProgressMainLayoutReducer', 'ScreenReducer', 'TableReducer', 'SnackbarReducer', 'MediaReducer', 'FireworksReducer'],
    whitelist: ['DrawerMainLayoutReducer', 'AuthenticationReducer', 'LoginPageReducer', 'MemoryReducer', 'SelectOrganismReducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer);
let persistor = persistStore(store);

export default {store, persistor};
