import React from 'react';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import SweetAlert from "sweetalert-react";
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TableComponent from '../../../../Component/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutePathname } from '../../../../Config/Route';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { Delete, Edit } from '@material-ui/icons';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';


export default function InstitutionGroupList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState([]);
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom du groupe",
            options: { filter: true, sort: true }
        },
        {
            name: "institutionNumber",
            label: "Établissement",
            options: { filter: true, sort: true }
        },
        {
            name: "discountCount",
            label: "Réductions actives",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <Box>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_group', { id: row.id })) }}>
                        <Edit style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => { setShowConfirm(true); setActionRow(row) }}>
                        <Delete style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={'Liste des établissements'} onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_list')) }} />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Groupement d\'établissements',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des groupements d\'établissements',
            links: [
                { path: getRoutePathname('institution_health_crm_institution_list'), label: 'Liste des établissements' }
            ],
            html: <GetActionMore />
        });
        setReadyContent(true);
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_crm_institution_group_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><GroupWorkIcon style={{ width: 20, height: 20 }} />Groupe</h1>}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un groupement',
                            onClick: () => props.history.push(getRoutePathname('institution_health_crm_institution_group', { id: null })),
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_crm_institution_group_list',
                                data: { organismId: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data;
                                        for (let index in data) {
                                            data[index].name = data[index].name ? data[index].name : '-';
                                            data[index].action = getAction(data[index]);
                                        }
                                        resolve(data);
                                    } else resolve([]);
                                });
                        }}
                    />
                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={'\nÊtes-vous sur de vouloir supprimer ce groupe d\'établissement ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_crm_institution_group_delete',
                                params: { id: actionRow.id }
                            },
                                (response) => {
                                    dispatch(LinearProgressMainLayoutDeactivate());

                                    if (response.status === 200) {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Groupement supprimé.',
                                                variant: 'success',
                                            })
                                        );
                                        dispatch(TableReload('institution_health_crm_institution_group_list'));
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}
const useStyles = makeStyles({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
})