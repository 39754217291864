export const SnackbarOpen = (content) => ({
    type: 'SnackbarOpen',
    text: content.text,
    variant: content.variant,
    duration: (content.duration) ? content.duration : 3000
});

export const SnackbarClose = () => ({
    type: 'SnackbarClose'
});
