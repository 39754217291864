import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Zoom, makeStyles } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import ButtonComponent from '../../../Component/ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import SelectComponent from '../../../Component/SelectComponent';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { getRoutePathname } from '../../../Config/Route';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';

function CommercialUsersManagement(props) {
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [user, setUser] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [showDelete, setShowDelete] = React.useState(false);
    const [edit, setEdit] = React.useState({
        isShow: false,
        id: null
    });
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroups, setOptionFormationGroups] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const [optionRegistrationOrigins, setOptionRegistrationOrigins] = React.useState([]);
    const [showExportDialog, setShowExportDialog] = React.useState(false);
    const [loadingExport, setLoadingExport] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const [optionMonths, setOptionMonths] = React.useState([
        { value: 1, label: 'Janvier' },
        { value: 2, label: 'Février' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Avril' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juin' },
        { value: 7, label: 'Juillet' },
        { value: 8, label: 'Août' },
        { value: 9, label: 'Septembre' },
        { value: 10, label: 'Octobre' },
        { value: 11, label: 'Novembre' },
        { value: 12, label: 'Décembre' }
    ]);
    const [optionYears, setOptionYears] = React.useState([]);
    const currentYear = new Date().getFullYear();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [showThrowAdd, setShowThrowAdd] = React.useState(false);
    const [throwMessage, setThrowMessage] = React.useState([]);

    const columns = [
        {
            name: "preCommissionPercent",
            label: "Pré-commission",
            options: { filter: true, sort: true }
        },
        {
            name: "commissionPercent",
            label: "Commission totale",
            options: { filter: true, sort: true }
        },
        {
            name: "organismText",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupText",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeText",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrationOriginText",
            label: "Origine",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        commissionPercent: {
            name: 'commissionPercent',
            label: 'Pourcentage de commission totale',
            textHelper: 'Saisissez un pourcentage de commission totale.',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        preCommissionPercent: {
            name: 'preCommissionPercent',
            label: 'Pourcentage de pré-commission',
            textHelper: 'Saisissez un pourcentage de pré-commission.',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Sélectionner un organisme.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Sélectionner un groupe de formation.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Sélectionner un type de financement.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        registrationOrigin: {
            name: 'registrationOrigin',
            label: 'Origine',
            textHelper: 'Sélectionner une origine.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);
    
    const [formAdd, setFormAdd] = React.useState({
        commissionPercent: {
            name: 'commissionPercent',
            label: 'Pourcentage de commission totale',
            textHelper: 'Saisissez un pourcentage de commission totale.',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        preCommissionPercent: {
            name: 'preCommissionPercent',
            label: 'Pourcentage de pré-commission',
            textHelper: 'Saisissez un pourcentage de pré-commission.',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Sélectionner un organisme.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Sélectionner un groupe de formation.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Sélectionner un type de financement.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        registrationOrigin: {
            name: 'registrationOrigin',
            label: 'Origine',
            textHelper: 'Sélectionner une origine.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handlerAdd = formHandlerInit(formAdd, setFormAdd);

    const [formExport, setFormExport] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Saisissez une année.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        month: {
            name: 'month',
            label: 'Mois',
            textHelper: 'Saisissez un mois.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handlerExport = formHandlerInit(formExport, setFormExport);

    const Loader = () => {
        return (
            <span className={classes.loader}>
                <span className={`${classes.dot} ${classes.dot1}`}>.</span>
                <span className={`${classes.dot} ${classes.dot2}`}>.</span>
                <span className={classes.dot}>.</span>
            </span>
        )
    };

    const getAction = (row) => {
        return (
            <div style={{ minWidth: 90 }}>
                <Tooltip title={'Éditer'} placement="left">
                    <IconButton onClick={() => {
                        setEdit({ isShow: true, id: row.id });
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setEdit({ isShow: false, id: row.id });
                        setShowDelete(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    const save = () => {
        
        if ((edit.id && handler.checkError())) {
            console.log('Error');
        }
        else if ((!edit.id && handlerAdd.checkError())) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (edit.id) {
                Api.post({
                    route: 'crm_commercial_users_management_commission_percent_edit',
                    params: { id: props.match.params.id, percent: edit.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('crm_commercial_users_management_percents_list'));
                        setEdit({ isShow: false, id: null });
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'crm_commercial_users_management_commission_percent_add',
                    params: { id: props.match.params.id },
                    data: handlerAdd.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        if ((response.data.throwMessage && response.data.throwMessage.length > 0)) {
                            setThrowMessage(response.data.throwMessage);
                            setShowThrowAdd(true);
                        }
                        dispatch(
                            SnackbarOpen({
                                text: 'Pourcentages créés avec succès.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('crm_commercial_users_management_percents_list'));
                        setEdit({ isShow: false, id: null });
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);
    React.useEffect(handler.start, []);
    React.useEffect(handlerAdd.start, []);
    React.useEffect(handlerExport.start, []);
    React.useEffect(() => {

        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        }, (response) => { if (response && response.data) setOptionOrganisms(response.data) });

        Api.get({
            route: 'select_formation_groups'
        }, (response) => { if (response && response.data) setOptionFormationGroups(response.data) });

        Api.get({
            route: 'select_finance_types'
        }, (response) => { if (response && response.data) setOptionFinanceTypes(response.data) });

        Api.get({
            route: 'select_registration_origins'
        }, (response) => { if (response && response.data) setOptionRegistrationOrigins(response.data) });

        // Year
        let yearsTMP = [];
        for (let i = currentYear; i > 2021; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        setBreadcrumbs({
            title: 'Gestion utilisateurs commercial',
            context: 'CRM',
            description: 'Fiche commercial.',
            links: [
                { path: getRoutePathname('crm_commercial_users_management_list'), label: 'Liste des commerciales' }
            ]
        });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Zoom in={isReadyContent} {...{ timeout: 1500 }}>
                <ShadowBoxComponent>
                    <Grid container spacing={2}>
                        <Grid item xs={4} sm={3} md={3} lg={3} xl={3}>
                            <p style={{ fontWeight: 600 }}>Référence :</p>
                            <p style={{ fontWeight: 600 }}>Nom :</p>
                            <p style={{ fontWeight: 600 }}>Email :</p>
                            <p style={{ fontWeight: 600 }}>Organismes :</p>
                        </Grid>
                        <Grid item xs={8} sm={9} md={9} lg={9} xl={9}>
                            <p style={{ margin: !user && 0 }}> {user ? user.id : <Loader />}</p>
                            <p style={{ margin: !user && 0 }}> {user ? user.username : <Loader />}</p>
                            <p style={{ margin: !user && 0 }}> {user ? user.email : <Loader />}</p>
                            <p style={{ margin: !user && 0 }}> {user ? user.organism : <Loader />}</p>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: isTall ? 'flex-end' : 'center' }}>
                        <ButtonComponent
                            color={'#28a745'}
                            onClick={() => { setShowExportDialog(true) }}
                            label={'Export Commission'}
                            style={{ width: isTall ? '' : 'calc(100% - 20px)' }}
                        />
                    </div>
                </ShadowBoxComponent>
            </Zoom>

            <br />

            <TableComponent
                id={'crm_commercial_users_management_percents_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Pourcentages de commission'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter des pourcentages',
                    onClick: () => { setEdit({ isShow: true, id: null }); handlerAdd.reset() }
                }}
                promiseData={(resolve) => {
                    Api.get({
                        route: 'crm_commercial_users_management_get',
                        params: { id: props.match.params.id }
                    },
                        (response) => {
                            if (response.status === 200) {
                                let data = response.data.percents
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                    data[index].preCommissionPercent = data[index].preCommissionPercent + '%';
                                    data[index].commissionPercent = data[index].commissionPercent + '%';
                                }
                                setUser(response.data);
                                resolve(data);
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                                props.history.push(getRoutePathname('crm_commercial_users_management_list'));
                            }
                        })
                }}
            />

            <br />

            <Dialog open={edit.isShow} fullWidth={true} onClose={() => setEdit({ isShow: false, id: null })}>
                <DialogTitle style={{ fontSize: 15 }}>{edit.id ? 'Édition des pourcentage de commission' : 'Ajouter des pourcentage de commission'}</DialogTitle>
                <DialogContent>
                    <div>
                        {edit.id ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'preCommissionPercent'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'commissionPercent'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'organism'} handler={handler} options={optionOrganisms} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroups} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'financeType'} handler={handler} options={optionFinanceTypes} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'registrationOrigin'} handler={handler} options={optionRegistrationOrigins} />
                                </Grid>
                            </Grid>
                         : 
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'preCommissionPercent'} handler={handlerAdd} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'commissionPercent'} handler={handlerAdd} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <MultipleSelectComponent name={'organism'} handler={handlerAdd} options={optionOrganisms} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <MultipleSelectComponent name={'formationGroup'} handler={handlerAdd} options={optionFormationGroups} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <MultipleSelectComponent name={'financeType'} handler={handlerAdd} options={optionFinanceTypes} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <MultipleSelectComponent name={'registrationOrigin'} handler={handlerAdd} options={optionRegistrationOrigins} />
                                </Grid>
                            </Grid>
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setEdit({ isShow: false, id: null })} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showDelete}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ces pourcentages ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowDelete(false);
                    Api.delete({
                        route: 'crm_commercial_users_management_commission_percent_delete',
                        params: { id: props.match.params.id, percent: edit.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Pourcentages de commission supprimées.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('crm_commercial_users_management_percents_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowDelete(false)}
            />

            <br />

            <Dialog open={showExportDialog} fullWidth={true} onClose={() => setShowExportDialog(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{'Choix des dates de l\'export'}</DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectComponent name={'year'} handler={handlerExport} options={optionYears} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectComponent name={'month'} handler={handlerExport} options={optionMonths} />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loadingExport} onClick={() => setShowExportDialog(false)} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent
                        disabled={loadingExport}
                        color={'#28a745'}
                        label={'Valider export'}
                        onClick={() => {

                            let data = handlerExport.getData();
                            let currentMonth = moment().month() +1;
                            let currentYear = moment().year();
                            let username = user.username.toLowerCase().replace(/ /g, "_").replace(/[^a-zA-Z0-9._-]/g, "-");

                            if (handlerExport.checkError()) {
                                console.log('Handler Export Error');
                            }
                            else if ((parseInt(data.year) === currentYear && parseInt(data.month) > currentMonth)) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Le mois choisis n\'est pas encore passé.',
                                        variant: 'error',
                                    })
                                );
                            }
                            else {
                                handlerExport.setFormLoading(true);
                                setLoadingExport(true);
                                Api.postDownload({
                                    route: 'crm_commercial_users_management_export_commission',
                                    filename: 'export_commission_' + username + '_' + data.month + '_' + data.year +'.xls',
                                    params: { id: user.id },
                                    data: data
                                }, (response) => {
                                    if (response.status === 200) {
                                        setShowExportDialog(false);
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Une erreur inattendue s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                    handlerExport.setFormLoading(false);
                                    setLoadingExport(false);
                                })
                            }
                        }}
                    />
                </DialogActions>
            </Dialog>

            <br />

            <Dialog open={showThrowAdd} onClose={() => setShowThrowAdd(false)}>
                <DialogTitle style={{ fontSize: 18, textAlign: 'center', color: '#5E6E82' }}>{'Attention !'}</DialogTitle>
                <DialogContent>
                    <div className={classes.dialThrowMessage}>
                        <p style={{fontWeight: 600}}>Les pourcentages pour :</p>
                        <br />
                        {throwMessage?.map((data, i) => (
                            <p key={i}>{data}, </p>
                        ))}
                        <br />
                        <p style={{fontWeight: 600}}>existent déjà et n'ont pas été enregistrés.</p>
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <ButtonComponent onClick={() => setShowThrowAdd(false)} color={'#3f51b5'} label={'Compris'} />
                </DialogActions>
            </Dialog>

            <br />

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    loader: {
        display: 'inline-block',
        fontSize: '2em',
    },
    dot: {
        opacity: 0,
        animation: '$fade-in-out 1s infinite',
    },
    dot1: {
        animationDelay: '0.2s',
    },
    dot2: {
        animationDelay: '0.4s',
    },
    '@keyframes fade-in-out': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    dialThrowMessage: {
        '& > p': {
            margin: 0,
            color: '#5E6E82',
            fontSize: 12
        }
    }
});

export default CommercialUsersManagement;
