import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import Api, { LibraryResourceImage } from "../../../Api";
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import ButtonComponent from '../../../Component/ButtonComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch, theme } from "../../../App";
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from "sweetalert-react";
import VisibilityIcon from '@material-ui/icons/Visibility';
import SelectComponent from '../../../Component/SelectComponent';
import UploadComponent from '../../../Component/UploadComponent';
import EditorComponent from '../../../Component/EditorComponent';
import LoadBackgroundComponent from '../../../Component/LoadBackgroundComponent';
import TitleComponent from '../../../Component/TitleComponent';
import { Rating } from '@material-ui/lab';

const LibraryResourceList = (props) => {

    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [resource, setResource] = React.useState(null);
    const [resourceLink, setResourceLink] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [isOpenLearnerList, getIsOpenLearnerList] = React.useState(false);
    const [learnerList, getLearnerList] = React.useState([]);
    const [optionTypes, setOptionTypes] = React.useState([]);
    const [optionFormats, setOptionFormats] = React.useState([]);
    const [format, setFormat] = React.useState();
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "title",
            label: "Titre",
            options: { filter: true, sort: true }
        },
        {
            name: "subTitle",
            label: "Sous-titre",
            options: { filter: true, sort: true }
        },
        {
            name: "descriptionFormatted",
            label: "Description",
            options: { filter: true, sort: true }
        },
        {
            name: "link",
            label: "Lien",
            options: { filter: true, sort: true }
        },
        {
            name: "libraryResourceTypeText",
            label: "Type",
            options: { filter: true, sort: true }
        },
        {
            name: "libraryResourceFormatText",
            label: "Format",
            options: { filter: true, sort: true }
        },
        {
            name: "numberView",
            label: "Nombre de vu",
            options: { filter: true, sort: true }
        },
        {
            name: "average",
            label: "Note",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const columnsLearner = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Mail",
            options: { filter: true, sort: true }
        },
        {
            name: "organism",
            label: "Oragnisme",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroup",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "numberView",
            label: "Vu",
            options: { filter: true, sort: true }
        },
        {
            name: "ratingLearner",
            label: "Note",
            options: { filter: true, sort: true }
        },
        {
            name: "comment",
            label: "Commentaire",
            options: { filter: true, sort: true }
        },
    ];
    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez le titre de la ressource.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        description: {
            name: 'description',
            label: 'Description',
            textHelper: "Saisissez la description de la ressource.",
            type: 'text',
            defaultValue: '',
        },
        subTitle: {
            name: 'subTitle',
            label: 'Sous-titre',
            textHelper: "Saisissez le sous-titre de la ressource.",
            type: 'text',
            defaultValue: '',
        },
        link: {
            name: 'link',
            label: 'Lien',
            textHelper: "Saisissez le lien de la ressource.",
            type: 'text',
            defaultValue: '',
        },
        libraryResourceType: {
            name: 'libraryResourceType',
            label: 'Type de la ressource',
            textHelper: "Selectionnez le type de la ressource.",
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        libraryResourceFormat: {
            name: 'libraryResourceFormat',
            label: 'Format de la ressource',
            textHelper: "Selectionnez le format de la ressource.",
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        banner: {
            name: 'banner',
            label: 'Bannière ressource',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        pdf: {
            name: 'pdf',
            label: 'PDF',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        image: {
            name: 'image',
            label: 'Image',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        mp3: {
            name: 'mp3',
            label: 'MP3',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setResource(row.id);
                        setFormat(row.libraryResourceFormat)
                        setResourceLink(row.link)
                        setShow(true);
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setResource(row.id);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {

        Api.get({
            route: 'select_library_resource_types'
        },
            (response) => {
                if (response && response.data) {
                    setOptionTypes(response.data);
                }
            });

        Api.get({
            route: 'select_resource_type'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormats(response.data);
                }
            });

        setBreadcrumbs({
            title: 'Liste des ressources',
            context: 'Gestion marketing',
            description: ''
        });
        setReadyContent(true);
    }, []);


    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            let datas = handler.getData();
            let pdf;
            let image;
            let mp3;
            switch (parseInt(format)) {
                case 1:
                    pdf = datas.pdf;
                    datas.link = '';
                    break;
                case 4:
                    image = datas.image;
                    datas.link = '';
                    break;
                case 5:
                    mp3 = datas.mp3;
                    datas.link = '';
                    break;
            }

            if (resource) {
                Api.post({
                    route: 'marketing_manager_library_resource_edit',
                    data: datas,
                    params: { id: resource },
                    file: {
                        banner: datas.banner ? datas.banner : '',
                        image: image ? image : '',
                        mp3: mp3 ? mp3 : '',
                        pdf: pdf ? pdf : ''
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Ressource modifié.',
                                variant: 'success',
                            })
                        );
                        setResource(null);
                        setShow(false);
                        dispatch(TableReload('marketing_manager_library_resource_list'));
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'marketing_manager_library_resource_add',
                    data: datas,
                    file: {
                        banner: datas.banner ? datas.banner : '',
                        image: image ? image : '',
                        mp3: mp3 ? mp3 : '',
                        pdf: pdf ? pdf : ''
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Ressource enregistrée.',
                                variant: 'success',
                            })
                        );
                        setResource(null);
                        setShow(false);
                        dispatch(TableReload('marketing_manager_library_resource_list'));
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const [commandLoad, setCommandLoad] = React.useState(false);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            {commandLoad && <LoadBackgroundComponent text={'Récupération des données en cours...'} />}

            <TableComponent
                id={'marketing_manager_library_resource_list'}
                title={'Ressources'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une ressource',
                    onClick: () => { setShow(true); handler.reset(); setFormat(null) }
                }}
                actionSecond={{
                    label: 'Lancer la commande livestorm',
                    disabled: commandLoad,
                    onClick: () => {
                        setCommandLoad(true);
                        Api.post({
                            route: 'marketing_manager_library_resource_command_execute_livestorm'
                        }, (response) => {

                            console.log(response);
                            dispatch(
                                SnackbarOpen({
                                    text: 'Commande exécutée.',
                                    variant: 'success',
                                })
                            );
                            dispatch(TableReload('marketing_manager_library_resource_list'));
                            setCommandLoad(false);
                        });
                    }
                }}

                promiseData={(resolve) => {
                    Api.get({
                        route: 'marketing_manager_library_resource_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].numberView =
                                    <IconButton className={classes.settings} onClick={() => { getIsOpenLearnerList(true); getLearnerList(data[index]) }}>
                                        <VisibilityIcon style={{ width: 20 }} /><p>{data[index].viewByResource.numberView > 99 ? '99+' : data[index].viewByResource.numberView}</p>
                                    </IconButton>;

                                data[index].average = <Rating
                                    name="hover-feedback"
                                    value={data[index].viewByResource.average}
                                    className={classes.rating}
                                    precision={1}
                                    readOnly
                                />

                                if (data[index].description) {
                                    data[index].descriptionFormatted = data[index].description.replace(/(<([^>]+)>)/gi, "");
                                }
                            }
                            resolve(data);
                        });
                }}
            />
            <Dialog open={show} maxWidth={'xl'}>
                <DialogTitle style={{ fontSize: 15 }}>{resource ? 'Modifier une ressource' : 'Ajouter une ressource'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <div>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'title'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'subTitle'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <EditorComponent name={'description'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent name={'libraryResourceFormat'} handler={handler} options={optionFormats} onChange={(val) => { setFormat(val) }} />
                            </Grid>
                            {(parseInt(format) === 1 || parseInt(format) === 4 || parseInt(format) === 5) &&
                                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                    {(parseInt(format) === 1) && <UploadComponent name={'pdf'} handler={handler} accept=".pdf" />}
                                    {(parseInt(format) === 4) && <UploadComponent name={'image'} handler={handler} accept=".png" />}
                                    {(parseInt(format) === 5) && <UploadComponent name={'mp3'} handler={handler} accept=".mp3" />}
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'link'} handler={handler} disabled={parseInt(format) === 1 || parseInt(format) === 4 || parseInt(format) === 5} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectComponent name={'libraryResourceType'} handler={handler} options={optionTypes} />
                            </Grid>

                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <UploadComponent name={'banner'} handler={handler} accept=".png" preview={LibraryResourceImage + resource + '.png'} />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => {
                        setResource(null);
                        setShow(false)
                    }} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <br />

            <Dialog open={isOpenLearnerList} maxWidth={'xl'} onClose={() => getIsOpenLearnerList(false)}>
                <DialogContent className={classes.listLearnerStyle}>
                    <TableComponent
                        id={'learner'}
                        title={
                            <p style={{ margin: 0, fontSize: 14, lineHeight: '19px' }}>
                                <span style={{ fontSize: 20 }}><b>{learnerList.title}</b></span><br />
                                | Liste des apprenants
                            </p>
                        }
                        columns={columnsLearner}
                        offRefresh={true}
                        promiseData={(resolve) => {
                            let data = learnerList.viewByResource.learnerList
                            for (let index in data) {
                                data[index].ratingLearner = data[index].rating !== null ? <Rating
                                    name="hover-feedback"
                                    value={data[index].rating}
                                    className={classes.rating}
                                    precision={1}
                                    readOnly
                                /> : <p style={{ textAlign: 'center', }}>Aucune note</p>
                                data[index].comment = data[index].comment ? data[index].comment : 'Aucun commentaire'
                            }
                            
                            resolve(data)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent onClick={() => { getIsOpenLearnerList(false) }} color={'#5E6E82'} label={'Quitter la liste'} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette ressource ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'marketing_manager_library_resource_delete',
                        params: { id: resource }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ressource supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('marketing_manager_library_resource_list'));
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
};

const useStyles = makeStyles({
    settings: {
        padding: 10,
        display: 'block',
        width: '65%',
        borderRadius: 5,
        margin: ' 0 25px',
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    listLearnerStyle: {
        minWidth: '30vw',
        paddingTop: '0 !important',
        '& .MuiPaper-elevation4': {
            boxShadow: 'none'
        }
    },
    rating: {
        color: '#5E6E82',
    }
});

export default LibraryResourceList;
