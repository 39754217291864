import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import Api from "../../../Api";
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ButtonComponent from '../../../Component/ButtonComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from "sweetalert-react";

const ResourceTypeList = (props) => {

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [edit, setEdit] = React.useState({
        isEdit: false,
        idType: null
    });
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "typeName",
            label: "Nom du type",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "updatedAt",
            label: "Date de modification",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du type.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setEdit({ isEdit: true, idType: row.id });
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setEdit({ isEdit: false, idType: row.id });
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des types de ressources',
            context: 'Gestion de contenu',
            description: ''
        });
        setReadyContent(true);
    }, []);


    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (edit.idType) {
                Api.post({
                    route: 'content_manager_resource_type_edit',
                    data: handler.getData(),
                    params: { id: edit.idType }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Type de ressource modifié.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('content_manager_resource_type_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_resource_type_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Type de ressource enregistrée.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('content_manager_resource_type_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <TableComponent
                id={'content_manager_resource_type_list'}
                title={'Types des ressources'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un type de ressource',
                    onClick: () => setEdit({ isEdit: true, idType: null })
                }}

                promiseData={(resolve) => {
                    Api.get({
                        route: 'content_manager_resource_type_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].typeName = data[index].name;
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].updatedAt = moment(data[index].updatedAt).format('lll');
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={edit.isEdit} fullWidth={true}>
                <DialogTitle style={{ fontSize: 15 }}>{edit.idType ? 'Modifier le nom du type n°' : 'Ajouter un type'}{edit.idType}</DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'name'} handler={handler} />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setEdit({ isEdit: false, idType: edit.idType })} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ce type de ressource ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_resource_type_delete',
                        params: { id: edit.idType }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'type de ressource supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_resource_type_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
};

export default ResourceTypeList;