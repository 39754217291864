import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Grid, makeStyles, Tooltip, IconButton} from "@material-ui/core";
import { Edit, Note } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";
import FilterListIcon from "@material-ui/icons/FilterList";
import { TableReload } from "../../../Action/TableAction";
import { useDispatch, useSelector } from "react-redux";
import { cacheTablePage, cacheTableServer } from '../../../Cache';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import BoxPopupActionComponent from '../../../Component/BoxPopupActionComponent';
import TitleComponent from '../../../Component/TitleComponent';
import SelectComponent from '../../../Component/SelectComponent';
import CheckboxComponent from '../../../Component/CheckboxComponent';
import SelectAutocompleteComponent from '../../../Component/SelectAutocompleteComponent';
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";

function EquipmentList(props) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [showComment, setShowComment] = React.useState(false);
    const [organism, setOrganism] = React.useState(null);
    const [brand, setBrand] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [optionIsPer, setOptionIsPer] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: true, label: 'Équipement périphérique'},
        {value: false, label: 'Équipement non périphérique'},
    ]);
    const [optionIsStock, setOptionIsStock] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: true, label: 'Équipement disponible'},
        {value: false, label: 'Équipement non disponible'},
    ]);
    const [optionIsArchive, setOptionIsArchive] = React.useState([
        {value: 'all', label: 'Tous'},
        {value: true, label: 'Équipement archivé'},
        {value: false, label: 'Équipement non archivé'},
    ]);
    const [optionEquipmentBrands, setOptionEquipmentBrands] = React.useState([]);
    const [optionEquipmentModels, setOptionEquipmentModels] = React.useState([]);
    const [optionOwners, setOptionOwners] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionModels, setOptionModels] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionEquipmentType, setOptionEquipmentType] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "tag",
            label: "Etiquette",
            options: { filter: true, sort: true }
        },
        {
            name: "owner",
            label: "Attribuer",
            options: { filter: true, sort: true }
        },
        {
            name: "type",
            label: "Type",
            options: { filter: true, sort: true }
        },
        {
            name: "stateLabel",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "price",
            label: "Prix",
            options: { filter: true, sort: true }
        },
        {
            name: "fullModel",
            label: "Modèle",
            options: { filter: true, sort: true }
        },
        {
            name: "cpu",
            label: "CPU",
            options: { filter: true, sort: true }
        },
        {
            name: "gpu",
            label: "GPU",
            options: { filter: true, sort: true }
        },
        {
            name: "ram",
            label: "RAM",
            options: { filter: true, sort: true }
        },
        {
            name: "storage",
            label: "Stockage",
            options: { filter: true, sort: true }
        },
        {
            name: "serialId",
            label: "Numéro de série",
            options: { filter: true, sort: true }
        },
        {
            name: "doneAt",
            label: "Fait le",
            options: { filter: true, sort: true }
        },
        {
            name: "typeActionLabel",
            label: "type d'action",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('it_infrastructure_equipment', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                {
                    row.comment &&
                    <Tooltip title={'Note'} placement="left">
                        <IconButton onClick={() => {
                            setShowComment(true)
                            setComment(row.comment)
                        }}>
                            <Note />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    // Search
    const [formSearch, setFormSearch] = React.useState({
        isPeripheral: {
            name: 'isPeripheral',
            label: 'Type périphérique',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].isPeripheral ? cacheTableServer['it_infrastructure_equipment_list'].isPeripheral : 'all',
            options: {}
        },
        isStock: {
            name: 'isStock',
            label: 'En stock',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].isStock ? cacheTableServer['it_infrastructure_equipment_list'].isStock : 'all',
            options: {}
        },
        isArchive: {
            name: 'isArchive',
            label: 'Archives',
            type: 'boolean',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].isArchive ? cacheTableServer['it_infrastructure_equipment_list'].isArchive : false,
            options: {}
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Chercher par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].pole ? cacheTableServer['it_infrastructure_equipment_list'].pole : 'all',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Chercher par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].organism ? cacheTableServer['it_infrastructure_equipment_list'].organism : 'all',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Chercher par type de l\'équipement.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].type ? cacheTableServer['it_infrastructure_equipment_list'].type : 'all',
            options: {}
        },
        brand: {
            name: 'brand',
            label: 'Marque',
            textHelper: 'Chercher par marque.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].brand ? cacheTableServer['it_infrastructure_equipment_list'].brand : 'all',
            options: {}
        },
        model: {
            name: 'model',
            label: 'Modèle',
            textHelper: 'Chercher par modèle.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].brand ? cacheTableServer['it_infrastructure_equipment_list'].brand : 'all',
            options: {}
        },
        owner: {
            name: 'owner',
            label: 'Utilisatreur',
            textHelper: 'Chercher par utilisateur.',
            type: 'text',
            defaultValue: cacheTableServer['it_infrastructure_equipment_list'] && cacheTableServer['it_infrastructure_equipment_list'].owner ? cacheTableServer['it_infrastructure_equipment_list'].owner : 'all',
            options: {}
        },
        price: {
            name: 'price',
            label: 'Prix',
            textHelper: 'Saisissez le prix de l\'équipement.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        text: {
            name: 'text',
            label: 'Caractéristique',
            textHelper: 'Chercher par caractéristique.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['it_infrastructure_equipment_list'] = 0;
        cacheTableServer['it_infrastructure_equipment_list'].page = 0;
        cacheTableServer['it_infrastructure_equipment_list'][index] = value;
        dispatch(TableReload('it_infrastructure_equipment_list'))
    };

    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des équipements',
            context: 'Parc informatique',
            description: '',
        });

        setReadyContent(true);

        Api.get({
            route: 'select_organisms'
        },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionOrganisms([{value: 'all', label: 'Tous'}, ...data]);
                }
            });

        Api.get({
            route: 'select_equipment_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionEquipmentType(data);
                }
            });

        Api.get({
            route: 'select_users'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionOwners(data);
                }
            });

        Api.get({
            route: 'select_equipment_brands'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionEquipmentBrands(data);
                }
            });
    }, []);

    React.useEffect(() => {
        if (organism) {
            console.log(organism)
            Api.get({
                route: 'select_internal_poles',
                data: { organism: organism }
            },
                (response) => {
                    if (response && response.data) {
                        let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                        setOptionPoles(data);
                    }
                });
        } else setOptionPoles([{ value: 'all', label: 'Tous' }]);
    }, [organism])

    React.useEffect(() => {
        Api.get({
                route: 'select_equipment_models',
                data: { brand: brand, type: type }
            },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionModels(data);
                }
            });
    }, [brand, type])

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <BoxPopupActionComponent
                className={classes.boxPopupAction}
                title={<TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />}
                textButton={{
                    text: <p style={{ margin: 0, color: '#FFF', fontSize: 14 }}>Filtre</p>,
                    icon: <FilterListIcon style={{ color: '#FFF', margin: 'auto', transform: 'translate(-1%,5%)', width: 20, height: 'auto' }} />,
                    style: { padding: '0 10px' },
                }}
                dimensionsButton={{ width: 'auto', height: 30 }}
                closedBoxActive={false}
                isBoxPopup={true}
                direction={'top'}
                template={
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'type'} handler={handlerSearch} options={optionEquipmentType} onChange={(val) => {
                                    inputSearchChange('type', val); setType(val === 'all' ? null : val)
                                }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'brand'} handler={handlerSearch} options={optionEquipmentBrands} onChange={(val) => {
                                    inputSearchChange('brand', val); setBrand(val === 'all' ? null : val)
                                }}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'model'} handler={handlerSearch} options={optionModels} onChange={(val) => inputSearchChange('model', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <TextFieldComponent name={'price'} handler={handlerSearch}/>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => { inputSearchChange('organism', val); setOrganism(val === 'all' ? null : val) }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <SelectAutocompleteComponent name={'owner'} handler={handlerSearch} options={optionOwners} onChange={(val) => inputSearchChange('owner', val)} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <TextFieldComponent name={'text'} handler={handlerSearch}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'isPeripheral'} handler={handlerSearch} options={optionIsPer} onChange={(val) => inputSearchChange('isPeripheral', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <SelectComponent name={'isStock'} handler={handlerSearch} options={optionIsStock} onChange={(val) => inputSearchChange('isStock', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <CheckboxComponent name={'isArchive'} handler={handlerSearch} onChange={(val) => inputSearchChange('isArchive', val)} />
                            </Grid>
                        </Grid>
                    </Box>
                }
            />
            <br />
            <TableComponent
                id={'it_infrastructure_equipment_list'}
                title={'Équipements'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un équipement',
                    onClick: () => props.history.push(getRoutePathname('it_infrastructure_equipment', { id: null }))
                }}
                promiseServerData={(resolve, options) => {
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        type: (formSearch.type.value === 'all') ? '' : formSearch.type.value,
                        pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                        owner: (formSearch.owner.value === 'all') ? '' : formSearch.owner.value,
                        brand: (formSearch.brand.value === 'all') ? '' : formSearch.brand.value,
                        organism: (formSearch.organism.value === 'all') ? '' : formSearch.organism.value,
                        price: formSearch.price.value ? formSearch.price.value : '',
                        text: options.searchText ? options.searchText : '',
                        isPeripheral: (formSearch.isPeripheral.value === 'all') ? null : formSearch.isPeripheral.value,
                        isArchive: formSearch.isArchive.value,
                        isStock: (formSearch.isStock.value === 'all') ? null : formSearch.isStock.value,
                    };
                    Api.get({
                        route: 'it_infrastructure_equipment_list',
                        data: filters,
                    },
                        (response) => {
                            if (response.status === 200) {
                                let data = response.data.data;
                                let count = response.data.count;
                                for (let index in data) {
                                    data[index].doneAt = data[index].doneAt ? moment(data[index].doneAt).format('ll') : '-';
                                    data[index].cpu = data[index].cpu ? data[index].cpu : '-';
                                    data[index].gpu = data[index].gpu ? data[index].gpu : '-';
                                    data[index].ram = data[index].ram ? data[index].ram : '-';
                                    data[index].storage = data[index].storage ? data[index].storage : '-';
                                    data[index].owner = data[index].owner ? data[index].owner : '-';
                                    data[index].price = data[index].price ? data[index].price : '-';
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data, count);
                            } else resolve([]);
                        });
                }}
            />
            <br/>

            <Dialog open={showComment} maxWidth={'lg'} onClose={() => setShowComment(false)}>
                <DialogTitle>Commentaire</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p style={{ padding: '1rem' }}>{comment}</p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => setShowComment(false)}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    boxPopupAction: {
        '& .root-BoxPopupAction-Popup': {
            maxWidth: 700,
            height: 'auto',
            '& .MuiGrid-item': {
                maxWidth: 'initial',
                flexBasis: 'fit-content',
            }
        }
    },
    CheckboxStyle: {
        display: 'flex',
        gap: 21,
        flexWrap: 'wrap',
        '& .MuiFormControl-root': {
            width: 'auto'
        }
    }
});

export default EquipmentList;


