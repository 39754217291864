import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from "react-redux";
import { theme } from "../App";

export default function TabsComponent(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);

    let tabs = props.tabs ? props.tabs : [];
    let tab = props.tab ? props.tab : '';
    let tabPanels = Array.isArray(props.children) ? props.children : [props.children];

    const [value, setValue] = React.useState(props.tabDefault ? props.tabDefault : '');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (tab) setValue(tab);
    }, [tab]);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <div className={`${classes.root} ${!isTall && classes.rootSmall}`} style={props.style && props.style}>
            <AppBar position="static">
                <Tabs variant="scrollable" scrollButtons="auto" value={value} onChange={handleChange} centered={props.centered} TabIndicatorProps={{ style: { background: '#039be5' } }}>
                    {
                        tabs
                            .filter((tab) => !tab.hidden)
                            .map((tab) => {
                                return (
                                    <Tab
                                        {...a11yProps(tab.id)}
                                        key={tab.id}
                                        value={tab.id}
                                        label={tab.label}
                                        disabled={tab.disabled}
                                        wrapped={tab.wrapped} />
                                )
                            })
                    }
                </Tabs>
            </AppBar>
            {
                tabPanels.map((tabPanel) => {
                    return (
                        <TabPanel className={classes.tab} key={tabPanel.key} value={value} index={tabPanel.key}>
                            {tabPanel}
                        </TabPanel>
                    )
                })
            }
        </div>
    );
}

const useStyles = (theme) => makeStyles({
    root: {
        boxShadow: '0 0px 3px 0px rgba(0,0,0,0.3)',
        flexGrow: 1,
        borderRadius: 7,
        overflow: 'hidden',
        '& .MuiButton-label':{
            width:' max-content !important',
        },
        '& header': {
            background: '#fff',
            boxShadow: 'none',
            '& .MuiTabs-root': {
                background: 'none',
                height: 45,
                minWidth: 100,
                padding: 0,
                '& .MuiTabs-indicator': {
                    background: theme.colorPrimary + ' !important'
                },
                '& .MuiTabs-flexContainer': {
                    background: '#ffffff',
                    height: 48,
                    borderRadius: '5px 5px 0 0',
                    '& .MuiButtonBase-root': {
                        background: 'none',
                        fontSize: 13,
                        color: '#5E6E82',
                        fontWeight: 400,
                        '&.Mui-selected ': {
                            color: theme.colorPrimary,
                        },
                        '& .MuiTab-wrapper': {
                            textTransform: 'none'
                        }
                    }
                }
            }
        }
    },
    rootSmall: {
        '& header': {
            '& .MuiTabs-root': {
                '& .MuiTabs-flexContainer': {
                    '& .MuiButtonBase-root': {
                        fontSize: 10,
                    }
                }
            }
        }
    },
    tab: {
        background: '#fff',
        margin: 0,
        borderRadius: '0 0 5px 5px',
        overflow: 'hidden',
        '& > div': {
            padding: 0
        }
    }
});

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}
