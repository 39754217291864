import React from 'react';
import {Fade, LinearProgress as LinearProgressMUI, makeStyles} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import {theme} from "../../../../App";

function CardFormation(props) {
    const classes = useStyles(theme)();

    const [isHover, setIsHover] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [isSelected, setSelected] = React.useState(props.selected);

    React.useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <Fade in={props.show} {...{timeout: 500}}>
            <div style={{display: 'inline-block', position: 'relative'}} onClick={() => {props.onClick();} }>
                <ShadowBoxComponent className={classes.shadowBox} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon}/> {props.hours}h</div> : <></>}

                            <div className={`${classes.backgroundHover} ${!isSelected ? !isHover ? classes.backgroundHoverHidden : '' : ''}`}>
                                <div className={classes.backgroundHoverButton}>{isSelected ? 'Retirer' : 'Ajouter'} la formation</div>
                            </div>

                            {!load && <img src="/asset/images/preload_image.png" className={classes.cardImagePreload} alt={'Image formation'}/>}
                            <CardMedia style={props.isAdd ? {
                                width: 200,
                                margin: 'auto',
                                height: 100,
                            } : {}} component="img" height="100" image={props.image && props.image} onLoad={() => setLoad(true)}/>

                            <CardContent className={classes.cardContent}>
                                <LinearProgressMUI className={classes.linearProgress} hidden={load}/>
                                <Typography className={classes.cardText} variant="body2" color="textSecondary" component="p">
                                    {props.title && props.title}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </ShadowBoxComponent>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles( ({
    shadowBox: {
        margin: '10px',
        padding: 0,
        height: 200,
        width: 200,
        textAlign: 'center',
        boxSizing: 'border-box',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
        display: 'inline-block'
    },
    card: {
        boxShadow: 'none',
        height: 200,
        width: 200,
    },
    cardImagePreload: {
        height: 100,
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardContent: {
        height: 100,
        position: 'relative'
    },
    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '5px 5px',
        borderRadius: '0 0 5px 0',
        margin: 0,
        fontSize: 12,
        top: 0,
        left: 0
    },
    cardHourIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 12,
        bottom: 0,
        right: 0
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    backgroundHover: {
        position: 'absolute',
        top: 0,
        height: 100,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        boxSizing: 'border-box',
        transition: 'all 700ms',
        opacity: 1,
        zIndex: 2
    },
    backgroundHoverHidden: {
        opacity: 0
    },
    backgroundHoverButton: {
        color: '#FFFFFF',
        border: '1px solid #FFFFFF',
        padding: 10,
        margin: '30px 20px'
    },
    linearProgress: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    }
}));

export default CardFormation;
