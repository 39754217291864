import { Box, Button, Fade, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, makeStyles } from '@material-ui/core';
import { CloudDownload, Send, Create, MoneyOff, GetApp } from '@material-ui/icons';
import React from 'react';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TitleComponent from '../../../Component/TitleComponent';
import Api from '../../../Api';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { dispatch } from '../../../App';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";

function Action(props = {
    reloadList: () => {
    }
}) {
    const classes = useStyles();
    const [buttons, setButtons] = React.useState([]);
    const [sessionFormation, setSessionFormation] = React.useState();
    const [propsReady, setPropsReady] = React.useState(false);
    const downloadParticipationCertificate = () => {
        const url = Api.route({
            route: 'financial_management_billing_v2_session_formation_download_participation_certificate',
            params: { id: props.match.params.id },
        });
        window.open(url);
    }

    const downloadKitParticipation = () => {
        const url = Api.route({
            route: 'financial_management_billing_v2_session_formation_download_kit_participation',
            params: { id: props.match.params.id },
        });
        window.open(url);
    }

    const sendParticipationCertificate = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'financial_management_billing_v2_session_formation_send_participation_certificate',
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Attestations envoyées.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    const followUpCertificate = (invoice) => {
        const url = Api.route({
            route: 'financial_management_billing_v2_session_formation_download_follow_up_certificate',
            params: { id: props.match.params.id },
            data: { invoice: invoice }
        });
        window.open(url);
    }

    const downloadInvoice = (type, id) => {
        window.open(Api.route({
            route: type === 'Facture' ? 'financial_management_billing_v2_invoice_download' : 'financial_management_billing_v2_credit_download',
            params: { id: id },
        }), '_blank');
    }

    React.useEffect(() => {
        if (Object.keys(props.sessionFormation).length > 0) {
            setPropsReady(true);
            setSessionFormation(props.sessionFormation);
            setButtons([
                {
                    label: 'Éditer une facture', action: props.openInvoice,
                    color: '#4caf50', icon: <Create className={classes.icon} />,
                    disabled: props.sessionFormation.isLock || !props.sessionFormation.numberDPC || props.sessionFormation.isInvoice
                },
                {
                    label: 'Télécharger toutes les attestation de participation', action: downloadParticipationCertificate,
                    color: '#007bff', icon: <CloudDownload className={classes.icon} />,
                    disabled: props.sessionFormationisLock || !props.sessionFormation.isInvoice
                },
                {
                    label: 'Télécharger les justificatifs de suivi DPC', action: () => followUpCertificate(null),
                    color: '#007bff', icon: <CloudDownload className={classes.icon} />,
                    disabled: false
                },
                {
                    label: 'Envoyer toutes les attestations de participation ', action: sendParticipationCertificate,
                    color: '#ba59de', icon: <Send className={classes.icon} />,
                    disabled: props.sessionFormationisLock || !props.sessionFormation.isInvoice
                },
                {
                    label: 'Télécharger informations kit participant', action: downloadKitParticipation,
                    color: '#007bff', icon: <CloudDownload className={classes.icon} />,
                    disabled: props.sessionFormation.isLock
                }
            ])
        }
    }, [props.sessionFormation]);

    return (
        propsReady ?
            <div style={{ padding: 4, position: 'relative' }}>
                <ShadowBoxComponent>
                    <TitleComponent title={<span style={{ fontWeight: 600 }}>Action</span>} />
                    <div className={classes.btnContainer}>
                        {buttons?.map((btn, i) => {
                            return <div className={classes.root} key={i}>
                                <Button
                                    style={{ background: btn.disabled ? '#959595' : btn.color }}
                                    variant="contained"
                                    className={classes.button}
                                    onClick={btn.action}
                                    disabled={btn.disabled}
                                >
                                    <div className={classes.column}>
                                        {btn.icon}
                                        {btn.label}
                                    </div>
                                </Button>
                            </div>
                        })}
                    </div>
                    <br />
                    <TitleComponent title={<span style={{ fontWeight: 600 }}>Facture / Avoir</span>} />
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableBody>
                                {
                                    (sessionFormation && sessionFormation.invoicesAndCredits && sessionFormation.invoicesAndCredits.length > 0) ?
                                        sessionFormation.invoicesAndCredits.map((ic, i) => {
                                            return <TableRow key={i}>
                                                <TableCell style={{ fontSize: 10 }} component="th" scope="row">{(ic.type === 'Facture' && ic.isCancel) ? 'Facture annulée' : ic.type}</TableCell>
                                                <TableCell style={{ fontSize: 10 }}>{ic.id}</TableCell>
                                                <TableCell style={{ fontSize: 10 }}>{ic.typeUnits}</TableCell>
                                                <TableCell style={{ fontSize: 10 }}>{ic.date}</TableCell>

                                                <TableCell align="center">
                                                    <Tooltip title={ic.type === 'Facture' ? 'Télécharger la facture' : 'Télécharger l\'avoir'} arrow placement='left'>
                                                        <IconButton
                                                            style={{ padding: 5 }}
                                                            onClick={() => downloadInvoice(ic.type, ic.id)}
                                                        >
                                                            <GetApp style={{ fontSize: 20 }} />
                                                        </IconButton>
                                                    </Tooltip>

                                                    

                                                    {(ic.type === 'Facture' && !ic.isCancel) &&
                                                        <>
                                                        <Tooltip title={'Générer un avoir'} arrow placement='left'>
                                                            <IconButton
                                                                style={{ padding: 5 }}
                                                                onClick={() => props.openCredit(ic.id)}
                                                            >
                                                                <MoneyOff style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title={'Télécharger le justificatif de suivi DPC'} arrow placement='left'>
                                                            <IconButton
                                                                style={{ padding: 5 }}
                                                                onClick={() => followUpCertificate(ic.id)}
                                                            >
                                                                <CloudDownload style={{ fontSize: 20 }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        </>
                                                    }
                                                </TableCell>
                                            </TableRow>;
                                        })
                                        :
                                        <TableRow>
                                            <TableCell style={{ textAlign: 'center', fontSize: 12 }}>Aucun résultat.</TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>
            </div>

            :

            <Fade in={true} {...{ timeout: 1000 }}>
                <Box className={classes.loader}>
                    <Box className={classes.loaderContent}>
                        <Box className={classes.loaderActivity}>
                            <UseAnimations animation={activity} size={70} />
                        </Box>
                    </Box>
                </Box>
            </Fade>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    btnContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 13
    },
    button: {
        width: 210,
        height: 85,
        textTransform: 'none',
        color: '#ffffff',
        borderRadius: 15,
        boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.3) !important',
        '&:hover': {
            opacity: 0.8,
        },
    },
    icon: {
        fontSize: '17px',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    }
}));

export default Action;