import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { Fade, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import CardFormation from "./Component/CardFormation";
import Grid from "@material-ui/core/Grid";
import { inArray } from '../../../Tool/ArrayCommon';

const Catalog = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [sizeView, setSizeView] = React.useState('');
    const [formationsCatalogLeaner, setFormationsCatalogLeaner] = React.useState([]);
    const [formationsCatalogLeanerNotAvailable, setFormationsCatalogLeanerNotAvailable] = React.useState([]);
    const [isTall, setIsTall] = React.useState(true);
    const [thematicsList, setThematicsList] = React.useState([]);
    const [thematicActive, setThematicActive] = React.useState();

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setBreadcrumbs({
            currentPage: 'Catalogue de formations',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        Api.get({
            route: 'learner_catalog_list',
            data: {
                organism: theme.id
            }
        },
            (response) => {
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setFormationsCatalogLeaner(response.data.available);
                    setFormationsCatalogLeanerNotAvailable(response.data.notAvailable);
                    let data = [];
                    let listThematic = [];
                    let dataAvailable = response.data.available;
                    let dataNotAvailable = response.data.notAvailable;

                    for (let index in dataAvailable) {
                        for (let i in dataAvailable[index].thematics) {
                            if (!(inArray(dataAvailable[index].thematics[i].id, data))) {
                                data.push(dataAvailable[index].thematics[i].id);
                                listThematic.push(dataAvailable[index].thematics[i]);
                            }
                        }
                    }
                    for (let key in dataNotAvailable) {
                        for (let k in dataNotAvailable[key].thematics) {
                            if (!(inArray(dataNotAvailable[key].thematics[k].id, data))) {
                                data.push(dataNotAvailable[key].thematics[k].id);
                                listThematic.push(dataNotAvailable[key].thematics[k]);
                            }
                        }
                    }
                    setThematicsList(listThematic);
                }, 1000);
            });
    }, []);

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: 1240, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <div style={{ padding: 15 }}>
                                <div style={{ overflow: sizeView === 'XS' ? 'hidden' : '', paddingTop: 20, paddingBottom: 20, width: (sizeView === 'XS' || sizeView === 'SM') ? '100%' : '80%', margin: 'auto' }}>

                                    {((thematicsList.length > 0) && (formationsCatalogLeanerNotAvailable || formationsCatalogLeaner)) &&
                                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 500 }}>Recherchez par thématique</p>
                                    }
                                    {(formationsCatalogLeanerNotAvailable || formationsCatalogLeaner) &&
                                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginBottom: 25 }}>
                                            {thematicsList?.map((thematic) => (
                                                <div
                                                    key={thematic.id}
                                                    className={classes.btnThematic}
                                                    onClick={() => {
                                                        if (thematicActive === thematic.id) {
                                                            setThematicActive(null);
                                                        }
                                                        else {
                                                            setThematicActive(thematic.id);
                                                        }
                                                    }}
                                                    style={{ background: thematicActive === thematic.id ? '#F2F2F2' : theme.colorPrimary }}
                                                >
                                                    <span style={{ color: thematicActive === thematic.id ? theme.colorPrimary : '#fff', fontWeight: 500 }}>{thematic.label}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                    {formationsCatalogLeanerNotAvailable &&
                                        <>
                                            <h1 className={classes.title}>
                                                Formations bientôt disponibles
                                            </h1>
                                            <Grid container spacing={3}>
                                                {
                                                    formationsCatalogLeanerNotAvailable.map((formationCatalogLeaner, index) => {
                                                        if (!thematicActive || inArray(thematicActive, formationCatalogLeaner.thematicsId)) {
                                                            return <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                                                                <CardFormation
                                                                    notAvailable={true}
                                                                    show={true}
                                                                    hours={formationCatalogLeaner.hours}
                                                                    image={learnerHomeFormationImageBanner + formationCatalogLeaner.image}
                                                                    title={formationCatalogLeaner.name}
                                                                    description={formationCatalogLeaner.description}
                                                                    financeTypes={formationCatalogLeaner.financeTypes}
                                                                    untilAvailable={formationCatalogLeaner.untilAvailable}
                                                                    isEPP={formationCatalogLeaner.isEPP}
                                                                    onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formationCatalogLeaner.id })) }}
                                                                />
                                                            </Grid>
                                                        }
                                                        else { return }
                                                    })
                                                }
                                            </Grid>
                                            <br />
                                        </>}

                                    <h1 className={classes.title}>
                                        Formations disponibles
                                    </h1>
                                    <Grid container spacing={3}>
                                        {formationsCatalogLeaner ?
                                            formationsCatalogLeaner.map((formationCatalogLeaner, index) => {
                                                if (!thematicActive || inArray(thematicActive, formationCatalogLeaner.thematicsId)) {
                                                    return <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                                                        <CardFormation
                                                            show={true}
                                                            hours={formationCatalogLeaner.hours}
                                                            image={learnerHomeFormationImageBanner + formationCatalogLeaner.image}
                                                            title={formationCatalogLeaner.name}
                                                            description={formationCatalogLeaner.description}
                                                            financeTypes={formationCatalogLeaner.financeTypes}
                                                            untilAvailable={formationCatalogLeaner.untilAvailable}
                                                            isEPP={formationCatalogLeaner.isEPP}
                                                            onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formationCatalogLeaner.id })) }}
                                                        />
                                                    </Grid>
                                                }
                                                else { return }
                                            }) : <p className={classes.error}>Aucune formations</p>
                                        }
                                    </Grid>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>

    );
};

const useStyles = (theme) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'left',
        background: '#4f93a5',
        width: 'max-content',
        padding: '7px 20px'
    },
    btnThematic: {
        cursor: 'pointer',
        width: 'max-content',
        padding: '10px 20px',
        margin: 5,
        borderRadius: 5
    },
    error: {
        fontSize: 30,
        width: '100%',
        color: '#4d4c4c2e',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    }
}));

export default Catalog;
