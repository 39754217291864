import React from "react";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { dispatch } from "../../../App";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Api from "../../../Api";
import { Box, Dialog, DialogActions, DialogContent, Fade, Grid, makeStyles } from "@material-ui/core";
import SelectComponent from "../../../Component/SelectComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import moment from "moment";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";


const RelaunchAddTask = (props) => {
    const classes = useStyles();
    const [registrationList, setRegistrationList] = React.useState([]);
    const [registrationId, setRegistrationId] = React.useState(null);
    const [loadingRegistration, setLoadingRegistration] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const refRegistartion = React.useRef(null)

    // Form
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Titre',
            textHelper: 'Saisissez un titre',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        todoAt: {
            name: 'todoAt',
            label: 'Date',
            textHelper: 'Sélectionnez une date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        note: {
            name: 'note',
            label: 'Note',
            textHelper: 'Saisissez une note',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Choisissez le type.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        prospect: {
            name: 'prospect',
            label: '',
            textHelper: '',
            type: 'integer',
            defaultValue: props.prospectId,
            options: { validation: ['required'] }
        },
        registration: {
            name: 'registration',
            label: '',
            textHelper: '',
            type: 'integer',
            defaultValue: registrationId,
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError() || registrationId === null) {
            if (registrationId === null) refRegistartion.current.style.border = 'solid 1px #982525'
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            Api.post({
                route: 'webcoach_relaunch_add',
                data: handler.getData()
            }, (response) => {
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false)
                if (response.status === 201) {
                    props.setIsOpen(false)
                    props.getLoadList();
                    dispatch(
                        SnackbarOpen({
                            text: 'Tàche ajouté.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    React.useEffect(() => {
        if (registrationId) {
            handler.setValue('registration', registrationId);
            refRegistartion.current.style.border = 'solid 0px'
        }
    }, [registrationId]);

    React.useEffect(() => {
        if (props.prospectId) {
            setLoadingRegistration(true)
            handler.setValue('prospect', props.prospectId)
            Api.get({
                route: 'webcoach_relaunch_registrations',
                params: { prospect: props.prospectId }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    let data = response.data;
                    setRegistrationList(data);
                    setLoadingRegistration(false);
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error,
                            variant: 'error',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }, [props.prospectId]);

    return (
        <Dialog open={props.isOpen} className={classes.dialogStyle} onClose={() => { props.setIsOpen(false); setRegistrationId(null); setRegistrationList([]) }}>
            <DialogContent style={{ padding: 30, paddingBottom: 10 }}>
                <Box style={{ width: '100%', margin: 0, gap: 35, display: 'flex' }}>
                    <Box style={{ width: '100%', margin: 0,  display: 'flex', flexDirection: 'column' }}>
                        <p style={{ margin: 0, fontSize: 17, fontWeight: 'bold', color: '#5E6E82' }}>Séléctionnez un formation :</p>
                        <Box ref={refRegistartion} style={{ position: 'relative', overflow: 'hidden auto', background: '#EFF2F4', borderRadius: 5, boxShadow: 'inset 0px 0px 3px #00000026', width: '100%', height: '100%', display: 'grid', gridTemplateRows: 'max-content', gap: 8 }}>
                            <Box style={{ position: 'absolute', display: 'flex', flexDirection: 'column', gap: 15, width: '90%', height: registrationList.length != 0 ? 'auto' : '100%', padding: registrationList.length != 0 ? '26px 0' : 0, left: '50%', transform: 'translate(-50%)' }}>
                                {loadingRegistration ?
                                    <Fade in={true} {...{ timeout: 1000 }}>
                                        <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex', }}>
                                            <Box className={classes.loaderContent}>
                                                <Box className={classes.loaderActivity}>
                                                    <UseAnimations animation={activity} size={70} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Fade>
                                    : (registrationList.length != 0 ? registrationList.map((registration, index) => (
                                        <Fade in={true} {...{ timeout: 500 }} key={index}>
                                            <Box>
                                                <ShadowBoxComponent
                                                    className={classes.styleRegistrationCard}
                                                    style={{ opacity: (registrationId === registration.id) || (registrationId === null) ? 1 : 0.4 }}
                                                    onClick={() => { setRegistrationId(registration.id) }}
                                                >
                                                    <Box style={{ lineHeight: '18px' }}>
                                                        <p style={{ margin: 0 }}>
                                                            <span style={{ fontSize: 12 }}>formation :</span><br />
                                                            <b>{registration.formation}</b>
                                                        </p>
                                                        <p style={{ margin: 0, fontSize: 13 }}>
                                                            Session :<br />
                                                            <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(-1px, 26%) scaleX(-1)" }} /><b>du</b> {moment(registration.startAt).format('ll')} <b>au</b> {moment(registration.endAt).format('ll')}
                                                        </p>
                                                        <p style={{ display: 'flex', alignItems: 'centers', margin: 0, gap: 5, alignItems: 'center' }}><b>Taux d'avancement : </b>{registration.completion} %</p>
                                                    </Box>
                                                </ShadowBoxComponent>
                                            </Box>
                                        </Fade>
                                    )) : <Fade in={true} {...{ timeout: 500 }} ><p><span className={classes.error}>Aucune formation</span></p></Fade>)
                                }
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ width: '100%' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <h1 style={{ fontSize: 20, color: '#5E6E82', display: 'flex', alignItems: 'center', gap: 5 }}><PlaylistAddIcon />Ajouter une tàche</h1>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'name'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent name={'type'} handler={handler} options={props.options} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DatePickerComponent name={'todoAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'note'} handler={handler} multiline={true} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { props.setIsOpen(false); setRegistrationId(null); setRegistrationList([]) }} />
                <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles({
    dialogStyle: {
        '& .MuiDialog-paper': {
            maxWidth: 1100
        }
    },
    error: {
        margin: 'auto',
        fontSize: 24,
        fontWeight: 'bold',
        opacity: 0.2,
        width: 'fit-content',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    },
    styleRegistrationCard: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        cursor: 'pointer',
        scale: 1,
        transition: 'all .4s',
        '&:hover': {
            scale: 1.02,
            opacity: '1 !important'
        }
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
});

export default RelaunchAddTask;