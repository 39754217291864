import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {Line} from 'react-chartjs-2';
import Api from "../../../Api";
import {useDispatch} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";

function Dashboard(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [datasRegistrations, setDatasRegistrations] = React.useState({labels: [], datas: {success: [], cancel: []}});

    // Chart Commission
    const dataChartRegistrations = {
        datasets: [
            {
                data: datasRegistrations.datas.success,
                backgroundColor: 'rgba(179, 239, 178, 0)',
                borderColor: 'rgba(179, 239, 178, 1)',
                borderWidth: 1
            },
            {
                data: datasRegistrations.datas.cancel,
                backgroundColor: 'rgba(255, 99, 132, 0)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }
        ],
        labels: datasRegistrations.labels,
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Inscriptions sur les 12 derniers mois',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + ' inscriptions et ' + data['datasets'][1]['data'][tooltipItem['index']] + ' annulations';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    // UseEffect
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setBreadcrumbs({
            title: 'Tableau de bord',
            context: 'CRM',
            description: 'Mes statistiques.'
        });


        Api.get({
                route: 'crm_dashboard_registrations'
            },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setDatasRegistrations(response.data);
                setReadyContent(true);
            });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={3}>
                <Zoom in={true} style={{transitionDelay: '0ms'}} {...(true ? {timeout: 500} : {})}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ShadowBoxComponent>
                            <Line height={100} data={dataChartRegistrations} options={dataChartRegistrations.options}/>
                        </ShadowBoxComponent>
                    </Grid>
                </Zoom>
            </Grid>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({});

export default Dashboard;

/*


    // Chart ProspectState
    const dataChartProspectsStates = {
        datasets: [{
            data: [1, 2, 3],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: ['toto', 'tata', 'titi'],
        options: {
            legend: {
                position: 'bottom',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                text: 'Statut des contacts',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + ' formulaires Générés';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let percent = 0;
                        if (dataset["_meta"][0]) {
                            percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                        }
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    // Chart Registration
    const dataChartRegistration = {
        datasets: [{
            data: [1, 2, 3],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: ['Pré-inscription', 'Inscription'],
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Pré-inscription et Inscription en cours',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + ' formulaires Générés';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let percent = 0;
                        if (dataset["_meta"][0]) {
                            percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                        }
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

                <Zoom in={true} style={{transitionDelay: '0ms'}} {...(true ? {timeout: 500} : {})}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <ShadowBoxComponent>
                            <Doughnut data={dataChartProspectsStates} options={dataChartProspectsStates.options}/>
                        </ShadowBoxComponent>
                    </Grid>
                </Zoom>
                <Zoom in={true} style={{transitionDelay: '0ms'}} {...(true ? {timeout: 500} : {})}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <ShadowBoxComponent>
                            <HorizontalBar data={dataChartRegistration} options={dataChartRegistration.options}/>
                        </ShadowBoxComponent>
                    </Grid>
                </Zoom>
 */
