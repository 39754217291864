import React, { useRef } from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Zoom } from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import { TableReload } from "../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { inArray } from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import ButtonComponent from '../../../Component/ButtonComponent';
import { Alert } from '@material-ui/lab';
import PaymentIcon from '@material-ui/icons/Payment';
import moment from 'moment';
import CheckboxComponent from '../../../Component/CheckboxComponent';
import { clone } from '../../../Tool/CloneCommon';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';

function LinearProgressWithLabel(props) {

    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function CommercialManagementRegistrationList(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionFinanceTypes, setFinanceTypes] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionRegistrationOrigins, setOptionRegistrationOrigins] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionStates, setOptionStates] = React.useState([
        { value: 1, label: 'Terminer' },
        { value: 2, label: 'Annulée' },
        { value: 3, label: 'Supprimée' },
        { value: 4, label: 'Facturée' },
        { value: 5, label: 'Non terminée' },
        { value: 6, label: 'Pré-inscription' }
    ]);
    const [optionsPreCommission, setOptionsPreCommission] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 'true', label: 'Oui' },
        { value: 'false', label: 'Non' },
    ]);
    const [optionsCommission, setOptionsCommission] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 'true', label: 'Oui' },
        { value: 'false', label: 'Non' },
    ]);
    const [optionEndMonth, setOptionEndMonth] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Janvier' },
        { value: 2, label: 'Février' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Avril' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juin' },
        { value: 7, label: 'Juillet' },
        { value: 8, label: 'Août' },
        { value: 9, label: 'Septembre' },
        { value: 10, label: 'Octobre' },
        { value: 11, label: 'Novembre' },
        { value: 12, label: 'Décembre' },
    ]);
    const [showAssign, setShowAssign] = React.useState(false);
    const [loadingAssign, setLoadingAssign] = React.useState(false);
    const [registration, setRegistration] = React.useState();
    const [showFiltre, setShowFiltre] = React.useState(false);
    const [showCommission, setShowCommission] = React.useState(false);
    const [loadingCommission, setLoadingCommission] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const refScrollBot = React.useRef(null);
    let InitialFormCommission = {
        preCommissionedAt: {
            name: 'preCommissionedAt',
            label: 'Date de l\'avance de commission',
            textHelper: 'Saisissez une date de pre-commission.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        isPreCommissionPaid: {
            name: 'isPreCommissionPaid',
            label: 'Pre-commission payé',
            textHelper: '',
            type: 'boolean',
            defaultValue: '',
            options: {}
        },
        preCommissionPercent: {
            name: 'preCommissionPercent',
            label: 'Pourcentage pre-commission',
            textHelper: '',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        commissionedAt: {
            name: 'commissionedAt',
            label: 'Date de la commission totale',
            textHelper: 'Saisissez une date de commission totale.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        isCommissionPaid: {
            name: 'isCommissionPaid',
            label: 'Commission totale payé',
            textHelper: '',
            type: 'boolean',
            defaultValue: '',
            options: {}
        },
        commissionPercent: {
            name: 'commissionPercent',
            label: 'Pourcentage commission',
            textHelper: '',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    }

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "commercialName",
            label: "Commercial",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeName",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: false, sort: false }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "origin",
            label: "Origine de la registration",
            options: { filter: true, sort: true }
        },
        {
            name: "billingPrice",
            label: "Prix facturé",
            options: { filter: true, sort: true }
        },
        {
            name: "preCommissionedAt",
            label: "Date de la pré-commission",
            options: { filter: true, sort: true }
        },
        {
            name: "isPreCommissionPaid",
            label: "Pré-commission payé",
            options: { filter: true, sort: true }
        },
        {
            name: "commissionedAt",
            label: "Date de la commission totale",
            options: { filter: true, sort: true }
        },
        {
            name: "isCommissionPaid",
            label: "Commission payé",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    // Action
    const getAction = (row) => {
        return (
            <div style={{ minWidth: 90 }}>
                {((row.preCommissionedAt === '-') && (row.commissionedAt === '-')) &&
                    <Tooltip title={'Changer le commercial'} placement="left">
                        <IconButton onClick={() => {
                            setShowAssign(true);
                            setRegistration(row);
                            handlerAssign.setValue('commercial', row.commercialId);
                        }}>
                            <AssignmentIndIcon />
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip title={'Commission'} placement="left">
                    <IconButton onClick={() => {
                        setShowCommission(true);
                        handlerCommission.reset();
                        dispatch(LinearProgressMainLayoutActivate());
                        setLoadingCommission(true);
                        handlerCommission.setFormLoading(true);
                        
                        setRegistration(row);
                        if (row.preCommissionedAt !== '-') {
                            handlerCommission.setValue('preCommissionedAt', moment(row.preCommissionedAt, "DD-MM-YYYY"));
                        }
                        if (row.commissionedAt !== '-') {
                            handlerCommission.setValue('commissionedAt', moment(row.commissionedAt, "DD-MM-YYYY"));
                        }
                        handlerCommission.setValue('isPreCommissionPaid', row.isPreCommissionPaid.props.value === 'true' ? true : false);
                        handlerCommission.setValue('isCommissionPaid', row.isCommissionPaid.props.value === 'true' ? true : false);

                        if ((!row.commissionPercent && row.commissionPercent !== 0 && !row.preCommissionPercent && row.preCommissionPercent !==0 && row.commercialId)) {
                            changeCoCommercial(row.commercialId, row.id, false);
                        }
                        else {
                            handlerCommission.setValue('preCommissionPercent', row.preCommissionPercent);
                            handlerCommission.setValue('commissionPercent', row.commissionPercent);
                        }

                        setTimeout(() => {
                            if (row && row.coCommercial.length > 0) {
                                addCoCommercial(row.coCommercial.length);
                            }
                        }, 150);
                        
                        setTimeout(() => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            setLoadingCommission(false);
                            handlerCommission.setFormLoading(false);
                        }, 300);
                    }}>
                        <PaymentIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };

    // Save
    const saveAssign = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoadingAssign(true);
        Api.post({
            route: 'crm_commercial_management_registration_assign',
            params: { registration: registration.id },
            data: handlerAssign.getData()
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoadingAssign(false);
                dispatch(TableReload('crm_commercial_management_registration_list'));
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Commercial assigné.',
                            variant: 'success',
                        })
                    );
                    setShowAssign(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const saveCommission = () => {
        if (handlerCommission.checkError()) {
            console.log('Error');
        }
        else {
            setLoadingCommission(true);
            dispatch(LinearProgressMainLayoutActivate());
            handlerCommission.setFormLoading(true);

            let data = handlerCommission.getData();
            let dataPost = {};

            for (let index in data) {
                let split = index.split('_');
                if (split[1]) {
                    let obj = {};
                    obj[split[0]] = data[index];
                    obj['indexField'] = split[1];
                    if (split[0] === 'coCommercial') {
                        obj['id'] = handlerCommission.form[index].id;
                    }
                    let num = split[1];
                    if (!dataPost[num]) {
                        dataPost[num] = {};
                    }
                    Object.assign(dataPost[num], obj);
                }
            }
            let dataPostArray = Object.values(dataPost);

            Api.post({
                route: 'crm_commercial_management_registration_commission',
                params: { registration: registration.id },
                data: {
                    'preCommissionedAt': data.preCommissionedAt,
                    'isPreCommissionPaid': data.isPreCommissionPaid,
                    'preCommissionPercent': data.preCommissionPercent,
                    'commissionedAt': data.commissionedAt,
                    'isCommissionPaid': data.isCommissionPaid,
                    'commissionPercent': data.commissionPercent,
                    'coCommercial': dataPostArray,
                }
            },
                (response) => {
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Enregistrement effectué.',
                                variant: 'success',
                            })
                        );
                        setRegistration(null);
                        handlerCommission.reset();
                        setCount(0);
                        resetForm();
                        dispatch(TableReload('crm_commercial_management_registration_list'));
                        setShowCommission(false);
                    } else if (response.status === 400) {
                        handlerCommission.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                                duration: 5000
                            })
                        );
                    }
                    setLoadingCommission(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerCommission.setFormLoading(false);
                });
        }
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'array',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].commercial ? cacheTableServer['crm_commercial_management_registration_list'].commercial : '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].organism ? cacheTableServer['crm_commercial_management_registration_list'].organism : 'all',
            options: {}
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Trier par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].pole ? cacheTableServer['crm_commercial_management_registration_list'].pole : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].formationGroup ? cacheTableServer['crm_commercial_management_registration_list'].formationGroup : 'all',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].startAt ? cacheTableServer['crm_commercial_management_registration_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].endAt ? cacheTableServer['crm_commercial_management_registration_list'].endAt : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].year ? cacheTableServer['crm_commercial_management_registration_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].financeType ? cacheTableServer['crm_commercial_management_registration_list'].financeType : 'all',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].andpc ? cacheTableServer['crm_commercial_management_registration_list'].andpc : '',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez un email.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].email ? cacheTableServer['crm_commercial_management_registration_list'].email : '',
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez un prénom.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].firstname ? cacheTableServer['crm_commercial_management_registration_list'].firstname : '',
            options: {}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez un nom.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].lastname ? cacheTableServer['crm_commercial_management_registration_list'].lastname : '',
            options: {}
        },
        origin: {
            name: 'origin',
            label: 'Origine',
            textHelper: 'Trier par origine.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].origin ? cacheTableServer['crm_commercial_management_registration_list'].origin : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par états.',
            type: 'array',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].state ? cacheTableServer['crm_commercial_management_registration_list'].state : '',
            options: {}
        },
        preCommissioned: {
            name: 'preCommissioned',
            label: 'Pré-commission',
            textHelper: 'Trier par pré-commission.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].preCommissioned ? cacheTableServer['crm_commercial_management_registration_list'].preCommissioned : 'all',
            options: {}
        },
        commissioned: {
            name: 'commissioned',
            label: 'Commission totale',
            textHelper: 'Trier par Commission.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].commissioned ? cacheTableServer['crm_commercial_management_registration_list'].commissioned : 'all',
            options: {}
        },
        monthEndAt: {
            name: 'monthEndAt',
            label: 'Mois de fin de session.',
            textHelper: 'Choisissez un mois.',
            type: 'text',
            defaultValue: cacheTableServer['crm_commercial_management_registration_list'] && cacheTableServer['crm_commercial_management_registration_list'].monthEndAt ? cacheTableServer['crm_commercial_management_registration_list'].monthEndAt : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['crm_commercial_management_registration_list'] = 0;
        cacheTableServer['crm_commercial_management_registration_list'].page = 0;
        cacheTableServer['crm_commercial_management_registration_list'][index] = value;
        dispatch(TableReload('crm_commercial_management_registration_list'))
    };

    //formAssign
    const [formAssign, setFormAssign] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Selectionner un commercial.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });
    const handlerAssign = formHandlerInit(formAssign, setFormAssign);

    //formCommission
    const [formCommission, setFormCommission] = React.useState(InitialFormCommission);
    const handlerCommission = formHandlerInit(formCommission, setFormCommission);

    // Height filters content
    const gridRef = useRef(null);
    const getHeightFilters = () => {
        if (gridRef.current) {
            return gridRef.current.clientHeight
        }
    };

    // Co-Commerciale
    const addCoCommercial = (iterate) => {
        let newForm = {};
        let countTMP = count;
        for (let i = 0; i < iterate; i++) {

            newForm['coCommercial_' + countTMP] = {
                name: 'coCommercial_' + countTMP,
                label: 'Co-commerciale',
                id: '',
                textHelper: 'Selectionnez un co-commerciale.',
                type: 'integer',
                defaultValue: '',
                options: { validation: ['required'] }
            };
            newForm['preCommissionedAt_' + countTMP] = {
                name: 'preCommissionedAt_' + countTMP,
                label: 'Date de l\'avance de commission',
                textHelper: 'Saisissez une date de pre-commission.',
                type: 'date',
                defaultValue: '',
                options: { validation: ['date'] }
            };
            newForm['isPreCommissionPaid_' + countTMP] = {
                name: 'isPreCommissionPaid_' + countTMP,
                label: 'Pre-commission payé',
                textHelper: '',
                type: 'boolean',
                defaultValue: '',
                options: {}
            };
            newForm['preCommissionPercent_' + countTMP] = {
                name: 'preCommissionPercent_' + countTMP,
                label: 'Pourcentage pré-commission',
                textHelper: '',
                type: 'float',
                defaultValue: '',
                options: { validation: ['required'] }
            };
            newForm['commissionedAt_' + countTMP] = {
                name: 'commissionedAt_' + countTMP,
                label: 'Date de commission',
                textHelper: 'Saisissez une date de commission.',
                type: 'date',
                defaultValue: '',
                options: { validation: ['date'] }
            };
            newForm['isCommissionPaid_' + countTMP] = {
                name: 'isCommissionPaid_' + countTMP,
                label: 'commission payé',
                textHelper: '',
                type: 'boolean',
                defaultValue: '',
                options: {}
            };
            newForm['commissionPercent_' + countTMP] = {
                name: 'commissionPercent_' + countTMP,
                label: 'Pourcentage commission',
                textHelper: '',
                type: 'float',
                defaultValue: '',
                options: { validation: ['required'] }
            };
            countTMP++;

            setTimeout(() => {
                if (i === iterate - 1) {
                    refScrollBot.current?.scrollIntoView({ behavior: 'smooth' });
                }
            }, 250);
        }
        setCount(countTMP);
        setFormCommission({ ...formCommission, ...newForm });
    };
    const removeCoCommercial = (indexForm) => {
        let oldForm = clone(formCommission);
        if (oldForm['preCommissionedAt_' + indexForm]) {
            delete oldForm['coCommercial_' + indexForm];
            delete oldForm['preCommissionedAt_' + indexForm];
            delete oldForm['isPreCommissionPaid_' + indexForm];
            delete oldForm['preCommissionPercent_' + indexForm];
            delete oldForm['commissionedAt_' + indexForm];
            delete oldForm['isCommissionPaid_' + indexForm];
            delete oldForm['commissionPercent_' + indexForm];
            setFormCommission(oldForm);
            setCount(count - 1);
        }
    };
    const changeCoCommercial = (commercialId, registrationId, index) => {
        LinearProgressMainLayoutActivate();
        handlerCommission.setFormLoading(true);
        setLoadingCommission(true);
        Api.get({
            route: 'crm_commercial_management_registration_get_percent',
            params: { commercial: commercialId, registration: registrationId }
        },
            (response) => {
                if ((response && response.data)) {
                    if (response.data.message) {
                        if (Number.isInteger(index)) {
                            handlerCommission.setError('coCommercial_' + index, response.data.message);
                            handlerCommission.setValue('coCommercial_' + index, commercialId);
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.data.message,
                                    variant: 'warning',
                                    duration: 6000
                                })
                            );
                        }
                    }
                    else {
                        if (Number.isInteger(index)) {
                            handlerCommission.setValue('preCommissionPercent_' + index, response.data.preCommissionPercent);
                            handlerCommission.setValue('commissionPercent_' + index, response.data.commissionPercent);
                            handlerCommission.setValue('coCommercial_' + index, response.data.commercialId);
                        }
                        else {
                            handlerCommission.setValue('preCommissionPercent', response.data.preCommissionPercent);
                            handlerCommission.setValue('commissionPercent', response.data.commissionPercent);
                        }
                    }
                }
                handlerCommission.setFormLoading(false);
                setLoadingCommission(false);
                LinearProgressMainLayoutDeactivate();
            });
    };
    const resetForm = () => {
        let newForm = InitialFormCommission;
        setFormCommission(newForm);
    }; 
    const GetRender = (props) => {
        if (props.form && count > 0) {
            
            let render = [];
            for (let index in props.form) {

                let split = index.split('_');

                if (parseInt(split[1]) >= 0) {
                    
                    if (split[0] === 'coCommercial') {
                        render.push(
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p style={{ fontWeight: 600, color: '#5E6E82' }}>Co-commerciale n° {parseInt(split[1]) + 1}</p>
                                    <Tooltip title={'Retirer le co-commerciale'} placement="right" arrow>
                                        <IconButton onClick={() => { removeCoCommercial(split[1]) }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <SelectComponent name={index} handler={handlerCommission} options={optionCommercials} onChange={(val) => { changeCoCommercial(val, registration.id, parseInt(split[1])) }} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === null) {
                                setTimeout(() => {
                                    handlerCommission.setValue(index, registration.coCommercial[parseInt(split[1])].commercial);
                                    handlerCommission.form[index].id = registration.coCommercial[parseInt(split[1])].id;
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'preCommissionedAt') {
                        render.push(
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                                <DatePickerComponent name={index} handler={handlerCommission} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === "") {
                                setTimeout(() => {
                                    if (registration.coCommercial[parseInt(split[1])].preCommissionedAt != '-') {
                                        handlerCommission.setValue(index, moment(registration.coCommercial[parseInt(split[1])].preCommissionedAt, "DD-MM-YYYY"));
                                    }
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'isPreCommissionPaid') {
                        render.push(
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                                <CheckboxComponent name={index} handler={handlerCommission} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === null) {
                                setTimeout(() => {
                                    handlerCommission.setValue(index, registration.coCommercial[parseInt(split[1])].isPreCommissionPaid);
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'preCommissionPercent') {
                        render.push(
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <TextFieldComponent name={index} handler={handlerCommission} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === null) {
                                setTimeout(() => {
                                    handlerCommission.setValue(index, registration.coCommercial[parseInt(split[1])].preCommissionPercent);
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'commissionedAt') {
                        render.push(
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                                <DatePickerComponent name={index} handler={handlerCommission} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === "") {
                                setTimeout(() => {
                                    if (registration.coCommercial[parseInt(split[1])].commissionedAt != '-') {
                                        handlerCommission.setValue(index, moment(registration.coCommercial[parseInt(split[1])].commissionedAt, "DD-MM-YYYY"));
                                    }
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'isCommissionPaid') {
                        render.push(
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                                <CheckboxComponent name={index} handler={handlerCommission} />
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === null) {
                                setTimeout(() => {
                                    handlerCommission.setValue(index, registration.coCommercial[parseInt(split[1])].isCommissionPaid);
                                }, 50);
                            }
                        }
                    }
                    if (split[0] === 'commissionPercent') {
                        render.push(
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                                <TextFieldComponent name={index} handler={handlerCommission} />
                                <div className={classes.separator}></div>
                            </Grid>
                        )
                        if (registration && registration.coCommercial.length > 0 && count <= registration.coCommercial.length) {
                            if (registration.coCommercial[parseInt(split[1])] && handlerCommission.getData()[index] === null) {
                                setTimeout(() => {
                                    handlerCommission.setValue(index, registration.coCommercial[parseInt(split[1])].commissionPercent);
                                }, 50);
                            }
                        }
                    }
                }
            }
            return render;
        }
        else { return <></> }
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handlerAssign.start, []);
    React.useEffect(handlerCommission.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestion commercial',
            context: 'CRM',
            description: 'Liste des inscriptions.'
        });

        // Year
        let yearsTMP = [];
        yearsTMP.push({ label: (currentYear + 1), value: (currentYear + 1) });
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'crm_commercial_list'
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([...[{ value: 0, label: 'Aucun' }], ...response.data]);
                }
            });

        Api.get({
            route: 'select_poles'
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(parseInt(response.data[index].value), authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setFinanceTypes(data);
                }
            });

        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

        Api.get({
            route: 'select_registration_origins'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionRegistrationOrigins(data);
                }
            });

        setReadyContent(true);
    }, []);

    // Render
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Zoom in={true} {...{ timeout: 1000 }}>
                <ShadowBoxComponent>
                    <Tooltip title={showFiltre ? 'Cacher les filtres' : 'Afficher les filtres'}>
                        <IconButton
                            className={classes.iconToggle}
                            onClick={() => { setShowFiltre(!showFiltre) }}
                        >
                            {showFiltre ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            Filtres
                        </IconButton>
                    </Tooltip>

                    <div style={{ visibility: showFiltre ? 'visible' : 'hidden', height: !showFiltre ? 0 : getHeightFilters(), overflow: !showFiltre && 'hidden', transition: showFiltre ? 'all .4s' : 'all .4s ease .3s' }} >
                        <Slide direction="left" in={showFiltre} style={{ transitionDelay: '0ms' }} {...{ timeout: 700 }}>
                            <Grid container spacing={2} ref={gridRef}>
                                <Grid item xs={12} sm={6} md={2} lg={2}>
                                    <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={4}>
                                    <TextFieldComponent name={'andpc'} handler={handlerSearch} onChange={(val) => inputSearchChange('andpc', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceTypes} onChange={(val) => inputSearchChange('financeType', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'origin'} handler={handlerSearch} options={optionRegistrationOrigins} onChange={(val) => inputSearchChange('origin', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'preCommissioned'} handler={handlerSearch} options={optionsPreCommission} onChange={(val) => inputSearchChange('preCommissioned', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <SelectComponent name={'commissioned'} handler={handlerSearch} options={optionsCommission} onChange={(val) => inputSearchChange('commissioned', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <MultipleSelectComponent name={'state'} handler={handlerSearch} options={optionStates} onChange={(val) => inputSearchChange('state', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <MultipleSelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => inputSearchChange('commercial', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent name={'email'} handler={handlerSearch} onChange={(val) => inputSearchChange('email', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent name={'firstname'} handler={handlerSearch} onChange={(val) => inputSearchChange('firstname', val)} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={3}>
                                    <TextFieldComponent name={'lastname'} handler={handlerSearch} onChange={(val) => inputSearchChange('lastname', val)} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SelectComponent name={'monthEndAt'} handler={handlerSearch} options={optionEndMonth} onChange={(val) => inputSearchChange('monthEndAt', val)} />
                                </Grid>
                            </Grid>
                        </Slide>
                    </div>
                </ShadowBoxComponent>
            </Zoom>

            <br />

            <TableComponent
                id={'crm_commercial_management_registration_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Inscriptions'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        pole: (formSearch.pole.value === 'all') ? '' : formSearch.pole.value,
                        startAt: datas.startAt ?? '',
                        andpc: datas.andpc ?? '',
                        endAt: datas.endAt ?? '',
                        commercial: (datas.commercial === '' || !datas.commercial) ? '' : datas.commercial,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                        email: datas.email ?? '',
                        firstname: datas.firstname ?? '',
                        lastname: datas.lastname ?? '',
                        origin: (datas.origin === 'all') ? '' : datas.origin,
                        state: (datas.state === '' || !datas.state) ? '' : datas.state,
                        preCommissioned: (datas.preCommissioned === 'all') ? '' : (datas.preCommissioned === 'true'),
                        commissioned: (datas.commissioned === 'all') ? '' : (datas.commissioned === 'true'),
                        monthEndAt: (datas.monthEndAt === 'all') ? '' : datas.monthEndAt,
                    };
                    Api.get({
                        route: 'crm_commercial_management_registration_list',
                        data: filters
                    },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                data[index].billingPrice = data[index].billingPrice ? data[index].billingPrice + ' €' : '-';
                                switch (data[index]['state']) {
                                    case 1:
                                        data[index]['stateText'] = <span style={{ color: '#28A745' }}>Validée</span>;
                                        break;
                                    case 2:
                                        data[index]['stateText'] = <span style={{ color: '#AD732C' }}>Annulée</span>;
                                        break;
                                    case 3:
                                        data[index]['stateText'] = <span style={{ color: '#DC3545' }}>Supprimée</span>;
                                        break;
                                    case 4:
                                        data[index]['stateText'] = <span style={{ color: '#35A2EB' }}>Facturée</span>;
                                        break;
                                    case 5:
                                        data[index]['stateText'] = <span style={{ color: '#000000' }}>Non terminée</span>;
                                        break;
                                    case 6:
                                        data[index]['stateText'] = <span style={{ color: '#67327b' }}>Temps manquant</span>;
                                        break;
                                    case 7:
                                        data[index]['stateText'] = <span style={{ color: '#000000' }}>Pré-inscription</span>;
                                        break;
                                }
                                data[index].action = getAction(data[index]);
                                data[index].isPreCommissionPaid = data[index].isPreCommissionPaid ? <CheckIcon className={classes.checkIcon} value='true' color='primary' /> : <CloseIcon value='false' color='error' />;
                                data[index].isCommissionPaid = data[index].isCommissionPaid ? <CheckIcon className={classes.checkIcon} value='true' color='primary' /> : <CloseIcon value='false' color='error' />;
                            }
                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <Dialog open={showAssign} maxWidth={'xl'} onClose={() => setShowAssign(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', fontWeight: 600 }}>Changement du commercial</DialogTitle>
                <DialogContent style={{ minWidth: '70vw' }}>
                    <Grid container spacing={2}>
                        {(registration && registration.state === 4) &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Alert severity="warning">Attention vous allez changer un commercial sur une registration déjà facturée !</Alert>
                            </Grid>
                        }
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p style={{ fontWeight: 600 }}>Réference registration :</p>
                            <p style={{ fontWeight: 600 }}>Email prospect :</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p>{registration ? registration.id : ''}</p>
                            <p>{registration ? registration.email : ''}</p>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectComponent name={'commercial'} handler={handlerAssign} options={optionCommercials} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loadingAssign} onClick={() => setShowAssign(false)} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loadingAssign} onClick={saveAssign} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <Dialog open={showCommission} maxWidth={'md'} fullWidth onClose={() => { setShowCommission(false); setRegistration(null); setCount(0); resetForm(); }}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', fontWeight: 600 }}>Commissions de la registration {registration ? registration.id : ''}</DialogTitle>
                <DialogContent style={{maxHeight: 360}}>
                    {(registration && registration.commercialName === '-') && <Alert severity="warning" variant="outlined">Attention, aucun commercial n'est attribué à cette inscription.</Alert>}
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p><span style={{ fontWeight: 600 }}>Organisme : </span>{registration ? registration.organism : ''}</p>
                            <p><span style={{ fontWeight: 600 }}>Groupe de formation : </span>{registration ? registration.groupFormationName : ''}</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <p><span style={{ fontWeight: 600 }}>Type de financement : </span>{registration ? registration.financeTypeName : ''}</p>
                            <p><span style={{ fontWeight: 600 }}>Commerciale : </span>{registration ? registration.commercialName === '-' ? 'Aucun' : registration.commercialName : ''}</p>
                        </Grid>
                    </Grid>
                    <div className={classes.separator}></div>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <DatePickerComponent name={'preCommissionedAt'} handler={handlerCommission} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CheckboxComponent name={'isPreCommissionPaid'} handler={handlerCommission} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'preCommissionPercent'} handler={handlerCommission} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <DatePickerComponent name={'commissionedAt'} handler={handlerCommission} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <CheckboxComponent name={'isCommissionPaid'} handler={handlerCommission} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'commissionPercent'} handler={handlerCommission} />
                        </Grid>
                    </Grid>
                    <div className={classes.separator}></div>
                    <Grid container spacing={2}>
                        <GetRender form={formCommission} />
                    </Grid>
                    <div ref={refScrollBot} />
                </DialogContent>
                <div className={classes.btnSubContainer}>
                    <ButtonComponent disabled={loadingCommission} className={classes.btnAdd} color={'#5e6e82db'} label={'Ajouter un co-commerciale'} onClick={() => { addCoCommercial(1) }} />
                </div>
                <DialogActions>
                    <ButtonComponent disabled={loadingCommission} onClick={() => { setShowCommission(false); setRegistration(null); setCount(0); resetForm(); }} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loadingCommission} onClick={saveCommission} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    iconToggle: {
        marginRight: 10,
        marginBottom: 10,
        padding: 0,
        fontSize: '1.1rem',
        color: '#5E6E82',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#fff',
        },
    },
    checkIcon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: green[600]
        }
    },
    btnAdd: {
        backgroundColor: '#5e6e82db',
        borderRadius: 20,
        padding: 5,
        margin: 5,
    },
    separator: {
        margin: '15px 0px',
        borderBottom: '2px dashed #5E6E82'
    },
    btnSubContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 6,
    }
});

export default CommercialManagementRegistrationList;
