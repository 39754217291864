import React from 'react';
import moment from "moment";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Delete} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert-react';
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import DatePickerComponent from '../../../Component/DatePickerComponent';
import SelectComponent from '../../../Component/SelectComponent';
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';

function CodeList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [isEdition, setEdition] = React.useState(false);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [form, setForm] = React.useState({
        promotionCode: {
            name: 'promotionCode',
            label: 'Code promo',
            textHelper: 'Saissisez un code promo',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        startAt: {
            name: 'startAt',
            label: 'Début',
            textHelper: 'Saissisez la date de début',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Fin',
            textHelper: 'Saissisez la date de fin',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Selectionnez un organisme',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Selectionnez une Formation',
            type: isEdition ? 'text' : 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        percent: {
            name: 'percent',
            label: 'Remise (en %)',
            textHelper: 'Saissisez un pourcentage de remise',
            type: 'float',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "promotionCode",
            label: "Code promo",
            options: {filter: true, sort: true}
        },
        {
            name: "formationName",
            label: "Formation",
            options: {filter: true, sort: true}
        },
        {
            name: "organismName",
            label: "Organism",
            options: {filter: true, sort: true}
        },
        {
            name: "startAtText",
            label: "Date de début",
            options: {filter: true, sort: true}
        },
        {
            name: "endAtText",
            label: "Date de fin",
            options: {filter: true, sort: true}
        },
        {
            name: "percent",
            label: "Remise",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const handler = formHandlerInit(form, setForm);
   
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setDialog(true);
                        setEdition(true);
                        setActionRow(row);
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'marketing_manager_stripe_promotion_code_edit',
                    params: {id: actionRow.id},
                    data: datas,
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setDialog(false)
                        dispatch(TableReload('marketing_manager_stripe_promotion_code_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'marketing_manager_stripe_promotion_code_add',
                    data: handler.getData(),
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setDialog(false)
                        dispatch(TableReload('marketing_manager_stripe_promotion_code_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Code enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(() => {

        Api.get({
            route: 'select_formations'
        },
        (response) => {
            if (response && response.data) {
                setOptionFormation(response.data);
            }
        });

        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });


        setBreadcrumbs({
            title: 'Liste des codes promo',
            context: 'Gestion marketing',
            description: ''
        });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'marketing_manager_stripe_promotion_code_list'}
                title={'Code promo'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un code',
                    onClick: () => {
                        setDialog(true)
                        setEdition(false)
                        handler.reset();
                    }
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'marketing_manager_stripe_promotion_code_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].startAtText = moment(data[index].startAt).format('ll');
                                data[index].endAtText = moment(data[index].endAt).format('ll');
                            }
                            resolve(data);
                        });
                }}
            />

            <br/>

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{isEdition ? 'Modifier le code' : 'Ajouter un code'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextFieldComponent name={'promotionCode'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextFieldComponent name={'percent'} handler={handler} disabled={isEdition} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <DatePickerComponent name={'startAt'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <DatePickerComponent name={'endAt'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            {isEdition ? 
                                <SelectComponent name={'formation'} options={optionFormation} handler={handler} />
                                :
                                <MultipleSelectComponent name={'formation'} options={optionFormation} handler={handler} />
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'organism'} options={optionOrganism} handler={handler} disabled={isEdition} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer le code promo : \n ' + actionRow.promotionCode + ' ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                            route: 'marketing_manager_stripe_promotion_code_delete',
                            params: {id: actionRow.id}
                        },
                        () => {
                            dispatch(TableReload('marketing_manager_stripe_promotion_code_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default CodeList;
