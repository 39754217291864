const LinearProgressMainLayoutReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LinearProgressMainLayoutActivate':
            return {
                isActivated: true
            };
        case 'LinearProgressMainLayoutDeactivate':
            return {
                isActivated: false
            };
        default:
            return state
    }
};

export default LinearProgressMainLayoutReducer;
