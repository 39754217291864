import React from 'react';
import { makeStyles } from "@material-ui/core";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtmlPuri from "draftjs-to-html";
import { draftToMarkdown } from 'markdown-draft-js';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function EditorComponent(props = {
    name: '',
    disabled: false,
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {

    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [isFocus, setFocus] = React.useState(false);
    const [error, setError] = React.useState(data.error);
    const [value, setValue] = React.useState(() => EditorState.createEmpty());
    const htmlPuri = draftToHtmlPuri(convertToRaw(value.getCurrentContent()))
    const valueContent = draftToMarkdown(convertToRaw(value.getCurrentContent()));
    const disabled = data.loading || data.disabled;

    const onEditorStateChange = (editorState) => {
        setValue(editorState);
    };

    const onBlur = () => {
        setFocus(false);
        let newForm = { ...props.handler.form };
        const regex = /<p>\s*<\/p>/g;
        const results = htmlPuri.match(regex);
        const resultsWithBr = results?.map((balise) => balise.replace(/<p>\s*<\/p>/g, '<p>&nbsp;</p>'));
        const htmlPuriWithBr = htmlPuri.replace(regex, () => resultsWithBr.shift());
        newForm[props.name].value = !valueContent ? '' : htmlPuriWithBr;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }

    const onFocus = () => {
        setFocus(true);
    }
    React.useEffect(() => {
        setError(props.handler.form[props.name].error);
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && valueContent || props.handler.form[props.name].value && props.handler.form[props.name].value !== htmlPuri) {
            const html = props.handler.form[props.name].value;
            const contentBlock = htmlToDraft(html);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editor = EditorState.createWithContent(contentState);
            setValue(editor);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <FormControl variant="outlined"
            className={`
                    ${classes.editor}
                    ${isFocus && !disabled ? classes.editorFocus : error && !disabled ? classes.editorError : valueContent && !(disabled || props.disabled) ? classes.editorValid : ''}
                `}
        >
            <div className='content'>
                <p className={`label ${classes.title}`} >{data.label ? data.label : ''}</p>
                <Editor
                    id={props.name}
                    editorState={value}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "colorPicker", "history"],
                        inline: {
                            inDropdown: true,
                            options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
                        },
                        blockType: {
                            inDropdown: true,
                            options: ["H1", "H2", "H3"],
                            className: classes.blockText,
                            dropdownClassName: 'blockTextDown',
                        },
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                        },
                        list: {
                            inDropdown: false,
                            options: ["unordered", "ordered"]
                        },
                        history: { inDropdown: true }
                    }}
                    readOnly={disabled || props.disabled}
                />
            </div>

            <FormHelperText>{error && !isFocus ? error : data.textHelper}</FormHelperText>
        </FormControl>
    );
}

const useStyles = makeStyles({
    editor: {
        width: '100%',
        height: '100%',
        '& .content': {
            border: "1px solid #5E6E8270",
            borderRadius: 4,
            height: '100%',
        },
        '& .rdw-editor-wrapper': {
            padding: 5,
            paddingTop: 10
        },
        '& .rdw-editor-main': {
            minHeight: 200,
            padding: 5,
            '& .public-DraftStyleDefault-block, h1, h2, h3': {
                margin: 0
            },
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px'
        },
    },
    blockText: {
        color: '#212121',
        '& .rdw-option-active': {
            fontWeight: 'bold'
        },
    },
    editorFocus: {
        '& .content': {
            borderColor: '#3F51BF !important',
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& .label': {
            color: '#3F51BF !important',
        }
    },
    editorValid: {
        '& .content': {
            borderColor: '#008000 !important',
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& .label': {
            color: '#008000 !important',
        }
    },
    editorError: {
        '& .content': {
            borderColor: '#982525 !important',
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& .label': {
            color: '#982525 !important',
        }
    },
    title: {
        fontSize: 10,
        position: 'absolute',
        top: -12,
        left: 10,
        background: '#fff',
        padding: 5,
        margin: 0
    }
});

export default EditorComponent;
