import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import { Box, makeStyles } from "@material-ui/core";
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import Api from "../../../../Api";
import { getRoutePathname } from "../../../../Config/Route";
import TitleComponent from "../../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import SwitchComponent from "../../../../Component/SwitchComponent";
import SelectComponent from '../../../../Component/SelectComponent';

function User(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);

    const [form, setForm] = React.useState({
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'adresse mail de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required', 'email']
            }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le numéro de téléphone de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        jobTitle: {
            name: 'jobTitle',
            label: 'Intitulé du poste',
            textHelper: 'Saisissez l\'intitulé du poste de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        jobDescription: {
            name: 'jobDescription',
            label: 'Description du poste',
            textHelper: 'Saisissez une description du poste de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 255,
            }
        },
        username: {
            name: 'username',
            label: 'Nom d\'utilisateur',
            textHelper: 'Saisissez un nom d\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez le nom de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom de l\'utilisateur.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Saisissez le mot de passe temporaire de l\'utilisateur.',
            type: 'text',
            defaultValue: Math.random().toString(36).slice(-6),
            options: {
                validation: ['required'],
                min: 6,
                max: 32,
            }
        },
        roles: {
            name: 'roles',
            label: 'Rôles',
            textHelper: 'Choisissez les rôles de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        poles: {
            name: 'poles',
            label: 'Pôles',
            textHelper: 'Choisissez les pôles de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        polemain: {
            name: 'polemain',
            label: 'Pôles principale',
            textHelper: 'Choisissez le pôle principale de l\'utilisateur.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        organisms: {
            name: 'organisms',
            label: 'Organismes',
            textHelper: 'Choisissez les organismes de l\'utilisateur.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        gender: {
            name: 'gender',
            label: 'Sexe',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {
                switchLabelRight: 'Homme',
                switchLabelLeft: 'Femme'
            }
        },
        ips: {
            name: 'ips',
            label: 'IPS',
            textHelper: 'Saisissez l\'Identifiant Professionnel de Santé. (ADELI ou RPPS)',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const [optionRole, setOptionRole] = React.useState([]);
    const [optionPole, setOptionPole] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [isAdmin, setIsAdmin] = React.useState(true);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'institution_health_administrator_user_edit',
                    params: { id: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'institution_health_administrator_user_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('institution_health_administrator_user', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Utilisateur créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }

        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setIsAdmin(authenticationReducer.roles && !authenticationReducer.roles.includes("ROLE_SUPER_ADMIN"))
        Api.get({
            route: 'select_roles'
        },
            (response) => {
                setOptionRole(response.data);
            });
        Api.get({
            route: 'select_poles'
        },
            (response) => {
                setOptionPole(response.data);
            });
        Api.get({
            route: 'select_organisms'
        },
            (response) => {
                setOptionOrganisms(response.data);
            });

    }, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un utilisateur',
                context: 'Portail',
                path: getRoutePathname('institution_health_home'),
                description: '',
                links: (authenticationReducer.roles && authenticationReducer.roles.includes("ROLE_SUPER_ADMIN"))
                && [ { path: getRoutePathname('institution_health_admin_user_list'), label: 'Liste des utilisateurs' }]
            });

            setIsEdition(true);
            if ((authenticationReducer.roles && authenticationReducer.roles.includes("ROLE_SUPER_ADMIN"))
                || (authenticationReducer.id === parseInt(props.match.params.id))) {
                Api.get({
                    route: 'institution_health_administrator_user_get',
                    params: { id: props.match.params.id }
                },
                    (response) => {
                        setLoading(false);
                        handler.setFormLoading(false);
                        setReadyContent(true);
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (response.status === 200) {
                            handler.setDataApi(response.data);
                        }
                        else if (response.status === 404) {
                            props.history.push(getRoutePathname('admin_user_list'));
                            dispatch(
                                SnackbarOpen({
                                    text: response.error.message,
                                    variant: 'warning',
                                })
                            );
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Une erreur inattendue s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
            } else props.history.push(getRoutePathname('institution_health_admin_user', { id: authenticationReducer.id }));

        } else if (authenticationReducer.roles && authenticationReducer.roles.includes("ROLE_SUPER_ADMIN")) {
            setBreadcrumbs({
                title: 'Ajouter un utilisateur',
                context: 'Portail',
                path: getRoutePathname('institution_health_home'),
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_admin_user_list'), label: 'Liste des utilisateurs' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        } else {
            props.history.push(getRoutePathname('institution_health_home'));
        }
    }, [props.match.params.id]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{ timeout: 500 }}>
                <ShadowBoxComponent className={classes.shadowBox}>
                    <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'un utilisateur' : 'Formulaire de création d\'un utilisateur'} />

                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'email'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'phone'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'lastname'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'firstname'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'username'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <TextFieldComponent name={'password'} handler={handler} password disabled={isEdition && isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <MultipleSelectComponent name={'roles'} handler={handler} options={optionRole} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <MultipleSelectComponent name={'organisms'} handler={handler} options={optionOrganisms} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <MultipleSelectComponent name={'poles'} handler={handler} options={optionPole} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SelectComponent name={'polemain'} handler={handler} options={optionPole} nullable={true} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent name={'ips'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SwitchComponent name={'gender'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent name={'jobTitle'} handler={handler} disabled={isAdmin} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent name={'jobDescription'} multiline={true} handler={handler} disabled={isAdmin} />
                        </Grid>

                    </Grid>

                    {!isAdmin && <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />}
                </ShadowBoxComponent>
            </Fade>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    boxComponent: {
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },

    button: {
        margin: '0 0 0 auto',
        display: 'block',
    }
});

export default User;
