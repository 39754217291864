import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import Api, { LibraryResourceImage, LibraryResourceTypeIcon } from '../../../Api';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LazyLoading from './Component/LazyLoading';
import { Rating } from '@material-ui/lab';

const LibraryResource = (props) => {

    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [libraryResources, setLibraryResources] = React.useState([]);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {

        setBreadcrumbs({
            currentPage: 'Bibliothèque de ressources',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        Api.get({
            route: 'learner_library_resource_list',
            data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
        },
            (response) => {
                if (response && response.data) {
                    setLibraryResources(response.data);
                }
                setLoading(false);
            });
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: isSmallView ? '100%' : '80%', margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <FeaturedResourceComponent
                                organism={authenticationReducer.organism}
                                formationGroup={authenticationReducer.formationGroup}
                                history={props.history}
                            />
                            <br />

                            {(libraryResources && libraryResources.length > 0) ?
                                libraryResources.map((libraryResource) => (
                                    <div key={libraryResource.id} style={{ width: '80%', margin: 'auto', position: 'relative' }}>
                                        {libraryResource.resources &&
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 700 }}>
                                                    <LazyLoading path={LibraryResourceTypeIcon + libraryResource.icon} type={'icon'} />
                                                    <p style={{ marginLeft: 10, fontSize: 20 }}>{libraryResource.label}</p>
                                                </div>

                                                <Grid container spacing={2}>
                                                    {libraryResource.resources.map((resource) => (

                                                        <Grid key={resource.id} item xs={12} sm={6} md={4} lg={4} xl={4}>
                                                            {
                                                                resource.format === 6 || resource.format === 2 ?
                                                                    <MediaLinkComponent
                                                                        path={resource.link}
                                                                        type={resource.format}
                                                                        isLibrary={true}
                                                                    >
                                                                        <div className={classes.card}>
                                                                            <ShadowBoxComponent style={{ padding: 0, height: 170, borderRadius: 10, boxShadow: '0 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                                <LazyLoading path={LibraryResourceImage + resource.image} />
                                                                            </ShadowBoxComponent>
                                                                            <p style={{ fontWeight: 600, margin: '30px 0 0 0', fontSize: 22, overflow: 'hidden', }}>{resource.title}</p>
                                                                            <p style={{ fontSize: 16, margin: 0, }}>{resource.subTitle}</p>
                                                                            <div className={classes.styleStats} >
                                                                                <p><VisibilityIcon />{resource.numberViews}</p>
                                                                                <Rating
                                                                                    name="hover-feedback"
                                                                                    value={resource.average}
                                                                                    precision={1}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </MediaLinkComponent>
                                                                    :
                                                                    <div className={classes.card} onClick={() => { props.history.push(getRoutePathname('learner_resource_type_resource', { id: libraryResource.id, resource: resource.id })) }}>
                                                                        <ShadowBoxComponent style={{ padding: 0, height: 170, borderRadius: 10, boxShadow: '0 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                            <LazyLoading path={LibraryResourceImage + resource.image} />
                                                                        </ShadowBoxComponent>
                                                                        <p style={{ fontWeight: 600, margin: '20px 0 0 0', fontSize: 22, overflow: 'hidden', }}>{resource.title}</p>
                                                                        <p style={{ fontSize: 16, margin: 0, }}>{resource.subTitle}</p>
                                                                        <div className={classes.styleStats} >
                                                                            <p><VisibilityIcon />{resource.numberViews}</p>
                                                                            <Rating
                                                                                name="hover-feedback"
                                                                                value={resource.average}
                                                                                precision={1}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <p
                                                    className={classes.textLink}
                                                    onClick={() => { props.history.push(getRoutePathname('learner_resource_type', { id: libraryResource.id })) }}
                                                >
                                                    {`Voir ${libraryResource.label} \>`}
                                                </p>
                                            </>}
                                    </div>
                                ))
                                :
                                <p>Aucune ressources disponible pour le moment...</p>
                            }
                        </>
                }
            </div>
        </>
    );
};

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    textLink: {
        textAlign: 'left',
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    },
    card: {
        height: 320,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            textAlign: 'left'
        }
    },
    imagePreload: {
        height: 160,
        width: '100%',
        maxWidth: 170,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    resourceImage: {
        width: '100%',
        height: 170,
        objectFit: 'cover'
    },
    styleStats: {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0 0 0',
        gap: 10,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: 5,
            border: '1px solid rgba(0, 0, 0, 0.15)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
            fontSize: 17,
        },
    }
});

export default LibraryResource;
