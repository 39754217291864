import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import Api from "../../../Api";
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ButtonComponent from '../../../Component/ButtonComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from "sweetalert-react";

const VirtualClassroomPeriodList = (props) => {

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [edit, setEdit] = React.useState({
        isEdit: false,
        idType: null
    });

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        
        {
            name: "startAt",
            label: "Heure de début",
            options: { filter: true, sort: true }
        },
        {
            name: "endAt",
            label: "Heure de fin",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const [form, setForm] = React.useState({
        startAt: {
            name: 'startAt',
            label: 'Heure de début',
            textHelper: 'Saisissez l\'heure de début de votre cours.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Heure de début',
            textHelper: 'Saisissez l\'heure de fin de votre cours.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });

    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setEdit({ isEdit: true, idType: row.id });
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setEdit({ isEdit: false, idType: row.id });
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des créneaux horaires',
            context: 'Gestion de contenu',
            description: ''
        });
        setReadyContent(true);
    }, []);


    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (edit.idType) {
                Api.post({
                    route: 'content_manager_virtual_classroom_period_edit',
                    data: handler.getData(),
                    params: { id: edit.idType }
                }, (response) => {

                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Créneau modifié.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('content_manager_virtual_classroom_period_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_virtual_classroom_period_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Créneau ajouté.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('content_manager_virtual_classroom_period_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);

                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <TableComponent
                id={'content_manager_virtual_classroom_period_list'}
                title={'Créneaux horaires'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un créneau horaire',
                    onClick: () => setEdit({ isEdit: true, idType: null })
                }}

                promiseData={(resolve) => {
                    Api.get({
                        route: 'content_manager_virtual_classroom_period_list'

                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={edit.isEdit} fullWidth={true}>
                <DialogTitle style={{ fontSize: 15 }}>{edit.idType ? 'Modifier le créneau horaire n°' : 'Ajouter un créneau horaire'}{edit.idType}</DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'startAt'} handler={handler} />
                                <TextFieldComponent name={'endAt'} handler={handler} />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setEdit({ isEdit: false, idType: edit.idType })} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ce créneau horaire ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_virtual_classroom_period_delete',
                        params: { id: edit.idType }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Créneau horaire supprimé.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_virtual_classroom_period_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
};

export default VirtualClassroomPeriodList;


