import React from 'react';
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

function RadioComponent(props = {
    name: '',
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? data.value : false);
    const disabled = data.loading || data.disabled;

    const onChange = (event) => {
        setValue(event.target.checked);

        if (props.onChange) {
            props.onChange(event.target.checked);
        }

        let newForm = {...props.handler.form};
        newForm[props.name].value = event.target.checked;
        newForm[props.name].error = '';
        props.handler.set(newForm);
    }

    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <FormControl variant="outlined" className={`${classes.checkbox}`}>
            <div style={{margin: 'auto'}}>
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>{data.options && data.options.switchLabelLeft}</Grid>
                    <Grid item>
                        <Switch
                            checked={value}
                            onChange={onChange}
                            name={props.name}
                            color="primary"
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item>{data.options && data.options.switchLabelRight}</Grid>
                </Grid>
            </div>
        </FormControl>
    );
}

const useStyles = makeStyles({
    checkbox: {
        width: '100%',
        textAlign: 'center',
        marginTop: 0
    }
});

export default RadioComponent;
