import React from 'react';
import './Css/style.css';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import {useDispatch} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {makeStyles} from '@material-ui/core/styles';
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import {theme} from "../../../App";
import TitleComponent from "../../../Component/TitleComponent";

let client = null;

function RegistrationVirtualClassroom(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [clientInfos, setClientInfos] = React.useState(null);

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('former_virtual_classroom_list'));
        }, 600);
    };
    React.useEffect(() => {
        setTimeout(() => setLoading(true), 50);
        if (props.match.params.id) {
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                    route: 'former_virtual_classroom',
                    params: {
                        id: props.match.params.id
                    }
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    console.log(response);
                    if (response.status === 200) {
                        setLoading(false);
                        let data = response.data;
                        setClientInfos(data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('former_virtual_classroom_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        props.history.push(getRoutePathname('former_virtual_classroom_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, []);


    React.useEffect(() => {
        if (clientInfos) {
            document.getElementsByClassName("MuiDrawer-docked")[0].style.display = "none";
            client = ZoomMtgEmbedded.createClient();
            startMeeting();
        }
        return () => {
            if (client) {
                client.leaveMeeting();
                client = null
                document.getElementsByClassName("MuiDrawer-docked")[0].style.display = "block";
            }
        }
    }, [clientInfos]);


    const startMeeting = () => {
        let meetingSDKElement = document.getElementById('meeting-zoom-former');

        client.init({
            zoomAppRoot: meetingSDKElement,
            language: 'fr-FR',
            customize: {
                meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
            }
        });

        client.join({
            apiKey: clientInfos.apiKey,
            signature: clientInfos.signature,
            meetingNumber: clientInfos.meetingNumber,
            password: 855788,
            userName: clientInfos.userName

        })
    }

    return (
        <div style={{textAlign: 'center', maxWidth: '90%', margin: 'auto'}}>
            {
                loading ?
                    <Fade in={true} {...{timeout: 1000}}>
                        <div className={classes.loader}>
                            <div className={classes.loaderContent}>
                                <ShadowBoxComponent>
                                    <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                    <div className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={35}/>
                                    </div>
                                </ShadowBoxComponent>
                            </div>
                        </div>
                    </Fade>
                    :
                    <Fade in={show} {...{timeout: 1000}}>
                        <div>
                            <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack}>
                                <ArrowLeftIcon className={classes.shadowBoxBackIcon}/>
                                <span className={classes.shadowBoxBackText}>Revenir au sommaire</span>
                            </ShadowBoxComponent>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                <TitleComponent title={'Classe Virtuelle'}/>
                                    <div id="meeting-zoom-former"></div>
                            </ShadowBoxComponent>
                        </div>
                    </Fade>
            }
        </div>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left',
        height: '1020px'
    }
});

export default RegistrationVirtualClassroom;
