import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Button, makeStyles } from "@material-ui/core";
import { getRoutePathname } from "../../../Config/Route";
import Fade from "@material-ui/core/Fade";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { CloudDownload } from "@material-ui/icons";
import TableComponent from '../../../Component/TableComponent';
import Api from "../../../Api";
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';

function Epp(props) {

    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [registrations, setRegistrations] = React.useState();

    // FORM
    const [form, setForm] = React.useState({
        organism: {
            name: 'organism',
            label: ' ',
            textHelper: ' ',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Numéro de programme',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        },
        formationName: {
            name: 'formationName',
            label: 'Nom de la formation',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Début de session',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de session',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            disabled: true,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    // TABLE
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "finishAt",
            label: "Formation terminée le",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const download = (downloadType) => {
        let isRegistrationFinish = false;
        for (let i = 0; i < registrations.length; i++) {
            const registration = registrations[i];
            if (registration.finishAt === '-' || registration.isFinishBeforeDeploymentEpp) {
                continue;
            }
            else {
                isRegistrationFinish = true;
                break;
            }
        }
        if (isRegistrationFinish) {
            if (downloadType === 'Pdf') {
                const url = Api.route({
                    route: 'reporting_pedagogic_epp_session_formation_download_form',
                    params: { id: props.match.params.id },
                });
                window.open(url);
            }
            if (downloadType === 'Excel') {
                Api.getDownload({
                    route: 'reporting_pedagogic_epp_session_formation_download_form_excel',
                    params: { id: props.match.params.id },
                    filename: 'export_audits.xls'
                }, (response) => {
                    if (response.status === 200) {
                        console.log('ok')
                    }
                })
            }
        }
        else {
            dispatch(
                SnackbarOpen({
                    text: 'Aucune Inscriptions ne possède un questionnaire epp valide',
                    variant: 'error',
                })
            );
        }
    }
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Télécharger pdf EPP'} placement="left">
                    <IconButton
                        onClick={() => {
                            const url = Api.route({
                                route: 'reporting_pedagogic_epp_session_formation_download_form',
                                params: { id: props.match.params.id },
                                data: { registration: row.id }
                            });
                            window.open(url, '_blank');
                        }}
                        disabled={row.finishAt === '-' || row.isFinishBeforeDeploymentEpp}
                    >
                        <CloudDownload />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Télécharger excel EPP'} placement="left">
                    <IconButton
                        onClick={() => {
                            Api.getDownload({
                                route: 'reporting_pedagogic_epp_session_formation_download_form_excel',
                                params: { id: props.match.params.id },
                                filename: 'export_audits_'+row.firstname+'_'+row.lastname+'_.xls',
                                data: { registration: row.id }
                            }, (response) => {
                                if (response.status === 200) {
                                    console.log('ok')
                                }
                            })
                        }}
                        disabled={row.finishAt === '-' || row.isFinishBeforeDeploymentEpp}
                    >
                        <CloudDownload />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    // INIT
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Évaluation pratiques professionnelles',
            context: 'Reporting pédagogique',
            description: 'Session formation.',
            links: [
                { path: getRoutePathname('reporting_pedagogic_epp_list'), label: 'Liste des sessions formation' }
            ]
        });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={true} {...{ timeout: 1000 }}>
                <ShadowBoxComponent>
                    <TitleComponent title={
                        <div>
                            {form.organism.value === 1 && <img src="/asset/images/logo_santeformapro.png" alt="logo" style={{ height: 30, marginBottom: -8 }} />}
                            {form.organism.value === 2 && <img src="/asset/images/logo_revuedupraticiendpc.png" alt="logo" style={{ height: 30, marginBottom: -8 }} />}
                            {form.organism.value === 3 && <img src="/asset/images/logo_actionsante.png" alt="logo" style={{ height: 30, marginBottom: -8 }} />}
                        </div>
                    } />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'formationName'} handler={handler} />
                        </Grid>

                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'andpc'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'startAt'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'endAt'} handler={handler} />
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </Fade>
            <br />

            <Fade in={true} {...{ timeout: 1000 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Grid item xs={12}>
                            <ShadowBoxComponent>
                                <TitleComponent title={'Actions'} style={{ marginTop: 10 }} />
                                <Button
                                    onClick={() => download('Pdf')}
                                    variant="contained"
                                    className={classes.action}
                                    style={{
                                        color: '#fff', backgroundColor: '#3f51b5'
                                    }}>
                                    Télécharger tous les EPP en Pdf
                                </Button>
                                <Button
                                    onClick={() => download('Excel')}
                                    variant="contained"
                                    className={classes.action}
                                    style={{
                                        color: '#fff', backgroundColor: '#3f51b5'
                                    }}>
                                    Télécharger tous les EPP en Excel
                                </Button>
                            </ShadowBoxComponent>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                        <TableComponent
                            id={'reporting_pedagogic_epp_registrations_list'}
                            title={'Liste des inscriptions'}
                            height={'calc(100vh - 300px)'}
                            columns={columns}
                            search={false}
                            promiseData={(resolve) => {
                                Api.get({
                                    route: 'reporting_pedagogic_epp_session_formation_registration_list',
                                    params: { id: props.match.params.id }
                                }, (response) => {
                                    if (response.status === 200) {
                                        handler.setDataApi(response.data);
                                        let data = response.data.registrations;
                                        setRegistrations(response.data.registrations);
                                        for (let index in data) {
                                            data[index].action = getAction(data[index]);
                                        }
                                        resolve(data);
                                    } else if (response.status === 404) {
                                        props.history.push(getRoutePathname('reporting_pedagogic_epp_session_formation_list'));
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error.message,
                                                variant: 'warning',
                                            })
                                        );
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Une erreur inattendue s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                            }}
                            small={true}
                        />
                    </Grid>
                </Grid>
            </Fade>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    action: {
        fontSize: 12,
        width: '100%',
        marginBottom: 6
    }
});

export default Epp;