import {createStyles, makeStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import React from 'react'

export default function TitleComponent(props = {
    title: null,
    icon: null,
    color: null,
    iconColor: null,
    titleColor: null,
    iconSize: null,
}) {
    const classes = useStyles();

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {props.icon && (
                    <IconButton
                        className={classes.icon}
                        disableRipple={true}
                        style={{color: '#5E6E82', padding: 5}}
                    >
                        {props.icon}
                    </IconButton>
                )}
                <div className={classes.title}>
                    {props.title}
                </div>
            </div>
            <hr align="left" style={{marginBottom: 20}} className={classes.hr}/>
        </>
    )
}

const useStyles = makeStyles(createStyles({
    icon: {
        cursor: 'default',
        "&:hover": {
            backgroundColor: '#fff',
        },
    },
    title: {
        fontSize: 18,
        color: '#5E6E82',
        padding: '0 5px 10px 5px',
        width: '100%',
        fontWeight: 300,
        textAlign: 'left'
    },
    hr: {
        marginBottom: '20px',
        marginTop: '0px',
        width: '100%',
        height: '1px',
        border: 0,
        borderBottom: 'solid 1px #5E6E82',
    }
}));
