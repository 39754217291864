import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ContactsIcon from '@material-ui/icons/Contacts';
import { Assignment } from "@material-ui/icons";
import { getRouteByPathname, getRoutePathname } from "../../../../Config/Route";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, makeStyles } from "@material-ui/core";
import { hasRight } from "../../../../Tool/UserTool";
import SweetAlert from "sweetalert-react";
import FilterListIcon from "@material-ui/icons/FilterList";
import Grid from "@material-ui/core/Grid";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../../Component/SelectComponent";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { TableReload } from "../../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import { Drawer as DrawerMUI } from "@material-ui/core";
import CheckboxComponent from "../../../../Component/CheckboxComponent";
import LoginAsComponent from '../../../../Component/LoginAsComponent';
import { theme } from "../../../../App";
import ButtonComponent from '../../../../Component/ButtonComponent';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import DeleteIcon from '@material-ui/icons/Delete';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import TitleComponent from '../../../../Component/TitleComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';

function InstitutionEmployeeList(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionFunctions, setOptionFunctions] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [pole, setPole] = React.useState('');
    const [isRoute, setIsRoute] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isScreen, setIsScreen] = React.useState(true);
    const [isAddEmployee, setIsAddEmployee] = React.useState(false);
    //const [isAddEmployeeByPole, setIsAddEmployeeByPole] = React.useState(false);
    const [isDialogEndFunction, setIsDialogEndFunction] = React.useState(false);
    const [actionRow, setActionRow] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const institutionId = props.match.params.institution ? props.match.params.institution : props.match.params.id;

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "lastName",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstName",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "function",
            label: "Fonction",
            options: { filter: true, sort: true }
        },
        {
            name: "pole",
            label: "Pôle",
            options: { filter: true, sort: true }
        },
        {
            name: "rpps",
            label: "Rpps",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: { filter: true, sort: true }
        },
        {
            name: "assign",
            label: "Assigné",
            options: { filter: true, sort: true }
        },
        {
            name: "startAt",
            label: "Date de début",
            options: { filter: true, sort: true }
        },
        {
            name: "endAt",
            label: "Date de fin",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row, list) => {
        return (
            <div style={{ width: 'max-content' }}>
                {(row.learnerId &&
                    (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_LOGIN_AS'))) &&
                    <LoginAsComponent email={row.email} organism={selectOrganismReducer.organism.value} />
                }
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: institutionId, id: row.id }), { list: list })}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Fin de fonction'} placement="left">
                    <IconButton onClick={() => { setIsDialogEndFunction(true); setActionRow(row); handler.setValue('startAt', row.startAt); handler.setValue('endAt', row.endAt) }}>
                        <WorkOffIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => { setShowConfirm(true); setActionRow(row) }}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        );
    };
    // Search
    const [form, setForm] = React.useState({
        startAt: {
            name: 'startAt',
            label: 'Début de fonction',
            textHelper: 'Saisissez la date de début.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de fonction',
            textHelper: 'Saisissez la date de fin.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },

    });
    const handler = formHandlerInit(form, setForm);

    const saveEndFunction = () => {
        if (handler.checkError()) {
            const errorLabels = Object.values(handler.form).filter(item => item.error).map(item => item.label);
            dispatch(
                SnackbarOpen({
                    text: errorLabels.length === 1 ? 'Le champ "' + errorLabels + '" est obligatoire' : 'Les champs "' + errorLabels.join(', ') + '" sont obligatoires',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (institutionId) {
                Api.post({
                    route: 'institution_health_crm_institution_employee_end_function',
                    data: handler.getData(),
                    params: { institution: institutionId, id: actionRow.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setIsDialogEndFunction(false);
                    handler.reset();
                    if (response.status === 200) {
                        dispatch(TableReload('institution_health_crm_institution_employee_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Ce salarié à été démis ces fonctions.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    // Search
    const [formSearch, setFormSearch] = React.useState({
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: 'Saisissez votre recherche.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Trier par pôle.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_employee_list'] && cacheTableServer['institution_health_crm_institution_employee_list'].pole ? cacheTableServer['institution_health_crm_institution_employee_list'].pole : 'all',
            options: {}
        },
        function: {
            name: 'function',
            label: 'Fonction',
            textHelper: 'Trier par Fonction.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_employee_list'] && cacheTableServer['institution_health_crm_institution_employee_list'].function ? cacheTableServer['institution_health_crm_institution_employee_list'].function : 'all',
            options: {}
        },
        isEnd: {
            name: 'isEnd',
            label: 'Plus en fonction',
            textHelper: '',
            type: 'boolean',
            defaultValue: '',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_crm_institution_employee_list'] = 0;
        cacheTableServer['institution_health_crm_institution_employee_list'].page = 0;
        cacheTableServer['institution_health_crm_institution_employee_list'][index] = value;
        dispatch(TableReload('institution_health_crm_institution_employee_list'))
    };

    const table = (pole) => {
        return (
            <TableComponent
                id={'institution_health_crm_institution_employee_list'}
                title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><ContactsIcon style={{ width: 20, height: 20 }} />Contacts</h1>}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un salarié',
                    onClick: () => false ? null /*setIsAddEmployee(true)*/ : props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: institutionId, id: null }))
                }}
                promiseServerData={(resolve, options) => {
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        organismId: selectOrganismReducer.organism.value,
                        poleId: pole ? pole : ((formSearch.pole.value === 'all') ? '' : formSearch.pole.value),
                        text: formSearch.text.value ? formSearch.text.value : '',
                        function: (formSearch.function.value === 'all') ? '' : formSearch.function.value,
                        isEnd: formSearch.isEnd.value,
                    };

                    Api.get({
                        route: 'institution_health_crm_institution_employee_list',
                        params: { institution: institutionId },
                        data: filters
                    },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                const newData = { ...data[index] };
                                data[index].assign = data[index].commercialId === authenticationReducer.id ? 'Vous' : (data[index].commercialName ?? '-');
                                data[index].action = getAction(newData, response.data.list);
                                data[index].function = data[index].function ? data[index].function : '-';
                                data[index].mobile = data[index].mobile ? data[index].mobile : '-';
                                data[index].phone = data[index].phone ? data[index].phone : '-';
                                data[index].rpps = data[index].rpps ? data[index].rpps : '-';
                                data[index].email = data[index].email ? data[index].email : '-';
                                data[index].pole = data[index].pole ? data[index].pole : '-';
                                data[index].startAt = data[index].startAt ? moment(data[index].startAt).format('lll') : '-';
                                data[index].endAt = data[index].endAt ? moment(data[index].endAt).format('lll') : '-';
                                data[index].firstName = data[index].firstName ? data[index].firstName : '-';
                                data[index].lastName = data[index].lastName ? data[index].lastName : '-';
                            }

                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />
        )
    }

    const GetButtonAction = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'text'} handler={handlerSearch} onChange={(val) => inputSearchChange('text', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'function'} handler={handlerSearch} options={optionFunctions} onChange={(val) => inputSearchChange('function', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'pole'} handler={handlerSearch} options={optionPoles} onChange={(val) => inputSearchChange('pole', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CheckboxComponent name={'isEnd'} handler={handlerSearch} onChange={(val) => inputSearchChange('isEnd', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Salariés',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des salariés.',
            links: [
                { path: getRoutePathname('institution_health_crm_institution_manager', { institution: props.match.params.institution }), label: 'Tableau de bord' }
            ],
            html: <GetButtonAction />

        });
    }, [isOpen]);

    React.useEffect(() => {
        setReadyContent(true);
        if (!props.isForm) {
            Api.get({
                route: 'select_institution_functions'
            },
                (response) => {
                    if (response && response.data) {
                        let data = response.data;
                        setOptionFunctions([{ value: 'all', label: 'Tous' }, ...data]);
                    }
                });
            Api.get({
                route: 'select_institution_poles',
                params: { institution: institutionId }
            },
                (response) => {
                    if (response && response.data) {
                        let data = response.data;
                        setOptionPoles([{ value: 'all', label: 'Tous' }, ...data]);
                    }
                });
        }
    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <>
            {
                props.isForm ?
                    table(props.pole)
                    :
                    <Box className={classes.styleContentView}>
                        <DrawerMUI
                            variant="permanent"
                            className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                            style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                            open={isOpen}>
                            {filter()}
                        </DrawerMUI>
                        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                            <Box className={classes.TableComponent} style={{ width: '95%' }}>
                                {table(null)}
                            </Box>
                        </ContentViewComponent>
                    </Box>
            }
            <Dialog open={isDialogEndFunction} maxWidth={'lg'} className={classes.styleDialog} onClose={() => { setIsDialogEndFunction(false); }}>
                <DialogContent style={{ padding: 20 }}>
                    <TitleComponent title={<><b>Fin de fonction</b></>} />
                    <Grid container spacing={1} style={{ width: '100%', margin: '0', }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <DatePickerComponent name={'startAt'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <DatePickerComponent name={'endAt'} handler={handler} />
                        </Grid>
                    </Grid>
                    <Box style={{ margin: 'auto 0 0 auto', width: 'fit-content', display: 'flex', gap: 5 }}>
                        <ButtonComponent label={'Annuler'} color={'#5E6E82'} className={classes.button} onClick={() => { setIsDialogEndFunction(false); handler.reset() }} />
                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={() => { saveEndFunction() }} />
                    </Box>
                </DialogContent>
            </Dialog>
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer ce salarié ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'institution_health_crm_institution_employee_delete',
                        params: { id: actionRow.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            props.isForm && props.getData();
                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Salarié supprimé.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('institution_health_crm_institution_employee_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
            {/*
             <Dialog open={isAddEmployeeByPole} maxWidth={'lg'} className={classes.styleDialog} onClose={() => { setIsAddEmployeeByPole(false); }}>
                <InstitutionPoleEmployee poleId={pole} setIsOpen={setIsAddEmployeeByPole} {...props} />
            </Dialog>
           <Dialog open={isAddEmployee} maxWidth={'lg'} onClose={() => { setIsAddEmployee(false); }}>
                <Box>
                    <h1 style={{ color: '#5E6E82', fontSize: 23, margin: 0, padding: 20, display: 'flex', }} >
                        Ajouter un employer
                        <Tooltip title={'Fermer'} placement="left">
                            <IconButton style={{ padding: 2, margin: '0 0 0 auto' }} onClick={() => { setIsAddEmployee(false) }}>
                                <CloseIcon style={{ color: '#5E6E82' }} />
                            </IconButton>
                        </Tooltip>
                    </h1>
                    <hr style={{ margin: 0, opacity: 0.2 }} />
                </Box>
                <Grid container spacing={0} style={{ maxWidth: 600, padding: 23 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle}>
                            <Box style={{ cursor: 'pointer' }} onClick={() => { setIsAddEmployeeByPole(true); setIsAddEmployee(false) }}>
                                <Box><ListAltIcon /></Box>
                                <Box>
                                    <p><b>Employé existant.</b></p>
                                    <p>Sélection de plusieurs employés déjà ajoutés à la plateforme</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle} onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: institutionId, id: null }))}>
                            <Box style={{ cursor: 'pointer' }}>
                                <Box><ListAltIcon /></Box>
                                <Box>
                                    <p><b>Employé non existant.</b></p>
                                    <p>Entrer un nouvel employé dans ce pôle</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
        </Dialog>
        */}
        </>
    )
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;

const useStyles = (theme) => makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleDialog: {
        '& .MuiDialog-paperScrollPaper': {
            //height: 'calc(100% - 170px)',
            //width: 1000
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    boxPopupAction: {
        '& .root-BoxPopupAction-Popup': {
            maxWidth: 700,
            height: 'auto',
            '& .MuiGrid-item': {
                maxWidth: 'initial',
                flexBasis: 'fit-content',
            }
        }
    },
    boxDialogStyle: {
        background: '#EFF2F4',
        boxShadow: '0px 0px 2px #0000005e',
        borderRadius: 4,
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            padding: 18,
            margin: '5px 0',
        },
        '& label': {
            background: 'transparent !important'
        },
        '& p': {
            color: '#5E6E82',
            margin: 0
        },
        '& svg': {
            color: '#5E6E82',
            margin: 0
        }
    }
}));

export default InstitutionEmployeeList;