import React, { useEffect, useRef, useState } from 'react';
import { IconButton, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const LoadingAnimation = () => {
    const classes = useStyles();
    return (
        <div className={classes.loading + ' root-Loading'}>
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
            <div class="four"></div>
            <div class="five"></div>
        </div>
    )
}

export default function CarouselComponent({ getValue, ...props }) {
    const classes = useStyles();

    const carouselContentRef = useRef(null)
    const [carouselContent, setCarouselContent] = useState('');
    const [carouselPosition, setCarouselPosition] = useState(0);
    const [carouselValue, setCarouselValue] = useState(null);
    const [disablePrev, setDisablePrev] = useState(true)
    const [disableNext, setDisableNext] = useState(false)
    // TODO: Gérer le Disable

    useEffect(() => {
        console.log(props.options);
        if (props.options.length > 0) {
            const options = props.options;
            let carouselContentTMP = <></>

            // Pour dupliquer le last child en premiere position
            if (props.loop) {
                setDisablePrev(false)
                carouselContentTMP =
                    <div
                        key={options[options.length - 1].value}
                        className={classes.carouselItem}
                        data-value={options[options.length - 1].value}
                        style={{ left: `-100%` }}
                    >
                        {options[options.length - 1].label}
                    </div>
            }

            options.forEach((e, i) => {
                carouselContentTMP = <>
                    {carouselContentTMP}
                    {i === 0 ? setCarouselValue(e.value) : null}
                    <div key={e.value} className={classes.carouselItem} data-value={e.value} style={{ left: `${i * 100}%` }}>{e.label}</div>
                </>
            })
            setCarouselContent(carouselContentTMP)
        }
        if (props.options.length === 1) { 
            setDisableNext(true)
            setDisablePrev(true) 
        } else { 
            setDisableNext(false)
        };
        setCarouselPosition(0)
    }, [props.options])

    async function carouselAnimation(isNext) {
        if (carouselContentRef) {
            const items = Array.from(carouselContentRef.current.children);
            items.forEach((e) => {
                let newLeft;
                if (isNext) {
                    console.log(carouselPosition);
                    setDisableNext(!props.loop && ((carouselPosition + 1) >= (items.length - 1)));
                    setDisablePrev(false);
                    setCarouselPosition(carouselPosition + 1);
                    newLeft = parseInt(e.style.left) - 100;
                } else {
                    setDisablePrev(!props.loop && ((carouselPosition - 1) === 0));
                    setDisableNext(false);
                    setCarouselPosition(carouselPosition - 1);
                    newLeft = parseInt(e.style.left) + 100;
                }

                e.style.left = newLeft + '%'
                if (newLeft === 0) {
                    setCarouselValue(parseInt(e.dataset.value));
                }
            });

            if (props.loop) {
                if (isNext) {
                    carouselContentRef.current.removeChild(carouselContentRef.current.firstChild);
                    const clonedElement = carouselContentRef.current.firstChild.cloneNode(true);
                    clonedElement.style.left = `${(props.options.length - 1) * 100}%`;
                    carouselContentRef.current.insertBefore(
                        clonedElement,
                        carouselContentRef.current.lastChild.nextSibling
                    );
                } else {
                    carouselContentRef.current.removeChild(carouselContentRef.current.lastChild);
                    const clonedElement = carouselContentRef.current.lastChild.cloneNode(true);
                    clonedElement.style.left = `-100%`;
                    carouselContentRef.current.insertBefore(
                        clonedElement,
                        carouselContentRef.current.firstChild
                    );
                }
            }
        }
        return new Promise(function (resolve) {
            setTimeout(resolve, 400);
        });
    }

    useEffect(() => {
        getValue(carouselValue)
    }, [carouselValue])

    return (
        props.loading ?
            <LoadingAnimation /> :
            (props.options.length != 0 ?
                <div className={classes.carouselContainer + ' ' + props.className + ' root-carouselComponent'}>
                    <IconButton
                        onClick={() => {
                            carouselAnimation(false)
                        }}
                        disabled={disablePrev || props.loading}
                    >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <div className={classes.carouselContent}>
                        <div ref={carouselContentRef} className={classes.carouselContentWrapper}>{carouselContent}</div>
                    </div>
                    <IconButton
                        onClick={() => {
                            carouselAnimation(true)
                        }}
                        disabled={disableNext || props.loading}
                    >
                        <ChevronRightIcon fontSize="large" />
                    </IconButton>
                </div>
                : <p style={{ textAlign: 'center', }}>{props.error}</p>
            )
    )
}


const useStyles = makeStyles({
    carouselContainer: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'center',
    },
    carouselContent: {
        flex: 'auto',
        alignSelf: 'stretch',
        overflow: 'hidden',
    },
    carouselContentWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    carouselItem: {
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        transition: 'all 0.3s linear',
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%) scale(47%)',
        '& div': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            margin: 'auto',
            animation: '$loading 2.5s ease-in-out infinite',
        },
        '& div:nth-child(0)': {
            animationDelay: '0s',
        },
        '& div:nth-child(1)': {
            animationDelay: '0.2s',
        },
        '& div:nth-child(2)': {
            animationDelay: ' 0.4s',
        },
        '& div:nth-child(3)': {
            animationDelay: '0.6s',
        },
        '& div:nth-child(4)': {
            animationDelay: '0.8s',
        },
        '& div:nth-child(5)': {
            animationDelay: '1s',
        }
    },

    '@keyframes loading': {
        '0%, 100%': {
            transform: 'scale(0.2)',
            backgroundColor: '#5E6E82',
        },
        '42%': {
            backgroundColor: '#5E6E82',
        },
        '50%': {
            transform: 'scale(1)',
            backgroundColor: '#5E6E82',
        },
    }
});