import React from 'react'
import {makeStyles} from "@material-ui/core";


export default function FlipCardComponent(props) {
    const classes = useStyles();

    return (
        <div className={classes.flipCard}>
            <div className={classes.flipCardInner} style={{transform: props.flip ? 'rotateX(180deg)' : 'none' }}>
                <div className={classes.flipCardChild + " " + classes.flipCardFront}>
                    {props.frontCard}
                </div>
                <div className={classes.flipCardChild + " " + classes.flipCardBack} >
                    {props.backCard}
                </div>
            </div>
        </div>
    )
}
const useStyles = makeStyles({
    flipCard: {
        backgroundColor: 'transparent',
        perspective: '1000px',
    },
    flipCardInner: {
        position: 'relative',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        transition: 'transform 0.6s',
        transformStyle: 'preserve-3d',
    },
    flipCardChild: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        '-webkit-backface-visibility': 'hidden',
        backfaceVisibility: 'hidden',
    },
    flipCardFront: {
    backgroundColor: 'green',
    },
    flipCardBack: {
    backgroundColor: 'red',
    transform: 'rotateX(180deg)',
    }
});