import React, { useEffect } from 'react';
import { Box, Grid, Slide, makeStyles, Grow, InputLabel, Select, MenuItem, FormControl, LinearProgress, Fade, TableCell, TableContainer, Table, TableHead, TableRow, TableBody } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import { LinearProgressMainLayoutDeactivate, LinearProgressMainLayoutActivate } from '../../../../Action/LinearProgressMainLayoutAction';
import BarChartComponent from "./Components/BarChartComponent";
import DoughnutChartComponent from "./Components/DoughnutChartComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import Api from "../../../../Api";
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <>
                    {expandComponent}
                </>
            )}
        </>
    );
};

export default function DashboardManager(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [numberRegistrations, setNumberRegistrations] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());
    const [yearAt, setYearAt] = React.useState(moment().format('YYYY'));
    const [endAt, setEndAt] = React.useState(null);
    const [textSearchSlider, setTextSearchSlider] = React.useState('');
    const [chartTimeText, setChartTimeText] = React.useState('journalier');
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const [period, setPeriod] = React.useState(0);
    const [loadingData, setLoadingData] = React.useState(false);
    const [info, setInfo] = React.useState();
    const [searchFormationGroup, setSearchFormationGroup] = React.useState("Pharmacien");
    const [barSearchFormationGroup, setBarSearchFormationGroup] = React.useState('all');
    const [formationGroups, setFormationGroups] = React.useState([
        {value: 5, label: "Pharmacien"}
    ]);
    const [barFormationGroups, setBarFormationGroups] = React.useState([
        {value: 5, label: "Pharmacien"}
    ]);
    const [dataByFormationGroup, setDataByFormationGroup] = React.useState([]);
    const [dataRankingSelling, setDataRankingSelling] = React.useState();
    const [averageByFormationGroup, setAverageByFormationGroup] = React.useState();
    const tableFormationGroups =
        selectOrganismReducer.organism.value === 5 ?
            [{ index: 'phar', text: 'Pharmacien' }] :
            [
                { index: 'med', text: 'Médecin' },
                { index: 'inf', text: 'Infirmière' },
                { index: 'dent', text: 'Dentiste' },
                { index: 'kin', text: 'Kinésithérapeute' },
                { index: 'orth', text: 'Orthophoniste' },
                { index: 'diver', text: 'Divers' },
                { index: 'phar', text: 'Pharmacien' },
                { index: 'ruma', text: 'Rumathologue' },
            ];

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Tableau Manager',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Suivi des inscriptions',
            links: []
        });
        setReadyContent(true);
    }, []);

    const Loader = () => {
        return (
            <span className={classes.loader}>
                <span className={`${classes.dot} ${classes.dot1}`}>•</span>
                <span className={`${classes.dot} ${classes.dot2}`}>•</span>
                <span className={classes.dot}>•</span>
            </span>
        )
    };

    // Filters
    const [form, setForm] = React.useState({
        commercials: {
            name: 'commercials',
            label: 'Commercials',
            textHelper: 'Trier par commercials.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['array'] }
        },
        financeType: {
            name: 'financeType',
            label: 'Types de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: 'all',
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);


    const changeSearchSlider = (direction = null) => {
        let newStartAt;
        let newEndAt;

        switch (period) {
            case 0: { // Journalière
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'days');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'days');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('day').format());
                newStartAt = moment(newStartAt.startOf('day').format());

                setTextSearchSlider(newStartAt.format('dddd LL'));
                break;
            }
            case 1: { // Hebdomadaire
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'week');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'week');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('week').format());
                newStartAt = moment(newStartAt.startOf('week').format());

                setTextSearchSlider(newStartAt.format('dddd LL') + ' - ' + newEndAt.format('dddd LL'));
                break;
            }
            case 2: { // Mensuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'month');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'month');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('month').format());
                newStartAt = moment(newStartAt.startOf('month').format());

                setTextSearchSlider(newStartAt.format('MMMM YYYY'));
                break;
            }
            case 3: { // Annuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('year').format());
                newStartAt = moment(newStartAt.startOf('year').format());

                setTextSearchSlider(newStartAt.format('YYYY'));
                break;
            }
            case 4: { // Personnalisée par plage
                newEndAt = endAt;
                newStartAt = startAt;
                break;
            }
            default: {
                newStartAt = null;
                newEndAt = null;
                break;
            }
        }
        setYearAt(newStartAt.format('YYYY'))
        setStartAt(newStartAt);
        setEndAt(newEndAt);
    };

    const periodChange = (event) => {
        setStartAt(moment().startOf('day'));
        setPeriod(parseInt(event.target.value));
    }

    useEffect(() => {
        if (period) {
            switch (period) {
                case 0:
                    setChartTimeText('journalier')
                    break
                case 1:
                    setChartTimeText('hebdomadaire')
                    break
                case 2:
                    setChartTimeText('mensuel')
                    break
                case 3:
                    setChartTimeText('annuel')
                    break
                default:
                    setChartTimeText('');
                    break
            }
        }
    }, [period]);

    const renderSearch = () => {
        return <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
            <div>
                <ShadowBoxComponent>
                    <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Période</InputLabel>
                                <Select
                                    native
                                    value={period}
                                    onChange={periodChange}
                                    label="Période"
                                    inputProps={{ name: 'Période', id: 'outlined-age-native-simple' }}
                                    className={classes.selectPeriod}
                                >
                                    <option value={0}>Journalière</option>
                                    <option value={1}>Hebdomadaire</option>
                                    <option value={2}>Mensuelle</option>
                                    <option value={3}>Annuelle</option>
                                    <option value={4}>Personnalisée par plage</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        {period !== 4 ?
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{ position: 'relative' }}>
                                <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')} />
                                <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')} />
                            </Grid> :
                            <>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <KeyboardDatePicker
                                        className={classes.datepicker}
                                        autoOk
                                        inputVariant="outlined"
                                        margin="normal"
                                        id="startAt"
                                        label="Début"
                                        format="dd/MM/yyyy"
                                        value={startAt}
                                        onChange={(date) => setStartAt(moment(date).startOf('day'))}
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <KeyboardDatePicker
                                        className={classes.datepicker}
                                        autoOk
                                        inputVariant="outlined"
                                        margin="normal"
                                        id="endAt"
                                        label="Fin"
                                        format="dd/MM/yyyy"
                                        value={endAt}
                                        onChange={(date) => setEndAt(moment(date).endOf('day'))}
                                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} sm={4} md={12} lg={6} xl={6}>
                            <MultipleSelectComponent name={'commercials'} handler={handler} options={optionCommercials} onChange={(val) => inputSearchChange('commercials', val)} />
                        </Grid>

                        <Grid item xs={12} sm={4} md={12} lg={6} xl={6}>
                            <SelectComponent name={'financeType'} handler={handler} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </div>
        </Slide>;
    };

    const inputSearchChange = (index, value) => {
        handler.setValue(index, value);
    };

    // Use Effect
    React.useEffect(handler.start, []);
    let datas = handler.getData();
    React.useEffect(changeSearchSlider, [period]);
    React.useEffect(() => {
        Api.get({
                route: 'institution_health_crm_commercial_list',
                data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([...response.data]);
                }
            });

        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': false }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceType([...[{ value: 'all', label: 'Tous' }], ...response.data]);
                }
            });
        if (selectOrganismReducer.organism.value !== 5) {
            Api.get({
                    route: 'select_formation_groups',
                },
                (response) => {
                    if (response && response.data) {
                        let data = [];
                        for (let index in response.data) {
                            data.push({
                                label: response.data[index].label,
                                value: response.data[index].label,
                            });
                        }
                        setFormationGroups(data);
                        setBarFormationGroups([{ label: 'Tous', value: 'all' }, ...data]);
                        setSearchFormationGroup(data[7].value);
                        setBarSearchFormationGroup('all');
                    }
                });
        }
        setBreadcrumbs({
            title: 'Tableau de bord Manager',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Suivi des inscriptions'
        });
        setReadyContent(true);
    }, []);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoadingData(true);
        if (startAt && endAt) {
            let filters = {
                startAt: startAt.format('YYYY-MM-DD HH:mm:ss'),
                endAt: endAt.format('YYYY-MM-DD HH:mm:ss'),
                commercials: (datas.commercials === '' || !datas.commercials) ? '' : datas.commercials,
                financeType: (datas.financeType === 'all' || !datas.financeType) ? '' : datas.financeType,
                organism: selectOrganismReducer.organism.value,
            };
            Api.get({
                route: 'institution_health_crm_dashboard_manager_registrations',
                data: filters,
            },
                (response) => {
                    if (response && response.data) {
                        let dataFormationGroup = response.data.registrationByFormationGroup;
                        setInfo(response.data.info);
                        let dataByFormationGroupTMP = []
                        for (let index in dataFormationGroup) {
                            dataByFormationGroupTMP.push({
                                label: dataFormationGroup[index].formationGroup,
                                callback: [
                                    'CA previsionnel: ' + dataFormationGroup[index].CAPreSells,
                                    'CA valide: ' + dataFormationGroup[index].CAAvailableSells,
                                    'CA facturé: ' + dataFormationGroup[index].CABilledSells,
                                    'CA annulé: ' + dataFormationGroup[index].CACanceledSells,
                                    'CA supprimé: ' + dataFormationGroup[index].CADeletedSells,
                                ],
                                "Pre-inscription": dataFormationGroup[index].preSells,
                                "Inscription validée": dataFormationGroup[index].availableSells,
                                "Inscription facturé": dataFormationGroup[index].billedSells,
                                "Inscription annulée": dataFormationGroup[index].canceledSells,
                                "Inscription supprimée": dataFormationGroup[index].deletedSells,
                            });
                        }
                        setDataByFormationGroup(dataByFormationGroupTMP);
                        setDataRankingSelling(response.data.dataRankingSelling);
                        setAverageByFormationGroup(response.data.averageByFormationGroup);
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoadingData(false);
                });
        }
    }, [startAt, datas.commercials, datas.financeType, datas.poles]);

    const renderObjectives = (info) => {
        if (info) {
            let data = {
                global: (info.totalCANet / 1000000).toFixed(2),
                MG: (info.totalCANetMg / 1000000).toFixed(2),
                IDEL_KINES: (info.totalCANetIdelKine / 1000000).toFixed(2),
            };

            let filterYear = textSearchSlider;
            let objectives = [
                {
                    year: 2023,
                    data: [
                        { label: 'global', value: '5.3', displayName: 'CA Global' },
                        { label: 'MG', value: '2.9', displayName: 'CA Médecin Généraliste' },
                        { label: 'IDEL_KINES', value: '2.3', displayName: 'CA Infirmier / Kinésithérapeute' },
                    ],
                }
            ];

            const filteredObjectives = objectives.find(obj => obj.year === parseInt(filterYear));

            if (!filteredObjectives) {
                return (
                    <Fade in={true} {...{ timeout: 1500 }}>
                        <p style={{ fontWeight: 600 }}>Pas d'objectifs pour cette année</p>
                    </Fade>
                );
            }

            return (
                loadingData ?
                    <Fade in={loadingData} {...{ timeout: 1500 }}>
                        <div style={{ width: '100%', height: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                <span style={{ fontWeight: 600 }}>chargement</span>
                                <UseAnimations animation={activity} size={30} />
                            </div>
                        </div>
                    </Fade>
                    :
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        {filteredObjectives.data.map((obj, i) => {
                            const referenceValue = parseFloat(obj.value);
                            const value = parseFloat(data[obj.label]);
                            return (
                                <Fade in={true} {...{ timeout: 1000 }} key={i}>
                                    <div className={classes.progressContainer}>
                                        <p style={{ width: '25%', textAlign: 'center', fontWeight: 600 }}>{obj.displayName}</p>
                                        <p style={{ position: 'absolute', zIndex: 1, fontWeight: 600, fontSize: 12 }}>{`${Math.round((value / referenceValue) * 100,)}%`}</p>
                                        <LinearProgress
                                            className={classes.progress}
                                            variant="determinate"
                                            value={(value / referenceValue) * 100}
                                        />
                                        <p style={{ width: '25%', textAlign: 'center', fontWeight: 600 }}>{data[obj.label]} / {obj.value} M€</p>
                                    </div>
                                </Fade>
                            );
                        })}
                    </Grid>
            );
        }
    }
    const renderGroup = (id, idUser) => {
        return (
            <>
                {tableFormationGroups.map((formationGroup, index) => (
                    <TableRow key={index} style={{ backgroundColor: numberRegistrations[id]['users'][idUser].isCommercial ? 'white' : 'rgba(0, 0, 0, 0.3)' }}>
                        <TableCell padding="checkbox" />
                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['01'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['02'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['03'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['04'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['05'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['06'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['07'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['08'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['09'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['10'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['11'][formationGroup['index']]}</TableCell>
                        <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year']['12'][formationGroup['index']]}</TableCell>
                    </TableRow>
                ))}
            </>
        )
    }

    const renderTable = Object.keys(numberRegistrations).map((id, index) => {
        let renderTableRow = Object.keys(numberRegistrations[id]['users']).map((idUser) => {
            let RenderCell = Object.keys(numberRegistrations[id]['users'][idUser]['year']).sort((a, b) => a < b ? -1 : 1).map((idMonth) => {
                return (
                    <TableCell className={classes.td}>{numberRegistrations[id]['users'][idUser]['year'][idMonth]['total']}</TableCell>
                )
            });

            return (
                <>
                    <ExpandableTableRow
                        key={index}
                        expandComponent={renderGroup(id, idUser)}
                        style={{ backgroundColor: numberRegistrations[id]['users'][idUser].isCommercial ? 'white' : 'rgba(0, 0, 0, 0.3)' }}
                    >
                        <TableCell className={classes.thSize}>{numberRegistrations[id]['users'][idUser]['name']}</TableCell>
                        {RenderCell}
                    </ExpandableTableRow>

                </>
            );
        });

        return (
            <>
                <ShadowBoxComponent>
                    <div style={{ display: 'grid', gridTemplateColumns: '80% auto', gap: 14, marginBottom: 20, }}>
                        <div className={classes.titleStyle}>
                            <TitleComponent title={numberRegistrations[id].name + ' Nombre d\'inscriptions par commercial'} />
                        </div>
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell className={classes.thSize}>Commercial</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTableRow}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>
                <br />
            </>
        );
    });

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.boxComponent} style={{ height: 'auto', padding: '30px 0' }}>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            {renderSearch()}
                        </Grid>
                        {(period === 3 && datas.financeType === 'all' && false/* HACK to dont show */) &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>

                                <Grow in={true} {...{ timeout: 1500 }}>
                                    <ShadowBoxComponent className={classes.box}>
                                        <Grid container spacing={2} justifyContent='space-evenly'>
                                            <Grid item xs={11} sm={11} md={11} lg={11}>
                                                <p style={{ fontSize: 20, textAlign: 'center', fontWeight: 700, color: '#A1A2A9' }}>Objectif année {textSearchSlider}</p>
                                            </Grid>
                                            {renderObjectives(info)}
                                        </Grid>
                                    </ShadowBoxComponent>
                                </Grow>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <ShadowBoxComponent className={classes.box}>
                                <Grid container spacing={2} justifyContent='space-evenly' style={{ width: '100%' }}>
                                    <Grid item xs={11} sm={11} md={11} lg={11}>
                                        <p style={{ fontSize: 20, textAlign: 'center', fontWeight: 700, color: '#A1A2A9' }}>Stats Générales</p>
                                    </Grid>
                                    <Grid item xs={11} sm={5} md={5} lg={5} style={{ border: 'dotted 2px #A1A2A9' }}>
                                        <p><b>Nombre d'inscriptions total : </b>{(info && !loadingData) ? info.totalCount : <Loader />}</p>
                                        <p><b>Montant total Brut :</b> {(info && !loadingData) ? info.totalCABrut : <Loader />} €</p>
                                    </Grid>
                                    <Grid item xs={11} sm={5} md={5} lg={5} style={{ border: 'dotted 2px #A1A2A9' }}>
                                        <p><b>Nombre d'inscriptions facturées total :</b> {(info && !loadingData) ? info.totalCountBilled : <Loader />}</p>
                                        <p><b>Montant total Net : </b>{(info && !loadingData) ? info.totalCANet : <Loader />} €</p>
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2} justifyContent='center'>
                                <Grid item xs={12} sm={12} md={6} lg={6} style={{ position: 'relative' }}>
                                    {
                                        (barSearchFormationGroup && !(selectOrganismReducer.organism.value === 5)) &&
                                        <Grow in={true} {...{ timeout: 1500 }}>
                                            <div className={classes.selectFormationGroup}>
                                                <InputLabel id="searchFormationGroup">Filtre professions</InputLabel>
                                                <Select
                                                    id="searchFormationGroup"
                                                    value={barSearchFormationGroup}
                                                    onChange={(event) => setBarSearchFormationGroup(event.target.value)}
                                                    MenuProps={{
                                                        className: classes.menu
                                                    }}
                                                >
                                                    {barFormationGroups.map((barFormationGroup, i) => (
                                                        <MenuItem key={i} value={barFormationGroup.value}>{barFormationGroup.value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Grow>
                                    }
                                    <BarChartComponent
                                        title={`Rapport de vente ${chartTimeText}`}
                                        data={dataByFormationGroup}
                                        group={barSearchFormationGroup}
                                        loading={loadingData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} style={{ position: 'relative' }}>
                                    {(searchFormationGroup && dataRankingSelling && !(selectOrganismReducer.organism.value === 5)) &&
                                        <Grow in={true} {...{ timeout: 1500 }}>
                                            <div className={classes.selectFormationGroup}>
                                                <InputLabel id="searchFormationGroup">Filtre professions</InputLabel>
                                                <Select
                                                    id="searchFormationGroup"
                                                    value={searchFormationGroup}
                                                    onChange={(event) => setSearchFormationGroup(event.target.value)}
                                                    MenuProps={{
                                                        className: classes.menu
                                                    }}
                                                >
                                                    {formationGroups.map((formationGroup, i) => (
                                                        <MenuItem key={i} value={formationGroup.value}>{formationGroup.value}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </Grow>
                                    }

                                    {(searchFormationGroup && averageByFormationGroup && averageByFormationGroup[searchFormationGroup]) &&
                                        <div className={classes.average}>
                                            <p style={{ fontWeight: 600, color: '#A1A2A9' }}>Pour <b>{averageByFormationGroup[searchFormationGroup].count}</b> formation {searchFormationGroup} le panier moyen de vente est de </p>
                                            <p><b>{(averageByFormationGroup[searchFormationGroup].price / averageByFormationGroup[searchFormationGroup].count).toFixed(2)} €</b></p>
                                        </div>}

                                    <DoughnutChartComponent
                                        title={'Top 5 des ventes'}
                                        label={'Classement'}
                                        data={(dataRankingSelling && dataRankingSelling[searchFormationGroup]) ? dataRankingSelling[searchFormationGroup] : [{ label: 'Aucune Donnée', value: 100 }]}
                                        loading={loadingData}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {(numberRegistrations) &&
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {renderTable}
                            </Grid>
                        }
                    </Grid>
                </Box>
            </ContentViewComponent>
        </Box>
    )
}

const useStyles = makeStyles({
    boxComponent: {
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    td: { width: '8%', fontSize: 12, padding: 12 },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    thSize: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    up: { fontSize: 16, verticalAlign: 'bottom', color: '#008000' },
    upText: { fontSize: 11, verticalAlign: 'bottom', color: '#008000' },
    down: { fontSize: 16, verticalAlign: 'bottom', color: '#dc3545' },
    downText: { fontSize: 11, verticalAlign: 'bottom', color: '#dc3545' },
    equalText: { fontSize: 9, verticalAlign: 'middle', color: '#000' },
    select: {
        '& select': {
            padding: 10
        }
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    },
    titleStyle: {
        '& hr': {
            margin: '0 !important',
        }
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    datepicker: {
        margin: 0,
        width: '100%',
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    textSearchSlider: {
        fontSize: 16,
        textAlign: 'center',
        height: 40,
        lineHeight: '40px',
        textTransform: 'capitalize'
    },
    box: {
        '& *': { margin: 0 }
    },
    loader: {
        display: 'inline-block',
        fontSize: '16px',
    },
    dot: {
        opacity: 0,
        animation: '$fade-in-out 1s infinite',
    },
    dot1: {
        animationDelay: '0.2s',
    },
    dot2: {
        animationDelay: '0.4s',
    },
    '@keyframes fade-in-out': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    selectFormationGroup: {
        position: 'absolute',
        zIndex: 1,
        top: 20,
        left: 20,
        '& .MuiFormLabel-root': {
            fontSize: 13,
        },
        '& .MuiInputBase-root': {
            fontSize: 13,
        }
    },
    menu: {
        '& li': {
            fontSize: 13,
            padding: '4px 10px',
            lineHeight: '16px',
            minHeight: '16px'
        }
    },
    average: {
        position: 'absolute',
        top: '15%',
        right: 40,
        fontSize: 12,
        boxShadow: 'inset 0px 0px 3px 0px #A1A2A9',
        textAlign: 'center',
        width: 150,
        padding: 10,
        '& > p': {
            margin: 0,
            padding: 2
        }
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        margin: 5,
        position: 'relative'
    },
    progress: {
        width: '50%',
        padding: 7,
        margin: '7px 0',
        borderRadius: 10,
        backgroundColor: '#e0e0e0',
        '& .MuiLinearProgress-bar': {
            backgroundColor: '#f6af95',
        }
    }
})