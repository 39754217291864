import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { Box, makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Api from "../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../../Config/Route";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../../Component/TabsComponent";
import SelectComponent from "../../../../Component/SelectComponent";
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import VirtualClassroomUser from "./VirtualClassroomUser";
import VirtualClassroomSession from "./VirtualClassroomSession";
import { hasRight } from "../../../../Tool/UserTool";


function VirtualClassroom(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    React.useEffect(() => {
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')) {
            setIsSuperAdmin(true);
        }
    }, []);


    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const [form, setForm] = React.useState({
        period: {
            name: 'period',
            label: 'Crénaux',
            textHelper: 'Choisissez les crénaux de cette classe.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['array', 'required'] }
        },
        former: {
            name: 'former',
            label: 'Formateur',
            textHelper: 'Choisissez un formateur',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Indiquez la durée de cette classe virtuelle',
            type: 'integer',
            options: { validation: ['required'], }
        },
        meetingId: {
            name: 'meetingId',
            label: 'ID de la réunion ZOOM',
            textHelper: "Saisissez l'ID de la réunion ZOOM",
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        meetingAt: {
            name: 'meetingAt',
            label: 'Date de la réunion ZOOM',
            textHelper: "Saisissez la date de la réunion ZOOM",
            type: 'date',
            defaultValue: '',
            options: { validation: ['date', 'required'] }
        },
        password: {
            name: 'password',
            label: 'Mot de passe de la réunion ZOOM',
            textHelper: "Saisissez le mot de passe de la réunion ZOOM",
            type: 'string',
            defaultValue: '',
            options: { validation: ['required'], }
        },
        meetingReplay: {
            name: 'meetingReplay',
            label: 'Lien de rediffusion de la réunion ZOOM',
            textHelper: "Saisissez le lien de rediffusion de la réunion ZOOM",
            type: 'string',
            defaultValue: '',
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    const [optionFormer, setOptionFormer] = React.useState([]);
    const [optionAddFormer, setOptionAddFormer] = React.useState([]);
    const [optionPeriod, setOptionPeriod] = React.useState([]);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'institution_health_content_manager_virtual_classroom_edit',
                    data: {organism: selectOrganismReducer.organism.value, ...handler.getData()},
                    params: { id: props.match.params.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Classe virtuelle modifiée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'institution_health_content_manager_virtual_classroom_delete',
            params: { id: props.match.params.id }
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('institution_health_content_manager_virtual_classroom_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Classe virtuelle supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    // ALL
    React.useEffect(handler.start, []);

    React.useEffect(() => {
        setReadyContent(false);

        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Classe virtuelle',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_content_manager_virtual_classroom_list'), label: 'Liste des classes virtuelles' }
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'institution_health_content_manager_virtual_classroom_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data[0];
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_content_manager_virtual_classroom_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        }
    }, [props.match.params.id]);

    React.useEffect(() => {
        Api.get({
            route: 'select_formers'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    let dataAdd = [...response.data];
                    setOptionFormer(data);
                    setOptionAddFormer(dataAdd);
                }
            });

        Api.get({
            route: 'select_virtual_classroom_period'
        },
            (response) => {
                if (response && response.data) {
                    let data = [...response.data];
                    setOptionPeriod(data);
                }
            });
        setReadyContent(true);
    }, []);


    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    <Grid container spacing={2}>
                        <Fade in={true} {...{ timeout: 750 }}>
                            <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ShadowBoxComponent style={{ minHeight: '100%' }} className={classes.shadowBox}>
                                    <TitleComponent title={isEdition ? <>Formulaire d'édition de la classe virtuelle</> : <>Formulaire de création d'une classe virtuelle</>} />

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={12} lg={4} xl={4}>
                                            <MultipleSelectComponent disabled={!isSuperAdmin} name={'period'} handler={handler} options={optionPeriod} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <SelectComponent disabled={!isSuperAdmin} name={'former'} handler={handler} options={optionAddFormer} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <TextFieldComponent disabled={!isSuperAdmin} name={'duration'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <TextFieldComponent disabled={!isSuperAdmin} name={'meetingId'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <TextFieldComponent disabled={!isSuperAdmin} name={'password'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <TextFieldComponent disabled={!isSuperAdmin} name={'meetingReplay'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3} lg={3}>
                                            <DatePickerComponent disabled={!isSuperAdmin} name={'meetingAt'} handler={handler} />
                                        </Grid>
                                    </Grid>

                                    <div className={classes.button}>
                                        <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={!isSuperAdmin} />
                                    </div>

                                    {isEdition && <div className={classes.button}>
                                        <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={!isSuperAdmin} />
                                    </div>}
                                </ShadowBoxComponent>
                            </Grid>
                        </Fade>
                    </Grid>
                    <br />
                    {
                        isEdition &&
                        <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                            <div>
                                <TabsComponent
                                    tabDefault={'users'}
                                    tabs={[
                                        { id: 'users', label: 'Participants' },
                                        { id: 'sessions', label: 'Sessions formation' },
                                    ]}
                                >
                                    <div key={'users'}>
                                        <VirtualClassroomUser {...props} />
                                    </div>
                                    <div key={'sessions'}>
                                        <VirtualClassroomSession {...props} />
                                    </div>

                                </TabsComponent>
                            </div>
                        </Slide>
                    }

                    <br />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const useStyles = makeStyles({
    TableComponent: {
        padding: 20,
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        height: '100%',
    },
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default VirtualClassroom;
