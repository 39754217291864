import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {makeStyles} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import ChapterCourse from "./ChapterCourse";
import FormationChapter from "./FormationChapter";

function Chapter(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la chapitre.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'content_manager_chapter_edit',
                    data: handler.getData(),
                    params: {id: props.match.params.id}
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Chapitre modifiée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_chapter_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('content_manager_chapter', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Chapitre enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'content_manager_chapter_delete',
            params: {id: props.match.params.id}
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('content_manager_chapter_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Chapitre supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setReadyContent(false);

        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Chapitres',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    {path: getRoutePathname('content_manager_chapter_list'), label: 'Liste des chapitres'}
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                    route: 'content_manager_chapter_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('content_manager_chapter_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else {
            setIsEdition(false);
            setReadyContent(true);
            setBreadcrumbs({
                title: 'Ajouter une chapitre',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    {path: getRoutePathname('content_manager_chapter_list'), label: 'Liste des chapitres'}
                ]
            });
        }
    }, [props.match.params.id]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Fade in={true} {...{timeout: 750}}>
                    <Grid style={{minHeight: '100%'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShadowBoxComponent style={{minHeight: '100%'}} className={classes.shadowBox}>
                            <TitleComponent title={isEdition ? <>Formulaire d'édition de la chapitre</> : <>Formulaire de création d'une chapitre</>}/>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent name={'name'} handler={handler}/>
                                </Grid>
                            </Grid>

                            <div className={classes.button}>
                                <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={loading}/>
                            </div>

                            {isEdition && <div className={classes.button}>
                                <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={loading}/>
                            </div>}
                        </ShadowBoxComponent>
                    </Grid>
                </Fade>
            </Grid>
            <br/>

            {
                isEdition &&
                <Slide direction={'up'} in={true} {...{timeout: 500}}>
                    <div>
                        <TabsComponent
                            tabDefault={'formations'}
                            tabs={[
                                {id: 'formations', label: 'Formations'},
                                {id: 'courses', label: 'Cours'},
                            ]}
                        >
                            <div key={'formations'}>
                                <FormationChapter {...props}/>
                            </div>
                            <div key={'courses'}>
                                <ChapterCourse {...props}/>
                            </div>
                        </TabsComponent>
                    </div>
                </Slide>
            }
            <br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default Chapter;
