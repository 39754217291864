import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid, IconButton, Tooltip } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TableComponent from '../../../Component/TableComponent';
import {Delete, Edit} from '@material-ui/icons';
import { TableReload } from '../../../Action/TableAction';
import {dispatch} from "../../../App";
import SweetAlert from "sweetalert-react";
import moment from "moment/moment";
import SelectComponent from "../../../Component/SelectComponent";

const EquipmentModelList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [isEdition, setIsEdition] = React.useState(false);
    const [isPc, setIsPc] = React.useState(false);
    const [hasStorage, setHasStorage] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [modelId, setModelId] = React.useState(null);
    const [optionEquipmentType, setOptionEquipmentType] = React.useState([]);
    const [optionEquipmentBrands, setOptionEquipmentBrands] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "brand",
            label: "Marque",
            options: { filter: true, sort: true }
        },
        {
            name: "type",
            label: "Type",
            options: { filter: true, sort: true }
        },
        {
            name: "price",
            label: "Prix",
            options: { filter: true, sort: true }
        },
        {
            name: "equipmentCount",
            label: "Nombre d'équipement",
            options: { filter: true, sort: true }
        },
        {
            name: "cpu",
            label: "Processeur",
            options: { filter: true, sort: true }
        },
        {
            name: "gpu",
            label: "Carte Graphique",
            options: { filter: true, sort: true }
        },
        {
            name: "ram",
            label: "RAM",
            options: { filter: true, sort: true }
        },
        {
            name: "storage",
            label: "Stockage",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Saisissez le type de l\'équipement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        brand: {
            name: 'brand',
            label: 'Brand',
            textHelper: 'Saisissez la marque.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        cpu: {
            name: 'cpu',
            label: 'Processeur',
            textHelper: 'Saisissez la référence du processeur.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        gpu: {
            name: 'gpu',
            label: 'Carte graphique',
            textHelper: 'Saisissez la référence de la carte graphique.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ram: {
            name: 'ram',
            label: 'RAM',
            textHelper: 'Saisissez la mémoire de la RAM.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        storage: {
            name: 'storage',
            label: 'Stockage',
            textHelper: 'Saisissez l\'espace de stockage (en GB).',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        price: {
            name: 'price',
            label: 'Prix',
            textHelper: 'Saisissez le prix de l\'équipement.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
    });

    React.useEffect(() => {
        Api.get({
                route: 'select_equipment_types'
            },
            (response) => {
                if (response && response.data) {
                    setOptionEquipmentType(response.data);
                }
            });

        Api.get({
                route: 'select_equipment_brands'
            },
            (response) => {
                if (response && response.data) {
                    setOptionEquipmentBrands(response.data);
                }
            });
    }, []);

    const handler = formHandlerInit(form, setForm);

    const save = (row) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'it_infrastructure_equipment_model_edit',
                    data: handler.getData(),
                    params: { id: row.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(TableReload('it_infrastructure_equipment_model_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Marque modifié.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'it_infrastructure_equipment_model_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        dispatch(TableReload('it_infrastructure_equipment_model_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Marque enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { setIsEdition(true); setRow(row); setDialog(true) }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setModelId(row.id);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des modèles d\'équipements',
            context: 'Parc informatique',
            description: ''
        });

        setReadyContent(true);

        if (row.id) {
            if ([1,2].includes(row.typeId)) {
                setIsPc(true)
            } else if ([9,10].includes(row.typeId)){
                setIsPc(false)
                setHasStorage(true)
            } else {
                setHasStorage(false)
                setIsPc(false)
            }
            handler.setDataApi(row);
        }
    }, [row]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'it_infrastructure_equipment_model_list'}
                title={'Liste des marques d\'équipements'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un modèle d\'équipement',
                    onClick: () => { setDialog(true); setIsEdition(false); }
                }}
                promiseServerData={(resolve, options) => {
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                    };
                    Api.get({
                            route: 'it_infrastructure_equipment_model_list',
                            data: filters,
                        },
                        (response) => {
                            if (response.status === 200) {
                                let data = response.data.data;
                                let count = response.data.count;
                                for (let index in data) {
                                    Object.keys(data[index]).forEach((key) => {
                                        if (!data[index][key]) {
                                            data[index][key] = '-';
                                        }
                                    });
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data, count);
                            } else resolve([]);
                        });
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => { setIsEdition(false); setDialog(false); handler.reset() }}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{isEdition ? 'Editer un modèle' : 'Ajouter un modèle'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SelectComponent
                                name={'type'}
                                handler={handler}
                                options={optionEquipmentType}
                                onChange={(val)=>{
                                    const value = parseInt(val)
                                    if ([1,2].includes(value)) {
                                        setIsPc(true)
                                    } else if ([9,10].includes(value)){
                                        setHasStorage(true)
                                    } else {
                                        setHasStorage(false)
                                        setIsPc(false)
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <SelectComponent name={'brand'} handler={handler} options={optionEquipmentBrands} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <TextFieldComponent name={'price'} handler={handler} />
                        </Grid>
                        {
                            (isPc) &&
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'cpu'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'ram'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                    <TextFieldComponent name={'gpu'} handler={handler} />
                                </Grid>
                            </>
                        }
                        {
                            (isPc || hasStorage) &&
                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                <TextFieldComponent name={'storage'} handler={handler} />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setIsEdition(false); setDialog(false) }} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(row)} disabled={loading} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ce modèle ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                            route: 'it_infrastructure_equipment_model_delete',
                            params: { id: modelId }
                        },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Modèle supprimé.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('it_infrastructure_equipment_model_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>

    );
};
const useStyles = makeStyles({});

export default EquipmentModelList;


