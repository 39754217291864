import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';

export default function FormationVirtualClassroom(props = {}) {

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "formerName",
            label: "Formateur",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAt",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAt",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "meetingAt",
            label: "Date classe virtuelle",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "registrations",
            label: "Participants",
            options: {filter: false, sort: false}
        }
    ];

    return (
        <TableComponent
            noCache={true}
            id={'content_manager_formation_virtual_classroom_list'}
            title={'Classes virtuelles lié à la formation : '}
            columns={columns}
            promiseData={(resolve) => {
                if (props.match.params.id) {
                    Api.get({
                        route: 'content_manager_formation_virtual_classroom_list',
                        params: { formation: props.match.params.id }
                    }, (response) => {
                        if (response.data) {
                            let data = response.data;
                            for (let index in data) {
                                data[index].sessionStartAt = moment(data[index].sessionStartAt).format('L');
                                data[index].sessionEndAt = moment(data[index].sessionEndAt).format('L');
                                data[index].meetingAt = moment(data[index].meetingAt).format('L');
                            }
                            resolve(data);
                        } else {
                            resolve([]);
                        }
                    })
                } else {
                    resolve([]);
                }
            }}
        />
    )
}
