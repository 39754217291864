import React from 'react';
import Api from "../../../Api";
import {useDispatch, useSelector} from "react-redux";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";
import {cacheTableServer} from "../../../Cache";
import SelectComponent from "../../../Component/SelectComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';

function Assign(props = {
    filter : {
    },
    onClose: () => {
    }
}) {
    const dispatch = useDispatch();

    const [optionCommercial, setOptionCommercial] = React.useState([]);
    const [optionLimit, setOptionLimit] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [showInfo, setShowInfo] = React.useState(false);
    const [info, setInfo] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);

    const [form, setForm] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez le commercial.',
            type: 'integer',
            options: {validation: ['required']}
        },
        limit: {
            name: 'limit',
            label: 'Limite',
            textHelper: 'Nombre de prospect/client à assigner (Max:10000)',
            type: 'integer',
            defaultValue: 0,
            options: {validation: ['required']}
        },
        export: {
            name: 'export',
            label: 'Exporter un fichier excel',
            textHelper: 'Exporter un fichier excel',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }

    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'crm_commercial_list'
            },
            (response) => {
                if (response && response.data) {
                    setOptionCommercial([{value: 'none', label: 'Aucun'}, ...response.data]);
                    setLoading(false);
                }
            });
    }, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();
            let filter = props.filter.getData();
            if (data.export) {
                Api.postDownload({
                        route: 'crm_prospect_assign', filename: 'assign_contact.xls', data: {
                            text: filter.text ? filter.text : '',
                            commercial: (filter.commercial === 'all' || !filter.commercial) ? '' : filter.commercial,
                            state: (filter.state === 'all' || !filter.state) ? '' : filter.state,
                            clientFilter: (filter.clientFilter === 'all') ? '' : filter.clientFilter,
                            pole: (filter.pole === 'all') ? '' : filter.pole,
                            organism: (filter.organism === 'all') ? '' : filter.organism,
                            formationGroup: (filter.formationGroup === 'all') ? '' : filter.formationGroup,
                            registrationAt: filter.registrationAt ? filter.registrationAt : '',
                            financeTypes: filter.financeTypes ? filter.financeTypes : '',
                            hasPhone: filter.hasPhone ? filter.hasPhone : '',
                            hasFormationGroup: filter.hasFormationGroup ? filter.hasFormationGroup : '',
                            commercialAssign : (data.commercial === 'none' || !data.commercial) ? '' : data.commercial,
                            limitAssign : data.limit,
                            downloadAssign: data.export
                        }
                    },
                    (response) => {
                        handler.setFormLoading(false)
                        setLoading(false);
                        if (response.status === 200) {
                            props.onClose()
                            dispatch(TableReload('crm_prospect_list'))
                        } else {
                            console.log(response.error);
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
            }
            else {
                Api.post({
                    route: 'crm_prospect_assign',
                    data: {
                        text: filter.text ? filter.text : '',
                        commercial: (filter.commercial === 'all' || !filter.commercial) ? '' : filter.commercial,
                        state: (filter.state === 'all' || !filter.state) ? '' : filter.state,
                        clientFilter: (filter.clientFilter === 'all') ? '' : filter.clientFilter,
                        pole: (filter.pole === 'all') ? '' : filter.pole,
                        organism: (filter.organism === 'all') ? '' : filter.organism,
                        formationGroup: (filter.formationGroup === 'all') ? '' : filter.formationGroup,
                        registrationAt: filter.registrationAt ? filter.registrationAt : '',
                        hasPhone: filter.hasPhone ? filter.hasPhone : '',
                        hasFormationGroup: filter.hasFormationGroup ? filter.hasFormationGroup : '',
                        commercialAssign : (data.commercial === 'none' || !data.commercial) ? '' : data.commercial,
                        limitAssign : data.limit,
                        downloadAssign: data.export
                    }
                }, (response) => {
                    handler.setFormLoading(false)
                    setLoading(false);

                    if (response.status === 200) {
                        setInfo(response.data);
                        setShowInfo(true);
                        dispatch(TableReload('crm_prospect_list'))
                    } else {
                        console.log(response.error);
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }


        }
    };

    return (
        <>
            {
                showInfo ? <>
                        <DialogTitle style={{fontSize: 15}}>Information sur l'importation des contacts</DialogTitle>
                        <DialogContent style={{boxSizing: 'border-box'}}>
                            <p>
                                <span style={{fontWeight: 900}}>{info.count}</span> contacts ont été assignés.
                            </p>
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Ok'} onClick={props.onClose}/>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogTitle style={{fontSize: 15}}>Assignation des contacts</DialogTitle>
                        <DialogContent style={{boxSizing: 'border-box'}}>
                            <SelectComponent name={'commercial'} handler={handler} options={optionCommercial}/>
                            <br/>
                            <TextFieldComponent name={'limit'} handler={handler} options={optionLimit}/>
                            <br/>
                            <CheckboxComponent name={'export'} handler={handler}/>
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={props.onClose} disabled={loading}/>
                            <ButtonComponent label={'Enregistrer'} onClick={() => setShowConfirm(true)} disabled={loading}/>
                        </DialogActions>
                    </>
            }
            <SweetAlert
                show={showConfirm}
                title={'Confirmation'}
                text={'\nÊtes-vous sur de vouloir assigner ce commercial à '+ handler.form.limit.value +' contacts ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    save();
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    );
}

export default Assign;
