import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import Api from "../../../../Api";
import moment from "moment";
import { Edit, Delete, SupervisorAccount } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { TableReload } from "../../../../Action/TableAction";
import { getRoutePathname } from "../../../../Config/Route";
import { AuthenticationLogin } from "../../../../Action/AuthenticationAction";
import { SelectOrganism } from '../../../../Action/SelectOrganismAction';

function UserList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Se connecter en tant que...'} placement="left">
                    <IconButton onClick={() => {
                        Api.post({
                            route: 'authentication_login',
                            data: {
                                email: row.email,
                                password: 'admin',
                                isAdmin: true
                            }
                        }, (response) => {
                            dispatch(AuthenticationLogin(response.data));
                            dispatch(SelectOrganism({ organism: {} }));
                        });
                    }}>
                        <SupervisorAccount />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('institution_health_admin_user', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Utilisateurs',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des utilisateurs.',
            links: []
        });
        setReadyContent(true);
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    <TableComponent
                        id={'institution_health_administrator_user_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}>Utilisateurs</h1>}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un utilisateur',
                            onClick: () => props.history.push(getRoutePathname('institution_health_admin_user', { id: null }))
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'administrator_user_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].createdAt = moment(data[index].createdAt).format('lll');
                                        data[index].action = getAction(data[index]);
                                    }

                                    resolve(data);
                                });
                        }}
                    />

                </Box>

                <SweetAlert
                    show={showConfirm}
                    title={'Supprimer'}
                    text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet utilisateur ?'}
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        setShowConfirm(false);
                        Api.delete({
                            route: 'administrator_user_delete',
                            params: { id: actionRow.id }
                        },
                            () => {
                                dispatch(TableReload('institution_health_admin_user_list'))
                            });
                    }}
                    onCancel={() => setShowConfirm(false)}
                />

            </ContentViewComponent>
        </Box>
    );
}

const useStyles = makeStyles({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
})

export default UserList;
