import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import SweetAlert from 'sweetalert-react';
import { TableReload } from "../../../../Action/TableAction";
import { getRoutePathname } from "../../../../Config/Route";
import { Box, makeStyles } from '@material-ui/core';

function ThematicList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Libellé",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('institution_health_marketing_manager_thematic', { id: row.id }))}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Liste des thématiques',
            path: getRoutePathname('institution_health_home'),
            context: 'Portail',
            links: [
                { label: 'Gestion marketing' },
            ]
        });

        setReadyContent(true);
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_marketing_manager_thematic_list'}
                        title={'Thématiques'}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajout de thématique',
                            onClick: () => props.history.push(getRoutePathname('institution_health_marketing_manager_thematic', { id: null }))
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_marketing_manager_thematic_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].action = getAction(data[index]);
                                    }
                                    resolve(data);
                                });
                        }}
                    />

                    <br />

                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={'\nÊtes-vous sur de vouloir supprimer la thématique ' + actionRow.label + ' ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_marketing_manager_thematic_delete',
                                params: { id: actionRow.id }
                            },
                                () => {
                                    dispatch(TableReload('institution_health_marketing_manager_thematic_list'))
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />

                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const useStyles = makeStyles({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
});

export default ThematicList;
