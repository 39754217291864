import { Box, Grid, Slide, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TabsComponent from '../../../../Component/TabsComponent';
import { getRoutePathname } from '../../../../Config/Route';

export default function BillingList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Facturation',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des sessions formation.',
            links: []
        });

        setReadyContent(true);
    }, []);
    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                
            </ContentViewComponent>
        </Box>
    )
}

const useStyles = makeStyles({
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
})