import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box, makeStyles } from "@material-ui/core";
import ApartmentIcon from '@material-ui/icons/Apartment';
import ButtonComponent from "../../../../Component/ButtonComponent";
import CountUp from "react-countup";


const CardInstitutionComponent = (props) => {
    const classes = useStyles();
    return (
        <ShadowBoxComponent className={classes.BoxIstitution}>
            <Box style={{ height: '100%', textAlign: 'center', display: 'grid', }}>
                <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, flexDirection: 'column', }}><ApartmentIcon style={{ width: 30, height: 30 }} />Établissement</h1>
                <p style={{ margin: '10px 0', fontSize: 15 }}>Découvrez la liste de vos<br />établissements à gérer ici.</p>
                <hr style={{ margin: '10px auto', color: '#FFF', width: '50%', height: 0, borderRadius: 100, }} />
                <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>Totale d’établissemnt</p>
                <p style={{ margin: '10px 0', fontSize: 45 }}>{props.data.institutionCount > 9999 ? '+9 999' : <CountUp separator=" " end={props.data.institutionCount ? props.data.institutionCount : 0} />}</p>
            </Box>
            <Box style={{ height: '100%', display: 'flex', }}>
                {props.button.isAccess &&
                    <ButtonComponent
                        style={props.button.style}
                        color={props.button.background} label={props.button.title}
                        onClick={() => { props.button.onClick() }}
                    />
                }
            </Box>
        </ShadowBoxComponent>
    )
}

const useStyles = makeStyles({
    BoxIstitution: {
        height: '100%',
        background: 'linear-gradient(rgba(94, 110, 130, .9), rgba(94, 110, 130, .8)), url(/asset/images/wallpaper_institution.webp)',
        display: 'grid',
        padding: 20,
        color: '#FFFFFF',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        gridTemplateRows: 'auto max-content',
    }
})

export default CardInstitutionComponent;