import React from 'react'
import Api from '../../../Api';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TemplatesComponent from '../../../Component/DraggableListComponent/TemplatesComponent';
import DraggableListComponent from '../../../Component/DraggableListComponent/DraggableListComponent';

const HandlerDraggableList = () => {
    const [formTemplate1, setFormTemplate1] = React.useState({
        comment: {
            name: 'comment',
            title: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            typeInput: 'inputText',
            options: { validation: ['required'] },
            defaultValue: ''
        },
    });
    const handlerTemplate1 = formHandlerInit(formTemplate1, setFormTemplate1);
    React.useEffect(handlerTemplate1.start, []);
    return { handlerTemplate1 }
}

const Templates = ({ item, dragHandleProps }) => {
    const draggableListHandlers = HandlerDraggableList();
    return (
        <TemplatesComponent
            item={item}
            dragHandleProps={dragHandleProps}
            handlerTemplate={{
                template1: {
                    handler: draggableListHandlers.handlerTemplate1,
                },
            }}
            alertMessage={<>Êtes-vous sur de vouloir <br /> supprimer ce commentaire ?</>}
        />
    )
}

function QuestionComment(props) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const data = props.data;
    const propsData = props.props;
    const handler = props.handler;
    const draggableListHandlers = HandlerDraggableList();
    const [listCommentDeleted, setListCommentDeleted] = React.useState([]);
    const [listComment, setListComment] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [id, setId] = React.useState(null)

    const save = (error) => {
        const listDeleted = listCommentDeleted.filter((value) => !listComment.some((item) => item.id === value.id));
        if (error || handler.checkError()) {
            console.log(error)
        } else {
            setLoading(true)
            props.setAction.setLoading(true);
            if (id) {
                Api.post({
                    route: 'content_manager_formation_formation_question_comment_action',
                    data: {
                        list: listComment,
                        listDeleted: listDeleted,
                        questionText: handler.getData().question,
                    },
                    params: { formation: propsData.match.params.id, question: data.id }
                }, (response) => {
                    if (response.status === 200) {
                        getComment(id)
                        props.setAction.setLoading(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Commentaires enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'content_manager_formation_formation_question_add',
                    data: {
                        question: handler.getData().question,
                        order: props.questionOrder,
                        list: props.questionList,
                        listDeleted: props.deletedQuestionList,
                    },
                    params: { formation: propsData.match.params.id }
                }, (response) => {
                    if (response.status === 200) {
                        if (response.data && response.data.id) {
                            data.id = response.data.id;
                            data.add = false;
                            setId(response.data.id)
                            props.loadingQuestion()
                            saveComment(response.data, listDeleted)
                        }
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const saveComment = (data, listDeleted) => {
        Api.post({
            route: 'content_manager_formation_formation_question_comment_action',
            data: {
                list: listComment,
                listDeleted: listDeleted,
            },
            params: { formation: propsData.match.params.id, question: data.id }
        }, (response) => {
            if (response.status === 200) {
                getComment(data.id)
                props.setAction.setLoading(false);
                dispatch(
                    SnackbarOpen({
                        text: 'Commentaires enregistrée.',
                        variant: 'success',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const getComment = () => {
        if (data.id) {
            setLoading(true);
            Api.get({
                route: 'content_manager_formation_formation_question_comment_list',
                params: { formation: propsData.match.params.id, question: data.id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    let dataComment = response.data;
                    setListComment(dataComment);
                    setListCommentDeleted(dataComment);
                } else {
                    setListComment([]);
                    setListCommentDeleted([]);
                }
            });

        } else {
            setLoading(true);
            props.setAction.setLoading(true)
            setTimeout(() => { setLoading(false); props.setAction.setLoading(false); setListComment([]); setListCommentDeleted([]); }, 1000);
        }
    }

    React.useEffect(() => { props.setCheckedList(listComment) }, [listComment])
    React.useEffect(() => { if (props.reload) getComment(data.id) }, [props.reload])
    React.useEffect(() => { setId(data.id); getComment(data.id) }, [data.id])
    return (
        <DraggableListComponent
            list={listComment}
            templates={Templates}
            loading={loading}
            onListChange={(newList) => { setListComment(newList) }}
            setAction={{
                setList: (newList) => { setListComment(newList) },
                setButton: [
                    {
                        direction: 'top',
                        textAction: 'Voir les réponses',
                        disabled: loading,
                        isAction: (newList) => { props.setAction.setIsListComment(!newList) },
                    }
                ]
            }}
            handlerTemplates={{
                template1: draggableListHandlers.handlerTemplate1,
            }}
            titleButtonAdd={'Ajouter un commentaire'}
            errorListMessage={'Aucune commentaire a été ajouter'}
            buttonAllDelete={{
                title: 'supprimer les commentaires',
                alertMessage: 'Êtes-vous sur de vouloir supprimer toute les commentaires ?',
            }}
            saveAction={save}
            buttonStyleOff
            className={classes.dragStyle}
        />
    )
}

const useStyles = (screenReducer) => makeStyles({
    dragStyle: {
        boxShadow: '0px 0px 11px #00000082', borderRadius: 8,
        background: '#FFF', width: '100%', height: '100%', top: 0, position: 'absolute',
    },
});

export default QuestionComment;