import React from 'react';
import { Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from "./Component/AppBar";
import { useHistory } from 'react-router-dom';
import Api from "../../Api";
import { useSelector } from 'react-redux';
import { getRoutePathname } from '../../Config/Route';
import SelectOrganisms from './Component/SelectOrganisms';
import { SelectOrganism } from '../../Action/SelectOrganismAction';
import { dispatch } from '../../App';
import { getMenu } from '../../Config/Menu';

export default function MainLayoutInstitution(props) {
    const classes = useStyles();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const history = useHistory();
    const [loading, setLoading] = React.useState(true);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);

    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            Api.cancelAll();
        });

        return () => {
            unlisten();
        };
    }, []);

    React.useEffect(() => {
        if (authenticationReducer.roles && !authenticationReducer.roles.includes('ROLE_LEARNER')) {
            setLoading(true);
            const routeRole = getMenu()[0];
            let path = '/';
            if (routeRole.path) {
                path = routeRole.id;
            } else if (routeRole.children && routeRole.children.length > 0) {
                path = routeRole.children[0].id;
            }
            let roleFound = false;
            for (let index of authenticationReducer.roles) {
                if(index.startsWith("ROLE_INSTITUTION")){
                    roleFound = true;
                    break;
                }
            }
    
            if(!roleFound && !authenticationReducer.roles.includes("ROLE_SUPER_ADMIN")) props.history.push(getRoutePathname(path));

            Api.get({
                route: 'select_organisms'
            },
                (response) => {
                    if (response && response.data) {
                        let data = [];
                        for (let index in response.data) {
                            if (response.data[index].isInstitution) {
                                data.push(response.data[index]);
                            }
                        }
                        const newData = (authenticationReducer.roles && authenticationReducer.roles.includes("ROLE_SUPER_ADMIN"))
                            ? data : data.filter(element => authenticationReducer.organisms && authenticationReducer.organisms.includes(element.value));

                        if (newData.length === 1) {
                            dispatch(SelectOrganism({ organism: newData[0] }));
                            setOptionOrganisms([])
                        } else if (newData.length > 1) {
                            setLoading(false);
                            setOptionOrganisms(newData)
                        } else {
                            setLoading(false);
                            setOptionOrganisms([])
                        }
                    }
                });
        }
    }, [authenticationReducer]);

    return (
        <div className={classes.mainLayout}>
            {/* <== AppBar */}
            <AppBar organisms={optionOrganisms} {...props} />
            {/* AppBar ==> */}

            {/* <== View */}
            {(selectOrganismReducer.organism && Object.keys(selectOrganismReducer.organism).length > 0) ?
                <div className={classes.view}>
                    <Switch>
                        {
                            props.routes.map((route) => (
                                <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes} />} />
                            ))
                        }
                    </Switch>
                </div> : <SelectOrganisms loading={loading} data={optionOrganisms} {...props} />
            }
            {/* View ==> */}
        </div>
    );
}

const useStyles = makeStyles({
    mainLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex'
    },
    view: {
        flexGrow: 1,
        overflowX: 'scroll',
        paddingTop: 58,
        boxSizing: 'border-box',
        background: '#EFF2F4',
        '& .MuiOutlinedInput-root': {
            //borderRadius: 20
        },
        '& .MuiFormControl-root': {
            '& .MuiFormLabel-root': {
                fontFamily: 'Trebuchet MS',
                //color: '#516375 !important',
            },
            '& legend': {
                fontFamily: 'Trebuchet MS',
                //color: '#516375 !important',
            },
        },
        '& .Mui-disabled': {
            //color: '#51637585 !important',
        },
        '& .MuiCheckbox-root': {
            //color: '#516375',
            padding: '5px 0px'
        },
        '& .MuiButton-containedPrimary': {
            //backgroundColor: '#516375'
        },
        '& .MuiButton-root': {
            //borderRadius: 9,
            boxShadow: 'none',
        }
    }
});
