import React from 'react';
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { theme } from "../../../App";
import TitleComponent from '../../../Component/TitleComponent';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Api from '../../../Api';

export default function ConnectedLearnerCount() {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loadingInfos, setLoadingInfos] = React.useState(true);
    const [textPeriod, setTextPeriod] = React.useState('Journalier');
    const [currentPeriodIndex, setCurrentPeriodIndex] = React.useState(0);
    const [startAt, setStartAt] = React.useState(moment());
    const [endAt, setEndAt] = React.useState(moment());
    const [textSearchSlider, setTextSearchSlider] = React.useState('');
    const [infos, setInfos] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);

    const periods = [
        { id: 1, text: 'Journalière' },
        { id: 2, text: 'Mensuelle' },
        { id: 3, text: 'Annuelle' },
        { id: 4, text: 'Personnalisée par plage' },
    ];

    const changePeriod = (direction) => {
        let newIndex;
        if (direction === 'left') {
            newIndex = (currentPeriodIndex - 1 + periods.length) % periods.length;
        } else {
            newIndex = (currentPeriodIndex + 1) % periods.length;
        }
        setCurrentPeriodIndex(newIndex);
        setTextPeriod(periods[newIndex].text);
    };

    const changeSearchSlider = (direction = null) => {
        let newStartAt;
        let newEndAt;

        switch (currentPeriodIndex) {
            case 0: { // Journalière
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'days');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'days');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('day').format());
                newStartAt = moment(newStartAt.startOf('day').format());

                setTextSearchSlider(newStartAt.format('dddd LL'));
                break;
            }
            case 1: { // Mensuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'month');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'month');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('month').format());
                newStartAt = moment(newStartAt.startOf('month').format());

                setTextSearchSlider(newStartAt.format('MMMM YYYY'));
                break;
            }
            case 2: { // Annuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('year').format());
                newStartAt = moment(newStartAt.startOf('year').format());

                setTextSearchSlider(newStartAt.format('YYYY'));
                break;
            }
            case 3: { // Personnalisée par plage
                newEndAt = endAt;
                newStartAt = startAt;
                break;
            }
            default: {
                newStartAt = null;
                newEndAt = null;
                break;
            }
        }
        setStartAt(newStartAt);
        setEndAt(newEndAt);
    };

    const getInfos = () => {
        setLoadingInfos(true);
        setInfos([]);
        setTotalCount(0);
        Api.get({
            route: 'connected_user_counter_infos',
            data: {
                startAt: startAt.format('YYYY-MM-DD'),
                endAt: endAt.format('YYYY-MM-DD')
            }
        },
            (response) => {
                setInfos(response.data.data);
                setTotalCount(response.data.total);
                setTimeout(() => {
                    setLoadingInfos(false);
                }, 500);
            });

    };

    React.useEffect(getInfos, [currentPeriodIndex, startAt, endAt]);
    React.useEffect(changeSearchSlider, [currentPeriodIndex]);

    return (
        <>
            <div style={{ padding: 20, height: '100%' }}>
                <TitleComponent title={'Détail nombre de connexion apprenant'} />
                <Grid container spacing={2}>
                    <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'space-between', width: '70%', margin: 'auto' }}>
                        <ArrowBackIcon className={classes.arrowLeft} onClick={() => !loadingInfos && changePeriod('left')} />
                        <div className={classes.textPeriod}>{textPeriod}</div>
                        <ArrowForwardIcon className={classes.arrowRight} onClick={() => !loadingInfos && changePeriod('right')} />
                    </div>
                    {currentPeriodIndex !== 3 ?
                        <div style={{ display: 'flex',alignItems: 'center', justifyContent: 'space-between', width: '70%', margin: 'auto' }}>
                            <ArrowBackIcon className={classes.arrowLeft} onClick={() => !loadingInfos && changeSearchSlider('left')} />
                            <div className={classes.textPeriod}>{textSearchSlider}</div>
                            <ArrowForwardIcon className={classes.arrowRight} onClick={() => !loadingInfos && changeSearchSlider('right')} />
                        </div> :
                        <>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <KeyboardDatePicker
                                    className={classes.datepicker}
                                    autoOk
                                    inputVariant="outlined"
                                    margin="normal"
                                    id="startAt"
                                    label="Début"
                                    format="dd/MM/yyyy"
                                    value={startAt}
                                    onChange={(date) => setStartAt(moment(date).startOf('day'))}
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <KeyboardDatePicker
                                    className={classes.datepicker}
                                    autoOk
                                    inputVariant="outlined"
                                    margin="normal"
                                    id="endAt"
                                    label="Fin"
                                    format="dd/MM/yyyy"
                                    value={endAt}
                                    onChange={(date) => setEndAt(moment(date).endOf('day'))}
                                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                />
                            </Grid>
                        </>
                    }
                </Grid>

                <Fade in={loadingInfos} {...{ timeout: 500 }}>
                    <div className={classes.loaderContent}>
                        <UseAnimations animation={activity} size={40} />
                    </div>
                </Fade>
                {!loadingInfos && <Fade in={!loadingInfos} {...{ timeout: 500 }}>
                    <div>
                        {totalCount > 0 ?
                            <div style={{ margin: '20px auto 0px auto' }} className={classes.card}>
                                <span style={{ fontSize: 17 }}>TOTAL</span>
                                <span style={{ fontSize: 17, fontWeight: 700 }}>{totalCount}</span>
                            </div>
                            :
                            <div className={classes.cardEmpty}>
                                <span style={{ fontSize: 16 }}>Aucune données pour ces dates...</span>
                            </div>
                        }
                        <div className={classes.cardContainer}>

                            {infos?.map((item, i) => (
                                <div className={classes.card} key={i}>
                                    <span>{item.organism}</span>
                                    <span style={{ fontWeight: 700 }}>{item.dailyCount}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                </Fade>}
            </div>
        </>
    );
}

const useStyles = (theme) => makeStyles({
    arrowLeft: {
        fontSize: 20,
        padding: '5px 5px',
        cursor: 'pointer',
        borderRadius: 50,
        background: '#EFF2F4',
    },
    arrowRight: {
        fontSize: 20,
        padding: '5px 5px',
        cursor: 'pointer',
        borderRadius: 50,
        background: '#EFF2F4',
    },
    textPeriod: {
        fontSize: 18,
        textAlign: 'center',
        color: '#5E6E82',
        padding: 10
    },
    loaderContent: {
        width: 40,
        padding: 10,
        borderRadius: 50,
        background: '#fff',
        boxShadow: '0 5px 10px 0px rgba(0,0,0,0.25)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    datepicker: {
        margin: 0,
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 5
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '25%',
        textAlign: 'center',
        background: '#EFF2F4',
        margin: 10,
        padding: '0 5px',
        height: 60,
        borderRadius: 10,
        border: '1px dotted black',
        '& > span': {
            fontSize: 13
        }
    },
    cardEmpty: {
        width: '100%',
        textAlign: 'center',
        marginTop: 40,
    }
});
