import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";

export default function ProspectComment(props = {}) {
    const dispatch = useDispatch();

    const [formProspectComment, setFormProspectComment] = React.useState({
        message: {
            name: 'message',
            label: 'Message',
            textHelper: 'Saisissez un message.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required'],
                max: 255
            }
        }
    });
    const [dialogProspectComment, setDialogProspectComment] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handlerProspectComment = formHandlerInit(formProspectComment, setFormProspectComment);
    React.useEffect(handlerProspectComment.start, []);

    const prospectComment = () => {
        if (handlerProspectComment.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerProspectComment.setFormLoading(true);
            setLoading(true);
            let data = handlerProspectComment.getData();

            Api.post({
                    route: 'crm_prospect_prospect_comment_add',
                    data: data,
                    params: {id: props.match.params.id}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setDialogProspectComment(false);
                    handlerProspectComment.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        dispatch(TableReload('crm_prospect_prospect_activity_list'));
                        dispatch(TableReload('crm_prospect_prospect_comment_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Commentaire ajouté.',
                                variant: 'success',
                            })
                        );
                        handlerProspectComment.reset();
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }
    const columnsProspectComments = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'message',
            label: 'Message',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        }
    ];

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'id', direction: 'desc'}}
                id={'crm_prospect_prospect_comment_list'}
                title={'Commentaires'}
                columns={columnsProspectComments}
                actionFirst={{
                    label: 'Ajouter un commentaire',
                    onClick: () => setDialogProspectComment(true)
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'crm_prospect_prospect_comment_list',
                            params: {id: props.match.params.id}
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) data[index].createdAt = moment(data[index].createdAt).fromNow();
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialogProspectComment} maxWidth={'xl'} onClose={() => setDialogProspectComment(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter un commentaire</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <TextFieldComponent name={'message'} handler={handlerProspectComment} multiline={true}/>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogProspectComment(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => prospectComment()} disabled={loading}/>
                </DialogActions>
            </Dialog>
        </>
    )
}
