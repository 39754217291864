import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import SweetAlert from 'sweetalert-react';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";


function TokenApiList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setEdition] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [form, setForm] = React.useState({
        token: {
            name: 'token',
            label: 'Token',
            textHelper: 'Saissisez le token',
            token: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        id: {
            name: 'id',
            label: 'Référence',
            textHelper: 'Saissisez la référence du token',
            token: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "token",
            label: "Token",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const handlerToken = formHandlerInit(form, setForm);
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setDialog(true);
                        setEdition(true);
                        setActionRow(row);
                        handlerToken.setValue('token', row.token)
                        handlerToken.setValue('id', row.id);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const save = () => {
        if (handlerToken.checkError()) {
            console.log('Error');
        } else {
            handlerToken.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                let datas = handlerToken.getData();
                Api.post({
                    route: 'administrator_token_api_edit',
                    params: {id: actionRow.id},
                    data: datas,
                }, (response) => {
                    handlerToken.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setDialog(false)
                        dispatch(TableReload('administrator_token_api_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handlerToken.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'administrator_token_api_add',
                    data: handlerToken.getData(),
                }, (response) => {
                    handlerToken.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setDialog(false)
                        dispatch(TableReload('administrator_token_api_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Token enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handlerToken.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handlerToken.start, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des token API',
            context: 'Gestion token API',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableComponent
                        id={'administrator_token_api_list'}
                        title={'Token API'}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un token',
                            onClick: () => {
                                setDialog(true)
                                setEdition(false)
                                handlerToken.reset();
                            }
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'administrator_token_api_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) { 
                                        data[index].action = getAction(data[index]); 
                                        data[index].createdAt = moment(data[index].createdAt).format('ll');
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                </Grid>
            </Grid>

            <br />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{isEdition ? 'Modifier le token' : 'Ajouter un token'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextFieldComponent name={'id'} handler={handlerToken} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <TextFieldComponent name={'token'} handler={handlerToken} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer le token : \n' + actionRow.id + ' ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'administrator_token_api_delete',
                        params: { id: actionRow.id },
                    },(response) => {
                        if (response.status === 200) {
                            dispatch(TableReload('administrator_token_api_list'))
                            dispatch(
                                SnackbarOpen({
                                    text: 'Le token a bien été supprimé.',
                                    variant: 'success',
                                })
                            );
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default TokenApiList;
