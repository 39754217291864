import React from 'react';
import {makeStyles} from "@material-ui/core";
import {LinearProgress as LinearProgressMUI} from "@material-ui/core";
import {useSelector} from "react-redux";
import {theme} from "../../../App";

export default function LinearProgress() {
    const classes = useStyles(theme)();

    const [isActivated, setActivated] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);

    const linearProgressMainLayoutReducer = useSelector(state => state.LinearProgressMainLayoutReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);

    React.useEffect(() => {
        setActivated(linearProgressMainLayoutReducer.isActivated);
    }, [linearProgressMainLayoutReducer]);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return <LinearProgressMUI className={`${classes.linearProgress} ${isSmallView && classes.linearProgressSmall}`} hidden={!isActivated}/>;
}

const useStyles = (theme) => makeStyles({
    linearProgress: {
        position: 'absolute',
        top: 80,
        width: '100vw',
        height: 2,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    linearProgressSmall: {
        top: 80,
    }
});
