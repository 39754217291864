import React from 'react';

function LoadBackgroundComponent(props = {text: ''}) {
    return (
        <div style={{
            background: 'rgb(0 0 0 / 60%)',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center'
        }}>
            <div style={{
                textAlign: 'center',
                color: '#FFF',
                width: '100vw',
                fontSize: 18
            }}>
                {props.text}
            </div>
        </div>
    );
}

export default LoadBackgroundComponent;
