import React from 'react';
import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import moment from "moment";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import {TrendingUp} from "@material-ui/icons";
import Card from "./Component/Card";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {useDispatch, useSelector} from "react-redux";
import {inArray} from "../../../Tool/ArrayCommon";

const useStyles = makeStyles({
    td: {width: '8%', fontSize: 12, padding: 12},
    th: {width: '8%', fontWeight: 900, fontSize: 12, padding: 12},
    thSize: {width: '8%', fontWeight: 900, fontSize: 12, padding: 12},
    up: {fontSize: 16, verticalAlign: 'bottom', color: '#008000'},
    upText: {fontSize: 11, verticalAlign: 'bottom', color: '#008000'},
    down: {fontSize: 16, verticalAlign: 'bottom', color: '#dc3545'},
    downText: {fontSize: 11, verticalAlign: 'bottom', color: '#dc3545'},
    equalText: {fontSize: 9, verticalAlign: 'middle', color: '#000'},
    textSearchSlider: { fontSize: 16, textAlign: 'center', height: 40, lineHeight: '40px', textTransform: 'capitalize'},
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    select: {
        '& select': {
            padding: 10
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    }

});

function Dashboard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [organism, setOrganism] = React.useState(0);
    const [financeType, setFinanceType] = React.useState(0);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const [textSearchSlider, setTextSearchSlider] = React.useState(moment().year());
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [numberRegistrations, setNumberRegistrations] = React.useState({});
    const [numberNewClients, setNumberNewClients] = React.useState({});
    const [numberPlanneds, setNumberPlanneds] = React.useState({});
    const [numberCompleteds, setNumberCompleteds] = React.useState({});
    const [numberCAs, setNumberCAs] = React.useState({});
    const [numberSatisfactions, setNumberSatisfactions] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());

    const formationGroups = [
        {index: 'med', text: 'Médecin'},
        {index: 'inf', text: 'Idel'},
        {index: 'dent', text: 'Dentiste'},
        {index: 'kin', text: 'Kinésithérpeute'},
        {index: 'orth', text: 'Orthophonistes'},
        {index: 'diver', text: 'Divers'},
    ];

    const getRenderSatisfaction = (value) => {
        if (value >= 0) {
            if (value >= 50) {
                return <span style={{color: '#008000'}}>{value}%</span>;
            } else {
                return <span style={{color: '#dc3545'}}>{value}%</span>;
            }
        } else {
            return 'N/A';
        }
    };
    const changeSearchSlider = (direction = null) => {
        let newStartAt;

        if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
        else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
        else newStartAt = startAt;

        newStartAt = moment(newStartAt.startOf('year').format());

        setTextSearchSlider(newStartAt.format('YYYY'));
        setStartAt(newStartAt);
    };
    const convertPercentage = (value1, value2) => {
        if (value1 > 0 && value2 > 0) {
            return <span>{(value1 * 100 / value2).toFixed(2)}% <span style={{fontSize: 10}}>({value1})</span></span>;
        } else {
            return <span>0% <span style={{fontSize: 10}}>({value1})</span></span>;
        }
    };
    const rows2 = [
        ['Médecin', 20, 159, 6.0, 24, 4.0, 1414, 20, 159, 6.0, 24, 4.0, 1414],
        ['Idel', 45757, 237, 9.0, 37, 2452, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Dentiste', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Kinésithérpeute', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Orthophonistes', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452]
        ['Divers', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452]
    ];
    // Get Info all chart
    const getInfo = () => {
        if (startAt) {
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                    route: 'investor_dashboard_info',
                    data: {year: startAt.format('YYYY'),
                    organism: (organism === 'all') ? '' : organism ,
                    financeType: (financeType === 'all') ? '' : financeType}
                },
                (response) => {
                    if (response && response.data) {
                        setNumberRegistrations(response.data.numberRegistrations ? response.data.numberRegistrations : {});
                        setNumberPlanneds(response.data.numberPlanneds ? response.data.numberPlanneds : {});
                        setNumberCompleteds(response.data.numberCompleteds ? response.data.numberCompleteds : {});
                        setNumberCAs(response.data.numberCAs ? response.data.numberCAs : {});
                        setNumberSatisfactions(response.data.numberSatisfactions ? response.data.numberSatisfactions : {});
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setReadyContent(true);
                });
        }
    };

    React.useEffect(() => {
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    if (response && response.data) {
                        for (let index in response.data) {
                            if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                                data.push(response.data[index]);
                            }
                        }
                        setOptionOrganisms(data);
                    }
                }
            });

        Api.get({
                route: 'select_finance_types'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(parseInt(response.data[index].value), authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionFinanceTypes(data);
                }
            });

    }, []);
    React.useEffect(changeSearchSlider, []);
    React.useEffect(getInfo, [startAt, organism, financeType]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Tableau de bord',
            context: 'Investisseur',
            description: 'Statistiques.'
        });
    }, []);
    //  <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span>
    //  <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span>
    //  <span className={classes.equalText}>(0)</span>

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Organisme</InputLabel>
                                    <Select
                                        native
                                        value={organism}
                                        onChange={(event) => setOrganism(event.target.value)}
                                        label="Organisme"
                                        inputProps={{name: 'Organisme', id: 'outlined-age-native-simple'}}
                                        className={classes.select}
                                    >
                                        <option value={'all'}>Tous</option>
                                        {
                                            optionOrganisms && optionOrganisms.map((organism) => {
                                                return <option key={organism.value} value={organism.value}>{organism.label}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <FormControl variant="outlined" style={{width: '100%'}}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Type de financement</InputLabel>
                                    <Select
                                        native
                                        value={financeType}
                                        onChange={(event) => setFinanceType(event.target.value)}
                                        label="Type de financement"
                                        inputProps={{name: 'Type de financement', id: 'outlined-age-native-simple'}}
                                        className={classes.select}
                                    >
                                        <option value={'all'}>Tous</option>
                                        {
                                            optionFinanceTypes && optionFinanceTypes.map((financeType) => {
                                                return <option key={financeType.value} value={financeType.value}>{financeType.label}</option>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} style={{position: 'relative'}}>
                                <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')}/>
                                <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')}/>
                            </Grid>

                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>
            <br/>

            {(numberRegistrations && numberRegistrations.total) && <>
                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Médecins'}
                                number={numberRegistrations.total.med}
                                percentage={numberRegistrations.totalPercentage.med}
                                turnover={numberRegistrations.total.med}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Idels'}
                                number={numberRegistrations.total.inf}
                                percentage={numberRegistrations.totalPercentage.inf}
                                turnover={numberRegistrations.total.inf}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Dentistes'}
                                number={numberRegistrations.total.dent}
                                percentage={numberRegistrations.totalPercentage.dent}
                                turnover={numberRegistrations.total.dent}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Kinésithérpeutes'}
                                number={numberRegistrations.total.kin}
                                percentage={numberRegistrations.totalPercentage.kin}
                                turnover={numberRegistrations.total.kin}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>

                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Orthophonistes'}
                                number={numberRegistrations.total.orth}
                                percentage={numberRegistrations.totalPercentage.orth}
                                turnover={numberRegistrations.total.orth}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>

                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Divers'}
                                number={numberRegistrations.total.diver}
                                percentage={numberRegistrations.totalPercentage.diver}
                                turnover={numberRegistrations.total.diver}
                                color={'#000'}
                                label={'Nombre d\'inscriptions cette année'}
                                labelEmpty={'Aucun inscription cette année'}
                            />
                        </Grid>
                    </Zoom>
                </Grid>

                <br/>

                <ShadowBoxComponent>
                    <TitleComponent title={'Nombre d\'inscriptions par mois'}/>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['02'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['04'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['06'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['08'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['10'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberRegistrations['12'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br/>
            </>}

            {(numberNewClients && numberNewClients.total) && <>
                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Nouveau client'}
                                number={0}
                                percentage={-1}
                                turnover={0}
                                color={'#000'}
                                label={'Nombre nouveau client cette année'}
                                labelEmpty={'Aucun nouveau client cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Nouveau client N-1'}
                                number={0}
                                percentage={-1}
                                turnover={0}
                                color={'#000'}
                                label={'Nombre nouveau client cette année'}
                                labelEmpty={'Aucun nouveau client cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Nouveau client N-2'}
                                number={0}
                                percentage={-1}
                                turnover={0}
                                color={'#000'}
                                label={'Nombre nouveau client cette année'}
                                labelEmpty={'Aucun nouveau client cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Nouveau client < N-2'}
                                number={0}
                                percentage={-1}
                                turnover={0}
                                color={'#000'}
                                label={'Nombre nouveau client cette année'}
                                labelEmpty={'Aucun nouveau client cette année'}
                            />
                        </Grid>
                    </Zoom>
                </Grid>

                <br/>

                <ShadowBoxComponent>
                    <TitleComponent title={'Nombre de nouveau client'}/>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Janvier N-1</TableCell>
                                    <TableCell className={classes.th}>Janvier N-2</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Février N-1</TableCell>
                                    <TableCell className={classes.th}>Février N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Mars N-1</TableCell>
                                    <TableCell className={classes.th}>Mars N-2</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Avril N-1</TableCell>
                                    <TableCell className={classes.th}>Avril N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Mai N-1</TableCell>
                                    <TableCell className={classes.th}>Mai N-2</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juin N-1</TableCell>
                                    <TableCell className={classes.th}>Juin N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Juillet N-1</TableCell>
                                    <TableCell className={classes.th}>Juillet N-2</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Août N-1</TableCell>
                                    <TableCell className={classes.th}>Août N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Septembre N-1</TableCell>
                                    <TableCell className={classes.th}>Septembre N-2</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Octobre N-1</TableCell>
                                    <TableCell className={classes.th}>Octobre N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Novembre N-1</TableCell>
                                    <TableCell className={classes.th}>Novembre N-2</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                    <TableCell className={classes.th}>Décembre N-1</TableCell>
                                    <TableCell className={classes.th}>Décembre N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{row[0]}</TableCell>
                                        <TableCell className={classes.td}>{row[1]} <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[2]}</TableCell>
                                        <TableCell className={classes.td}>{row[3]} <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span></TableCell>
                                        <TableCell className={classes.td}>{row[4]}</TableCell>
                                        <TableCell className={classes.td}>{row[5]} <span className={classes.equalText}>(0)</span></TableCell>
                                        <TableCell className={classes.td}>{row[6]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br/>
            </>}

            {(numberPlanneds && numberPlanneds.total && numberCompleteds && numberCompleteds.total && numberCAs && numberCAs.total) && <>
                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Médecins'}
                                number={numberPlanneds.total.med}
                                percentage={numberPlanneds.totalPercentage.med}
                                turnover={numberPlanneds.total.med}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Idels'}
                                number={numberPlanneds.total.inf}
                                percentage={numberPlanneds.totalPercentage.inf}
                                turnover={numberPlanneds.total.inf}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Dentistes'}
                                number={numberPlanneds.total.dent}
                                percentage={numberPlanneds.totalPercentage.dent}
                                turnover={numberPlanneds.total.dent}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Kinésithérpeute'}
                                number={numberPlanneds.total.kin}
                                percentage={numberPlanneds.totalPercentage.kin}
                                turnover={numberPlanneds.total.kin}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Orthophonistes'}
                                number={numberPlanneds.total.orth}
                                percentage={numberPlanneds.totalPercentage.orth}
                                turnover={numberPlanneds.total.orth}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>

                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Divers'}
                                number={numberPlanneds.total.diver}
                                percentage={numberPlanneds.totalPercentage.diver}
                                turnover={numberPlanneds.total.diver}
                                color={'#000'}
                                label={'Nombre de formation planifiée cette année'}
                                labelEmpty={'Aucune formation planifiée cette année'}
                            />
                        </Grid>
                    </Zoom>
                </Grid>

                <br/>

                <ShadowBoxComponent>
                    <TitleComponent title={'Taux de complétion de formation'}/>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Janvier (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Janvier (CA)</TableCell>
                                    <TableCell className={classes.th}>Février (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Février (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Février (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['01'][formationGroup['index']], numberPlanneds['01'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['02'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['02'][formationGroup['index']], numberPlanneds['02'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['02'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mars (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Mars (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Mars (CA)</TableCell>
                                    <TableCell className={classes.th}>Avril (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Avril (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Avril (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['03'][formationGroup['index']], numberPlanneds['03'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['04'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['04'][formationGroup['index']], numberPlanneds['04'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['04'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mai (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Mai (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Mai (CA)</TableCell>
                                    <TableCell className={classes.th}>Juin (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Juin (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Juin (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['05'][formationGroup['index']], numberPlanneds['05'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['06'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['06'][formationGroup['index']], numberPlanneds['06'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['06'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Juillet (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Juillet (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Juillet (CA)</TableCell>
                                    <TableCell className={classes.th}>Août (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Août (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Août (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['07'][formationGroup['index']], numberPlanneds['07'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['08'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['08'][formationGroup['index']], numberPlanneds['08'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['08'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Septembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Septembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Septembre (CA)</TableCell>
                                    <TableCell className={classes.th}>Octobre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Octobre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Octobre (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['09'][formationGroup['index']], numberPlanneds['09'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['10'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['10'][formationGroup['index']], numberPlanneds['10'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['10'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Novembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Novembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Novembre (CA)</TableCell>
                                    <TableCell className={classes.th}>Décembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Décembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Décembre (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['11'][formationGroup['index']], numberPlanneds['11'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['12'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['12'][formationGroup['index']], numberPlanneds['12'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['12'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br/>
            </>}

            {(numberSatisfactions && numberSatisfactions.total) && <>
                <ShadowBoxComponent>
                    <TitleComponent title={'Taux de satisfaction'}/>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['01'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['02'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['03'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['04'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['05'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['06'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['07'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['08'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['09'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['10'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['11'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['12'][formationGroup['index']])}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br/>
            </>}

        </ContentViewComponent>
    );
}

export default Dashboard;
