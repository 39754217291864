import React from 'react'
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SweetAlert from "sweetalert-react";
import QuestionAnswer from './QuestionAnswer';
import QuestionComment from './QuestionComment';

function Question(props) {
    const data = props.dataQuestion.data;
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const [loading, setLoading] = React.useState(true);
    const [isListComment, setIsListComment] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isReload, setIsReload] = React.useState(false);

    const [form, setForm] = React.useState({
        question: {
            name: 'question',
            title: 'Question',
            textHelper: 'Saisissez une question',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);
    const onChangeQuestion = (newQuestion) => {
        const updatedList = props.list.map(item => {
            if (item.order === data.order) {
                return { ...item, question: newQuestion }
            }
            return item
        });
        props.onChange(updatedList)
    }
    const [checkedListComment, setCheckedListComment] = React.useState([]);
    const [checkedListAnswer, setCheckedListAnswer] = React.useState([]);
    React.useEffect(handler.start, []);
    React.useEffect(() => { handler.setDataApi(data) }, [data.question])
    return (
        <>
            <Box className={classes.subMenu} style={{ width: screenReducer.screen === 'XL' ? 1100 : '100%', height: '100%' }}>
                <Box className={classes.containerTextField} style={{ boxShadow: '0px 0px 11px #00000082', borderRadius: 8 }}>
                    <Box style={{ display: 'flex', margin: '20px 20px 0 20px', alignItems: 'center' }}>
                        <Box className={classes.select}>
                            <IconButton className={classes.settings} disabled={loading} onClick={() => {
                                if ((checkedListComment.length === 0) && (checkedListAnswer.length === 0)) {
                                    props.isBack(false); setIsListComment(false)
                                } else {
                                    const hasAddPropertyComment = checkedListComment.some(item => item.hasOwnProperty('add'));
                                    const hasAddPropertyAnswer = checkedListAnswer.some(item => item.hasOwnProperty('add'));
                                    if (hasAddPropertyAnswer || hasAddPropertyComment) {
                                        setShowConfirm(true)
                                    } else {
                                        props.isBack(false); setIsListComment(false)
                                    }
                                }
                            }} >
                                <ArrowBackIosIcon style={{ width: 16 }} /><p style={{ margin: 0, fontWeight: 'bold', whiteSpace: 'nowrap', }}>Retour aux questions</p>
                            </IconButton>
                        </Box>
                        <p style={{ margin: 0, padding: '0px 0px', fontWeight: 'bold', color: 'rgb(94, 110, 130)', fontSize: 20 }} >Question - {props.dataQuestion.order && props.dataQuestion.order} </p>
                    </Box>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'question'} handler={handler} disabled={loading} onChange={(newQuestion) => { onChangeQuestion(newQuestion) }} />
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ position: 'relative' }}>
                    <Box className={classes.dragStyle}
                        style={{
                            left: isListComment ? '-50%' : '50%',
                            opacity: isListComment ? 0 : 1,
                            visibility: isListComment ? 'hidden' : 'visible'
                        }}>
                        <QuestionAnswer
                            handler={handler}
                            data={data}
                            setAction={{ setLoading, setIsListComment }}
                            setCheckedList={setCheckedListAnswer}
                            questionOrder={props.dataQuestion.order}
                            loadingQuestion={props.loadingQuestion}
                            questionList={props.list}
                            deletedQuestionList={props.deletedQuestionList}
                            reload={isReload}
                            {...props}
                        />
                    </Box>
                    <Box className={classes.dragStyle}
                        style={{
                            left: isListComment ? '50%' : '150%',
                            opacity: isListComment ? 1 : 0,
                            visibility: isListComment ? 'visible' : 'hidden'
                        }}>
                        <QuestionComment
                            handler={handler}
                            data={data}
                            setAction={{ setLoading, setIsListComment }}
                            setCheckedList={setCheckedListComment}
                            questionOrder={props.dataQuestion.order}
                            loadingQuestion={props.loadingQuestion}
                            questionList={props.list}
                            deletedQuestionList={props.deletedQuestionList}
                            reload={isReload}
                            {...props}
                        />
                    </Box>
                </Box>
            </Box>
            <SweetAlert
                show={showConfirm}
                title={'Des réponses ou commentaires n\'ont pas été enregistrés.'}
                text={'Êtes-vous sûr(e) de vouloir revenir au questionnaire ? \nSi oui, les informations non enregistrées \nseront retirées de leur liste.'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => { 
                    props.isBack(false); 
                    setIsListComment(false); 
                    setIsReload(true); 
                    setShowConfirm(false) 
                    setTimeout(() => {setIsReload(false)}, 100);
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}

const useStyles = (screenReducer) => makeStyles({
    subMenu: {
        height: '100%',
        background: 'transparent',
        borderRadius: 4,
        display: 'grid',
        gridGap: 20,
        gridTemplateRows: '20% 77%',
        margin: 0,
        transitionProperty: 'all', transitionDuration: '.4s',
    },
    containerTextField: {
        background: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiGrid-container': {
            width: '88%',
            margin: '14px auto',
        }
    },
    select: {
        border: "1px solid #d7d6e1",
        margin: 'auto 8px auto 0',
        borderRadius: 4,
        background: "#F8FAFC",
        overflow: 'hidden',
        height: 30,
        width: 31,
        transitionProperty: 'all', transitionDuration: '.4s',
        '&:hover': {
            width: 176,
        },
    },
    settings: {
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            transitionProperty: 'all',
            transitionDuration: '.3s',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
            justifyContent: 'initial',
            padding: '0px 10px',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    dragStyle: {
        boxShadow: '0px 0px 11px #00000082', borderRadius: 8,
        transitionProperty: 'all', transitionDuration: '.5s',
        transform: 'translate(-50%,-50%)',
        background: '#FFF', width: '100%', height: '100%',
        top: '50%', position: 'absolute', overflow: 'hidden',
    }
});

export default Question;
