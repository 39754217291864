import React from 'react';
import { Box, makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { getRouteByPathname } from "../../../Config/Route";
import Popover from "@material-ui/core/Popover";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import { Drawer as DrawerMUI } from "@material-ui/core";
import { theme } from "../../../App";

export default function Drawer(props = { route: [] }) {
    const classes = useStyles(theme)();
    const [expanded, setExpanded] = React.useState('');
    const [expandedPopover, setExpandedPopover] = React.useState({});
    const [route, setRoute] = React.useState({});

    const onClickTooltip = (id, event) => {
        if (props.isOpen) setExpanded(id === expanded ? '' : id);
        else setExpandedPopover((expandedPopover.id === id) ? {} : { id: id, anchorEl: event.currentTarget });
    };
    const list = () => {
        return (
            <List style={{ color: '#FFF', padding: 0 }}>
                {
                    props.route.map((element, index) => {
                        if (element.id) {
                            if (element.children) {
                                return (
                                    <div key={index} className={`${props.isOpen && expanded.startsWith(element.id) ? classes.drawerListOpen : classes.drawerList}`} >
                                        <Tooltip title={!props.isOpen ? element.label : ''} placement="right" key={element.id} style={(route.id && route.id.startsWith(element.id)) ? { background: 'rgb(236 236 236)' } : {}}>
                                            <ListItem button key={element.id} onClick={(event) => onClickTooltip(element.id, event)} className={classes.drawerListItem}>
                                                <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                                                <ListItemText className={classes.drawerListText} primary={element.label} />
                                            </ListItem>
                                        </Tooltip>
                                        {
                                            props.isOpen ?
                                                <Collapse in={expanded.startsWith(element.id)} timeout="auto" unmountOnExit style={{ zIndex: 0 }}>
                                                    {
                                                        element.children.map((children) => {
                                                            return (
                                                                <ListItem className={classes.drawerListItem}
                                                                    button
                                                                    key={children.id}
                                                                    selected={(children.id === route.id)}
                                                                    style={(children.id === route.id) ? { background: 'rgb(236 236 236)' } : {}}
                                                                    onClick={() => props.history.push(children.path)}
                                                                >
                                                                    <ListItemIcon className={classes.drawerListIcon} />
                                                                    <ListItemText className={classes.drawerListText} primary={children.label} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                                :
                                                <Popover
                                                    id={element.id}
                                                    open={expandedPopover.id === element.id}
                                                    anchorEl={expandedPopover.anchorEl}
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                                                    onClose={() => setExpandedPopover({})}
                                                    className={classes.drawerPopover}
                                                >
                                                    {
                                                        element.children.map((children) => {
                                                            return (
                                                                <ListItem className={classes.drawerListItem}
                                                                    button
                                                                    key={children.id}
                                                                    selected={(children.id === route.id)}
                                                                    style={(children.id === route.id) ? { background: 'rgb(236 236 236)' } : {}}
                                                                    onClick={() => props.history.push(children.path)}
                                                                >
                                                                    <ListItemText className={classes.drawerListText} primary={children.label} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </Popover>
                                        }
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div key={index}>
                                        <Tooltip title={!props.isOpen ? element.label : ''} placement="right" key={element.id}>
                                            <ListItem className={classes.drawerListItem}
                                                button
                                                key={element.id}
                                                selected={(element.id === route.id)}
                                                style={(element.id === route) ? { background: 'rgb(236 236 236)' } : {}}
                                                onClick={() => props.history.push(element.path)}
                                            >
                                                <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                                                <ListItemText className={classes.drawerListText} primary={element.label} />
                                            </ListItem>
                                        </Tooltip>
                                    </div>
                                );
                            }
                        }
                    })
                }
            </List>
        );
    }

    React.useEffect(() => {
        let currentRoute = getRouteByPathname(props.location.pathname);
        setRoute(currentRoute);
        setExpanded(currentRoute.id);
        setExpandedPopover({});
    }, [props.location.pathname]);


    return <DrawerMUI
        variant="permanent"
        className={`${classes.drawer} ${props.isOpen ? classes.drawerOpen : classes.drawerClose}`}
        open={props.isOpen}
    >
        <Box style={{
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            padding: '19px 19px 10px 19px',
            color: '#5E6E82', opacity: 0.7, fontSize: 14
        }}>MENU<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
        </Box>

        {list()}
    </DrawerMUI>
}


const drawerOpenWidth = 260;
const drawerCloseWidth = 0;

const useStyles = (theme) => makeStyles(style => ({
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1000,
        paddingTop: 58,
        boxSizing: 'border-box',
        position: 'absolute',
        right: 0,
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth,
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    drawerHeader: {
        transition: 'all 200ms',
        textAlign: 'center'
    },
    drawerOpenHeader: {
        width: drawerOpenWidth,
    },
    drawerCloseHeader: {
        width: drawerCloseWidth,
    },
    drawerAvatar: {
        width: 45,
        marginTop: 25
    },
    drawerText: {
        transition: 'all 200ms',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 500,
        color: '#5E6E82',
        textTransform: 'capitalize',
        fontSize: 14,
        paddingBottom: 10
    },
    drawerOpenText: {
        opacity: 1
    },
    drawerCloseText: {
        opacity: 0
    },
    drawerNetwork: {
        display: 'flex',
        margin: 'auto 0 0 0',
        justifyContent: 'center',
        width: '100%'
    },
    drawerNetworkSmall: {
        width: '100%',
        top: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'flex-end'
    },
    drawerOpenNetwork: {
        display: 'flex'
    },
    drawerCloseNetwork: {
        display: 'none'
    },
    drawerListItem: {
        padding: '3px 30px',
        gap: 10
    },
    drawerListIcon: {
        color: '#5E6E82',
        minWidth: 'initial',
        '& .MuiSvgIcon-root': {
            fontSize: 18
        }
    },
    drawerListText: {
        cursor: 'pointer',
        '& .MuiListItemText-primary': {
            fontSize: 14,
            color: '#5E6E82'
        }
    },
    drawerPopover: {
        left: '68px !important',
        '& .MuiPopover-paper': {
            borderRadius: '0px !important',
            left: '1px !important',
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 10px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
        },
        '& > div:nth-child(1)': {
            left: '68px !important',
        }
    },
    drawerListOpen: {
        zIndex: 1,
        backgroundColor: '#FFFFFF',
        borderLeft: '2px solid ' + theme.colorPrimary + '!important',
        boxSizing: "border-box",
        position: 'relative'
    },
    drawerList: {
        borderLeft: '2px solid #FFFFFF',
        transition: 'all .1s',
        '&:hover': {
            borderLeft: '2px solid ' + theme.colorPrimary,
        }
    },
    drawerIconClose: {
        position: 'absolute',
        padding: 12,
        fontSize: 18,
        zIndex: 2,
        cursor: 'pointer'
    }
}));
