import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import {makeStyles, Grid, Box, Fade, Typography, Slider, Input} from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import WorkIcon from '@material-ui/icons/Work';
import SearchIcon from "@material-ui/icons/Search";
import InsertChartIcon from '@material-ui/icons/InsertChart';

const LoadingAnimation = () => {
    const classes = useStyles()();
    return (
        <div className={classes.loading}>
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
            <div class="four"></div>
            <div class="five"></div>
        </div>
    )
}

export default function OrgChartComponent({ className, data, loading }) {
    const containerRef = React.useRef(null);
    const treeRef = React.useRef(null);
    const [treeWidth, setTreeWidth] = React.useState(0);
    const classes = useStyles()();
    const [newData, setNewData] = React.useState(null);
    const [scaleTree, setScaleTree] = React.useState(100);

    const handleSliderChange = (event, newValue) => {
        setScaleTree(newValue);
    };

    const handleInputChange = (event) => {
        setScaleTree(event.target.value === '' ? '' : Number(event.target.value));
    };

    const handleBlur = () => {
        if (scaleTree < 0) {
            setScaleTree(0);
        } else if (scaleTree > 100) {
            setScaleTree(100);
        }
    };

    const dataTemplate = (nodeData) => {
        if (nodeData.supervisors) {
            return (
                <div style={{ border: '1px solid', borderRadius: 10, padding: '0 10px', width: 'fit-content', margin: 'auto', display: 'flex', gap: 17, background: '#5E6E82' }} >
                    {nodeData.supervisors.map((nodeSupervisor) => (
                        <div style={{ borderRadius: 10, padding: 10, margin: '10px auto', width: 'fit-content', background: '#FFF', display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 10 }} >
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <FaceIcon />
                            </div>
                            <div>
                                <p style={{ margin: 0 }}><b>{nodeSupervisor.lastname} {nodeSupervisor.firstname}</b></p>
                                <span style={{ display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap', width: 'max-content' }}><WorkIcon style={{ width: 18 }} /> {nodeSupervisor.jobTitle ? nodeSupervisor.jobTitle : ' aucun poste'}</span>
                            </div>
                        </div>
                    ))}
                </div >
            );
        } else {
            return (
                <div style={{ border: '1px solid', borderRadius: 10, padding: 10, margin: '0px auto', width: 'fit-content', background: '#FFF', display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 10 }} >
                    <div style={{ height: '100%', display: 'flex', alignItems: 'center', margin: 'auto' }}>
                        <FaceIcon />
                    </div>
                    <div>
                        <p style={{ margin: 0 }}><b>{nodeData.lastname} {nodeData.firstname}</b></p>
                        <span style={{ display: 'flex', alignItems: 'center', gap: 5, flexWrap: 'wrap', width: 'max-content' }}><WorkIcon style={{ width: 18 }} /> {nodeData.jobTitle ? nodeData.jobTitle : ' aucun poste'}</span>
                    </div>
                </div>
            );
        }
    };

    const renderTreeNode = (node) => {
        return (node &&
            <TreeNode key={node.id} label={dataTemplate(node)}>
                {node.children && node.children.map(childNode => renderTreeNode(childNode))}
            </TreeNode>
        );
    };

    React.useEffect(() => {
        const newDataValue = data && data.supervisors.length !== 0 ? data : null;
        setNewData(newDataValue);
    }, [data]);

    React.useEffect(() => {
        const container = containerRef.current;
        const tree = treeRef.current;

        if (container && tree) {
            const resizeObserver = new ResizeObserver(entries => {
                const containerWidth = entries[0].contentRect.width;
                const newScale = containerWidth / treeWidth;
                const scaleTreeTMP = newScale > 1 ? 100 : newScale * 100
                setScaleTree(scaleTreeTMP);
            });

            if (tree) {
                const treeRect = tree.getBoundingClientRect();
                setTreeWidth(tree.offsetWidth);
            }

            resizeObserver.observe(container);
            return () => {
                resizeObserver.unobserve(container);
            };
        }
    }, [treeWidth, newData]);

    return (
        (loading) ?
            <Fade in={true} {...{ timeout: 1000 }}>
                <LoadingAnimation />
            </Fade> :
            <Fade in={true} {...{ timeout: 1000 }}>
                <div className={className} >
                    <Box ref={containerRef} style={{ height: '100%', position: "relative" }} >
                        {newData ?
                            <div ref={treeRef} style={{ width: 'max-content', transformOrigin: 'left top', margin: 'auto', transform: `scale(${scaleTree}%)` }}>
                                <Tree lineWidth={'1px'} lineColor='#5E6E82'>
                                    {renderTreeNode(newData)}
                                </Tree>
                            </div>
                            : <div className={classes.error}><InsertChartIcon /><p>Aucune hiérarchie n'a été définie.</p></div>
                        }
                        {newData &&
                            <div className={classes.root}>
                                <Typography id="input-slider" gutterBottom>
                                    Zoom
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <SearchIcon />
                                    </Grid>
                                    <Grid item xs>
                                        <Slider
                                            value={typeof scaleTree === 'number' ? scaleTree : 80}
                                            onChange={handleSliderChange}
                                            aria-labelledby="input-slider"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Input
                                            className={classes.input}
                                            value={scaleTree}
                                            margin="dense"
                                            onChange={handleInputChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                step: 10,
                                                min: 0,
                                                max: 100,
                                                type: 'number',
                                                'aria-labelledby': 'input-slider',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </Box>
                </div>
            </Fade>
    );
};

const useStyles = () => makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '60% !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double #5E6E82',
        borderRadius: 100,
        '& svg': {
            color: '#5E6E82',
        }
    },
    error: {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) !important',
        width: '100%',
        opacity: '0.4',
        flexDirection: 'column',
        alignItems: 'center',
        '& svg': {
            height: '20vh',
            width: 'auto',
        },
        '& p': {
            fontSize: '6vh',
            margin: 0,
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center'
        }
    },
    loading: {
        width: 215,
        display: 'flex',
        scale: '76%',
        margin: 'auto',
        '& div': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            margin: 'auto',
            animation: '$loading 2.5s ease-in-out infinite',
        },
        '& div:nth-child(0)': {
            animationDelay: '0s',
        },
        '& div:nth-child(1)': {
            animationDelay: '0.2s',
        },
        '& div:nth-child(2)': {
            animationDelay: ' 0.4s',
        },
        '& div:nth-child(3)': {
            animationDelay: '0.6s',
        },
        '& div:nth-child(4)': {
            animationDelay: '0.8s',
        },
        '& div:nth-child(5)': {
            animationDelay: '1s',
        }
    },
    root: {
        width: 250,
        position: "fixed",
        bottom: 20,
        right: 20,
        border: '2px solid',
        borderRadius: '10px',
        background: '#FFFFFFB8',
        zIndex: 1,
        padding: 10,
    },
    input: {
        width: 42,
    },

    '@keyframes loading': {
        '0%, 100%': {
            transform: 'scale(0.2)',
            backgroundColor: '#5E6E82',
        },
        '42%': {
            backgroundColor: '#5E6E82',
        },
        '50%': {
            transform: 'scale(1)',
            backgroundColor: '#5E6E82',
        },
    }
})