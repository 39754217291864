import React from 'react';
import { Box, Fade, Grid, Slide, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import TitleComponent from '../../../../Component/TitleComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import CheckIcon from '@material-ui/icons/Check';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import InstitutionGroupInstitution from './InstitutionGroupInstitution';
import InstitutionGroupDiscount from './InstitutionGroupDiscount';

export default function InstitutionGroup(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [dialogAddDiscount, setDialogAddDiscount] = React.useState(false);
    const classes = useStyles(isEdition)();

    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du groue',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            const errorLabels = Object.values(handler.form).filter(item => item.error).map(item => item.label);
            dispatch(
                SnackbarOpen({
                    text: errorLabels.length === 1 ? 'Le champ "' + errorLabels + '" est obligatoire' : 'Les champs "' + errorLabels.join(', ') + '" sont obligatoires',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                if (props.match.params.id) {
                    Api.post({
                        route: 'institution_health_crm_institution_group_edit',
                        data: handler.getData(),
                        params: { organismId: selectOrganismReducer.organism.value, id: props.match.params.id }
                    }, (response) => {
                        handler.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (response.status === 200) {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Groupement modifiée.',
                                    variant: 'success',
                                })
                            );
                        } else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_crm_institution_group_add',
                    data: {...datas, organism: selectOrganismReducer.organism.value }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('institution_health_crm_institution_group', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Groupement enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Groupement',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'formulaire',
            links: [
                { path: getRoutePathname('institution_health_crm_institution_group_list'), label: 'Groupement d\'établissements' }
            ],
        });
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setIsEdition(true);
            Api.get({
                route: 'institution_health_crm_institution_group_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_crm_institution_group_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else setReadyContent(true);
    }, [props.match.params.id]);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box style={{ height: '90%', width: '95%', margin: 'auto' }}>
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Grid container spacing={3} style={{ margin: 'auto', height: '100%', width: '100%' }}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                                <Slide direction={'right'} in={true} {...{ timeout: 500 }}>
                                    <div style={{ height: '100%' }}>
                                        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, gap: 0, gridTemplateRows: 'max-content auto max-content' }}>
                                            <Box>
                                                <TitleComponent title={
                                                    <Box style={{ display: 'flex' }}>
                                                        <p style={{ margin: 0 }}>Formulaire d'édition de groupe</p>
                                                    </Box>
                                                } />
                                            </Box>
                                            <Grid container spacing={0} style={{ width: '100%', margin: '0', gap: 10, height: '100%', gridTemplateRows: 'max-content auto', display: 'grid' }}>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <TextFieldComponent name={'name'} handler={handler} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ position: 'relative', }}>
                                                    <InstitutionGroupDiscount
                                                        dialogAddDiscount={dialogAddDiscount}
                                                        setDialogAddDiscount={setDialogAddDiscount}
                                                        setLoading={setLoading}
                                                        {...props}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Box style={{ margin: 'auto 0 0 auto', width: '100%', display: 'flex', borderRadius: '0 0 10px 10px', overflow: 'hidden' }}>
                                                <ButtonComponent label={'Ajouter une réduction'} className={classes.button} onClick={() => { setDialogAddDiscount(true) }} disabled={!isEdition || loading} />
                                                <Tooltip title={'Enregistrer'} placement="left">
                                                    <ButtonComponent label={<CheckIcon />} color={"#28a745"} className={classes.button} style={{ width: 'max-content', }} onClick={() => { save() }} disabled={loading} />
                                                </Tooltip>
                                            </Box>
                                        </ShadowBoxComponent>
                                    </div>
                                </Slide>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9} className={classes.TableComponent}>
                                <InstitutionGroupInstitution setLoading={setLoading} isEdition={isEdition} {...props} />
                            </Grid>
                        </Grid>
                    </Fade>
                </Box>
            </ContentViewComponent>
        </Box>
    )
}



const useStyles = (isEdition) => makeStyles({
    TableComponent: {
        height: '100%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    button: {
        borderRadius: 0,
        width: '100%',
    },
    infoSpanTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10
    },
    infoSpanText: {
        fontSize: 13
    },
})