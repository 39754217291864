import React from 'react';
import { makeStyles } from "@material-ui/core";
import CountUp from 'react-countup';
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";

function CardV2(props = {
    title: '',
    number: 100,
    percentage: 100,
    turnover: 100,
    color: '',
    button: {
        nameButton: '',
        onClick: '',
    },
    disabled: false,
}) {
    const classes = useStyles();
    const [buttonPara, setButton] = React.useState();

    React.useEffect(() => {
        if (props.button) {
            setButton(props.button[0])
        }
    }, [props.button]);

    return (
        <ShadowBoxComponent disabled={props.disabled}>
            <div className={classes.percentage}>{props.percentage >= 0 ? (props.percentage + '%') : ''}</div>
            <div className={classes.number}>
                {props.number > 0 ? <span style={{ fontSize: 12 }}>{props.label}</span> : <span style={{ fontSize: 12 }}>{props.labelEmpty}</span>}
            </div>
            <div className={classes.turnover}><CountUp separator=" " end={props.turnover} /></div>
            <hr style={{ marginTop: 20 }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className={classes.title}>{props.title}</span>
                {buttonPara && <button className={classes.button} onClick={buttonPara.onClick}>{buttonPara.nameButton}</button>}
            </div>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    number: {
        textAlign: 'end',
        fontSize: 15,
    },
    turnover: {
        textAlign: 'end',
        fontWeight: 500,
        fontSize: 40,
        marginTop: 5,
        color: '#5E6E82'
    },
    percentage: {
        position: 'absolute',
        top: 15,
        left: 15,
        fontSize: 15,
    },
    icon: {
        position: 'absolute',
        top: 40,
        left: 15,
        fontSize: 30
    },
    button: {
        margin: '0 0 0 auto',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        display: 'block',
        fontWeight: '500',
        letterSpacing: '0.02857em',
        background: '#5463B6',
        cursor: 'pointer',
        border: '0',
        padding: '7px 22px',
        fontSize: '15px',
        borderRadius: '3px',
        color: '#FFF',
    }
});

export default CardV2;
