import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core';
import {Delete, Edit, Note} from '@material-ui/icons';
import ButtonComponent from '../../../Component/ButtonComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { TableReload } from '../../../Action/TableAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import SelectComponent from '../../../Component/SelectComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import SweetAlert from "sweetalert-react";

export default function EquipmentHistorical(props = {}) {
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showComment, setShowComment] = React.useState(false);
    const [historyId, setHistoryId] = React.useState(null);
    const [comment, setComment] = React.useState(null);
    const [row, setRow] = React.useState([]);
    const [optionEquipmentState, setOptionEquipmentState] = React.useState([]);
    const [optionEquipmentActions, setOptionEquipmentActions] = React.useState([]);

    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'actionLabel',
            label: 'Type d\'action',
            options: { filter: true, sort: true }
        },
        {
            name: 'doneByName',
            label: 'Fait par',
            options: { filter: true, sort: true }
        },
        {
            name: 'doneAt',
            label: 'Fait le',
            options: { filter: true, sort: true }
        },
        {
            name: 'resetAt',
            label: 'Formaté le',
            options: { filter: true, sort: true }
        },
        {
            name: 'stateLabel',
            label: 'État',
            options: { filter: true, sort: true }
        },
        {
            name: 'ownerName',
            label: 'Attribuer à',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Date de creation',
            options: { filter: true, sort: true }
        },
        {
            name: 'getAction',
            label: 'Action',
            options: { filter: true, sort: true }
        },
    ];

    const [form, setForm] = React.useState({
        doneAt: {
            name: 'doneAt',
            label: 'Date',
            textHelper: 'Réctification de la date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Réctification de l\'état de l\'équipement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Réctification du commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);

    const save = (row) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'it_infrastructure_equipment_history_edit',
                data: handler.getData(),
                params: { equipment: props.match.params.id, id: row.id }
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    props.loading()
                    setDialog(false); handler.reset()
                    dispatch(
                        SnackbarOpen({
                            text: 'Type modifié.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        }
    };

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { setDialog(true); setRow(row); handler.setDataApi(row) }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setHistoryId(row.id);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
                {
                    row.comment &&
                    <Tooltip title={'Note'} placement="left">
                        <IconButton onClick={() => {
                            setShowComment(true)
                            setComment(row.comment)
                        }}>
                            <Note />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    React.useEffect(() => {
        if (dialog) {
            Api.get({
                route: 'select_equipment_states'
            },
                (response) => {
                    if (response && response.data) {
                        setOptionEquipmentState(response.data);
                    }
                });

            Api.get({
                route: 'select_equipment_actions',
                data: { typeAction: null }
            },
                (response) => {
                    if (response && response.data) {
                        setOptionEquipmentActions(response.data);
                    }
                });
        }
    }, [dialog])

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'id', direction: 'desc' }}
                id={'it_infrastructure_equipment_history_list'}
                title={'Historique'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    if (props.match.params.id) {
                        let filters = {
                            limit: options.rowsPerPage,
                            offset: options.page * options.rowsPerPage
                        };
                        Api.get({
                            route: 'it_infrastructure_equipment_history_list',
                            params: { equipment: props.match.params.id },
                            data: filters,
                        },
                            (response) => {
                                if (response.data) {
                                    let data = response.data.data;
                                    let count = response.data.count;
                                    for (let index in data) {
                                        data[index].createdAt = moment(data[index].createdAt).format('ll');
                                        data[index].resetAt = data[index].resetAt ? moment(data[index].resetAt).format('ll') : '-';
                                        data[index].doneAt = data[index].doneAt ? moment(data[index].doneAt).format('ll') : '-';
                                        data[index].doneByName = data[index].doneByName ? data[index].doneByName : '-';
                                        data[index].ownerName = data[index].ownerName ? data[index].ownerName : '-';
                                        data[index].getAction = getAction(data[index]);
                                    };
                                    resolve(data, count);
                                }
                                else {
                                    resolve([], 0);
                                }
                            })
                    }
                    else {
                        resolve([], 0);
                    }
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => { setDialog(false); handler.reset(); setRow([]) }}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{'Editer l\'historique'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <DatePickerComponent name={'doneAt'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectComponent name={'state'} handler={handler} options={optionEquipmentState} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setDialog(false); setRow([]) }} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(row)} disabled={loading} />
                </DialogActions>
            </Dialog>

            <Dialog open={showComment} maxWidth={'lg'} onClose={() => setShowComment(false)}>
                <DialogTitle>Commentaire</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <p style={{ padding: '1rem' }}>{comment}</p>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => setShowComment(false)}/>
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cet historique ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                            route: 'it_infrastructure_equipment_history_delete',
                            params: { id: historyId }
                        },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            props.loading()
                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Historique supprimé.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('it_infrastructure_equipment_history_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
