import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import Task from "../Task";

function CommercialTask(props) {
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Agenda',
            context: 'CRM',
            description: 'Votre gestionnaire de tâches.',
            links: []
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <ShadowBoxComponent>
                <Task {...props}/>
            </ShadowBoxComponent>
            <br/>
        </ContentViewComponent>
    );
}

export default CommercialTask;
