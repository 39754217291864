const TableReducer = (state = {}, action) => {
    switch (action.type) {
        case 'TableReload':
            return {
                id: action.id,
                withCache: action.withCache
            };
        default:
            return state
    }
};

export default TableReducer;
