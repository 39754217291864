const MemoryReducer = (state = {}, action) => {
    switch (action.type) {
        case 'Memory':
            return {...state, ...action.data};
        default:
            return state
    }
};

export default MemoryReducer;
