import React from 'react';
import {makeStyles} from "@material-ui/core";
import {dispatch, theme} from "../../../App";
import Api from '../../../Api';
import {CircularProgress, Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {useSelector} from 'react-redux';
import {UpdateAuthentication} from '../../../Action/AuthenticationAction';
import {SnackbarOpen} from '../../../Action/SnackbarAction';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import CheckboxComponent from '../../../Component/CheckboxComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import {formHandlerInit} from '../../../Tool/FormHandlerCommon';

export default function Optin() {
    const classes = useStyles();
    const [loadConsent, setLoadConsent] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [form, setForm] = React.useState({conditions: {}});
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        setLoadConsent(true);
        let data = handler.getData();
        if (data.conditions) {
            Api.post({
                route: 'learner_cgu_consent',
                data: data
            }, (response) => {
                if (response.status === 200) {
                    dispatch(UpdateAuthentication({name: 'cgConsent', value: response.data.cgConsentAt}));
                    setLoadConsent(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }
    React.useEffect(() => {

        if (authenticationReducer && !authenticationReducer.cgConsent) {
            setOpenDialog(true);
        }
        else {
            setOpenDialog(false);
        }


        setForm({
            conditions: {
                name: 'conditions',
                label: <span style={{fontSize: 14}}>
                    En cochant la case ci-contre, je déclare avoir lu et accepté la déclaration de
                    <MediaLinkComponent
                        style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                        path={theme.dataProtection}
                        type={1}
                    > protection des données </MediaLinkComponent>
                    ainsi que les
                    <MediaLinkComponent
                        style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                        path={theme.termsConditions}
                        type={1}
                    > conditions générales </MediaLinkComponent>
                </span>,
                textHelper: '',
                type: 'boolean',
                defaultValue: false,
                options: {}
            }
        });
    }, [authenticationReducer, theme]);

    return (
        <Dialog open={openDialog} className={classes.popupCguConsent}>

            <AssignmentIcon style={{
                margin: '-25px auto auto auto',
                width: 30,
                height: 'auto',
                background: '#FFF',
                borderRadius: 100,
                fill: '#5E6E82',
                padding: 10
            }}/>
            <DialogTitle style={{color: '#5E6E82', textAlign: 'center', padding: '0px 24px'}}>
                Conditions générales
            </DialogTitle>
            <hr style={{border: '#5E6E82 1px solid', width: '80%', margin: 'auto'}}/>
            <DialogContent style={{position: 'relative', padding: '8px 25px 15px 25px'}}>
                <CheckboxComponent name={'conditions'} handler={handler} direction={'right'}/>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {
                        loadConsent ?
                            <CircularProgress style={{color: theme.colorPrimary}} size={30}/>
                            :
                            <ButtonComponent style={{background: theme.colorPrimary}} label={'Accepter'} onClick={save} disabled={handler.getData().conditions === false}/>
                    }
                </div>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles({
    popupCguConsent: {
        '& .MuiDialog-paperScrollPaper': {
            maxWidth: 660,
            overflowY: 'initial'
        },
        '& h2.MuiTypography-root': {
            fontSize: 29,
            fontWeight: 'bold',
            marginTop: -17,
        },
        '& .MuiIconButton-root': {
            borderRadius: '10%'
        }
    },
    icon: {
        fontSize: 30,
        padding: '0px 5px',
        verticalAlign: 'sub',
    }
});
