import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";
import TitleComponent from '../../../Component/TitleComponent';

export default function ANDPC(props = {}) {
    const dispatch = useDispatch();

    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [formANDPC, setFormANDPC] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Saisissez une année.',
            type: 'integer',
            defaultValue: '',
            options: {
                validation: ['required'],
                max: 2100,
                min: 1999
            }
        },
        code: {
            name: 'code',
            label: 'Code',
            textHelper: 'Saisissez un code.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez un organisme.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la durée totale de la formation (C+CV+HC en second).',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        priceHT: {
            name: 'priceHT',
            label: 'prix HT',
            textHelper: 'Saisissez un prix.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        durationFC: {
            name: 'durationFC',
            label: 'Durée FC',
            textHelper: 'Saisissez la durée de la formation continue.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        durationEpp1: {
            name: 'durationEpp1',
            label: 'Durée EPP temps 1',
            textHelper: 'Saisissez la durée EPP temps 1.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        durationEpp2: {
            name: 'durationEpp2',
            label: 'Durée EPP temps 2',
            textHelper: 'Saisissez la durée EPP temps 2.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        priceFcPerHour: {
            name: 'priceFcPerHour',
            label: 'Prix FC par heure',
            textHelper: 'Saisissez un prix FC par heure.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        priceEppPerHour: {
            name: 'priceEppPerHour',
            label: 'Prix EPP par heure',
            textHelper: 'Saisissez un prix EPP par heure.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        durationPre: {
            name: 'durationPre',
            label: 'Durée questionnaire Pre',
            textHelper: 'Saisissez une durée questionnaire Pre.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        durationPost: {
            name: 'durationPost',
            label: 'Durée questionnaire Post',
            textHelper: 'Saisissez une durée questionnaire Post.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        durationCV: {
            name: 'durationCV',
            label: 'Durée classe virtuelle',
            textHelper: 'Saisissez la durée de la classe virtuelle.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        priceCVPerHour: {
            name: 'priceCVPerHour',
            label: 'Prix classe virtuelle par heure',
            textHelper: 'Saisissez un prix classe virtuelle par heure.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        durationOffline: {
            name: 'durationOffline',
            label: 'Durée hors ligne',
            textHelper: 'Saisissez la durée hors ligne.',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        priceOfflinePerHour: {
            name: 'priceOfflinePerHour',
            label: 'Prix hors ligne par heure',
            textHelper: 'Saisissez un prix hors ligne par heure.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        andpcSupport: {
            name: 'andpcSupport',
            label: 'Prise en charge ANDPC',
            textHelper: 'Saisissez un prix de prise en charge par l\'Andpc.',
            type: 'float',
            defaultValue: 0,
            options: {}
        }
    });
    const [dialogANDPC, setDialogANDPC] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handlerANDPC = formHandlerInit(formANDPC, setFormANDPC);
    React.useEffect(handlerANDPC.start, []);

    const saveANDPC = () => {
        if (handlerANDPC.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerANDPC.setFormLoading(true);
            setLoading(true);
            let data = handlerANDPC.getData();

            Api.post({
                    route: 'content_manager_formation_andpc',
                    data: data,
                    params: {id: props.match.params.id}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setDialogANDPC(false);
                    handlerANDPC.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        dispatch(TableReload('content_manager_formation_andpc_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'ANDPC enregistré.',
                                variant: 'success',
                            })
                        );
                        handlerANDPC.reset();
                    } else if (response.status === 400) {
                        handlerANDPC.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        handlerANDPC.setValue('code', row.code);
                        handlerANDPC.setValue('year', row.year);
                        handlerANDPC.setValue('organism', row.organism);
                        handlerANDPC.setValue('duration', row.duration);
                        handlerANDPC.setValue('priceHT', row.priceHT);
                        handlerANDPC.setValue('durationFC', row.durationFC);
                        handlerANDPC.setValue('durationEpp1', row.durationEpp1);
                        handlerANDPC.setValue('durationEpp2', row.durationEpp2);
                        handlerANDPC.setValue('priceFcPerHour', row.priceFcPerHour);
                        handlerANDPC.setValue('priceEppPerHour', row.priceEppPerHour);
                        handlerANDPC.setValue('durationPre', row.durationPre);
                        handlerANDPC.setValue('durationPost', row.durationPost);
                        handlerANDPC.setValue('durationCV', row.durationCV);
                        handlerANDPC.setValue('priceCVPerHour', row.priceCVPerHour);
                        handlerANDPC.setValue('durationOffline', row.durationOffline);
                        handlerANDPC.setValue('priceOfflinePerHour', row.priceOfflinePerHour);
                        handlerANDPC.setValue('andpcSupport', row.andpcSupport);
                        setDialogANDPC(true);
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const columnsANDPCs = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'code',
            label: 'Code',
            options: {filter: true, sort: true}
        },
        {
            name: 'year',
            label: 'Année',
            options: {filter: true, sort: true}
        },
        {
            name: 'organismName',
            label: 'Organisme',
            options: {filter: true, sort: true}
        },
        {
            name: 'duration',
            label: 'Durée',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceHT',
            label: 'Prix HT',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceFcPerHour',
            label: 'Prix par heure FC',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceEppPerHour',
            label: 'Prix par heure EPP',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationFC',
            label: 'Durée FC',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationEpp1',
            label: 'Durée Epp temps 1',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationEpp2',
            label: 'Durée Epp temps 2',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationPre',
            label: 'Durée questionnaire Pre',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationPost',
            label: 'Durée questionnaire Post',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationCV',
            label: 'Durée classe virtuelle',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceCVPerHour',
            label: 'Prix par heure classe virtuelle',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationOffline',
            label: 'Durée hors ligne',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceOfflinePerHour',
            label: 'Prix par heure hors ligne',
            options: {filter: true, sort: true}
        },
        {
            name: 'andpcSupport',
            label: 'Prise en charge par l\'Andpc',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    React.useEffect(() => {
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, [])

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'id', direction: 'desc'}}
                id={'content_manager_formation_andpc_list'}
                title={'ANDPC'}
                columns={columnsANDPCs}
                actionFirst={{
                    label: 'Ajouter ou éditer un code ANDPC',
                    onClick: () => {
                        handlerANDPC.reset();
                        setDialogANDPC(true)
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_formation_andpc_list',
                            params: {id: props.match.params.id}
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).fromNow();
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialogANDPC} maxWidth={'xl'} onClose={() => setDialogANDPC(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter ou éditer un code ANDPC</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>

                    <TitleComponent title={'Globale'}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'year'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'code'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SelectComponent name={'organism'} handler={handlerANDPC} options={optionOrganism}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'duration'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'priceHT'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'andpcSupport'} handler={handlerANDPC}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <TitleComponent title={'Formation continue e-learning'}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'priceFcPerHour'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'durationFC'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'durationPre'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                            <TextFieldComponent name={'durationPost'} handler={handlerANDPC}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <TitleComponent title={'Hors ligne'}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'priceOfflinePerHour'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'durationOffline'} handler={handlerANDPC}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <TitleComponent title={'EPP'}/>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'priceEppPerHour'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'durationEpp1'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'durationEpp2'} handler={handlerANDPC}/>
                        </Grid>
                    </Grid>
                    <br/>
                    <TitleComponent title={'Classe virtuelle'}/>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'priceCVPerHour'} handler={handlerANDPC}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'durationCV'} handler={handlerANDPC}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogANDPC(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => saveANDPC()} disabled={loading}/>
                </DialogActions>
            </Dialog>
        </>
    )
}
