import React from 'react';
import { theme } from "../../../../../App";
import moment from "moment";
import { Box, makeStyles } from '@material-ui/core';

function GroupPostComponent(props) {
    const classes = useStyles(theme)();
    const [loadImg, setLoadImg] = React.useState(false);
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        setData(props.data)
    }, [props.data]);
    return (
        <Box className={classes.boxComponent}>
            <Box className={classes.cardImage}>
                {!loadImg && <img src="/asset/images/preload_image.png" className={classes.cardImageLoad} alt={'Image formation'} />}
                <img src={data.image ? "/api/content-manager/formation/image/" + data.image : "/asset/images/discussion_group.webp"} className={classes.cardImageFormation} alt={'Image formation'} onLoad={() => setLoadImg(true)} />
            </Box>
            <p style={{ fontWeight: 700, fontSize: 22, margin: "0", padding: "10px 0 0 0", overflow: "hidden", textOverflow: "ellipsis", width: "98%" }}>{data.name}</p>
            <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} > Groupe créer le {moment(data.createdAt).format("lll")}</p>
            <p style={{ fontWeight: 400, fontSize: 17, margin: "0", padding: "10px 0" }}>{data.numGroup > 99 ? '99+' : data.numGroup} <span>Membres,</span> {data.numPost > 99 ? '99+' : data.numPost} <span>Postes</span></p>
        </Box >
    );
}

const useStyles = (theme) => makeStyles(({
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
    },
    cardImageLoad: {
        zIndex: 1,
    },
    cardImage: {
        margin: 'auto',
        borderRadius: 10,
        position: 'relative',
        width: '100%',
        border: '3px solid rgb(223, 223, 223)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            display: 'block',
        }
    },
}));

export default GroupPostComponent;
