import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Grid, IconButton, Tooltip } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TableComponent from '../../../Component/TableComponent';
import {Delete, Edit} from '@material-ui/icons';
import { TableReload } from '../../../Action/TableAction';
import {dispatch} from "../../../App";
import SweetAlert from "sweetalert-react";
import moment from "moment/moment";

const EquipmentBrandList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [row, setRow] = React.useState([]);
    const [isEdition, setIsEdition] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [brandId, setBrandId] = React.useState(null);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);

    const save = (row) => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'it_infrastructure_equipment_brand_edit',
                    data: handler.getData(),
                    params: { id: row.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(TableReload('it_infrastructure_equipment_brand_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Marque modifié.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'it_infrastructure_equipment_brand_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        dispatch(TableReload('it_infrastructure_equipment_brand_list'));
                        setIsEdition(false); setDialog(false); handler.reset()
                        dispatch(
                            SnackbarOpen({
                                text: 'Marque enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { setIsEdition(true); setRow(row); setDialog(true) }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setBrandId(row.id);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des marques d\'équipements',
            context: 'Parc informatique',
            description: ''
        });

        setReadyContent(true);

        if (row.id) {
            handler.setDataApi(row);
        }
    }, [row]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'it_infrastructure_equipment_brand_list'}
                title={'Liste des marques d\'équipements'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une marque d\'équipement',
                    onClick: () => { setDialog(true); setIsEdition(false); }
                }}
                promiseServerData={(resolve, options) => {
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                    };
                    Api.get({
                            route: 'it_infrastructure_equipment_brand_list',
                            data: filters,
                        },
                        (response) => {
                            if (response.status === 200) {
                                let data = response.data.data;
                                let count = response.data.count;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data, count);
                            } else resolve([]);
                        });
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => { setIsEdition(false); setDialog(false); handler.reset() }}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{isEdition ? 'Editer une marque' : 'Ajouter une marque'}</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setIsEdition(false); setDialog(false) }} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => save(row)} disabled={loading} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette marque ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                            route: 'it_infrastructure_equipment_brand_delete',
                            params: { id: brandId }
                        },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Marque supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('it_infrastructure_equipment_brand_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>

    );
};
const useStyles = makeStyles({});

export default EquipmentBrandList;


