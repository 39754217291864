import React from 'react';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import TableComponent from '../../../Component/TableComponent';
import Api, { LibraryResourceTypeIcon, LibraryResourceTypeImage } from "../../../Api";
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import moment from "moment";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import ButtonComponent from '../../../Component/ButtonComponent';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from "sweetalert-react";
import UploadComponent from '../../../Component/UploadComponent';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';

const LibraryResourceTypeList = (props) => {

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [typeId, setTypeId] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "organismText",
            label: "Organism",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupText",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Nom',
            textHelper: 'Saisissez le nom du type.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        icon: {
            name: 'icon',
            label: 'Icone du type de ressource',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        image: {
            name: 'image',
            label: 'Image du type de ressource',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez les organismes du type.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Saisissez les Groupes de formation du type.',
            type: 'array',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setTypeId(row.id);
                        setShow(true);
                        handler.setDataApi(row);
                        handler.setValue('organism', row.organismId);
                        handler.setValue('formationGroup', row.formationGroupId);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setTypeId(row.id);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des types de ressources',
            context: 'Gestion marketing',
            description: ''
        });

        Api.get({
            route: 'select_organisms',
            data: {'isFull': true}
        },
        (response) => {
            if (response && response.data) {
                setOptionOrganism(response.data);
            }
        });

        Api.get({
            route: 'select_formation_groups',
        },
        (response) => {
            if (response && response.data) {
                setOptionFormationGroup(response.data);
            }
        });

        setReadyContent(true);
    }, []);


    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            let datas = handler.getData();
            if (typeId) {
                Api.post({
                    route: 'marketing_manager_library_resource_type_edit',
                    data: datas,
                    params: { id: typeId },
                    file: {
                            icon: datas.icon ? datas.icon : '',
                            image: datas.image ? datas.image : ''
                        }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Type de ressource modifié.',
                                variant: 'success',
                            })
                        );
                        setTypeId(null);
                        setShow(false);
                        dispatch(TableReload('marketing_manager_library_resource_type_list'));
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'marketing_manager_library_resource_type_add',
                    data: datas,
                    file: {
                        icon: datas.icon ? datas.icon : '',
                        image: datas.image ? datas.image : ''
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Type de ressource enregistrée.',
                                variant: 'success',
                            })
                        );
                        setTypeId(null);
                        setShow(false);
                        dispatch(TableReload('marketing_manager_library_resource_type_list'));
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <TableComponent
                id={'marketing_manager_library_resource_type_list'}
                title={'Types des ressources'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un type de ressource',
                    onClick: () => {setShow(true); handler.reset()}
                }}

                promiseData={(resolve) => {
                    Api.get({
                        route: 'marketing_manager_library_resource_type_list'
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('ll');
                            }
                            resolve(data);
                        });
                }}
            />

            <Dialog open={show} fullWidth={true}>
                <DialogTitle style={{ fontSize: 15 }}>{typeId ? 'Modifier un type' : 'Ajouter un type'}</DialogTitle>
                <DialogContent>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'label'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <MultipleSelectComponent name={'organism'} handler={handler} options={optionOrganism}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MultipleSelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <UploadComponent name={'icon'} handler={handler} accept=".svg" preview={LibraryResourceTypeIcon + typeId + '.svg'}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <UploadComponent name={'image'} handler={handler} accept=".png" preview={LibraryResourceTypeImage + typeId + '.png'}/>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => {setTypeId(null); setShow(false)}} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer ce type de ressource ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'marketing_manager_library_resource_type_delete',
                        params: { id: typeId }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'type de ressource supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('marketing_manager_library_resource_type_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </ContentViewComponent>
    );
};

export default LibraryResourceTypeList;
