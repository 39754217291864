export function inArray(string, array) {
    return (array.indexOf(string) > -1)
}

export function removeElementInArray(array, element) {
    const index = array.indexOf(element);
    if (index > -1) array.splice(index, 1);
    return [...array];
}

