import React from 'react';
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import TableComponent from "../../../Component/TableComponent";
import moment from "moment";

function ActivityFIFPL(props) {
    const columnsProspectActivities = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'description',
            label: 'Description',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: 'Date de création',
            options: {filter: true, sort: true}
        }
    ];

    return (
        <>
            <Dialog open={props.open} maxWidth={'lg'}>
                <DialogContent style={{padding: 0, marginBottom: 15}}>
                    <TableComponent
                        noCache={true}
                        sortOrder={{name: 'id', direction: 'desc'}}
                        id={'crm_prospect_prospect_activity_list'}
                        title={'Historique des activités'}
                        columns={columnsProspectActivities}
                        promiseData={(resolve) => {
                            if (props.sessionFormation && props.sessionFormation.activities) {
                                let data = props.sessionFormation.activities;
                                for (let index in data) data[index].createdAt = moment(data[index].createdAt).format('lll');
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent onClick={props.close} color={'#5E6E82'} label={'Fermer'}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ActivityFIFPL;
