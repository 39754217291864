import React, { Fragment } from 'react';
import Card from './Component/Card';
import { LinearProgress as LinearProgressMUI } from "@material-ui/core";
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { Box, Grid, makeStyles, Drawer as DrawerMUI, Fade, } from '@material-ui/core';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import SelectComponent from '../../../Component/SelectComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import TitleComponent from '../../../Component/TitleComponent';
import FilterListIcon from '@material-ui/icons/FilterList';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FilterPaginationComponent from '../../../Component/FilterPaginationComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch, theme } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { useSelector } from 'react-redux';
import { hasRight } from '../../../Tool/UserTool';
import Api from '../../../Api';
import RelaunchOffUser from './RelaunchOffUser';
import RelaunchAddTask from './RelaunchAddTask';
import RelaunchTask from './RelaunchTask';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import PaginationComponent from '../../../Component/PaginationComponent';

const Relaunch = (props) => {
    const classes = useStyles(theme)();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isOpenOffuser, setIsOpenOffuser] = React.useState(false);
    const [isOpenAddTask, setIsOpenAddTask] = React.useState(false);
    const [isOpenTask, setIsOpenTask] = React.useState(false);
    const [load, setLoad] = React.useState(true);
    const [loadingProspect, setLoadingProspect] = React.useState(false);
    const [taskId, setTaskId] = React.useState(null);
    const isAdmin = hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_WEBCOACH_ADMIN');
    const [prospectList, setProspectList] = React.useState([]);
    const [prospectCount, setProspectCount] = React.useState(0);
    const [prospectId, setProspectId] = React.useState(null);
    const [optionStates, setOptionStates] = React.useState([]);
    const [offset, setOffset] = React.useState(0);

    const [optionFilterWebcoachs, setOptionFilterWebcoachs] = React.useState([]);
    const [optionWebcoachs, setOptionWebcoachs] = React.useState([]);
    const [optionTypes, setOptionTypes] = React.useState([]);
    const [optionResolutionTexts, setOptionResolutionTexts] = React.useState([]);

    const [tasks, setTasks] = React.useState([]);

    const viewLateTasks = (task) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 2 }}>
                <Card
                    task={task}
                    key={task.id}
                    color={'#FFEEDA'}
                    setTaskId={setTaskId}
                    setIsOpen={setIsOpenTask}
                    loading={load}
                    setLoading={setLoad}
                    getLoadList={getRelaunchList}
                    {...props}
                />
            </Grid>
        )
    }

    const viewListTasks = (task) => {
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 2 }}>
                <Card
                    task={task}
                    key={task.id}
                    color={'#FFFFFF'}
                    setTaskId={setTaskId}
                    setIsOpen={setIsOpenTask}
                    loading={load}
                    setLoading={setLoad}
                    getLoadList={getRelaunchList}
                    {...props}
                />
            </Grid>
        )
    }

    // Form Search
    const [formSearch, setFormSearch] = React.useState({
        startTodoAt: {
            name: 'startTodoAt',
            label: 'Début',
            textHelper: 'Sélectionnez une date de début.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
        endTodoAt: {
            name: 'endTodoAt',
            label: 'Fin',
            textHelper: 'Sélectionnez une date de fin.',
            type: 'date',
            defaultValue: '',
            options: {}
        },
        webCoachId: {
            name: 'webCoachId',
            label: 'Webcoach',
            textHelper: 'Sélectionnez un webcoach',
            type: 'text',
            defaultValue: isAdmin ? 'all' : authenticationReducer.id,
            options: {}
        },
        state: {
            name: 'state',
            label: 'Statut',
            textHelper: 'Sélectionnez un statut',
            type: 'text',
            defaultValue: 'listTodayTasks',
            options: {}
        }
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        if ((handlerSearch.getData().startTodoAt >= handlerSearch.getData().endTodoAt) && handlerSearch.getData().endTodoAt) {
            handlerSearch.setError('endTodoAt', 'La date de fin doit être supérieure à la date de début')
        } else {
            handlerSearch.setError('endTodoAt', '')
            getRelaunchList()
        }
    };

    const filter = () => {
        return {
            state: formSearch.state.value ? formSearch.state.value : '',
            startTodoAt: formSearch.startTodoAt.value ? formSearch.startTodoAt.value : '',
            endTodoAt: formSearch.endTodoAt.value ? formSearch.endTodoAt.value : '',
            webCoachId: (formSearch.webCoachId.value === 'all') ? '' : formSearch.webCoachId.value,
        }
    };

    // Form Search
    const [formSearchProspect, setFormSearchProspect] = React.useState({
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: 'Saisissez votre recherche',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handlerSearchProspect = formHandlerInit(formSearchProspect, setFormSearchProspect);
    React.useEffect(handlerSearchProspect.start, []);

    const viewProspect = (prospect) => {
        return (
            <ShadowBoxComponent style={{ display: 'flex', flexDirection: 'column', gap: 10, width: '100%', height: 'fit-content' }}>
                <p style={{ margin: 0, lineHeight: '15px', fontSize: 14 }}>
                    <span style={{ fontSize: 12 }}>Contact :</span><br />
                    <b>{prospect.fullName ? prospect.fullName : '-'}</b><br />
                    <span style={{ fontSize: 13 }}><b>Mail :</b> {prospect.email ? prospect.email : '-'}</span><br />
                    <span style={{ fontSize: 12 }}><b>{prospect.organism}</b></span>
                </p>
                <ButtonComponent label={!prospect.hasRegistrations ? 'Aucune inscription' : 'Créer une tâche'} onClick={() => { setIsOpenAddTask(true); setProspectId(prospect.id) }} disabled={!prospect.hasRegistrations || load} />
            </ShadowBoxComponent>
        )
    }

    const inputSearchChangeProspect = (index, value) => {
        handlerSearchProspect.setValue(index, value);
        setLoadingProspect(true);
    };

    const getRelaunchList = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoad(true);
        Api.get({
            route: 'webcoach_relaunch_list',
            data: filter()
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            setLoad(false);
            if (response.status === 200) {
                let data = response.data;
                setTasks(data);
                setOptionStates(data.viewsLabel)
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error,
                        variant: 'error',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }


    React.useEffect(() => {
        if (formSearchProspect.text.value) {
            Api.get({
                route: 'webcoach_relaunch_prospects',
                data: {
                    text: formSearchProspect.text.value ? formSearchProspect.text.value : null,
                    limit: 10,
                    offset: offset
                }
            }, (response) => {
                setLoadingProspect(false);
                if (response.status === 200) {
                    let data = response.data;
                    setProspectList(data.data)
                    setProspectCount(data.count)
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error,
                            variant: 'error',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        } else {
            setLoadingProspect(false);
            setProspectList([])
            setProspectCount(0)
        }
    }, [formSearchProspect.text.value , offset]);

    React.useEffect(() => {
        getRelaunchList()
    }, []);

    React.useEffect(() => {
        if (!isOpenAddTask) {
            setProspectId(null)
        }
    }, [isOpenAddTask]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Interface des relances',
            context: 'Webcoach',
            description: 'Liste des tâches.'
        });

        setTimeout(() => {
            setReadyContent(true);
        }, 3000);

        Api.get({
            route: 'select_webcoach_task_types'
        },
            (response) => {
                if (response && response.data) {
                    setOptionTypes(response.data);
                }
            });

        Api.get({
            route: 'select_webcoachs'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFilterWebcoachs(isAdmin ? ([...[{ value: 'all', label: 'Tous' }], ...response.data]) : response.data);
                    setOptionWebcoachs(response.data);
                }
            });

        Api.get({
            route: 'select_webcoach_task_resolution_texts'
        },
            (response) => {
                if (response && response.data) {
                    setOptionResolutionTexts(response.data);
                }
            });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2} direction='row-reverse'>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Box style={{ position: 'sticky', top: 30, display: 'flex', flexDirection: 'column', gap: 15 }}>
                        <Fade in={true} {...{ timeout: 750 }}>
                            <ShadowBoxComponent style={{ fontSize: 17, padding: '12px 30px' }}>
                                <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>Vue d'ensemble</p>
                                <p style={{ margin: 0 }}><span style={{ fontSize: 24 }}>{tasks.countTodayTasks}</span> tâches aujourd'hui</p>
                                <p style={{ margin: 0 }}><span style={{ fontSize: 24 }}>{tasks.countLateTasks}</span> tâches en retard</p>
                                <p style={{ margin: 0 }}><span style={{ fontSize: 24 }}>{tasks.countWeekTasks}</span> tâches cette semaine</p>
                                <p style={{ margin: 0 }}><span style={{ fontSize: 24 }}>{tasks.countNextWeekTasks}</span> tâches la semaine prochaine</p>

                                <div className={classes.containerBtn}>
                                    {((isAdmin && (formSearch.webCoachId.value !== 'all')) || (authenticationReducer.id === parseInt(formSearch.webCoachId.value))) &&
                                        <ButtonComponent label={'Absence'} onClick={() => setIsOpenOffuser(true)} disabled={load} />}
                                </div>
                            </ShadowBoxComponent>
                        </Fade>
                        {((authenticationReducer.id === parseInt(formSearch.webCoachId.value)) || isAdmin) &&
                            <Fade in={true} {...{ timeout: 750 }}>
                                <ShadowBoxComponent className={classes.styleProspect}>
                                    <LinearProgressMUI className={`${classes.linearProgress}`} hidden={!loadingProspect} />
                                    <p style={{ margin: 0, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }}>{isAdmin ? 'Contacts' : 'Vos Contacts'}</p>
                                    <Box style={{ margin: '0 auto', width: '90%', display: 'flex' }}>
                                        <TextFieldComponent name={'text'} handler={handlerSearchProspect} onChange={(val) => inputSearchChangeProspect('text', val)} />
                                    </Box>
                                    <PaginationComponent
                                        dataList={prospectList}
                                        pageNumber={10}
                                        offset={(value) => setOffset(value)}
                                        count={prospectCount}
                                        viewList={viewProspect}
                                        error={'Aucun contact'}
                                    />
                                </ShadowBoxComponent>
                            </Fade>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent>
                                <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                                <Grid container spacing={2}>

                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                        <DatePickerComponent name={'startTodoAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startTodoAt', val)} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                        <DatePickerComponent name={'endTodoAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endTodoAt', val)} />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                        <SelectComponent name={'webCoachId'} handler={handlerSearch} options={optionFilterWebcoachs} onChange={(val) => inputSearchChange('webCoachId', val)} />
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={3} lg={3}>
                                        <SelectComponent name={'state'} handler={handlerSearch} options={optionStates} onChange={(val) => inputSearchChange('state', val)} />
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>

                        {(tasks.views && optionStates) &&
                            optionStates.map((data, index) => (
                                ((formSearch.state.value === data.value) || ((formSearch.state.value === 'listTodayTasks') && ((data.value === 'listLateTasks') || (data.value === 'listTodayTasks')))) &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={index} >
                                    <FilterPaginationComponent
                                        data={tasks.views[data.value]}
                                        title={data.label}
                                        icon={<AssignmentIcon />}
                                        pageNumber={3}
                                        viewList={data.value === 'listLateTasks' ? viewLateTasks : viewListTasks}
                                        loading={load}
                                        error={'Aucune Tâche'}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Fragment>
                <DrawerMUI className={classes.subMenu} anchor={'right'} open={isOpenTask} onClose={() => setIsOpenTask(false)}>
                    <Grid container spacing={3} style={{ width: '80%', margin: '0 auto', height: '100%' }}>
                        <RelaunchTask
                            taskId={taskId}
                            setIsOpen={setIsOpenTask}
                            getLoadList={getRelaunchList}
                            options={optionResolutionTexts}
                            {...props}
                        />
                    </Grid>
                </DrawerMUI>
            </Fragment>
            <RelaunchAddTask
                options={optionTypes}
                userId={formSearch.webCoachId.value}
                prospectId={prospectId}
                isOpen={isOpenAddTask}
                getLoadList={getRelaunchList}
                setIsOpen={setIsOpenAddTask}
                isAdmin={isAdmin}
                {...props}
            />
            <RelaunchOffUser
                options={optionWebcoachs}
                userId={formSearch.webCoachId.value}
                isOpen={isOpenOffuser}
                setIsOpen={setIsOpenOffuser}
                isAdmin={isAdmin}
                {...props}
            />

        </ContentViewComponent >
    );
}

const useStyles = (theme) => makeStyles({
    containerBtn: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 20,
        '& > button': {
            margin: 5
        }
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 600,
            width: 600,
        }
    },
    styleProspect: {
        fontSize: 17,
        height: 600,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: 10,
        '& .listPagination-root': {
            display: 'flex',
            flexDirection: 'row',
            gap: 15,
            padding: '16px 20px',
            overflow: 'auto',
            height: '100%',
            width: 'auto !important',
            margin: '0 12px',
            background: '#EFF2F4',
            borderRadius: 10
        },
        '& .error-root': {
            margin: 'auto',
            fontSize: 20,
            opacity: 0.3
        }
    },
    linearProgress: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 2,
        left: 0,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
});

export default Relaunch;
