import React from 'react';
import { Tooltip } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';

function CountdownEpp2(props) {
    const [countdown, setCountdown] = React.useState(props.duration - props.time);

    React.useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
        else { props.isFinish() }
    }, [countdown]);

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const seconds = time % 60;

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}>
            <Tooltip title="Temps avant de valider votre grille d'évaluation 2">
                <TimerIcon />
            </Tooltip>
            {formatTime(countdown)}
        </div>
    );
}

export default CountdownEpp2;
