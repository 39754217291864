import React from 'react';
import { useSelector } from "react-redux";
import { getRouteByPathname } from "../Config/Route";
import Api from "../Api";
import { hasRight } from "../Tool/UserTool";
import SweetAlert from "sweetalert-react";

let ticTimeout;
let ticCount = 0;

function TicComponent() {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const seconds = 30;
    const secondsConfirm = 14400;

    const sendTic = () => {
        if (showConfirm) return;

        // Check userAgent
        let userAgent = '';
        if (window && window.navigator && window.navigator.userAgent) {
            userAgent = window.navigator.userAgent.toLowerCase();
        }

        let navigator = 'Inconnu';
        if (/chrome|crios/i.test(userAgent)) {
            navigator = "Google Chrome";
        } else if (/firefox|fxios/i.test(userAgent)) {
            navigator = "Mozilla Firefox";
        } else if (/safari/i.test(userAgent) && !/chrome|crios/i.test(userAgent)) {
            navigator = "Safari";
        } else if (/opera|opr/i.test(userAgent)) {
            navigator = "Opera";
        } else if (/edge|edg/i.test(userAgent)) {
            navigator = "Microsoft Edge";
        } else if (/msie|trident/i.test(userAgent)) {
            navigator = "Internet Explorer";
        }

        let device = 'Inconnu';
        if (/mobile|tablet|ipad|iphone|android/i.test(userAgent)) {
            device = 'Appareil Mobile / Tablette';
        } else if (/macintosh|mac os x/i.test(userAgent)) {
            device = 'Mac';
        } else if (/windows|win32/i.test(userAgent)) {
            device = 'Windows';
        } else if (/linux/i.test(userAgent)) {
            device = 'Linux';
        }
        // Check userAgent

        ticCount += seconds;
        let params = window.location.href.split('/');
        let parser = document.createElement('a');
        parser.href = window.location.href;
        let route = (getRouteByPathname(parser.pathname));

        if ((route.id && route.id.startsWith("learner_registration"))) {

            // Course
            if (params[6] === 'course') {
                Api.post({
                    route: 'learner_timer_tic',
                    data: {
                        registration: params[5],
                        type: 'COURSE',
                        course: params[7],
                        navigator: navigator, device: device
                    }
                }, () => { });
                // Survey pre
            } else if (params[params.length - 1] === 'pre' && params[params.length - 2] === 'survey-pre-post') {
                Api.post({
                    route: 'learner_timer_tic',
                    data: {
                        registration: params[5],
                        type: 'PRE',
                        navigator: navigator,
                        device: device
                    }
                }, () => { });
                // Survey post
            } else if (params[params.length - 1] === 'post' && params[params.length - 2] === 'survey-pre-post') {
                Api.post({
                    route: 'learner_timer_tic',
                    data: {
                        registration: params[5],
                        type: 'POST',
                        navigator: navigator,
                        device: device
                    }
                }, () => { });
                // Epp 1 Quiz
            } else if (params[params.length - 1] === 'pre' && params[params.length - 2] === 'survey-epp') {
                Api.post({
                    route: 'learner_timer_tic',
                    data: {
                        registration: params[5],
                        type: 'EPP1_QUIZ',
                        navigator: navigator,
                        device: device
                    }
                }, () => { });
                // Epp 2 Quiz
            } else if (params[params.length - 1] === 'post' && params[params.length - 2] === 'survey-epp') {
                Api.post({
                    route: 'learner_timer_tic',
                    data: {
                        registration: params[5],
                        type: 'EPP2_QUIZ',
                        navigator: navigator,
                        device: device
                    }
                }, () => { });
            } else Api.post({
                route: 'learner_timer_tic',
                data: {
                    registration: params[5],
                    navigator: navigator,
                    device: device
                }
            }, () => { });

            // Timer Tic Epp 2
            if (params[params.length - 1] === 'post' && params[params.length - 2] === 'survey-epp') {
                Api.post({ route: 'learner_timer_tic_epp2', data: { registration: params[params.length - 3] } }, () => { });
            }
        } else {
            Api.post({ route: 'learner_timer_tic', data: { navigator: navigator, device: device } }, () => { });
        }

        if (ticCount > secondsConfirm) {
            setShowConfirm(true);
        }
    }

    React.useEffect(() => {
        if (ticTimeout) clearInterval(ticTimeout);
        if (hasRight(authenticationReducer, 'ROLE_LEARNER') && !authenticationReducer.isLoginAs) {
            sendTic();
            ticTimeout = setInterval(sendTic, 1000 * seconds);
        }
    }, [showConfirm, authenticationReducer]);

    return (
        <>
            <SweetAlert
                show={showConfirm}
                title={'Êtes-vous toujours là ?'}
                text={
                    'Afin de vous assurer une expérience d\'apprentissage optimale, nous aimerions savoir si vous êtes toujours présent et actif. \n\n' +
                    'Veuillez cliquer sur le bouton ci-dessous pour confirmer votre présence.'
                }
                type={'warning'}
                confirmButtonText={'Je suis toujours là'}
                showCancelButton={false}
                onConfirm={() => {
                    ticCount = 0;
                    setShowConfirm(false);
                }}
            />
        </>
    );
}

export default TicComponent;
