import React from 'react';
import { Box, IconButton, makeStyles } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import ButtonComponent from "../../../../Component/ButtonComponent";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { theme } from "../../../../App";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from "moment";
import Popover from '@material-ui/core/Popover';

function CardCommunityPostV2(props) {
    const classes = useStyles(theme)();
    const dataList = props.data;
    const propsData = props.propsData;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const viewAction = (row) => {
        return (
            <div className={classes.boxOrganism}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <MoreVertIcon style={{ color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {props.getAction(row, handleClose)}
                </Popover>
            </div>
        )
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box style={{ color: "#5E6E82", borderBottom: "3px solid rgb(223, 223, 223)", textAlign: "left", padding: '2px 0px' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box style={{ display: 'flex' }}>
                            <Box style={{ padding: '0px' }}>
                                <p style={{ margin: 0, fontSize: 13, fontWeight: "bold", color: "#495666" }}>{dataList.title}</p>
                                <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                    <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%) scaleX(-1)" }} /> post créer le {moment(dataList.createdAt).format("lll")}
                                </p>
                            </Box>
                            <Box style={{ margin: '0 0 0 auto' }}>{viewAction(dataList)}</Box>
                        </Box>
                        <p className={classes.subTitle}
                            style={{
                                margin: 0,
                                fontSize: 13,
                                padding: '10px 0 0 0px',
                                whiteSpace: 'normal',
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                            }}> {dataList.subTitle ? dataList.subTitle.replace(/(<([^>]+)>)/gi, "") : ""} </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "0px", display: 'flex' }} >
                            <p style={{ margin: 0, textAlign: "left", alignContent: 'center', display: 'grid' }}>{dataList.numComment > 99 ? '99+' : dataList.numComment} Commentaires</p>
                            {(dataList.numUnseenComment != 0) && <Box style={{ width: 'auto', height: 22, margin: "auto 0px auto 10px", background: '#dc3545', borderRadius: 5, color: '#FFFFFF', display: 'flex', alignItems: 'center', padding: '0 10px', }}>
                                <b>{dataList.numUnseenComment > 99 ? '99+' : dataList.numUnseenComment}</b><NotificationsActiveIcon style={{width: 14, padding: '0 0 0 4px'}}/></Box>}
                            <ButtonComponent
                                small={true}
                                color='#03a9f4'
                                style={{ margin: "10px 0px 10px auto", display: "block" }}
                                className={classes.buttonPost}
                                label={'Ouvrir'}
                                onClick={() => props.openPost(dataList)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <br />
        </Grid>
    );
}

const useStyles = (theme) => makeStyles(({
    boxPosts: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
}));

export default CardCommunityPostV2;
