import React from 'react';
import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import DatePickerComponent from "../../../Component/DatePickerComponent";

function Invoice(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [countRegistration, setCountRegistration] = React.useState(false);
    const [form, setForm] = React.useState({});
    const [formInfo, setFormInfo] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [loadingGetTime, setLoadingGetTime] = React.useState(false);
    const [registrationTime, setRegistrationTime] = React.useState();

    const Loader = () => {
        return (
            <span className={classes.loader}>
                <span className={`${classes.dot} ${classes.dot1}`}>.</span>
                <span className={`${classes.dot} ${classes.dot2}`}>.</span>
                <span className={classes.dot}>.</span>
            </span>
        )
    };

    React.useEffect(() => {

        let registrations = props.sessionFormation.registrations;
        let count = 0;
        let newForm = {};
        let newFormInfo = {};

        for (let index in registrations) {
            if ((registrations[index].state === 1 || registrations[index].state === 5 || registrations[index].state === 6)) {
                count++;
                newForm[count] = {
                    name: count,
                    label: 'Prix unitaire',
                    textHelper: 'Saisissez un prix unitaire',
                    type: 'float',
                    defaultValue: '',
                    value: props.sessionFormation.price * registrations[index].completionInvoice.props.children.props.value / 100,
                    options: {validation: ['required']}
                };

                newFormInfo[count] = {
                    id: registrations[index].id,
                    completion: registrations[index].completionInvoice.props.children.props.value,
                    firstname: registrations[index].firstname,
                    lastname: registrations[index].lastname,
                };
            }
            setForm({...form, ...newForm});
            setFormInfo({...formInfo, ...newFormInfo});
            setCountRegistration(count);
        }
    }, [countRegistration]);

    React.useEffect(() => {
        setLoadingGetTime(true);
        Api.get({
                route: 'financial_management_billing_session_formation_get_time',
                params: {id: props.sessionFormation.id}
            },
            (response) => {
                if (response.status === 200) {
                    setRegistrationTime(response.data);
                    setLoadingGetTime(false);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    const [formAt, setFormAt] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: props.invoiceAt,
            options: {validation: ['required', 'date']}
        }
    });

    const handler = formHandlerInit(form, setForm);
    const handlerAt = formHandlerInit(formAt, setFormAt);

    const getRender = () => {
        if (form && formInfo) {
            let render = [];
            for (let index in form) {
                let rt = {};

                for (let index2 in registrationTime) {
                    if (formInfo[index].id === registrationTime[index2].id) {
                        rt = registrationTime[index2];
                        break;
                    }
                }

                render.push(
                    <TableRow key={index}>
                        <TableCell className={classes.td}>{formInfo[index].id}</TableCell>
                        <TableCell className={classes.td}>{formInfo[index].firstname} {formInfo[index].lastname}</TableCell>
                        <TableCell style={{color: '#3f51b5'}} className={classes.td}>{loadingGetTime ? <Loader/> : rt.timeFC}</TableCell>
                        <TableCell style={{color: '#3f51b5'}} className={classes.td}>{loadingGetTime ? <Loader/> : rt.timeEpp}</TableCell>
                        <TableCell style={{color: '#3f51b5'}} className={classes.td}>{loadingGetTime ? <Loader/> : rt.timeCV}</TableCell>
                        <TableCell className={classes.td}><TextFieldComponent name={index} handler={handler}/></TableCell>
                    </TableRow>
                );
            }
            return render;
        }
        else {
            return <></>;
        }
    };
    const calcTotal = () => {
        let data = handler.getData();
        let total = 0;
        for (let index in data) {
            total = total + data[index]++;
        }
        setTotal((Number(total) === total) ? total.toFixed(2) : 0);
    };
    const save = () => {
        if (handler.checkError() || handlerAt.checkError()) {
            console.log('Error');
        }
        else {

            let data = handler.getData();
            let dataAt = handlerAt.getData();
            let dataSave = [];

            for (let index in data) {
                dataSave.push({'price': form[index].value, 'id': formInfo[index].id});
            }

            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                    route: 'financial_management_billing_session_formation_invoice_v2',
                    params: {id: props.sessionFormation.id},
                    data: {lines: dataSave, 'invoiceAt': dataAt.invoiceAt}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        dispatch(TableReload('billing_registration_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('billing_registration_list'));
                        const url = Api.route({
                            route: 'financial_management_billing_invoice_download',
                            params: {id: response.data.id},
                        });
                        window.open(url, '_blank');
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(handlerAt.start, []);
    React.useEffect(calcTotal, [handler.getData()]);
    React.useEffect(() => {
        handlerAt.setValue('invoiceAt', props.sessionFormation.invoiceAt);
    }, [props]);

    return (
        <>
            <Dialog open={props.open} maxWidth={'xl'}>
                <DialogTitle style={{fontSize: 15}}>Facture</DialogTitle>
                <DialogContent>
                    <div style={{width: '70vw', maxWidth: 1240}}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DatePickerComponent name={'invoiceAt'} handler={handlerAt}/>
                            </Grid>
                        </Grid>

                        <hr/>
                        <br/>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.th}>Référence</TableCell>
                                        <TableCell className={classes.th}>Nom</TableCell>
                                        <TableCell className={classes.th}>Temps Formation continue</TableCell>
                                        <TableCell className={classes.th}>Temps Epp</TableCell>
                                        <TableCell className={classes.th}>Temps Classe virtuelle</TableCell>
                                        <TableCell className={classes.th}>Prix</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getRender(form, handler)}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <br/>
                        <hr/>

                        <div style={{textAlign: 'end'}}>
                            Total : {total}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Générer la facture'}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center'
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center'
    },

    loader: {
        display: 'inline-block',
        fontSize: '2em',
    },
    dot: {
        opacity: 0,
        animation: '$fade-in-out 1s infinite',
    },
    dot1: {
        animationDelay: '0.2s',
    },
    dot2: {
        animationDelay: '0.4s',
    },
    '@keyframes fade-in-out': {
        '0%': {
            opacity: 0,
        },
        '50%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
});

export default Invoice;
