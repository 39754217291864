import React from 'react'
import { Box, Fade, makeStyles, Popover, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ReorderIcon from '@material-ui/icons/Reorder';
import SettingsIcon from '@material-ui/icons/Settings';
import MoreIcon from '@material-ui/icons/More';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { Delete, Edit } from "@material-ui/icons";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { theme } from "../../App";
import TextFieldComponent from "../TextFieldComponent";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import SelectAutocompleteComponent from "../SelectAutocompleteComponent";
import EditorComponent from '../EditorComponent';

const TemplateDraggableListComponentV1 = ({ data, handler, item, dragHandleProps, alertMessage, GetAction, options, select = () => { }, load, isId }) => {
    const classes = useStyles(theme)();
    const [isSettings, setIsSettings] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [isDelete, setIsDelete] = React.useState(false);
    const [isButtonTransition, setIsButtonTransition] = React.useState(false);
    const { onMouseDown } = dragHandleProps;
    const RefContnaire = React.useRef();

    const onValide = () => {
        if (handler.checkError()) {
            setIsEdition(true);
        } else {
            let datas = handler.getData();
            let optionsName = '';
            for (let index in options)
                if (options[index].value === datas[data.name]) {
                    item[data.name + "Id"] = options[index].value;
                    optionsName = options[index].label;
                };
            item[data.name] = handler.form[data.name].typeInput === 'inputSelect' ? optionsName : datas[data.name];
            setIsEdition(false);
        }
    }

    const onNoValide = () => {
        setIsEdition(false);
    }

    const onSettings = () => {
        if (isSettings == false) {
            setIsSettings(true);
        } else {
            setIsSettings(false);
            setIsEdition(false);
            if (isEdition) {
                setIsSettings(true);
                setIsEdition(true);
            }
            if (item.deleted) {
                setIsSettings(true)
            }
        }
    }

    const onDelete = () => {
        Object.assign(item, { deleted: true });
        setIsDelete(true);
    }

    const onNoDelete = () => {
        Object.assign(item, { deleted: false });
        setIsDelete(false);
    }

    const onValideDelete = () => {
        setIsSettings(false);
        setIsEdition(false);
        setIsDelete(false)
        item.onDelete(item.order, data.name);
    }

    const editAction = () => {
        select();
        handler.setDataApi(item);
        if (handler.form[data.name].typeInput === 'inputSelect') handler.form[data.name].value = item[data.name + "Id"];
    };
    const TypeInput = () => {
        return (handler.form[data.name].typeInput === 'inputSelect' ?
            <SelectAutocompleteComponent name={data.name} handler={handler} options={options} onClick={() => { onValide() }} />
            : handler.form[data.name].typeInput === 'inputText' ?
                <TextFieldComponent name={data.name} handler={handler} multiline={true} onClick={() => { onValide() }} />
                : handler.form[data.name].typeInput === 'inputEditor' ?
                    <EditorComponent name={data.name} handler={handler} onClick={() => { onValide() }} />
                    : ''
        )
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const viewAction = () => {
        return (
            <div style={{ margin: '0 0 0 10px' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <MoreIcon style={{ fontSize: '1.2rem', color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        <GetAction />
                    </Typography>
                </Popover>
            </div>
        )
    }

    React.useEffect(() => {
        if (item.add || !item[data.name]) {
            select();
            setIsSettings(true)
            setIsEdition(true)
        }
    }, []);
    React.useEffect(() => {
        handler.form[data.name].typeInput === 'inputSelect' && handler.setFormLoading(load);
    }, [load]);
    React.useEffect(() => {
        if (!item.add || item[data.name]) {
            setIsSettings(false)
            setIsEdition(false)
        }
    }, [item]);
    React.useEffect(() => {
        if (item.buttonTransition) {
            setIsButtonTransition(true)
        } else setIsButtonTransition(false)
    }, [item.buttonTransition]);
    return (
        <>
            <Box ref={RefContnaire} className={classes.containerTemplate1} style={{ gridTemplateColumns: isSettings ? 'auto 100px' : 'auto 0', }}>
                <Box style={{ display: "grid", alignItems: 'center', gridTemplateColumns: 'auto 40px', height: '100%' }}>
                    <div style={{ display: "flex", alignItems: 'center', padding: '30px 20px', position: 'relative', overflow: 'hidden' }}>
                        <IconButton style={{ padding: 6 }} onMouseDown={(e) => onMouseDown(e)}>
                            <ReorderIcon style={{ width: 20, color: 'rgb(94, 110, 130)' }} />
                        </IconButton>
                        <Box style={{ padding: '0 10px', width: '100%' }}>
                            {isId && <p style={{ margin: 0, fontWeight: '400', color: 'rgb(94, 110, 130)', fontSize: 12 }}>Id : {item.courseId ? item.courseId : '-'}</p>}
                            <Box style={{ display: 'flex', alignItems: 'center', padding: '0 0 6px 0', }}>
                                <p style={{ margin: 0, fontWeight: 'bold', color: 'rgb(94, 110, 130)' }}>{data.title} | {item.order}</p>
                                {GetAction && viewAction()}
                            </Box>
                            {isEdition && !isDelete ?
                                <TypeInput /> : <p className={classes.styleText} dangerouslySetInnerHTML={{ __html: item[data.name] }} />
                            }
                        </Box>
                        <Fade in={isDelete} {...{ timeout: 400 }} >
                            <Box className={classes.popupAlert}>
                                <Box className={classes.boxMessage}>
                                    <ErrorOutlineIcon className={classes.iconAlert} />
                                    <p className={classes.messageAlert}>{alertMessage && alertMessage}</p>
                                </Box>
                            </Box>
                        </Fade>
                    </div>
                    <Box style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <IconButton
                            type={'submit'}
                            className={classes.settings}
                            style={{ margin: 'auto 0 auto auto', width: '100%', height: '100%', borderBottom: '1px solid #d7d6e1', background: 'rgb(215, 215, 215)', zIndex: 2 }}
                            onClick={() => { onSettings() }}>
                            <SettingsIcon style={{ width: 20, position: 'relative', zIndex: 2, }} />
                        </IconButton>
                        <div style={{
                            width: 30, height: 30,
                            background: 'rgb(215, 215, 215)',
                            position: 'absolute',
                            transform: 'translate(-50%,-50%) rotate(45deg)', top: '50%',
                            zIndex: 1,
                            left: isSettings ? '100%' : '0%', transition: 'all .5s',
                        }} />
                    </Box>
                </Box>
                <Box className={classes.buttonAction}>
                    <Box style={{ transitionProperty: 'all', transitionDuration: '.3s', display: 'grid', gridTemplateColumns: isEdition || isDelete ? '0% auto' : '100% auto', }}>
                        <Box style={{ display: 'grid' }}>
                            <IconButton className={classes.settings2} onClick={() => { setIsEdition(true); editAction() }}>
                                <Edit style={{ width: 20 }} /><p>Modifier</p>
                            </IconButton>
                            <IconButton className={classes.settings2} style={{ background: 'rgb(220, 53, 69)', color: '#FFF' }} onClick={onDelete}>
                                <Delete style={{ width: 20 }} /><p>Retirer</p>
                            </IconButton>
                        </Box>
                        <Box style={{ display: 'grid' }}>
                            <IconButton disabled={isDelete ? '' : load} type={'submit'} className={classes.valide} onClick={() => { isDelete ? onValideDelete() : onValide() }}>
                                <CheckIcon style={{ width: 20 }} /><p>{isDelete ? 'Oui' : 'Valider'}</p>
                            </IconButton>
                            <IconButton className={classes.noValide} onClick={() => { isDelete ? onNoDelete() : onNoValide() }}>
                                <ClearIcon style={{ width: 20 }} /><p>{isDelete ? 'Non' : 'Annuler'}</p>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {isButtonTransition &&
                <Box style={{ width: '100%', height: 'auto', position: 'relative' }}>
                    <IconButton
                        type={'submit'}
                        className={classes.button}
                        style={{ padding: 0, display: 'block', width: '100%', borderRadius: 0, height: 40, overflow: 'hidden', borderBottom: '1px solid #d7d6e1', background: 'rgb(215, 215, 215)' }}
                        onClick={() => {
                            editAction()
                            if (handler.checkError()) {
                                setIsSettings(true)
                                setIsEdition(true)
                            } else {
                                item.buttonTransition.data(item, item.order);
                                item.buttonTransition.isTransition(true)
                            }
                        }}>
                        <p style={{ margin: 0, padding: 0, fontWeight: 'bold', fontSize: 10, width: '100%', }}>{item.buttonTransition && item.buttonTransition.title}</p>
                    </IconButton>
                </Box>
            }
        </>
    )
}

const TemplateDraggableListComponentV2 = ({ data, handler, item, options, select = () => { }, load }) => {
    const classes = useStyles(theme)();
    const RefContnaire = React.useRef();
    const Ref = React.useRef();
    const [templateAction, setTemplateAction] = React.useState(false);
    const [valueHeight, setValueHeight] = React.useState(0);
    const [isEditionAction, setIsEditionAction] = React.useState(false);

    const onValide = () => {
        if (handler.checkError()) {
            setIsEditionAction(true);
        } else {
            let datas = handler.getData();
            let optionsName = '';
            for (let index in options) if (options[index].value === datas[data.name]) { optionsName = options[index].label; item.id = options[index].value };
            item[data.name] = handler.form[data.name].typeInput === 'inputSelect' ? optionsName : datas[data.name];
            setIsEditionAction(false)
        }
    }
    const TypeInput = () => {
        return (handler.form[data.name].typeInput === 'inputSelect' ?
            <SelectAutocompleteComponent name={data.name} handler={handler} options={options} onClick={() => { onValide() }} />
            : handler.form[data.name].typeInput === 'inputText' ?
                <TextFieldComponent name={data.name} handler={handler} multiline={true} onClick={() => { onValide() }} />
                : handler.form[data.name].typeInput === 'inputEditor' ?
                    <EditorComponent name={data.name} handler={handler} onClick={() => { onValide() }} />
                    : ''
        )
    }
    const onTemplate = () => {
        if (templateAction == false) {
            setTemplateAction(true);
            setValueHeight(Ref.current.offsetHeight)
            setTimeout(() => setValueHeight('auto'), 250);
        } else {
            setTemplateAction(false);
            setValueHeight(Ref.current.offsetHeight)
            setTimeout(() => setValueHeight(0), 150);
        }
    }

    const editAction = () => {
        handler.setDataApi(item);
        if (handler.form[data.name].typeInput === 'inputSelect') handler.form[data.name].value = item.id;
    };

    React.useEffect(() => {
        handler.form[data.name].typeInput === 'inputSelect' && handler.setFormLoading(load);
    }, [load]);

    return (
        <>
            <IconButton className={classes.buttonArrowDrop} onClick={onTemplate}>
                <Box className={classes.boxArrowDrop} style={{ top: templateAction ? -18 : -28 }}>
                    <ArrowDropUpIcon className={classes.arrowDropUp} style={{ transform: templateAction ? 'translate(0,40%)' : 'translate(0,0%)', color: 'rgb(94, 110, 130)' }} />
                    <p style={{ color: 'rgb(94, 110, 130)' }}>{data.title}</p>
                    <ArrowDropDownIcon className={classes.arrowDropDown} style={{ transform: templateAction ? 'translate(0,0%)' : 'translate(0,-33%)', color: 'rgb(94, 110, 130)' }} />
                </Box>
            </IconButton>

            <Box ref={RefContnaire}
                style={{
                    transitionProperty: 'all',
                    transitionDuration: '.3s',
                    height: valueHeight,
                    overflow: 'hidden',
                }}>
                <div style={{ padding: '20px 0' }} ref={Ref} >
                    <Box className={classes.templateAction}>
                        <Box style={{ width: '90%', padding: '20px 0', margin: 'auto' }}>
                            {isEditionAction ?
                                <TypeInput /> : <p className={classes.styleText} dangerouslySetInnerHTML={{ __html: item[data.name] }} />
                            }
                        </Box>
                        <Box style={{
                            background: "#F8FAFC",
                            height: '100%',
                            transitionProperty: 'all',
                            transitionDuration: '.3s',
                            display: 'grid',
                            gridTemplateColumns: isEditionAction ? '0% auto' : '100% auto',
                        }}>
                            <IconButton style={{ padding: 0, height: '100%', borderRadius: 0, }} onClick={() => { setIsEditionAction(true); editAction(); select(); }}>
                                <Edit style={{ width: 20 }} />
                            </IconButton>
                            <Box style={{ display: 'grid' }}>
                                <IconButton className={classes.valide} onClick={onValide}>
                                    <CheckIcon style={{ width: 20 }} />
                                </IconButton>
                                <IconButton
                                    className={classes.noValide}
                                    onClick={() => { setIsEditionAction(false); editAction() }}>
                                    <ClearIcon style={{ width: 20 }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Box>
        </>
    )
}

const TemplateDraggableListComponentCheckBox = ({ data, handler, item }) => {
    const classes = useStyles()();
    const RefContnaire = React.useRef();
    const handlerForm = handler.form[data.name]
    const [value, setValue] = React.useState(item[data.name] ? item[data.name] : false);
    const onChange = (value) => {
        const changedValue = value.target.checked;
        setValue(changedValue)
        item[data.name] = changedValue;

        let newForm = { ...handler.form };
        newForm[data.name].value = changedValue;
        newForm[data.name].error = handler.getErrorByField(newForm[data.name]);
        handler.set(newForm);
    };
    React.useEffect(() => {
        setValue(item[data.name])
    }, [item[data.name]]);
    return (
        <Box ref={RefContnaire} style={{ height: '100%' }}>
            <Box className={classes.checkboxContnaire}>
                <FormControl variant="outlined" className={`${classes.checkbox} ${value ? classes.checkboxValid : ''}`}>
                    <div>
                        <FormLabel component="legend">{handlerForm.label + ((handlerForm.options && handlerForm.options.validation && handlerForm.options.validation.indexOf('required') > -1) ? ' *' : '')}</FormLabel>
                        <Checkbox checked={value} onChange={(value) => { onChange(value) }} />
                    </div>
                </FormControl>
            </Box>
        </Box>
    )
}

const TemplatesComponent = ({
    item, dragHandleProps,
    handlerTemplate = {
        template1: {
            handler: [],
            options: [],
            getAction: '',
            select: () => { },
            load: true,
            isId: false
        },
        template2: {
            handler: [],
            options: [],
            select: () => { },
            load: true,
        },
        templateCheckBox: {},
    }, alertMessage }) => {
    const classes = useStyles()();
    const [name, setName] = React.useState()
    const handlerTemplate1 = handlerTemplate.template1 && handlerTemplate.template1.handler;
    const handlerTemplate2 = handlerTemplate.template2 && handlerTemplate.template2.handler;
    const handlerCheckBox = handlerTemplate.templateCheckBox;

    React.useEffect(() => {
        handlerTemplate1 && setName(Object.keys(handlerTemplate1.form)[0]);
    }, [])

    return (
        <div className={classes.boxContnaire} style={{ display: 'grid', gridTemplateColumns: handlerCheckBox ? 'auto 100px' : 'auto 0px', gridGap: handlerCheckBox ? 10 : 0, alignItems: 'center' }}>
            <Box data-id={name + "_" + item.order} style={{ height: 'auto', display: 'flex', alignItems: 'center', }}>
                <Fade in={true} {...{ timeout: 1000 }} style={{ height: '100%', zIndex: 10, width: '100%', }}>
                    <div>
                        <Box style={{
                            transitionProperty: 'all', transitionDuration: '.3s',
                            border: item.add ? '6px solid rgb(215, 215, 215)' : '1px solid rgb(215, 215, 215)',
                            overflow: 'hidden', borderRadius: 5, background: "#F8FAFC", height: 'auto',
                        }}>
                            <div style={{ display: 'grid', height: '100%', overflow: 'hidden', }}>
                                {handlerTemplate1 &&
                                    Object.keys(handlerTemplate1.form).map((handlerList, index) =>
                                        <div key={index}>
                                            <TemplateDraggableListComponentV1
                                                handler={handlerTemplate1}
                                                data={handlerTemplate1.form[handlerList]}
                                                dragHandleProps={dragHandleProps}
                                                options={handlerTemplate.template1.options}
                                                select={() => { handlerTemplate.template1.select && handlerTemplate.template1.select() }}
                                                GetAction={handlerTemplate.template1.getAction && handlerTemplate.template1.getAction}
                                                load={handlerTemplate.template1.load}
                                                isId={handlerTemplate.template1.isId}
                                                item={item}
                                                alertMessage={alertMessage}
                                            />
                                        </div>
                                    )
                                }
                                {handlerTemplate2 &&
                                    Object.keys(handlerTemplate2.form).map((handlerList, index) =>
                                        <div key={index}>
                                            <TemplateDraggableListComponentV2
                                                key={index}
                                                handler={handlerTemplate2}
                                                data={handlerTemplate2.form[handlerList]}
                                                options={handlerTemplate.template2.options}
                                                load={handlerTemplate.template2.load}
                                                select={() => { handlerTemplate.template2.select && handlerTemplate.template2.select() }}
                                                item={item}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </Box>

                    </div>
                </Fade>
            </Box>
            {handlerCheckBox &&
                <Fade in={true} {...{ timeout: 1000 }} style={{ height: '100%', display: 'grid', gap: 10, position: 'relative', alignContent: 'center', }}>
                    <div>
                        {Object.keys(handlerCheckBox.form).map((handlerList, index) =>
                            <>
                                <div style={{ width: 60, height: 5, background: 'rgb(215, 215, 215)', position: 'absolute', top: '50%', left: 0, transform: 'translate(-50%,-50%)', zIndex: -1 }} />
                                <Box key={index} data-id={name + "_" + item.order + "_check"} style={{ height: 'auto' }}>
                                    <div>
                                        <Box style={{
                                            transitionProperty: 'all', transitionDuration: '.3s',
                                            border: item.add ? '6px solid rgb(215, 215, 215)' : '1px solid rgb(215, 215, 215)',
                                            overflow: 'hidden', borderRadius: 5, background: "#F8FAFC", height: 'auto',
                                        }}>
                                            <TemplateDraggableListComponentCheckBox
                                                handler={handlerCheckBox}
                                                data={handlerCheckBox.form[handlerList]}
                                                item={item}
                                            />
                                        </Box>
                                    </div>
                                </Box>
                            </>
                        )}
                    </div>
                </Fade>
            }
        </div>
    );
};

const useStyles = (theme) => makeStyles(({
    boxContnaire: {
        '& .rdw-editor-main': {
            minHeight: 77,
            maxHeight: 130
        }
    },
    test: {
        width: '100%',
    },
    valide: {
        padding: 0,
        display: 'block',
        width: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    noValide: {
        padding: 0,
        display: 'block',
        width: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            backgroundColor: 'rgb(220, 53, 69)',
            color: '#FFF',
            height: '100%',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    settings: {
        padding: 0,
        display: 'block',
        width: '100%',
        borderRadius: 0,
        '& .MuiIconButton-label': {
            height: '100%',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    settings2: {
        padding: 0,
        display: 'block',
        width: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
        },
    },
    buttonArrowDrop: {
        padding: 0,
        display: 'block',
        width: '100%',
        borderRadius: 0,
        height: 40,
        overflow: 'hidden',
        borderBottom: '1px solid #d7d6e1',
        '& .MuiIconButton-label': {
            display: 'block',
            backgroundColor: 'rgba(0, 0, 0, 0.06)',
            height: '100%',
        },
    },
    boxArrowDrop: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        position: 'absolute',
        width: '100%',
        '& p': {
            margin: 0,
            padding: 0,
            fontWeight: 'bold',
            fontSize: 10,
            width: '100%',
        },
    },
    arrowDropUp: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        margin: 'auto',
        display: 'block',
        height: 37,
        width: '100%',
    },
    arrowDropDown: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        margin: 'auto',
        display: 'block',
        height: 37,
        width: '100%',
    },
    styleText: {
        margin: 0, color: 'rgb(94, 110, 130)', fontSize: '1rem',
        '& p, ul, ol, h1, h2, h3': { margin: 0 },
        '& div': {
            whiteSpace: 'normal !important',
        }
    },
    templateAction: {
        background: "#fff",
        display: "grid",
        alignItems: 'center',
        width: '90%',
        margin: '0 auto 0 auto',
        border: '1px solid #d7d6e1',
        borderRadius: 4,
        gridTemplateColumns: '90% auto',
        '& .MuiIconButton-root .MuiIconButton-label': {
            height: '100%',
            overflow: 'hidden',
        },
    },
    template3Action: {
        background: "#fff",
        width: '100%',
        height: '100%',
        margin: '0 auto 0 auto',
        border: '1px solid #d7d6e1',
        borderRadius: 4,
        '& .MuiIconButton-root .MuiIconButton-label': {
            height: '100%',
            overflow: 'hidden',
        },
    },
    textField: {
        width: '100%',
        '& input': {
            padding: '10px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            minHeight: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        }
    },
    textFieldFocus: {
        '& .MuiFormHelperText-root': {
            color: '#3F51BF'
        }
    },
    textFieldError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525'
        },
        '& label': {
            color: '#982525'
        }
    },
    textFieldValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000'
        },
        '& label': {
            color: '#008000'
        }
    },
    buttonAction: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        background: "#F8FAFC",
        height: '100%',
        display: "grid",
    },
    containerTemplate1: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        background: "#fff",
        display: "grid",
        alignItems: 'center',
        width: '100%',
    },
    popupAlert: {
        width: '100%',
        height: '100%',
        background: '#ffffffa8',
        position: 'absolute',
        transform: 'translate(-50%,-50%)',
        top: '50%',
        left: '50%',
        overflow: 'hidden',
        display: 'flex',
        zIndex: 3,
    },
    boxMessage: {
        margin: 'auto',
        width: '74%', height: '75%',
        background: '#FFF',
        border: '4px solid #F8BB86',
        borderRadius: 5, display: 'flex'
    },
    iconAlert: {
        margin: 'auto -31px',
        width: 60, height: 60,
        color: 'rgb(248, 187, 134)',
        background: '#FFF',
        borderRadius: 100
    },
    messageAlert: {
        padding: 0,
        margin: 'auto',
        fontWeight: 'bold',
        fontSize: 20,
        textAlign: 'center',
        width: '74%'
    },
    checkboxContnaire: {
        height: '100%',
        '& div': {
            height: '100%',
            '& div': {
                display: 'grid',
                height: '100%',
                '& legend': {
                    color: 'rgb(94, 110, 130)',
                    margin: 0, fontSize: 14,
                    textAlign: 'center',
                    fontWeight: 'bold', background: '#FFF',
                    padding: 10, borderBottom: '1px solid rgb(215, 215, 215)',
                    alignContent: 'center',
                    display: 'grid',
                },
                '& .MuiCheckbox-root': {
                    margin: 'auto',
                    display: 'block',
                }
            }
        },
    },
    checkbox: {
        width: '100%',
        marginTop: 0,
        '& legend': {
            padding: '10px 0',
            textAlign: 'initial'
        },
        '& .MuiFormHelperText-root': {
            margin: '0 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        }
    },
    checkboxValid: {
        '& legend': {
            color: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        }
    }
}));

export default TemplatesComponent;
