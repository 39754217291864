import React from 'react';
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";

function ButtonComponent(props = {
    label: '',
    color: '',
    style: {},
    onClick: false,
    className: '',
    disabled: false
}) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <Button
            className={`${isTall && !props.small ? classes.button : classes.buttonSmall} ${props.className ? props.className : ''}`}
            disabled={props.disabled}
            variant="contained"
            color="primary"
            style={props.color ? {...props.style, backgroundColor: props.color} : props.style}
            onClick={props.onClick}>
            {props.label}
        </Button>
    );
}

const useStyles = makeStyles({
    button: {
        fontSize: 12,
        opacity: 0.9
    },
    buttonSmall: {
        fontSize: 10,
        opacity: 0.9
    }
});

export default ButtonComponent;
