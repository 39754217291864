import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import { getRoutePathname } from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@material-ui/core/Slide";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import Fade from "@material-ui/core/Fade";
import TabsComponent from "../../../Component/TabsComponent";
import ProspectForm from "./ProspectForm";
import ProspectActivity from "./ProspectActivity";
import { TableReload } from "../../../Action/TableAction";
import { inArray } from "../../../Tool/ArrayCommon";
import SweetAlert from "sweetalert-react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Assignment, Edit, HowToReg, HighlightOff, LowPriority, PhoneForwarded, Send, ArrowLeft, ArrowRight } from "@material-ui/icons";
import UseAnimations from "react-useanimations";
import archive from "react-useanimations/lib/archive";
import RubberBand from 'react-reveal/RubberBand';
import LoopComponent from "../../../Component/LoopComponent";
import moment from 'moment';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import SelectComponent from "../../../Component/SelectComponent";
import ProspectComment from "./ProspectComment";
import ProspectHotline from "./ProspectHotline";
import Task from "../Task";
import Registration from "./Registration";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import { hasRight } from "../../../Tool/UserTool";
import { theme } from "../../../App";
import LoginAsComponent from '../../../Component/LoginAsComponent';

function Prospect(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [formationGroup, setFormationGroup] = React.useState(false);

    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const [organisms, setOrganisms] = React.useState([]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const isAdmin = hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN');
    const isWebcoach = (hasRight(authenticationReducer, 'ROLE_WEBCOACH') || hasRight(authenticationReducer, 'ROLE_WEBCOACH_ADMIN')) && !isAdmin

    // Form Prospect
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez le nom du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'email du contact.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required', 'email'] }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le numéro de téléphone du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        mobile: {
            name: 'mobile',
            label: 'Téléphone Mobile',
            textHelper: 'Saisissez le numéro de téléphone mobile du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ips: {
            name: 'ips',
            label: 'IPS',
            textHelper: 'Saisissez l\'Identifiant Professionnel de Santé du contact. (ADELI ou RPPS)',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        siret: {
            name: 'siret',
            label: 'SIRET',
            textHelper: 'Saisissez le numéro SIRET du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        poles: {
            name: 'poles',
            label: 'Pôles',
            textHelper: 'Choisissez les pôles du contact.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Choisissez le groupe de formation du contact.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez l\'adresse du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        birthdayAt: {
            name: 'birthdayAt',
            label: 'Date d\'anniversaire',
            textHelper: 'Saisissez la date d\'anniversaire du contact.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: '',
            type: 'integer',
            defaultValue: theme.id,
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                let data = handler.getData();

                Api.post({
                    route: 'crm_prospect_edit',
                    params: { id: props.match.params.id },
                    data: data
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                        dispatch(TableReload('crm_prospect_prospect_activity_list'));

                        setInfo({ ...info, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), commercialId: response.data.commercialId });
                        setFormationGroup(data.formationGroup);
                        changeCommercialList();
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                let data = handler.getData();

                Api.post({
                    route: 'crm_prospect_add',
                    data: data
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setIsEdition(true);
                        props.history.push(getRoutePathname('crm_prospect', { id: response.data.id }));
                        dispatch(TableReload('crm_prospect_prospect_activity_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact créé avec succès.',
                                variant: 'success',
                            })
                        );

                        setInfo({ ...info, createdAt: data.createdAt, updatedAt: data.updatedAt });
                        changeCommercialList(response.data.id);
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const changeCommercialList = (id) => {
        let prospectId = id ?? props.match.params.id;

        if (isAdmin && prospectId) {
            Api.get({
                route: 'crm_prospect_commercial_list',
                params: { id: prospectId }
            },
                (response) => {
                    if (response && response.data) {
                        setOptionCommercials(response.data);
                    }
                });
        }
    }
    const changeOrganism = (value) => {
        handler.setValue('organism', value);
    };

    // Info Prospect
    const [info, setInfo] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showTask, setShowTask] = React.useState(false);
    const [formProspectState, setFormProspectState] = React.useState({
        prospectState: {
            name: 'prospectState',
            label: 'État',
            textHelper: 'Choisissez l\'état du contact.',
            type: 'integer',
            defaultValue: 1,
            options: {}
        }
    });
    const handlerProspectState = formHandlerInit(formProspectState, setFormProspectState);
    const [formCommercial, setFormCommercial] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez le commercial du contact.',
            type: 'integer',
            options: {}
        }
    });
    const handlerCommercial = formHandlerInit(formCommercial, setFormCommercial);
    React.useEffect(handlerCommercial.start, []);
    React.useEffect(handlerProspectState.start, []);
    React.useEffect(() => {
        handlerCommercial.setValue('commercial', info.commercialId);
    }, [info.commercialId]);

    const archived = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'crm_prospect_delete',
            params: { id: props.match.params.id }
        },
            (response) => {
                if (response.status === 200) {
                    setInfo({ ...info, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), deletedAt: moment().format('YYYY-MM-DD HH:mm:ss') });
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Contact archivé.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const unarchived = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'crm_prospect_cancel_deletion',
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setInfo({ ...info, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), deletedAt: '' });
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Contact désarchivé.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const assignMe = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'crm_prospect_assign_me',
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setInfo({
                        ...info,
                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        commercialId: authenticationReducer.id
                    });
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Contact attribué.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const unassignMe = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'crm_prospect_unassign_me',
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setInfo({
                        ...info,
                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        commercialId: '',
                        commercialName: ''
                    });
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Contact désattribué.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const prospectState = (value) => {
        if (handlerProspectState.checkError()) {
            console.log('Error');
        }
        else {
            handlerProspectState.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'crm_prospect_prospect_state_edit',
                params: { id: props.match.params.id },
                data: { prospectState: value }
            }, (response) => {
                handlerProspectState.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le changement d\'état a bien été pris en compte.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));

                    setInfo({ ...info, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss') });
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };
    const assignCommercial = (value) => {
        if (handlerCommercial.checkError()) {
            console.log('Error');
        }
        else {
            handlerCommercial.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'crm_prospect_commercial',
                params: { id: props.match.params.id },
                data: { commercial: value }
            }, (response) => {
                handlerCommercial.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le changement du commercial a été pris en compte.',
                            variant: 'success',
                        })
                    );

                    let commercialName = '';
                    for (let index in optionCommercials) {
                        if (optionCommercials[index].value === parseInt(value)) {
                            commercialName = optionCommercials[index].label;
                            break;
                        }
                    }
                    setInfo({
                        ...info,
                        updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                        commercialName: commercialName,
                        commercialId: value
                    });
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_registration_free_list'));
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    // Info Prospect : Note
    const [formNote, setFormNote] = React.useState({
        note: {
            name: 'note',
            label: 'Note',
            textHelper: 'Modifier la note générale de la fiche contact.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 2000
            }
        }
    });
    const [dialogNote, setDialogNote] = React.useState(false);
    const handlerNote = formHandlerInit(formNote, setFormNote);
    React.useEffect(handlerNote.start, []);
    const note = () => {
        dispatch(LinearProgressMainLayoutActivate());

        let data = handlerNote.getData();
        setInfo({
            ...info,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            note: data.note
        });

        Api.post({
            route: 'crm_prospect_note_edit',
            data: data,
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Note modifiée.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setInfo({ ...info, note: '' });
                }
            });
    }

    const resetPasswordSend = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.post({
            route: 'crm_prospect_reset_password',
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Le mot de passe a été transmis.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }
    // Info Prospect : Fusion
    const [formFusion, setFormFusion] = React.useState({
        prospect: {
            name: 'prospect',
            label: 'Référence contact',
            textHelper: 'Saisissez la référence du contact à fusionner.',
            type: 'integer',
            defaultValue: ''
        }
    });
    const [dialogFusion, setDialogFusion] = React.useState(false);
    const handlerFusion = formHandlerInit(formFusion, setFormFusion);
    React.useEffect(handlerFusion.start, []);
    const fusion = () => {
        dispatch(LinearProgressMainLayoutActivate());
        handlerFusion.setFormLoading(true);

        let data = handlerFusion.getData();

        Api.post({
            route: 'crm_prospect_fusion',
            data: data,
            params: { id: props.match.params.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFusion.setFormLoading(false);
                setDialogFusion(false);

                if (response.status === 200) {
                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_registration_free_list'));
                    dispatch(TableReload('crm_prospect_prospect_hotline_list'));
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                    dispatch(TableReload('crm_prospect_prospect_comment_list'));
                    dispatch(TableReload('crm_prospect_form_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Les 2 contacts ont bien été fusionnés.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    // Select
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    React.useEffect(() => {
        Api.get({
            route: 'select_poles'
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
            route: 'select_prospect_states'
        },
            (response) => {
                if (response && response.data) {
                    setOptionProspectStates(response.data);
                }
            });
        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });

        changeCommercialList();
    }, []);

    // Prev/Next
    const [next, setNext] = React.useState(null);
    const [prev, setPrev] = React.useState(null);

    // ALL
    React.useEffect(() => {
        setShowTask(false);
        setReadyContent(false);

        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Fiche contact',
                context: isWebcoach ? 'Webcoach' : 'CRM' + (isAdmin ? ' (Admin)' : ''),
                description: '',
                links: [
                    isWebcoach ?
                        { path: getRoutePathname('webcoach_relaunch'), label: 'Interface des relances' }
                        : { path: getRoutePathname('crm_prospect_list'), label: 'Liste des contacts' }

                ]
            });

            setIsEdition(true);

            Api.get({
                route: 'crm_prospect_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                        setInfo({ ...info, ...response.data });
                        handlerNote.setValue('note', response.data.note);
                        setTimeout(() => handlerProspectState.setValue('prospectState', response.data.prospectState), 50);
                        setFormationGroup(response.data.formationGroup);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('crm_prospect_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un contact',
                context: 'CRM' + (isAdmin ? ' (Admin)' : ''),
                description: '',
                links: [
                    { path: getRoutePathname('crm_prospect_list'), label: 'Liste des contacts' }
                ]
            });
            setIsEdition(false);
            Api.get({
                route: 'select_organisms'
            },
                (response) => {
                    if (response && response.data) {
                        let data = [];
                        for (let index in response.data) {
                            if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                                data.push({value: response.data[index].value, label: response.data[index].label, logo: response.data[index].logo });
                            }
                        }

                        setOrganisms(data);
                        handler.setValue('organism', inArray(theme.id, data) ? theme.id : data[0]);
                        setTimeout(() => setReadyContent(true), 250);
                    }
                });
            handler.reset();
        }

        if (props.location.state && props.location.state.list) {
            let id = parseInt(props.match.params.id);
            for (let index in props.location.state.list) {
                if (props.location.state.list[index] === id) {
                    setNext(props.location.state.list[(parseInt(index) + 1)]);
                    setPrev(props.location.state.list[(parseInt(index) - 1)]);
                    break;
                }
            }
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        if (props.location.state && props.location.state.emailIPS) {
            if ((props.location.state.emailIPS.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null)) {
                handler.setValue('ips', props.location.state.emailIPS);
            }
            else {
                handler.setValue('email', props.location.state.emailIPS);
            }
        }
    }, []);

    // LOGO
    const [gridLogo, setGridLogo] = React.useState(12);
    React.useEffect(() => {
        if (organisms) {
            let count = organisms.length;
            setGridLogo(Math.ceil(12 / count));
        }
    }, [organisms]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            {
                !isEdition &&
                <>
                    <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                        <div>
                            <Grid container spacing={2}>
                                {organisms.map((organism, i) => (
                                    <Grid key={i} item xs={isTall ? gridLogo : 12} onClick={() => changeOrganism(organism.value)}>
                                        <ShadowBoxComponent style={{ minHeight: '100%', textAlign: 'center', cursor: 'pointer' }} disabled={form.organism.value !== organism.value}>
                                            <img src={"/asset/images/" + organism.logo} alt="logo" className={classes.logo} style={form.organism.value !== organism.value ? { filter: 'grayscale(100%)' } : {}} />
                                        </ShadowBoxComponent>
                                    </Grid>
                                ))}
                                {isTall && <Grid item xs={1} />}
                            </Grid>
                        </div>
                    </Slide>
                    <br />
                </>
            }

            {
                <div style={{ position: 'absolute', top: 50, width: 'calc(100% - 45px)' }}>
                    {next && <Tooltip title="Passer à la fiche suivante" aria-label="next">
                        <IconButton style={{ padding: 5, boxShadow: '0 5px 20px 0px rgb(0 0 0 / 25%)', backgroundColor: 'white' }} className={classes.buttonAction} aria-label="next"
                            onClick={() => props.history.push(getRoutePathname('crm_prospect', { id: next }), { list: props.location.state.list })}>
                            <ArrowRight style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                        </IconButton>
                    </Tooltip>}
                    {prev && <Tooltip title="Passer à la fiche précédente" aria-label="next">
                        <IconButton style={{ padding: 5, boxShadow: '0 5px 20px 0px rgb(0 0 0 / 25%)', backgroundColor: 'white' }} className={classes.buttonAction} aria-label="next"
                            onClick={() => props.history.push(getRoutePathname('crm_prospect', { id: prev }), { list: props.location.state.list })}>
                            <ArrowLeft style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                        </IconButton>
                    </Tooltip>}
                </div>
            }

            <Grid container spacing={2}>
                <Fade in={true} {...{ timeout: 750 }}>
                    <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 7 : 12} xl={isEdition ? 7 : 12}>
                        <ShadowBoxComponent style={{ minHeight: '100%' }}>
                            <TitleComponent title={
                                isEdition ? <>
                                    Formulaire d'édition du contact <div>
                                        <img src={"/asset/images/" + info.organismLogo} alt="logo" style={{ height: 30, position: 'absolute', top: 7, right: 15 }} />
                                    </div>
                                </> : <>Formulaire de création d'un contact</>
                            } />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'lastname'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'firstname'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent name={'address'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'zipCode'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'city'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'phone'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'mobile'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'email'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePickerComponent name={'birthdayAt'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'ips'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'siret'} handler={handler} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup} disabled={isWebcoach} />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <MultipleSelectComponent name={'poles'} handler={handler} options={optionPoles} disabled={(!!props.match.params.id && !isAdmin) || isWebcoach} />
                                </Grid>
                            </Grid>

                            {!isWebcoach &&
                                <div className={classes.buttonGroup}>
                                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                                </div>
                            }
                        </ShadowBoxComponent>
                    </Grid>
                </Fade>
                {
                    isEdition &&
                    <Slide direction={'left'} in={true} {...{ timeout: 750 }}>
                        <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 5 : 12} xl={isEdition ? 5 : 12}>
                            <ShadowBoxComponent style={{ minHeight: '100%' }}>
                                <TitleComponent title={<>
                                    <span>Infos générales</span>
                                    {((!info.commercialId && !isWebcoach) || (info.commercialId === authenticationReducer.id) || isAdmin) &&
                                        <div className={classes.buttonActionGroup} style={isTall ? {} : { position: 'relative', top: 5, right: 5 }}>
                                            {
                                                info.deletedAt ?
                                                    <Tooltip title="Désarchiver" aria-label="Désarchiver">
                                                        <IconButton className={`${classes.buttonAction} ${classes.buttonActionUseAni}`} aria-label="Désarchiver" onClick={unarchived}>
                                                            <LoopComponent>
                                                                <RubberBand>
                                                                    <UseAnimations className={classes.buttonActionIcon} animation={archive} />
                                                                </RubberBand>
                                                            </LoopComponent>
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Archiver" aria-label="Archiver">
                                                        <IconButton className={`${classes.buttonAction} ${classes.buttonActionUseAni}`} aria-label="Archiver" onClick={() => setShowConfirm(true)}>
                                                            <UseAnimations className={classes.buttonActionIcon} animation={archive} />
                                                        </IconButton>
                                                    </Tooltip>
                                            }
                                            {
                                                (
                                                    hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') ||
                                                    hasRight(authenticationReducer, 'ROLE_CRM_LOGIN_AS')
                                                ) &&
                                                <LoginAsComponent
                                                    className={`${classes.buttonAction} ${classes.buttonActionUseAni}`}
                                                    email={info.email}
                                                    organism={info.organism}
                                                />
                                            }
                                            <Tooltip title="Ajouter une tâche" aria-label="Tâche">
                                                <IconButton className={classes.buttonAction} aria-label="Tâche" onClick={() => setShowTask(true)}>
                                                    <Assignment className={classes.buttonActionIcon} />
                                                </IconButton>
                                            </Tooltip>
                                            {
                                                <Tooltip title="Envoyer mot de passe" aria-label="Réinitialiser">
                                                    <IconButton className={classes.buttonAction} aria-label="Réinitialiser" onClick={() => resetPasswordSend()}>
                                                        <Send className={classes.buttonActionIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            }

                                            <Tooltip title="Éditer la note" aria-label="Note">
                                                <IconButton className={classes.buttonAction} aria-label="Note" onClick={() => setDialogNote(true)}>
                                                    <Edit className={classes.buttonActionIcon} />
                                                </IconButton>
                                            </Tooltip>
                                            { // HACK
                                                false && <Tooltip title="Fusionner" aria-label="Fusionner">
                                                    <IconButton className={classes.buttonAction} aria-label="Fusionner" onClick={() => setDialogFusion(true)}>
                                                        <LowPriority className={classes.buttonActionIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                info.commercialId === authenticationReducer.id ?
                                                    <Tooltip title="Me désattribuer le contact" aria-label="Désattribuer">
                                                        <IconButton className={classes.buttonAction} aria-label="Désattribuer" onClick={unassignMe}>
                                                            <HighlightOff className={classes.buttonActionIcon} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    !info.commercialId ?
                                                        <Tooltip title="M'attribuer le contact" aria-label="Attribuer">
                                                            <IconButton className={classes.buttonAction} aria-label="Attribuer" onClick={assignMe}>
                                                                <HowToReg className={classes.buttonActionIcon} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <></>
                                            }
                                        </div>
                                    }
                                </>} />

                                <Grid container spacing={1}>
                                    {
                                        isAdmin &&
                                        <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <SelectComponent name={'commercial'} handler={handlerCommercial} options={optionCommercials} nullable={true} onChange={assignCommercial} disabled={isWebcoach} />
                                        </Grid>
                                    }

                                    <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {
                                            info.deletedAt ?
                                                <span style={{ color: '#a47d09', fontSize: 12 }}>Archivé depuis le {moment(info.deletedAt).format('ll')}</span>
                                                :
                                                <SelectComponent name={'prospectState'} handler={handlerProspectState} options={optionProspectStates} nullable={false} onChange={prospectState} disabled={isWebcoach} />
                                        }
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <span className={classes.infoSpanTitle}>Date de création : </span>
                                        <span className={classes.infoSpanText}>{info.createdAt ? moment(info.createdAt).format('lll') : '-'}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <span className={classes.infoSpanTitle}>Dernière mise à jour : </span>
                                        <span className={classes.infoSpanText}>{info.updatedAt ? moment(info.updatedAt).format('lll') : '-'}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <span className={classes.infoSpanTitle}>Commercial : </span>
                                        <span className={classes.infoSpanText}>{
                                            info.commercialId === authenticationReducer.id ? 'Vous' : info.commercialId ? info.commercialName : '-'
                                        }</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <span className={classes.infoSpanTitle}>Client depuis le : </span>
                                        <span className={classes.infoSpanText}>{info.clientAt ? moment(info.clientAt).format('lll') : '-'}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                        <span className={classes.infoSpanTitle}>Provenance : </span>
                                        <span className={classes.infoSpanText}>{info.prospectOrigin ? info.prospectOrigin : '-'}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <span className={classes.infoSpanTitle}>Note : </span>
                                        <p className={classes.infoSpanText} style={{ padding: '0 30px' }}>{info.note ? info.note : '-'}</p>
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>
                    </Slide>
                }
            </Grid>

            <br />

            {
                isEdition &&
                <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                    <div>
                        <TabsComponent
                            tabDefault={isWebcoach ? 'prospectActivities' : 'prospectRegistration'}
                            tabs={[
                                { id: 'prospectActivities', label: 'Activités' },
                                !isWebcoach && { id: 'prospectRegistration', label: 'Inscriptions DPC' },
                                !isWebcoach && { id: 'prospectRegistrationPERSONAL', label: 'Inscriptions PERSONNEL' },
                                !isWebcoach && { id: 'prospectRegistrationFIFPL', label: 'Inscriptions FIFPL Conventionné' },
                                !isWebcoach && { id: 'prospectRegistrationCPF', label: 'Inscriptions CPF' },
                                !isWebcoach && { id: 'prospectRegistrationFree', label: 'Inscriptions Gratuites' },
                                !isWebcoach && { id: 'prospectHotline', label: 'Hotlines' },
                                !isWebcoach && { id: 'prospectComment', label: 'Commentaires' },
                                !isWebcoach && { id: 'prospectForm', label: 'Formulaires' }
                            ]}
                        >
                            <div key={'prospectActivities'}>
                                <ProspectActivity {...props} />
                            </div>
                            {!isWebcoach &&
                                <div key={'prospectRegistration'}>
                                    <Registration
                                        {...props}
                                        organism={form.organism.value}
                                        formationGroup={formationGroup}
                                        info={{ get: info, set: setInfo }}
                                        financeType={1}
                                        title={'Inscriptions DPC'}
                                        label={'Ajouter une inscription DPC'}
                                        labelAvailable={'Participation ANDPC'}
                                    />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectRegistrationPERSONAL'}>
                                    <Registration
                                        {...props}
                                        organism={form.organism.value}
                                        formationGroup={formationGroup}
                                        info={{ get: info, set: setInfo }}
                                        financeType={6}
                                        title={'Inscriptions personnel'}
                                        label={'Ajouter une inscription personnel'}
                                        labelAvailable={'Participation personnel'}
                                    />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectRegistrationFIFPL'}>
                                    <Registration
                                        {...props}
                                        organism={form.organism.value}
                                        formationGroup={formationGroup}
                                        info={{ get: info, set: setInfo }}
                                        financeType={3}
                                        title={'Inscriptions FIFPL'}
                                        label={'Ajouter une inscription FIFPL Conventionné'}
                                        labelAvailable={'Participation FIFPL'}
                                    />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectRegistrationCPF'}>
                                    <Registration
                                        {...props}
                                        organism={form.organism.value}
                                        formationGroup={formationGroup}
                                        info={{ get: info, set: setInfo }}
                                        financeType={4}
                                        title={'Inscriptions CPF'}
                                        label={'Ajouter une inscription CPF'}
                                        labelAvailable={'Participation CPF'}
                                    />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectRegistrationFree'}>
                                    <Registration
                                        {...props}
                                        organism={form.organism.value}
                                        formationGroup={formationGroup}
                                        info={{ get: info, set: setInfo }}
                                        financeType={2}
                                        title={'Inscriptions Gratuites'}
                                        label={'Ajouter une inscription Gratuite'}
                                        labelAvailable={'Disponible'}
                                    />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectHotline'}>
                                    <ProspectHotline {...props} />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectComment'}>
                                    <ProspectComment {...props} />
                                </div>
                            }
                            {!isWebcoach &&
                                <div key={'prospectForm'}>
                                    <ProspectForm {...props} />
                                </div>
                            }
                        </TabsComponent>
                    </div>
                </Slide>
            }

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Archiver'}
                text={form.firstname.value + ' ' + form.lastname.value + '\nÊtes-vous sur de vouloir archiver ce contact ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    archived();
                    setShowConfirm(false);
                }}
                onCancel={() => setShowConfirm(false)}
            />

            <Dialog open={dialogNote} maxWidth={'xl'} onClose={() => setDialogNote(false)}>
                <DialogTitle style={{ fontSize: 15 }}>Note générale</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <TextFieldComponent name={'note'} handler={handlerNote} multiline={true} />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogNote(false)} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => {
                        note();
                        setDialogNote(false);
                    }} />
                </DialogActions>
            </Dialog>

            <Dialog open={dialogFusion} maxWidth={'xl'} onClose={() => setDialogFusion(false)}>
                <DialogTitle style={{ fontSize: 15, paddingBottom: 0 }}>
                    Fusion
                    <p style={{ fontSize: 14 }}>Attention, cette action est irréversible !</p>
                </DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>
                    <TextFieldComponent name={'prospect'} handler={handlerFusion} />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogFusion(false)} />
                    <ButtonComponent label={'Fusionner'} onClick={() => fusion()} />
                </DialogActions>
            </Dialog>

            <Dialog open={showTask} maxWidth={'lg'} onClose={() => setShowTask(false)}>
                <DialogContent>
                    <Task {...props} />
                </DialogContent>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
    buttonActionGroup: {
        position: 'absolute',
        top: 3,
        right: 15
    },
    buttonAction: {
        padding: 10,
        marginRight: 10,
        margin: 0,
        float: 'right'
    },
    buttonActionUseAni: {
        padding: '4px 8px 10px'
    },
    buttonActionIcon: {
        fontSize: 18,
        opacity: 1,
        '& g > path': {
            stroke: 'rgba(0, 0, 0, 0.6)'
        }
    },
    infoSpanTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10
    },
    infoSpanText: {
        fontSize: 13
    },
    logo: {
        height: 50,
        padding: '15px 0'
    }
});

export default Prospect;
