import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { makeStyles } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import { getRoutePathname } from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import SelectComponent from "../../../Component/SelectComponent";
import CheckboxComponent from '../../../Component/CheckboxComponent';

function Organism(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez l\'organisme principal.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        isInstitution: {
            name: 'isInstitution',
            label: 'Établissement',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez le Email de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le téléphone de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        numberFormationDPC: {
            name: 'numberFormationDPC',
            label: 'Numéro formation DPC',
            textHelper: 'Saisissez le numéro formation DPC de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        numberAPE: {
            name: 'numberAPE',
            label: 'Numéro APE',
            textHelper: 'Saisissez le numéro APE de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        numberDPC: {
            name: 'numberDPC',
            label: 'Numéro DPC',
            textHelper: 'Saisissez le numéro DPC de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        numberTVA: {
            name: 'numberTVA',
            label: 'Numéro TVA',
            textHelper: 'Saisissez le numéro TVA de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        stamp: {
            name: 'stamp',
            label: 'Signature',
            textHelper: 'Saisissez la signature de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        responsible: {
            name: 'responsible',
            label: 'Responsable',
            textHelper: 'Saisissez le responsable de cette organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        image: {
            name: 'image',
            label: 'image',
            textHelper: 'Saisissez l\'image de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        footer: {
            name: 'footer',
            label: 'Footer',
            textHelper: 'Saisissez le footer de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        acronym: {
            name: 'acronym',
            label: 'Acronyme',
            textHelper: 'Saisissez l\'acronyme de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        siret: {
            name: 'siret',
            label: 'Siret',
            textHelper: 'Saisissez le Siret de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code Zip',
            textHelper: 'Saisissez le code zip de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez l\'adresse de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        url: {
            name: 'url',
            label: 'Url',
            textHelper: 'Saisissez l\'url de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        iban: {
            name: 'iban',
            label: 'IBAN',
            textHelper: 'Saisissez l\'iban de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        bic: {
            name: 'bic',
            label: 'BIC',
            textHelper: 'Saisissez l\'bic de l\'organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'administrator_organism_edit',
                    params: { id: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'administrator_organism_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('administrator_organism', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Organisme créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un organisme',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_organism_list'), label: 'Liste des organismes' }
                ]
            });
            setIsEdition(true);

            Api.get({
                route: 'administrator_organism_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('administrator_organism_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un organisme',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_organism_list'), label: 'Liste des organismes' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        Api.get({
            route: 'select_organisms'
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'un organisme' : 'Formulaire de création d\'un organisme'} />

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'name'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <TextFieldComponent name={'acronym'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
                                <CheckboxComponent name={'isInstitution'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <SelectComponent name={'organism'} handler={handler} options={optionOrganism} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'responsible'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'email'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'phone'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'city'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <TextFieldComponent name={'address'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'url'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <TextFieldComponent name={'numberFormationDPC'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <TextFieldComponent name={'numberAPE'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <TextFieldComponent name={'numberDPC'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <TextFieldComponent name={'numberTVA'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'siret'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'zipCode'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'stamp'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'image'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                <TextFieldComponent name={'footer'} handler={handler} multiline={true} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'iban'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'bic'} handler={handler} />
                            </Grid>
                        </Grid>
                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                    </ShadowBoxComponent>
                </div>
            </Fade>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default Organism;
