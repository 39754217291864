import React from 'react';
import { Box, makeStyles } from "@material-ui/core";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { useDispatch, useSelector } from "react-redux";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import { theme } from "../../../App";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import ToggleButtonGroupComponent from "../../../Component/ToggleButtonGroupComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import ReCAPTCHA from "react-google-recaptcha";
import ParticlesComponent from "../../../Component/ParticlesComponent";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from "../../../Config/Route";

function RegisterPatati(props) {

    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(theme, screenReducer)();
    const [show, setShow] = React.useState(true);
    const [formStep, setFormStep] = React.useState(1);
    const [isReCAPTCHA, setIsReCAPTCHA] = React.useState(null);
    const [formation, setFormation] = React.useState(null);
    const [groupFormation, setGroupFormation] = React.useState(null);
    const [groupFormationApi, setGroupFormationApi] = React.useState(null);
    const [currentPrice, setCurrentPrice] = React.useState(0);
    const [finance, setFinance] = React.useState(null);
    const [optionGroupFormation, setOptionGroupFormation] = React.useState([]);
    const [optionGroupFinance, setOptionGroupFinance] = React.useState(null);
    const [formations, setFormations] = React.useState(null);
    const [progressFormStatus, setProgressFormStatus] = React.useState(1);
    const [personalPaymentType, setPersonalPaymentType] = React.useState(null);
    const [price, setPrice] = React.useState(0);
    const [isDescription, setIsDescription] = React.useState(false);
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState(null);
    const [loadingSecondForm, setLoadingSecondForm] = React.useState(false);
    const [promo, setPromo] = React.useState({ percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0 });
    const [loading, setLoading] = React.useState(false);

    const [form, setForm] = React.useState({
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez votre nom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez votre prénom.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez votre email.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required', 'email'] }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez votre numéro de téléphone.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez votre code postal.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez votre ville.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez votre adresse.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        conditions: {
            name: 'conditions',
            label: <span style={{ fontSize: 12 }}>
                En cochant la case ci-contre, je déclare avoir lu et accepté la déclaration de
                <MediaLinkComponent
                    style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                    path={theme.dataProtection}
                    type={1}
                > protection des données </MediaLinkComponent>
                ainsi que les
                <MediaLinkComponent
                    style={{ color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer' }}
                    path={theme.termsConditions}
                    type={1}
                > conditions générales </MediaLinkComponent>
                de {theme.name} ainsi que la création d'un compte sur la plateforme {theme.name} me donnant accès à l'ensemble des ressources.
            </span>,
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const [formSecondStep, setFormSecondStep] = React.useState({
        groupFinance: {
            name: 'groupFinance',
            label: 'Sélectionnez votre mode de financement :',
            textHelper: '',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Sélectionnez votre groupe de formation :',
            textHelper: '',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Sélectionnez le type de paiement :',
            textHelper: '',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        promotionCodeStripe: {
            name: 'promotionCodeStripe',
            label: 'Code promotionnel',
            textHelper: 'Entrer un code promotionel',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    const handlerSecondStep = formHandlerInit(formSecondStep, setFormSecondStep);

    React.useEffect(handler.start, []);
    React.useEffect(handlerSecondStep.start, []);
    React.useEffect(() => {
        setLoading(true);
        handler.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());
        let params = new URLSearchParams(props.location.search);

        Api.get({
            route: 'enrollment_register_formation_get',
            data: { organism: theme.id },
            params: { formation: params.get('fid') }
        },
            (response) => {
                if (response.status === 200) {
                    let data = response.data;
                    setFormation(data.formation);
                    let financeType = [];
                    for (let index in data.groupFinance) {
                        financeType.push(data.groupFinance[index]);
                    }
                    setOptionGroupFormation([
                        {value: 13, label: 'PARTICULIER' },
                        {value: 9, label: 'ORTHOPHONISTE' },
                    ]);
                    setOptionGroupFinance(financeType);
                    setOptionPersonalPaymentType(data.formation.personalPaymentType);
                    handlerSecondStep.setDataApi(response.data.formation.default);
                }
                else if (response.status === 404) {
                    props.history.push('/authentication/login');
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    props.history.push('/authentication/login');
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    }, []);

    // SUBMIT
    const [disabled, setDisabled] = React.useState(false);
    const [disabledSecondStep, setDisabledSecondStep] = React.useState(false);

    const firstStep = () => {
        if (handler.checkError() < 1) {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                route: 'reporting_marketing_form_send',
                data: {
                    ...data,
                    organism: theme.id,
                    formation: formation.id,
                    origin: 'enrolment'
                }
            }, (response) => {
                if (response.status === 201) {
                    handler.setFormLoading(false);
                    setLoading(false);
                    setFormStep(formStep + 1);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                    handler.setFormLoading(false);
                    setLoading(false);
                } else {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const secondStep = () => {
        if (handlerSecondStep.checkError() < 1) {
            handlerSecondStep.setFormLoading(true);
            setLoading(true);
            let firstForm = handler.getData();
            let data = handlerSecondStep.getData();
            let encodeEmail = encodeURIComponent(firstForm.email);

            if (data.groupFinance === 99) {
                data.groupFinance = 6;
                data.isFifpl = true;
            }
            Api.post({
                route: 'enrollment_register_create',
                data: {
                    ...data,
                    groupFormation: 9,
                    email: firstForm.email,
                    organism: theme.id,
                    formations: [formation.id]
                }
            }, (response) => {
                if (response.status === 201) {
                    if (response.data.sessionIdStripe) {
                        props.history.push(getRoutePathname('payment_stripe'),
                            {
                                sessionIdStripe: response.data.sessionIdStripe,
                                clientSecret: response.data.clientSecret,
                            });
                    }
                    Api.get({
                        route: 'enrollment_register_formation_list',
                        data: {
                            ...data,
                            email: encodeEmail,
                            organism: theme.id,
                            formation: formation.id
                        }
                    },
                        (response) => {
                            setLoading(false);
                            handlerSecondStep.setFormLoading(false);

                            if (response.status === 200) {
                                if (response.data && response.data.length > 0) {
                                    setFormations(response.data.formations);
                                    setGroupFormationApi(response.data.formationGroup);
                                }
                                else {setProgressFormStatus(3);}
                            }
                            else if (response.status === 404) {
                                props.history.push('/authentication/login');
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.message,
                                        variant: 'warning',
                                    })
                                );
                            }
                            else {
                                props.history.push('/authentication/login');
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });

                } else if (response.status === 400) {
                    handlerSecondStep.setErrorApi(response.error);
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                } else {
                    handlerSecondStep.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const goHome = () => {
        let params = new URLSearchParams(props.location.search);
        window.location.href = params.get('url') ? params.get('url') : theme.website;
    }

    const actionResume = () => {
        return (
            (formStep === 1) ?
                <div className={`${hasShowImage ? classes.containerResume : classes.containerResumeSmallScreen}`}>
                    <div className={classes.Resume}>
                        <p style={{ margin: '0', fontSize: '25px', color: '#333', padding: '20px 0', fontWeight: 'bold' }}>Résumé</p>
                        <div style={{ margin: '0', color: '#333', backgroundColor: '#FFF', padding: '20px 0' }}>
                            <p className={classes.title}>Formation choisie</p>
                            <p className={classes.info}>{(formation == null) ? '-' : formation.name}</p>
                        </div>
                    </div>
                    {hasShowImage && <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo} />}
                </div>
                :
                <div className={`${hasShowImage ? classes.containerResume : classes.containerResumeSmallScreen}`}>
                    <div className={classes.Resume}>
                        <p style={{ margin: '0', fontSize: '25px', color: '#333', padding: '20px 0', fontWeight: 'bold' }}>Résumé</p>
                        <div style={{ margin: '0', color: '#333', backgroundColor: '#FFF', padding: '20px 0' }}>
                            <p className={classes.title}>Formation choisie</p>
                            <p className={classes.info}>{(formation == null) ? '-' : formation.name}</p>
                            <p className={classes.title}>Vous êtes</p>
                            <p className={classes.info}>{(groupFormation == null) ? '-' : getGroupFormationById(groupFormation)}</p>
                            <p className={classes.title}>Mode de financement</p>
                            <p className={classes.info}>{(finance == null) ? '-' : getGroupFinanceById(finance)}</p>
                        </div>
                        {formation && promo && promo.percent ?
                            <p style={{ margin: '0', fontSize: '15px', color: '#333', padding: '20px 0' }}>
                                Prix : <span style={{ fontSize: '15px', fontWeight: 600 }}>{promo.originalPrice}€</span><br />
                                Remise : <span style={{ fontSize: '15px', fontWeight: 600 }}>({promo.percent}%) {promo.discountAmount}€</span><br />
                                Reste à charge : <span style={{ fontSize: '15px', fontWeight: 600 }}>{promo.remisedPrice}€</span>
                            </p>
                            :
                            <p style={{margin: '0', fontSize: '15px', color: '#333', padding: '20px 0'}}>
                                Prix : <span style={{fontSize: '15px',}}>{(formation == null) ? '0' : price}€</span><br/>
                                <span style={{margin: '0', fontSize: '15px'}}><b>Reste à charge : {currentPrice}€</b><sup style={{fontSize: '10px'}}>(1)</sup></span><br/><br/>
                            </p>
                        }
                    </div>
                    {hasShowImage && <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo} />}
                </div>

        )
    }

    const buttonSave = () => {
        return (
            (formStep === 1) ?
                <div style={{ textAlign: 'right', width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                    <ButtonComponent style={{ marginTop: 14, background: theme.colorPrimary }} type="submit" label="Suivant" className={classes.button} onClick={firstStep} disabled={loading || disabled || isReCAPTCHA === null} />
                </div> : (formStep === 2) ?
                    <div style={{ textAlign: 'right', width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                        <ButtonComponent style={{ marginTop: 14, background: theme.colorPrimary }} disabled={loadingSecondForm || disabledSecondStep} type="submit" label="Finaliser" className={classes.button} onClick={secondStep} />
                    </div> : <></>
        )
    }

    React.useEffect(() => {
        setDisabled(!form.conditions.value);
    }, [form.conditions.value]);

    React.useEffect(() => {
        if (finance === 6 || finance === 99) {
            if (!personalPaymentType && optionPersonalPaymentType && optionPersonalPaymentType.length > 0) {
                setPersonalPaymentType(optionPersonalPaymentType[0].value)
                formSecondStep.personalPaymentType.value = optionPersonalPaymentType[0].value
            }
            if (formation !== null) {
                if (personalPaymentType && formation.personalPrice) {
                    setPrice(formation.personalPrice[personalPaymentType]);
                    setCurrentPrice(formation.personalPrice[personalPaymentType]);
                }
                else {
                    setPrice(formation.price);
                    setCurrentPrice(formation.price);
                }
            }
        }
        else {
            if (formation !== null) {
                let currentPriceTmp = 0;
                if (formation.andpcSupport) {
                    currentPriceTmp = formation.price - formation.andpcSupport;
                    if (currentPriceTmp <= 0) {
                        currentPriceTmp = 0;
                    }
                    setCurrentPrice(currentPriceTmp);
                }
                setPrice(formation.price);
            }
        }

        if (promo && promo.percent) {
            changePriceByPromo(promo.percent);
        }
    }, [finance, personalPaymentType]);

    React.useEffect(() => {
        if (formations != null && groupFormationApi != null) {
            if (finance === 6 || finance === 99) {
                setProgressFormStatus(3);
            }
        }
    }, [formations, groupFormationApi]);
    
    const getGroupFormationById = (id) => {
        for (let groupFormation of optionGroupFormation) {
            if (groupFormation.value === id) {
                return groupFormation.label;
            }
        }
        return null;
    };
    const getGroupFinanceById = (id) => {
        for (let groupFinance of optionGroupFinance) {
            if (groupFinance.value === id) {
                return groupFinance.label;
            }
        }
        return null;
    };

    const changePriceByPromo = (percent) => {
        if (formation !== null && personalPaymentType) {
            let originalPrice = formation.personalPrice[personalPaymentType];
            let discountAmount = originalPrice * (percent / 100);
            let remisedPrice = originalPrice - discountAmount;
            setPromo({
                percent: percent,
                remisedPrice: remisedPrice,
                originalPrice: originalPrice,
                discountAmount: discountAmount
            });
        }
    };

    const applyCode = () => {
        let data = handlerSecondStep.getData();
        if (!data.personalPaymentType) {
            handlerSecondStep.setError('personalPaymentType', 'Veuillez entrer un type de paiement avant de valider');
            return;
        }
        if (data.promotionCodeStripe) {
            handlerSecondStep.setFormLoading(true);
            setLoadingSecondForm(true);
            Api.get({
                route: 'stripe_promotion_code_verify',
                data: {
                    organism: theme.id,
                    code: data.promotionCodeStripe,
                    formation: formation.id
                },
            },
                (response) => {
                    if (response.status === 200) {
                        changePriceByPromo(response.data.percent);
                    }
                    else if (response.status === 400) {
                        handlerSecondStep.setValue('promotionCodeStripe', '');
                        handlerSecondStep.setErrorApi(response.error);
                        setPromo({ percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0 });
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    handlerSecondStep.setFormLoading(false);
                    setLoadingSecondForm(false);
                });
        }
        else {
            handlerSecondStep.setError('promotionCodeStripe', 'Veuillez entrer un code avant de valider');
        }
    };

    // SCREEN
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [hasShowImage, setHasShowImage] = React.useState(false);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'SM' || screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);

            let hasShowImageTmp = screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
            if (hasShowImageTmp !== hasShowImage) setHasShowImage(hasShowImageTmp);
        }
    }, [screenReducer.screen]);

    return (
        <div className={classes.content}>
            {!isSmallScreen && <ParticlesComponent loading={loading} />}
            {
                loading === true ?
                    <div style={{ textAlign: 'center', margin: 'auto', height: '100%' }}>
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallScreen ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    : loading === false ?
                        <>
                            <Fade in={show} {...{ timeout: 750 }}>
                                {
                                    progressFormStatus === 1 ?
                                        <>
                                            <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>

                                                <div style={{
                                                    fontSize: 25,
                                                    margin: 14,
                                                    width: hasShowImage ? 'calc(100% - 388px)' : '100%',
                                                    textAlign: 'left',
                                                    color: theme.colorPrimary,
                                                    fontWeight: 'bold'
                                                }}>
                                                    Formulaire d'inscription - <span style={{ fontSize: 20 }}>{formStep}/2</span>
                                                </div>

                                                {
                                                    (formStep === 1) ?
                                                        (
                                                            <>
                                                                <div style={{ width: hasShowImage ? 'calc(100% - 388px)' : '100%' }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'lastname'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'firstname'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'email'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'phone'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'zipCode'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={6}>
                                                                            <TextFieldComponent name={'city'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid className={classes.MuiOutlinedInput} item xs={12}>
                                                                            <TextFieldComponent name={'address'} handler={handler} />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <CheckboxComponent name={'conditions'} handler={handler} direction={'right'} />
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div style={{ margin: 'auto', width: 304, height: 78 }}>
                                                                                <ReCAPTCHA
                                                                                    sitekey="6LcGd24qAAAAAF0JZw5ee6Cnp_Nx0yLG1jgx0aGH"
                                                                                    onChange={(value) => setIsReCAPTCHA(value)}
                                                                                />
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                {hasShowImage && buttonSave()}
                                                            </>
                                                        )
                                                        : (formStep === 2) ? (
                                                            <>
                                                                <div style={{ width: hasShowImage ? 'calc(100% - 388px)' : '100%', textAlign: 'left' }}>
                                                                    
                                                                    <div style={{ display: 'block', padding: '15px' }}>
                                                                        <ToggleButtonGroupComponent name={'formationGroup'} handler={handlerSecondStep} options={optionGroupFormation} onChange={(event) => (setGroupFormation(event))} />
                                                                    </div>
                                                                    <div style={{ display: 'block', padding: '15px' }}>
                                                                        <ToggleButtonGroupComponent name={'groupFinance'} handler={handlerSecondStep} options={optionGroupFinance} onChange={(event) => (setFinance(event))} />
                                                                    </div>
                                                                    {
                                                                        (finance === 6 || finance === 99) && <>
                                                                            {optionPersonalPaymentType && optionPersonalPaymentType.length > 0 ?
                                                                                <>
                                                                                    <div style={{ display: 'block', padding: '0px 15px' }}>
                                                                                        <ToggleButtonGroupComponent name={'personalPaymentType'} handler={handlerSecondStep} options={optionPersonalPaymentType} onChange={(event) => { setPersonalPaymentType(event) }} />
                                                                                    </div>
                                                                                    <div className={classes.MuiOutlinedInput} style={{ display: 'block', padding: '0px 10px' }}>
                                                                                        <h4 style={{ fontSize: '10px', margin: '5px', }}>Saisissez un code promotionel puis valider pour appliquer la remise (facultatif) :</h4>
                                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                            <TextFieldComponent name={'promotionCodeStripe'} handler={handlerSecondStep} />
                                                                                            <ButtonComponent style={{ margin: '0px 0px 10px 10px', borderRadius: 20, padding: '2px 15px', background: theme.colorPrimary, fontSize: 10 }} label="Valider" disabled={loadingSecondForm} onClick={applyCode} />
                                                                                        </div>

                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <div style={{ display: 'block', padding: '0px 10px' }}>
                                                                                    <p>Aucun moyen de paiement disponible.</p>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                                {hasShowImage && buttonSave()}
                                                            </>
                                                        ) : <></>

                                                }
                                                {hasShowImage && actionResume()}
                                            </ShadowBoxComponent>
                                            {!hasShowImage &&
                                                <Box className={classes.containerDescription}>
                                                    <div className={'root-resume'} style={isDescription ? { transform: 'translate(0%)', visibility: 'visible', opacity: 1 } : { transform: 'translate(100%)', visibility: 'hidden', opacity: 1 }}>{actionResume()}</div>
                                                    <Box className={classes.containerButton}>
                                                        <div>
                                                            <Box className={'root-containerDetail'} style={{ marginBottom: isDescription ? '-20%' : '0', visibility: isDescription ? 'hidden' : 'visible', opacity: isDescription ? 0 : 1 }}>
                                                                <p>
                                                                    <span>Formation choisie</span>
                                                                    {(formation == null) ? '-' : formation.name}
                                                                </p>
                                                            </Box>
                                                        </div>
                                                        <Box className={'root-containerButton'}>
                                                            {buttonSave()}
                                                            <ButtonComponent style={{}} type="submit" label="Résumé+" className={classes.button} onClick={() => { setIsDescription(!isDescription) }} disabled={loading} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            }
                                        </>
                                        : progressFormStatus === 3 ?
                                                <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>
                                                    <div style={{
                                                        fontSize: 25,
                                                        margin: 14,
                                                        width: '100%',
                                                        textAlign: 'center',
                                                        color: theme.colorPrimary,
                                                        fontWeight: 'bold'
                                                    }}>
                                                        Votre demande d'inscription a bien été prise en compte
                                                    </div>
                                                    <p style={{ textAlign: 'left' }}>Merci pour votre inscription ! Un conseiller va vous recontacter rapidement pour finaliser cette dernière.</p>

                                                   
                                                    <p style={{ textAlign: 'left' }}>Un courriel vous a été envoyé contenant toutes les informations nécessaires à la finalisation de votre inscription. </p>
                                                    <div style={{ textAlign: 'center', width: '100%', marginTop: 20 }}>
                                                        <ButtonComponent style={{ margin: 15, background: theme.colorPrimary }} type="submit" label="ACCUEIL du site" className={classes.button} onClick={goHome} />
                                                        <ButtonComponent style={{ margin: 15, background: theme.colorPrimary }} label="Accès platform" className={classes.button} onClick={() => { props.history.push('/authentication/login'); }} />
                                                    </div>
                                                </ShadowBoxComponent>
                                                : <></>
                                }
                            </Fade>
                        </> : <></>
            }
        </div >
    );
}

const useStyles = (theme, screenReducer) => makeStyles({
    MuiOutlinedInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px !important',
        },
    },
    title: {
        fontSize: '10px',
        margin: 0,
    },
    info: {
        fontSize: '15px',
        padding: '5px 10px',
        background: '#E6E6E6',
        width: '200px',
        margin: '5px auto 15px auto',
        borderRadius: '10px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden auto',
        boxSizing: 'border-box'
    },
    shadowBox: {
        padding: 25,
        height: 'auto',
        margin: 'auto',
        width: 'calc(100vw - 200px)',
        maxWidth: 1000,
        textAlign: 'center',
        boxSizing: 'border-box',
        minHeight: '400px'
    },
    containerDescription: {
        '& .root-resume': {
            transition: 'all .4s'
        }
    },
    containerButton: {
        background: '#FFF',
        position: 'relative',
        zIndex: '10000',
        boxShadow: '0px -2px 0px #00000024',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
        padding: 10,
        '& .root-containerDetail': {
            width: 'fit-content',
            margin: 'auto',
            padding: '10px 30px',
            background: '#e3eaf4',
            borderRadius: 5,
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: 13,
            transition: 'all .4s',
            '& p': {
                width: 'fit-content',
                margin: 'auto',
                display: 'block',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                '& span': {
                    display: 'block',
                    fontSize: 10,
                }
            },
        },
        '& .root-containerButton': {
            display: 'flex',
            gap: 10,
            alignItems: 'center',
            flexWrap: 'wrap',
            width: 'fit-content',
            margin: 'auto',
            '& >div': {
                width: 'initial !important'
            },
            '& button': {
                margin: '0 !important'
            }
        }
    },
    shadowBoxSmall: {
        padding: 25,
        height: '100vh',
        overflow: 'scroll',
        width: '100vw',
        margin: 'auto',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    containerResume: {
        height: '100%',
        width: 388,
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        background: 'linear-gradient(to bottom, rgba(231, 62, 1, 0.75), rgba(231, 62, 1, 0))'
    },
    containerResumeSmallScreen: {
        right: 0,
        width: (screenReducer.screen === 'SM' || screenReducer.screen === 'XS') ? '100%' : 388,
        height: 'fit-content',
        position: 'absolute',
        display: 'flex',
        background: 'linear-gradient(to bottom, rgba(231, 62, 1, 0.75), rgba(231, 62, 1, 0))',
        borderRadius: '5px 0 0 0',
        textAlign: 'center',
        bottom: 0,
        padding: '30px 0',
    },
    Resume: {
        height: 'auto',
        width: '80%',
        margin: 'auto',
        backgroundColor: '#E6E6E6',
        borderRadius: '5px',
        boxShadow: '0px 0px 3px rgba(0,0,0,.2)'
    },
    logo: {
        width: '65%',
        maxWidth: 130,
        margin: 'auto',
        position: 'absolute',
        bottom: '20px',
        right: '20px'
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    }
});

export default RegisterPatati;
