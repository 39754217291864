import React from 'react'

export default function LoopComponent(props) {
    const [loop, setLoop] = React.useState(false);

    React.useEffect(() => {
        let loopTmp = false;
        const loopTimeout = setInterval(() => {
            setLoop(loopTmp);
            loopTmp = !loopTmp;
        }, 3000);
        return () => clearInterval(loopTimeout);
    }, []);

    return (
        <>
            <div hidden={loop}>
                {props.children}
            </div>
            <div hidden={!loop}>
                {props.children}
            </div>
        </>
    )
}