import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import Grid from "@material-ui/core/Grid";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import { TableReload } from "../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { inArray } from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import FlipCardComponent from '../../../Component/FlipCardComponent';
import { theme } from '../../../App';
import ButtonComponent from '../../../Component/ButtonComponent';
import { Drawer as DrawerMUI } from "@material-ui/core";

function BillingListV2(props) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [loadDownload, setLoadDownload] = React.useState(false);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [optionEndMonth, setOptionEndMonth] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Janvier' },
        { value: 2, label: 'Février' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Avril' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juin' },
        { value: 7, label: 'Juillet' },
        { value: 8, label: 'Août' },
        { value: 9, label: 'Septembre' },
        { value: 10, label: 'Octobre' },
        { value: 11, label: 'Novembre' },
        { value: 12, label: 'Décembre' },
    ]);
    const [flip, setFlip] = React.useState(false);
    const [buttonflipText, setButtonFlipText] = React.useState('Choisir par mois');
    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "andpc",
            label: "Numéro de programme DPC",
            options: { filter: true, sort: true }
        },
        {
            name: "startAt",
            label: "Début de session",
            options: { filter: true, sort: true }
        },
        {
            name: "endAt",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "percentageComplete",
            label: "Ratio formations terminées",
            options: { filter: true, sort: true }
        },
        {
            name: "numberEntered",
            label: "Nombre d'inscrits",
            options: { filter: false, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('financial_management_billing_v2', { id: row.id }))}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].organism ? cacheTableServer['financial_management_billing_v2_session_formation_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].formationGroup ? cacheTableServer['financial_management_billing_v2_session_formation_list'].formationGroup : 'all',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].andpc ? cacheTableServer['financial_management_billing_v2_session_formation_list'].andpc : '',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].startAt ? cacheTableServer['financial_management_billing_v2_session_formation_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].endAt ? cacheTableServer['financial_management_billing_v2_session_formation_list'].endAt : '',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].state ? cacheTableServer['financial_management_billing_v2_session_formation_list'].state : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].year ? cacheTableServer['financial_management_billing_v2_session_formation_list'].year : currentYear,
            options: {}
        },
        monthEndAt: {
            name: 'monthEndAt',
            label: 'Mois de fin de session.',
            textHelper: 'Choisissez un mois.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_v2_session_formation_list'] && cacheTableServer['financial_management_billing_v2_session_formation_list'].monthEndAt ? cacheTableServer['financial_management_billing_v2_session_formation_list'].monthEndAt : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['financial_management_billing_v2_session_formation_list'] = 0;
        cacheTableServer['financial_management_billing_v2_session_formation_list'].page = 0;
        cacheTableServer['financial_management_billing_v2_session_formation_list'][index] = value;
        dispatch(TableReload('financial_management_billing_v2_session_formation_list'))
    };

    const GetActionDowlaod = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtres</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    const filter = () => {
        return (
            <Box style={{ width: '100%', height: '100%' }}>
                <Grid container spacing={0} style={{ width: '100%', height: '100%', margin: 'auto', padding: '45px 25px' }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <TextFieldComponent name={'andpc'} handler={handlerSearch} onChange={(val) => inputSearchChange('andpc', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <SelectComponent name={'state'} handler={handlerSearch} options={optionProspectStates} onChange={(val) => inputSearchChange('state', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                        <FlipCardComponent
                            flip={flip}
                            frontCard={
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 50}}>
                                        <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                                    </Grid>
                                </Grid>
                            }
                            backCard={
                                <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0}}>
                                    <SelectComponent name={'monthEndAt'} handler={handlerSearch} options={optionEndMonth} onChange={(val) => inputSearchChange('monthEndAt', val)} />
                                </Grid>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{height: 0, marginTop: 20}}>
                        <Button
                            color="primary"
                            onClick={
                                () => {
                                    if (flip) {
                                        handlerSearch.setValue('monthEndAt', 'all')
                                        setButtonFlipText('Choisir par mois')

                                    }
                                    else {
                                        handlerSearch.setValue('startAt', null)
                                        handlerSearch.setValue('endAt', null)
                                        setButtonFlipText('Choisir par periode')
                                    }
                                    dispatch(TableReload('crm_relaunch_registration_list'));
                                    setFlip(!flip);
                                }
                            }
                        >{buttonflipText}</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            disabled={loadDownload}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                let data = handlerSearch.getData();
                                setLoadDownload(true);
                                handlerSearch.setFormLoading(true);
                                Api.getDownload({
                                    route: 'financial_management_billing_v2_session_formation_list', filename: 'export_sessions_formations.xls', data: {
                                        year: data.year ?? currentYear,
                                        andpc: data.andpc,
                                        startAt: data.startAt ?? '',
                                        endAt: data.endAt ?? '',
                                        monthEndAt: (data.monthEndAt === 'all') ? '' : data.monthEndAt,
                                        organism: (data.organism === 'all') ? '' : data.organism,
                                        formationGroup: (data.formationGroup === 'all') ? '' : data.formationGroup,
                                        state: (data.state === 'all') ? '' : data.state,
                                    }
                                }, (response) => {
                                    if (response.status === 200) {
                                        setLoadDownload(false);
                                        handlerSearch.setFormLoading(false);
                                    }})
                            }}>
                            Télécharger au format Excel
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const table = () => {
        return (
                <TableComponent
                    id={'financial_management_billing_v2_session_formation_list'}
                    minHeight={'calc(100vh - 515px)'}
                    title={'Sessions'}
                    columns={columns}
                    promiseServerData={(resolve, options) => {
                        let datas = handlerSearch.getData();
                        let filters = {
                            limit: options.rowsPerPage,
                            offset: options.page * options.rowsPerPage,
                            sortName: options.sortOrder.name,
                            sortDirection: options.sortOrder.direction,
                            year: datas.year ?? currentYear,
                            andpc: datas.andpc ?? '',
                            startAt: datas.startAt ?? '',
                            endAt: datas.endAt ?? '',
                            monthEndAt: (datas.monthEndAt === 'all') ? '' : datas.monthEndAt,
                            organism: (datas.organism === 'all') ? '' : datas.organism,
                            formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                            state: (datas.state === 'all') ? '' : datas.state,
                        };
                        Api.get({
                            route: 'financial_management_billing_v2_session_formation_list',
                            data: filters
                        },
                            (response) => {
                                let data = response.data.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    let color = '#000';
                                    switch (data[index].stateId) {
                                        case 1:
                                            color = '#007bff';
                                            break;
                                        case 2:
                                            color = '#28a745';
                                            break;
                                        case 3:
                                            color = '#db770a';
                                            break;
                                        case 4:
                                            color = '#9c27b0';
                                            break;
                                    }
                                    data[index].state = <span style={{ color: color, width: 85, display: 'block' }}>{data[index].stateLabel}</span>;
                                    data[index].formationName = <span style={{ width: 270, display: 'block' }}>{data[index].formationName}</span>;
                                }
                                resolve(data, response.data.count);
                            });
                    }}
                    search={false}
                />
        )
    }

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Facturation V2',
            context: 'Gestion financière',
            description: 'Liste des sessions formation.',
            html: <GetActionDowlaod />
        });
    }, [isOpen]);

    React.useEffect(() => {
        // Stats
        Api.get({
            route: 'select_sessions_formations_state'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionProspectStates(data);
                }
            });
        // Year
        let yearsTMP = [];
        yearsTMP.push({ label: (currentYear + 1), value: (currentYear + 1) });
        for (let i = currentYear; i > currentYear - 1; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);
        // Organisms
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });
        // Formation groups
        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormationGroup(data);
                }
            });
        setReadyContent(true);
    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    {table()}
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = (theme) => makeStyles(style => ({
    TableComponent: {
        height: '95%',
        width: '100%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
}))

export default BillingListV2;
