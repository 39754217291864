import React from 'react';
import { makeStyles } from "@material-ui/core";
import Api from '../../../../Api';
import TableComponent from '../../../../Component/TableComponent';

function RegistrationLogs(props) {
    const classes = useStyles();
    const [totalTime, setTotalTime] = React.useState();
    const [loadDownload, setLoadDownload] = React.useState(false);
    const columns = [
        {
            name: 'startAt',
            label: 'Début de connexion',
            options: { filter: true, sort: true }
        },
        {
            name: 'endAt',
            label: 'Fin de connexion',
            options: { filter: true, sort: true }
        },
        {
            name: 'time',
            label: 'Temps',
            options: { filter: true, sort: true }
        },
        {
            name: 'ip',
            label: 'Adresse IP',
            options: { filter: true, sort: true }
        },
        {
            name: 'device',
            label: 'Appareil',
            options: { filter: true, sort: true }
        },
        {
            name: 'navigator',
            label: 'Navigateur',
            options: { filter: true, sort: true }
        },
        {
            name: 'type',
            label: 'Type',
            options: { filter: true, sort: true }
        },
        {
            name: 'course',
            label: 'Référence du cours',
            options: { filter: true, sort: true }
        },
    ];

    return (
        <>
            <div className={classes.containerInfo}>
                <p><span style={{fontWeight: 600}}>Registration n° </span> {props.registration}</p>
                <p><span style={{fontWeight: 600}}>Temps total : </span> {totalTime ? totalTime : '-'}</p>
            </div>
            
            <TableComponent
                id={'crm_prospect_registration_logs_list'}
                title={'Détail'}
                columns={columns}
                actionFirst={{
                    label: 'Export',
                    disabled: loadDownload,
                    onClick: () => {
                        setLoadDownload(true);
                        Api.getDownload({
                            route: 'crm_prospect_registration_logs_list',
                            filename: 'export_logs_'+ props.registration +'.xls',
                            params: { prospect: props.prospect, registration: props.registration }
                        }, (response) => {
                            if (response.status === 200) {
                                setLoadDownload(false);
                            }
                        })
                    }
                }}
                promiseData={(resolve) => {
                    if (props.registration && props.prospect) {
                        Api.get({
                            route: 'crm_prospect_registration_logs_list',
                            params: { prospect: props.prospect, registration: props.registration }
                        },
                            (response) => {
                                if (response.data) {
                                    setTotalTime(response.data.total);

                                    let data = response.data.data;
                                    for (let index in data) {
                                        data[index].device = data[index].device ? data[index].device : '-';
                                        data[index].navigator = data[index].navigator ? data[index].navigator : '-';
                                        data[index].type = data[index].type ? data[index].type : '-';
                                        data[index].course = data[index].course ? data[index].course : '-';
                                    }
                                    resolve(data);
                                } else {
                                    resolve([]);
                                }
                            })
                    } else {
                        resolve([]);
                    }
                }}
            />

        </>
    );
}

const useStyles = makeStyles({
    containerInfo: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    }
});

export default RegistrationLogs;
