import React from 'react';
import { makeStyles } from "@material-ui/core";
import Api from '../../../../Api';
import TableComponent from '../../../../Component/TableComponent';

function RegistrationScoreAdmin(props) {
    const classes = useStyles();
    const columns = [
        {
            name: 'course',
            label: 'Cours',
            options: { filter: true, sort: true }
        },
        {
            name: 'score',
            label: 'Score',
            options: { filter: true, sort: true }
        },
        {
            name: 'adminName',
            label: 'Nom Admin',
            options: { filter: true, sort: true }
        },
        {
            name: 'adminEmail',
            label: 'Email Admin',
            options: { filter: true, sort: true }
        },
        {
            name: 'date',
            label: 'Date',
            options: { filter: true, sort: true }
        }
    ];

    return (
        <>
            <TableComponent
                id={'crm_prospect_registration_score_admin_list'}
                title={'Détail'}
                columns={columns}
                promiseData={(resolve) => {
                    if (props.registration && props.prospect) {
                        Api.get({
                            route: 'crm_prospect_registration_score_admin_list',
                            params: { prospect: props.prospect, registration: props.registration }
                        },
                            (response) => {
                                if (response.data) {

                                    let data = response.data;
                                    for (let index in data) {

                                    }
                                    resolve(data);
                                } else {
                                    resolve([]);
                                }
                            })
                    } else {
                        resolve([]);
                    }
                }}
            />

        </>
    );
}

const useStyles = makeStyles({
    
});

export default RegistrationScoreAdmin;
