import React from 'react';
import ReactDOM from 'react-dom';
import './Css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import Store from "./Store";

const appRoot = document.getElementById('root');
appRoot.setAttribute('class', 'notranslate');
appRoot.setAttribute('translate', 'no');

ReactDOM.render(
    <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persistor}>
            <App/>
        </PersistGate>
    </Provider>,
    appRoot
);

reportWebVitals();
