import { makeStyles } from '@material-ui/core';
import React from 'react'
import { useSelector } from 'react-redux';
import Particles from 'react-tsparticles';

let timeoutClose = false;

export default function FireworksComponent(props) {
    const classes = useStyles();
    const fireworksReducer = useSelector(state => state.FireworksReducer);
    const [isActivatedFireworks, setActivatedFireworks] = React.useState(false);

    React.useEffect(() => {
        setActivatedFireworks(fireworksReducer.isActivated);

        if (timeoutClose) clearTimeout(timeoutClose)
        timeoutClose = setTimeout(() => {
            setActivatedFireworks(false);
        }, fireworksReducer.duration);
    }, [fireworksReducer]);

    return (
        (isActivatedFireworks) ?
            <div className={classes.container}>
                {
                    fireworksReducer.text &&
                    <p className={classes.text}>
                        {fireworksReducer.text}
                    </p>
                }
                <Particles
                    options={{
                        fpsLimit: 60,
                        key: "fireworks",
                        name: "Fireworks",
                        fullScreen: {
                            zIndex: 1
                        },
                        particles: {
                            number: {
                                value: 0
                            },
                            color: {
                                value: [
                                    "#ee64ec",
                                    "#817dff",
                                    "#7dffef",
                                    "#efec2c",
                                    "#f5291f",
                                    "#64ee83"
                                ]
                            },
                            shape: {
                                type: [
                                    "circle",
                                    "square",
                                    "triangle"
                                ],
                                options: {}
                            },
                            opacity: {
                                value: {
                                    min: 0.4,
                                    max: 1
                                },
                                animation: {
                                    enable: true,
                                    speed: 4,
                                    startValue: "max",
                                    destroy: "min"
                                }
                            },
                            size: {
                                value: {
                                    min: 1,
                                    max: 4
                                }
                            },
                            links: {
                                enable: false
                            },
                            life: {
                                duration: {
                                    sync: true,
                                    value: 5
                                },
                                count: 1
                            },
                            move: {
                                enable: true,
                                gravity: {
                                    enable: true,
                                    acceleration: 10
                                },
                                speed: {
                                    min: 10,
                                    max: 30
                                },
                                decay: 0.1,
                                direction: "none",
                                straight: false,
                                outModes: {
                                    default: "destroy",
                                    top: "none"
                                }
                            },
                            rotate: {
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                direction: "random",
                                move: true,
                                animation: {
                                    enable: true,
                                    speed: 40
                                }
                            },
                            tilt: {
                                direction: "random",
                                enable: true,
                                move: true,
                                value: {
                                    min: 0,
                                    max: 360
                                },
                                animation: {
                                    enable: true,
                                    speed: 40
                                }
                            },
                            roll: {
                                darken: {
                                    enable: true,
                                    value: 25
                                },
                                enable: true,
                                speed: {
                                    min: 15,
                                    max: 25
                                }
                            },
                            wobble: {
                                distance: 20,
                                enable: true,
                                move: true,
                                speed: {
                                    min: -15,
                                    max: 15
                                }
                            }
                        },
                        emitters: {
                            life: {
                                count: 0,
                                duration: 0.1,
                                delay: 0.4
                            },
                            rate: {
                                delay: 0.1,
                                quantity: 200
                            },
                            size: {
                                width: 0,
                                height: 0
                            }
                        }
                    }
                    }
                />
            </div>
            :
            <></>
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%',
    },
    text: {
        fontWeight: 700,
        fontSize: 60,
        position: 'absolute',
        textShadow: '-15px 5px 20px #ced0d3',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        letterSpacing: '0.02em',
        textAlign: 'center',
        color: '#F9f1cc',
        textShadow: `5px 5px 0px #FFB650, 
          10px 10px 0px #FFD662, 
          15px 15px 0px  #FF80BF, 
          20px 20px 0px #EF5097`,
    },
    '@keyframes gradientAnimation': {
      '0%': {
        backgroundPosition: '0% 50%',
      },
      '50%': {
        backgroundPosition: '100% 50%',
      },
      '100%': {
        backgroundPosition: '0% 50%',
      },
    },
  }));