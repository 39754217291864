import { Box, Grid, IconButton, Popover, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React from 'react'
import Api from '../../../../Api';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import LaunchIcon from '@material-ui/icons/Launch';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { getRoutePathname } from '../../../../Config/Route';
import moment from 'moment';
import SweetAlert from "sweetalert-react";
import NoteIcon from '@material-ui/icons/Note';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../../App';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { useSelector } from 'react-redux';

export default function Card(props) {
  const classes = useStyles();
  const [showConfirm, setShowConfirm] = React.useState(false);
  const authenticationReducer = useSelector(state => state.AuthenticationReducer);

  const Note = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <>
        <Tooltip title={'Note'} placement="left">
          <IconButton style={{ padding: 5, background: '#5E6E82' }} onClick={handleClick}>
            <NoteIcon style={{ color: '#FFF', fontSize: 13 }} />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.popover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography style={{ padding: 8, maxWidth: 250, color: '#5E6E82' }}>
            <span style={{ margin: 0, fontSize: 13 }}>{props.task.note ? <span style={{ margin: 0 }}><b>Note :</b><br />{props.task.note}</span> : 'Aucune note'}</span>
          </Typography>
        </Popover >
      </>
    )
  }

  return (
    (props && props.task) ?
      <>
        <ShadowBoxComponent className={classes.container} style={{ background: props.color ? props.color : '' }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={props.task.doneAt ? 8 : 9} md={props.task.doneAt ? 8 : 9} lg={props.task.doneAt ? 8 : 9}>
              <p style={{ margin: '5px 0px', display: 'flex', alignItems: 'center', gap: 5 }}>
                <b>{props.task.type}</b> | {moment(props.task.todoAt).format('ll')}
                {Note()}
              </p>
              <p style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 4 }}><EventNoteIcon /><b>{props.task.label}</b></p>
              <p style={{ margin: '0 10px', display: 'flex', alignItems: 'center', gap: 4 }}>
                <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0px, 10%) scaleX(-1)" }} />
                <b>Client :</b> {props.task.prospectName}
                <LaunchIcon
                  className={classes.icon}
                  onClick={() => window.open(getRoutePathname('crm_prospect', { id: props.task.prospect }), '_blank')}
                />
              </p>
              <p style={{ margin: '5px 0px', fontSize: 14 }}><b>Webcoach :</b> {authenticationReducer.id === props.task.webCoachId ? 'Vous' : (props.task.webCoach ? props.task.webCoach : '-')}</p>
              {props.task.reassignedWebCoach && <p style={{ margin: '5px 0px', fontSize: 14 }}><b>tâche re assigné à :</b> {authenticationReducer.id === props.task.reassignedWebCoachId ? 'Vous' : props.task.reassignedWebCoach}</p>}
            </Grid>
            {props.task.doneAt === null ?
              <Grid item xs={12} sm={3} md={3} lg={3}>
                <ButtonComponent disabled={props.loading} style={{ width: '100%', margin: 5 }} label={'Aller à la tâche'} onClick={() => { props.setIsOpen(true); props.setTaskId(props.task.id) }} />
                <ButtonComponent disabled={props.loading} style={{ width: '100%', margin: 5 }} label={'Ignorer'} color={'#5E6E82'} onClick={() => setShowConfirm(true)} />
              </Grid> :
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box>
                  <p style={{ margin: 0 }}><b>Fait par :</b> {props.task.doneBy}</p>
                  <p style={{ margin: 0 }}><b>le :</b> {moment(props.task.doneAt).format('ll')}</p>
                  <p style={{ margin: 0 }}><b>Résolution :</b> <i>{props.task.resolutionText}</i></p>
                </Box>
              </Grid>
            }
          </Grid>
        </ShadowBoxComponent>
        <SweetAlert
          show={showConfirm}
          title={'Ignorer'}
          text={'Êtes-vous sur de vouloir ignorer cette tâche ?'}
          type={'warning'}
          cancelButtonText={'Non'}
          confirmButtonText={'Oui'}
          showCancelButton={true}
          onConfirm={() => {
            dispatch(LinearProgressMainLayoutActivate());
            props.setLoading(true)
            setShowConfirm(false);
            Api.post({
              route: 'webcoach_relaunch_ignore',
              params: { id: props.task.id }
            },
              (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                  props.getLoadList()
                  dispatch(
                    SnackbarOpen({
                      text: 'Tâche ignorer.',
                      variant: 'success',
                    })
                  );
                } else {
                  props.setLoading(false)
                  dispatch(
                    SnackbarOpen({
                      text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                      variant: 'error',
                    })
                  );
                }
              });
          }}
          onCancel={() => setShowConfirm(false)}
        />
      </>
      :
      <></>
  )
}

const useStyles = makeStyles({

  container: {
    margin: '10px 0'
  },
  icon: {
    cursor: 'pointer',
    verticalAlign: 'bottom',
    fontSize: 'large'
  }

});
