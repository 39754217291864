import { makeStyles } from '@material-ui/core';
import React from 'react';
import { theme } from '../../../../App';

function LazyLoading(props) {
    const classes = useStyles(theme)();
    const [loadImage, setLoadImage] = React.useState(false);
    const [loadIcon, setLoadIcon] = React.useState(false);

    return (

        (props.type === 'icon') ?
            <>
                {!loadIcon && <img src="/asset/images/icon_type_resource_preload.svg" className={classes.iconPreload} alt={'icon'} />}
                <img width="40px" height="40px" src={props.path ? props.path : ''} alt='icon' onLoad={() => setLoadIcon(true)} />
            </>
            :
            <>
                {!loadImage && <img src="/asset/images/preload_image.png" className={classes.imagePreload} alt={'Image formation'} />}
                <img className={classes.resourceImage} src={props.path ? props.path : ''} alt='image resource' onLoad={() => setLoadImage(true)} />
            </>
    );
}

const useStyles = (theme) => makeStyles(({
    imagePreload: {
        height: 170,
        width: '100%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1,
        borderRadius: 10
    },
    resourceImage: {
        width: '100%',
        height: 170,
        objectFit: 'cover',
        borderRadius: 10
    },
    iconPreload: {
        width: 40,
        height: 40,
        position: 'absolute',
        background: '#fefbf5'
    }
}));

export default LazyLoading;
