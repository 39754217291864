import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { Box, makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../../Component/TitleComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Api from "../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { getRoutePathname } from "../../../../Config/Route";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../../Component/TabsComponent";
import FormationResource from "./FormationResource";
import SelectComponent from "../../../../Component/SelectComponent";
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import UploadComponent from '../../../../Component/UploadComponent';

function Resource(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la ressource.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        path: {
            name: 'path',
            label: 'Chemin',
            textHelper: 'Saisissez le chemin de la ressource.',
            type: 'text',
            defaultValue: '',
            options: { validation: [] }
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la duration de la ressource en seconde (en second).',
            type: 'integer',
            defaultValue: '',
            options: { validation: [] }
        },
        resourceType: {
            name: 'resourceType',
            label: 'Type',
            textHelper: 'Choisissez le type de la ressource.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        resourceGroup: {
            name: 'resourceGroup',
            label: 'Groupe',
            textHelper: 'Choisissez le groupe de la ressource.',
            type: 'integer',
            defaultValue: '',
            options: { validation: [] }
        },
        pdf: {
            name: 'pdf',
            label: 'PDF',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        image: {
            name: 'image',
            label: 'Image',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
        mp3: {
            name: 'mp3',
            label: 'MP3',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);
    const [optionResourceType, setOptionResourceType] = React.useState([]);
    const [optionResourceGroups, setOptionResourceGroups] = React.useState([]);
    const [type, setType] = React.useState();

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let datas = handler.getData();
            let pdf;
            let image;
            let mp3;
            switch (parseInt(type)) {
                case 1:
                    pdf = datas.pdf;
                    datas.path = '';
                    break;
                case 4:
                    image = datas.image;
                    datas.path = '';
                    break;
                case 5:
                    mp3 = datas.mp3;
                    datas.path = '';
                    break;
            }

            if (isEdition) {
                Api.post({
                    route: 'institution_health_content_manager_resource_edit',
                    data: datas,
                    params: { id: props.match.params.id },
                    file: {
                        image: image ? image : '',
                        mp3: mp3 ? mp3 : '',
                        pdf: pdf ? pdf : ''
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Ressource modifiée.',
                                variant: 'success',
                            })
                        );
                        resourceGet();
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_content_manager_resource_add',
                    data: datas,
                    file: {
                        image: image ? image : '',
                        mp3: mp3 ? mp3 : '',
                        pdf: pdf ? pdf : ''
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('institution_health_content_manager_resource', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Ressource enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'institution_health_content_manager_resource_delete',
            params: { id: props.match.params.id }
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('institution_health_content_manager_resource_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Ressource supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const resourceGet = () => {
        Api.get({
            route: 'institution_health_content_manager_resource_get',
            params: { id: props.match.params.id }
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    handler.setDataApi(data);
                    setType(response.data.resourceType);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('institution_health_content_manager_resource_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setTimeout(() => setReadyContent(true), 250);
            });
    };

    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setReadyContent(false);

        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Ressources',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_content_manager_resource_list'), label: 'Liste des ressources' }
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            resourceGet();
        }
        else {
            setIsEdition(false);
            setReadyContent(true);
            setBreadcrumbs({
                title: 'Ajouter une ressource',
                context: 'Gestion de contenu',
                description: '',
                links: [
                    { path: getRoutePathname('institution_health_content_manager_resource_list'), label: 'Liste des ressources' }
                ]
            });
        }
    }, [props.match.params.id]);
    React.useEffect(() => {
        Api.get({
            route: 'select_resource_type'
        },
            (response) => {
                if (response && response.data) {
                    setOptionResourceType(response.data);
                }
            });
        Api.get({
            route: 'select_resource_groups'
        },
            (response) => {
                if (response && response.data) {
                    setOptionResourceGroups(response.data);
                }
            });
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    <Grid container spacing={2}>
                        <Fade in={true} {...{ timeout: 750 }}>
                            <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ShadowBoxComponent style={{ minHeight: '100%' }} className={classes.shadowBox}>
                                    <TitleComponent title={isEdition ? <>Formulaire d'édition de la ressource</> : <>Formulaire de création d'une ressource</>} />

                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <TextFieldComponent name={'name'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <TextFieldComponent name={'duration'} handler={handler} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <SelectAutocompleteComponent name={'resourceGroup'} handler={handler} options={optionResourceGroups} nullable={true} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <SelectComponent name={'resourceType'} handler={handler} options={optionResourceType} onChange={(val) => { setType(val) }} />
                                        </Grid>
                                        {(parseInt(type) === 1 || parseInt(type) === 4 || parseInt(type) === 5) &&
                                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                                {(parseInt(type) === 1) && <UploadComponent name={'pdf'} handler={handler} accept=".pdf" />}
                                                {(parseInt(type) === 4) && <UploadComponent name={'image'} handler={handler} accept=".png, .jpeg, .jpg, .webp" />}
                                                {(parseInt(type) === 5) && <UploadComponent name={'mp3'} handler={handler} accept=".mp3" />}
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <TextFieldComponent name={'path'} handler={handler} disabled={parseInt(type) === 1 || parseInt(type) === 4 || parseInt(type) === 5} />
                                        </Grid>
                                    </Grid>

                                    <div className={classes.button}>
                                        <ButtonComponent label={'Enregistrer'} className={classes.buttonSave} onClick={save} disabled={loading} />
                                    </div>

                                    {isEdition && <div className={classes.button}>
                                        <ButtonComponent label={'Supprimer'} color={'#dc3545'} className={classes.buttonDelete} onClick={remove} disabled={loading} />
                                    </div>}
                                </ShadowBoxComponent>
                            </Grid>
                        </Fade>
                    </Grid>
                    <br />

                    {
                        isEdition &&
                        <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                            <div>
                                <TabsComponent
                                    tabDefault={'formations'}
                                    tabs={[
                                        { id: 'formations', label: 'Formations' },
                                    ]}
                                >
                                    <div key={'formations'}>
                                        <FormationResource {...props} />
                                    </div>
                                </TabsComponent>
                            </div>
                        </Slide>
                    }
                    <br />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const useStyles = makeStyles({
    TableComponent: {
        padding: 20,
        width: '95%',
        margin: 'auto',
    },
    shadowBox: {
        paddingBottom: 60
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    }
});

export default Resource;
