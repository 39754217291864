import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Box, Dialog, DialogActions, DialogContent, Fade, Grid, makeStyles, Zoom } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { dispatch, theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import Api, { learnerHomeFormationImageBanner, LibraryResourceImage } from '../../../Api';
import Iframe from './Component/Iframe';
import CardFormation from './Component/CardFormation';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import Rating from '@material-ui/lab/Rating';
import LazyLoading from './Component/LazyLoading';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ButtonComponent from '../../../Component/ButtonComponent';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Countdown from './Component/Countdown';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
});

const Resource = (props) => {

    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [info, setInfo] = React.useState([]);
    const [moreInfo, setMoreInfo] = React.useState([]);
    const [isFormation, setIsFormation] = React.useState(false);
    const [loadLivestorm, setLoadLivestorm] = React.useState(false);
    const [showCountdownFinish, setShowCountdownFinish] = React.useState(false);
    const [showRating, setShowRating] = React.useState(false);
    const [loadRating, setLoadRating] = React.useState(false);
    const [average, setAverage] = React.useState(0);
    const [isAverage, setIsAverage] = React.useState(true);
    const [rating, setRating] = React.useState(0);
    const [hover, setHover] = React.useState(-1);

    const customIcons = {
        1: <SentimentVeryDissatisfiedIcon />,
        2: <SentimentDissatisfiedIcon />,
        3: <SentimentSatisfiedIcon />,
        4: <SentimentSatisfiedAltIcon />,
        5: <SentimentVerySatisfiedIcon />,
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        setLoading(true);
        setLoad(false);
        Api.get({
            route: 'learner_resource_get',
            params: { resource: props.match.params.resource },
            data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
        },
            (response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                    setMoreInfo(response.data.moreInfo);
                    setIsFormation(response.data.isFormation);

                    setAverage(response.data.data.average);
                    setRating(response.data.data.ratingByLearner);
                    setHover(response.data.data.ratingByLearner ? response.data.data.ratingByLearner : -1);
                    setIsAverage(response.data.data.isAverage);

                    setLoading(false);
                    setBreadcrumbs({
                        currentPage: response.data.data.title ? response.data.data.title : 'Média',
                        links: [
                            { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                            { path: getRoutePathname('learner_library_resource'), label: 'Bibliothèque de ressources' },
                            { path: getRoutePathname('learner_resource_type', { id: props.match.params.id }), label: response.data.resourceTypeName ? response.data.resourceTypeName : 'Ressources' },
                        ]
                    });
                }
            });
    }, [props.match.params.resource]);
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    })
    const handler = formHandlerInit(form, setForm);
    const saveRating = () => {
        if (!handler.checkError()) {
            setLoadRating(true)
            Api.get({
                route: 'learner_resource_type_rating',
                params: { resource: props.match.params.resource },
                data: { comment: handler.getData().comment, rating: rating }
            }, (response) => {
                if (response.status === 200) {
                    setLoadRating(false)
                    setAverage(response.data.average);
                    setIsAverage(response.data.isAverage);
                    setRating(response.data.ratingByLearner);
                    setHover(response.data.ratingByLearner ? response.data.ratingByLearner : -1)
                    setShowRating(false)
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
                setLoadLivestorm(false);
            });
        }
    };

    const registerLivestorm = (val, action) => {
        setLoadLivestorm(true);
        Api.post({
            route: 'learner_resource_livestorm_register',
            params: { resource: props.match.params.resource, sessionlivestorm: val.sessionlivestorm },
            data: {
                idEvent: val.idEvent,
            }
        }, (response) => {
            if (response.status === 201) {
                dispatch(
                    SnackbarOpen({
                        text: 'L\'inscription au webinaire est un succès',
                        variant: 'success',
                    })
                );
                switch (val.state) {
                    case 2:
                        info.livestorm.state = 1;
                        break;
                    case 3:
                        info.livestorm.state = 4;
                        break;
                    case 6:
                        info.livestorm.state = 5;
                        break;
                }
                setTimeout(() => {
                    if (action) { goLive(val) }
                }, 500);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'error',
                    })
                );
            }
            setLoadLivestorm(false);
        });
    }

    const goLive = (val) => {
        setLoadLivestorm(true);
        Api.get({
            route: 'learner_resource_livestorm_login',
            params: { resource: props.match.params.resource, id: val.idEvent },
            data: {
                sessionId: val.sessionlivestorm
            }
        }, (response) => {
            if (response.status === 200) {
                window.open(response.data.connectionLink, '_blank');
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'error',
                    })
                );
            }
            setLoadLivestorm(false);
        });
    };

    const ButtonLivestorm = (val) => {

        if ((val.state === 3 || val.state === 4)) {
            let currentDate = new Date();
            let date = new Date(val.sessionStart.date);
            let minutes = val.estimatedDuration;
            date.setMinutes(date.getMinutes() + minutes);

            if (date.getTime() < currentDate.getTime()) {

                if (val.state === 3) {
                    info.livestorm.state = 6;
                }
                if (val.state === 4) {
                    info.livestorm.state = 5;
                }
            }
        }

        switch (val.state) {
            case 0:
                return <></>;
            case 1: // inscrit et live non démarrer

                // Compte a rebours avant début du live
                // return (
                //     <Countdown date={val.sessionStart} callbackCountdown={callbackCountdown} />
                // );
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Accéder à la salle'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 2: // non inscrit et live non démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Inscrivez-vous'}
                        disabled={loadLivestorm}
                        onClick={() => { registerLivestorm(val, null) }}
                    />
                );
            case 3: // non inscrit et live démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Inscrivez-vous et accéder au webinaire en live'}
                        disabled={loadLivestorm}
                        onClick={() => {
                            registerLivestorm(val, true);
                        }}
                    />
                );
            case 4: // inscrit et live démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Accéder au webinaire en live'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 5: // inscrit et live terminer 
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Voir le replay'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 6: // non inscrit et live terminer 
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Voir le replay'}
                        disabled={loadLivestorm}
                        onClick={() => {
                            registerLivestorm(val, true);
                        }}
                    />
                );
        }
    };

    const callbackCountdown = () => {
        setShowCountdownFinish(true);
        info.livestorm.state = 4;
    };

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{ textAlign: 'center', maxWidth: 1240, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            {info &&
                                <>
                                    <div className={classes.styleResource} style={{ width: isSmallView ? '90%' : '80%', margin: '40px auto 10px auto' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                                <div style={{ position: 'relative', margin: '0 auto 20px auto', width: 'fit-content', }}>
                                                    {!load && <img src="/asset/images/preload_image.png" className={`${isSmallView ? classes.widthImageSmall : classes.widthImage} ${classes.resourceImagePreload}`} alt={'image'} />}
                                                    <img className={`${isSmallView ? classes.widthImageSmall : classes.widthImage} ${classes.resourceImage}`} src={LibraryResourceImage + info.id + '.png'} alt='image' onLoad={() => setLoad(true)} />
                                                </div>
                                                <div className={classes.stateResource}>
                                                    <div>{customIcons[average ? average : 1]}<p>Note : {average ? average : 0}/5</p></div>
                                                    <Rating
                                                        name="hover-feedback"
                                                        value={average}
                                                        precision={1}
                                                        readOnly
                                                    />
                                                    {isAverage &&
                                                        <ButtonComponent
                                                            color={theme.colorPrimary}
                                                            style={{ margin: 10 }}
                                                            label={'Donnez une note'}
                                                            onClick={() => { setShowRating(true) }}
                                                        />
                                                    }
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                <div style={{ position: 'relative', textAlign: 'left', marginTop: screenReducer.screen === 'XS' ? 120 : 0 }}>
                                                    <p style={{ fontSize: 22, fontWeight: 600, margin: 0 }}>{info.title ? info.title : ''}</p>
                                                    {info.subTitle && <p style={{ margin: 0, padding: '10px 0', }}>{info.subTitle}</p>}
                                                    <div className={classes.description} dangerouslySetInnerHTML={{ __html: info.description }} />
                                                    <br />
                                                    {
                                                        info.type === 7 ?
                                                            <div>
                                                                <ButtonLivestorm
                                                                    state={info.livestorm.state}
                                                                    sessionlivestorm={info.livestorm.idSession}
                                                                    idEvent={info.livestorm.id}
                                                                    path={info.livestorm.room_link}
                                                                    sessionStart={info.livestorm.estimated_started_at}
                                                                    estimatedDuration={info.livestorm.estimated_duration}
                                                                />
                                                            </div>
                                                            :
                                                            <Iframe type={info.type} path={info.link} />
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            }
                            <br />
                            {
                                moreInfo &&
                                <div style={{ width: isSmallView ? '90%' : '80%', margin: 'auto' }}>
                                    <p style={{ fontSize: 20, fontWeight: 600, textAlign: 'left' }}>Pour aller plus loin</p>
                                    {
                                        isFormation ?
                                            <Grid container spacing={4}>
                                                {moreInfo.map((formation, index) =>
                                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                                                        <CardFormation
                                                            show={true}
                                                            hours={formation.hours}
                                                            image={learnerHomeFormationImageBanner + formation.image}
                                                            title={formation.name}
                                                            description={formation.description}
                                                            financeTypes={formation.financeTypes}
                                                            isEPP={formation.isEPP}
                                                            onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formation.id })) }}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            :
                                            <Grid container spacing={4}>
                                                {moreInfo.map((resource, index) =>
                                                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                                                        {
                                                            resource.format === 6 || resource.format === 2 ?
                                                                <MediaLinkComponent
                                                                    path={resource.link}
                                                                    type={resource.format}
                                                                    isLibrary={true}
                                                                >
                                                                    <div className={classes.card}>
                                                                        <ShadowBoxComponent style={{ padding: 0, height: 170, borderRadius: 10, boxShadow: '0 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                            <LazyLoading path={LibraryResourceImage + resource.image} />
                                                                        </ShadowBoxComponent>
                                                                        <p style={{ fontWeight: 600, margin: '20px 0 0 0', fontSize: 22, overflow: 'hidden', }}>{resource.title}</p>
                                                                        <p style={{ fontSize: 16, margin: 0, }}>{resource.subTitle}</p>
                                                                        <div className={classes.styleStats} >
                                                                            <p><VisibilityIcon />{resource.numberViews}</p>
                                                                            <Rating
                                                                                name="hover-feedback"
                                                                                value={resource.average}
                                                                                precision={1}
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </MediaLinkComponent>
                                                                :
                                                                <div className={classes.card}
                                                                    onClick={() => {
                                                                        props.history.push(getRoutePathname('learner_resource_type_resource', { id: props.match.params.id, resource: resource.id }));
                                                                    }}>
                                                                    <ShadowBoxComponent style={{ padding: 0, height: 170, borderRadius: 10, boxShadow: '0 0px 3px 0px rgba(0,0,0,0.25)' }}>
                                                                        <LazyLoading path={LibraryResourceImage + resource.image} />
                                                                    </ShadowBoxComponent>
                                                                    <p style={{ fontWeight: 600, margin: '20px 0 0 0', fontSize: 22, overflow: 'hidden', }}>{resource.title}</p>
                                                                    <p style={{ fontSize: 16, margin: 0, }}>{resource.subTitle}</p>
                                                                    <div className={classes.styleStats} >
                                                                        <p><VisibilityIcon />{resource.numberViews}</p>
                                                                        <Rating
                                                                            name="hover-feedback"
                                                                            value={resource.average}
                                                                            precision={1}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                        }
                                                    </Grid>
                                                )}
                                            </Grid>
                                    }
                                </div>
                            }
                            <br />
                            <Dialog className={classes.dialog} open={showRating} onClose={() => { setShowRating(false) }} maxWidth={'xl'} TransitionComponent={Transition}>
                                <DialogContent>
                                    <div className='customIcons-root'>{customIcons[hover !== -1 ? hover : (rating ? rating : 1)]}</div>
                                    <h1 style={{ margin: 0, }}>Donnez une note</h1>
                                    <p style={{ margin: 0, padding: '10px 0', }}><span>Donnez votre avis en quelques clics !</span><br /> Évaluez cette ressource de 0 à 5 étoiles.</p>
                                    <Rating
                                        name="hover-feedback"
                                        value={rating}
                                        precision={1}
                                        style={{ padding: '0 0 10px 0', }}
                                        onChange={(event, newValue) => {
                                            setRating(newValue);
                                        }}
                                        onChangeActive={(event, newHover) => {
                                            setHover(newHover);
                                        }}
                                        disabled={loadRating}
                                    />
                                    <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                                </DialogContent>
                                <DialogActions>
                                    <ButtonComponent color={theme.colorPrimary} style={{ margin: '10px auto', }} label={'valider'} onClick={() => { saveRating() }} disabled={loadRating} />
                                </DialogActions>
                            </Dialog>
                            <Dialog open={showCountdownFinish} onClose={() => setShowCountdownFinish(false)} maxWidth={'xl'} TransitionComponent={Transition}>
                                <img style={{ width: '50%', padding: 15 }} src={'/asset/images/logo-livestorm.svg'} />
                                <DialogContent>
                                    <div style={{ width: '50vw', maxWidth: 500, textAlign: 'center' }}>
                                        <p style={{ fontSize: 18, fontWeight: 500 }}>Le live : {info.title} commence, <br /> voulez-vouz être rediriger ?</p>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <ButtonComponent onClick={() => setShowCountdownFinish(false)} color={'#5E6E82'} label={'Non'} />
                                    <ButtonComponent onClick={() => {
                                        setShowCountdownFinish(false);
                                        goLive({
                                            state: info.livestorm.state,
                                            sessionlivestorm: info.livestorm.idSession,
                                            idEvent: info.livestorm.id,
                                            path: info.livestorm.room_link,
                                            sessionStart: info.livestorm.estimated_started_at
                                        });
                                    }} label={'Oui'} />
                                </DialogActions>
                            </Dialog>
                            <br />
                        </>

                }
            </div>
        </>
    );
};

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    widthImage: {
        height: 200,
        width: 200,
    },
    widthImageSmall: {
        height: 130,
        width: 130,
    },
    stateResource: {
        border: 'solid 1px #DADADA',
        borderRadius: 5,
        background: '#FFF',
        padding: 10,
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            margin: 'auto',
            gap: 5,
            '& p': {
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
            }
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
        }
    },
    resourceImagePreload: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        borderRadius: 10,
        border: 'solid 1px #DADADA',
    },
    resourceImage: {
        objectFit: 'cover',
        borderRadius: 10,
        border: 'solid 1px #DADADA',
        display: 'block',
    },
    circle: {
        borderRadius: 0,
        float: 'left',
        shapeOutside: 'margin-box',
        marginRight: 15,
        marginBottom: 7
    },
    card: {
        height: 320,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            textAlign: 'left'
        }
    },
    textLink: {
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    },
    btnThematic: {
        cursor: 'pointer',
        width: 'max-content',
        boxShadow: 'rgb(204, 219, 232) 1px 1px 3px 0px inset, rgba(255, 255, 255, 0.5) -1px -1px 3px 1px inset',
        padding: '10px 20px',
        margin: 5,
        borderRadius: 5
    },
    description: {
        '& *': {
            margin: 0
        }
    },
    button: {
        fontSize: 10,
        margin: 3
    },
    dialog: {
        '& .MuiDialog-paper, .MuiDialogContent-root': {
            overflow: 'initial',
            textAlign: 'center',
        },
        '& div.customIcons-root': {
            '& svg': {
                display: 'block',
                width: 'auto',
                margin: '-44px auto auto auto',
                background: '#FFF',
                height: 50,
                borderRadius: 100
            },
        },
        '& .MuiRating-root': {
            margin: 'auto',
            width: 'fit-content',
            fontSize: 35,
            display: 'flex',
            color: theme.colorPrimary,
        }
    },
    styleStats: {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0 0 0',
        gap: 10,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: 5,
            border: '1px solid rgba(0, 0, 0, 0.15)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
            fontSize: 17,
        },
    }
});

export default Resource;
