import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import {getRoutePathname} from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Fade from "@material-ui/core/Fade";
import SelectComponent from "../../../Component/SelectComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import TableComponent from '../../../Component/TableComponent';
import { TableReload } from '../../../Action/TableAction';
import { Card, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SwitchComponent from "../../../Component/SwitchComponent";

function Pole(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [showDialog, setShowDialog] = React.useState(false);
    const columns = [
        {
            name: "value",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prenom",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        }
    ];

    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du pôle.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez l\'organisme du pôle.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        acronym: {
            name: 'acronym',
            label: 'Abréviation',
            textHelper: 'Saisissez le nom du pôle.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        internal: {
            name: 'internal',
            label: 'Interne',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {
                switchLabelRight: 'Interne',
                switchLabelLeft: 'Externe'
            }
        }
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'administrator_pole_edit',
                    params: {id: props.match.params.id},
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'administrator_pole_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('administrator_pole', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Pôle créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'select_organisms'
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un pôle',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_pole_list'), label: 'Liste des pôles'}
                ]
            });
            setIsEdition(true);

            Api.get({
                    route: 'administrator_pole_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('administrator_pole_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un pôle',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_pole_list'), label: 'Liste des pôles'}
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);

    React.useEffect(() => {

        if (props.match.params.id) {

            Api.get({
                route: 'administrator_pole_users',
                data: { id: props.match.params.id}
            },
                (response) => {
                    if (response && response.data) {
                        setLeft(response.data.dataWithoutPole);
                        setRight(response.data.dataWithPole);
                    }
                });
        }
    }, [props.match.params.id, showDialog]);

    const saveAddUsers = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'administrator_pole_users_edit',
            data: { poleId: props.match.params.id, users: right },
        }, (response) => {
            if (response.status === 201) {
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('administrator_pole_users_list'));
                setRight([]);
                setLeft([]);
                dispatch(
                    SnackbarOpen({
                        text: 'La liste des utilisateurs ont été modifiés avec succès.',
                        variant: 'success',
                    })
                );
                setShowDialog(false);
            }
            else if (response.status === 400) {
                handler.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };
    //--------- Transfert List ---------//

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'Tous les utilisateurs selectionnés' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selectionné`}
            />
            <Divider />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = value.label;
                    return (
                        <ListItem key={value.label} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Fade in={isReadyContent} {...{timeout: 500}}>
                    <div>
                        <ShadowBoxComponent className={classes.shadowBox}>
                            <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'un pôle' : 'Formulaire de création d\'un pôle'}/>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'name'} handler={handler}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldComponent name={'acronym'} handler={handler}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectComponent name={'organism'} handler={handler} options={optionOrganism}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SwitchComponent name={'internal'} handler={handler}/>
                                </Grid>
                            </Grid>

                            <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading}/>
                        </ShadowBoxComponent>
                    </div>
                </Fade>
                <br></br>
                {props.match.params.id && 
                    <TableComponent
                        id={'administrator_pole_users_list'}
                        title={`Liste des utilisateurs`}
                        columns={columns}
                        actionFirst={{
                            label: 'Gerer les utilisateurs',
                            onClick: () => {
                                setShowDialog(true);
                            }
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'administrator_pole_users_list',
                                data: { id: props.match.params.id }
                            },
                                (response) => {
                                    let data = response.data;
                                    resolve(data);
                                });
                        }}
                />}

                <br />

            </ContentViewComponent>

            <Dialog open={showDialog} maxWidth={'xl'} onClose={() => {setShowDialog(false); setRight([]); setLeft([]);}}>
                <DialogTitle style={{ fontSize: 15 }}>Gerer les utilisateurs pour le pôle {handler.getData().name}</DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid item>{customList('Utilisateurs sans ce pôle', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Tooltip title={'Déplacer vers la droite.'}>
                                    <IconButton
                                        disabled={leftChecked.length === 0}
                                        onClick={handleCheckedRight}
                                        className={classes.buttonList}
                                        size="small"
                                    >
                                        <ArrowForwardIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={'Déplacer vers la gauche.'}>
                                    <IconButton
                                        disabled={rightChecked.length === 0}
                                        onClick={handleCheckedLeft}
                                        className={classes.buttonList}
                                        size="small"
                                    >
                                        <ArrowBackIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Utilisateur avec ce pôle', right)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => {setShowDialog(false); setRight([]); setLeft([]);}} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => saveAddUsers()} disabled={loading} />
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: '8px 16px',
    },
    list: {
        width: 250,
        height: 230,
        backgroundColor: '#F2F2F2',
        overflow: 'auto',
    },
    buttonList: {
        margin: '2px 0px',
    },
});

export default Pole;
