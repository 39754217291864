import React from 'react';
import { Avatar, Badge, Box, Button, Chip, Fade, IconButton, LinearProgress as LinearProgressMUI, makeStyles, Tooltip, Typography } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import LockIcon from "@material-ui/icons/Lock";
import { getRoutePathname } from "../../../../Config/Route";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { theme } from "../../../../App";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSelector } from "react-redux";
import moment from "moment";
import Popover from '@material-ui/core/Popover';
import { Edit } from '@material-ui/icons';

function CardCommunityPost(props) {
    const classes = useStyles(theme)();
    const dataList = props.data;
    const propsData = props.propsData;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const viewAction = (row) => {
        return (
            <div className={classes.boxOrganism}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <MoreVertIcon style={{ color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {props.getAction(row, handleClose)}
                </Popover>
            </div>
        )
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box className={classes.boxPosts}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                        {dataList.status === 'Admin' ?
                            <Badge color="secondary" badgeContent={'A'} showZero >
                                <Avatar className={classes.avatar}>{dataList.firstname.charAt().toUpperCase()}{dataList.lastname.charAt().toUpperCase()}</Avatar>
                            </Badge> : (!dataList.pseudo) ?
                                <Avatar className={classes.avatar}>{dataList.firstname.charAt().toUpperCase()}{dataList.lastname.charAt().toUpperCase()}</Avatar> :
                                <Avatar className={classes.avatar}>{dataList.pseudo.charAt().toUpperCase()}</Avatar>
                        }
                        <Box style={{ padding: '0 10px' }}>
                            <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}><span>{dataList.title}</span></p>
                            <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par
                                {dataList.isPostProspect === 'Mes postes' ?
                                    <span className={classes.nameProspect}> vous </span> :
                                    <span className={classes.nameProspect}> {dataList.status === 'Admin' ? 'un administrateur' : !dataList.pseudo ? dataList.firstname.charAt().toUpperCase() + "." + dataList.lastname : dataList.pseudo} </span>}
                                le {dataList.createdAt}
                            </p>
                            <p className={classes.subTitle} style={{ margin: 0, padding: "10px 0" }} dangerouslySetInnerHTML={{ __html: dataList.subTitle }} />
                        </Box>
                        <Box style={{ margin: '0 0 0 auto' }}>{(dataList.isPostProspect === 'Mes postes' && props.isGroup) && <sup>{viewAction(dataList)}</sup>}</Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "0px", display: 'flex' }} >
                            <p style={{ margin: 0, textAlign: "left", alignContent: 'center', display: 'grid' }}>{dataList.numComment > 99 ? '99+' : dataList.numComment} Commentaires</p>
                            {props.isGroup &&
                                <ButtonComponent
                                    small={true}
                                    color='#03a9f4'
                                    style={{ margin: "10px  56px", display: "block" }}
                                    className={classes.buttonPost}
                                    label={'Ouvrir la discussion'}
                                    onClick={() => { propsData.history.push(getRoutePathname('learner_community_post', { communityGroup: propsData.match.params.communityGroup, communityPost: dataList.id })) }}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <br />
        </Grid>
    );
}

const useStyles = (theme) => makeStyles(({
    boxPosts: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    buttonPostProspect: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
}));

export default CardCommunityPost;
