import { makeStyles } from '@material-ui/core';
import React from 'react';
import { theme } from '../../../../App';

function LazyLoading(props) {
    const classes = useStyles(theme)();
    const [loadImage, setLoadImage] = React.useState(false);

    return (
        <>
            {!loadImage && <img src="/asset/images/preload_image.png" className={classes.imagePreload} alt={'Image resource'} />}
            <img className={classes.resourceImage} src={props.path ? props.path : ''} alt='image resource' onLoad={() => setLoadImage(true)} />
        </>
    )
}

const useStyles = (theme) => makeStyles(({
    imagePreload: {
        height: 170,
        width: '100%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1,
        borderRadius: 10
    },
    resourceImage: {
        width: '100%',
        height: 170,
        objectFit: 'cover',
        borderRadius: 10
    }
}));

export default LazyLoading;
