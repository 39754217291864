import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Assignment, Cancel} from "@material-ui/icons";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import {TableReload} from "../../../Action/TableAction";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {inArray} from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {getRoutePathname} from "../../../Config/Route";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";

function PaymentFollowList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionfinanceType, setOptionfinanceType] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [idEdit, setIdEdit] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: {filter: true, sort: true}
        },
        {
            name: "formationId",
            label: "Numéro Formation",
            options: {filter: true, sort: true}
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: {filter: false, sort: true}
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: {filter: false, sort: true}
        },
        {
            name: "code",
            label: "Numéro de programme DPC ou FIFPL",
            options: {filter: true, sort: true}
        },
        {
            name: "numberDPC",
            label: "Numéro de session",
            options: {filter: true, sort: true}
        },
        {
            name: "startAt",
            label: "Début de session",
            options: {filter: true, sort: true}
        },
        {
            name: "endAt",
            label: "Fin de session",
            options: {filter: true, sort: true}
        },
        {
            name: "amount",
            label: "Montant",
            options: {filter: true, sort: true}
        },
        {
            name: "state",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <div style={{minWidth: 90}}>
                <Tooltip title={'Visualiser la session formation'} placement="left">
                    <IconButton onClick={() => {
                        window.open(getRoutePathname('financial_management_billing', {id: row.sessionFormationId}), '_blank').focus();
                    }} disabled={row.state === 3}>
                        <Assignment disabled={row.state === 3}/>
                    </IconButton>
                </Tooltip>
                {row.acquittedAt ? <Tooltip title={'Annuler l\'acquittement'} placement="left">
                    <IconButton onClick={() => cancel(row.id)}>
                        <Cancel/>
                    </IconButton>
                </Tooltip> : <Tooltip title={'Editer l\'acquittement'} placement="left">
                    <IconButton onClick={() => {
                        handlerAcquitted.reset();
                        setIdEdit(row.id);
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>}
            </div>
        );
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].organism ? cacheTableServer['financial_management_payment_follow_invoice_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].formationGroup ? cacheTableServer['financial_management_payment_follow_invoice_list'].formationGroup : 'all',
            options: {}
        },
        code: {
            name: 'code',
            label: 'Code andpc ou fifpl',
            textHelper: 'Saisissez un code andpc ou fifpl.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].code ? cacheTableServer['financial_management_payment_follow_invoice_list'].code : '',
            options: {}
        },
        numberDPC: {
            name: 'numberDPC',
            label: 'Numéro de session',
            textHelper: 'Saisissez un numéro de session.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].numberDPC ? cacheTableServer['financial_management_payment_follow_invoice_list'].numberDPC : '',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].startAt ? cacheTableServer['financial_management_payment_follow_invoice_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].endAt ? cacheTableServer['financial_management_payment_follow_invoice_list'].endAt : '',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].state ? cacheTableServer['financial_management_payment_follow_invoice_list'].state : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].year ? cacheTableServer['financial_management_payment_follow_invoice_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_payment_follow_invoice_list'] && cacheTableServer['financial_management_payment_follow_invoice_list'].financeType ? cacheTableServer['financial_management_payment_follow_invoice_list'].financeType : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['financial_management_payment_follow_invoice_list'] = 0;
        cacheTableServer['financial_management_payment_follow_invoice_list'].page = 0;
        cacheTableServer['financial_management_payment_follow_invoice_list'][index] = value;
        dispatch(TableReload('financial_management_payment_follow_invoice_list'))
    };

    // Edit
    const [formAcquitted, setFormAcquitted] = React.useState({
        acquittedAt: {
            name: 'acquittedAt',
            label: 'Date de l\'acquittement',
            textHelper: 'Choisissez une date de l\'acquittement.',
            type: 'date',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handlerAcquitted = formHandlerInit(formAcquitted, setFormAcquitted);
    const save = () => {
        if (handlerAcquitted.checkError()) {
            console.log('Error');
        } else {
            let data = handlerAcquitted.getData();
            dispatch(LinearProgressMainLayoutActivate());
            handlerAcquitted.setFormLoading(true);
            setLoading(true);
            Api.post({
                    route: 'financial_management_payment_follow_invoice_acquitted',
                    data: {acquittedAt: data['acquittedAt']},
                    params: {id: idEdit}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerAcquitted.setFormLoading(false);
                    setLoading(false);
                    dispatch(TableReload('financial_management_payment_follow_invoice_list'));

                    if (response.status === 200) {
                        setIdEdit(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Acquittement enregistré.',
                                variant: 'success',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };
    const cancel = (id) => {
        Api.post({
                route: 'financial_management_payment_follow_invoice_acquitted',
                data: {acquittedAt: null},
                params: {id: id}
            },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('financial_management_payment_follow_invoice_list'));

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Annulation enregistrée.',
                            variant: 'success',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handlerAcquitted.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Suivi des règlements',
            context: 'Gestion financière',
            description: 'Liste des factures.'
        });

        // Stats
        let data = [{value: 'all', label: 'Tous'}, {value: 1, label: 'Acquittée'}, {value: 2, label: 'En attente de paiement'}, {value: 3, label: 'Annulée'}];
        setOptionProspectStates(data);

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({label: i, value: i});
        }
        setOptionYears(yearsTMP);

        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionFormationGroup(data);
                }
            });
            
        Api.get({
                route: 'select_finance_types',
                data: { 'isFull': true }
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionfinanceType(data);
                }
            });

        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={1}>
                            <Grid item xs={2} sm={2} md={1} lg={1}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)}/>
                            </Grid>
                            <Grid item xs={5} sm={5} md={3} lg={3}>
                                <TextFieldComponent name={'code'} handler={handlerSearch} onChange={(val) => inputSearchChange('code', val)}/>
                            </Grid>
                            <Grid item xs={5} sm={5} md={2} lg={2}>
                                <TextFieldComponent name={'numberDPC'} handler={handlerSearch} onChange={(val) => inputSearchChange('numberDPC', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionProspectStates} onChange={(val) => inputSearchChange('state', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <SelectComponent name={'financeType'} handler={handlerSearch} options={optionfinanceType} onChange={(val) => inputSearchChange('financeType', val)}/>
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br/>

            <TableComponent
                id={'financial_management_payment_follow_invoice_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Sessions'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();

                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        code: datas.code ?? '',
                        startAt: datas.startAt ?? '',
                        endAt: datas.endAt ?? '',
                        numberDPC: datas.numberDPC ?? '',
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        state: (datas.state === 'all') ? '' : datas.state,
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                    };

                    Api.get({
                            route: 'financial_management_payment_follow_invoice_list',
                            data: filters
                        },

                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].amount = data[index].amount + ' €';
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                if (data[index].creditId) {
                                    data[index].state = <span style={{color: '#DC3545', width: 85, display: 'block'}}>Annulée</span>
                                } else if (data[index].acquittedAt) {
                                    data[index].state = <span style={{color: '#28a745', width: 85, display: 'block'}}>Acquittée le <br/>{moment(data[index].acquittedAt).format('ll')}</span>
                                } else {
                                    data[index].state = <span style={{color: '#db770a', width: 85, display: 'block'}}>En attente de paiement</span>
                                }
                            }

                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/>

            <Dialog open={idEdit}>
                <DialogTitle style={{fontSize: 15}}>Facture - {idEdit}</DialogTitle>
                <DialogContent>
                    <div>
                        <DatePickerComponent name={'acquittedAt'} handler={handlerAcquitted}/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setIdEdit(null)} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});

export default PaymentFollowList;
