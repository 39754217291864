import { CardWelcome, CardHotlines, CardBilling, CardDashboardManager, CardSessions, CardFormations, CardInstitution, CardInstitutionGroup, CardRelaunch, CardRelaunchV2, CardInvestisor, CardVirtualClassroom, CardRessource, CardCourse } from './ExportCard';
export const listCard = [
    {
        roles: ['ROLE_SUPER_ADMIN'],
        view: ` 'welcome welcome institution formations'
                'welcome welcome institution investisor'
                'welcome welcome institution dashboardManager'
                'welcome welcome institutionGroup dashboardManager'
                'relaunch billing institutionGroup dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            billing: CardBilling,
            relaunch: CardRelaunchV2,
            institution: CardInstitution,
            formations: CardFormations,
            institutionGroup: CardInstitutionGroup,
            investisor: CardInvestisor,
            dashboardManager: CardDashboardManager,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_ADMIN'],
        view: ` 'welcome welcome institution virtualClassroom'
                'welcome welcome institution sessions'
                'welcome welcome institution dashboardManager'
                'welcome welcome institutionGroup dashboardManager'
                'investisor billing institutionGroup dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            billing: CardBilling,
            institution: CardInstitution,
            institutionGroup: CardInstitutionGroup,
            sessions: CardSessions,
            investisor: CardInvestisor,
            virtualClassroom: CardVirtualClassroom,
            dashboardManager: CardDashboardManager,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'],
        view: ` 'welcome welcome institution formations'
                'welcome welcome institution course'
                'welcome welcome virtualClassroom ressource'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
            formations: CardFormations,
            virtualClassroom: CardVirtualClassroom,
            ressource: CardRessource,
            course: CardCourse,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN', 'ROLE_INSTITUTION_CRM_ADMIN'],
        view: ` 'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institutionGroup dashboardManager'
                'hotlines relaunch institutionGroup dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
            institutionGroup: CardInstitutionGroup,
            relaunch: CardRelaunchV2,
            hotlines: CardHotlines,
            dashboardManager: CardDashboardManager,
        }
    },
    {
        roles: ['ROLE_INSTITUTION'],
        view: ` 'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institution institution'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
        }
    },
];

