import React from 'react';
import { resource } from '../../../../Api';

function Iframe(props) {

    let url; 

    if (props.type) {
        switch (props.type) {
            case 1: // PDF
                url = resource + 'library/pdf/' + props.path;
                return (
                    <iframe
                        style={{
                            border: 0,
                            height: 'calc(110vh - 120px)',
                            width: '100%',
                            textAlign: 'center',
                        }}
                        src={url}
                    />
                )

            case 3: // Youtube
                url = 'https://www.youtube.com/embed/' + props.path;
                return (
                    <iframe
                        style={{
                            border: 0,
                            height: 'calc(80vh - 100px)',
                            width: '100%',
                            textAlign: 'center',
                        }}
                        src={url}
                    />
                )

            case 4: // Image
                url = resource + 'library/image/' + props.path;
                return (
                    <img
                        style={{
                            border: 0,
                            height: 'calc(80vh - 100px)',
                            width: '80%',
                            textAlign: 'center',
                        }}
                        src={url}
                        alt={'image'}
                    />
                )

            case 5: // MP3 
                url = resource + 'library/mp3/' + props.path;

                return (
                    <div
                        style={{
                            height: 85,
                            textAlign: 'center',
                        }}
                    >
                        <audio
                            controls
                            src={url}
                            style={{width: '100%', height: 70}}
                        >
                        </audio>
                    </div>
                )
        }
    } else {
        return (<></>);
    }
}

export default Iframe;
