import React from 'react';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import { Box, makeStyles } from '@material-ui/core';
import ViewManagerComponent from './Component/ViewManagerComponent';

export default function InstitutionManager(props) {
    const classes = useStyles();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestionnaire d’établissements',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            links: [
                { path: getRoutePathname('institution_health_crm_institution_list'), label: 'Liste des établissements' },
            ],
        });
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent breadcrumbs={breadcrumbs} {...props}>
                <Box style={{ margin: 'auto', width: '95%' }}>
                    <ViewManagerComponent {...props} />
                </Box>
            </ContentViewComponent >
        </Box >
    );
}

const useStyles = makeStyles({
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
})