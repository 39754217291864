import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Delete} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert-react';
import {TableReload} from "../../../Action/TableAction";
import {getRoutePathname} from "../../../Config/Route";

function ThematicList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "label",
            label: "Libellé",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('marketing_manager_thematic', {id: row.id}))}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des thématiques',
            context: 'Gestion marketing',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'marketing_manager_thematic_list'}
                title={'Thématiques'}
                columns={columns}
                actionFirst={{
                    label: 'Ajout de thématique',
                    onClick: () => props.history.push(getRoutePathname('marketing_manager_thematic', {id: null}))
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'marketing_manager_thematic_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

            <br/>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer la thématique ' + actionRow.label + ' ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                            route: 'marketing_manager_thematic_delete',
                            params: {id: actionRow.id}
                        },
                        () => {
                            dispatch(TableReload('marketing_manager_thematic_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default ThematicList;
