import React from 'react';
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { dispatch, theme } from "../../../../App";
import TitleComponent from '../../../../Component/TitleComponent';
import { Avatar, Box, IconButton, makeStyles, Popover, Typography } from '@material-ui/core';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';

const ViewPopover = ({ data, setActionRow, setShowConfirm }) => {
    const classes = useStyles(theme)();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ margin: '0 0 0 auto' }}>
            <IconButton style={{ padding: 0 }} onClick={handleClick}>
                <MoreVertIcon style={{ color: "#5E6E82" }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography style={{ padding: 8 }}>
                    <IconButton
                        className={classes.buttonPostProspect}
                        onClick={() => { setActionRow(data); setShowConfirm(true); handleClose(); }}>
                        <ExitToAppIcon style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Retirer</span>
                    </IconButton>
                </Typography>
            </Popover>
        </div>
    )
};

function FormationPostProspectList(props) {
    const classes = useStyles(theme)();
    const propsData = props.propsData;
    const [data, setData] = React.useState([]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    React.useEffect(() => {
        setData(props.data)
    }, [props.data]);
    return (
        <>
            <Box className={classes.boxComponent}>
                <TitleComponent title={'Membres'} />
                <Box style={{ maxHeight: '39vw', overflow: 'scroll', }}>
                    {data.length >= 0 ?
                        <p className={classes.textError}><b>Aucun Membres</b></p>
                        :
                        Object.keys(data).map((dataList, index) =>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', margin: 'auto', padding: 10, alignItems: 'center' }}>
                                <Avatar className={classes.avatar}>{data[dataList].firstname.charAt().toUpperCase()}{data[dataList].lastname.charAt().toUpperCase()}</Avatar>
                                <p className={classes.boxName}>
                                    <span>{data[dataList].firstname} {data[dataList].lastname}</span>
                                    {data[dataList].pseudo ? <><br/><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%) scaleX(-1)" }} /><span style={{fontSize: 10}}>{ 'pseudo: '+data[dataList].pseudo}</span></> : ''}
                                </p>
                                <ViewPopover
                                    setActionRow={setActionRow}
                                    setShowConfirm={setShowConfirm}
                                    data={data[dataList]}
                                />
                            </Grid>
                        )
                    }
                </Box>
            </Box >
            <SweetAlert
                show={showConfirm}
                title={'Retirer'}
                text={'Êtes-vous sûr de vouloir retirer ce membre ?\n Si oui tous ces commentaires seront supprimés'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'community_group_formation_post_comment_all_delete',
                        params: {
                            id: actionRow.id,
                            groupFormation: propsData.match.params.groupFormationId,
                            post: propsData.match.params.id
                        }
                    },
                        (response) => {
                            if (response.status === 200) {
                                props.loadData();
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ce membre a bien été retirée.',
                                        variant: 'success',
                                    })
                                );
                            } else if (response.status === 404) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.message,
                                        variant: 'warning',
                                    })
                                );
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }

                        });
                }}
                onCancel={() => { setShowConfirm(false) }}
            />
        </>

    );
}

const useStyles = (theme) => makeStyles(({
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
    },
    textError: {
        textAlign: 'center',
        color: "#666",
        textTransform: "uppercase",
        fontSize: 20,
        opacity: 0.1,
        width: "100%"
    },
    boxName: {
        margin: '0px 10px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#495666",
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    buttonPostProspect: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
}));

export default FormationPostProspectList;
