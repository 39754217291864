import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core';
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../App';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { Delete } from '@material-ui/icons';

export default function ThematicFormation(props = {}) {

    const [show, setShow] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [actionIdFormation, setActionIdFormation] = React.useState();
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'name',
            label: 'Nom de la formation',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionIdFormation(row.id)
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const [form, setForm] = React.useState({
        formation: {
            name: 'formation',
            label: 'Formations',
            textHelper: 'Selectionnez les formations à liées',
            type: 'array',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);


    const [optionFormations, setOptionFormations] = React.useState([]);
    React.useEffect(() => {
        Api.get({
            route: 'select_formations'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormations(response.data);
                }
            });
        setLoading(false);
    }, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'marketing_manager_thematic_thematic_formation_add',
                data: handler.getData(),
                params: { id: props.match.params.id },
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Formation(s) ajoutée(s) à la thématique.',
                            variant: 'success',
                        })
                    );
                    setShow(false);
                    dispatch(TableReload('marketing_manager_thematic_thematic_formation_list'));
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    return (
        <>
            <TableComponent
                id={'marketing_manager_thematic_thematic_formation_list'}
                title={'Formations liée à la thématique :'}
                columns={columns}
                actionFirst={{
                    label: 'Associer des formations',
                    onClick: () => {
                        setShow(true);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'marketing_manager_thematic_thematic_formation_list',
                            params: { id: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                let idFormations = [];
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);

                                    idFormations.push(data[index].id);
                                }
                                handler.setValue('formation', idFormations);
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={show} maxWidth={'xl'} onClose={() => setShow(false)}>
                <DialogTitle style={{ fontSize: 17 }}>Associer des formations</DialogTitle>
                <DialogContent style={{ minWidth: '70vw' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MultipleSelectComponent name={'formation'} handler={handler} options={optionFormations} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setShow(false)} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'marketing_manager_thematic_thematic_formation_delete',
                        params: { id: props.match.params.id, formation: actionIdFormation }
                    },
                        (response) => {

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Relation thématique supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('marketing_manager_thematic_thematic_formation_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
