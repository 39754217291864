import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import ButtonComponent from "../../../../Component/ButtonComponent";


const CardComponent = (props) => {
    return (
        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, background: props.background, color: props.color, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', gap: 10 }}>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5 }}>{props.icon}{props.title}</h1>
                <p style={{ margin: 0, fontSize: 15 }}>{props.text}</p>
            </Box>
            {props.button &&
                <Box style={{ height: '100%', display: 'flex', }}>
                    <ButtonComponent
                        style={{ margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: props.button.color, background: props.button.background }}
                        label={props.button.disabled ? <LockIcon style={{ fontSize: 14 }} /> : props.button.title}
                        onClick={() => { !props.button.disabled && props.button.onClick() }}
                    />
                </Box>
            }
        </ShadowBoxComponent>
    )
}

export default CardComponent;