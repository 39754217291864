import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import { getRoutePathname } from "../../../../Config/Route";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "sweetalert-react";
import { TableReload } from "../../../../Action/TableAction";
import { Box, makeStyles } from '@material-ui/core';

function FormationList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [organism, setOrganism] = React.useState();

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Types de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupName",
            label: "Groupe",
            options: { filter: true, sort: true }
        },
        {
            name: "duration",
            label: "Durée",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('institution_health_content_manager_formation', { id: row.id }));
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const listData = (resolve) => {
        Api.get({
            route: 'institution_health_content_manager_formation_list',
            data: { organism: organism }
        },
            (response) => {
                let data = response.data;
                if (data) {
                    for (let index in data) {
                        data[index].duration = (data[index].duration / 60 / 60) + 'h';
                        data[index].createdAt = moment(data[index].createdAt).format('lll');
                        data[index].action = getAction(data[index]);
                    }
                    resolve(data);
                } else resolve([]);
            });
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Formations',
            context: 'Gestion de contenu',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des formations.',
        });
    }, []);

    React.useEffect(() => {
        setReadyContent(false)
        if (selectOrganismReducer.organism.value) {
            setOrganism(selectOrganismReducer.organism.value)
        }
    }, [selectOrganismReducer]);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_content_manager_formation_list'}
                        title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}>Formations</h1>}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter une formation', onClick: () => props.history.push(getRoutePathname('institution_health_content_manager_formation', { id: null })),
                        }}
                        actionSecond={{
                            label: 'Télécharger en PDF', onClick: () => {
                                const url = Api.route({ route: 'institution_health_content_manager_formation_list_download', data: { organism: organism } });
                                window.open(url, '_blank');
                            },
                        }}
                        promiseData={(resolve) => listData(resolve)}
                    />
                    <br />
                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet formation ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_content_manager_formation_delete',
                                params: { id: actionRow.id }
                            },
                                () => {
                                    dispatch(TableReload('institution_health_content_manager_formation_list'))
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
}));

export default FormationList;
