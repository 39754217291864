import React from 'react';
import {Switch, Route} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';

export default function AuthenticationLayout(props) {
    const classes = useStyles();

    return (
        <div className={classes.authenticationLayout}>
            <Switch>
                {
                    props.routes.map((route) => (
                        <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes}/>}/>
                    ))
                }
            </Switch>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    authenticationLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    }
}));
