import React from 'react';
import Api from "../../../../Api";
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TableComponent from '../../../../Component/TableComponent';
import moment from "moment";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../../App';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import SweetAlert from "sweetalert-react";


const ResourceGroupList = (props) => {
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [edit, setEdit] = React.useState({
        isEdit: false,
        idType: null
    });
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "groupName",
            label: "Nom du groupe",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: { filter: true, sort: true }
        },
        {
            name: "updatedAt",
            label: "Date de modification",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du groupe.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setEdit({ isEdit: true, idType: row.id });
                        handler.setDataApi(row);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setShowConfirm(true);
                        setEdit({ isEdit: false, idType: row.id });
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des groupes de ressources',
            context: 'Gestion de contenu',
            description: ''
        });
        setReadyContent(true);
    }, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (edit.idType) {
                Api.post({
                    route: 'institution_health_content_manager_resource_group_edit',
                    data: handler.getData(),
                    params: { id: edit.idType }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Groupe de ressource modifié.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('institution_health_content_manager_resource_group_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_content_manager_resource_group_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Groupe de ressource enregistrée.',
                                variant: 'success',
                            }),
                            setEdit({ isEdit: false, idType: null }),
                            dispatch(TableReload('institution_health_content_manager_resource_group_list'))
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };


    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>

                    <TableComponent
                        id={'institution_health_content_manager_resource_group_list'}
                        title={'Groupes des ressources'}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un groupe de ressource',
                            onClick: () => setEdit({ isEdit: true, idType: null })
                        }}

                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_content_manager_resource_group_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].action = getAction(data[index]);
                                        data[index].groupName = data[index].label;
                                        data[index].id = data[index].value;
                                        data[index].createdAt = moment(data[index].createdAt).format('lll');
                                        data[index].updatedAt = moment(data[index].updatedAt).format('lll');
                                    }
                                    resolve(data);
                                });
                        }}
                    />

                    <Dialog open={edit.isEdit} fullWidth={true}>
                        <DialogTitle style={{ fontSize: 15 }}>{edit.idType ? 'Modifier le nom du groupe n°' : 'Ajouter un groupe'}{edit.idType}</DialogTitle>
                        <DialogContent>
                            <div>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextFieldComponent name={'name'} handler={handler} />
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent disabled={loading} onClick={() => setEdit({ isEdit: false, idType: edit.idType })} color={'#5E6E82'} label={'Annuler'} />
                            <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                        </DialogActions>
                    </Dialog>

                    <br />

                    <SweetAlert
                        show={showConfirm}
                        title={'Supprimer'}
                        text={'Êtes-vous sur de vouloir supprimer ce groupe de ressource ?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            setShowConfirm(false);
                            Api.delete({
                                route: 'institution_health_content_manager_resource_group_delete',
                                params: { id: edit.idType }
                            },
                                (response) => {
                                    dispatch(LinearProgressMainLayoutDeactivate());

                                    if (response.status === 200) {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'groupe de ressource supprimée.',
                                                variant: 'success',
                                            })
                                        );
                                        dispatch(TableReload('institution_health_content_manager_resource_group_list'));
                                    } else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
};
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
}));
export default ResourceGroupList;