import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {Doughnut, Bar, HorizontalBar} from 'react-chartjs-2';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Slide from "@material-ui/core/Slide";
import Switch from "@material-ui/core/Switch";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {KeyboardDatePicker} from '@material-ui/pickers';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FilterListIcon from '@material-ui/icons/FilterList';
import ShadowBoxComponent from "./../../../Component/ShadowBoxComponent";
import Card from "./Component/Card";
import ChartColorOpacity from "../../../Constant/ChartColorOpacity";
import FormOriginInfo from "../../../Constant/FormOriginInfo";
import Api from "./../../../Api";
import {inArray, removeElementInArray} from "../../../Tool/ArrayCommon";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TitleComponent from "../../../Component/TitleComponent";

function Dashboard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [isReadyChart, setReadyChart] = React.useState(false);
    const [isTurnoverFormation, setIsTurnoverFormation] = React.useState(true);
    const [period, setPeriod] = React.useState(0);
    const [formOrigins, setFormOrigins] = React.useState([]);
    const [formationGroups, setFormationGroups] = React.useState([]);
    const [formations, setFormations] = React.useState([]);
    const [campaigns, setCampaigns] = React.useState([]);
    const [campaign, setCampaign] = React.useState(0);
    const [organism, setOrganism] = React.useState(0);
    const [organisms, setOrganisms] = React.useState(0);
    const [campaignTypes, setCampaignTypes] = React.useState([]);
    const [campaignType, setCampaignType] = React.useState(0);
    const [formOriginSelected, setFormOriginSelected] = React.useState([]);
    const [info, setInfo] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());
    const [endAt, setEndAt] = React.useState(null);
    const [textSearchSlider, setTextSearchSlider] = React.useState('');
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isSmallView, setSmallView] = React.useState(false);

    // Get Info all chart
    const getInfo = () => {
        if (startAt && endAt) {
            dispatch(LinearProgressMainLayoutActivate());

            Api.get({
                route: 'reporting_marketing_dashboard_info',
                data: {
                    startAt: startAt.format('YYYY-MM-DD HH:mm:ss'),
                    endAt: endAt.format('YYYY-MM-DD HH:mm:ss'),
                    campaign: campaign === '0' ? null : campaign,
                    campaignType: campaignType === '0' ? null : campaignType,
                    organism: organism === '0' ? null : organism
                }
            }, (response) => {
                if (response && response.data) setInfo(response.data);
                dispatch(LinearProgressMainLayoutDeactivate());
            });
        }
    };

    // Treatment Percentage by Profession
    const getDataPercentageProfession = () => {
        let tmpData = {'data': [], 'labels': []};
        let formationGroupData = {};

        if (info.formOriginData && formationGroups) {
            for (let index in info.formOriginData) {
                if (inArray(parseInt(index), formOriginSelected)) continue;

                for (let index1 in info.formOriginData[index]['formationGroupData']) {
                    if (!formationGroupData[index1]) formationGroupData[index1] = {};

                    if (formationGroupData[index1].count) {
                        formationGroupData[index1].count += info.formOriginData[index]['formationGroupData'][index1].count;
                    } else {
                        formationGroupData[index1].count = info.formOriginData[index]['formationGroupData'][index1].count;
                    }
                }
            }

            for (let index in formationGroups) {
                tmpData['labels'].push(formationGroups[index].name);
                tmpData['data'].push(formationGroupData[formationGroups[index].id] && formationGroupData[formationGroups[index].id].count);
            }
        }

        return tmpData;
    };
    const dataPercentageProfession = {
        datasets: [{
            data: getDataPercentageProfession()['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataPercentageProfession()['labels'],
        options: {
            legend: {
                position: 'bottom',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                text: 'Formulaire généré par profession',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + ' formulaires Générés';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    // Treatment Turnover by Profession
    const getDataTurnoverProfession = () => {
        let tmpData = {'data': [], 'labels': []};
        let formationGroupData = {};

        if (info.formOriginData && formationGroups) {
            for (let index in info.formOriginData) {
                if (inArray(parseInt(index), formOriginSelected)) continue;

                for (let index1 in info.formOriginData[index]['formationGroupData']) {
                    if (!formationGroupData[index1]) formationGroupData[index1] = {};

                    if (formationGroupData[index1].turnover) {
                        formationGroupData[index1].turnover += info.formOriginData[index]['formationGroupData'][index1].turnover;
                    } else {
                        formationGroupData[index1].turnover = info.formOriginData[index]['formationGroupData'][index1].turnover;
                    }
                }
            }

            for (let index in formationGroups) {
                tmpData['labels'].push(formationGroups[index].name);
                tmpData['data'].push(formationGroupData[formationGroups[index].id] && formationGroupData[formationGroups[index].id].turnover);
            }
        }

        return tmpData;
    };
    const dataTurnoverProfession = {
        datasets: [{
            data: getDataTurnoverProfession()['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataTurnoverProfession()['labels'],
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Chiffre d\'affaires par profession hors taxe',
                fontSize: 20,
                fontColor: '#A1A2A9',
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + ' euros';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    // Treatment Turnover & Count by Formation
    const getDataTurnoverFormation = () => {
        let tmpData = {'data': [], 'labels': []};
        let formationData = {};

        if (info.formOriginData && formations) {
            for (let index in info.formOriginData) {
                if (inArray(parseInt(index), formOriginSelected)) continue;

                for (let index1 in info.formOriginData[index]['formationData']) {
                    if (!formationData[index1]) formationData[index1] = {};

                    if (formationData[index1].turnover) {
                        formationData[index1].turnover += info.formOriginData[index]['formationData'][index1].turnover;
                    } else {
                        formationData[index1].turnover = info.formOriginData[index]['formationData'][index1].turnover;
                    }
                }
            }

            for (let index in formations) {
                tmpData['labels'].push(formations[index].name.substring(0, 75) + '... ');
                tmpData['data'].push(formationData[formations[index].id] && formationData[formations[index].id].turnover);
            }
        }

        return tmpData;
    };
    const getDataCountFormation = () => {
        let tmpData = {'data': [], 'labels': []};
        let formationData = {};

        if (info.formOriginData && formations) {
            for (let index in info.formOriginData) {
                if (inArray(parseInt(index), formOriginSelected)) continue;

                for (let index1 in info.formOriginData[index]['formationData']) {
                    if (!formationData[index1]) formationData[index1] = {};

                    if (formationData[index1].count) {
                        formationData[index1].count += info.formOriginData[index]['formationData'][index1].count;
                    } else {
                        formationData[index1].count = info.formOriginData[index]['formationData'][index1].count;
                    }
                }
            }

            for (let index in formations) {
                tmpData['labels'].push(formations[index].name.substring(0, 75) + '... ');
                tmpData['data'].push(formationData[formations[index].id] && formationData[formations[index].id].count);
            }
        }

        return tmpData;
    };
    const getFormationColor = () => {
        let n = Math.ceil(formations.length / 10);
        let tmpColor = [];
        for (let i = 0; i < n; i++) {
            tmpColor = [...tmpColor, ...ChartColorOpacity];
        }
        return tmpColor;
    };
    const dataTurnoverFormation = {
        datasets: [{
            data: isTurnoverFormation ? getDataTurnoverFormation()['data'] : getDataCountFormation()['data'],
            backgroundColor: getFormationColor(),
            borderColor: '#FFFFFF'
        }],
        labels: isTurnoverFormation ? getDataTurnoverFormation()['labels'] : getDataCountFormation()['labels'],
        options: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: isTurnoverFormation ? 'Chiffre d\'affaires par formation hors taxe' : 'Formulaire généré par formation',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 15
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']] + (isTurnoverFormation ? ' euros' : ' formulaires générés');
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    // Treatment search form
    const changeSearchSlider = (direction = null) => {
        let newStartAt;
        let newEndAt;

        switch (period) {
            case 0: { // Journalière
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'days');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'days');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('day').format());
                newStartAt = moment(newStartAt.startOf('day').format());

                setTextSearchSlider(newStartAt.format('dddd LL'));
                break;
            }
            case 1: { // Hebdomadaire
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'week');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'week');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('week').format());
                newStartAt = moment(newStartAt.startOf('week').format());

                setTextSearchSlider(newStartAt.format('dddd LL') + ' - ' + newEndAt.format('dddd LL'));
                break;
            }
            case 2: { // Mensuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'month');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'month');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('month').format());
                newStartAt = moment(newStartAt.startOf('month').format());

                setTextSearchSlider(newStartAt.format('MMMM YYYY'));
                break;
            }
            case 3: { // Annuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('year').format());
                newStartAt = moment(newStartAt.startOf('year').format());

                setTextSearchSlider(newStartAt.format('YYYY'));
                break;
            }
            case 4: { // Personnalisée par plage
                newEndAt = endAt;
                newStartAt = startAt;
                break;
            }
            default: {
                newStartAt = null;
                newEndAt = null;
                break;
            }
        }

        setStartAt(newStartAt);
        setEndAt(newEndAt);
    };
    const periodChange = (event) => {
        setStartAt(moment().startOf('day'));
        setPeriod(parseInt(event.target.value));
    }

    // Render
    const renderChart = () => {
        return <>
            <Grid style={{marginTop: 2}} container spacing={3}>{formOrigins.map((formOrigin) => {
                let data = info.formOriginData ? info.formOriginData[formOrigin.id] : {};
                return (
                    <Zoom in={isReadyChart} style={{transitionDelay: '0ms', cursor: 'pointer'}} {...(isReadyChart ? {timeout: 500} : {})} key={formOrigin.id}>
                        <Grid item xs={12} sm={6} md={6} lg={3} onClick={() => {
                            if (inArray(formOrigin.id, formOriginSelected)) {
                                setFormOriginSelected(removeElementInArray(formOriginSelected, formOrigin.id));
                            } else {
                                setFormOriginSelected([...formOriginSelected, formOrigin.id]);
                            }
                        }}>
                            <Card
                                title={formOrigin.title}
                                number={(data && data.count) ? data.count : 0}
                                percentage={(data && data.percentage) ? data.percentage : 0}
                                turnover={(data && data.turnover) ? data.turnover : 0}
                                icon={formOrigin.icon}
                                color={formOrigin.color}
                                disabled={inArray(formOrigin.id, formOriginSelected)}
                            />
                        </Grid>
                    </Zoom>
                )
            })}</Grid>
            {
                !isSmallView && (
                    <>
                        <Grid container spacing={3}>
                            <Zoom in={isReadyChart} style={{transitionDelay: '0ms'}} {...(isReadyChart ? {timeout: 500} : {})}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <ShadowBoxComponent>
                                        <Doughnut data={dataPercentageProfession} options={dataPercentageProfession.options}/>
                                    </ShadowBoxComponent>
                                </Grid>
                            </Zoom>
                            <Zoom in={isReadyChart} style={{transitionDelay: '0ms'}} {...(isReadyChart ? {timeout: 500} : {})}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <ShadowBoxComponent>
                                        <Bar data={dataTurnoverProfession} options={dataTurnoverProfession.options}/>
                                    </ShadowBoxComponent>
                                </Grid>
                            </Zoom>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12} style={{position: 'relative'}}>
                                <Grid component="label" container alignItems="center" spacing={1} style={{position: 'absolute', zIndex: 2, top: 20, right: 15, width: 290}}>
                                    <Grid item style={{fontSize: 12, color: '#5E6E82'}}>Formulaire généré</Grid>
                                    <Grid item>
                                        <Switch
                                            checked={isTurnoverFormation}
                                            onChange={(event) => setIsTurnoverFormation(event.target.checked)}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                        />
                                    </Grid>
                                    <Grid item style={{fontSize: 12, color: '#5E6E82'}}>Chiffre d'affaires</Grid>
                                </Grid>
                                <ShadowBoxComponent>
                                    <HorizontalBar height={500} data={dataTurnoverFormation} options={dataTurnoverFormation.options}/>
                                </ShadowBoxComponent>
                            </Grid>
                        </Grid>
                    </>
                )
            }

        </>;
    };
    const renderSearch = () => {
        return <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
            <div>
                <ShadowBoxComponent>
                    <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel htmlFor="outlined-age-native-simple">Période</InputLabel>
                                <Select
                                    native
                                    value={period}
                                    onChange={periodChange}
                                    label="Période"
                                    inputProps={{name: 'Période', id: 'outlined-age-native-simple'}}
                                    className={classes.selectPeriod}
                                >
                                    <option value={0}>Journalière</option>
                                    <option value={1}>Hebdomadaire</option>
                                    <option value={2}>Mensuelle</option>
                                    <option value={3}>Annuelle</option>
                                    <option value={4}>Personnalisée par plage</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        {period !== 4 ?
                            <Grid item xs={12} sm={12} md={8} lg={8} style={{position: 'relative'}}>
                                <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')}/>
                                <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')}/>
                            </Grid> :
                            <>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <KeyboardDatePicker
                                        className={classes.datepicker}
                                        autoOk
                                        inputVariant="outlined"
                                        margin="normal"
                                        id="startAt"
                                        label="Début"
                                        format="MM/dd/yyyy"
                                        value={startAt}
                                        onChange={(date) => setStartAt(moment(date).startOf('day'))}
                                        KeyboardButtonProps={{'aria-label': 'change date'}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <KeyboardDatePicker
                                        className={classes.datepicker}
                                        autoOk
                                        inputVariant="outlined"
                                        margin="normal"
                                        id="endAt"
                                        label="Fin"
                                        format="MM/dd/yyyy"
                                        value={endAt}
                                        onChange={(date) => setEndAt(moment(date).endOf('day'))}
                                        KeyboardButtonProps={{'aria-label': 'change date'}}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid container spacing={3} style={{marginTop: 10}}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel htmlFor="outlined-age-native-simple">Organisme</InputLabel>
                                <Select
                                    native
                                    value={organism}
                                    onChange={(event) => setOrganism(event.target.value)}
                                    label="Organisme"
                                    inputProps={{name: 'Organisme', id: 'outlined-age-native-simple'}}
                                    className={classes.selectPeriod}
                                >
                                    <option value={0}>Tous</option>
                                    {
                                        organisms && organisms.map((organism) => {
                                            return <option key={organism.value} value={organism.value}>{organism.label}</option>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel htmlFor="outlined-age-native-simple">Type de campagne</InputLabel>
                                <Select
                                    native
                                    value={campaignType}
                                    onChange={(event) => {
                                        setCampaignType(parseInt(event.target.value));
                                        setCampaign(0);
                                    }}
                                    label="Type de campagne"
                                    inputProps={{name: 'Type de campagne', id: 'outlined-age-native-simple'}}
                                    className={classes.selectPeriod}
                                >
                                    <option value={0}>Tous</option>
                                    <option value={-1}>Seulement les campagnes</option>
                                    <option value={-2}>Sans les campagnes</option>
                                    {
                                        campaignTypes && Object.entries(campaignTypes).map((campaignType) => {
                                            return <option key={campaignType[1].id} value={campaignType[1].id}>{campaignType[1].name}</option>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl variant="outlined" style={{width: '100%'}}>
                                <InputLabel htmlFor="outlined-age-native-simple">Campagne</InputLabel>
                                <Select
                                    native
                                    value={campaign}
                                    onChange={(event) => setCampaign(event.target.value)}
                                    label="Campagne"
                                    inputProps={{name: 'Campagne', id: 'outlined-age-native-simple'}}
                                    className={classes.selectPeriod}
                                >
                                    <option value={0}>Toutes</option>
                                    {
                                        campaigns && Object.entries(campaigns).map((campaign) => {
                                            if (campaign[1].campaignTypeId === campaignType || campaignType === 0 || campaignType === -1) {
                                                return <option key={campaign[1].id} value={campaign[1].id}>{campaign[1].name}</option>;
                                            }
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </div>
        </Slide>;
    };

    React.useEffect(() => {
        let promises = [];
        let tmpFormOrigins = [];
        let tmpFormationGroups = [];
        let tmpFormations = [];
        let tmpCampaigns = [];
        let tmpCampaignTypes = [];
        let tmpOrganisms = [];

        promises.push(new Promise((resolve) => {
            Api.get({
                route: 'reporting_marketing_dashboard_form_origin_list',
            }, (response) => {
                if (response && response.data) {
                    tmpFormOrigins = response.data;
                    for (let index in tmpFormOrigins) {
                        let id = tmpFormOrigins[index].id
                        if (FormOriginInfo[id]) {
                            tmpFormOrigins[index].title = FormOriginInfo[id].title;
                            tmpFormOrigins[index].color = FormOriginInfo[id].color;
                            tmpFormOrigins[index].icon = FormOriginInfo[id].icon;
                        }
                    }
                }

                resolve();
            });
        }));
        promises.push(new Promise((resolve) => {
            Api.get({
                route: 'reporting_marketing_dashboard_formation_group_list',
            }, (response) => {
                if (response && response.data) {
                    tmpFormationGroups = response.data;
                }

                resolve();
            });
        }));
        promises.push(new Promise((resolve) => {
            Api.get({
                route: 'reporting_marketing_dashboard_formation_list',
            }, (response) => {
                if (response && response.data) {
                    tmpFormations = response.data;
                }

                resolve();
            });
        }));
        promises.push(new Promise((resolve) => {
            Api.get({
                route: 'reporting_marketing_dashboard_campaign_list',
            }, (response) => {
                if (response && response.data) {
                    tmpCampaigns = response.data['campaigns'];
                    tmpCampaignTypes = response.data['campaignTypes'];
                }

                resolve();
            });
        }));
        promises.push(new Promise((resolve) => {
            Api.get({
                route: 'select_organisms',
            }, (response) => {
                if (response && response.data) {
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            tmpOrganisms.push(response.data[index]);
                        }
                    }
                }

                resolve();
            });
        }));

        Promise.all(promises).then(() => {
            setFormOrigins(tmpFormOrigins);
            setFormationGroups(tmpFormationGroups);
            setFormations(tmpFormations);
            setCampaigns(tmpCampaigns);
            setCampaignTypes(tmpCampaignTypes);
            setOrganisms(tmpOrganisms);
            setReadyContent(true);
            setTimeout(() => setReadyChart(true), 500);
        });
    }, []);
    React.useEffect(changeSearchSlider, [period]);
    React.useEffect(getInfo, [startAt, campaign, campaignType, organism]);
    React.useEffect(() => {
        if (period === 4) getInfo();
    }, [endAt]);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Tableau de bord',
            context: 'Reporting Marketing',
            description: 'Statistiques des formulaires du site internet.'
        });
    }, []);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS'));
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            {isReadyContent && renderSearch()}
            {isReadyContent && isReadyChart && renderChart()}
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h1: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 30,
        fontWeight: 400,
        backgroundColor: '#FFF',
        margin: '0 0 20px 0',
        padding: 20,
        borderBottom: 'solid 1px #ccc'
    },
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    datepicker: {
        margin: 0,
        width: '100%',
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    textSearchSlider: {
        fontSize: 16,
        textAlign: 'center',
        height: 40,
        lineHeight: '40px',
        textTransform: 'capitalize'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    }
});

export default Dashboard;
