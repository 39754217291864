import React from 'react';
import {makeStyles} from "@material-ui/core";
import moment from "moment";
import {KeyboardDatePicker} from "@material-ui/pickers";

function DatePickerComponent(props = {
    name: '',
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? moment(data.value) : null);
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled || props.disabled;

    const onChange = (date) => {
        if (date === null) {
            let newForm = {...props.handler.form};
            newForm[props.name].value = null;
            newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
            setError(newForm[props.name].error);
            props.handler.set(newForm);

            if (props.onChange) {
                props.onChange('');
                setFocus(false);
            }

            setValue(null);
        } else if (date != 'Invalid Date') {
            let val = moment(date);
            if (val.isValid()) {
                let newForm = {...props.handler.form};
                newForm[props.name].value = val.format('YYYY-MM-DD');
                newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
                setError(newForm[props.name].error);
                props.handler.set(newForm);
            }
            setValue(val);

            if (props.onChange) {
                props.onChange(val.format('YYYY-MM-DD'));
                setFocus(false);
            }
        } else {
            setValue('Invalid Date');
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = {...props.handler.form};
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value ? props.handler.form[props.name].value : null);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <KeyboardDatePicker
            className={`
                ${classes.datePicker}
                ${isFocus ? classes.datePickerFocus : error ? classes.datePickerError : value && !disabled ? classes.datePickerValid : ''}
            `}
            autoOk
            inputVariant="outlined"
            okLabel='Ok'
            cancelLabel='Annuler'
            margin="normal"
            id={props.name}
            label={data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}
            format="dd/MM/yyyy"
            value={value}
            helperText={error && !isFocus ? error : data.textHelper}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
        />
    );
}

const useStyles = makeStyles({
    datePicker: {
        width: '100%',
        marginTop: 0,
        '& input': {
            padding: '10px 14px',
            fontSize: 13
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 9,
            height: 9,
            lineHeight: '9px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        },
        '& button > span > svg': {
            width: 16,
            height: 16
        }
    },
    datePickerFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    datePickerValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& label': {
            color: '#008000 !important'
        }
    },
    datePickerError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    }
});

export default DatePickerComponent;
