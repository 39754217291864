import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import {theme} from "../../../App";
import activity from "react-useanimations/lib/activity";
import {getRoutePathname} from '../../../Config/Route';
import {Fade, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api, {learnerHomeFormationImageBanner} from "../../../Api";
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../Component/SelectComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TitleComponent from '../../../Component/TitleComponent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LazyBackground from '../Catalog/Component/LazyBackground';
import TextFieldComponent from '../../../Component/TextFieldComponent';

const Register = (props) => {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [activeFinanceType, setActiveFinanceType] = React.useState();
    const [formationSelected, setFormationSelected] = React.useState();
    const [personalPrice, setPersonalPrice] = React.useState(0);
    const [paymentTypeActive, setPaymentTypeActive] = React.useState(null);
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState([]);
    const [registerBtnLabel, setRegisterBtnLabel] = React.useState();
    const [promo, setPromo] = React.useState({percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0});
    const [hours, setHours] = React.useState();
    const [financeTypes, setFinanceTypes] = React.useState([]);

    const [form, setForm] = React.useState({
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Saisissez un type.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        promotionCodeStripe: {
            name: 'promotionCodeStripe',
            label: 'Code promotionnel',
            textHelper: 'Entrer un code promotionel',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const [registerSuccess, setRegisterSuccess] = React.useState({
        isSuccess: '',
        formationName: '',
        duration: '',
        session: '',
        financeType: '',
    });
    const handler = formHandlerInit(form, setForm);

    const loadSession = (formationId, financeType) => {
        setOptionsSessionFormation([]);
        handler.reset();
        handler.setFormLoading(true);
        setLoadingForm(true);
        Api.get({
                route: 'learner_catalog_formation_session_formation_list',
                params: {formation: formationId},
                data: {financeType: financeType.id}
            },
            (response) => {
                handler.setFormLoading(false);
                setLoadingForm(false);
                if (response && response.data) {
                    setOptionsSessionFormation(response.data);
                }
            });
    };
    const register = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoadingForm(true);

            let data = handler.getData();

            Api.post({
                    route: 'learner_catalog_register',
                    data: {
                        ...data, 
                        formation: formationSelected.id, 
                        financeType: parseInt(activeFinanceType) === 99 ? 6 : parseInt(activeFinanceType),
                        isFifpl: parseInt(activeFinanceType) === 99 ? true : false
                    },
                },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoadingForm(false);

                    if (response.status === 201) {
                        window.dataLayer.push({
                            'event': 'eec.add',
                            'items': {
                                'numero_formation': formationSelected.id,
                                'nom_formation': formationSelected.name,
                                'duree_formation': hours
                            },
                            'add_type': "réinscription"
                        })

                        if (response.data.sessionIdStripe) {
                            props.history.push(getRoutePathname('payment_stripe'), {sessionIdStripe: response.data.sessionIdStripe, clientSecret: response.data.clientSecret});
                        }
                        else {
                            setRegisterSuccess({
                                isSuccess: true,
                                formationName: formationSelected.name,
                                duration: hours,
                                session: response.data.session,
                                financeType: response.data.financeType
                            });
                        }
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        if (formationSelected && formationSelected.id && theme.id === 2) {
            window.dataLayer.push({
                'event': 'eec.detail',
                'duree_formation': hours + " heures",
                'format_formation': "E-learning",
                'titre_formation': formationSelected.name,
                'numero_formation': formationSelected.id,
                'type_page' : "catalogue",
            })
        }

    }, [formationSelected]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
                route: 'learner_catalog_formation_get',
                params: {id: props.match.params.id}
            },
            (response) => {
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setFormationSelected(response.data);
                    setOptionPersonalPaymentType(response.data.optionPersonalPaymentType);
                    setActiveFinanceType(response.data.financeTypes[0].id);

                    // HACK FINANCE TYPE FIFPL non conventionné
                    let financeType = [];
                    for (let index in response.data.financeTypes) {
                        if (response.data.financeTypes[index].label === 'PERSONNEL') {
                            financeType.push({
                                label: 'FIFPL non conventionné',
                                id: '99'
                            });
                            financeType.push(response.data.financeTypes[index]);
                        }
                        else {
                            financeType.push(response.data.financeTypes[index]);
                        }
                    }
                    setFinanceTypes(financeType);

                    if (parseInt(response.data.financeTypes[0].id !== 6)) {
                        loadSession(response.data.id, response.data.financeTypes[0]);
                    }
                    if (response.data.description) {
                        response.data.description = response.data.description.replace(/\n/g, "<br/>")
                    }
                    if (response.data.objectives) {
                        response.data.objectives = response.data.objectives.replace(/\n/g, "<br/>")
                    }
                    setBreadcrumbs({
                        currentPage: response.data.name && response.data.name,
                        links: [
                            {path: getRoutePathname('learner_home'), label: 'Tableau de bord'},
                            {path: getRoutePathname('learner_catalog'), label: 'Catalogue de formations'},
                        ]
                    });
                }, 1000);
            });
    }, []);
    React.useEffect(() => {
        if (formationSelected) {
            setHours(formationSelected.hours['default']);
            if (activeFinanceType) {
                if (activeFinanceType === '99') {
                    setHours(formationSelected.hours['6']);
                }
                else {
                    setHours(formationSelected.hours[activeFinanceType]);
                }
            }
        }
        if (((parseInt(activeFinanceType) === 6 || parseInt(activeFinanceType) === 99) && parseInt(paymentTypeActive) === 1)) {
            setRegisterBtnLabel('Procéder au paiement');
        }
        else {
            setRegisterBtnLabel('Je m\'inscris');
        }

        if (formationSelected && !paymentTypeActive && optionPersonalPaymentType && optionPersonalPaymentType.length > 0 ) {
            handler.setValue('personalPaymentType', optionPersonalPaymentType[0].value)
            setPaymentTypeActive(optionPersonalPaymentType[0].value);
        }
        if (formationSelected) {
            setPersonalPrice(formationSelected.personalPrice['default']);
            if (paymentTypeActive) {
                setPersonalPrice(formationSelected.personalPrice[paymentTypeActive]);
            }
        }
        if (promo && promo.percent) {
            changePriceByPromo(promo.percent);
        }
    }, [paymentTypeActive, activeFinanceType]);
    
    const changePriceByPromo = (percent) => {
        if (formationSelected && paymentTypeActive) {
            let originalPrice = formationSelected.personalPrice[paymentTypeActive];
            let discountAmount = originalPrice * (percent / 100);
            let remisedPrice = originalPrice - discountAmount;
            setPromo({
                percent: percent, 
                remisedPrice: remisedPrice, 
                originalPrice: originalPrice, 
                discountAmount: discountAmount
            });
        }
    };

    const applyCode = () => {
        let data = handler.getData();
        
        if (!data.personalPaymentType) {
            handler.setError('personalPaymentType', 'Veuillez entrer un type de paiement avant de valider');
            return;
        }

        if (data.promotionCodeStripe) {
            handler.setFormLoading(true);
            setLoadingForm(true);
            Api.get({
                route: 'stripe_promotion_code_verify',
                data: {
                    organism: theme.id,
                    code: data.promotionCodeStripe,
                    formation: formationSelected.id
                },
            },
            (response) => {
                if (response.status === 200) {
                    changePriceByPromo(response.data.percent);
                }
                else if (response.status === 400) {
                    handler.setValue('promotionCodeStripe', '');
                    handler.setErrorApi(response.error);
                    setPromo({percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0});
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                handler.setFormLoading(false);
                setLoadingForm(false);
            });
        }
        else {
            handler.setError('promotionCodeStripe', 'Veuillez entrer un code avant de valider');
        }
    };

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />

            <div style={{textAlign: 'center', maxWidth: 1240, margin: 'auto'}}>
                {
                    loading ?
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        :
                        <>
                            <div style={{padding: 15, width: isSmallView ? '90%' : '80%', margin: 'auto'}}>
                                <p style={{width: '100%', padding:' 0 5px 10px 5px', fontSize: 24, textAlign: 'left', fontWeight: 700}}>{formationSelected ? formationSelected.name : ''}</p>
                                {
                                    formationSelected &&
                                    <p style={{fontSize: 13, textAlign: 'left', margin: '0px 0px 5px 10px', color: theme.colorPrimary}}>
                                        {formationSelected.isEpp === 1 ? 'Programme intégré' : 'Formation continue'}
                                    </p>
                                }
                                <div className={classes.container}>

                                    {formationSelected && 
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                                                <LazyBackground classe={classes.circle} src={learnerHomeFormationImageBanner + formationSelected.image}/>
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                                                
                                                <div style={{textAlign: 'justify'}}>
                                                    {formationSelected.description ?
                                                        <p className={classes.dialogText}>
                                                            <span className={classes.boxTitle}>Description : </span><br/>
                                                            <span dangerouslySetInnerHTML={{__html: formationSelected.description}}/>
                                                        </p> : <></>}
                                                    {formationSelected.objectives ?
                                                        <p className={classes.dialogText}>
                                                            <span className={classes.boxTitle}>Objectifs : </span><br/>
                                                            <span dangerouslySetInnerHTML={{__html: formationSelected.objectives}}/>
                                                        </p> : <></>}
                                                </div>
                                            {(formationSelected && hours) && <p className={classes.dialogText} style={{textAlign: 'left'}}><span className={classes.boxTitle}>Temps de formation</span><br/>{hours} heures</p>}
        
                                            {(activeFinanceType === '1' && formationSelected.code) &&
                                            <p className={classes.dialogText} style={{textAlign: 'left'}}>
                                                <span className={classes.boxTitle}>Code ANDPC</span><br/>
                                                <span>{formationSelected.code}</span>
                                                <Tooltip title="Copier le code">
                                                    <IconButton aria-label="copy" size="small"
                                                                onClick={() => {
                                                                    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                                        navigator.clipboard
                                                                            .writeText(formationSelected.code)
                                                                            .then(r => dispatch(
                                                                                SnackbarOpen({
                                                                                    text: 'Code copié dans le presse papier',
                                                                                    variant: 'success',
                                                                                })
                                                                            ))
                                                                    }
                                                                }}>
                                                        <FileCopyIcon fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </p>
                                            }
                                            </Grid>
                                        </Grid>
                                    }

                                    <div className={classes.boxForm} style={{width: '100%', background: '#fff'}}>
                                        <p className={classes.boxFormTitle}>Choisir un mode de financement :</p>
                                        <div style={{display: 'flex', justifyContent: 'center', margin: 10}}>
                                            {formationSelected && financeTypes.map((financeType) =>
                                                <ShadowBoxComponent
                                                    key={financeType.id}
                                                    className={financeType.id === activeFinanceType ? classes.buttonFinanceActive : classes.buttonFinance}
                                                    style={{width: isTall ? 100 : 85, padding: !isTall && 6, display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                                    onClick={() => {
                                                        setActiveFinanceType(financeType.id);
                                                        if (parseInt(financeType.id) !== 6 && parseInt(financeType.id) !== 99) {
                                                            loadSession(formationSelected.id, financeType);
                                                        }
                                                    }}>
                                                    <span>{financeType.label}</span>
                                                </ShadowBoxComponent>
                                            )}
                                        </div>
                                        {(activeFinanceType === '6' || activeFinanceType === '99') ?
                                            <>
                                                <p className={classes.boxFormTitle}>Choisir un type de paiement :</p>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.MuiOutlinedInput}>
                                                        <SelectComponent name={'personalPaymentType'} handler={handler} options={optionPersonalPaymentType} onChange={(val) => setPaymentTypeActive(val)}/>
                                                    </Grid>
                                                </Grid>
                                                <p className={classes.boxFormTitle}>Code promotionnel : </p>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.MuiOutlinedInput}>
                                                        <TextFieldComponent name={'promotionCodeStripe'} handler={handler}/>
                                                        <ButtonComponent style={{ margin: '0px 0px 10px 10px', borderRadius: 20, padding: '2px 15px', background: theme.colorPrimary, fontSize: 10 }} label="Valider le code" disabled={loadingForm} onClick={applyCode} />
                                                    </Grid>
                                                </Grid>

                                                {formationSelected && promo && promo.percent ? 
                                                    <p style={{ margin: '0', fontSize: '16px', color: '#333', padding: '20px 0' }}>
                                                        Prix initial : <span style={{fontSize: '16px', fontWeight: 600 }}>{promo.originalPrice}€</span><br />
                                                        Remise ({promo.percent}%) : <span style={{fontSize: '16px', fontWeight: 600 }}> {promo.discountAmount}€</span><br />
                                                        Total : <span style={{fontSize: '16px', fontWeight: 600 }}>{promo.remisedPrice}€</span>
                                                    </p>
                                                    :
                                                    <p style={{fontSize: 16, margin: 10}}>Prix : <span style={{fontWeight: 600}}>{personalPrice} €</span></p>
                                                }
                                            </>
                                            :
                                            <>
                                                <p className={classes.boxFormTitle}>Choisir une session :</p>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.MuiOutlinedInput}>
                                                        <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true}/>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        <br/>
                                        <div style={{width: '95%', display: 'flex', justifyContent: 'flex-end'}}>
                                            <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'} onClick={() => {
                                                props.history.push(getRoutePathname('learner_catalog'))
                                            }} disabled={loadingForm}/>
                                            <ButtonComponent className={classes.button} color={theme.colorPrimary} label={registerBtnLabel} onClick={register} disabled={loadingForm}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Dialog
                                maxWidth={'xl'}
                                open={registerSuccess.isSuccess}
                                onClose={() => {
                                    setRegisterSuccess({
                                        isSuccess: false,
                                        formationName: '',
                                        duration: '',
                                        session: ''
                                    });
                                    props.history.push(getRoutePathname('learner_catalog'));
                                }}>
                                <DialogContent>
                                    <TitleComponent title={'Félicitations !'}/>

                                    {registerSuccess.financeType === 1 ?
                                        <>
                                            <p>Votre inscription à la formation <span className={classes.boxTitle}>{registerSuccess.formationName}</span> d’une durée de {registerSuccess.duration} heures à bien été prise en compte mais n’est pas encore finalisée.</p>
                                            <p>Afin de la compléter, veuillez suivre les étapes suivantes : </p>
                                            <ol>
                                                <li>Rendez-vous sur: <a target="_blank" href='https://www.agencedpc.fr/professionnel/login'>https://www.agencedpc.fr/professionnel/login</a>, connectez-vous ou créez un compte.</li>
                                                <li>Cliquez sur la rubrique « Inscription »</li>
                                                <li>Dans le menu à gauche, cliquez sur « Rechercher actions »</li>
                                                <li>Dans le champ « Référence de l’actions » copiez/collez le code suivant :
                                                    <span className={classes.boxTitle}>{formationSelected.code}</span>
                                                    <Tooltip title="Copier le code">
                                                        <IconButton aria-label="copy" size="small"
                                                                    onClick={() => {
                                                                        navigator.clipboard.writeText(formationSelected.code);
                                                                        dispatch(
                                                                            SnackbarOpen({
                                                                                text: 'Code copié dans le presse papier',
                                                                                variant: 'success',
                                                                            })
                                                                        );
                                                                    }}>
                                                            <FileCopyIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </li>
                                                <li>Cliquez sur « Détail action de DPC » puis sélectionnez une session et finalisez votre inscription.</li>
                                            </ol>
                                            <p className={classes.boxTitle}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.<br/>Notre équipe reste à votre disposition pour tout renseignement complémentaire. </p>
                                        </>
                                        :
                                        <>
                                            <p>Votre inscription à la formation <span className={classes.boxTitle}>{registerSuccess.formationName}</span> d’une durée de {registerSuccess.duration} heures à bien été prise en compte.</p>
                                            <p>Un courriel vous a été envoyé contenant toutes les informations nécessaires à la finalisation de votre inscription.</p>
                                            <p className={classes.boxTitle}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.</p>
                                        </>
                                    }

                                </DialogContent>
                                <DialogActions>
                                    <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'}
                                                     onClick={() => {
                                                         setRegisterSuccess({
                                                             isSuccess: false,
                                                             formationName: '',
                                                             duration: '',
                                                             session: '',
                                                             financeType: ''
                                                         });
                                                         props.history.push(getRoutePathname('learner_catalog'));
                                                     }}/>
                                    <ButtonComponent className={classes.button} label={'Aller au tableau de bord'} onClick={() => {
                                        props.history.push(getRoutePathname('learner_home'))
                                    }}/>
                                </DialogActions>
                            </Dialog>
                        </>
                }
            </div>
        </>
    );
};

const useStyles = (theme) => makeStyles(({
    boxFormTitle: {
        fontSize: 14,
        fontWeight: 600,
    },
    MuiOutlinedInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px !important',
        },
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'left',
        background: '#4f93a5',
        width: 'max-content',
        padding: '7px 20px'
    },
    boxTitle: {
        fontSize: 22,
        fontWeight: 700,
        textAlign: 'left'
    },
    dialogText: {
        fontSize: 15,
        width: '100%',
        margin: 8
    },
    buttonFinance: {
        margin: '0px 10px',
        padding: 7,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.colorPrimary,
            color: '#FFFFFF'
        }
    },
    buttonFinanceActive: {
        margin: '0px 10px',
        padding: 7,
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF'
    },
    dialogPaper: {
        borderRadius: 0,
        width: '100%',
        height: '100%'
    },
    boxForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: 'solid 1px #cccccc80',
        margin: 'auto',
        paddingBottom: 10,
        fontSize: 13,
    },
    container: {
        width: '100%',
        padding: 10,
        boxSizing: 'border-box',
    },
    circle: {
        width: 180,
        height: 180,
        borderRadius: 10,
        // float: 'left',
        // shapeOutside: 'none',
        marginRight: 15,
        marginBottom: 7
    },
    button: {
        fontSize: 10,
        margin: 3
    }
}));

export default Register;
