import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import {PlayCircleOutline} from "@material-ui/icons";
import {SupervisorAccount} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {getRoutePathname} from "../../../Config/Route";

function FormerVirtualClassroomList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "formationName",
            label: "Formation",
            options: {filter: true, sort: true}
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: {filter: true, sort: true}
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: {filter: true, sort: true}
        },
        {
            name: "meetingAtText",
            label: "Date classe virtuelle",
            options: {filter: true, sort: true}
        },
        {
            name: "meetingId",
            label: "réference Classe virtuelle",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        let currentDate = moment().format('L');
        let meetingDate = moment(row.meetingAt).format('L');
        let color = currentDate === meetingDate ? "green" : null
        const playBtn = (currentDate === meetingDate ?
            <>
                <Tooltip title={'Lancer la classe virtuelle'} placement="left">
                    <IconButton onClick={() => {
                        setTimeout(() => {
                            props.history.push(getRoutePathname('former_virtual_classroom', {id: row.id}));
                        });
                    }}>
                        <PlayCircleOutline style={{fill: color}} />
                    </IconButton>
                </Tooltip>
            </>
            :
            <>
                <Tooltip title={'Indisponible'} placement="left">
                    <IconButton>
                        <PlayCircleOutline style={{fill: color}}/>
                    </IconButton>
                </Tooltip>
            </>)
        return (
            <>
                <Tooltip title={'Liste des participants'} placement="left">
                    <IconButton onClick={() => {
                        if (currentDate === meetingDate) {
                            props.history.push(getRoutePathname('former_virtual_classroom_learners', {id: row.id}));
                        }
                    }}>
                        <SupervisorAccount style={{fill: color}}/>
                    </IconButton>
                </Tooltip>
                {playBtn}
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des classes virtuelles',
            context: 'Formateur',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'former_virtual_classroom_list'}
                title={'Classes Virtuelle'}
                columns={columns}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'former_virtual_classroom_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].sessionStartAtText = moment(data[index].sessionStartAt).format('Do MMM YYYY');
                                data[index].sessionEndAtText = moment(data[index].sessionEndAt).format('Do MMM YYYY');
                                data[index].meetingAtText = moment(data[index].meetingAt).format('Do MMM YYYY');
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />
        </ContentViewComponent>
    );
}

export default FormerVirtualClassroomList;
