import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import { useDispatch } from "react-redux";
import { TableReload } from "../../../Action/TableAction";
import { Checkbox } from '@material-ui/core';
import { inArray } from '../../../Tool/ArrayCommon';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';

let datas = [];
let isChecked = [];
function CatalogList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroup",
            label: "Groupe",
            options: { filter: true, sort: true }
        },
        {
            name: "duration",
            label: "Durée",
            options: { filter: true, sort: true }
        },
        {
            name: "format",
            label: "Format",
            options: { filter: true, sort: true }
        },
        {
            name: "displayCatalog",
            label: "Affichée dans le catalogue",
            options: { filter: false, sort: false }
        },
    ];

    const getAction = (row, checked) => {
        return (
            <Checkbox
                key={row.id + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(row.id, isChecked)) {
                        let index = isChecked.indexOf(row.id);
                        if (index > -1) {
                            isChecked.splice(index, 1);
                        }
                    } else {
                        isChecked.push(row.id);
                    }
                    dispatch(TableReload('marketing_manager_catalog_list'));
                }}
            />
        );
    };

    const getDatas = (resolve) => {
        dispatch(LinearProgressMainLayoutActivate());
        if (datas.length > 0 && isReadyContent) {
            for (let index in datas) {
                datas[index].displayCatalog = getAction(datas[index], inArray(datas[index].id, isChecked));
            }
            resolve([...datas]);
            datas = [...datas];
            dispatch(LinearProgressMainLayoutDeactivate());
            setIsLoading(false);
        } else {
            Api.get({
                route: 'marketing_manager_catalog_list'
            },
                (response) => {
                    if (response.data) {
                        let data = response.data;
                        for (let index in data) {
                            if (data[index].displayCatalog) {
                                isChecked.push(data[index].id);
                            }
                            data[index].displayCatalog = getAction(data[index], data[index].displayCatalog);
                            if (data[index].format) {
                                data[index].format = <span style={{color: '#f49304'}}>Programme intégré</span>;
                            }
                            else {
                                data[index].format = <span style={{color: '#007bff'}}>Formation continue</span>;
                            }
                            data[index].duration = (data[index].duration / 3600) + 'h';
                        }
                        resolve([]);
                        resolve([...data]);
                        datas = [...data];
                        dispatch(LinearProgressMainLayoutDeactivate());
                        setIsLoading(false);
                    }
                    else {
                        resolve([]);
                        datas = [];
                        dispatch(LinearProgressMainLayoutDeactivate());
                    }
                });
            setReadyContent(true);
        }
    };

    const selectedAll = () => {
        for (let index in datas) {
            if (!inArray(datas[index].id, isChecked)) {
                isChecked.push(datas[index].id);
            }
        }
        dispatch(TableReload('marketing_manager_catalog_list'));
    };
    
    const save = () => {
        setIsLoading(true);
        dispatch(LinearProgressMainLayoutActivate());
        let dataPost = [];
        for (let index in datas) {
            dataPost.push({
                formation: datas[index].id,
                displayCatalog : datas[index].displayCatalog.props.checked
            });
        }

        Api.post({
            route: 'marketing_manager_catalog_add',
            data: {dataPost: dataPost}
        },
        (response) => {
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Enregistrement effectué.',
                        variant: 'success',
                    })
                );
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('marketing_manager_catalog_list'));
                setIsLoading(false);
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
                dispatch(LinearProgressMainLayoutDeactivate());
                setIsLoading(false);
            }
        });
     };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestion catalogue',
            context: 'Gestion marketing',
            description: ''
        });

        datas = [];
        isChecked = [];
    }, []);

    return (
        <ContentViewComponent loading={false} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'marketing_manager_catalog_list'}
                title={'Liste des formations'}
                columns={columns}
                promiseData={(resolve) => getDatas(resolve)}
                actionFirst={{
                    label: 'Enregistrer',
                    onClick: save,
                    disabled: isLoading,
                }}
                actionSecond={{
                    label: 'Tous cocher',
                    onClick: selectedAll,
                    disabled: isLoading,
                }}
                actionThird={{
                    label: 'Tous décocher',
                    onClick: () => {
                        isChecked = [];
                        dispatch(TableReload('marketing_manager_catalog_list'));
                    },
                    disabled: isLoading,
                }}
                onBeforeRefresh={() => {
                    datas = [];
                    isChecked = [];
                    setIsLoading(true);
                }}
            />
        </ContentViewComponent>
    );
}

export default CatalogList;
