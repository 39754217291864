
import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {makeStyles, Slide} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import {getRoutePathname} from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Fade from "@material-ui/core/Fade";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import TextFieldComponent from '../../../Component/TextFieldComponent';
import TabsComponent from '../../../Component/TabsComponent';
import ThematicFormation from './ThematicFormation';
import ThematicFormationGroup from './ThematicFormationGroup';
import ThematicLibraryResource from './ThematicLibraryResource';

function Thematic(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [form, setForm] = React.useState({
        label: {
            name: 'label',
            label: 'Libellé',
            textHelper: 'Saisissez le label du thème.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'marketing_manager_thematic_edit',
                    params: {id: props.match.params.id},
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'marketing_manager_thematic_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('marketing_manager_thematic', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Thématique créé avec succès.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer les thématiques',
                context: 'Gestion marketing',
                description: '',
                links: [
                    {path: getRoutePathname('marketing_manager_thematic_list'), label: 'Liste des thématiques'}
                ]
            });
            setIsEdition(true);

            Api.get({
                    route: 'marketing_manager_thematic_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('marketing_manager_thematic_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setBreadcrumbs({
                title: 'Ajouter des thématiques',
                context: 'Gestion marketing',
                description: '',
                links: [
                    {path: getRoutePathname('marketing_manager_thematic_list'), label: 'Liste des thématiques'}
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);
    
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={isEdition ? 'Formulaire d\'édition des thématiques' : 'Formulaire de création des thématiques'}/>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'label'} handler={handler}/>
                            </Grid>
                        </Grid>
                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading}/>
                    </ShadowBoxComponent>
                </div>
            </Fade>
            <br />

            {props.match.params.id && 
            <Slide direction={'up'} in={true} {...{timeout: 500}}>
                <div>
                    <TabsComponent
                        tabDefault={'formation'}
                        tabs={[
                            {id: 'formation', label: 'Formations'},
                            {id: 'formationGroup', label: 'Groupes de formations'},
                            {id: 'libraryResource', label: 'Ressources'},
                        ]}
                    >
                        <div key={'formation'}>
                            <ThematicFormation {...props} />
                        </div>
                        <div key={'formationGroup'}>
                            <ThematicFormationGroup {...props} />
                        </div>
                        <div key={'libraryResource'}>
                            <ThematicLibraryResource {...props} />
                        </div>
                    </TabsComponent>
                </div>
            </Slide>}


        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default Thematic;
