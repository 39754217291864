import React from 'react';
import { theme } from "../../../App";
import RegisterGMS from "./RegisterGMS";
import RegisterSFP from "./RegisterSFP";
import RegisterTimelia from './RegisterTimelia';
import RegisterPatati from './RegisterPatati';

function Register(props) {

    return (
        <>
            {
                theme.id === 1 ?
                    <RegisterSFP {...props} />
                    : theme.id === 2 ?
                        <RegisterGMS {...props} />
                        : theme.id === 4 ?
                            <RegisterTimelia  {...props} />
                            :theme.id === 6 ?
                                <RegisterPatati  {...props} />
                                : <></>
            }
        </>

    )
}



export default Register;
