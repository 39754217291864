import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { Button, Card, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import { getRoutePathname } from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import Fade from "@material-ui/core/Fade";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import TableComponent from '../../../Component/TableComponent';
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { Delete } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

function Role(props) {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const columns = [
        {
            name: "value",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prenom",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du rôle.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        description: {
            name: 'description',
            label: 'Description',
            textHelper: 'Saisissez la description du rôle.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <Tooltip title={'Supprimer'} placement="left">
                <IconButton onClick={() => {
                    setActionRow(row);
                    setShowConfirm(true);
                }}>
                    <Delete />
                </IconButton>
            </Tooltip>
        );
    };
    //--------- Use Effect ---------//

    React.useEffect(handler.start, []);
    React.useEffect(() => {

        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un rôle',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_role_list'), label: 'Liste des Rôles' }
                ]
            });
            setIsEdition(true);

            Api.get({
                route: 'administrator_role_get',
                params: { id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('administrator_role_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un rôle',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_role_list'), label: 'Liste des Roles' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);

    React.useEffect(() => {

        if (props.match.params.id) {

            Api.get({
                route: 'administrator_role_users',
                data: { id: props.match.params.id, withoutRole: true }
            },
                (response) => {
                    if (response && response.data) {
                        setLeft(response.data);
                    }
                });
        }

    }, [props.match.params.id, showDialog]);

    //--------- Function save ---------//
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                Api.post({ route: 'administrator_role_edit', params: { id: props.match.params.id }, data: handler.getData() }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({ route: 'administrator_role_add', data: handler.getData() }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('administrator_role_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Role créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const saveAddUsers = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'administrator_role_users_add',
            data: { roleId: props.match.params.id, users: right },
        }, (response) => {
            if (response.status === 201) {
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('administrator_role_users'));
                setRight([]);
                dispatch(
                    SnackbarOpen({
                        text: 'Utilisateurs ajoutés au role.',
                        variant: 'success',
                    })
                );
                setShowDialog(false);
            }
            else if (response.status === 400) {
                handler.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    //--------- Transfert List ---------//

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    function union(a, b) {
        return [...a, ...not(b, a)];
    }

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'Tous les utilisateurs selectionnés' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selectionné`}
            />
            <Divider />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = value.label;
                    return (
                        <ListItem key={value.label} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Fade in={isReadyContent} {...{ timeout: 500 }}>
                    <div>
                        <ShadowBoxComponent className={classes.shadowBox}>
                            <TitleComponent title={isEdition ? 'Formulaire d\'édition d\'un rôle' : 'Formulaire de création d\'un rôle'} />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent name={'name'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TextFieldComponent name={'description'} handler={handler} />
                                </Grid>
                            </Grid>
                            <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                        </ShadowBoxComponent>
                    </div>
                </Fade>
                <br></br>
                {props.match.params.id && <TableComponent
                    id={'administrator_role_users'}
                    title={`Liste des utilisateurs liés avec : ${props.match.params.id}`}
                    columns={columns}
                    actionFirst={{
                        label: 'Ajouter des utilisateurs',
                        onClick: () => {
                            setShowDialog(true);
                        }
                    }}
                    promiseData={(resolve) => {
                        Api.get({
                            route: 'administrator_role_users',
                            data: { id: props.match.params.id, withoutRole: false }
                        },
                            (response) => {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            });
                    }}
                />}

                <br />

                <SweetAlert
                    show={showConfirm}
                    title={'Supprimer'}
                    text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        dispatch(LinearProgressMainLayoutActivate());
                        setShowConfirm(false);
                        Api.delete({
                            route: 'administrator_role_user_delete',
                            params: { role: props.match.params.id, user: actionRow.value }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());

                                if (response.status === 201) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Liaison supprimée.',
                                            variant: 'success',
                                        })
                                    );
                                    dispatch(TableReload('administrator_role_users'));
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }}
                    onCancel={() => setShowConfirm(false)}
                />
            </ContentViewComponent>

            <Dialog open={showDialog} maxWidth={'xl'} onClose={() => {setShowDialog(false); setRight([]);}}>
                <DialogTitle style={{ fontSize: 15 }}>Ajouter des utilisateurs pour le rôle : {props.match.params.id}</DialogTitle>
                <Divider />
                <DialogContent style={{ minWidth: '30vw' }}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        className={classes.root}
                    >
                        <Grid item>{customList('Liste utilisateurs', left)}</Grid>
                        <Grid item>
                            <Grid container direction="column" alignItems="center">
                                <Tooltip title={'Déplacer vers la droite.'}>
                                    <IconButton
                                        disabled={leftChecked.length === 0}
                                        onClick={handleCheckedRight}
                                        className={classes.buttonList}
                                        size="small"
                                    >
                                        <ArrowForwardIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={'Déplacer vers la gauche.'}>
                                    <IconButton
                                        disabled={rightChecked.length === 0}
                                        onClick={handleCheckedLeft}
                                        className={classes.buttonList}
                                        size="small"
                                    >
                                        <ArrowBackIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item>{customList('Liste choisie', right)}</Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => {setShowDialog(false); setRight([]);}} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => saveAddUsers()} disabled={loading} />
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 50
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: '8px 16px',
    },
    list: {
        width: 250,
        height: 230,
        backgroundColor: '#F2F2F2',
        overflow: 'auto',
    },
    buttonList: {
        margin: '2px 0px',
    },
});

export default Role;
