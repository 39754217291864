import React from 'react';
import ParticlesComponent from '../../Component/ParticlesComponent';
import ShadowBoxComponent from '../../Component/ShadowBoxComponent';
import { Box, Fade, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { theme } from '../../App';
import Api from '../../Api';
import TitleComponent from '../../Component/TitleComponent';
import ButtonComponent from '../../Component/ButtonComponent';
import { getRoutePathname } from '../../Config/Route';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";

function PaymentStripeSuccess(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loadingInfo, setLoadingInfo] = React.useState(true);
    const [info, setInfo] = React.useState([]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);
        }
    }, [screenReducer.screen]);

    React.useEffect(() => {
        setLoadingInfo(true);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        const organismUrl = window.location.origin;

        if (sessionId) {
            Api.get({
                route: 'stripe_session_status',
                data: { organism: organismUrl, sessionId: sessionId }
            },
                (response) => {
                    setInfo(response.data);
                    setLoadingInfo(false);
                });
        }
    }, []);

    return (
        <div className={classes.content}>
            {!isSmallScreen && <ParticlesComponent loading={loading} />}
            <ShadowBoxComponent className={`${classes.shadowBoxBase} ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>

                {
                    loadingInfo ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent}>
                                    <Box className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={70} />
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <>
                            <div className={classes.logoContainer}>
                                {info.organism === 1 && <img src="/asset/images/logo_santeformapro.png" alt="logo" style={{ height: 35 }} />}
                                {info.organism === 2 && <img src="/asset/images/logo_revuedupraticiendpc.png" alt="logo" style={{ height: 35 }} />}
                                {info.organism === 4 && <img src="/asset/images/logo_timelia.png" alt="logo" style={{ height: 35 }} />}
                            </div>
                            <TitleComponent title={info.status === 'complete' ? 'Félicitation pour votre paiement' : 'Une erreur est survenue'} />
                            {
                                info.status === "complete" ? (
                                    <>
                                        <p>
                                            Votre inscription à la formation <i>{info?.formationName}</i> à bien été prise en compte.
                                        </p>
                                        <p>
                                            Votre formation sera disponible dans votre tableau de bord pendant une année à compter d'aujourd'hui. 
                                            De plus la facture de votre paiement sera disponible dans l'onglet "Mes factures" de votre espace client.
                                        </p>
                                        <p>
                                            {theme.name} vous exprime sa gratitude pour votre confiance et vous souhaite une formation enrichissante.
                                            <br />
                                            Notre équipe reste disponible pour toute question supplémentaire.
                                        </p>
                                    </>
                                ) : (
                                    <p>
                                        Nous avons rencontré une erreur lors du traitement de votre paiement pour la formation {info?.formationName}.
                                        <br />
                                        Veuillez réessayer ultérieurement ou contacter notre service d'assistance pour obtenir de l'aide.
                                    </p>
                                )

                            }
                            {
                                Object.keys(authenticationReducer).length === 0 ?
                                    <>
                                        <ButtonComponent className={classes.button} label={'Se connecter à la platforme'} onClick={() => { props.history.push('/authentication/login') }} />
                                    </>
                                    :
                                    <>
                                        <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'} onClick={() => { props.history.push(getRoutePathname('learner_catalog')); }} />
                                        <ButtonComponent className={classes.button} label={'Aller au tableau de bord'} onClick={() => { props.history.push(getRoutePathname('learner_home')) }} />
                                    </>
                            }
                        </>
                }
            </ShadowBoxComponent>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    shadowBoxBase: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        overflow: 'scroll'
    },
    shadowBox: {
        padding: 25,
        height: '55%',
        width: '50%'
    },
    shadowBoxSmall: {
        padding: 5,
        height: '100%',
        width: '100%'
    },
    button: {
        fontSize: 10,
        margin: 3
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 0px 20px 0px'
    },
});

export default PaymentStripeSuccess;