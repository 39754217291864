import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TableComponent from "../../../Component/TableComponent";
import Grid from "@material-ui/core/Grid";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import Api from "../../../Api";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {TableReload} from "../../../Action/TableAction";
import Zoom from "@material-ui/core/Zoom";
import Slide from "@material-ui/core/Slide";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";

function Campaign(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    // Campaign Type.
    const columnsCampaignType = [
        {
            name: "id",
            label: "Référence",
            options: {
                filter: true,
                sort: true,
                sortDirection: 'desc'
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true
            }
        }
    ];
    const [formCampaignType, setFormCampaignType] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Nom du type de la campagne.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        }
    });
    const [loadingButtonCampaignType, setLoadingButtonCampaignType] = React.useState(false);
    const handlerCampaignType = formHandlerInit(formCampaignType, setFormCampaignType);
    const saveCampaignType = () => {
        if (handlerCampaignType.checkError()) {
            console.log('Error');
        } else {
            handlerCampaignType.setFormLoading(true);
            setLoadingButtonCampaignType(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'reporting_marketing_campaign_type_add',
                data: handlerCampaignType.getData()
            }, () => {
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('reporting_marketing_campaign_type_list'));
                handlerCampaignType.setFormLoading(false);
                setLoadingButtonCampaignType(false);
                handlerCampaignType.reset();
            });
        }
    };
    React.useEffect(handlerCampaignType.start, []);

    // Campaign.
    const columnsCampaign = [
        {
            name: "id",
            label: "Référence",
            options: {
                filter: true,
                sort: true,
                sortDirection: 'desc'
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "campaignTypeName",
            label: "Type",
            options: {
                filter: true,
                sort: true
            }
        }
    ];
    const [loadingButtonCampaign, setLoadingButtonCampaign] = React.useState(false);
    const [optionsCampaignType, setOptionsCampaignType] = React.useState([]);
    const [formCampaign, setFormCampaign] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Nom de la campagne.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        type: {
            name: 'campaignTypeId',
            label: 'Type',
            textHelper: 'Type de la campagne.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        }
    });
    const handlerCampaign = formHandlerInit(formCampaign, setFormCampaign);
    const saveCampaign = () => {
        if (handlerCampaign.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerCampaign.setFormLoading(true);
            setLoadingButtonCampaign(true);
            Api.post({
                route: 'reporting_marketing_campaign_add',
                data: handlerCampaign.getData()
            }, () => {
                dispatch(LinearProgressMainLayoutDeactivate());
                dispatch(TableReload('reporting_marketing_campaign_list'));
                handlerCampaign.setFormLoading(false);
                setLoadingButtonCampaign(false);
                handlerCampaign.reset();
            });
        }
    };
    React.useEffect(handlerCampaign.start, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Gestion des campagnes',
            context: 'Reporting Marketing',
            description: 'Gérer les campagnes des formulaires du site internet.'
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Zoom in={isReadyContent} style={{transitionDelay: '0ms'}} {...(isReadyContent ? {timeout: 500} : {})}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                <TitleComponent title={'Ajouter un type de campagne'}/>
                                <TextFieldComponent name={'name'} handler={handlerCampaignType}/>
                                <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={saveCampaignType} disabled={loadingButtonCampaignType}/>
                            </ShadowBoxComponent>
                        </Grid>
                    </Zoom>
                    <br/>
                    <Slide direction="up" in={isReadyContent} style={{transitionDelay: '0ms'}} {...(isReadyContent ? {timeout: 500} : {})}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableComponent
                                id={'reporting_marketing_campaign_type_list'}
                                title={'Types de campagne'}
                                height={'calc(100vh - 500px)'}
                                columns={columnsCampaignType}
                                promiseData={(resolve) => {
                                    Api.get({
                                        route: 'reporting_marketing_campaign_type_list'
                                    }, (response) => {
                                        if (response && response.data) {
                                            let newOptionsCampaignType = [];
                                            for (let index in response.data) {
                                                newOptionsCampaignType.push({value: response.data[index].id, label: response.data[index].name})
                                            }
                                            setOptionsCampaignType(newOptionsCampaignType);
                                            resolve(response.data);
                                        } else {
                                            resolve([]);
                                        }
                                    });
                                }}
                            />
                        </Grid>
                    </Slide>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Zoom in={isReadyContent} style={{transitionDelay: '0ms'}} {...(isReadyContent ? {timeout: 500} : {})}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                <TitleComponent title={'Ajouter une campagne'}/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <TextFieldComponent name={'name'} handler={handlerCampaign}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <SelectComponent name={'type'} handler={handlerCampaign} options={optionsCampaignType}/>
                                    </Grid>
                                </Grid>
                                <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={saveCampaign} disabled={loadingButtonCampaign}/>
                            </ShadowBoxComponent>
                        </Grid>
                    </Zoom>
                    <br/>
                    <Slide direction="up" in={isReadyContent} style={{transitionDelay: '0ms'}} {...(isReadyContent ? {timeout: 500} : {})}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TableComponent
                                id={'reporting_marketing_campaign_list'}
                                title={'Campagnes'}
                                height={'calc(100vh - 500px)'}
                                columns={columnsCampaign}
                                promiseData={(resolve) => {
                                    Api.get({
                                        route: 'reporting_marketing_campaign_list'
                                    }, (response) => {
                                        if (response && response.data) resolve(response.data);
                                        else resolve([]);
                                    });
                                }}
                            />
                        </Grid>
                    </Slide>
                </Grid>
            </Grid>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        height: '100%',
        position: 'relative',
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default Campaign;
