import React from 'react';
import { Box, Button, Chip, Fade, IconButton, LinearProgress as LinearProgressMUI, makeStyles, Tooltip, Typography } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../../Component/ShadowBoxComponent";
import { theme } from "../../../../../App";
import { useSelector } from "react-redux";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import WorkIcon from '@material-ui/icons/Work';

function Card(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [sizeView, setSizeView] = React.useState('');
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSizeView(screenReducer.screen);
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{ timeout: 500 }}>
            <div>
                <ShadowBoxComponent className={classes.shadowBox} style={{ position: " relative", overflow: "initial", border: '1px solid rgba(0,0,0, 15%)', boxShadow: 'initial', opacity: 1 }} >
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            <Box style={{ padding: "20px 15px", textAlign: "left", position: "relative" }}>
                                <Box style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <AccountCircleIcon />
                                    <p style={{ fontWeight: 700, fontSize: 14, margin: "0", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {(props.data.lastName && props.data.firstName) ? props.data.lastName + ' ' + props.data.firstName : '-'}
                                    </p>
                                </Box>
                                <p style={{ margin: 0, fontSize: 11 }} >
                                    <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> Date d'entrer en fonction <b style={{ color: theme.colorPrimary }}>{props.data.startAt}</b>
                                </p>
                                <hr style={{ opacity: .1, borderRadius: 100 }} />
                                <p className={classes.infoStyle} ><WorkIcon /> {props.data.function}</p>
                                <p className={classes.infoStyle} ><MailIcon /> {props.data.email}</p>
                                <p className={classes.infoStyle} ><PhoneIcon /> {props.data.mobile}</p>
                                <p className={classes.infoStyle} ><PhoneIphoneIcon /> {props.data.phone}</p>
                            </Box>
                        </Grid>
                    </Grid>
                </ShadowBoxComponent>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    chipBox: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    infoStyle: {
        margin: 0,
        padding: 2,
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        gap: 5,
        '& svg': {
            fontSize: 14
        }
    },
    boxOrganism: {
        position: "absolute",
        bottom: 4,
        right: 10,
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },
        '& .MuiButtonBase-root': {
            padding: 4,
            color: 'rgb(123, 123, 123)',
            margin: '0 5px',
        },
    },
    popover: {
        '& .MuiPaper-elevation8': {
            boxShadow: '0px 1px 5px rgba(0,0,0,0.2)',
        },
        '& .MuiTypography-root': {
            padding: 7,
        }
    },
    chip: {
        margin: '3px 3px 3px 1px',
        fontSize: 7,
        fontWeight: 700,
        color: '#FFFFFF',
        backgroundColor: '#03a9f4',
        '&.MuiChip-sizeSmall': {
            height: 15
        }
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    cardImagePreload: {
        height: 130,
        width: '100%',
        maxWidth: 130,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    cardImage: {
        height: 130,
        width: '100%',
        maxWidth: 130,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        borderRadius: '4px',
        top: '50%',
        left: '50%',
        zIndex: 0
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '5px 5px',
        borderRadius: '0 0 5px 0',
        margin: 0,
        fontSize: 12,
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardHourIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 12,
        bottom: 0,
        right: 0
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonV1: {
        bottom: 10,
        left: 5,
        position: "absolute",
        textTransform: "initial",
        boxShadow: "none",
        fontSize: 11,
        padding: "3px 11px",
        backgroundColor: "rgb(79, 147, 165) !important",
    },
    buttonV2: {
        bottom: "10px",
        left: "5px",
        position: "absolute",
        textTransform: "initial",
        fontSize: 11,
        padding: "3px 11px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)"
    },
    LockIcon: {
        left: 5,
        color: "rgb(79, 147, 165)",
        border: "solid 1px rgb(79, 147, 165)",
        bottom: 10,
        padding: "3px 11px",
        position: "absolute",
        boxShadow: "none",
        textTransform: "initial",
        backgroundColor: "transparent !important",
        height: 19,
        width: 43,
        borderRadius: 5,
    },
    iconOrganism_500x500: {
        width: 55,
        height: "auto",
    },
    iconOrganism: {
        width: 119,
        height: "auto",
    }
}));

export default Card;
