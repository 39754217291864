import React from 'react';
import { makeStyles } from "@material-ui/core";
import { theme } from "../App";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

function ToggleButtonGroupComponent(props = {
    name: '',
    nullable: true,
    disabled: false,
    onChange: false,
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    },
    options: []
}) {
    const classes = useStyles(theme)();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(typeof data.value !== 'undefined' ? data.value : '');
    const [error, setError] = React.useState(data.error);
    const [alignment, setAlignment] = React.useState('-');
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;

    const onChange = (event, Alignment) => {
        setValue(Alignment);
        setAlignment(Alignment);
        if (props.onChange) {
            props.onChange(Alignment);
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = { ...props.handler.form };
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }
    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);

    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <>
            <h4 className={`
                ${classes.titleToggleGroup}
                ${isFocus && !disabled ? classes.titleToggleGroupFocus : error && !disabled ? classes.titleToggleGroupError : value !== 'undefined' && !(disabled || props.disabled)  ? classes.titleToggleGroupValid : ''}
            `}>{data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}</h4>
            <ToggleButtonGroup
                className={classes.buttonToggleGroup}
                value={alignment}
                onChange={onChange}
                exclusive
            >

                {props.options.map((data, key) =>
                    <ToggleButton style={{
                        color: theme.colorPrimary,
                        borderColor: theme.colorPrimary
                    }}
                        className={classes.buttonToggle}
                        key={key}
                        value={data.value}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        selected={value === data.value}
                        disabled={disabled || props.disabled || data.disabled}
                    >
                        {data.label}
                    </ToggleButton>
                )}

            </ToggleButtonGroup>
        </>
    );
}

const useStyles = (theme) => makeStyles({
    buttonToggleGroup: {
        display: 'initial'
    },
    titleToggleGroup: {
        fontSize: '14px',
        margin: '10px',
    },
    buttonToggle: {
        fontSize: '10px',
        borderRadius: '50px !important',
        margin: '0 20px 5px 0',
        width: '120px',
        padding: '5px',
        "&.Mui-disabled": {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: '#FFF!important',
            borderColor: 'rgba(0, 0, 0, 0.12)!important'
        },
        "&.Mui-selected": {
            backgroundColor: theme.colorPrimary,
            color: '#FFF!important'
        },
        "&:hover": {
            backgroundColor: theme.colorPrimary + '!important',
            color: '#FFF!important'
        }
    },
    titleToggleGroupFocus: {
        color: '#3F51BF !important'
    },
    titleToggleGroupValid: {
        color: '#008000 !important'
    },
    titleToggleGroupError: {
        color: '#982525 !important'
    }
});

export default ToggleButtonGroupComponent;
