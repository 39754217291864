import React from 'react'
import Api from '../../../../Api';
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { Box, Grid, IconButton, Popover, Typography, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { Assignment } from "@material-ui/icons";
import SettingsIcon from '@material-ui/icons/Settings';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import TemplatesComponent from '../../../../Component/DraggableListComponent/TemplatesComponent';
import DraggableListComponent from '../../../../Component/DraggableListComponent/DraggableListComponent';
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { getRoutePathname } from '../../../../Config/Route';

const HandlerDraggableList = () => {
    const [formTemplate1, setFormTemplate1] = React.useState({
        course: {
            id: '',
            name: 'course',
            title: 'Cour',
            textHelper: 'Choisissez un cours.',
            type: 'integer',
            typeInput: 'inputSelect',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
    });
    const [formTemplate2, setFormTemplate2] = React.useState({
        name: {
            name: 'name',
            title: 'Nom surchargé',
            textHelper: 'Saisissez un nom surchargé.',
            type: 'text',
            typeInput: 'inputText',
            defaultValue: ''
        },
        duration: {
            name: 'duration',
            title: 'Durée surchargé',
            textHelper: 'Saisissez une durée surchargé (en seconde).',
            type: 'integer',
            typeInput: 'inputText',
            defaultValue: ''
        }

    });
    const handlerTemplate1 = formHandlerInit(formTemplate1, setFormTemplate1);
    const handlerTemplate2 = formHandlerInit(formTemplate2, setFormTemplate2);

    React.useEffect(() => { handlerTemplate1.start(); handlerTemplate2.start() }, []);

    return { handlerTemplate1, handlerTemplate2 }
}

const Templates = ({ item, dragHandleProps }) => {
    const draggableListHandlers = HandlerDraggableList();
    const [optionCourses, setOptionCourses] = React.useState([]);
    const [load, setLoad] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const selectCourses = () => {
        Api.get({
            route: 'select_courses'
        },
            (response) => {
                setLoad(true);
                if (response && response.data) {
                    setOptionCourses(response.data);
                    setLoad(false)
                }
            });
    }
    const getAction = () => {
        return (
            <IconButton
                disabled={item.course ? false : true}
                className={classes.iconButton}
                onClick={() => { item.props.history.push(getRoutePathname('institution_health_content_manager_course', { id: item.courseId })) }}>
                <Assignment style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Visualiser</span>
            </IconButton>
        )
    }
    return (
        <TemplatesComponent
            item={item}
            dragHandleProps={dragHandleProps}
            handlerTemplate={{
                template1: {
                    handler: draggableListHandlers.handlerTemplate1,
                    getAction: getAction,
                    options: optionCourses,
                    select: () => { selectCourses() },
                    load: load
                },
                template2: {
                    handler: draggableListHandlers.handlerTemplate2,
                },
            }}
            alertMessage={'Êtes-vous sur de vouloir supprimer ce cours ?'}
        />
    )
}

function FormationChapterCourse({ props = {}, setIsOpen, setIsEdition, isEdition, setChapterId, setFormationChapterId, handler, handlerChapter, getChapter = () => { }, formationChapterId, chapterId }) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const draggableListHandlers = HandlerDraggableList();
    const classes = useStyles(screenReducer)();
    const [optionChapters, setOptionChapters] = React.useState([false]);
    const [listCourseDeleted, setListCourseDeleted] = React.useState([]);
    const [listCourse, setListCourse] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [createdChapter, setCreatedChapter] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const viewAction = () => {
        return (
            <div style={{ margin: '0 0 0 10px', transform: 'translate(0%,-30%)', }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <SettingsIcon style={{ color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {getAction()}
                    </Typography>
                </Popover>
            </div>
        )
    }
    const getAction = () => {
        return (
            <>
                <IconButton
                    disabled={handler.form.chapter.chapterId ? false : true}
                    className={classes.iconButton}
                    onClick={() => { props.history.push(getRoutePathname('institution_health_content_manager_chapter', { id: handler.form.chapter.chapterId })); }}>
                    <Assignment style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Visualiser</span>
                </IconButton>
            </>
        )
    }

    const saveChapter = (error) => {
        if (error || handlerChapter.checkError()) {
            console.log('Error');
        } else {
            handlerChapter.setFormLoading(true);
            Api.post({
                route: 'institution_health_content_manager_chapter_add',
                data: handlerChapter.getData()
            }, (response) => {
                if (response.status === 201) {
                    selectChapters()
                    if (response.data) {
                        handler.form.chapter.chapterId = response.data.id;
                        handler.form.chapter.value = response.data.id;
                        setChapterId(response.data.id)
                        save(error, response.data.id)
                    }
                } else if (response.status === 400) {
                    setLoading(false)
                    handlerChapter.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handlerChapter.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const save = (error, newChapterId) => {
        if (error || handler.checkError()) {
            console.log('Error');
        } else {
            setLoading(true)
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_chapter_edit',
                    data: datas,
                    params: { formation: props.match.params.id, id: formationChapterId, chapter: newChapterId ? newChapterId : chapterId }
                }, (response) => {
                    if (response.status === 200) {
                        saveCours(newChapterId ? newChapterId : chapterId);
                    } else if (response.status === 400) {
                        setLoading(false)
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_content_manager_formation_formation_chapter_add',
                    params: { formation: props.match.params.id },
                    data: handler.getData()
                }, (response) => {
                    if (response.status === 200) {
                        setFormationChapterId(response.data.id)
                        saveCours(newChapterId ? newChapterId : chapterId);
                    } else if (response.status === 400) {
                        setLoading(false)
                        handler.setFormLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const saveCours = (id) => {
        const listDeleted = listCourseDeleted.filter((value) => !listCourse.includes(value));
        setLoading(true)
        Api.post({
            route: 'institution_health_content_manager_formation_formation_chapter_course_action',
            data: {
                list: listCourse,
                listDeleted: listDeleted,
            },
            params: { formation: props.match.params.id, chapter: id }
        }, (response) => {
            setLoading(false)
            handler.setFormLoading(false);
            handlerChapter.setFormLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                getCourse(id, false);
                getChapter();
                setCreatedChapter(false)
                setIsEdition(true)
                handlerChapter.reset();
                dispatch(
                    SnackbarOpen({
                        text: 'Le chapitre et les cours en été enregistrée.',
                        variant: 'success',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }
    const getCourse = (id, isCreated) => {
        if (isCreated) {
            setTimeout(() => {
                setLoading(false);
                setListCourse([]);
                setListCourseDeleted([]);
            }, 500);
        } else {
            if (props.match.params.id && id) {
                Api.get({
                    route: 'institution_health_content_manager_formation_formation_chapter_course_list',
                    params: { formation: props.match.params.id, chapter: id }
                }, (response) => {
                    if (response.status === 200 && !isCreated) {
                        let dataCourses = response.data;
                        for (let index in dataCourses) dataCourses[index].props = props;
                        setListCourse(dataCourses);
                        setListCourseDeleted(dataCourses);
                        setLoading(false);
                    } else {
                        setTimeout(() => {
                            setLoading(false);
                            setListCourse([]);
                            setListCourseDeleted([]);
                        }, 500);
                    }
                })
            }
        }
    }

    const selectChapters = () => {
        Api.get({
            route: 'select_chapters'
        },
            (response) => {
                if (response && response.data) {
                    setOptionChapters(response.data);
                }
            });
    }

    React.useEffect(() => {
        setLoading(true);
        getCourse(chapterId, false);
        selectChapters()
    }, []);
    return (
        <>
            <Box style={{ background: '#FFF', width: '90%', margin: 'auto', display: 'grid', gridTemplateRows: screenReducer.screen === 'XL' ? '60px auto' : '80px auto', }}>
                <Box style={{
                    fontSize: 22, color: 'rgb(94, 110, 130)', margin: 0, fontWeight: 'bold',
                    display: screenReducer.screen === 'XL' ? 'flex' : 'bloc',
                    overflow: 'hidden',
                }}>
                    <p style={{ margin: 'auto 0px', height: 'fit-content', padding: screenReducer.screen === 'XL' ? 0 : '10px 0 0 0', }}>{isEdition ? <>Modifier le chapitre</> : <>Ajouter un chapitre</>} | </p>
                    <Box style={{ display: 'flex', margin: '0px', alignItems: 'center' }}>
                        <Box className={classes.select} style={{ borderBottom: createdChapter ? "2px solid rgb(94, 110, 130)" : "", }}>
                            <IconButton className={classes.settings} onClick={() => { setCreatedChapter(true); getCourse(null, true); setLoading(true) }}>
                                <p>Créer un chapitre</p>
                            </IconButton>
                        </Box>
                        <Box className={classes.select} style={{ borderBottom: createdChapter ? "" : "2px solid rgb(94, 110, 130)", }}>
                            <IconButton className={classes.settings} onClick={() => { setCreatedChapter(false); getCourse(chapterId, false); setLoading(true); }}>
                                <p>Chapitre existant</p>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {!createdChapter ?
                                <Box style={{ display: 'flex', alignItems: 'center' }}><SelectAutocompleteComponent name={'chapter'} handler={handler} options={optionChapters}
                                    onChange={(value) => { getCourse(value, false); setChapterId(value); handler.form.chapter.chapterId = value; setLoading(true); }} />{viewAction()}</Box>
                                : <TextFieldComponent name={'chapter'} handler={handlerChapter} />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'duration'} handler={handler} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <DraggableListComponent
                title={'Cours'}
                list={listCourse}
                templates={Templates}
                loading={loading}
                onListChange={(newList) => { setListCourse(newList) }}
                setAction={{
                    setList: (newList) => { setListCourse(newList) },
                    setIsOpen: (isOpen) => { setIsOpen(isOpen) }
                }}
                handlerTemplates={{
                    template1: draggableListHandlers.handlerTemplate1,
                    template2: draggableListHandlers.handlerTemplate2
                }}
                titleButtonAdd={'Ajouter un cours'}
                errorListMessage={'Aucun cours n\'à été ajoutée'}
                buttonAllDelete={{
                    title: 'supprimer les cours',
                    alertMessage: 'Êtes-vous sur de vouloir supprimer tout les cours ?',
                }}
                saveAction={!createdChapter ? save : saveChapter}
                className={classes.styleDrag}
            />
        </>
    )
}

const useStyles = (screenReducer) => makeStyles({
    styleDrag: {
        borderRadius: '0 0 15px 15px',
        overflow: 'hidden',
        height: '95% !important',
        width: '95% !important',
        margin: '20px 0 !important',
        gridTemplateRows: '60px auto 50px !important',
        '& .contnaireBoxDrag-root': {
            padding: '10px 0 0 0',
            '& .BoxDrag-root': {
                backgroundColor: '#EFF2F4 !important',
                border: '1px solid rgba(94, 110, 130, 0.28) !important',
                borderRadius: '15px 15px 0 0',
            },
        },
        '& .BoxDragTitle-root': {
            border: 'none !important',
        },
        '& .popupAllDelete-root': {
            top: 7,
            borderRadius: '15px 15px 0 0'
        },
    },
    select: {
        margin: '0 5px',
        borderRadius: 0,
        overflow: 'hidden',
        height: 35,
    },
    settings: {
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            transitionProperty: 'all',
            transitionDuration: '.3s',
            height: '100%',
            justifyContent: 'initial',
            padding: '0px 10px',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
            color: 'rgb(94, 110, 130)',
        },
    },
    iconButton: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        color: '#5E6E82',
        "& span": {
            justifyContent: 'left'
        }
    },
});

export default FormationChapterCourse;
