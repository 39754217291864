import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import SelectComponent from "../../../Component/SelectComponent";
import EquipmentHistorical from "./EquipmentHistorical";
import CheckboxComponent from '../../../Component/CheckboxComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import { TableReload } from '../../../Action/TableAction';
import SelectAutocompleteComponent from '../../../Component/SelectAutocompleteComponent';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from "@material-ui/core/IconButton";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Tooltip from "@material-ui/core/Tooltip";

function Equipment(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [optionEquipmentType, setOptionEquipmentType] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionEquipmentState, setOptionEquipmentState] = React.useState([]);
    const [optionEquipmentActions, setOptionEquipmentActions] = React.useState([]);
    const [optionEquipmentBrands, setOptionEquipmentBrands] = React.useState([]);
    const [optionModels, setOptionModels] = React.useState([]);
    const [value, setValue] = React.useState(null);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionOwners, setOptionOwners] = React.useState([]);
    const [equipment, setEquipment] = React.useState([])
    const [openAssign, setOpenAssign] = React.useState(false);
    const [isAssign, getIsAssign] = React.useState(false);
    const [isArchive, setIsArchive] = React.useState(false);
    const [openAction, setOpenAction] = React.useState(false);
    const [openRestore, setOpenRestore] = React.useState(false);
    const [typeAction, setTypeAction] = React.useState('');
    const [loadingAction, setLoadingAction] = React.useState(true);
    const [isPc, setIsPc] = React.useState(false);
    const [hasStorage, setHasStorage] = React.useState(false);
    const [organism, setOrganism] = React.useState(1);
    const [brand, setBrand] = React.useState(null);
    const [model, setModel] = React.useState(null);
    const [type, setType] = React.useState(null);

    const [form, setForm] = React.useState({
        doneAt: {
            name: 'doneAt',
            label: 'Date',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Choisissez l\'état de l\'équipement.',
            type: 'text',
            defaultValue: isEdition ? '' : 'NEW',
            options: { validation: ['required'] }
        },
        action: {
            name: 'action',
            label: 'Action',
            textHelper: 'Choisissez le type d\'action.',
            type: 'text',
            defaultValue: isEdition ? '' : 'BUY',
            options: isEdition ? {} : { validation: ['required'] }
        },
        pole: {
            name: 'pole',
            label: 'Pôle',
            textHelper: 'Choisissez le pôle.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Choisissez le type de l\'équipement.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        price: {
            name: 'price',
            label: 'Prix',
            textHelper: 'Saisissez le prix de l\'équipement.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        model: {
            name: 'model',
            label: 'Modèle',
            textHelper: 'Choisissez le modèle.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        serialId: {
            name: 'serialId',
            label: 'Numéro de série',
            textHelper: 'Saisissez le numéro de série',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        cpu: {
            name: 'cpu',
            label: 'CPU',
            textHelper: 'Saisissez le CPU.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        gpu: {
            name: 'gpu',
            label: 'GPU',
            textHelper: 'Saisissez le GPU.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ram: {
            name: 'ram',
            label: 'RAM',
            textHelper: 'Saisissez la RAM.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        storage: {
            name: 'storage',
            label: 'Stockage',
            textHelper: 'Saisissez le stockage.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: 1,
            options: {}
        },
        brand: {
            name: 'brand',
            label: 'Marque',
            textHelper: 'Choisissez la marque.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'it_infrastructure_equipment_edit',
                    data: handler.getData(),
                    params: { id: props.match.params.id }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        dispatch(TableReload('it_infrastructure_equipment_history_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'Équipement modifié.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'it_infrastructure_equipment_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('it_infrastructure_equipment', { id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Équipement enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const [formAssign, setFormAssign] = React.useState({
        owner: {
            name: 'owner',
            label: 'Utilisateur',
            textHelper: 'Choisissez un Utilisateur.',
            type: 'integer',
            defaultValue: '',
            options: isAssign ? { validation: ['required'] } : {}
        },
        doneAt: {
            name: 'doneAt',
            label: 'Date',
            textHelper: 'Choisissez une date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Choisissez l\'état de l\'équipement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    })
    const handlerAssign = formHandlerInit(formAssign, setFormAssign);

    const saveOwner = (type) => {
        if (handlerAssign.checkError()) {
            console.log('Error');
        } else {
            handlerAssign.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'it_infrastructure_equipment_user_action',
                params: { id: props.match.params.id },
                data: handlerAssign.getData()
            }, (response) => {
                handlerAssign.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    getData();
                    setOpenAssign(false);
                    handlerAssign.reset();
                    dispatch(
                        SnackbarOpen({
                            text: type === 'assign' ? 'Attribution ajouter' : 'Attribution retirer.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handlerAssign.setErrorApi(response.error);
                    if (response.error && response.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'error',
                            })
                        );
                    }
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const [formAction, setFormAction] = React.useState({
        action: {
            name: 'action',
            label: 'Action',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Choisissez l\'état de l\'équipement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        doneAt: {
            name: 'doneAt',
            label: 'Date',
            textHelper: 'Choisissez une date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
    })
    const [formRestore, setFormRestore] = React.useState({
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Choisissez l\'état de l\'équipement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        doneAt: {
            name: 'doneAt',
            label: 'Date',
            textHelper: 'Choisissez une date.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
    })
    const handlerAction = formHandlerInit(formAction, setFormAction);
    const handlerRestore = formHandlerInit(formRestore, setFormRestore);

    const saveAction = (action) => {
        if (handlerAction.checkError()) {
            console.log(handlerAction.form.action.error)
            console.log('Error');
        } else {
            handlerAction.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'it_infrastructure_equipment_type_action',
                params: { id: props.match.params.id },
                data: {...handlerAction.getData(), isArchive: action === 'archives' }
            }, (response) => {
                handlerAction.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    getData();
                    setOpenAction(false);
                    handlerAction.reset();
                    dispatch(
                        SnackbarOpen({
                            text: 'Action effectuer.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handlerAction.setErrorApi(response.error);
                    if (response.error && response.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'error',
                            })
                        );
                    }
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const saveRestore = () => {
        if (handlerRestore.checkError()) {
            console.log(handlerRestore.form.action.error)
            console.log('Error');
        } else {
            handlerRestore.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'it_infrastructure_equipment_restore',
                params: { id: props.match.params.id },
                data: handlerRestore.getData()
            }, (response) => {
                handlerRestore.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    getData();
                    setOpenAction(false);
                    setOpenRestore(false);
                    handlerRestore.reset();
                    dispatch(
                        SnackbarOpen({
                            text: 'Équipement restauré.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handlerRestore.setErrorApi(response.error);
                    if (response.error && response.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'error',
                            })
                        );
                    }
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const getData = () => {
        setLoading(true);
        handler.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'it_infrastructure_equipment_get',
            params: { id: props.match.params.id }
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    handler.setDataApi(data);
                    setEquipment(data);
                    setIsArchive(data.isArchive);
                    setType(data.type)
                    setBrand(data.brandId)
                    dispatch(TableReload('it_infrastructure_equipment_history_list'));
                } else if (response.status === 404) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setTimeout(() => setReadyContent(true), 250);
            });
    }

    // ALL
    React.useEffect(handlerAction.start, []);
    React.useEffect(handlerRestore.start, []);
    React.useEffect(handlerAssign.start, []);
    React.useEffect(handler.start, []);

    React.useEffect(() => {
        setReadyContent(false);
        if (props.match.params.id) {
            setIsEdition(true);
            setTypeAction('')
            getData();
        } else {
            setIsEdition(false);
            setTypeAction('add')
            setReadyContent(true);
        }
    }, [props.match.params.id]);

    React.useEffect(() => {
        Api.get({
            route: 'select_equipment_types'
        },
            (response) => {
                if (response && response.data) {
                    setOptionEquipmentType(response.data);
                }
            });

        Api.get({
            route: 'select_equipment_states'
        },
            (response) => {
                if (response && response.data) {
                    setOptionEquipmentState(response.data);
                }
            });

        Api.get({
            route: 'select_users'
        },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionOwners(data);
                }
            });

        Api.get({
                route: 'select_organisms'
            },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionOrganisms(data);
                }
            });

        Api.get({
                route: 'select_equipment_brands'
            },
            (response) => {
                if (response && response.data) {
                    setOptionEquipmentBrands(response.data);
                }
            });
    }, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Équipement',
            context: 'Parc informatique',
            description: '',
            links: [
                { path: getRoutePathname('it_infrastructure_equipment_list'), label: 'Liste des équipements' }
            ],
            html: <p><span style={{ fontWeight: 600 }}>Équipement action :</span> {equipment.actionLabel}</p>
        });
    }, [equipment]);

    React.useEffect(() => {
        if (typeAction) {
            setLoadingAction(true)
            Api.get({
                route: 'select_equipment_actions',
                data: { typeAction: typeAction }
            },
                (response) => {
                    setLoadingAction(false)
                    if (response && response.data) {
                        setOptionEquipmentActions(response.data);
                    }
                });
        } else setOptionEquipmentActions([]);
    }, [typeAction]);

    React.useEffect(() => {
        if (organism) {
            Api.get({
                route: 'select_internal_poles',
                data: { organism: organism }
            },
                (response) => {
                    if (response && response.data) {
                        let data = response.data;
                        setOptionPoles(data);
                    }
                });
        } else setOptionPoles([]);
    }, [organism]);

    React.useEffect(() => {
        const typeId = parseInt(type)
        if ([1,2].includes(typeId)) {
            setIsPc(true)
        } else if ([9,10].includes(typeId)){
            setIsPc(false)
            setHasStorage(true)
        } else {
            setHasStorage(false)
            setIsPc(false)
        }

        Api.get({
                route: 'select_equipment_models',
                data: { brand: brand, type: type }
            },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionModels(data);
                }
            });
    }, [brand, type]);


    React.useEffect(() => {
        if (model){
            Api.get({
                    route: 'it_infrastructure_equipment_model_get',
                    params: { id: model }
                },
                (response) => {
                    if (response && response.data) {
                        let data = response.data;
                        handler.setDataApi(data)
                    }
                });
        }
    }, [model]);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Grid container spacing={2}>
                    <Fade in={true} {...{ timeout: 750 }}>
                        <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 8 : 12} xl={isEdition ? 8 : 12}>
                            <ShadowBoxComponent style={{ minHeight: '100%' }} className={classes.shadowBox}>
                                <TitleComponent title={isEdition ?
                                    <p style={{
                                        margin: 0,
                                        padding: ' 5px 0',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 5,
                                        flexWrap: 'wrap'
                                    }}>
                                        <span
                                            style={{fontWeight: 'bold'}}
                                        >
                                            Formulaire d'édition d'un équipement
                                        </span>
                                        <span
                                            style={{margin: '0 0 0 auto'}}
                                        >
                                            {
                                                equipment.isArchive ?
                                                    <Tooltip
                                                        title={'Restaurer l\'équipement'}
                                                        placement="left"
                                                    >
                                                        <IconButton
                                                            style={{marginRight: '10px'}}
                                                            onClick={() => {
                                                                setOpenRestore(true)
                                                                setOpenAction(true)
                                                            }}
                                                        >
                                                            <SettingsBackupRestoreIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                     : ''
                                            }
                                            <b>Étiquette :</b> {equipment.tag}
                                        </span>
                                    </p>
                                    :
                                    <p style={{margin: 0, fontWeight: 'bold', padding: ' 5px 0'}}>Formulaire de création d'un équipement</p>
                                }/>

                                <Grid container spacing={1}>
                                    {!isEdition &&
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                            <SelectComponent name={'action'} handler={handler} options={optionEquipmentActions} />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={12} lg={!isEdition ? 4 : 6} xl={!isEdition ? 4 : 6}>
                                        <SelectComponent name={'state'} handler={handler} options={optionEquipmentState} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={!isEdition ? 4 : 6} xl={!isEdition ? 4 : 6}>
                                        <DatePickerComponent name={'doneAt'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <SelectComponent name={'organism'} handler={handler} options={optionOrganisms} onChange={(val) => { setOrganism(val) }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <SelectComponent name={'pole'} handler={handler} options={optionPoles} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <SelectComponent name={'type'} handler={handler} options={optionEquipmentType} onChange={(val) => { setType(val) }} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <SelectComponent name={'brand'} handler={handler} options={optionEquipmentBrands} onChange={(val) => { setBrand(val) }}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <SelectComponent name={'model'} handler={handler} options={optionModels} onChange={(val) => { setModel(val) }}/>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextFieldComponent name={'price'} handler={handler} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <TextFieldComponent name={'serialId'} handler={handler} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                                    </Grid>
                                    {
                                        isPc &&
                                        <>
                                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <TextFieldComponent name={'cpu'} handler={handler} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <TextFieldComponent name={'ram'} handler={handler} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                                <TextFieldComponent name={'gpu'} handler={handler} />
                                            </Grid>
                                        </>
                                    }
                                    {
                                        (isPc || hasStorage) &&
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <TextFieldComponent name={'storage'} handler={handler} />
                                        </Grid>
                                    }
                                </Grid>

                                <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', padding: 10 }}>
                                    {isEdition &&
                                        <>
                                            <ButtonComponent color={'rgb(220, 53, 69)'} label={'Archiver l\'équipement'} onClick={() => { setOpenAction(true); setTypeAction('archives') }} disabled={loading || isArchive} />
                                            <ButtonComponent color={'rgb(53, 162, 235)'} label={'Entretien de l\'équipement'} onClick={() => { setOpenAction(true); setTypeAction('maintenance') }} disabled={loading || isArchive} />
                                        </>
                                    }
                                    <ButtonComponent label={'Enregistrer'} style={{ margin: '0 0 0 auto' }} onClick={save} disabled={loading} />
                                </div>

                            </ShadowBoxComponent>
                        </Grid>
                    </Fade>
                    {
                        isEdition &&
                        <Slide direction={'left'} in={true} {...{ timeout: 750 }}>
                            <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 4 : 12} xl={isEdition ? 4 : 12}>
                                <ShadowBoxComponent style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }} >
                                    <TitleComponent title={<Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ margin: 0, fontWeight: 'bold' }}>Attribution</p>
                                        <ButtonComponent
                                            style={{ margin: '0 0 0 auto' }}
                                            label={!equipment.owner ? 'Attribuer l\'équipement' : 'Retirer l\'attribution'}
                                            onClick={() => { setOpenAssign(true); equipment.owner ? getIsAssign(true) : getIsAssign(false) }}
                                            disabled={loading || isArchive}
                                        />
                                    </Box>} />
                                    {equipment.owner ?
                                        <>
                                            <Box style={{ textAlign: 'center', background: '#5E6E82', borderRadius: 10, width: '90%', margin: '0 auto', padding: ' 25px 0' }}>
                                                <AccountCircleIcon style={{ fontSize: 114, fill: '#FFF' }} />
                                                <p style={{ margin: 0, fontSize: 20, fontWeight: 'bold', color: '#FFF', }}>{(equipment.owner && equipment.owner.name) ? equipment.owner.name : 'Aucun nom'}</p>
                                                <p style={{ margin: 0, fontSize: 17, fontWeight: 300, color: '#FFF', }}>{(equipment.owner && equipment.owner.jobTitle) ? equipment.owner.jobTitle : 'Aucun poste'}</p>
                                                <br />
                                                <ButtonComponent
                                                    label={'Plus d\'info'}
                                                    onClick={() => {
                                                        props.history.push(getRoutePathname('administrator_user', { id: equipment.owner.id }));
                                                    }}
                                                    style={{ color: '#5E6E82' }}
                                                    color={'#FFF'}
                                                    disabled={loading}
                                                />
                                            </Box>
                                            <Box style={{ margin: 'auto', width: '90%', }}>
                                                <Box style={{ display: 'flex', alignItems: 'center', gap: 10, margin: 10 }}>
                                                    <PhoneAndroidIcon />
                                                    <p style={{ margin: 0 }}>
                                                        <span style={{ fontWeight: 'bold' }}>{equipment.owner.phone ? equipment.owner.phone : 'Aucun téléphone'}</span><br />
                                                        <span style={{ fontSize: 13, opacity: 0.6 }}>Téléphone</span>
                                                    </p>
                                                </Box>
                                                <Box style={{ display: 'flex', alignItems: 'center', gap: 10, margin: 10 }}>
                                                    <MailIcon />
                                                    <p style={{ margin: 0 }}>
                                                        <span style={{ fontWeight: 'bold' }}>{equipment.owner.mail ? equipment.owner.mail : 'Aucun mail'}</span><br />
                                                        <span style={{ fontSize: 13, opacity: 0.6 }}>Adresse mail</span>
                                                    </p>
                                                </Box>
                                            </Box>
                                        </> : <p className={classes.error}>Aucune attribution</p>
                                    }
                                </ShadowBoxComponent>
                            </Grid>
                        </Slide >
                    }
                </Grid >

                <br />

                {
                    isEdition &&
                    <>
                        <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                            <div>
                                <TabsComponent
                                    tabDefault={'historical'}
                                    tabs={[
                                        { id: 'historical', label: 'historique' },
                                    ]}
                                >
                                    <div key={'historical'}>
                                        <EquipmentHistorical {...props} loading={getData} />
                                    </div>
                                </TabsComponent>
                            </div>
                        </Slide>
                        <br />
                    </>
                }

                <Dialog open={openAction} maxWidth={'xl'} onClose={() => { setOpenAction(false); setOpenRestore(false); handlerAction.reset();handlerRestore.reset(); setValue(null) }}>
                    {
                        !openRestore ?
                            <DialogTitle style={{ fontSize: 15, color: '#5E6E82', padding: '16px 24px 0 24px' }}>{typeAction === 'archives' ? <>Archiver l'équipement</> : <>Entretien de l'équipement</>}</DialogTitle> :
                            <DialogTitle style={{ fontSize: 15, color: '#5E6E82', padding: '16px 24px 0 24px' }}>Restaurer l'équipement </DialogTitle>
                    }

                    <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                        {
                            !openRestore &&
                            <Box className={classes.styleMenu}>
                                {loadingAction ? '-' : optionEquipmentActions.map((data, index) => {
                                    return (
                                        <Box
                                            className={'button-root'}
                                            key={index}
                                            style={{ background: data.value === value ? 'rgba(94, 110, 130, 0.4)' : 'rgba(94, 110, 130, 0.15)', }}
                                            onClick={() => { setValue(data.value); handlerAction.setValue('action', data.value) }} >
                                            <p>{data.label}</p>
                                        </Box>
                                    )
                                })}
                            </Box>
                        }
                        <p style={{ color: '#982525', margin: 'auto', textAlign: 'center', padding: '0 0 10px', fontSize: 10 }}>{handlerAction.form.action.error ? handlerAction.form.action.error : ''}</p>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <SelectComponent name={'state'} handler={openRestore ? handlerRestore : handlerAction} options={optionEquipmentState} disabled={openRestore ? false : loadingAction} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <DatePickerComponent name={'doneAt'} handler={openRestore ? handlerRestore : handlerAction} disabled={openRestore ? false : loadingAction} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'comment'} handler={openRestore ? handlerRestore : handlerAction} multiline={true} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handlerAction.reset(); handlerRestore.reset(); setOpenAction(false); setOpenRestore(false); setValue(null) }} disabled={loading} />
                        <ButtonComponent label={'Enregistrer'} onClick={() => { openRestore ? saveRestore() : saveAction(typeAction) }} disabled={loading} />
                    </DialogActions>
                </Dialog>

                <Dialog open={openAssign} maxWidth={'xl'} onClose={() => { setOpenAssign(false); handlerAssign.reset() }}>
                    <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>{!isAssign ? <>Attribuer à</> : <>Retirer l'attribution</>}</DialogTitle>
                    <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                        <Grid container spacing={1}>
                            {!isAssign &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectAutocompleteComponent name={'owner'} handler={handlerAssign} options={optionOwners} />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <SelectComponent name={'state'} handler={handlerAssign} options={optionEquipmentState} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <DatePickerComponent name={'doneAt'} handler={handlerAssign} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'comment'} handler={handlerAssign} multiline={true} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handlerAssign.reset(); setOpenAssign(false) }} disabled={loading} />
                        <ButtonComponent label={'Enregistrer'} onClick={() => saveOwner('assign')} disabled={loading} />
                    </DialogActions>
                </Dialog>
            </ContentViewComponent >
        </Box>
    );
}

const useStyles = makeStyles({
    styleContentView: {
        '& .ContentView-root': {
            '& .Breadcrumbs-root:nth-of-type(1)': {
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        },
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 12,
        height: 'calc(100% - 24px)',
        width: 'calc(100% - 24px)',
        minHeight: 250,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1
    },
    image: {
        height: '100%',
        width: '100%',
        minHeight: 250,
        maxHeight: 450,
    },
    styleMenu: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '10px auto',
        overflow: 'hidden',
        borderRadius: 5,
        width: 'fit-content',
        '& .button-root': {
            color: 'rgb(94, 110, 130)',
            minWidth: 50,
            cursor: 'pointer',
            textAlign: 'center',
            padding: '10px 20px',
            transition: 'all .4s',
            '&:hover': {
                background: 'rgba(94, 110, 130, 0.4) !important',
            },
            '& p': {
                margin: 0
            }
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%)',
        fontSize: 20,
        margin: 0,
        opacity: 0.5,
        fontWeight: 'bold'
    },
});

export default Equipment;



