import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { TableReload } from "../../../Action/TableAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Check, Clear } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";
import CheckboxComponent from '../../../Component/CheckboxComponent';

export default function PERSONAL(props = {}) {
    const dispatch = useDispatch();

    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState([]);
    const [formPERSONAL, setFormPERSONAL] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Saisissez une année.',
            type: 'integer',
            defaultValue: '',
            options: {
                validation: ['required'],
                max: 2100,
                min: 1999
            }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez un organisme.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Saisissez un type.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        priceHT: {
            name: 'priceHT',
            label: 'prix HT',
            textHelper: 'Saisissez un prix.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        priceTTC: {
            name: 'priceTTC',
            label: 'prix TTC',
            textHelper: 'Saisissez un prix.',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        isStripeActive: {
            name: 'isStripeActive',
            label: 'Validez la disponibilité sur Stripe.',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
    });
    const [dialogPERSONAL, setDialogPERSONAL] = React.useState(false);
    const [editId, setEditId] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [paymentTypeSelected, setPaymentTypeSelected] = React.useState();
    const handlerPERSONAL = formHandlerInit(formPERSONAL, setFormPERSONAL);
    React.useEffect(handlerPERSONAL.start, []);

    const savePERSONAL = () => {
        if (handlerPERSONAL.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerPERSONAL.setFormLoading(true);
            setLoading(true);
            let data = handlerPERSONAL.getData();
            if (editId) {
                data.formationPERSONALId = editId
            }

            Api.post({
                route: 'content_manager_formation_personal',
                data: data,
                params: { id: props.match.params.id }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setDialogPERSONAL(false);
                    handlerPERSONAL.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200 || response.status === 201) {
                        dispatch(TableReload('content_manager_formation_personal_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.status === 200 ? 'Code personnel modifié.' : 'Code personnel crée.',
                                variant: 'success',
                            })
                        );
                        handlerPERSONAL.reset();
                    } else if (response.status === 400) {
                        handlerPERSONAL.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        handlerPERSONAL.setValue('year', row.year);
                        handlerPERSONAL.setValue('organism', row.organism);
                        handlerPERSONAL.setValue('personalPaymentType', row.personalPaymentType);
                        handlerPERSONAL.setValue('priceHT', row.priceHT);
                        handlerPERSONAL.setValue('priceTTC', row.priceTTC);
                        handlerPERSONAL.setValue('isStripeActive', row.isStripeActive);
                        setPaymentTypeSelected(row.personalPaymentType);
                        setEditId(row.id);
                        setDialogPERSONAL(true);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const columnsPERSONALs = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'year',
            label: 'Année',
            options: { filter: true, sort: true }
        },
        {
            name: 'organismName',
            label: 'Organisme',
            options: { filter: true, sort: true }
        },
        {
            name: 'personalPaymentTypeName',
            label: 'Type de paiement',
            options: { filter: true, sort: true }
        },
        {
            name: 'isStripeActiveSpan',
            label: 'Activité Stripe',
            options: { filter: true, sort: true }
        },
        {
            name: 'priceHT',
            label: 'Prix HT',
            options: { filter: true, sort: true }
        },
        {
            name: 'priceTTC',
            label: 'Prix TTC',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: ' ',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    React.useEffect(() => {
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });

        Api.get({
            route: 'select_personal_payment_types'
        },
            (response) => {
                if (response && response.data) {
                    setOptionPersonalPaymentType(response.data);
                }
            });
    }, [])

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'id', direction: 'desc' }}
                id={'content_manager_formation_personal_list'}
                title={'PERSONNEL'}
                columns={columnsPERSONALs}
                actionFirst={{
                    label: 'Ajouter un code PERSONNEL',
                    onClick: () => {
                        handlerPERSONAL.reset();
                        setDialogPERSONAL(true);
                        setPaymentTypeSelected();
                        setEditId(null);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_formation_personal_list',
                            params: { id: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).fromNow();
                                    data[index].isStripeActiveSpan = data[index].isStripeActive ? <span style={{color: 'green'}}><Check/></span> : <span style={{color: 'red'}}><Clear/></span>;
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialogPERSONAL} maxWidth={'xl'} onClose={() => setDialogPERSONAL(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{editId ? 'Éditer' : 'Ajouter'} un code PERSONNEL</DialogTitle>
                <DialogContent style={{ minWidth: '30vw' }}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldComponent name={'year'} handler={handlerPERSONAL} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SelectComponent name={'organism'} handler={handlerPERSONAL} options={optionOrganism} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <SelectComponent name={'personalPaymentType'} handler={handlerPERSONAL} options={optionPersonalPaymentType} onChange={(val) => setPaymentTypeSelected(val)} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'priceHT'} handler={handlerPERSONAL} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'priceTTC'} handler={handlerPERSONAL} />
                        </Grid>

                        {
                            (parseInt(paymentTypeSelected) === 1) &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CheckboxComponent name={'isStripeActive'} handler={handlerPERSONAL} direction={'left'} />
                            </Grid>
                        }
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogPERSONAL(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={() => savePERSONAL()} disabled={loading} />
                </DialogActions>
            </Dialog>
        </>
    )
}
