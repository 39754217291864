import React from 'react';
import {makeStyles} from "@material-ui/core";
import Particles from "react-tsparticles";
import {theme} from "../App";

function ParticlesComponent(props = {loading: false}) {
    const classes = useStyles();

    return (
        <div className={classes.content}>
            <Particles
                className={classes.particles}
                options={{
                    fpsLimit: 60,
                    interactivity: {
                        detectsOn: "canvas",
                        events: {
                            onClick: {
                                enable: true,
                                mode: "push",
                            },
                            onHover: {
                                enable: true,
                                mode: "grab",
                            }
                        },
                        modes: {
                            bubble: {
                                distance: 400,
                                duration: 0.4,
                                opacity: 0.8,
                                size: 40,
                            },
                            push: {
                                quantity: 4,
                            },
                            grab: {
                                distance: 200,
                                duration: 0.4,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: theme.colorPrimary,
                        },
                        links: {
                            color: theme.colorPrimary,
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: props.loading ? 'right' : 'none',
                            enable: true,
                            outMode: props.loading ? 'out' : 'bounce',
                            random: false,
                            speed: props.loading ? 12 : 2,
                            straight: false
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 800,
                            },
                            value: 100,
                            limit: 125,
                        },
                        opacity: {
                            value: 0.5,
                        },
                        shape: {
                            type: "circle",
                        },
                        size: {
                            random: true,
                            value: 3,
                        },
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    particles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        border: 0,
        outline: 0,
        fontSize: '100%',
        verticalAlign: 'baseline',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%'
    }
});

export default ParticlesComponent;
