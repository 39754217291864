import React from 'react';

const LazyBackground = (props) => {

    const [source, setSource] = React.useState("/asset/images/preload_image.png");
    const src = props.src;

    React.useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setSource(src);
    }, [src]);

    return (
        <div className={props.classe} style={{ backgroundImage: `url(${source})`, backgroundSize: '100% 100%' }}></div>
    );
};

export default LazyBackground;
