import React, {Fragment} from 'react';
import {makeStyles} from "@material-ui/core";
import List from "@material-ui/core/List";
import {getMenu} from "../../../Config/Menu";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import {getRouteByPathname} from "../../../Config/Route";
import Popover from "@material-ui/core/Popover";
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import {Drawer as DrawerMUI} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {DrawerMainLayoutClose} from "../../../Action/DrawerMainLayoutAction";
import CloseIcon from '@material-ui/icons/Close';
import {theme} from "../../../App";

export default function Drawer(props = {route: []}) {
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const drawerMainLayoutReducer = useSelector(state => state.DrawerMainLayoutReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const [expanded, setExpanded] = React.useState('');
    const [expandedPopover, setExpandedPopover] = React.useState({});
    const [route, setRoute] = React.useState({});
    const [showDrawerFull, setShowDrawerFull] = React.useState(true);

    const onClickTooltip = (id, event) => {
        if (drawerMainLayoutReducer.isOpen) setExpanded(id === expanded ? '' : id);
        else setExpandedPopover((expandedPopover.id === id) ? {} : {id: id, anchorEl: event.currentTarget});
    };
    const list = () => {
        return (
            <List style={{color: '#FFF', padding: 0}}>
                {
                    getMenu().map((element, index) => {
                        if (element.children) {
                            return (
                                <div key={index} className={`${drawerMainLayoutReducer.isOpen && expanded.startsWith(element.id) ? classes.drawerListOpen : ''}`} style={showDrawerFull ? {} : {borderRight: 0}}>
                                    <Tooltip title={!drawerMainLayoutReducer.isOpen ? element.label : ''} placement="right" key={element.id} style={(route.id && route.id.startsWith(element.id)) ? {background: 'rgb(236 236 236)'} : {}}>
                                        <ListItem button key={element.id} onClick={(event) => onClickTooltip(element.id, event)} className={classes.drawerListItem}>
                                            <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                                            <ListItemText className={classes.drawerListText} primary={element.label}/>
                                        </ListItem>
                                    </Tooltip>
                                    {
                                        drawerMainLayoutReducer.isOpen ?
                                            <Collapse in={expanded.startsWith(element.id)} timeout="auto" unmountOnExit style={{zIndex: 0}}>
                                                {
                                                    element.children.map((children) => {
                                                        return (
                                                            <ListItem className={classes.drawerListItem}
                                                                      button
                                                                      key={children.id}
                                                                      selected={(children.id === route.id)}
                                                                      style={(children.id === route.id) ? {background: 'rgb(236 236 236)'} : {}}
                                                                      onClick={() => props.history.push(children.path)}
                                                            >
                                                                <ListItemIcon className={classes.drawerListIcon}/>
                                                                <ListItemText className={classes.drawerListText} primary={children.label}/>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </Collapse>
                                            :
                                            <Popover
                                                id={element.id}
                                                open={expandedPopover.id === element.id}
                                                anchorEl={expandedPopover.anchorEl}
                                                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                                                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                                                onClose={() => setExpandedPopover({})}
                                                className={classes.drawerPopover}
                                            >
                                                {
                                                    element.children.map((children) => {
                                                        return (
                                                            <ListItem className={classes.drawerListItem}
                                                                      button
                                                                      key={children.id}
                                                                      selected={(children.id === route.id)}
                                                                      style={(children.id === route.id) ? {background: 'rgb(236 236 236)'} : {}}
                                                                      onClick={() => props.history.push(children.path)}
                                                            >
                                                                <ListItemText className={classes.drawerListText} primary={children.label}/>
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </Popover>
                                    }
                                </div>
                            );
                        }
                        else {
                            return (
                                <div key={index}>
                                    <Tooltip title={!drawerMainLayoutReducer.isOpen ? element.label : ''} placement="right" key={element.id}>
                                        <ListItem className={classes.drawerListItem}
                                                  button
                                                  key={element.id}
                                                  selected={(element.id === route.id)}
                                                  style={(element.id === route) ? {background: 'rgb(236 236 236)'} : {}}
                                                  onClick={() => props.history.push(element.path)}
                                        >
                                            <ListItemIcon className={classes.drawerListIcon}>{element.icon}</ListItemIcon>
                                            <ListItemText className={classes.drawerListText} primary={element.label}/>
                                        </ListItem>
                                    </Tooltip>
                                </div>
                            );
                        }
                    })
                }
            </List>
        );
    }

    React.useEffect(() => {
        if (!showDrawerFull) dispatch(DrawerMainLayoutClose());
        let currentRoute = getRouteByPathname(props.location.pathname);
        setRoute(currentRoute);
        setExpanded(currentRoute.id);
        setExpandedPopover({});
    }, [props.location.pathname]);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isDrawerFull = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isDrawerFull !== showDrawerFull) {
                if (!isDrawerFull) dispatch(DrawerMainLayoutClose());
                setShowDrawerFull(isDrawerFull);
            }
        }
    }, [screenReducer.screen]);

    return showDrawerFull ? (
        <DrawerMUI
            variant="permanent"
            className={`${classes.drawer} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpen : classes.drawerClose}`}
            open={drawerMainLayoutReducer.isOpen}
        >
            <div className={`${classes.drawerHeader} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpenHeader : classes.drawerCloseHeader}`}>
                <img alt="avatar" src={authenticationReducer.gender ? '/asset/images/avatar_man.png' : '/asset/images/avatar_woman.png'} className={classes.drawerAvatar}/>
                <p className={`${classes.drawerText} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpenText : classes.drawerCloseText}`}>{authenticationReducer.firstname} {authenticationReducer.lastname}</p>
            </div>
            {list()}
            <div className={`${classes.drawerNetwork} ${drawerMainLayoutReducer.isOpen ? classes.drawerOpenNetwork : classes.drawerCloseNetwork}`}>
                <UseAnimations animation={facebook} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.facebook, "_blank")}/>
                <UseAnimations animation={twitter} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.twitter, "_blank")}/>
                <UseAnimations animation={linkedin} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.linkedin, "_blank")}/>
            </div>
        </DrawerMUI>
    ) : (
        <Fragment>
            <DrawerMUI anchor={'top'} open={drawerMainLayoutReducer.isOpen} onClose={() => dispatch(DrawerMainLayoutClose())}>
                <CloseIcon className={classes.drawerIconClose} onClick={() => dispatch(DrawerMainLayoutClose())}/>
                <div className={classes.drawerNetworkSmall}>
                    <UseAnimations animation={facebook} size={18} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.facebook, "_blank")}/>
                    <UseAnimations animation={twitter} size={18} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.twitter, "_blank")}/>
                    <UseAnimations animation={linkedin} size={18} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.linkedin, "_blank")}/>
                </div>
                <div className={`${classes.drawerHeader}`}>
                    <img alt="avatar" src={authenticationReducer.gender ? '/asset/images/avatar_man.png' : '/asset/images/avatar_woman.png'} className={classes.drawerAvatar}/>
                    <p className={`${classes.drawerText}`}>{authenticationReducer.firstname} {authenticationReducer.lastname}</p>
                </div>
                {list()}
            </DrawerMUI>
        </Fragment>
    );
}


const drawerOpenWidth = 260;
const drawerCloseWidth = 68;

const useStyles = (theme) => makeStyles(style => ({
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        paddingTop: 58,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    drawerHeader: {
        transition: 'all 200ms',
        textAlign: 'center'
    },
    drawerOpenHeader: {
        width: drawerOpenWidth,
    },
    drawerCloseHeader: {
        width: drawerCloseWidth,
    },
    drawerAvatar: {
        width: 45,
        marginTop: 25
    },
    drawerText: {
        transition: 'all 200ms',
        textAlign: 'center',
        marginTop: 0,
        fontWeight: 500,
        color: '#5E6E82',
        textTransform: 'capitalize',
        fontSize: 14,
        paddingBottom: 10
    },
    drawerOpenText: {
        opacity: 1
    },
    drawerCloseText: {
        opacity: 0
    },
    drawerNetwork: {
        display: 'flex',
        margin: 'auto 0 0 0',
        justifyContent: 'center',
        width: '100%'
    },
    drawerNetworkSmall: {
        width: '100%',
        top: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'flex-end'
    },
    drawerOpenNetwork: {
        display: 'flex'
    },
    drawerCloseNetwork: {
        display: 'none'
    },
    drawerListItem: {
        paddingTop: 3,
        paddingBottom: 3
    },
    drawerListIcon: {
        paddingLeft: 10,
        color: '#5E6E82',
        minWidth: 42,
        '& .MuiSvgIcon-root': {
            fontSize: 18
        }
    },
    drawerListText: {
        cursor: 'pointer',
        '& .MuiListItemText-primary': {
            fontSize: 12,
            color: '#5E6E82'
        }
    },
    drawerPopover: {
        left: '68px !important',
        '& .MuiPopover-paper': {
            borderRadius: '0px !important',
            left: '1px !important',
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 10px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
        },
        '& > div:nth-child(1)': {
            left: '68px !important',
        }
    },
    drawerListOpen: {
        zIndex: 1,
        backgroundColor: '#FFFFFF',
        boxShadow: "0 0 17px 0 #00000050",
        borderRight: '2px solid ' + theme.colorPrimary,
        boxSizing: "border-box",
        position: 'relative'
    },
    drawerIconClose: {
        position: 'absolute',
        padding: 12,
        fontSize: 18,
        zIndex: 2,
        cursor: 'pointer'
    }
}));
