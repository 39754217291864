import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box, makeStyles } from "@material-ui/core";
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ButtonComponent from "../../../../Component/ButtonComponent";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';


const CardRelaunchComponent = (props) => {
    const classes = useStyles();
    return (
        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, color: '#5E6E82' }}>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 20, display: 'flex', alignItems: 'center', gap: 2 }}><PlaylistAddCheckIcon style={{ width: 25, height: 25 }} />Suivi des inscriptions</h1>
                <p style={{ margin: 0, fontSize: 15 }}>Liste des inscriptions.</p>
            </Box>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 20, display: 'flex', alignItems: 'center', gap: 5, fontWeight: 'lighter' }}>Nombre d'inscriptions cette année :</h1>
            </Box>
            <Box style={{ height: '100%', display: 'flex', }}>
                <Box style={{ height: '100%', width: '100%', textAlign: 'center', position: 'relative' }}>
                    <RecentActorsIcon style={{ height: '100%', width: '100%', textAlign: 'center', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} />
                </Box>
                <Box style={{ height: '100%', width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', }}>
                    <p style={{ margin: '5px auto', display: 'block', fontSize: 15 }}>
                        <span style={{ fontSize: 45 }}>{0}</span>
                    </p>
                    {props.button.isAccess &&
                        <ButtonComponent
                            style={props.button.style}
                            color={props.button.background} label={props.button.title}
                            onClick={() => { props.button.onClick() }}
                        />
                    }
                </Box>
            </Box>
        </ShadowBoxComponent>
    )
}

const useStyles = makeStyles({})

export default CardRelaunchComponent;