import React from "react";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { dispatch } from "../../../App";
import WorkOffIcon from '@material-ui/icons/WorkOff';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Api from "../../../Api";
import activity from "react-useanimations/lib/activity";
import { Box, Dialog, DialogActions, DialogContent, Fade, Grid, makeStyles } from "@material-ui/core";
import SelectComponent from "../../../Component/SelectComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import moment from "moment";
import UseAnimations from "react-useanimations";

const ManagerOffUser = (props) => {
    const classes = useStyles();
    const [offuserList, setOffuserList] = React.useState([]);
    const [name, setName] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    // Form Off user
    const [form, setForm] = React.useState({
        userId: {
            name: 'userId',
            label: 'Webcoach',
            textHelper: 'Sélectionnez un webcoach',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        startAt: {
            name: 'startAt',
            label: 'Début',
            textHelper: 'Sélectionnez une date de début.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
        endAt: {
            name: 'endAt',
            label: 'Fin',
            textHelper: 'Sélectionnez une date de fin.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required', 'date'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError() || ((handler.getData().startAt >= handler.getData().endAt) && handler.getData().endAt)) {
            if (handler.getData().startAt >= handler.getData().endAt) handler.setError('endAt', 'La date de fin doit être supérieure à la date de début')
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'webcoach_offuser_add',
                data: handler.getData()
            }, (response) => {
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    getList()
                    dispatch(
                        SnackbarOpen({
                            text: 'Les dates ont été ajoutées.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const getList = () => {
        if ((handler.getData().startAt > handler.getData().endAt) && handler.getData().endAt) {
            handler.setError('endAt', 'La date de fin doit pas être inférieur à la date de début')
        } else {
            handler.setError('endAt', '');
            handler.setValue('startAt', '');
            handler.setValue('endAt', '');
            if (props.userId) {
                setIsLoading(true)
                dispatch(LinearProgressMainLayoutActivate());
                Api.get({
                    route: 'webcoach_offuser_get',
                    params: { user: props.userId }
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        setOffuserList(data);
                        setIsLoading(false);
                        props.loadingData()
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error,
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }
    React.useEffect(() => {
        if (props.userId) {
            handler.setValue('userId', props.userId);
            for (let index in props.options) {
                if (parseInt(props.userId) === parseInt(props.options[index].value)) {
                    setName(props.options[index].label);
                }
            }
            getList();
        } else { setOffuserList([]); setIsLoading(false); }
    }, [props.userId, props.isAllUser]);

    return (
        <Dialog open={props.isOpen} className={classes.dialogStyle} onClose={() => { props.setIsOpen(false); props.setUserId(null); handler.reset() }}>
            <DialogContent style={{ padding: 30, paddingBottom: 10 }}>
                <Box style={{ width: '100%', margin: 0, gap: 35, display: 'flex' }}>
                    <Box style={{ width: '50%' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <h1 style={{ fontSize: 20, color: '#5E6E82', display: 'flex', alignItems: 'center', gap: 5 }}><WorkOffIcon />{props.isAllUser ? 'Absence' : name + ' serez absent(e) du ...'}</h1>
                            </Grid>
                            {props.isAllUser &&
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectComponent name={'userId'} handler={handler} options={props.options} onChange={(val) => props.setUserId(val)} />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DatePickerComponent name={'startAt'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <DatePickerComponent name={'endAt'} handler={handler} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box style={{ background: '#EFF2F4', borderRadius: 5, boxShadow: 'inset 0px 0px 3px #00000026', overflow: 'auto', padding: '0 30px', width: '50%', position: 'relative', maxHeight: 260 }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', gap: 15, padding: '15px 0' }}>
                            {isLoading ? <>
                                <Fade in={true} {...{ timeout: 1000 }}>
                                    <Box className={classes.loader}>
                                        <Box className={classes.loaderContent}>
                                            <Box className={classes.loaderActivity}>
                                                <UseAnimations animation={activity} size={70} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Fade>
                            </> :
                                offuserList.length != 0 ? offuserList.map((user, index) => (
                                    <ShadowBoxComponent key={index} style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <Box style={{ lineHeight: '11px' }}>
                                            <p style={{ display: 'flex', alignItems: 'centers', margin: 0, gap: 5 }}><EventAvailableIcon /> <b>Période</b></p>
                                            <p style={{ margin: 0, fontSize: 13, padding: '0 10px' }}>
                                                <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(-4px, 26%) scaleX(-1)" }} /><b>du</b> {moment(user.startAt).format('ll')} <b>au</b> {moment(user.endAt).format('ll')}
                                            </p>
                                        </Box>
                                    </ShadowBoxComponent>
                                )) : <p className={classes.error}>{handler.getData().userId ? 'Aucune absence' : 'Aucun Webcoach sélectionnez'}</p>
                            }
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { props.setIsOpen(false); props.setUserId(null); handler.reset() }} />
                <ButtonComponent label={'Valider'} onClick={save} />
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    dialogStyle: {
        '& .MuiDialog-paper': {
            maxWidth: 1100
        }
    },
    error: {
        margin: 'auto',
        fontSize: 24,
        fontWeight: 'bold',
        opacity: 0.2,
        textAlign: 'center',
        width: 'fit-content',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)'
    }
});

export default ManagerOffUser;