import React from 'react';
import Api from "../../../Api";
import {useDispatch} from "react-redux";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {DoneAll, HighlightOff, Search} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";
import SelectComponent from '../../../Component/SelectComponent';

function Check(props = {
    onClose: () => {
    }
}) {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        emailIPS: {
            name: 'emailIPS',
            label: 'Email ou IPS',
            textHelper: '',
            type: 'text',
            options: {validation: ['required']},
            defaultValue: ''
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Choisissez un organisme.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const [already, setAlready] = React.useState(null);
    const [emailIPS, setEmailIPS] = React.useState('');
    const [optionOrganism, setOptionOrganism] = React.useState([]);

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const check = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.get({
                    route: 'crm_prospect_check',
                    params: data
                },
                (response) => {
                    handler.setFormLoading(false)
                    setLoading(false);

                    if (response.status === 200) {
                        setAlready(response.data.already);
                        setEmailIPS(data.emailIPS);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        Api.get({
            route: 'select_organisms',
            data: {'isFull': true}
        },
        (response) => {
            if (response && response.data) {
                setOptionOrganism(response.data);
            }
        });
    }, []);

    return (
        <>
            <DialogTitle style={{fontSize: 15}}>Vérification de disponibilité du contact</DialogTitle>
            <DialogContent style={{boxSizing: 'border-box'}}>
                {
                    already === null ? <div style={{textAlign: 'center'}}>
                            <Search style={{fontSize: 75, color: '#6c757d'}}/>
                            <p style={{fontSize: 20, marginBottom: 20, marginTop: 0, color: '#6c757d'}}>Recherche</p>
                        </div>
                        : already === true ? <div style={{textAlign: 'center'}}>
                            <HighlightOff style={{fontSize: 75, color: '#dc3545'}}/>
                            <p style={{fontSize: 20, marginBottom: 20, marginTop: 0, color: '#dc3545'}}>Indisponible</p>
                        </div> :
                        <div style={{textAlign: 'center'}}>
                            <DoneAll style={{fontSize: 75, color: '#28a745'}}/>
                            <p style={{fontSize: 20, marginBottom: 20, marginTop: 0, color: '#28a745'}}>Disponible</p>
                        </div>
                }
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'organism'} handler={handler} options={optionOrganism} onChange={() => { setAlready(null); }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextFieldComponent name={'emailIPS'} handler={handler} onChange={() => { setAlready(null); }}/>
                        </Grid>
                    </Grid>

            </DialogContent>
            <DialogActions>
                <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={props.onClose} disabled={loading}/>
                <ButtonComponent label={'Vérifier'} onClick={check} disabled={loading}/>
            </DialogActions>
            {already === false && <ButtonComponent color={'#28a745'} label={'Ajouter l\'utilisateur'} onClick={() => {
                props.history.push({
                    pathname: getRoutePathname('crm_prospect', {id: null}),
                    state: {emailIPS: emailIPS}
                });
            }} style={{position: 'absolute', bottom: 16, left: 32}}/>}
        </>
    );
}

export default Check;
