import React from 'react';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import Api from '../../../../Api';
import Slide from "@material-ui/core/Slide";
import TabsComponent from '../../../../Component/TabsComponent';
import { theme } from "../../../../App";
import { getRoutePathname } from '../../../../Config/Route';
import FeaturedResourceComponent from './Component/FeaturedResourceComponent';

const FeaturedResource = (props) => {

    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isTall, setIsTall] = React.useState(true);
    const [formationGroups, setFormationGroups] = React.useState([]);

    React.useEffect(() => {

        setBreadcrumbs({
            title: 'Resources à la une',
            path: getRoutePathname('institution_health_home'),
            context: 'Portail',
            description: 'Apreçu apprenant.',
            links: [
                { label: 'Gestion marketing' },
            ]
        });

        Api.get({
            route: 'select_formation_groups',
        },
            (response) => {
                if (response && response.data) {
                    setFormationGroups(response.data);
                    setReadyContent(true);
                }
            });

    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const getTabsFormationGroups = (formationGroups) => {
        let tabs = [];
        if (formationGroups) {
            for (let i = 0; i < formationGroups.length; i++) {
                tabs.push({ id: formationGroups[i].label, label: formationGroups[i].label },)
            }
            return tabs;
        }
    };

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Slide direction="right" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                    <Box style={{ display: 'flex' }}>
                        <ShadowBoxComponent style={{ margin: '30px auto', width: '95%' }}>
                            {formationGroups &&
                                <TabsComponent
                                    style={{ boxShadow: 'none' }}
                                    tabDefault={'Dentiste'}
                                    tabs={getTabsFormationGroups(formationGroups)}
                                >
                                    {formationGroups.map((formationGroup) => (
                                        <div key={formationGroup.label}>
                                            <br />
                                            <FeaturedResourceComponent
                                                organism={selectOrganismReducer.organism.value}
                                                formationGroup={formationGroup.value}
                                                isAdmin={true}
                                            />
                                        </div>
                                    ))}
                                </TabsComponent>}
                        </ShadowBoxComponent>
                    </Box>
                </Slide>
            </ContentViewComponent>
        </Box>
    );
};

const useStyles = makeStyles({
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
});

export default FeaturedResource;