import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Fade, makeStyles } from '@material-ui/core';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";

const generateColors = (count, opacity) => {
    const colors = [];
    for (let i = 0; i < count; i++) {
        const hue = (i * (360 / count)) % 360;
        const color = `hsla(${hue}, 70%, 50%, ${opacity})`;
        colors.push(color);
    }
    return colors;
};

const DoughnutChartComponent = (props) => {

    const classes = useStyles();
    const [dataLabels, setDataLabels] = React.useState([]);
    const [dataValues, setDataValues] = React.useState([]);

    const truncateLabel = (label) => {
        const maxLength = 30;
        return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
    };

    React.useEffect(() => {
        const labels = props.data.map((data) => truncateLabel(data.label));
        const values = props.data.map((data) => data.value);

        setDataLabels(labels);
        setDataValues(values);

    }, [props]);

    const chartData = {
        labels: dataLabels,
        datasets: [
            {
                label: props.label ? props.label : '',
                data: dataValues,
                backgroundColor: props.data[0].label === 'Aucune Donnée' ? '#DADADA' : generateColors(props.data.length, 0.4),
                borderColor: props.data[0].label === 'Aucune Donnée' ? '#DADADA' : generateColors(props.data.length, 1),
                borderWidth: 1,
            },
        ],
        options: {
            legend: {
                display: true,
                position: 'right',
            },
            title: {
                display: true,
                text: props.title ? props.title : '',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 20
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        if (props.data[0].label === 'Aucune Donnée') {
                            return 'Aucune Donnée';
                        } else {
                            const index = tooltipItem[0].index;
                            let labelWithLineBreak = [];
                            const label = props.data[index].label;
                            for (let i = 0; i < label.length; i += 50) {
                                labelWithLineBreak.push(label.substring(i, i + 50));
                            }
                            return labelWithLineBreak;
                        }
                    },
                    label: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const value = dataset.data[tooltipItem.index];
                        if (props.data[0].label === 'Aucune Donnée') {
                            return '';
                        } else {
                            return `Vendu ${value} fois.`;
                        }
                    },
                    afterLabel: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const value = dataset.data[tooltipItem.index];
                        const total = dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue);
                        const percentage = ((value / total) * 100).toFixed(2);
                        if (props.data[0].label === 'Aucune Donnée') {
                            return '';
                        }
                        else {
                            return percentage + ' %';
                        }
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1,
            }
        }
    };

    return (
        <div className={classes.doughnutChart} style={{ transition: 'background-color 1s ease', background: props.loading ? '#DADADA81' : '' }}>
            {(props.loading) &&
                <Fade in={props.loading} {...{ timeout: 1000 }}>
                    <div className={classes.loader}>
                        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                            <span style={{ fontWeight: 600 }}>chargement</span>
                            <UseAnimations animation={activity} size={30} />
                        </div>
                    </div>
                </Fade>
            }
            <Doughnut
                data={chartData}
                options={chartData.options}
                height={120}
            />
        </div>
    );
};

const useStyles = makeStyles({
    doughnutChart: {
        backgroundColor: '#fff',
        borderRadius: 5,
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        padding: '20px',
        color: '#000000',
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        width: '100%',
        height: '100%'
    }
});

export default DoughnutChartComponent;
