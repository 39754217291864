import React from 'react';
import { makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';

function BoxLearnerComponent(props) {

    const classes = useStyles(props)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);


    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <div className={classes.box}>
            <div className={classes.title}>
                {props.iconTitle && props.iconTitle}
                {props.title && <p style={{margin: 0}}>{props.title}</p>}
            </div>
            <div 
                className={isSmallView ? classes.contentSmall : classes.content} 
                style={props.styleContent ? (isSmallView ? props.styleContentSmall : props.styleContent) : {}}
            >
                {props.children}
            </div>
        </div>
    );
}

const useStyles = (props) => makeStyles(({
    
    box: {
        width: '80%',
        margin: '20px auto',
        textAlign: 'left',
        borderRadius: 10,
        position: 'relative'
    },
    title: {
        borderBottom: '1px solid #DADADA',
        textAlign: 'left',
        fontSize: 20,
        padding: 7,
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
            marginLeft: 10,
        },
        '& > p': {
            marginLeft: 10,
            fontWeight: 600,
        }
    },
    content: {
        margin: 0,
        //padding: 25,
        display: 'flex',
    },
    contentSmall: {
        margin: 0,
        display: 'flex',
        '& > div': {
            margin: 10
        }
    },
}));

export default BoxLearnerComponent;
