import {AssignmentInd, ContactPhone, ContactSupport, EmojiPeople} from "@material-ui/icons";

const FormOriginInfo = {
    1: {
        id: 1,
        color: '#9966FE',
        title: 'Source inconnue',
        icon: ContactSupport
    },
    2: {
        id: 2,
        color: '#FF9F40',
        title: 'Formulaire de rappel',
        icon: EmojiPeople
    },
    3: {
        id: 3,
        color: '#4CC0C0',
        title: 'Formulaire de contact',
        icon: ContactPhone
    },
    4: {
        id: 4,
        color: '#35A2EB',
        title: 'Formulaire d\'inscription',
        icon: AssignmentInd
    },
};

export default FormOriginInfo;