import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { theme } from "../../../../App";
import { useSelector } from 'react-redux';
import { LibraryResourceTypeImage } from '../../../../Api';

function CardResourceType(props) {
    const classes = useStyles(theme)();
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} onClick={props.onClick ? props.onClick : ''}>
            <div className={classes.box}>
                {props.libraryResourceType.image &&
                    <>
                        {!load && <img src="/asset/images/image_type_resource_preload.png" className={classes.imageTypePreload} alt={'image'} />}
                        <img style={{objectFit: 'contain'}} width="120px" height="150px" src={LibraryResourceTypeImage + props.libraryResourceType.image} alt='image' onLoad={() => setLoad(true)} />
                    </>
                }
                {props.libraryResourceType.label && <p style={{ color: '#222222', textAlign: 'center', fontWeight: 500, margin: 5, fontSize: 18 }}>{props.libraryResourceType.label}</p>}
            </div>
            <br/>
        </Grid>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        border: 'solid 1px #F2F2F2',
        background: '#fff',
        padding: 0,
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 15,
        cursor: 'pointer',
    },
    imageTypePreload: {
        width: 120,
        height: 80,
        position: 'absolute',
        background: '#fefbf5'
    }
}));

export default CardResourceType;
