import React from 'react';
import { Avatar, Box, Fade, Grid, TextField, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { AvatarGroup } from '@material-ui/lab';
import ButtonComponent from '../../../../../Component/ButtonComponent';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactsIcon from '@material-ui/icons/Contacts';
import ApartmentIcon from '@material-ui/icons/Apartment';
import activity from "react-useanimations/lib/activity";
import DvrIcon from '@material-ui/icons/Dvr';
import { Bar } from 'react-chartjs-2';
import FilterListIcon from '@material-ui/icons/FilterList';
import ShadowBoxComponent from "../../../../../Component/ShadowBoxComponent";
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../../Action/LinearProgressMainLayoutAction';
import Api from '../../../../../Api';
import moment from 'moment';
import UseAnimations from 'react-useanimations';
import { theme } from '../../../../../App';
import { getRoutePathname } from '../../../../../Config/Route';
import { hasRight } from '../../../../../Tool/UserTool';
import { SnackbarOpen } from '../../../../../Action/SnackbarAction';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

const ViewManagerComponent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [session, setSession] = React.useState([]);
    const [data, setData] = React.useState([]);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [dataFormation, setDataFormation] = React.useState({});
    const isLearner = hasRight(authenticationReducer, 'ROLE_LEARNER');

    const [selectedStartAt, setSelectedStartAt] = React.useState(moment().year() + '-01');
    const [selectedEndAt, setSelectedEndAt] = React.useState(moment().year() + '-12');
    const [isError, setIsError] = React.useState(false);

    const handleMonthYearChange = (date, type) => {
        const formattedDate = date ? new Date(date) : null;
        const formattedMonthYear =
            formattedDate && format(formattedDate, 'yyyy-MM', { locale: frLocale });

        if (type === 'startAt') setSelectedStartAt(formattedMonthYear); else if (type === 'endAt') setSelectedEndAt(formattedMonthYear);
    };

    // Tester avec l'id 18927 et rajouter des registrations
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'institution_health_crm_institution_manager',
            params: { id: props.match.params.institution },
            data: { organismId: isLearner ? authenticationReducer.organism : selectOrganismReducer.organism.value }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false)
                if (response.status === 200) {
                    let data = response.data;
                    data.holders = data.holders[0];
                    setData(data);
                } else if (response.status === 404) {
                    props.history.push(
                        isLearner ? getRoutePathname('learner_home') : getRoutePathname('institution_health_home')
                    );
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(
                        isLearner ? getRoutePathname('learner_home') : getRoutePathname('institution_health_home')
                    );
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    React.useEffect(() => {
        if (selectedStartAt >= selectedEndAt) {
            setIsError(true);
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            setIsError(false);
            Api.get({
                route: 'institution_health_crm_institution_info',
                params: { id: props.match.params.institution },
                data: { startAt: selectedStartAt + '-01', endAt: selectedEndAt + '-01'}
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let resData = response.data.bar;
                        resData.datasets[0].backgroundColor = 'rgba(53, 162, 235, 0.5)'
                        resData.datasets[1].backgroundColor = 'rgba(255, 99, 132, 0.5)'
                        setSession(response.data.session);
                        setDataFormation({
                            data: resData,
                            options: {
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'right',
                                        fontColor: '#FFFFFF',
                                        labels: {
                                            fontColor: '#A1A2A9',
                                            padding: 15
                                        }
                                    },
                                    title: {
                                        display: true,
                                        text: 'Chart.js Bar Chart',
                                    },
                                },
                            },
                        })
                    }
                });
        }
    }, [selectedStartAt, selectedEndAt]);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        loading ?
            <Fade in={true} {...{ timeout: 1000 }}>
                <Box className={classes.loader}>
                    <Box className={classes.loaderContent + ' loaderContent'} style={isSmallView ? { width: 225 } : {}}>
                        <ShadowBoxComponent>
                            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                            <Box className={classes.loaderActivity}>
                                <UseAnimations animation={activity} size={35} />
                            </Box>
                        </ShadowBoxComponent>
                    </Box>
                </Box>
            </Fade>
            :
            <Fade in={true} {...{ timeout: 1000 }}>
                <Grid container spacing={2} style={{ margin: 'auto', /*height: '90%',*/ width: '100%', padding: '20px 0', }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ height: '100%' }}>
                        <Grid container spacing={0} style={{ /*display: 'grid',*/ height: '100%', gap: 20, /*gridTemplateRows: 'max-content',*/ }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                <Grid container spacing={0}
                                    style={
                                        (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL') ?
                                            { height: '100%', width: '100%', margin: 0, gap: 20, flexWrap: 'initial' } :
                                            { height: '100%', width: '100%', margin: 0, gap: 20 }
                                    }
                                >
                                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <ShadowBoxComponent className={classes.BoxIstitution} >
                                            <Box style={{ height: '100%', textAlign: 'center', display: 'grid', }}>
                                                <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, flexDirection: 'column', }}>
                                                    <ApartmentIcon style={{ width: 30, height: 30 }} />Établissement
                                                </h1>
                                                <hr style={{ margin: '10px auto', color: '#FFF', width: '50%', height: 0, borderRadius: 100, }} />
                                                <p style={{ margin: 0, fontSize: 25, fontWeight: 'bold' }}>{data.name ? data.name : '-'}</p>
                                                <p style={{ margin: '10px 0', fontSize: 15 }}>Titulaire de Établissement<br /><span style={{ fontSize: 20 }}>{(data.holders && data.holders.length !== 0) ? data.holders.firstName + ' ' + data.holders.lastName : 'Aucun titulaire n\'a été attribué.'}</span></p>
                                            </Box>
                                            {!isLearner &&
                                                <Box style={{ height: '100%', display: 'flex', }}>
                                                    <ButtonComponent
                                                        style={{ margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#5E6E82' }}
                                                        color={'#FFFFFF'} label={'Gestion d’établissement'}
                                                        onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution', { institution: props.match.params.institution }))}
                                                    />
                                                </Box>
                                            }
                                        </ShadowBoxComponent>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} style={{ height: '100%', display: 'flex', flexDirection: 'column', gap: 20 }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                            <Card
                                                data={data.employees}
                                                icon={<ContactsIcon style={{ width: 20, height: 20 }} />}
                                                title={'Salariés'}
                                                text={'Liste des salariés.'}
                                                button={{
                                                    title: 'Accéder',
                                                    background: '#5E6E82',
                                                    onClick: () => {
                                                        props.history.push(
                                                            isLearner ?
                                                                getRoutePathname('learner_institution_employee_list', { institution: props.match.params.institution }) :
                                                                getRoutePathname('institution_health_crm_institution_employee_list', { institution: props.match.params.institution })

                                                        )
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }} >
                                            <Card
                                                data={data.registrations}
                                                icon={<AssignmentIndIcon style={{ width: 20, height: 20 }} />}
                                                title={'Inscriptions'}
                                                text={'Liste des inscrits.'}
                                                button={{
                                                    title: 'Accéder',
                                                    background: '#5E6E82',
                                                    onClick: () => {
                                                        props.history.push(
                                                            isLearner ?
                                                                getRoutePathname('learner_institution_registration_list', { institution: props.match.params.institution }) :
                                                                getRoutePathname('institution_health_crm_institution_registration_list', { institution: props.match.params.institution })
                                                        )
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                <ShadowBoxComponent
                                    className={classes.styleLine}
                                    style={(screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL') ?
                                        { gridTemplateColumns: '70% 30%', gap: 20 } : { gridTemplateColumns: 'initial', gap: 20 }}
                                >
                                    <Box style={(screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL') ? { order: 0 } : { order: 2 }} >
                                        <Bar options={dataFormation.options} data={dataFormation.data} />
                                    </Box>
                                    <Box>
                                        <h1 style={{ margin: 0, display: 'flex', alignItems: 'center', gap: 5, fontSize: 20 }}><DvrIcon />formations</h1>
                                        <p style={{ margin: 0 }}>Statistiques.</p>
                                    </Box>
                                </ShadowBoxComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Grid container spacing={0} style={{ display: 'grid', height: '100%', gap: 20, gridTemplateRows: 'max-content auto' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                <ShadowBoxComponent style={{ height: '100%', display: 'flex', padding: 20, gap: 10 }}>
                                    <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5 }} >
                                        <FilterListIcon style={{ width: 20, height: 20 }} />Filtre
                                    </h1>
                                    <Box className={classes.stylePicker}>
                                        <Box style={{ display: 'flex', alignItems: 'center', }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                <DatePicker
                                                    views={['year', 'month']}
                                                    value={selectedStartAt}
                                                    onChange={(data) => handleMonthYearChange(data, 'startAt')}
                                                    renderInput={(params) => <TextField error={isError} {...params} />}
                                                />
                                            </MuiPickersUtilsProvider>
                                            |
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                                <DatePicker
                                                    views={['year', 'month']}
                                                    value={selectedEndAt}
                                                    onChange={(data) => handleMonthYearChange(data, 'endAt')}
                                                    renderInput={(params) => <TextField error={isError} {...params} />}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                        {isError && <p className={classes.error}>La date de fin doit être supérieure à la date de début</p>}
                                    </Box>
                                    <Box></Box>
                                </ShadowBoxComponent>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                                <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, gap: 10, gridTemplateRows: 'max-content auto' }}>
                                    <Box style={{ height: '100%' }}>
                                        <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 2 }}><DateRangeIcon style={{ width: 20, height: 20 }} />Session</h1>
                                        <p style={{ margin: 0, fontSize: 15 }}>Liste des session.</p>
                                    </Box>
                                    <Box style={{ height: '100%', background: '#EFF2F4', borderRadius: 10, boxShadow: 'inset 0px 0px 4px #0000001a', display: 'flex', position: 'relative' }}>
                                        {session.length != 0 ?
                                            <Box style={{ height: '100%', width: '100%', overflow: 'auto', position: 'absolute' }}>
                                                {session.map((data, index) => (
                                                    <CardSession data={data} key={index} />
                                                ))}
                                            </Box>
                                            : <h1 style={{ width: 'fit-content', height: 'fit-content', margin: 'auto', opacity: 0.3, fontSize: 20 }}>Aucune données...</h1>
                                        }
                                    </Box>
                                </ShadowBoxComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
    )
}

const Card = (props) => {
    const classes = useStyles();
    return (
        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20, background: props.background, color: props.color }}>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5 }}>{props.icon}{props.title}</h1>
                <p style={{ margin: 0, fontSize: 15 }}>{props.text}</p>
            </Box>
            {props.button &&
                <Box style={{ height: '100%', display: 'flex', }}>
                    <AvatarGroup className={classes.Avatar} max={3}>
                        {(props.data && (props.data.length > 3)) && props.data.map((data, index) => (
                            <Avatar key={index} className={'MuiAvatar-colorActive'}>
                                <PersonIcon />
                            </Avatar>
                        ))}
                    </AvatarGroup>
                    <ButtonComponent
                        style={{ margin: 'auto 0px auto auto', width: 'fit-content', padding: '6px 40px', color: props.button.color, background: props.button.background }}
                        label={props.button.title}
                        onClick={() => props.button.onClick()}
                    />
                </Box>
            }
        </ShadowBoxComponent>
    )
}

const CardSession = (props) => {
    const classes = useStyles();
    return (
        <ShadowBoxComponent style={{ height: 'auto', display: 'flex', background: 'rgb(255, 255, 255)', color: 'rgb(94, 110, 130)', alignItems: 'center', width: '90%', margin: '20px auto', gap: 5 }}>
            <EventAvailableIcon style={{ width: 30, height: 30 }} />
            <Box style={{ height: '100%' }}>
                <p style={{ margin: 0, fontSize: 13 }}>Formation</p>
                <p style={{ margin: 0, fontSize: 17, fontWeight: 'bold' }}>{props.data.formation}</p>
                <p style={{ margin: 0, fontSize: 13 }}>{props.data.date}</p>
            </Box>
        </ShadowBoxComponent >
    )
}

const useStyles = makeStyles({
    stylePicker: {
        '& .MuiInputBase-input': {
            textAlign: 'center',
            color: '#5E6E82',
            fontWeight: 'bold',
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    BoxIstitution: {
        height: '100%',
        background: 'linear-gradient(rgba(94, 110, 130, 1), rgba(94, 110, 130, .8)), url(/asset/images/wallpaper_institution.webp)',
        display: 'grid',
        padding: 20,
        color: '#FFFFFF',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    styleLine: {
        height: '100%',
        display: 'grid',
        padding: 20,
    },
    Avatar: {
        '& .MuiAvatar-colorActive': {
            background: '#5E6E82 !important',
            color: '#FFFFFF',
            width: 30,
            height: 30,
            fontSize: 12
        },
        '& .MuiAvatar-colorDefault': {
            width: 30,
            height: 30,
            fontSize: 12,
            color: '#FFFFFF',
            backgroundColor: '#5E6E82C4',
        }
    },
    containerButtonArrow: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        margin: 'auto',
        padding: '12px 0',
        '& p': {
            margin: 'auto',
            width: '60%',
            textAlign: 'center',
            background: '#EFF2F4',
            padding: '10px 0',
            border: '1px solid #0000002e',
            borderRadius: 5
        },
    },
    buttonArrow: {
        width: 25,
        height: 25,
        overflow: 'hidden',
        background: 'rgb(239, 242, 244)',
        position: 'relative',
        borderRadius: 20,
        cursor: 'pointer',
        '& svg': {
            position: 'absolute',
            width: '80%',
            height: '100%',
            left: '50%',
            top: '50%',
        }
    },
    error: {
        margin: 0,
        fontSize: 10,
        textAlign: 'center',
        padding: '3px 0',
        color: 'red',
    }
})

export default ViewManagerComponent;