import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import SelectComponent from "../../../Component/SelectComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import SweetAlert from 'sweetalert-react';
import { TableReload } from "../../../Action/TableAction";
import { getRoutePathname } from "../../../Config/Route";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import moment from "moment";

function SessionList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [actionRow, setActionRow] = React.useState({});
    const [optionYears, setOptionYears] = React.useState([]);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAtText",
            label: "Debut de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAtText",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "organismName",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('administrator_session', { id: row.id }))}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        sessionStartAtText: {
            name: 'sessionStartAtText',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['administrator_session_list'] && cacheTableServer['administrator_session_list'].sessionStartAtText ? cacheTableServer['administrator_session_list'].sessionStartAtText : '',
            options: {}
        },
        sessionEndAtText: {
            name: 'sessionEndAtText',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['administrator_session_list'] && cacheTableServer['administrator_session_list'].sessionEndAtText ? cacheTableServer['administrator_session_list'].sessionEndAtText : '',
            options: {}
        },
        organismName: {
            name: 'organismName',
            label: 'Organisms',
            textHelper: 'Trier par organisms.',
            type: 'text',
            defaultValue: cacheTableServer['administrator_session_list'] && cacheTableServer['administrator_session_list'].organismName ? cacheTableServer['administrator_session_list'].organismName : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['administrator_session_list'] && cacheTableServer['administrator_session_list'].year ? cacheTableServer['administrator_session_list'].year : currentYear,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['administrator_session_list'] && cacheTableServer['administrator_session_list'].financeType ? cacheTableServer['administrator_session_list'].financeType : 'all',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['administrator_session_list'] = 0;
        cacheTableServer['administrator_session_list'].page = 0;
        cacheTableServer['administrator_session_list'][index] = value;
        dispatch(TableReload('administrator_session_list'))
    };

    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des sessions',
            context: 'Administrateur',
            description: ''
        });
        
        // Year
        let yearsTMP = [];
        for (let i = currentYear + 1; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);

        Api.get({
            route: 'select_organisms',
            data: {'isFull': true}
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionOrganism(data);
                }
            });

        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFinanceType(data);
                }
            });

        setReadyContent(true);
    }, []);

    const getDatas = (resolve, filters) => {
        Api.get({
            route: 'administrator_session_list', data: filters
        },
            (response) => {
                let data = response.data.data;
                for (let index in data) {
                    data[index].sessionStartAtText = moment(data[index].sessionStartAt).format("DD/MM/YYYY");
                    data[index].sessionEndAtText = moment(data[index].sessionEndAt).format("DD/MM/YYYY");
                    data[index].action = getAction(data[index]);
                }
                resolve(data, response.data.count);
            });
    }
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                        <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'organismName'} handler={handlerSearch} options={optionOrganism} onChange={(val) => inputSearchChange('organismName', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <DatePickerComponent name={'sessionStartAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAtText', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <DatePickerComponent name={'sessionEndAtText'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAtText', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>
            <br />
            <TableComponent
                id={'administrator_session_list'}
                title={'Sessions'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une session',
                    onClick: () => props.history.push(getRoutePathname('administrator_session', { id: null }))
                }}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        organismName: (datas.organismName === 'all') ? '' : datas.organismName,
                        sessionStartAt: datas.sessionStartAtText ?? '',
                        sessionEndAt: datas.sessionEndAtText ?? '',
                        financeType: (datas.financeType === 'all') ? '' : datas.financeType,
                    };
                    getDatas(resolve, filters)
                }}
                search={false}
            />

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.name + '\nÊtes-vous sur de vouloir supprimer cet session ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'administrator_session_delete',
                        params: { id: actionRow.id }
                    },
                        () => {
                            dispatch(TableReload('administrator_session_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}
const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});
export default SessionList;
