import React from 'react';
import { Box, Avatar, makeStyles, Popover, Typography } from "@material-ui/core";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import Grid from "@material-ui/core/Grid";
import { theme } from "../../../App";
import IconButton from "@material-ui/core/IconButton";
import EditorComponent from "../../../Component/EditorComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { useDispatch } from "react-redux";
import { Delete, Edit } from "@material-ui/icons";
import Slide from '@material-ui/core/Slide';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CommentIcon from '@material-ui/icons/Comment';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Badge from '@material-ui/core/Badge';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { getRoutePathname } from "../../../Config/Route";


function CommunityComment(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const data = props.data;
    const containerRef = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState('');
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Écrire un commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });

    const handler = formHandlerInit(form, setForm);
    const save = (id) => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                Api.post({
                    route: 'learner_community_comment_edit',
                    data: handler.getData(),
                    params: {
                        id: id,
                        communityGroup: props.params.communityGroup,
                        communityPost: props.params.communityPost
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        props.loadData();
                        handler.reset();
                        onOpen();
                        dispatch(
                            SnackbarOpen({
                                text: 'Votre commentaire a bien été modifier.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                Api.post({
                    route: 'learner_community_comment_add',
                    data: { comment: handler.getData().comment, communityComment: id },
                    params: {
                        communityGroup: props.params.communityGroup,
                        communityPost: props.params.communityPost
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 201) {
                        props.loadData()
                        handler.reset();
                        onOpen();
                        dispatch(
                            SnackbarOpen({
                                text: 'Vous avez répondu au commentaire.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const editAction = (id) => {
        if (id && props.params.communityPost) {
            setIsEdition(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'learner_community_comment_get',
                params: {
                    id: id,
                    communityGroup: props.params.communityGroup,
                    communityPost: props.params.communityPost
                }
            },
                (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data[0]);
                    }
                    else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
        else {
            setIsEdition(false);
        }
    }

    const onOpen = () => isOpen == false ? setIsOpen(true) : setIsOpen(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const getAction = () => {
        return (
            <>
                {data.isCommentProspect ?
                    <>
                        <IconButton className={classes.buttonPostProspect}
                            onClick={() => {
                                editAction(data.id);
                                setIsEdition(true);
                                setLoading(true);
                                handleClose()
                                handler.reset();
                                onOpen()
                            }}>
                            <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Modifier</span>
                        </IconButton>
                        <IconButton
                            className={classes.buttonPostProspect}
                            onClick={() => {
                                setActionRow(data);
                                setShowConfirm(true);
                                handleClose();
                            }}>
                            <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Supprimer</span>
                        </IconButton>
                    </>
                    :
                    <IconButton className={classes.buttonPostProspect}
                        onClick={() => {
                            handler.reset();
                            handleClose()
                            setLoading(false);
                            onOpen()
                        }}>
                        <CommentIcon style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Répondre</span>
                    </IconButton>
                }
            </>
        )
    }

    const viewAction = () => {
        return (
            <div>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <MoreVertIcon style={{ color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {getAction()}
                    </Typography>
                </Popover>
            </div>
        )
    }

    const boxForm = (data, isOpen) => {
        return (
            <Box sx={{ transitionProperty: 'all', transitionDuration: '.3s', width: "100%", display: 'flex', overflow: "hidden" }}>
                <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.contnaireForm} ref={containerRef}>
                            <Grid container spacing={2} className={classes.boxComponent} style={{ textAlign: 'center', position: 'relative' }}>
                                <Box onClick={() => onOpen()} style={{ width: 30, height: 30, right: 20, cursor: 'pointer', borderRadius: 10, background: '#5E6E82', position: 'absolute', display: 'flex', alignItems: 'center' }}>
                                    <HighlightOffIcon style={{margin:'auto', color: '#FFFFFF'}}/>
                                </Box>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p style={{ color: "#5E6E82", fontSize: 20, margin: "auto 45px", fontWeight: "bold", borderLeft: "10px solid", padding: " 10px 30px", textAlign: "left", }}>
                                        {isEdition ? 'Modifier votre commentaire' : 'Répondre au commentaire'}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <EditorComponent name={'comment'} handler={handler} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ButtonComponent className={classes.buttonComment} label={data.isCommentProspect ? 'Commenter' : 'Répondre'} onClick={() => {
                                        save(data.id)
                                    }} disabled={loading} />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Slide>
            </Box>

        )
    }
    const viewResponse = (data) => {
        return (
            <div className={classes.boxCommentResponse}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                    {data.status === 'Admin' ?
                        <Badge color="secondary" badgeContent={'A'} showZero>
                            <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                        </Badge> : (!data.pseudo) ?
                            <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar> :
                            <Avatar className={classes.avatar}>{data.pseudo.charAt().toUpperCase()}</Avatar>
                    }

                    <div style={{ padding: '0 10px', width: "100%" }}>
                        <p className={classes.pseudo}>
                            {data.status === 'Admin' ? <span style={{ color: theme.colorPrimary }}> Administrateur </span> : !data.pseudo ? <span> {data.firstname.charAt().toUpperCase()}.{data.lastname} </span> : <span> {data.pseudo} </span>}
                        </p>
                        <p className={classes.stats}><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> commentaire ajouter le {data.createdAt}</p>
                        <p className={classes.subTitle} dangerouslySetInnerHTML={{ __html: data.comment }} />
                        <p className={classes.isUpdatedAt}>{data.isUpdatedAt && 'Commentaire modifier'}</p>
                    </div>
                </Grid>
            </div>
        )
    }
    React.useEffect(handler.start, []);
    return (
        <div className={classes.boxComment}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex' }}>
                {data.status === 'Admin' ?
                    <Badge color="secondary" badgeContent={'A'} showZero>
                        <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar>
                    </Badge> : (!data.pseudo) ?
                        <Avatar className={classes.avatar}>{data.firstname.charAt().toUpperCase()}{data.lastname.charAt().toUpperCase()}</Avatar> :
                        <Avatar className={classes.avatar}>{data.pseudo.charAt().toUpperCase()}</Avatar>
                }

                <div style={{ padding: '0 10px', width: "100%" }}>
                    <p className={classes.pseudo}>
                        {data.status === 'Admin' ? <span style={{ color: theme.colorPrimary }}> Administrateur </span> : !data.pseudo ? <span> {data.firstname.charAt().toUpperCase()}.{data.lastname} </span> : <span> {data.pseudo} </span>}
                    </p>
                    <p className={classes.stats}><KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> commentaire ajouter le {data.createdAt}</p>
                    {data.responsProspect && viewResponse(data.responsProspect)}
                    <p className={classes.subTitle} dangerouslySetInnerHTML={{ __html: data.comment }} />
                    <p className={classes.isUpdatedAt}>{data.isUpdatedAt && 'Commentaire modifier'}</p>
                </div>
                <Box style={{ margin: '0 0 0 auto' }}>{props.isComment && viewAction()}</Box>
            </Grid>
            {boxForm(data, isOpen)}
            <br />
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sûr de vouloir supprimer ce commentaire ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                className={classes.sweetAlert}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'learner_community_comment_delete',
                        params: {
                            id: actionRow.id,
                            communityGroup: props.params.communityGroup,
                            communityPost: props.params.communityPost
                        }
                    },
                        (response) => {
                            if (response.status === 200) {
                                props.loadData();
                            }
                            else {
                                props.history.push(getRoutePathname('learner_home'));
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Une erreur inattendue s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </div>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        margin: 0,
        padding: "10px 0",
        '& p,h1,h2,h3': { margin: 0 }
    },
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
    },
    boxCommentResponse: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
        margin: 16,
    },
    isUpdatedAt: {
        textAlign: 'right',
        margin: 0,
        fontSize: 10
    },
    contnaireForm: {
        margin: 0,
        fontSize: 12,
        color: "#7B7B7B",
        padding: 10,
        '& .rdw-editor-main': {
            minHeight: 158
        }
    },
    buttonComment: {
        borderRadius: "5px",
        padding: "0px 59px",
        fontSize: 15,
        textTransform: "initial",
        fontWeight: "bolder",
        background: "#c8dfe5 !important",
        boxShadow: "0 0",
        border: "4px solid #c8dfe5",
        color: "#5E6E82"
    },
    boxComment: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        width: "93%",
        margin: "auto",
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    buttonPostProspect: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
    pseudo: {
        margin: 0,
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#495666"
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    stats: {
        margin: 0,
        fontSize: 11,
        fontWeight: "bold"
    }
}));

export default CommunityComment;
