import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Info } from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import moment from "moment/moment";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {TableReload} from "../../../Action/TableAction";
import {useDispatch} from "react-redux";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Grid from "@material-ui/core/Grid";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";
import Slide from "@material-ui/core/Slide";
import TextFieldComponent from "../../../Component/TextFieldComponent";

export default function PlatformLogs(props) {
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [dialog, setDialog] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState('');
    const [optionUser, setOptionUser] = React.useState([]);

    const [form, setForm] = React.useState({
        user: {
            name: 'user',
            label: 'Utilisateur',
            textHelper: 'Rechercher par utilisateur.',
            type: 'text',
            defaultValue: 'all',
            options: {}
        },
        endPoint: {
            name: 'endPoint',
            label: 'Route',
            textHelper: 'Rechercher par route',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début',
            textHelper: "Rechercher par date de début",
            type: 'date',
            defaultValue: '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin',
            textHelper: "Rechercher par date de fin",
            type: 'date',
            defaultValue: '',
            options: {}
        },
    });

    const handler = formHandlerInit(form, setForm);

    const inputSearchChange = (index, value) => {
        handler.setValue(index, value);
        dispatch(TableReload('administrator_platform_log_list'))
    };

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "user",
            label: "Utilisateur",
            options: { filter: true, sort: true }
        },
        {
            name: "endPoint",
            label: "Route",
            options: { filter: true, sort: true }
        },
        {
            name: "date",
            label: "Date",
            options: { filter: false, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'voir la requête'} placement="left">
                    <IconButton onClick={() => {
                        // let contentTMP = <></>
                        // for (let index in row.payload) {
                        //     contentTMP =
                        //         <>
                        //             {contentTMP}
                        //             <p><strong>{index} :</strong> {row.payload[index]}</p>
                        //         </>
                        // }
                        
                        let contentTMP = [];
                        for (let index in row.payload) {
                            
                            contentTMP.push(
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <p><strong>{index} :</strong> {row.payload[index] ? row.payload[index] : '-'}</p>
                                </Grid>
                            );
                            
                        }

                        
                        setDialog(true);
                        setDialogContent(contentTMP)
                    }}>
                        <Info />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        Api.get({
            route: 'select_users'
        },
            (response) => {
                if (response && response.data) {
                    setOptionUser([...[{ value: 'all', label: 'Tous' }], ...response.data]);
                }
            });
    }, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des logs de la plateforme',
            context: 'Logs de la plateforme',
            description: ''
        });
        setReadyContent(true);
    }, []);

    const getDatas = (resolve, filters) => {
        Api.get({
                route: 'administrator_platform_log_list',
                data: filters
            },
            (response) => {
                
                if (response && response.data && response.data.data && response.data.count) {
                    let data = response.data.data;
                    for (let index in data) {
                        let color = '#000';
                        let text = '';
                        let hasPayload = false;
                        const endPoint = data[index].endPoint
    
                        if (data[index].payload){
                            data[index].payload = JSON.parse(data[index].payload)
                            data[index].action = getAction(data[index]);
                            hasPayload = true;
                        }
    
                        if (hasPayload) {
                            color = '#db770a';
                            text = 'Modification'
                            if (endPoint.includes('add')){
                                color = '#28a745';
                                text = 'Ajout'
                            }
                            if (endPoint.includes('export') || endPoint.includes('download')){
                                color = '#007bff';
                                text = 'Export'
                            }
                        } else if (endPoint.includes('export') || endPoint.includes('download')){
                            color = '#007bff';
                            text = 'Export'
                        } else {
                            color = '#DB0A22'
                            text = 'Suppression'
                        }
    
                        data[index].endPoint = <span style={{color: color}}>{data[index].endPoint + ' (' + text + ')' }</span>;
                        data[index].date = moment(data[index].date).format('llll');
                    }
                    resolve(data, response.data.count);
                }
                else {
                    resolve([]);
                }
            });
    }
    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon />Filtre</>} />
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <DatePickerComponent name={'startAt'} handler={handler} onChange={(val) => inputSearchChange('startAt', val)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <DatePickerComponent name={'endAt'} handler={handler} onChange={(val) => inputSearchChange('endAt', val)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <TextFieldComponent name={'endPoint'} handler={handler} onChange={(val) => inputSearchChange('endPoint', val)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3}>
                                <SelectAutocompleteComponent name={'user'} handler={handler} options={optionUser} nullable={true} onChange={(val) => inputSearchChange('user', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />

            <TableComponent
                id={'administrator_platform_log_list'}
                title={'Liste des logs'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let data = handler.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        startAt: data.startAt ?? '',
                        endAt: data.endAt ?? '',
                        endPoint: data.endPoint ?? '',
                        user: (data.user === 'all') ? '' : data.user,
                    };
                    getDatas(resolve, filters)
                }}
                search={false}
            />

            <Dialog open={dialog} maxWidth={'sm'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>Objet de la requête</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        {dialogContent}
                    </Grid> 
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Fermer'} onClick={() => setDialog(false)}/>
                </DialogActions>
            </Dialog>
        </ContentViewComponent>
    );
}