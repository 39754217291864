import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Assignment} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";

function HotlineList(props) {
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', {id: row.prospectId}))}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'contact',
            label: 'Contact',
            options: {filter: true, sort: true}
        },
        {
            name: 'email',
            label: 'Email du contact',
            options: {filter: true, sort: true}
        },
        {
            name: 'authorComment',
            label: 'Commentaire',
            options: {filter: true, sort: true}
        },
        {
            name: 'hotlineTypeName',
            label: 'Demande',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            options: {filter: true, sort: true}
        },
        {
            name: 'supportComment',
            label: 'Commentaire du support',
            options: {filter: true, sort: true}
        },
        {
            name: 'treatedAt',
            label: 'Suivi',
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Mes hotlines',
            context: 'CRM',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'crm_hotline_list'}
                title={'Hotlines'}
                columns={columns}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'crm_hotline_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].createdAt = moment(data[index].createdAt).format('ll');
                                data[index].treatedAt = data[index].treatedAt ? moment(data[index].treatedAt).format('ll') : 'En cours...';
                                data[index].supportComment = data[index].supportComment ? data[index].supportComment : '-';
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />
            <br/>
        </ContentViewComponent>
    );
}

export default HotlineList;
