import React from 'react';
import { Fade, makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LockIcon from '@material-ui/icons/Lock';
import { theme } from "../../../../App";
import { OrganismIndex, Organisms } from "../../../../Organism";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSelector } from 'react-redux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function CardFormation(props) {
    const classes = useStyles(theme)();
    const [load, setLoad] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const checkRegistrationOrganism = () => {
        let organism = '';
        let logo = '';
        if (props.organism) organism = OrganismIndex[props.organism];
        if (Organisms[organism]) {
            logo = Organisms[organism].logo;
        }
        return logo;
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
        if ((screenReducer.screen === 'XS' || screenReducer.screen === 'SM')) {
            setLoad(false);
        }
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{ timeout: 500 }}>
            <div onClick={() => props.onClick()} style={{ margin: 10, background: '#fff', width: isSmallView ? '100%' : '96%', position: 'relative', borderRadius: 10, cursor: 'pointer' }}>
                <div className={isSmallView ? classes.boxSmall : classes.box}>

                {!isSmallView && 
                    <div style={{position: 'relative', width: 100, height: 110}}>
                        {(!load) && <img src="/asset/images/preload_image.png" className={classes.cardImage} alt={'Image formation'}/>}
                        {(props.image && !isSmallView) && <img src={props.image} className={classes.cardImage} style={{objectFit: 'cover'}} alt={'Image formation'} onLoad={() => { setLoad(true) }} />}
                    </div>
                }
                    
                <div className={isSmallView ? classes.contentSmall : classes.content}>
                        <Typography className={classes.cardText} style={{ margin: 5, fontSize: 16, height: isSmallView ? '50%' : '45%', width: isSmallView ? '70%' : '80%', paddingTop: 5 }} variant="subtitle2" component="p">
                            {props.title && props.title}
                        </Typography>
                        <Typography style={{ margin: 5, fontSize: 11, fontStyle: 'italic', color: '#23193D' }} variant="subtitle2" component="p">
                            {props.textSession && props.textSession}
                        </Typography>
                        {(props.availableAt) ?
                            <div style={{ backgroundColor: '#17a2b8' }} className={classes.cardState}>
                                <LockIcon className={classes.cardStateIcon} />
                                Bientôt disponible
                                {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                            </div>
                            :
                            <>
                                {(props.state === 1) &&
                                    <div style={{ backgroundColor: '#28a745' }} className={classes.cardState}>
                                        <CheckCircleOutlineIcon className={classes.cardStateIcon} />
                                        Disponible
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                {(props.state === 2) &&
                                    <div style={{ backgroundColor: '#17a2b8' }} className={classes.cardState}>
                                        <LockIcon className={classes.cardStateIcon} />
                                        Bientôt disponible
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                {(props.state === 3) &&
                                    <div style={{ backgroundColor: '#6c757d' }} className={classes.cardState}>
                                        <EmojiEventsIcon className={classes.cardStateIcon} />
                                        Terminée
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                {(props.state === 4) &&
                                    <div style={{ backgroundColor: '#ffc107' }} className={classes.cardState}>
                                        <HourglassEmptyIcon className={classes.cardStateIcon} />
                                        ANDPC en attente
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                {(props.state === 5) &&
                                    <div style={{ backgroundColor: '#6c757d' }} className={classes.cardState}>
                                        <EmojiEventsIcon className={classes.cardStateIcon} />
                                        Terminée
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                {(props.state === 6) &&
                                    <div style={{ backgroundColor: '#ffc107' }} className={classes.cardState}>
                                        <HourglassEmptyIcon className={classes.cardStateIcon} />
                                        Financement en attente
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                                
                                {(props.state === 7) &&
                                    <div style={{ backgroundColor: '#dc3545' }} className={classes.cardState}>
                                        <ErrorOutlineIcon className={classes.cardStateIcon} />
                                        Conflit de paiement
                                        {props.hours ? <div className={classes.cardHour}><AccessTimeIcon className={classes.cardHourIcon} /> {props.hours}h</div> : <></>}
                                    </div>
                                }
                            </>
                        }

                        {(props.state === 1) && <p className={classes.textLink}>Continuer<ArrowRightAltIcon /></p>}
                        {(props.state === 2) && <p className={classes.textLink}>Visualiser<ArrowRightAltIcon /></p>}
                        {(props.state === 3 || props.state === 5) && <p className={classes.textLink}>Revoir la formation<ArrowRightAltIcon /></p>}
                        {(props.state === 4 || props.state === 6) && <p className={classes.textLink}>Validez l'inscription<ArrowRightAltIcon /></p>}

                        {props.organism && <img src={'/asset/images/' + checkRegistrationOrganism()} alt="logo" className={classes.logoOrganism} style={{ width: isSmallView ? '30%' : '20%' }} />}
                    </div>
                    
                    
                    
                </div>
            </div>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        padding: '0px 0px 10px 0px',
        height: 100,
        display: 'flex',
        border: '1px solid #DADADA',
        borderRadius: 10
    },
    boxSmall: {
        padding: 5,
        height: 140,
        display: 'flex',
        border: '1px solid #DADADA',
        borderRadius: 10
    },
    cardImage: {
        height: 110,
        width: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '10px 0px 0px 10px'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 11,
        lineHeight: '15px',
        bottom: -10,
        left: 0
    },
    content: {
        height: 100,
        position: 'relative',
        width: '100%',
        marginLeft: 20
    },
    contentSmall: {
        height: 140,
        position: 'relative',
        width: '100%'
    },
    textLink: {
        position: 'absolute',
        bottom: 18,
        right: 20,
        margin: 0,
        color: theme.colorPrimary,
        fontSize: 14,
        '& > svg': {
            verticalAlign: 'middle'
        }
    },
    logoOrganism: {
        position: 'absolute',
        right: 5,
        top: 10,
        opacity: 0.5
    },
    cardText: {
        color: '#23193D',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardHour: {
        position: 'absolute',
        width: 'max-content',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 10,
        margin: 0,
        fontSize: 11,
        top: 0,
        left: 165
    },
    cardHourIcon: {
        fontSize: 15,
        verticalAlign: 'bottom'
    },
}));

export default CardFormation;
