import React from 'react';
import {ViewState} from "@devexpress/dx-react-scheduler";
import moment from "moment";
import {Appointments, AppointmentTooltip, DateNavigator, Resources, Scheduler, TodayButton, Toolbar, WeekView} from "@devexpress/dx-react-scheduler-material-ui";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";
import ButtonComponent from "./ButtonComponent";
import {formHandlerInit} from "../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import DateTimePickerComponent from "./DateTimePickerComponent";
import TextFieldComponent from "./TextFieldComponent";
import {useDispatch} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../Action/LinearProgressMainLayoutAction";
import SelectComponent from "./SelectComponent";
import SchedulerColor from "../Constant/SchedulerColor";

function SchedulerComponent(props = {
    title: 'Agenda',
    datas: [],
    options: [],
    onEdit: false,
    onDelete: false
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [visible, setVisible] = React.useState(false);

    const getTypeLabel = (id) => {
        for (let index in props.options) {
            if (id === props.options[index].value) return props.options[index].label
        }

        return '';
    };

    // Calendar
    const TimeTableCell = ({startDate, endDate}) => {
        let now = moment();
        return (
            <WeekView.TimeTableCell className={moment(startDate).isBefore(now) && classes.cellDisable} onDoubleClick={() => {
                if (moment(startDate).isAfter(now)) {
                    setDateSelected({});
                    handler.reset();
                    handler.setValue('startDate', startDate);
                    handler.setValue('endDate', endDate);
                    setDialog(true);
                }
            }}/>
        );
    };
    let ressource = {
        fieldName: 'type',
        title: 'Type',
        instances: [],
    };
    for (let index in props.options) {
        ressource.instances.push({
            id: props.options[index].value,
            text: props.options[index].label,
            color: SchedulerColor[props.options[index].value]
        });
    }

    // Form
    const [dateSelected, setDateSelected] = React.useState({});
    const [dialog, setDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        id: {
            name: 'id',
            label: 'Id',
            textHelper: '',
            type: 'integer'
        },
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez un titre.',
            type: 'text',
            options: {validation: ['required']}
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text'
        },
        startDate: {
            name: 'startDate',
            label: 'Date de début',
            textHelper: 'Choisissez une date de début.',
            type: 'datetime',
            options: {validation: ['required', 'datetime'], minDate: moment().format('YYYY-MM-DD HH:mm:ss')}
        },
        endDate: {
            name: 'endDate',
            label: 'Date de fin',
            textHelper: 'Choisissez une date de fin.',
            type: 'datetime',
            options: {validation: ['required', 'datetime']}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Choisissez le type.',
            type: 'integer',
            defaultValue: 1,
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        dispatch(LinearProgressMainLayoutDeactivate());

        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let datasForm = {...dateSelected, ...handler.getData()};

            if (props.onEdit) {
                props.onEdit(datasForm, () => {
                    setDialog(false);
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setVisible(false);
                });
            }
        }
    }

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (form.startDate.value && form.endDate.value) {
            form.endDate.options.minDate = form.startDate.value;
            form.endDate.options.maxDate = moment(form.startDate.value).format('YYYY-MM-DD 23:59:59');
            form.endDate.error = handler.getErrorByField(form.endDate);
            handler.set({...form});
        }
    }, [form.startDate.value, form.endDate.value]);

    return (
        <>
            <Scheduler data={props.datas} locale={'fr-FR'} onClick={() => alert('c')}>
                <ViewState defaultCurrentDate={moment().format('YYYY-MM-DD')}/>
                <WeekView startDayHour={6} endDayHour={20} timeTableCellComponent={TimeTableCell}/>
                <Toolbar/>
                <DateNavigator/>
                <TodayButton messages={{today: 'Aujourd\'hui'}}/>
                <Appointments/>
                <AppointmentTooltip
                    onAppointmentMetaChange={(event) => setDateSelected(event.data)}
                    onVisibilityChange={(v) => setVisible(v)}
                    visible={visible}
                    contentComponent={(contentProps) => {
                        return (
                            <div className={classes.content}>
                                <div className={classes.contentTitle}>{contentProps.appointmentData.title}</div>
                                <div className={classes.contentDate}>
                                    Le {moment(contentProps.appointmentData.startDate).format('LL')} de {moment(contentProps.appointmentData.startDate).format('LT')} à {moment(contentProps.appointmentData.endDate).format('LT')}
                                </div>
                                <div className={classes.contentComment}>{contentProps.appointmentData.comment}</div>
                            </div>
                        );
                    }}
                    headerComponent={(contentProps) => {
                        return <div style={{backgroundColor: SchedulerColor[contentProps.appointmentData.type], height: 36}}>
                            <div className={classes.headerTitle} style={contentProps.appointmentData.infoHeader ? {lineHeight: '15px', marginTop: 3} : {}}>
                                {getTypeLabel(contentProps.appointmentData.type)}
                                {contentProps.appointmentData.infoHeader && <><br/>{contentProps.appointmentData.infoHeader}</>}
                            </div>
                            <Tooltip title={'Modifier'} placement="left">
                                <IconButton className={classes.buttonAction} size={'small'} onClick={() => {
                                    handler.reset();
                                    handler.setDataApi(dateSelected);
                                    setDialog(true);
                                }}>
                                    <Edit className={classes.buttonActionIcon}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Supprimer'} placement="left">
                                <IconButton className={classes.buttonAction} onClick={() => {
                                    if (props.onDelete) {
                                        props.onDelete(dateSelected.id, () => {
                                            setDialog(false);
                                            handler.setFormLoading(false);
                                            setLoading(false);
                                            dispatch(LinearProgressMainLayoutDeactivate());
                                            setVisible(false);
                                        });
                                    }
                                }}>
                                    <Delete className={classes.buttonActionIcon}/>
                                </IconButton>
                            </Tooltip>
                        </div>;
                    }}
                />
                <Resources data={[ressource]}/>
            </Scheduler>

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent name={'title'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectComponent name={'type'} handler={handler} options={props.options}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePickerComponent name={'startDate'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePickerComponent name={'endDate'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldComponent name={'comment'} handler={handler} multiline/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    buttonActionIcon: {
        fontSize: 18,
        opacity: 0.8,
        color: '#eeeeee',
        marginBottom: 3
    },
    buttonAction: {
        padding: '4px 5px',
        marginTop: 5,
        marginRight: 5,
        margin: 0,
        float: 'right'
    },
    cellDisable: {
        backgroundColor: '#eeeeee'
    },
    headerTitle: {
        float: 'left',
        color: 'white',
        lineHeight: '36px',
        fontSize: 12,
        paddingLeft: 8,
    },
    content: {
        padding: 10,
        textAlign: 'center',
    },
    contentTitle: {
        fontSize: 18,
        padding: 5
    },
    contentDate: {
        fontSize: 13
    },
    contentComment: {
        padding: 10,
        fontSize: 15
    },
});

export default SchedulerComponent;
