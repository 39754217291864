import React from 'react';
import {Slider, makeStyles} from "@material-ui/core";

function RangeComponent(props = {
    name: '',
    disabled: false,
    onSubmit: () => {
    },
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    }
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(0);
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;
    let options = data.options ?? {};

    const onChange = (event, newValue) => {
        setValue(newValue);

        if (!isFocus) {
            let newForm = {...props.handler.form};
            newForm[props.name].value = newValue;
            newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
            setError(newForm[props.name].error);
            props.handler.set(newForm);
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = {...props.handler.form};
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error);
    }, [props.handler.form[props.name]].error);

    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <div>
            <p style={{margin: 0, textAlign: 'center', fontSize: 10}}>{data.label && data.label}</p>
            <Slider
                id={props.name}
                className={`${classes.range} ${error ? classes.rangeError : classes.rangeValid}`}
                step={1}
                valueLabelDisplay="on"
                value={value}
                defaultValue={value}
                disabled={disabled || props.disabled}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onMouseUp={() => {
                    if (props.onChange) {
                        props.onChange(value)
                    }
                }}
                min={options.rangeMin ? options.rangeMin : 0}
                max={options.rangeMax ? options.rangeMax : 100}
            />
        </div>
    );
}

const useStyles = makeStyles({
    rangeValid: {color: '#008000b0',},
    rangeError: {color: '#982525b0',},
    range: {
        height: 8,
        width: '90%',
        margin: 'auto',
        display: 'inherit',
        padding: '15px 0',
        '& > .MuiSlider-thumb': {
            height: 24,
            width: 24,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            marginTop: -8,
            marginLeft: -12,
            '&:focus, &:hover': {
                boxShadow: 'inherit',
            },
            '& > .MuiSlider-valueLabel': {
                left: 'calc(-25% + 4px)',
                top: -20,
                '& > span': {
                    width: 22,
                    height: 22,
                    '& > span': {
                        fontSize: '0.55rem'
                    }
                }
            },
        },
        '& > .MuiSlider-track': {
            height: 8,
            borderRadius: 4,
        },
        '& > .MuiSlider-rail': {
            height: 8,
            borderRadius: 4,
        },
    },
});

export default RangeComponent;
