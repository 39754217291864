import React from 'react'
import TableComponent from '../../../../Component/TableComponent';
import Api from '../../../../Api';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from '@material-ui/core';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../../App';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { Delete } from '@material-ui/icons';

export default function ThematicLibraryResource(props = {}) {

    const [show, setShow] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [actionIdLibraryResource, setActionIdLibraryResource] = React.useState();
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'name',
            label: 'Nom de la ressource',
            options: { filter: true, sort: true }
        },
        {
            name: 'action',
            label: 'Action',
            options: { filter: true, sort: true }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionIdLibraryResource(row.id)
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const [form, setForm] = React.useState({
        libraryResource: {
            name: 'libraryResource',
            label: 'Ressources',
            textHelper: 'Selectionnez les ressources à liés',
            type: 'array',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);


    const [optionLibraryResources, setOptionLibraryResources] = React.useState([]);
    React.useEffect(() => {
        Api.get({
            route: 'select_library_resource'
        },
            (response) => {
                if (response && response.data) {
                    setOptionLibraryResources(response.data);
                }
            });
        setLoading(false);
    }, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'institution_health_marketing_manager_thematic_thematic_library_resource_add',
                data: handler.getData(),
                params: { id: props.match.params.id },
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Ressource(s) ajouté(s) à la thématique.',
                            variant: 'success',
                        })
                    );
                    setShow(false);
                    dispatch(TableReload('institution_health_marketing_manager_thematic_thematic_library_resource_list'));
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    return (
        <>
            <TableComponent
                id={'institution_health_marketing_manager_thematic_thematic_library_resource_list'}
                title={'Ressources liée à la thématique :'}
                columns={columns}
                actionFirst={{
                    label: 'Associer des ressources',
                    onClick: () => {
                        setShow(true);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'institution_health_marketing_manager_thematic_thematic_library_resource_list',
                            params: { id: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                let idResources = [];
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);

                                    idResources.push(data[index].id);
                                }
                                handler.setValue('libraryResource', idResources);
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={show} maxWidth={'xl'} onClose={() => setShow(false)}>
                <DialogTitle style={{ fontSize: 17 }}>Associer des ressources</DialogTitle>
                <DialogContent style={{ minWidth: '70vw' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MultipleSelectComponent name={'libraryResource'} handler={handler} options={optionLibraryResources} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => setShow(false)} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={save} label={'Enregistrer'} />
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'institution_health_marketing_manager_thematic_thematic_library_resource_delete',
                        params: { id: props.match.params.id, libraryResource: actionIdLibraryResource }
                    },
                        (response) => {

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Relation thématique supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('institution_health_marketing_manager_thematic_thematic_library_resource_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </>
    )
}
