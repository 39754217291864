import React from 'react';
import ContentViewComponent from "../../Component/ContentViewComponent";
import ShadowBoxComponent from "../../Component/ShadowBoxComponent";
import TitleComponent from "../../Component/TitleComponent";
import {makeStyles} from "@material-ui/core";

function Update(props) {
    const classes = useStyles();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Mises à jour',
            context: 'À propos de l\'ERP',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 9 février janvier 2022'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Intégration du module de phoning.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 6 février janvier 2022'}/>

                <p className={classes.subTitle}>Gestion financière</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement du module "Gestion financière".</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 12 janvier 2022'}/>

                <p className={classes.subTitle}>Apprenant</p>
                <ul type="square" className={classes.list}>
                    <li>Intégration des classe virtuelle.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 3 janvier 2022'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Se connecter en tant que.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 1 janvier 2022'}/>

                <p className={classes.subTitle}>Général</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement de la version 2 de l'ERP.</li>
                    <li>Partie apprenant.</li>
                    <li>Formation SFP.</li>
                    <li>Formation GMS.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 6 octobre 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Correction des commentaire dans les hotlines.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 1 octobre 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Correction du cache de la liste des contacts.</li>
                </ul>
                <p className={classes.subTitle}>Investisseur</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement du tableau de bord investisseur.</li>
                </ul>

            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 24 septembre 2021'}/>

                <p className={classes.subTitle}>Contenu</p>
                <ul type="square" className={classes.list}>
                    <li>Ajout de la gestion des PDFs.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 22 septembre 2021'}/>

                <p className={classes.subTitle}>Contenu</p>
                <ul type="square" className={classes.list}>
                    <li>Ajout des moteurs et des cours.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 28 juillet 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Correction inscription.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 27 juillet 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Option de suppression d'une inscription contact.</li>
                    <li>Possibilité d'afficher les sessions passées sur le formulaire d'inscription contact.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 21 juin 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Gestion ANDPC par organisme.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 18 juin 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Amélioration de la recherche des contacts.</li>
                    <li>Export excel des la liste des contacts.</li>
                    <li>Réinscription disponible.</li>
                    <li>Fusion des contacts.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 8 juin 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Export base prospects sous Excel.</li>
                    <li>Validation de la disponibilité des prospects.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 4 juin 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Gestion des hotlines.</li>
                    <li>Gestion des inscriptions gratuites.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 6 mars 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Les contacts peuvent être sur plusieurs organismes différents sans créer de conflits de doublon.</li>
                    <li>L'organismes GMS est dorénavant disponible.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 2 mars 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Automatisation des désassignations des prospects sans activité pendant 15 jours.</li>
                    <li>Ajout de filtres dans la recherche des contacts.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 17 février 2021'}/>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Importation des prospects dans la base de données.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 11 février 2021'}/>
                <p className={classes.subTitle}>Général</p>
                <ul type="square" className={classes.list}>
                    <li>Migration de l'ERP vers nos Datacenters.</li>
                </ul>

                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Modification de la liste des sessions, toutes sessions qui ont la date de début inférieur à la date actuelle ne seront plus affichées.</li>
                    <li>Le nommage du bouton "annulation" d'une inscription comptabilisé devient "désinscription".</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 1 février 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement de la version 1.05.</li>
                    <li>La note d'un contact passe de 255 à 2000 caractères.</li>
                    <li>Les états du contact change en "A traiter", "Préinscription", "Budget à compléter", "Budget consommé".</li>
                    <li>Possibilité d'annuler une inscription seulement comptabilisée.</li>
                    <li>Débug de la liste des commercials du contact après un changement de pôle.</li>
                    <li>Récupération des dernières mises à jour de TeamLeader.</li>
                    <li>Blocage des inscriptions selon leurs dates de comptabilisations.</li>
                    <li><span className={classes.bold}>Bêta</span> - Tableau de bord commercial.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 29 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Débug des pré-incription sur l'ajout de session.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 27 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Ajout du pôle inscription Automatique pour les contacts autonomes.</li>
                    <li>Possibilité d'annuler une (pré-)inscription.</li>
                    <li>Affichage du code ANDPC sur la liste des formations des inscriptions.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 24 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Ajout des filtres commercial et état sur la liste des contacts.</li>
                    <li>Les admins peuvent changer le commercial d'un contact.</li>
                    <li>Possibilité de pré-inscription un contact.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 20 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Débug de la recherche sur la liste des contacts.</li>
                    <li>Ajout du champ mobile sur les contacts et ré-importation de cette information depuis TeamLeader.</li>
                    <li>Changement de l'acronyme OGDPC en ANDPC.</li>
                    <li>Accès à tous les contacts de son pôle pour chaque commercial. <br/>De ce fait "Mon portefeuille" devient "Liste des contacts".</li>
                    <li>Changement de l'affichage de la liste des sessions.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 17 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Importation des clients depuis TeamLeader.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 16 janvier 2021'}/>
                <p className={classes.subTitle}>CRM</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement du CRM.</li>
                    <li>Gestion des sessions formation.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 11 janvier 2021'}/>
                <p className={classes.subTitle}>Administrateur</p>
                <ul type="square" className={classes.list}>
                    <li>Gestion des organismes.</li>
                    <li>Gestion des pôles.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 15 décembre 2020'}/>
                <p className={classes.subTitle}>Administrateur</p>
                <ul type="square" className={classes.list}>
                    <li>Gestion des utilisateurs.</li>
                </ul>
                <p className={classes.subTitle}>Utilisateur</p>
                <ul type="square" className={classes.list}>
                    <li>Authentification utilisateur.</li>
                    <li>Mot de passe oublié.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 30 novembre 2020'}/>
                <p className={classes.subTitle}>Reporting Marketing</p>
                <ul type="square" className={classes.list}>
                    <li>Gestion des campagnes.</li>
                    <li>Gestion des campagnes type.</li>
                </ul>
            </ShadowBoxComponent>

            <ShadowBoxComponent className={classes.shadowBox}>
                <TitleComponent title={'Le 10 novembre 2020'}/>
                <p className={classes.subTitle}>Reporting Marketing</p>
                <ul type="square" className={classes.list}>
                    <li>Déploiement du Reporting Marketing.</li>
                </ul>
            </ShadowBoxComponent>

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        marginBottom: 20
    },
    subTitle: {
        marginLeft: 14,
        fontWeight: 900
    },
    list: {
        fontSize: 14
    },
    bold: {
        fontWeight: 900
    }
});

export default Update;
