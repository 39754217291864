import React from 'react';
import { theme } from '../../../App';
import { useDispatch } from 'react-redux';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { Slide, makeStyles } from '@material-ui/core';
import { getRoutePathname } from '../../../Config/Route';
import TabsComponent from '../../../Component/TabsComponent';
import Activity from './Activity';
import Action from './Action';
import Registrations from './Registrations';
import Info from './Info';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import Api from '../../../Api';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { TableReload } from '../../../Action/TableAction';
import InvoiceV2 from './InvoiceV2';
import CreditV2 from './CreditV2';

function BillingV2(props) {

    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [sessionFormation, setSessionFormation] = React.useState({});
    const [reloadList, setReloadList] = React.useState(false);
    const [showInvoice, setShowInvoice] = React.useState(false);
    const [showCredit, setShowCredit] = React.useState(null);

    // SUPER VISION
    const [countSuperVision, setCountSuperVision] = React.useState(0);
    const [isSuperVision, setIsSuperVision] = React.useState(false);
    const superVision = () => {
        let count = countSuperVision + 1;
        if (count === 1) {
            setTimeout(() => {
                setCountSuperVision(0);
            }, 2000);
        }
        if (count === 3) {
            setIsSuperVision(!isSuperVision);
            setCountSuperVision(0);
            setReloadList(!reloadList);
        } else {
            setCountSuperVision(count);
        }
    };

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'financial_management_billing_v2_session_formation_get',
            params: { id: props.match.params.id }
        },
            (response) => {
                if (response.status === 200) {
                    setSessionFormation(response.data);
                    dispatch(TableReload('financial_management_billing_v2_registration_list'));
                    dispatch(TableReload('financial_management_billing_v2_session_formation_activity_list'));
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('financial_management_billing_v2_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    }, [reloadList]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Facturation V2',
            context: 'Gestion financière',
            description: 'Session formation.',
            links: [
                { path: getRoutePathname('financial_management_billing_v2_list'), label: 'Liste des sessions formation' }
            ]
        });
        setReadyContent(true);
    }, []);
    return (
        <ContentViewComponent className={classes.contentView} loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Slide direction="left" in={isSuperVision} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div hidden={!isSuperVision} style={{
                    position: 'absolute',
                    top: 38,
                    background: '#dd3444ad',
                    right: 26,
                    padding: 5,
                    color: '#FFF',
                    borderRadius: 5,
                }}>Supervision
                </div>
            </Slide>
            {(sessionFormation && sessionFormation.state) && (
                <>
                    {
                        (() => {
                            let chipColor = '#28a745';
                            switch (sessionFormation.state) {
                                case 1:
                                    chipColor = '#007bff';
                                    break;
                                case 2:
                                    chipColor = '#28a745';
                                    break;
                                case 3:
                                    chipColor = '#db770a';
                                    break;
                                case 4:
                                    chipColor = '#9c27b0';
                                    break;
                            }
                            return (
                                <span onClick={superVision} style={{ background: chipColor }} className={classes.chip}>{sessionFormation.stateText}</span>
                            );
                        })()
                    }
                </>
            )}

            <TabsComponent
                tabDefault={'general'}
                style={{ boxShadow: 'none', borderRadius: 0 }}
                tabs={[
                    { id: 'general', label: 'Général' },
                    { id: 'registrations', label: 'Détail des inscriptions' },
                    { id: 'action', label: 'Action' },
                    { id: 'activity', label: 'Historique' },
                ]}
            >
                <div key={'general'}>
                    <Info sessionFormation={sessionFormation} reloadList={() => setReloadList(!reloadList)} {...props} />
                </div>
                <div key={'registrations'}>
                    <Registrations sessionFormation={sessionFormation} isSuperVision={isSuperVision} reloadList={() => setReloadList(!reloadList)} {...props} />
                </div>
                <div key={'action'}>
                    <Action sessionFormation={sessionFormation} reloadList={() => setReloadList(!reloadList)} openInvoice={() => setShowInvoice(!showInvoice)} openCredit={(val) => setShowCredit(val)} {...props} />
                </div>
                <div key={'activity'}>
                    <Activity sessionFormation={sessionFormation} reloadList={() => setReloadList(!reloadList)} {...props} />
                </div>
            </TabsComponent>

            {showInvoice && <InvoiceV2 sessionFormation={sessionFormation} open={showInvoice} reloadList={() => setReloadList(!reloadList)} close={() => setShowInvoice(false)} />}
            {showCredit && <CreditV2 open={showCredit} reloadList={() => setReloadList(!reloadList)} close={() => setShowCredit(null)} />}

        </ContentViewComponent>
    );
};

const useStyles = (theme) => makeStyles(style => ({
    contentView: {
        padding: 2
    },
    chip: {
        padding: 5,
        position: 'absolute',
        zIndex: 1,
        color: '#fff',
        right: 15,
        top: 130,
        fontSize: 13
    }
}));

export default BillingV2;