import React from 'react';
import {makeStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

function MultipleSelectComponent(props = {
    name: '',
    disabled: false,
    handler: {
        name: '',
        label: '',
        textHelper: '',
        type: '',
        defaultValue: '',
        value: '',
        error: '',
        options: {}
    },
    options: []
}) {
    const classes = useStyles();
    const data = props.handler.form[props.name];
    const [value, setValue] = React.useState(data.value ? data.value : []);
    const [error, setError] = React.useState(data.error);
    const [isFocus, setFocus] = React.useState(false);
    const disabled = data.loading || data.disabled;

    const onChange = (event) => {
        setValue(event.target.value);
        let newForm = {...props.handler.form};
        newForm[props.name].value = event.target.value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    }
    const onBlur = () => {
        setFocus(false);
        let newForm = {...props.handler.form};
        newForm[props.name].value = value;
        newForm[props.name].error = props.handler.getErrorByField(newForm[props.name]);
        setError(newForm[props.name].error);
        props.handler.set(newForm);
    }
    const onFocus = () => {
        setFocus(true);
    }

    React.useEffect(() => {
        setError(props.handler.form[props.name].error)
    }, [props.handler.form[props.name]].error);
    React.useEffect(() => {
        if (!props.handler.form[props.name].value && value || props.handler.form[props.name].value && props.handler.form[props.name].value !== value) {
            setValue(props.handler.form[props.name].value ? props.handler.form[props.name].value : []);
        }
    }, [props.handler.form[props.name].value]);

    return (
        <FormControl variant="outlined"
                     className={`
                        ${classes.multipleSelect}
                        ${isFocus ? classes.multipleSelectFocus : error ? classes.multipleSelectError : (value && value.length > 0) && !(disabled || props.disabled) ? classes.multipleSelectValid : ''}
                    `}
        >
            <InputLabel htmlFor="outlined">{data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}</InputLabel>
            <Select
                labelId={data.name}
                multiple
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                autoFocus={false}
                inputProps={{name: data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"},
                    className: classes.menu
                }}
                label={data.label + ((data.options && data.options.validation && data.options.validation.indexOf('required') > -1) ? ' *' : '')}
                disabled={disabled || props.disabled}
            >
                {
                    props.options.map(data => (
                        <MenuItem key={data.value} value={data.value}>
                            {data.label}
                        </MenuItem>
                    ))
                }
            </Select>
            <FormHelperText>{error && !isFocus ? error : data.textHelper}</FormHelperText>
        </FormControl>
    );
}

const useStyles = makeStyles({
    multipleSelect: {
        width: '100%',
        marginTop: 0,
        '& .MuiSelect-select': {
            padding: '10px 14px',
            textAlign: 'start',
            fontSize: 12
        },
        '& .MuiSelect-select:focus': {
            background: '#FFFFFF'
        },
        '& .MuiOutlinedInput-root': {
            fontSize: 13
        },
        '& .MuiInputLabel-outlined': {
            transform: 'translate(13px, 10px) scale(1)',
            fontSize: 13
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(15px, -4px) scale(0.75)',
        },
        '& .MuiFormHelperText-root': {
            margin: '2px 14px',
            fontSize: 10,
            height: 10,
            lineHeight: '10px'
        },
        '& fieldset': {
            borderWidth: '1px !important'
        },
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0
        }
    },
    multipleSelectFocus: {
        '& fieldset': {
            borderColor: '#3F51BF !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#3F51BF !important'
        },
        '& label': {
            color: '#3F51BF !important'
        }
    },
    multipleSelectValid: {
        '& fieldset': {
            borderColor: '#008000 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#008000 !important'
        },
        '& label': {
            color: '#008000 !important'
        }
    },
    multipleSelectError: {
        '& fieldset': {
            borderColor: '#982525 !important'
        },
        '& .MuiFormHelperText-root': {
            color: '#982525 !important'
        },
        '& label': {
            color: '#982525 !important'
        }
    },
    menu: {
        '& li': {
            fontSize: 12,
            padding: '4px 10px',
            lineHeight: '16px',
            minHeight: '16px'
        }
    }
});

export default MultipleSelectComponent;
