import React from 'react';
import Api from '../../../Api';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { makeStyles, IconButton, Grid, DialogTitle, DialogContent, DialogActions, Dialog, Typography, Popover, Fade, Box } from "@material-ui/core";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import TableComponent from "../../../Component/TableComponent";
import SelectComponent from "../../../Component/SelectComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import CarouselComponent from "../Component/CarouselComponent";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TableReload } from "../../../Action/TableAction";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import {
    LinearProgressMainLayoutActivate,
    LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Tooltip from "@material-ui/core/Tooltip";
import { Delete } from "@material-ui/icons";
import SweetAlert from "sweetalert-react";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";

const LoadingAnimation = () => {
    const classes = useStyles();
    return (
        <div className={classes.loading + ' root-Loading'}>
            <div class="one"></div>
            <div class="two"></div>
            <div class="three"></div>
            <div class="four"></div>
            <div class="five"></div>
        </div>
    )
}

const ViewAction = ({ data, setAddAction, editAction, setDialog, setIsEdition, setShowConfirm, setActionRow, setNewList }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={{ margin: '0 0 0 auto', }} >
            <IconButton style={{ padding: 5 }} onClick={handleClick}>
                <MoreVertIcon style={{ color: "#5E6E82" }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography style={{ padding: 8 }}>
                    <IconButton className={classes.buttonStyle} onClick={() => {
                        setAddAction('supervisor');
                        setIsEdition(false);
                        editAction(data);
                        setDialog(true);
                        setNewList.setOptionSubordinates(setNewList.newData);
                        setNewList.setIsSupervisor(true);
                    }}>
                        <PersonAddIcon style={{ width: 'auto', height: 15, padding: '0 5px' }} /> <span> Ajouter des employés</span>
                    </IconButton>
                    <IconButton className={classes.buttonStyle} color={'#dc3545'} onClick={() => { setAddAction('supervisor'); setActionRow(data); setShowConfirm(true); }}>
                        <Delete style={{ width: 'auto', height: 15, padding: '0 5px' }} /> <span> Supprimerle superviseur</span>
                    </IconButton>
                </Typography>
            </Popover>
        </div>
    )
}

export default function EmployeeRelationship(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const dispatch = useDispatch();

    const [dialog, setDialog] = React.useState(false);
    const [directorDialog, setDirectorDialog] = React.useState(false);
    const [dialogSupervisor, setDialogSupervisor] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loadingRegister, setLoadingRegister] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(true);
    const [loading, setLoading] = React.useState(true);

    const [optionPoles, setOptionPoles] = React.useState([]);
    const [poleId, setPoleId] = React.useState(null);
    const [optionUsers, setOptionUsers] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [organismId, setOrganismId] = React.useState(null);
    const [actionRow, setActionRow] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);


    const [supervisor, setSupervisor] = React.useState([]);
    const [employe, setEmploye] = React.useState([]);
    const [director, setDirector] = React.useState();

    const [optionEmploye, setOptionEmploye] = React.useState([]);
    const [optionSubordinates, setOptionSubordinates] = React.useState([])

    const [addAction, setAddAction] = React.useState('employe');
    const [isEdition, setIsEdition] = React.useState(false);
    const [employeData, setEmployeData] = React.useState([]);
    const [isSupervisor, setIsSupervisor] = React.useState(true)

    const [formDirector, setFormDirector] = React.useState({
        director: {
            name: 'director',
            label: 'Directeur',
            textHelper: 'Selectionner un directeur',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subDirector: {
            name: 'subDirector',
            label: 'Sous directeur',
            textHelper: 'Selectionner un sous directeur',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const [formSupervisor, setFormSupervisor] = React.useState({
        subordinates: {
            name: 'subordinates',
            label: 'Superviseur',
            textHelper: "Séléctionner des superviseurs",
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const [form, setForm] = React.useState({
        supervisor: {
            name: 'supervisor',
            label: 'Superviseur',
            textHelper: 'Selectionner votre supperviseur',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subordinates: {
            name: 'subordinates',
            label: 'Employé(e)s',
            textHelper: "Formez l'équipe du superviseur",
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });

    const handlerDirector = formHandlerInit(formDirector, setFormDirector);
    const handlerSupervisor = formHandlerInit(formSupervisor, setFormSupervisor);
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handlerDirector.start, []);
    React.useEffect(handlerSupervisor.start, []);
    React.useEffect(handler.start, []);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "subordinate",
            label: "Employé(e)",
            options: { filter: true, sort: true }
        },
        {
            name: "supervisor",
            label: "Superviseur",
            options: { filter: true, sort: true }
        },
        {
            name: "pole",
            label: "Pole",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ]

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const saveDirector = () => {
        if (handlerDirector.checkError()) {
            console.log('Error');
        } else {
            handlerDirector.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoadingRegister(true);
            if ((director && director.length === 0)) {
                const data = handlerDirector.getData()
                data.organism = organismId;
                Api.post({
                    route: 'human_resources_organism_director_add',
                    data: data
                }, (response) => {
                    handlerDirector.setFormLoading(false);
                    handlerDirector.reset();
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        setDirectorDialog(false);
                        getLoadingData()
                        setLoadingRegister(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Le directeur a été ajouter a cette organisme.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerDirector.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'human_resources_organism_director_edit',
                    params: { organism: organismId },
                    data: handlerDirector.getData()
                }, (response) => {
                    handlerDirector.setFormLoading(false);
                    handlerDirector.reset();
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        setDirectorDialog(false);
                        getLoadingData()
                        setLoadingRegister(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Le directeur a été modifier.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handlerDirector.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const saveSupervisor = () => {
        if (handlerSupervisor.checkError()) {
            console.log('Error');
        }
        else {
            setLoadingRegister(true);
            handlerSupervisor.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            const data = handlerSupervisor.getData();
            data.hierarchie = addAction;
            data.director = director.id;
            Api.post({
                route: 'human_resources_relationship_add',
                params: { organism: organismId, pole: poleId },
                data: data
            }, (response) => {
                handlerSupervisor.setFormLoading(false);
                handlerSupervisor.reset();
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    setDialogSupervisor(false);
                    dispatch(TableReload('human_resources_employees_relationship'));
                    getLoadingData()
                    setLoadingRegister(false);
                    dispatch(
                        SnackbarOpen({
                            text: 'Superviseur ajouée avec succès.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handlerSupervisor.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        }
    };

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoadingRegister(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            const data = handler.getData()
            data.hierarchie = addAction;
            data.director = director.id;
            if (isEdition) {
                Api.post({
                    route: 'human_resources_relationship_edit',
                    params: { organism: organismId, pole: poleId },
                    data: data
                }, (response) => {
                    handler.setFormLoading(false);
                    handler.reset();
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        setDialog(false);
                        dispatch(TableReload('human_resources_employees_relationship'));
                        getLoadingData();
                        setLoadingRegister(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Équipe créée avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'human_resources_relationship_add',
                    params: { organism: organismId, pole: poleId },
                    data: data
                }, (response) => {
                    handler.setFormLoading(false);
                    handler.reset();
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        setDialog(false);
                        dispatch(TableReload('human_resources_employees_relationship'));
                        getLoadingData()
                        setLoadingRegister(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Équipe créée avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const editAction = (data) => {
        if (data) {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'human_resources_relationship_get',
                params: { organism: organismId, id: data.id }
            },
                (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }

    const getLoadingData = () => {
        setLoadingData(true);
        Api.get({
            route: 'human_resources_organism_director_get',
            params: { organism: organismId }
        },
            (response) => {
                setLoadingData(false);
                if (response.status === 200) {
                    let data = response.data;
                    setDirector(data)
                }
                else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error,
                            variant: 'error',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        dispatch(TableReload('human_resources_employees_relationship'));

        Api.get({
            route: 'human_resources_relationship_list',
            params: { organism: organismId, pole: poleId }
        },
            (response) => {
                if (response.status === 200) {
                    let data = response.data;
                    setSupervisor(data.supervisors);
                    setEmploye(data.subordinates);
                }
                else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error,
                            variant: 'error',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

    }

    const [existDirectorId, setDirectorId] = React.useState()
    const [existSubDirectorId, setSubDirectorId] = React.useState()

    const existDirector = (value) => {
        if (handlerDirector.getData().subDirector === value) {
            handlerDirector.setValue('subDirector', existDirectorId)
        }
        if (handlerDirector.getData().director === value) {
            handlerDirector.setValue('director', existSubDirectorId)
        }
    }

    React.useEffect(() => {
        setDirectorId(handlerDirector.getData().director);
    }, [handlerDirector.getData().director]);
    React.useEffect(() => {
        setSubDirectorId(handlerDirector.getData().subDirector);
    }, [handlerDirector.getData().subDirector]);

    const updatedEmploye = () => {
        return [
            ...employe,
            ...employeData.filter(item => employe.some(existingItem => existingItem.subordinate != item.subordinate))
        ]
    };

    const filterUsers = (data) => {
        const mergedArray = data.reduce((accumulator, currentItem) => {
            if (!accumulator.some(item => item.label === currentItem.label)) {
                accumulator.push(currentItem);
            }
            return accumulator;
        }, []);
        return optionUsers.filter(item => !mergedArray.some(removeItem => removeItem.label === item.label));
    }

    React.useEffect(() => {
        if (organismId) getLoadingData();
    }, [organismId, poleId]);

    React.useEffect(() => {
        setLoading(true)
        if (organismId) {
            Api.get({
                route: 'select_internal_poles',
                data: { organism: organismId }
            },
                (response) => {
                    setLoading(false)
                    let data = response.data;
                    setOptionPoles(data);
                });
        }
    }, [organismId]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'List des relations superviseurs / employé(e)s',
            context: 'Ressources Humaine',
            description: ''
        });

        Api.get({
            route: 'select_organisms',
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganisms(response.data);
                }
            });

        Api.get({
            route: 'select_users',
        },
            (response) => {
                let data = response.data;
                setOptionEmploye(data)
                setOptionSubordinates(data)
                setOptionUsers(data);
            });
    }, [])

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={false} breadcrumbs={breadcrumbs} {...props}>
                <Grid container spacing={2} style={{ height: '95%', margin: 'auto', width: '100%' }}>
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ display: 'grid', gap: 14, gridTemplateRows: 'min-content auto', height: '100%' }}>
                            <ShadowBoxComponent>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <CarouselComponent
                                            options={optionOrganisms}
                                            getValue={(val) => setOrganismId(val)}
                                            loop={false}
                                            error={'Aucun organisme a été ajouter.'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                            <p style={{ fontSize: 20, margin: 'auto' }}>
                                                {(director && director.length != 0) ?
                                                    <>
                                                        <span style={{ fontSize: 15, transform: 'translate(0, 20%)', display: 'block' }}>Directeur:</span>
                                                        <b>{director.directorName}</b><br />
                                                        <span style={{ fontSize: 13, transform: 'translate(0, -10%)', display: 'block' }}>
                                                            Sous directeur: <b>{director.subdirectorName}</b>
                                                        </span>
                                                    </> : <span style={{ fontSize: 15, textAlign: 'center', display: 'block' }}>Aucun directeur n'a été asigné a cette organisme.</span>
                                                }
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ padding: 10 }}>
                                        <ButtonComponent style={{ margin: 'auto', display: 'block' }} disabled={loadingData} label={(director && director.length != 0) ? 'Modifier' : 'Ajouter un directeur'}
                                            onClick={() => {
                                                handlerDirector.setDataApi(director);
                                                setDirectorDialog(true);
                                                const newdata = filterUsers([...supervisor, ...updatedEmploye()]);
                                                setOptionSubordinates(newdata);
                                            }} />
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                            <Box style={{ position: 'relative' }}>
                                <ShadowBoxComponent style={{ display: 'grid', gap: 14, gridTemplateRows: 'min-content auto min-content', overflow: 'hidden', position: 'absolute', height: '100%', width: '100%' }}>
                                    <div className={classes.CarouselStyle}>
                                        <Fade in={true} {...{ timeout: 1000 }}>
                                            <CarouselComponent
                                                options={optionPoles}
                                                getValue={(val) => setPoleId(val)}
                                                loop={false}
                                                loading={loading}
                                                error={'Aucun pôle pour cette organisme.'}
                                            />
                                        </Fade>
                                        <hr style={{ color: 'rgb(94, 110, 130)', width: '80%', margin: '5px auto' }} />
                                        <p style={{ margin: ' auto', textAlign: 'center' }}><b>Superviseur du pole</b></p>
                                    </div>
                                    <div style={{ overflow: 'hidden auto', height: '100%' }}>
                                        {loadingData || loading ?
                                            <LoadingAnimation /> :
                                            <Fade in={true} {...{ timeout: 1000 }}>
                                                <div>
                                                    {(supervisor.length != 0 && director) ? supervisor.map((supervisorData) => (
                                                        <div style={{ padding: '5px 10px', border: ' 1px solid', borderRadius: 10, margin: 10, display: 'flex', alignItems: 'center', }}>
                                                            <p style={{ margin: 0, fontSize: 15 }}><b>{supervisorData.subordinate}</b></p>
                                                            <ViewAction
                                                                data={supervisorData}
                                                                setAddAction={setAddAction}
                                                                editAction={editAction}
                                                                setDialog={setDialog}
                                                                setIsEdition={setIsEdition}
                                                                setShowConfirm={setShowConfirm}
                                                                setActionRow={setActionRow}
                                                                setNewList={{
                                                                    newData: filterUsers((director.input && supervisor) ? [...director.input, ...supervisor, ...updatedEmploye()] : []),
                                                                    setOptionSubordinates,
                                                                    setIsSupervisor
                                                                }}
                                                            />
                                                        </div>
                                                    )) : <p style={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>Aucun résultat.</p>}
                                                </div>
                                            </Fade>
                                        }
                                    </div>
                                    <div>
                                        <ButtonComponent
                                            style={{ margin: 'auto', display: 'block' }}
                                            disabled={((director && director.length != 0) ? false : true) || loadingData || (optionPoles.length === 0)} label={'Ajouter un supervisor'}
                                            onClick={() => {
                                                setAddAction('director');
                                                setDialogSupervisor(true);
                                                const newdata = filterUsers((director.input && supervisor) ? [...director.input, ...supervisor, ...updatedEmploye()] : []);
                                                setOptionSubordinates(newdata);
                                            }}
                                        />
                                    </div>
                                </ShadowBoxComponent>
                            </Box>
                        </Grid>
                    </Fade>
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <ShadowBoxComponent style={{ height: ((screenReducer.screen === 'LG') || (screenReducer.screen === 'XL')) ? '100%' : '500px', display: 'grid', gridTemplateRows: 'min-content auto', }}>
                                <div>
                                    <p style={{ margin: ' auto', textAlign: 'center' }}><b>Employé(e)</b></p>
                                    <hr style={{ margin: '12px 0 0 0' }} />
                                </div>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ overflow: 'hidden auto', position: 'absolute', height: '100%', width: '100%' }}>
                                        {loadingData || loading ?
                                            <LoadingAnimation /> :
                                            <Fade in={true} {...{ timeout: 1000 }}>
                                                <div>
                                                    {(employe.length != 0 && director) ? employe.map((employeData) => (
                                                        <div style={{ padding: '5px 10px', border: ' 1px solid', borderRadius: 10, margin: 10, display: 'flex', alignItems: 'center', }}>
                                                            <div>
                                                                <p style={{ margin: 0, fontSize: 15 }}><b>{employeData.subordinate}</b></p>
                                                                <p style={{ margin: 0, fontSize: 12 }}><b>superviseur : </b>{employeData.supervisor}</p>
                                                            </div>
                                                            <Tooltip title={'Supprimer'} placement="left">
                                                                <IconButton style={{ margin: '0 0 0 auto', }} disabled={loadingData || loading} onClick={() => { setActionRow(employeData); setShowConfirm(true); }}>
                                                                    <Delete style={{ width: 'auto', height: 20, color: '#5E6E82' }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    )) : <p style={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>Aucun résultat.</p>}
                                                </div>
                                            </Fade>
                                        }
                                    </div>
                                </div>
                            </ShadowBoxComponent>
                        </Grid>
                    </Fade>
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.TableComponent}>
                            <TableComponent
                                id={'human_resources_employees_relationship'}
                                title={'Relation entre Employé(e)'}
                                columns={columns}
                                actionFirst={{
                                    disabled: (loadingData || (employe.length === 0) || loading),
                                    label: 'Ajouter une relation',
                                    onClick: () => {
                                        setAddAction('employe');
                                        setDialog(true);
                                        const newdata = (director.input && supervisor) ? [...director.input, ...supervisor, ...updatedEmploye()] : [];
                                        setOptionSubordinates(filterUsers(newdata));
                                        setOptionEmploye(updatedEmploye());
                                        setIsSupervisor(false);
                                    }
                                }}
                                promiseData={(resolve) => {
                                    if (organismId && poleId) {
                                        Api.get({
                                            route: 'human_resources_relationship_list',
                                            params: { organism: organismId, pole: poleId }
                                        },
                                            (response) => {
                                                let data = response.data.employes;
                                                for (let index in data) {
                                                    data[index].action = getAction(data[index]);
                                                }
                                                setEmployeData(data);
                                                resolve(data);
                                            });
                                    } else {
                                        setEmployeData([]);
                                        resolve([]);
                                    }
                                }}
                            />
                        </Grid>
                    </Fade>
                </Grid>

                <Dialog open={directorDialog} maxWidth={'xl'} onClose={() => { handlerDirector.reset(); setDirectorDialog(false) }}>
                    <DialogTitle style={{ fontSize: 15 }}>{(director && director.length != 0) ? 'Ajouter le directeur' : 'Ajouter un directeur'}</DialogTitle>
                    <DialogContent style={{ minWidth: '30vw' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectAutocompleteComponent name={'director'} options={optionSubordinates} handler={handlerDirector} onChange={(value) => {
                                    setDirectorId(value);
                                    existDirector(value);
                                }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectAutocompleteComponent name={'subDirector'} options={optionSubordinates} handler={handlerDirector} onChange={(value) => {
                                    setSubDirectorId(value);
                                    existDirector(value);
                                }} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handlerDirector.reset(); setDirectorDialog(false) }} />
                        <ButtonComponent label={'Enregistrer'} onClick={saveDirector} />
                    </DialogActions>
                </Dialog>

                <Dialog open={dialogSupervisor} maxWidth={'xl'} onClose={() => { handlerSupervisor.reset(); setDialogSupervisor(false) }}>
                    <DialogTitle style={{ fontSize: 15 }}>{'Ajouter un superviseur'}</DialogTitle>
                    <DialogContent style={{ minWidth: '30vw' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MultipleSelectComponent name={'subordinates'} options={optionSubordinates} handler={handlerSupervisor} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handlerSupervisor.reset(); setDialogSupervisor(false) }} />
                        <ButtonComponent label={'Enregistrer'} onClick={saveSupervisor} />
                    </DialogActions>
                </Dialog>

                <Dialog open={dialog} maxWidth={'xl'} onClose={() => { handler.reset(); setDialog(false) }}>
                    <DialogTitle style={{ fontSize: 15 }}>{addAction === 'supervisor' ? 'Ajouter des employés' : 'Ajouter des relations entre employé'}</DialogTitle>
                    <DialogContent style={{ minWidth: '30vw' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectAutocompleteComponent name={'supervisor'} options={isSupervisor ? supervisor : optionEmploye} handler={handler} disabled={(addAction === 'supervisor') || isEdition} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <MultipleSelectComponent name={'subordinates'} options={optionSubordinates} handler={handler} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => { handler.reset(); setDialog(false) }} />
                        <ButtonComponent label={'Enregistrer'} onClick={save} />
                    </DialogActions>
                </Dialog>

                <SweetAlert
                    show={showConfirm}
                    title={'Supprimer'}
                    text={
                        (actionRow.supervisor ? 'Superviseur : ' + actionRow.supervisor : 'Directeur : ' + actionRow.director) +
                        '\nEmployé(e) : ' + actionRow.subordinate + '\nÊtes-vous sur de vouloir supprimer cet relation' + (actionRow.supervisor ? ' superviseur ' : ' directeur ') + 'employé(e) dans le pôle (' + actionRow.pole + ') ?'}
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        setShowConfirm(false);
                        setLoadingRegister(true);
                        Api.delete({
                            route: 'human_resources_relationship_delete',
                            params: { organism: organismId, pole: poleId, id: actionRow.id }
                        },
                            (response) => {
                                if (response.status === 200) {
                                    dispatch(TableReload('human_resources_employees_relationship'));
                                    getLoadingData();
                                    setLoadingRegister(false);
                                }
                                else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error,
                                            variant: 'error',
                                        })
                                    );
                                }
                                else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }}
                    onCancel={() => setShowConfirm(false)}
                />

            </ContentViewComponent>
        </Box>
    )
}


const useStyles = makeStyles({
    styleContentView: {
        height: '100%',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        },
        '& .root-carouselComponent .MuiIconButton-root': {
            padding: 5,
        }
    },
    TableComponent: {
        height: '100%',
        margin: 'auto',
        width: '100%',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
    },
    headingText: {
        marginBottom: '10px'
    },
    listText: {
        listStyleType: '- ',
        marginBottom: '20px'
    },
    footerText: {
        marginBottom: '10px'
    },
    buttonStyle: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        color: '#5E6E82',
        "& span": {
            justifyContent: 'left'
        }
    },
    CarouselStyle: {
        '& .root-Loading': {
            position: 'initial !important',
            transform: 'scale(47%) !important',
        }
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        width: '100%',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%) scale(47%)',
        '& div': {
            width: 40,
            height: 40,
            borderRadius: '50%',
            margin: 'auto',
            animation: '$loading 2.5s ease-in-out infinite',
        },
        '& div:nth-child(0)': {
            animationDelay: '0s',
        },
        '& div:nth-child(1)': {
            animationDelay: '0.2s',
        },
        '& div:nth-child(2)': {
            animationDelay: ' 0.4s',
        },
        '& div:nth-child(3)': {
            animationDelay: '0.6s',
        },
        '& div:nth-child(4)': {
            animationDelay: '0.8s',
        },
        '& div:nth-child(5)': {
            animationDelay: '1s',
        }
    },

    '@keyframes loading': {
        '0%, 100%': {
            transform: 'scale(0.2)',
            backgroundColor: '#5E6E82',
        },
        '42%': {
            backgroundColor: '#5E6E82',
        },
        '50%': {
            transform: 'scale(1)',
            backgroundColor: '#5E6E82',
        },
    }
});