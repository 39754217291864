import React from 'react'
import TableComponent from '../../../../Component/TableComponent';
import Api from '../../../../Api';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import {hasRight} from "../../../../Tool/UserTool";
import { Assignment } from "@material-ui/icons";
import { getRoutePathname } from "../../../../Config/Route";
import { useSelector } from "react-redux";
import {SnackbarOpen} from "../../../../Action/SnackbarAction";
import {dispatch} from "../../../../App";


export default function VirtualClassroomUser(props = {}) {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'lastname',
            label: 'Nom',
            options: { filter: true, sort: true }
        },
        {
            name: 'firstname',
            label: 'Prénom',
            options: { filter: true, sort: true }
        },
        {
            name: 'email',
            label: 'Email',
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "present",
            label: "Présence",
            options: { filter: true, sort: true }
        },
        //{
          //  name: "action",
          //  label: "Action",
          //  options: { filter: false, sort: false }
        //}
    ];

    //const getAction = (row) => {
    //    return (
    //        <>
    //            <Tooltip title={'Visualiser'} placement="left">
    //                <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', { id: row.id }))}>
    //                    <Assignment />
    //                </IconButton>
    //            </Tooltip>
    //        </>
    //    );
    //};

    const getPresence = (row) => {
        return (
            <>
                <Checkbox
                    key={row.id + '-' + new Date().getTime()}
                    defaultChecked={row.isPresent}
                    disabled={!hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')}
                    onChange={() => {
                        Api.post({
                          route: 'former_virtual_classroom_learner_present_edit',
                          params: {
                              id: props.match.params.id,
                              registration: row.registrationId
                          }
                        },
                          (response) => {
                            if (response.status === 200) {
                              if (response.data[0]) {
                                dispatch(
                                  SnackbarOpen({
                                    text: row.lastname + ' ' + row.firstname + ' est présent(e)',
                                    variant: 'success',
                                  })
                                );
                              } else {
                                dispatch(
                                  SnackbarOpen({
                                    text: row.lastname + ' ' + row.firstname + ' est absent(e)',
                                    variant: 'success',
                                  })
                                );
                              }
              
                            } else {
                              dispatch(
                                SnackbarOpen({
                                  text: "Une erreur s'est produite",
                                  variant: 'error',
                                })
                              );
                            }
                          })
                      }}
                />
            </>
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'institution_health_content_manager_virtual_classroom_user_list'}
                title={'Participants lié a cette classe virtuelle : '}
                columns={columns}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'institution_health_content_manager_virtual_classroom_user_list',
                            params: { virtualClassroom: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    //data[index].action = getAction(data[index]);
                                    data[index].present = getPresence(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />
        </>
    )
}
