import React from 'react';
import UploadComponent from "../../../Component/UploadComponent";
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import Api from "../../../Api";
import {inArray} from "../../../Tool/ArrayCommon";
import {useDispatch, useSelector} from "react-redux";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {TableReload} from "../../../Action/TableAction";
import {cacheTableServer} from "../../../Cache";
import SelectComponent from "../../../Component/SelectComponent";

function Import(props = {
    onClose: () => {
    }
}) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showInfo, setShowInfo] = React.useState(false);
    const [info, setInfo] = React.useState({});
    const [form, setForm] = React.useState({
        file: {
            name: 'file',
            label: 'Fichier d\'import',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {validation: ['required']}
        },
        poles: {
            name: 'poles',
            label: 'Pôles',
            textHelper: 'Choisissez les pôles des contacts importés.',
            type: 'array',
            defaultValue: [],
            options: {validation: ['required']}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'select_poles'
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });

        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });
    }, []);
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                    route: 'crm_prospect_import',
                    data: {poles: data.poles, organism: data.organism},
                    file: {file: data.file}
                },
                (response) => {
                    handler.setFormLoading(false)
                    setLoading(false);

                    if (response.status === 200) {
                        setInfo(response.data);
                        setShowInfo(true);
                        dispatch(TableReload('crm_prospect_list'))
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    return (
        <>
            {
                showInfo ? <>
                        <DialogTitle style={{fontSize: 15}}>Information sur l'importation des contacts</DialogTitle>
                        <DialogContent style={{boxSizing: 'border-box'}}>
                            <p>
                                <span style={{fontWeight: 900}}>{info.newProspect}</span> nouveaux contacts insérés dont <span style={{fontWeight: 900}}>{info.assignProspect}</span> assignés à des commerciaux.
                            </p>
                            <p>
                                <span style={{fontWeight: 900}}>{info.alreadyProspect}</span> contacts non insérés (déjà en base de données).
                            </p>
                            <p>
                                <span style={{fontWeight: 900}}>{info.failProspect}</span> contacts non insérés par manque d'information.
                            </p>
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Ok'} onClick={props.onClose}/>
                        </DialogActions>
                    </>
                    :
                    <>
                        <DialogTitle style={{fontSize: 15}}>Importation des contacts</DialogTitle>
                        <DialogContent style={{boxSizing: 'border-box'}}>
                            <UploadComponent name={'file'} handler={handler} accept=".xlsx,.xls,.ods"/>
                            <br/>
                            <MultipleSelectComponent name={'poles'} handler={handler} options={optionPoles}/>
                            <br/>
                            <SelectComponent name={'organism'} handler={handler} options={optionOrganisms}/>
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={props.onClose} disabled={loading}/>
                            <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading}/>
                        </DialogActions>
                    </>
            }
        </>
    );
}

export default Import;
