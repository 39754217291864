import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import TextFieldComponent from "../../../Component/TextFieldComponent";

function CreditFIFPL(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);

    // Form
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            let data = handler.getData();

            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            Api.post({
                    route: 'financial_management_billing_fifpl_invoice_credit',
                    params: {id: props.sessionFormation.lastInvoice},
                    data: data
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        dispatch(TableReload('billing_registration_list'));

                        dispatch(
                            SnackbarOpen({
                                text: 'Avoir à la Facturée créée.',
                                variant: 'success',
                            })
                        );

                        const url = Api.route({
                            route: 'financial_management_billing_fifpl_credit_download',
                            params: {id: response.data.id},
                        });
                        window.open(url, '_blank');
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    return (
        <>
            <Dialog open={props.open} maxWidth={'lg'}>
                <DialogTitle style={{fontSize: 15}}>Avoir</DialogTitle>
                <DialogContent>
                    <div style={{width: '70vw', maxWidth: 600, textAlign: 'center'}}>
                        Êtes-vous sur de vouloir générer un avoir à la facture ?<br/> <br/>
                        <span style={{fontWeight: 900}}>{props.sessionFormation.lastInvoice} </span><br/> <br/>
                        <TextFieldComponent name={'comment'} handler={handler} multiline={true}/> <br/> <br/>
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'}/>
                    <ButtonComponent disabled={loading} onClick={save} label={'Générer un avoir'}/>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CreditFIFPL;
