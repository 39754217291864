import React from 'react';
import Api from '../../../Api';
import ContentViewComponent from '../../../Component/ContentViewComponent';
import { makeStyles, Box, Fade } from "@material-ui/core";
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import CarouselComponent from "../Component/CarouselComponent";
import { useDispatch } from "react-redux";
import {
  LinearProgressMainLayoutActivate,
  LinearProgressMainLayoutDeactivate
} from "../../../Action/LinearProgressMainLayoutAction";
import OrgChartComponent from '../Component/OrgChartComponent';
const LoadingAnimation = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div class="one"></div>
      <div class="two"></div>
      <div class="three"></div>
      <div class="four"></div>
      <div class="five"></div>
    </div>
  )
}
function OrganisationChartHR(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [orgData, setOrgData] = React.useState(null);
  const [loading, setLoading] = React.useState(true)

  const [breadcrumbs, setBreadcrumbs] = React.useState({});

  const [organismId, setOrganismId] = React.useState(null);
  const [poleId, setPoleId] = React.useState(null);

  const [optionOrganisms, setOptionOrganisms] = React.useState([]);
  const [optionPoles, setOptionPoles] = React.useState([]);

  React.useEffect(() => {
    setBreadcrumbs({
      title: 'Organigramme hiérarchique',
      context: 'Ressources Humaine',
      description: '',
    });

    Api.get({
      route: 'select_organisms',
    },
      (response) => {
        if (response && response.data) {
          setOptionOrganisms(response.data);
        }
      });
  }, []);

  React.useEffect(() => {
    setLoading(true)
    if (organismId) {
      dispatch(LinearProgressMainLayoutActivate());
      Api.get({
        route: 'select_internal_poles',
        data: { organism: organismId }
      },
        (response) => {
          let data = response.data;
          setOptionPoles(data);
          setLoading(false)
        });
      Api.get({
        route: 'human_resources_organism_chart',
        params: { organism: organismId }
      },
        (response) => {
          if (response && response.data) {
            dispatch(LinearProgressMainLayoutDeactivate());
            setOrgData(response.data);
          }
        });
    }
  }, [organismId]);

  return (
    <Box className={classes.styleContentView}>
      <ContentViewComponent loading={false} breadcrumbs={breadcrumbs} {...props}>
        <Fade in={true} {...{ timeout: 1000 }}>
          <div style={{ position: 'relative' }}>
            <ShadowBoxComponent style={{ width: '100%', height: '90%', margin: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', }}>
              <Fade in={true} {...{ timeout: 1000 }}>
                <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'min-content auto' }}>
                  <div style={{ width: '100%', margin: 'auto' }}>
                    <div className={classes.CarouselStyle} style={{ width: 400, margin: 'auto', fontWeight: 'bold', }}>
                      <Fade in={true} {...{ timeout: 1000 }}>
                        <CarouselComponent
                          options={optionPoles}
                          getValue={(val) => setPoleId(val)}
                          loop={true}
                          loading={loading}
                          error={'Aucun pôle pour cette organisme.'}
                        />
                      </Fade>
                    </div>
                  </div>

                  <OrgChartComponent
                    className={classes.organizationChart}
                    data={(orgData && orgData.org && orgData.org[poleId]) && orgData.org[poleId]}
                    loading={loading}
                    action={poleId}
                  />

                  <div className={classes.bossesStyle}>
                    <CarouselComponent
                      options={optionOrganisms}
                      getValue={(val) => setOrganismId(val)}
                      loop={false}
                      error={'Aucun organisme a été ajouter.'}
                    />
                    <hr style={{ color: 'rgb(94, 110, 130)', width: '80%', margin: '5px auto' }} />
                    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <p style={{ fontSize: 20, margin: 'auto', width: '80%', }}>
                        {loading ? <LoadingAnimation /> :
                          (orgData && orgData.directors && orgData.directors.length != 0) ?
                            <>
                              <span style={{ fontSize: 15, transform: 'translate(0, 20%)', display: 'block' }}>Directeur:</span>
                              <b>{orgData.directors.directorName}</b><br />
                              <span style={{ fontSize: 13, transform: 'translate(0, -10%)', display: 'block' }}>
                                Sous directeur:
                                <br /><b>{orgData.directors.subdirectorName}</b>
                              </span>
                            </> : <span style={{ fontSize: 15, textAlign: 'center', display: 'block' }}>Aucun directeur n'a été asigné a cette organisme.</span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </Fade>
            </ShadowBoxComponent>
          </div>
        </Fade>
      </ContentViewComponent>
    </Box>
  )
}

const useStyles = makeStyles({
  organizationChart: {
    overflow: 'auto',
    height: '100%',
    '& div>ul': {
      padding: '10px 38px',
      '& >li>ul': {
        padding: 0
      },
      '& ul': {
        width: 'max-content',
      },
      '& li': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    }
  },
  fullHeightChart: {
    height: '100%',
  },
  styleContentView: {
    height: '100%',
    '& .ContentView-root': {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      '& .Breadcrumbs-root:nth-of-type(1)': {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        height: 'auto',
        '& div:nth-of-type(2)': {
          margin: '0 0 0 auto',
        }
      }
    }
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    margin: 'auto',
    gap: '20px',
    alignItems: 'center'
  },
  bosses: {
    display: 'flex',
    justifyContent: 'center',
  },
  poles: {
    display: 'flex',
  },
  bossesStyle: {
    position: 'absolute',
    width: 240,
    background: '#FFFFFFB8',
    border: '2px solid',
    borderRadius: 10,
    padding: 10,
    zIndex: 1,
    top: 10,
    left: 10,
    '& .root-carouselComponent': {
      fontWeight: 'bolder',
      '& button': {
        padding: 0,
      }
    },
  },
  CarouselStyle: {
    '& .root-Loading': {
      position: 'initial !important',
      transform: 'scale(47%) !important',
    }
  },
  loading: {
    display: 'flex',
    scale: '47%',
    '& div': {
      width: 40,
      height: 40,
      borderRadius: '50%',
      margin: 'auto',
      animation: '$loading 2.5s ease-in-out infinite',
    },
    '& div:nth-child(0)': {
      animationDelay: '0s',
    },
    '& div:nth-child(1)': {
      animationDelay: '0.2s',
    },
    '& div:nth-child(2)': {
      animationDelay: ' 0.4s',
    },
    '& div:nth-child(3)': {
      animationDelay: '0.6s',
    },
    '& div:nth-child(4)': {
      animationDelay: '0.8s',
    },
    '& div:nth-child(5)': {
      animationDelay: '1s',
    }
  },

  '@keyframes loading': {
    '0%, 100%': {
      transform: 'scale(0.2)',
      backgroundColor: '#5E6E82',
    },
    '42%': {
      backgroundColor: '#5E6E82',
    },
    '50%': {
      transform: 'scale(1)',
      backgroundColor: '#5E6E82',
    },
  }
});

export default OrganisationChartHR