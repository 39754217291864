import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit, Delete, SupervisorAccount} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import SweetAlert from 'sweetalert-react';
import {TableReload} from "../../../Action/TableAction";
import {getRoutePathname} from "../../../Config/Route";
import {AuthenticationLogin} from "../../../Action/AuthenticationAction";
import { SelectOrganism } from '../../../Action/SelectOrganismAction';

function UserList(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "createdAt",
            label: "Date de création",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Se connecter en tant que...'} placement="left">
                    <IconButton onClick={() => {
                        Api.post({
                            route: 'authentication_login',
                            data: {
                                email: row.email,
                                password: 'admin',
                                isAdmin: true
                            }
                        }, (response) => {
                            dispatch(AuthenticationLogin(response.data));
                            dispatch(SelectOrganism({ organism: {} }));
                        });
                    }}>
                        <SupervisorAccount/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        props.history.push(getRoutePathname('administrator_user', {id: row.id}));
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des utilisateurs',
            context: 'Administrateur',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'administrator_user_list'}
                title={'Utilisateurs'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un utilisateur',
                    onClick: () => props.history.push(getRoutePathname('administrator_user', {id: null}))
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'administrator_user_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].action = getAction(data[index]);
                            }

                            resolve(data);
                        });
                }}
            />

            <br/>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.firstname + ' ' + actionRow.lastname + '\nÊtes-vous sur de vouloir supprimer cet utilisateur ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                            route: 'administrator_user_delete',
                            params: {id: actionRow.id}
                        },
                        () => {
                            dispatch(TableReload('administrator_user_list'))
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}

export default UserList;
