import React from 'react';
import ParticlesComponent from '../../Component/ParticlesComponent';
import ShadowBoxComponent from '../../Component/ShadowBoxComponent';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { theme } from '../../App';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import Api from '../../Api';

function PaymentStripe(props) {
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(false);
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [clientSecret, setClientSecret] = React.useState(null);
    const [stripeInstance, setStripeInstance] = React.useState(null);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);
        }
    }, [screenReducer.screen]);

    React.useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const clientSecret = urlParams.get('client_secret');

        if (clientSecret) {
            setClientSecret(clientSecret);
        }
        else {
            setClientSecret(props.location.state.clientSecret);
        }


        Api.get({
            route: 'stripe_public_key_get',
            data: { organism: theme.id }
        },
            (response) => {
                if (response && response.data) {
                    loadStripe(response.data.publicKey).then((instance) => {
                        setStripeInstance(instance);
                    }).catch((error) => {
                        console.error("Error loading Stripe:", error);
                    });
                }
            });
    }, [props.location.state]);

    return (

        <div className={classes.content}>

            {!isSmallScreen && <ParticlesComponent loading={loading} />}
            <ShadowBoxComponent className={`${classes.shadowBoxBase} ${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`} id="checkout">
                    {stripeInstance && clientSecret && (
                        <EmbeddedCheckoutProvider stripe={stripeInstance} options={{ clientSecret }}>
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    )}
            </ShadowBoxComponent>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    shadowBoxBase: {
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        overflow: 'scroll'
    },
    shadowBox: {
        padding: 25,
        height: '90%',
        width: '90%'
    },
    shadowBoxSmall: {
        padding: 5,
        height: '100%',
        width: '100%'
    },
});

export default PaymentStripe;