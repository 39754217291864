import React from 'react';
import { makeStyles } from "@material-ui/core";
import CountUp from 'react-countup';
import ShadowBoxComponent from "./../../../../Component/ShadowBoxComponent";

function Card(props = {
    title: '',
    number: 100,
    percentage: 100,
    turnover: 100,
    icon: '',
    color: '',
    disabled: false
}) {
    const classes = useStyles();

    return (
        <ShadowBoxComponent disabled={props.disabled}>
            {props.icon}
            <div className={classes.percentage}>{props.percentage >= 0 ? (props.percentage + '%') : ''}</div>
            <div className={classes.number}>
                {props.number > 0 ? <span style={{ fontSize: 12 }}>{props.label}</span> : <span style={{ fontSize: 12 }}>{props.labelEmpty}</span>}
            </div>
            <div className={classes.turnover}><CountUp separator=" " end={props.turnover} /></div>
            <hr style={{ marginTop: 15 }} />
            <span className={classes.title}>{props.title}</span>
        </ShadowBoxComponent>
    );
}

const useStyles = makeStyles({
    number: {
        textAlign: 'end',
        fontSize: 15,
    },
    turnover: {
        textAlign: 'end',
        fontWeight: 500,
        fontSize: 25,
        marginTop: 5,
        color: '#5E6E82'
    },
    percentage: {
        position: 'absolute',
        top: 15,
        left: 15,
        fontSize: 15,
    },
    icon: {
        position: 'absolute',
        top: 40,
        left: 15,
        fontSize: 30
    }
});

export default Card;
