const SelectOrganismReducer = (state = { organism: {} }, action) => {
    switch (action.type) {
        case 'SelectOrganism':
            return { ...state, organism: action.data.organism };
        default:
            return state
    }
};

export default SelectOrganismReducer;
