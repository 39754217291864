import React from 'react';
import { Box, ListItemText, Menu, MenuItem, makeStyles, withStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "./LinearProgress";
import { AppBar as AppBarMUI } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Api from "../../../Api";
import { AuthenticationLogout } from "../../../Action/AuthenticationAction";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { theme } from "../../../App";
import TabsNavigation from './TabsNavigation';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AccountBalance } from "@material-ui/icons";
import { getRouteByPathname, getRoutePathname, getRoutes } from '../../../Config/Route';
import { SelectOrganism } from '../../../Action/SelectOrganismAction';
import { inArray } from '../../../Tool/ArrayCommon';
import Drawer from './Drawer';

export default function AppBar(props = { organisms: [] }) {
    const [drawerRoute, setDrawerRoute] = React.useState([]);
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const [isRoute, setIsRoute] = React.useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const optionOrganisms = props.organisms;
    const [loading, setLoading] = React.useState(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const classes = useStyles(screenReducer.screen, drawerRoute)();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getSwitchPage = () => {
        let route = getRoutes();
        const newRoute = Object.keys(route).filter(key => route[key].roles.some(role => !role.startsWith("ROLE_INSTITUTION")));
        props.history.push(getRoutePathname(newRoute[0]))
    }

    React.useEffect(() => {
        setLoading(optionOrganisms.length === 0);
    }, [optionOrganisms]);

    React.useEffect(() => {
        let path = props.location.pathname;
        let currentRoute = getRouteByPathname(path);
        setIsRoute((currentRoute.id !== 'institution_health_home') && (Object.keys(selectOrganismReducer.organism).length > 0));
    }, [props.location.pathname, selectOrganismReducer]);

    React.useEffect(() => {
        if (authenticationReducer.roles) {
            const roles = authenticationReducer.roles
            let isInstitution = false;
            
            const filteredRoles = roles.filter(role => !role.startsWith("ROLE_INSTITUTION") && !role.includes("ROLE_USER") && !role.includes("ROLE_SUPER_ADMIN"));
            if (filteredRoles.length !== 0) {
                isInstitution = true;
            }
            setIsSuperAdmin(roles.includes("ROLE_SUPER_ADMIN") || isInstitution);
        }
    }, [authenticationReducer]);

    return (
        <>
            <AppBarMUI position="fixed" className={`${classes.appBar}`}>
                <Toolbar>
                    {screenReducer.screen === 'XS' ? '' : <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.appBarLogo} />}
                    <Box style={{ margin: '0px 13px', display: 'flex' }}>
                        {isSuperAdmin &&
                            <Box style={{ background: 'rgba(94, 110, 130, 0.87)', borderRadius: selectOrganismReducer.organism.label ? '20px 0 0 20px' : 20, margin: 0, padding: '0px 5px', display: 'flex' }}>
                                <Tooltip title="Portail Libéral" aria-label="Établissement">
                                    <IconButton component="span" className={classes.appBarLogout} onClick={() => { getSwitchPage() }}>
                                        <AccountBalance className={classes.appBarLogoutIcon} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }
                        {selectOrganismReducer.organism.label &&
                            <Box style={{ display: 'flex' }}>
                                <Box style={{ background: '#EFF2F4', margin: 0, borderRadius: !isSuperAdmin ? '20px 0 0 20px' : 0, padding: '0px 5px', display: 'flex', alignItems: 'center', boxShadow: 'inset 0 0 2px' }}>
                                    <p style={{ margin: 0, fontSize: 11, padding: '0 10px', }}>
                                        {selectOrganismReducer.organism.label}
                                    </p>
                                </Box>
                                <Box style={{ background: 'rgba(94, 110, 130, 0.87)', borderRadius: '0 20px 20px 0', display: 'flex', alignItems: 'center', }}>
                                    <Tooltip title="Organisme" aria-label="Organisme">
                                        <IconButton component="span" disabled={loading} className={classes.appBarLogout} style={{ transform: 'translate(-8%)' }} onClick={Boolean(anchorEl) ? handleClose : handleClick}>
                                            <ArrowDropDownIcon className={classes.appBarLogoutIcon} />
                                        </IconButton>
                                    </Tooltip>
                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {optionOrganisms.map((element, index) => (
                                            <StyledMenuItem
                                                key={element.value}
                                                selected={element.value === selectOrganismReducer.organism.value}
                                                onClick={() => {
                                                    dispatch(SelectOrganism({ organism: element }));
                                                    props.history.push(getRoutePathname('institution_health_home'))
                                                }}>
                                                <ListItemText primary={element.label} />
                                            </StyledMenuItem>
                                        ))}
                                    </StyledMenu>
                                </Box>
                            </Box>
                        }
                    </Box>


                    <div style={{ margin: '0px 0px 0px auto', display: 'flex', alignItems: 'center', height: '100%' }}>
                        <TabsNavigation
                            {...props}
                            isRoute={isRoute}
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            setDrawerRoute={setDrawerRoute}
                        />
                    </div>
                </Toolbar>
                <LinearProgress />
            </AppBarMUI>

            {drawerRoute.length !== 0 &&
                <>
                    <Drawer route={drawerRoute} isOpen={isOpen} {...props} />
                    {isOpen &&
                        <Box onClick={() => setIsOpen(false)} style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            background: 'rgba(51, 51, 51, 0.39)',
                            zIndex: 900,
                        }} />
                    }
                </>
            }
        </>
    );
}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles({
    root: {
        '&:focus': {
            backgroundColor: '#5E6E82 !important',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#fff',
            },
        },
        '& .MuiMenuItem-root': {
            padding: '0 20px',
        },
        '& .MuiTypography-body1': {
            fontSize: 10,
            color: '#5E6E82',
        },
        '& .MuiList-padding': {
            padding: 0
        }
    },
})(MenuItem);

const useStyles = (screen, drawerRoute) => makeStyles(theme => ({
    appBar: {
        zIndex: 1200,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: '#FFFFFF',
        color: 'rgba(0, 0, 0, 0.5)',
        height: 58,
        '& .MuiToolbar-regular': {
            height: 58,
            minHeight: '58px !important',
            paddingLeft: screen === 'XS' ? 0 : 23,
            paddingRight: drawerRoute.length !== 0 ? 0 : 23,
        }
    },
    appBarButton: {
        padding: 0,
        cursor: 'pointer',
    },
    appBarLogo: {
        height: 25,
        margin: '0 0 0 16px ',
    },
    appBarLogout: {
        padding: 5,
        margin: '0',
        color: '#FFF'
    },
    appBarLogoutIcon: {
        fontSize: 17
    },
    before: {
        '&:before': {
            content: '" "',
            width: 5,
            height: 5,
            background: 'green',
            borderRadius: 50,
            position: 'absolute',
            top: 4,
            left: 18
        }
    },
    popover: {
        '& .MuiPopover-paper': {
            minHeight: 330,
            width: '50%'
        }
    }
}));
