import React from 'react';
import { useSelector } from "react-redux"
import { getRoutePathname } from "../../../../Config/Route"
import { OrganismIndex, Organisms } from '../../../../Organism'
import CardComponent from "./CardComponent"
import CardDashboardManagerComponent from "./CardDashboardManagerComponent"
import CardInstitutionComponent from "./CardInstitutionComponent"
import CardInstitutionGroupComponent from "./CardInstitutionGroupComponent"
import CardWelcomeComponent from "./CardWelcomeComponent"
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import CategoryIcon from '@material-ui/icons/Category';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ComputerIcon from '@material-ui/icons/Computer';
import DvrIcon from '@material-ui/icons/Dvr';
import SchoolIcon from '@material-ui/icons/School';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { hasRight } from '../../../../Tool/UserTool';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CardRelaunchComponent from './CardRelaunchComponent';

const getAccess = (props) => {
    const isAdmin = hasRight(props.authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ADMIN');
    return {
        isAdmin: isAdmin,
        isCrmAdmin: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'),
        isCrm: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN'),
        isContentManager: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CONTENT_MANAGER'),
        isFinancial: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ACCOUNTANT'),
        isAdministrator: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ADMINISTRATOR'),
        isInstitution: hasRight(props.authenticationReducer, 'ROLE_INSTITUTION') && hasRight(props.authenticationReducer, 'ROLE_USER')
    }
}

export function CardWelcome(props) {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const organism = Organisms[OrganismIndex[selectOrganismReducer.organism.value]];
    return (
        <CardWelcomeComponent
            icon={organism ? <img src={'/asset/images/' + organism.icon} /> : ''}
            onClick={() => props.history.push(getRoutePathname('institution_health_admin_user', { id: props.authenticationReducer.id }))}
        />
    )
}

export function CardHotlines(props) {
    return (
        <CardComponent
            icon={<HeadsetMicIcon style={{ width: 17, height: 17 }} />}
            title={'Mes hotlines'}
            text={'Voir vos hotlines.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_hotline_list')) }
            }}
        />
    )
}


export function CardBilling(props) {
    return (
        <CardComponent
            icon={<LibraryBooksIcon style={{ width: 20, height: 20 }} />}
            title={'Facturation'}
            text={'Liste des sessions formation.'}
            background={'#5E6E82'}
            color={'#FFF'}
            button={{
                title: 'Accéder',
                color: '#5E6E82',
                background: '#FFFFFF',
                onClick: () => { props.history.push(getRoutePathname('institution_health_financial_management_billing_list')) }
            }}
        />
    )
}

export function CardInstitution(props) {
    return (
        <CardInstitutionComponent
            data={props.data}
            button={{
                title: 'Gestion d’établissement',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#5E6E82' },
                background: '#FFFFFF',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_institution_list')) }
            }}
        />
    )
}

export function CardInvestisor(props) {
    return (
        <CardComponent
            icon={<TrendingUpIcon style={{ width: 20, height: 20 }} />}
            title={'Investisseur'}
            text={'Statistiques.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_investor')) }
            }}
        />
    )
}

export function CardVirtualClassroom(props) {
    return (
        <CardComponent
            icon={<ComputerIcon style={{ width: 20, height: 20 }} />}
            title={'Classes virtuelles'}
            text={'Liste classes virtuelles.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_virtual_classroom_list')) }
            }}
        />
    )
}

export function CardRessource(props) {
    return (
        <CardComponent
            icon={<CategoryIcon style={{ width: 20, height: 20 }} />}
            title={'Ressource'}
            text={'Liste des ressources.'}
            background={'#5E6E82'}
            color={'#FFF'}
            button={{
                title: 'Accéder',
                color: '#5E6E82',
                background: '#FFFFFF',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_resource_list')) }
            }}
        />
    )
}

export function CardCourse(props) {
    return (
        <CardComponent
            icon={<SchoolIcon style={{ width: 20, height: 20 }} />}
            title={'Cours'}
            text={'Liste des cours.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_course_list')) }
            }}
        />
    )
}

export function CardFormations(props) {
    return (
        <CardComponent
            icon={<DvrIcon style={{ width: 20, height: 20 }} />}
            title={'Formations'}
            text={'Liste des formations.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_formation_list')) }
            }}
        />
    )
}

export function CardSessions(props) {
    return (
        <CardComponent
            icon={<DateRangeIcon style={{ width: 20, height: 20 }} />}
            title={'Sessions'}
            text={'Liste des sessions.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_admin_session_list')) }
            }}
        />
    )
}

export function CardInstitutionGroup(props) {
    return (
        <CardInstitutionGroupComponent
            data={props.data}
            button={{
                title: 'Gestion de groupe',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#FFFFFF' },
                background: '#5E6E82',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_institution_group_list')) }
            }}
        />
    )
}

export function CardDashboardManager(props) {
    return (
        <CardDashboardManagerComponent
            data={props.data}
            button={{
                title: 'Détails',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#5E6E82' },
                background: '#FFFFFF',
                isAccess: getAccess(props).isCrmAdmin,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_dashboard_manager')) }
            }}
        />
    )
}

export function CardRelaunch(props) {
    return (
        <CardRelaunchComponent
            data={props.data}
            button={{
                title: 'Accéder',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#FFFFFF' },
                background: '#5E6E82',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_relaunch_list')) }
            }}
        />
    )
}

export function CardRelaunchV2(props) {
    return (
        <CardComponent
            icon={<PlaylistAddCheckIcon />}
            title={'Suivi des inscriptions'}
            text={'Liste des inscriptions.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_relaunch_list')) }
            }}
        />
    )
}