import React from 'react';
import {makeStyles, Tooltip} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import Fade from "@material-ui/core/Fade";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import IconButton from "@material-ui/core/IconButton";
import {ArrowLeft, ArrowRight, Fullscreen, FullscreenExit} from "@material-ui/icons";
import {ScreenFull} from "../../../Action/ScreenAction";
import {getRoutePathname} from "../../../Config/Route";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {theme} from "../../../App";
import Iframe from "./Iframe";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import {Alert} from '@material-ui/lab';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';

const StayTime = (props) => {
    const [initTime, setInitTime] = React.useState(0);
    const minutes = Math.floor(initTime / 60);
    const remainingSeconds = initTime % 60;
    const seconds = props.duration - props.timeSpent;

    React.useEffect(() => {
        const interval = setInterval(() => {
            setInitTime(initTimes => {
                const updatedTime = initTimes - 1;
                if (updatedTime <= 0) {
                    clearInterval(interval);
                }
                return updatedTime;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [props.match.params.course, seconds]);

    React.useEffect(() => {
        setInitTime(seconds)
    }, [props.match.params.course, seconds]);

    return (
        <span style={{background: ((minutes <= 0) && (remainingSeconds <= 0)) ? '#6FD08C' : '#69ABAA', fontWeight: 'bold', color: '#FFF', padding: '6px 15px', borderRadius: 6, fontSize: 11, margin: '0 5px', display: 'flex', gap: 5, alignItems: 'center'}}>
            {((minutes <= 0) && (remainingSeconds <= 0)) ? 'Temps validé' : minutes + ' min ' + remainingSeconds + 's restant'} <AccessTimeIcon style={{fontSize: 16}}/>
        </span>
    )
}

function RegistrationCourse(props) {
    const dispatch = useDispatch();
    const [iFrameScale, setIFrameScale] = React.useState(1);
    const classes = useStyles(iFrameScale)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [show, setShow] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [prevNextCourse, setPrevNextCourse] = React.useState({prev: '', next: ''});
    const [isNextCourseLock, setIsNextCourseLock] = React.useState(false);
    const [isNextCourseLockFront, setIsNextCourseLockFront] = React.useState(false);
    const [isFinishedCourse, setIsFinishedCourse] = React.useState(false);
    const [isCourseUnblock, setIsCourseUnblock] = React.useState(false);
    const [info, setInfo] = React.useState([]);

    const goBack = () => {
        setShow(false);
        setIsFinishedCourse(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
        }, 600);
    };

    const goCourse = (course) => {
        setIsFinishedCourse(false);
        setShow(false);
        if (!isNextCourseLock) {
            setTimeout(() => {
                props.history.push({
                    pathname: getRoutePathname('learner_registration_course', {registration: props.match.params.registration, course: course}),
                    state: {courseType: props.location.state.courseType}
                });
            }, 800);
        }
        else {
            props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
        }
    };

    const navCourse = (course) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
                route: 'learner_registration_course_nav',
                params: {
                    registration: props.match.params.registration,
                    course: props.match.params.course
                }
            },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setIsNextCourseLock(!response.data.isNextCourseUnlock);
                    if (response.data.isNextCourseUnlock) {
                        props.history.push({
                            pathname: getRoutePathname('learner_registration_course', {registration: props.match.params.registration, course: course}),
                            state: {courseType: props.location.state.courseType}
                        });
                    }
                    else {
                        setIsNextCourseLockFront(true);
                        setTimeout(() => {
                            setIsNextCourseLockFront(false);
                        }, 2000);
                    }

                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    // Iframe Scale
    const generateIFrameScale = () => {

        if (!isScale) return;

        let orientation = window.screen.orientation && window.screen.orientation.angle === 90;

        if (screenReducer.full) {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                }
                else {
                    setIFrameScale(ratioWidth);
                }
            }
            else {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                }
                else {
                    setIFrameScale(ratioWidth);
                }
            }
        }
        else {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (window.innerHeight / 3)) / iFrameHeight;


            if (orientation) {
                if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                }
                else {
                    setIFrameScale(1);
                }
            }
            else {
                let r = 1;
                if (window.innerHeight < (iFrameHeight + 200)) {
                    r = ratioHeight;
                }
                if (window.innerWidth < iFrameWidth && ratioHeight > ratioWidth) {
                    r = ratioWidth;
                }

                setIFrameScale(r);
            }
        }
    };
    React.useEffect(generateIFrameScale, [screenReducer.full, screenReducer.size, screenReducer.orientation]);
    React.useEffect(() => {
        if (screenReducer.orientation && isScale) {
            setTimeout(() => dispatch(ScreenFull(true)), 50);
        }
    }, [screenReducer.orientation]);

    let isScale = true;
    let styleContent = {};
    let iFrameWidth = 0;
    let iFrameHeight = 0;

    if (props.location.state) {
        if (props.location.state.courseType === 4 || props.location.state.courseType === 7) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = {maxWidth: iFrameWidth, width: '100%', height: '100%', zIndex: 1, position: 'relative'};
            isScale = false;
        }
        else if (props.location.state.courseType === 6) {
            iFrameWidth = 1240;
            iFrameHeight = '100%';
            styleContent = {maxWidth: iFrameWidth, width: '100%', height: iFrameHeight, zIndex: 1, position: 'relative', transform: 'scale(' + iFrameScale + ')'};
            isScale = true;
        }
        else {
            iFrameWidth = 920;
            iFrameHeight = 640;
            styleContent = {width: iFrameWidth, height: iFrameHeight, zIndex: 101, position: 'relative', transform: 'scale(' + iFrameScale + ')'};
            isScale = true;
        }
    }

    React.useEffect(() => {
        if (props.match.params.registration && props.match.params.course) {
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                    route: 'learner_registration_course_get',
                    params: {
                        registration: props.match.params.registration,
                        course: props.match.params.course
                    }
                },
                (response) => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setShow(true);
                        setInfo(response.data);
                        setBreadcrumbs({
                            currentPage: response.data.name,
                            links: [
                                {path: getRoutePathname('learner_home'), label: 'Tableau de bord'},
                                {path: getRoutePathname('learner_registration', {id: props.match.params.registration}), label: 'Sommaire'}
                            ]
                        });
                        setPrevNextCourse({
                            prev: response.data.prevCourse,
                            next: response.data.nextCourse
                        });

                        if (isFinishedCourse === true && !response.data.nextCourse) {
                            setTimeout(() => {
                                props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                            }, 100);

                        }
                        else if (props.location.state.courseType === 4 && isFinishedCourse === true) {
                            setTimeout(() => {
                                goCourse(response.data.nextCourse);
                            }, 100);

                        }
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        props.history.push(getRoutePathname('learner_registration', {id: props.match.params.registration}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            Api.get({
                route: 'learner_registration_course_nav',
                params: {
                    registration: props.match.params.registration,
                    course: props.match.params.course
                }
            }, (response) => {
                if (response.status === 200) {
                    setIsCourseUnblock(response.data.isNextCourseUnlock);
                    if (!response.data.isNextCourseUnlock) {
                        window.onbeforeunload = () => true;
                    }
                    else window.onbeforeunload = () => undefined;
                }
            });
            setIsUnblock(false);
        }
    }, [props.match.params.course, isFinishedCourse]);

    const courseFinished = () => {
        setIsFinishedCourse(true);
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        window.addEventListener("fromItemToPortail", goBack);
        return () => window.removeEventListener("fromItemToPortail", goBack)
    }, []);
    React.useEffect(() => {
        window.addEventListener("isCourseFinished", courseFinished);
        return () => window.removeEventListener("isCourseFinished", courseFinished)
    }, []);

    React.useEffect(() => {
        window.addEventListener('message', function (event) {
            if (event.origin === 'https://data.santeformapro.com' && event.data === 'isCourseFinished') {
                courseFinished();
            }
        });
        return () => {
            window.removeEventListener('message', function (event) {
                if (event.origin === 'https://data.santeformapro.com' && event.data === 'isCourseFinished') {
                    courseFinished();
                }
            });
        };
    }, []);

    React.useEffect(() => {
        if (!props.location.state) goBack();
    }, [props.location.state]);

    React.useEffect(() => {
        if (props.location.state.courseType === 5 || props.location.state.courseType === 6) {
            Api.post({
                    route: 'learner_registration_course_score',
                    params: {
                        registration: props.match.params.registration,
                        course: props.match.params.course
                    },
                    data: {
                        score: 100
                    }
                },
                (response) => {
                    if (response.status === 200) {
                        setIsFinishedCourse(true);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, [props.match.params.course]);

    const [showDialog, setShowDialog] = React.useState(false);
    const [nextPath, setNextPath] = React.useState(null);
    const [isUnblock, setIsUnblock] = React.useState(false);

    React.useEffect(() => {

        if (!isNextCourseLock) {
            const unblock = props.history.block((tx) => {
                const nextLocation = {
                    ...tx,
                    retry() {
                        unblock();
                        tx.retry();
                    },
                };
                setNextPath(nextLocation.pathname)
                if (isFinishedCourse) {
                    setShowDialog(false);
                    return
                }
                if ((nextLocation.pathname !== props.history.location.pathname)) {
                    dispatch(ScreenFull(false));
                }
                if ((!isUnblock && !isCourseUnblock && !isFinishedCourse && (nextLocation.pathname !== props.history.location.pathname) && props.location.state.courseType !== 7)) {
                    setShowDialog(true);
                    return false;
                }
            });
            return unblock;
        }

    }, [isUnblock, isCourseUnblock, isFinishedCourse]);


    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <div style={{textAlign: 'center', margin: 'auto'}}>
                {
                    loading === true ?
                        <Fade in={true} {...{timeout: 1000}}>
                            <div className={classes.loader}>
                                <div className={classes.loaderContent} style={isSmallView ? {width: 225} : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo}/>
                                        <div className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35}/>
                                        </div>
                                    </ShadowBoxComponent>
                                </div>
                            </div>
                        </Fade>
                        : loading === false ?
                            <Fade in={show} {...{timeout: 1000}}>
                                <div style={{textAlign: 'center', margin: 'auto', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column',}}>
                                    {(!screenReducer.full && info.length != 0 && parseInt(info.financeType) !== 6) &&
                                        <div style={{position: 'fixed', right: 20, zIndex: 1000, top: 90, display: 'flex'}}>
                                            <StayTime duration={info.duration} timeSpent={info.timeSpent} {...props} />
                                        </div>
                                    }
                                    {screenReducer.full && <div style={{height: '100%', width: '100%', top: 0, backgroundColor: '#000', position: 'absolute', zIndex: 100}}/>}

                                    <div style={{margin: '0 35px 0 auto', transform: 'translate(0, 30px)'}}>
                                        {prevNextCourse.next && <Tooltip title="Cours suivant" aria-label="next" arrow>
                                            <IconButton style={{padding: '5px 10px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px', backgroundColor: 'white'}} className={classes.buttonAction} aria-label="next"
                                                        onClick={() => navCourse(prevNextCourse.next)}>
                                                <span style={{fontSize: 13}}>Cours suivant</span>
                                                <ArrowRight style={{fontSize: 25}} className={classes.buttonActionIcon}/>
                                            </IconButton>
                                        </Tooltip>}
                                        {prevNextCourse.prev && <Tooltip title="Cours précédent" aria-label="prev" arrow>
                                            <IconButton style={{padding: '5px 10px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 4px 0px', backgroundColor: 'white'}} className={classes.buttonAction} aria-label="prev"
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: getRoutePathname('learner_registration_course', {registration: props.match.params.registration, course: prevNextCourse.prev}),
                                                                state: {courseType: props.location.state.courseType}
                                                            });
                                                        }}>
                                                <ArrowLeft style={{fontSize: 25}} className={classes.buttonActionIcon}/>
                                                <span style={{fontSize: 13}}>Cours précédent</span>
                                            </IconButton>
                                        </Tooltip>}
                                    </div>

                                    <Fade in={isNextCourseLockFront} {...{timeout: 1000}} style={{position: 'absolute', top: '50%', transform: 'translate(-50%,-50%)', left: '50%', zIndex: 110}}>
                                        <Alert severity="info">
                                            Terminez le cours actuel pour passer au suivant.
                                        </Alert>
                                    </Fade>

                                    <div className={`${classes.styleIframe} ${screenReducer.full && classes.styleIframeFull}`} style={styleContent}>
                                        {
                                            isScale && <>
                                                {
                                                    screenReducer.full ?
                                                        <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(false))}>
                                                            <FullscreenExit className={classes.btnFullScreenIcon}/>
                                                        </IconButton> :
                                                        <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(true))}>
                                                            <Fullscreen className={classes.btnFullScreenIcon}/>
                                                        </IconButton>
                                                }
                                            </>
                                        }
                                        <Iframe
                                            id={'Iframe'}
                                            registration={props.match.params.registration}
                                            course={props.match.params.course}
                                            courseType={props.location.state.courseType}
                                        />
                                    </div>
                                </div>
                            </Fade>
                            : <></>
                }
            </div>
            <SweetAlert
                show={showDialog}
                title={''}
                text={'\nÊtes-vous sûr de vouloir quitter cette page ? Si oui, toutes les progressions de ce cours seront perdues.'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setIsUnblock(true)
                    setShowDialog(false)
                    props.history.push({
                        pathname: nextPath,
                        state: {courseType: props.location.state.courseType}
                    });
                    dispatch(ScreenFull(false));
                    window.onbeforeunload = () => undefined;
                }}
                onCancel={() => {
                    setShowDialog(false)
                }}
            />
        </>
    );
}

const useStyles = (iFrameScale) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(-15%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
            {
                margin: 'auto',
                width: '15% !important'
            }
    },
    shadowBox: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 5px 20px 0px rgba(0,0,0,0.25)',
    },
    btnFullScreen: {
        right: 60,
        top: 0,
        position: 'absolute',
        padding: 10,
        margin: 0,
        marginRight: 12,
        color: '#FFFFFF',
        zIndex: 3
    },
    btnFullScreenIcon: {
        fontSize: 30
    },
    buttonAction: {
        padding: 10,
        marginRight: 10,
        margin: 0,
        float: 'right',
        borderRadius: 8
    },
    buttonActionIcon: {
        fontSize: 18,
        opacity: 1,
        '& g > path': {
            stroke: 'rgba(0, 0, 0, 0.6)'
        }
    },
    styleIframe: {
        '& iframe': {
            marginTop: '0 !important',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 0px !important',
            borderRadius: '5px !important',
        }
    },
    styleIframeFull: {
        position: 'absolute !important',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%) scale(${iFrameScale}) !important`
    }
}));

export default RegistrationCourse;
