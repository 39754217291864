import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";

function RoleList(props) {
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    const columns = [
        {
            name: "name",
            label: "Rôle",
            options: {filter: true, sort: true}
        },
        {
            name: "description",
            label: "Description",
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('administrator_role', {id: row.id}))}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des rôles',
            context: 'Administrateur',
            description: ''
        });
        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <TableComponent
                id={'administrator_role_list'}
                title={'Rôles'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un rôle',
                    onClick: () => props.history.push(getRoutePathname('administrator_role', {id: null}))
                }}
                promiseData={(resolve) => {
                    Api.get({
                            route: 'administrator_role_list'
                        },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].description = data[index].description ? data[index].description : '-';
                                data[index].action = getAction(data[index]);
                            }
                            resolve(data);
                        });
                }}
            />

        </ContentViewComponent>
    );
}

export default RoleList;
