import React from 'react';
import {makeStyles} from "@material-ui/core";
import {useSelector, useDispatch} from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import {MediaHide} from "../Action/MediaAction";

function MediaComponent() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const mediaReducer = useSelector(state => state.MediaReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [showBtnClose, setShowBtnClose] = React.useState(false);

    const mediaHide = () => {
        dispatch(MediaHide());
    };

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        mediaReducer.type ?
            <div className={classes.content}>

                <div className={classes.contentBackground}/>

                {  // PDF
                    mediaReducer.type === 1 && <div className={classes.contentPdf} style={isTall ? {} : {padding: 0}}>
                        {showBtnClose && <div style={{position: 'relative', maxWidth: 1000, margin: 'auto'}}>
                            <IconButton className={classes.btnPdf} key="close" aria-label="close" color="inherit" onClick={mediaHide}>
                                <CancelIcon className={classes.icon}/>
                            </IconButton>
                        </div>}
                        <iframe
                            onLoad={() => setTimeout(() => setShowBtnClose(true), 250)}
                            className={classes.pdf}
                            src={mediaReducer.path}>
                        </iframe>
                    </div>
                }
                { // Youtube
                    mediaReducer.type === 3 && <div className={classes.contentYoutube} style={isTall ? {padding: 125} : {padding: 0}}>
                        {showBtnClose && <div style={{position: 'relative', maxWidth: 1000, margin: 'auto'}}>
                            <IconButton className={classes.btnYoutube} key="close" aria-label="close" color="inherit" onClick={mediaHide}>
                                <CancelIcon className={classes.icon}/>
                            </IconButton>
                        </div>}
                        <iframe
                            onLoad={() => setTimeout(() => setShowBtnClose(true))}
                            className={classes.youtube}
                            src={mediaReducer.path}>
                        </iframe>
                    </div>
                }
                { // Image
                    mediaReducer.type === 4 && <div className={classes.contentImage}>
                        <div style={{position: 'absolute', height: '100vh', width: '100vw', zIndex: 102}} onClick={mediaHide}/>
                        {showBtnClose && <IconButton className={classes.btnImage} key="close" aria-label="close" color="inherit" onClick={mediaHide}>
                            <CancelIcon className={classes.icon}/>
                        </IconButton>}
                        <img
                            onLoad={() => setTimeout(() => setShowBtnClose(true))}
                            className={classes.image}
                            src={mediaReducer.path}
                            alt={'Image'}>
                        </img>
                    </div>
                }
                { // MP3
                    mediaReducer.type === 5 && <div className={classes.contentMp3} style={isTall ? {padding: '250px 200px'} : {padding: 0}}>
                        {showBtnClose && <div style={{position: 'relative', maxWidth: 768, margin: 'auto'}}>
                            <IconButton className={classes.btnMp3} key="close" aria-label="close" color="inherit" onClick={mediaHide}>
                                <CancelIcon className={classes.icon}/>
                            </IconButton>
                            <div style={{zIndex: 102, color: '#FFF', position: 'absolute', top: 150, left: 'calc(50% - 20px)', fontWeight: '700', fontSize: 26}}>MP3</div>
                        </div>}
                        <iframe
                            onLoad={() => setTimeout(() => setShowBtnClose(true))}
                            className={classes.mp3}
                            src={mediaReducer.path}>
                        </iframe>
                    </div>
                }
            </div> : <></>
    );
}

const useStyles = makeStyles({
    content: {
        zIndex: 3000,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
    },
    contentBackground: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: '#000',
        opacity: 0.4
    },
    contentPdf: {
        zIndex: 101,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        padding: 50,
        boxSizing: 'border-box',
        margin: 'auto',
        textAlign: 'center'
    },
    pdf: {
        zIndex: 103,
        width: '100%',
        height: '100%',
        maxWidth: 1000,
        border: 0
    },
    btnPdf: {
        zIndex: 103,
        position: 'absolute',
        right: 132,
        top: 8,
        cursor: 'pointer',
        padding: 8,
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    },
    contentYoutube: {
        zIndex: 101,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        padding: 50,
        boxSizing: 'border-box',
        margin: 'auto',
        textAlign: 'center'
    },
    youtube: {
        zIndex: 103,
        width: '100%',
        height: '100%',
        maxWidth: 1000,
        border: 0
    },
    btnYoutube: {
        zIndex: 103,
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        padding: 8,
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    },
    contentMp3: {
        zIndex: 101,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        padding: 50,
        boxSizing: 'border-box',
        margin: 'auto',
        textAlign: 'center'
    },
    mp3: {
        zIndex: 103,
        width: '100%',
        height: '100%',
        maxWidth: 768,
        border: 0
    },
    btnMp3: {
        zIndex: 103,
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        padding: 8,
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    },
    contentImage: {
        zIndex: 101,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        boxSizing: 'border-box',
        margin: 'auto',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        zIndex: 103,
        maxWidth: '100vw',
        maxHeight: '100vh',
        border: 0,
    },
    btnImage: {
        zIndex: 103,
        position: 'absolute',
        right: 0,
        top: 0,
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    },
    icon: {
        color: '#F2F2F2',
        fontSize: 22
    }
});

export default MediaComponent;

