import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { inArray } from "../../../Tool/ArrayCommon";
import { makeStyles } from "@material-ui/core";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Assignment, Delete, Edit } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import SweetAlert from "sweetalert-react";
import { getRoutePathname } from "../../../Config/Route";
import CheckboxComponent from "../../../Component/CheckboxComponent";

function GroupProspectList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    let isBanGroupChecked = [];

    // LIST
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'lastname',
            label: 'Prénom',
            options: { filter: true, sort: true }
        },
        {
            name: 'firstname',
            label: 'Nom',
            options: { filter: true, sort: true }
        },
        {
            name: 'pseudo',
            label: 'Pseudo',
            options: { filter: true, sort: true }
        },
        {
            name: 'email',
            label: 'Email',
            options: { filter: true, sort: true }
        },
        {
            name: 'organism',
            label: 'Organisme',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Rejoint le Groupe',
            options: { filter: true, sort: true }
        },
        {
            name: 'deletedAt',
            label: 'Quitter le Groupe',
            options: { filter: true, sort: true }
        },
        {
            name: 'active',
            label: 'Bannir',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const save = (id, checked) => {
        Api.post({
            route: 'community_group_prospect_banned',
            params: { group: props.match.params.id , id : id },
            data: { isBanGroup: checked }
        },
            (response) => {
                if (response.status === 201) {
                    dispatch(TableReload('community_group_prospect_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.data.isBanGroup ? 'Cet presonne a était banni.' : 'Cet presonne a était débanni.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Retirer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <ExitToAppIcon />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const getActionCheck = (id, checked) => {
        return (
            <Checkbox
                key={id + '-' + new Date().getTime()}
                checked={checked}
                onChange={() => {
                    if (inArray(id, isBanGroupChecked)) {
                        let index = isBanGroupChecked.indexOf(id);
                        if (index > -1) {
                            isBanGroupChecked.splice(index, 1);
                            save(id, false)
                        }
                    } else {
                        isBanGroupChecked.push(id);
                        save(id, true);
                    }
                }}
            />
        );
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{ name: 'order', direction: 'desc' }}
                id={'community_group_prospect_list'}
                title={'Membres du groupe : '}
                columns={columns}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'community_group_prospect_list',
                            params: { group: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).format('lll');
                                    data[index].pseudo = data[index].pseudo ? data[index].pseudo : "-";
                                    data[index].deletedAt = data[index].deletedAt ? moment(data[index].deletedAt).format('lll') : "-";
                                    data[index].action = getAction(data[index]);

                                    if (data[index].isBanGroup === true) isBanGroupChecked.push(data[index].id);
                                    data[index].active = getActionCheck(data[index].id, data[index].isBanGroup);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <SweetAlert
                show={showConfirm}
                title={'Retirer'}
                text={'Êtes-vous sur de vouloir le retirée de ce groupe ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'community_group_prospect_delete',
                        params: { id: actionRow.id, group: props.match.params.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Ce membre à bien était retirée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('community_group_prospect_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>

    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default GroupProspectList;
