const LoginPageReducer = (state = {}, action) => {
    switch (action.type) {
        case 'LoginPage':
            return {
                page: action.page
            };
        default:
            return state
    }
};

export default LoginPageReducer;
