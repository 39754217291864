import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Assignment} from "@material-ui/icons";
import {getRoutePathname} from "../../../Config/Route";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {Button, makeStyles} from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import {TableReload} from "../../../Action/TableAction";
import {cacheTablePage, cacheTableServer} from "../../../Cache";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import {inArray} from "../../../Tool/ArrayCommon";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import FlipCardComponent from '../../../Component/FlipCardComponent';

function BillingList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isTall, setIsTall] = React.useState(true);
    const [optionEndMonth, setOptionEndMonth] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Janvier' },
        { value: 2, label: 'Février' },
        { value: 3, label: 'Mars' },
        { value: 4, label: 'Avril' },
        { value: 5, label: 'Mai' },
        { value: 6, label: 'Juin' },
        { value: 7, label: 'Juillet' },
        { value: 8, label: 'Août' },
        { value: 9, label: 'Septembre' },
        { value: 10, label: 'Octobre' },
        { value: 11, label: 'Novembre' },
        { value: 12, label: 'Décembre' },
    ]);
    const [flip, setFlip] = React.useState(false);
    const [buttonflipText, setButtonFlipText] = React.useState('Choisir par mois');
    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: {filter: true, sort: true}
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: {filter: true, sort: true}
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: {filter: false, sort: true}
        },
        {
            name: "andpc",
            label: "Numéro de programme DPC",
            options: {filter: true, sort: true}
        },
        {
            name: "startAt",
            label: "Début de session",
            options: {filter: true, sort: true}
        },
        {
            name: "endAt",
            label: "Fin de session",
            options: {filter: true, sort: true}
        },
        {
            name: "state",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "percentageComplete",
            label: "Ratio formations terminées",
            options: {filter: true, sort: true}
        },
        {
            name: "numberEntered",
            label: "Nombre d'inscrits",
            options: {filter: false, sort: false}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('financial_management_billing', {id: row.id}))}>
                        <Assignment/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    // Search
    const currentYear = new Date().getFullYear();
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].organism ? cacheTableServer['financial_management_billing_session_formation_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].formationGroup ? cacheTableServer['financial_management_billing_session_formation_list'].formationGroup : 'all',
            options: {}
        },
        andpc: {
            name: 'andpc',
            label: 'Code andpc',
            textHelper: 'Saisissez un code andpc.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].andpc ? cacheTableServer['financial_management_billing_session_formation_list'].andpc : '',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Date de début de session',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].startAt ? cacheTableServer['financial_management_billing_session_formation_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Date de fin de session.',
            textHelper: 'Saisissez une date.',
            type: 'date',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].endAt ? cacheTableServer['financial_management_billing_session_formation_list'].endAt : '',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].state ? cacheTableServer['financial_management_billing_session_formation_list'].state : 'all',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].year ? cacheTableServer['financial_management_billing_session_formation_list'].year : currentYear,
            options: {}
        },
        monthEndAt: {
            name: 'monthEndAt',
            label: 'Mois de fin de session.',
            textHelper: 'Choisissez un mois.',
            type: 'text',
            defaultValue: cacheTableServer['financial_management_billing_session_formation_list'] && cacheTableServer['financial_management_billing_session_formation_list'].monthEndAt ? cacheTableServer['financial_management_billing_session_formation_list'].monthEndAt : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['financial_management_billing_session_formation_list'] = 0;
        cacheTableServer['financial_management_billing_session_formation_list'].page = 0;
        cacheTableServer['financial_management_billing_session_formation_list'][index] = value;
        dispatch(TableReload('financial_management_billing_session_formation_list'))
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Facturation',
            context: 'Gestion financière',
            description: 'Liste des sessions formation.'
        });

        // Stats
        Api.get({
                route: 'select_sessions_formations_state'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionProspectStates(data);
                }
            });

        // Year
        let yearsTMP = [];
        yearsTMP.push({label: (currentYear + 1), value: (currentYear + 1)});
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({label: i, value: i});
        }
        setOptionYears(yearsTMP);

        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({value: 'all', label: 'Tous'});
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });

        Api.get({
                route: 'select_formation_groups'
            },
            (response) => {
                if (response && response.data) {
                    let data = [{value: 'all', label: 'Tous'}, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{transitionDelay: '0ms'}} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3}/>Filtre</>}/>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                                <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)}/>
                            </Grid>
                            <Grid item xs={6} sm={4} md={4} lg={4}>
                                <TextFieldComponent name={'andpc'} handler={handlerSearch} onChange={(val) => inputSearchChange('andpc', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionProspectStates} onChange={(val) => inputSearchChange('state', val)}/>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={10} md={10} lg={10}>
                                <FlipCardComponent
                                    flip={flip}
                                    frontCard={
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6} lg={6} style={{/*padding: '0 5px 0 0'*/ }}>
                                                <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6} style={{/*padding: '0 0 0 5px'*/ }}>
                                                <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                                            </Grid>
                                        </Grid>
                                    }
                                    backCard={
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <SelectComponent name={'monthEndAt'} handler={handlerSearch} options={optionEndMonth} onChange={(val) => inputSearchChange('monthEndAt', val)} />
                                        </Grid>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2}>
                                <Button
                                    color="primary"
                                    onClick={
                                        () => {
                                            if (flip) {
                                                handlerSearch.setValue('monthEndAt', 'all')
                                                setButtonFlipText('Choisir par mois')

                                            }
                                            else {
                                                handlerSearch.setValue('startAt', null)
                                                handlerSearch.setValue('endAt', null)
                                                setButtonFlipText('Choisir par periode')
                                            }
                                            dispatch(TableReload('crm_relaunch_registration_list'));
                                            setFlip(!flip);
                                        }
                                    }
                                >{buttonflipText}</Button>
                            </Grid>
                        </Grid>

                        <br/>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br/>

            <TableComponent
                id={'financial_management_billing_session_formation_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Sessions'}
                columns={columns}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();

                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        year: datas.year ?? currentYear,
                        andpc: datas.andpc ?? '',
                        startAt: datas.startAt ?? '',
                        endAt: datas.endAt ?? '',
                        monthEndAt: (datas.monthEndAt === 'all') ? '' : datas.monthEndAt,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        state: (datas.state === 'all') ? '' : datas.state,
                    };

                    Api.get({
                            route: 'financial_management_billing_session_formation_list',
                            data: filters
                        },
                        (response) => {
                            let data = response.data.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                let color = '#000';
                                switch (data[index].stateId) {
                                    case 1:
                                        color = '#007bff';
                                        break;
                                    case 2:
                                        color = '#28a745';
                                        break;
                                    case 3:
                                        color = '#db770a';
                                        break;
                                }
                                data[index].state = <span style={{color: color, width: 85, display: 'block'}}>{data[index].stateLabel}</span>;
                            }

                            resolve(data, response.data.count);
                        });
                }}
                search={false}
            />

            <br/>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});

export default BillingList;
