import React from 'react';
import { Box, Dialog, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import TableComponent from "../../../../Component/TableComponent";
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Edit } from "@material-ui/icons";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from "sweetalert-react";
import ApartmentIcon from '@material-ui/icons/Apartment';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import CloseIcon from '@material-ui/icons/Close';
import Api from '../../../../Api';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import { getRoutePathname } from '../../../../Config/Route';
import { TableReload } from '../../../../Action/TableAction';
import InstitutionAddGroup from './InstitutionAddGroup';

export default function InstitutionGroupInstitution(props) {
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [isAddInstitution, setIsAddInstitution] = React.useState(false);
    const [isAddInstitutionByGroup, setIsAddInstitutionByGroup] = React.useState(false);
    const [actionRow, setActionRow] = React.useState([]);
    const classes = useStyles();

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "holder",
            label: "Titulaire",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    /*const [formSearch, setFormSearch] = React.useState({
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: '',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_list'] && cacheTableServer['institution_health_crm_institution_list'].text ? cacheTableServer['institution_health_crm_institution_list'].text : '',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_crm_institution_list'] = 0;
        cacheTableServer['institution_health_crm_institution_list'].page = 0;
        cacheTableServer['institution_health_crm_institution_list'][index] = value;
        dispatch(TableReload('institution_health_crm_institution_list'))
    };*/

    const [formInstitution, setFormInstitution] = React.useState({
        institutionsId: {
            name: 'institutionsId',
            label: 'Établissements',
            textHelper: 'Saisissez les établissements.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
    });
    const handlerInstitution = formHandlerInit(formInstitution, setFormInstitution);
    React.useEffect(handlerInstitution.start, []);

    const getAction = (row) => {
        return (
            <Box>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution', { institution: row.id })) }}>
                        <Edit style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Tableau de bord'} placement="left">
                    <IconButton onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_manager', { institution: row.id })) }}>
                        <DashboardIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Retirer'} placement="left">
                    <IconButton onClick={() => { setShowConfirm(true); setActionRow(row) }}>
                        <ExitToAppIcon style={{ color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    return (
        <>
            <TableComponent
                id={'institution_health_crm_institution_list'}
                title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><ApartmentIcon style={{ width: 20, height: 20 }} />Vos établissements</h1>}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter un établissement',
                    onClick: () => setIsAddInstitution(true),
                    disabled: !props.isEdition || props.loading
                }}
                promiseServerData={(resolve, options) => {
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        text: options.searchText ? options.searchText : '',
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                    };
                    if (props.match.params.id) {
                        Api.get({
                            route: 'institution_health_crm_institution_list',
                            data: { ...filters, organismId: selectOrganismReducer.organism.value, group: props.match.params.id }
                        },
                            (response) => {
                                if (response.status === 200) {
                                    let data = response.data.data;
                                    for (let index in data) {
                                        data[index].action = getAction(data[index]);
                                        data[index].holder = data[index].holder ? data[index].holder.firstName + ' ' + data[index].holder.lastName : '-';
                                        data[index].email = data[index].email ? data[index].email : '-';
                                        data[index].name = data[index].name ? data[index].name : '-';
                                        data[index].phone = data[index].phone ? data[index].phone : '-';
                                    }
                                    resolve(data, response.data.count);
                                } else resolve([]);
                            });
                    } else resolve([]);
                }}
            />
            <Dialog open={isAddInstitutionByGroup} maxWidth={'lg'} className={classes.styleDialog} onClose={() => { setIsAddInstitutionByGroup(false); }}>
                <InstitutionAddGroup groupId={props.match.params.id} setIsOpen={setIsAddInstitutionByGroup} {...props} />
            </Dialog>
            <Dialog open={isAddInstitution} maxWidth={'lg'} onClose={() => { setIsAddInstitution(false); }}>
                <Box>
                    <h1 style={{ color: '#5E6E82', fontSize: 23, margin: 0, padding: 20, display: 'flex', }} >
                        Ajouter un établissement
                        <Tooltip title={'Fermer'} placement="left">
                            <IconButton style={{ padding: 2, margin: '0 0 0 auto' }} onClick={() => { setIsAddInstitution(false); }}>
                                <CloseIcon style={{ color: '#5E6E82' }} />
                            </IconButton>
                        </Tooltip>
                    </h1>
                    <hr style={{ margin: 0, opacity: 0.2 }} />
                </Box>
                <Grid container spacing={0} style={{ maxWidth: 600, padding: 23 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle}>
                            <Box style={{ cursor: 'pointer' }} onClick={() => { setIsAddInstitutionByGroup(true); setIsAddInstitution(false) }}>
                                <Box><ListAltIcon /></Box>
                                <Box>
                                    <p><b>Établissement existant</b></p>
                                    <p>Sélection de plusieurs établissements déjà ajoutés à la plateforme</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle} onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution', { institution: null }))}>
                            <Box style={{ cursor: 'pointer' }}>
                                <Box><ListAltIcon /></Box>
                                <Box>
                                    <p><b>Établissement non existant</b></p>
                                    <p>Entrer un nouvel établissement dans ce groupe</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
            <SweetAlert
                show={showConfirm}
                title={'Retirer'}
                text={'\nÊtes-vous sur de vouloir retirer cet établissement de ce groupe ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.post({
                        route: 'institution_health_crm_institution_group_remove_institutions',
                        params: { id: actionRow.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Établissement retirer.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('institution_health_crm_institution_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}

const useStyles = makeStyles({
    boxDialogStyle: {
        background: '#EFF2F4',
        boxShadow: '0px 0px 2px #0000005e',
        borderRadius: 4,
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            padding: 18,
            margin: '5px 0',
        },
        '& label': {
            background: 'transparent !important'
        },
        '& p': {
            color: '#5E6E82',
            margin: 0
        },
        '& svg': {
            color: '#5E6E82',
            margin: 0
        }
    },
    styleDialog: {
        '& .MuiDialog-paperScrollPaper': {
            height: 'calc(100% - 170px)',
            width: 1000
        }
    },
})