import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { theme } from "../../../../App";
import { useSelector } from 'react-redux';
import { LibraryResourceTypeImage } from '../../../../Api';
import LazyLoading from './LazyLoading';

function CardResourceType(props) {
    const classes = useStyles(theme)();
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} onClick={props.onClick ? props.onClick : ''}>
            <div className={classes.box}>
                {props.libraryResourceType.image &&
                    <LazyLoading path={LibraryResourceTypeImage + props.libraryResourceType.image} type={'imageType'} />
                }
                {props.libraryResourceType.label && <p style={{ color: '#228B9F', textAlign: 'center', fontWeight: 500, margin: 5, fontSize: 20 }}>{props.libraryResourceType.label}</p>}
            </div>
            <br/>
        </Grid>

    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        border: 'solid 1px #F2F2F2',
        background: '#fff',
        padding: 0,
        margin: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: 15,
        cursor: 'pointer',
    }
}));

export default CardResourceType;
