import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import TableComponent from "../../../../Component/TableComponent";
import Api from "../../../../Api";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment } from "@material-ui/icons";
import { getRoutePathname } from "../../../../Config/Route";
import { useSelector } from 'react-redux';
import { Box, makeStyles } from '@material-ui/core';

function HotlineEmployeeList(props) {
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);

    
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('institution_health_crm_employee', { id: row.prospectId }))}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: { filter: true, sort: true }
        },
        {
            name: 'contact',
            label: 'Contact',
            options: { filter: true, sort: true }
        },
        {
            name: 'email',
            label: 'Email du contact',
            options: { filter: true, sort: true }
        },
        {
            name: 'authorComment',
            label: 'Commentaire',
            options: { filter: true, sort: true }
        },
        {
            name: 'hotlineTypeName',
            label: 'Demande',
            options: { filter: true, sort: true }
        },
        {
            name: 'createdAt',
            label: 'Créé le',
            options: { filter: true, sort: true }
        },
        {
            name: 'supportComment',
            label: 'Commentaire du support',
            options: { filter: true, sort: true }
        },
        {
            name: 'treatedAt',
            label: 'Suivi',
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Mes hotlines',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    <TableComponent
                        id={'institution_health_crm_hotline_list'}
                        title={'Hotlines'}
                        columns={columns}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_crm_hotline_list',
                                data: { organismId: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].createdAt = moment(data[index].createdAt).format('ll');
                                        data[index].treatedAt = data[index].treatedAt ? moment(data[index].treatedAt).format('ll') : 'En cours...';
                                        data[index].supportComment = data[index].supportComment ? data[index].supportComment : '-';
                                        data[index].action = getAction(data[index]);
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
}));

export default HotlineEmployeeList;