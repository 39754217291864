import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Edit} from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";

export default function CPF(props = {}) {
    const dispatch = useDispatch();

    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [formCPF, setFormCPF] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Saisissez une année.',
            type: 'integer',
            defaultValue: '',
            options: {
                validation: ['required'],
                max: 2100,
                min: 1999
            }
        },
        code: {
            name: 'code',
            label: 'Code',
            textHelper: 'Saisissez un code.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required']
            }
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Saisissez un organisme.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la durée totale de la formation (C+CV+HC en second).',
            type: 'integer',
            defaultValue: 0,
            options: {}
        },
        priceHT: {
            name: 'priceHT',
            label: 'prix HT',
            textHelper: 'Saisissez un prix.',
            type: 'float',
            defaultValue: 0,
            options: {}
        }
    });
    const [dialogCPF, setDialogCPF] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handlerCPF = formHandlerInit(formCPF, setFormCPF);
    React.useEffect(handlerCPF.start, []);

    const saveCPF = () => {
        if (handlerCPF.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            handlerCPF.setFormLoading(true);
            setLoading(true);
            let data = handlerCPF.getData();

            Api.post({
                    route: 'content_manager_formation_cpf',
                    data: data,
                    params: {id: props.match.params.id}
                },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setDialogCPF(false);
                    handlerCPF.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        dispatch(TableReload('content_manager_formation_cpf_list'));
                        dispatch(
                            SnackbarOpen({
                                text: 'CPF enregistré.',
                                variant: 'success',
                            })
                        );
                        handlerCPF.reset();
                    } else if (response.status === 400) {
                        handlerCPF.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        console.log(row);
                        handlerCPF.setValue('code', row.code);
                        handlerCPF.setValue('year', row.year);
                        handlerCPF.setValue('organism', row.organism);
                        handlerCPF.setValue('duration', row.duration);
                        handlerCPF.setValue('priceHT', row.priceHT);
                        setDialogCPF(true);
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const columnsCPFs = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'code',
            label: 'Code',
            options: {filter: true, sort: true}
        },
        {
            name: 'year',
            label: 'Année',
            options: {filter: true, sort: true}
        },
        {
            name: 'duration',
            label: 'Durée',
            options: {filter: true, sort: true}
        },
        {
            name: 'priceHT',
            label: 'Prix HT',
            options: {filter: true, sort: true}
        },
        {
            name: 'organismName',
            label: 'Organisme',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: ' ',
            options: {filter: true, sort: true}
        },
        {
            name: "action",
            label: "Action",
            options: {filter: false, sort: false}
        }
    ];
    React.useEffect(() => {
        Api.get({
                route: 'select_organisms',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
    }, [])

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'id', direction: 'desc'}}
                id={'content_manager_formation_cpf_list'}
                title={'CPF'}
                columns={columnsCPFs}
                actionFirst={{
                    label: 'Ajouter ou éditer un code CPF',
                    onClick: () => {
                        handlerCPF.reset();
                        setDialogCPF(true)
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_formation_cpf_list',
                            params: {id: props.match.params.id}
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].createdAt = moment(data[index].createdAt).fromNow();
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialogCPF} maxWidth={'xl'} onClose={() => setDialogCPF(false)}>
                <DialogTitle style={{fontSize: 15}}>Ajouter ou éditer un code CPF</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'year'} handler={handlerCPF}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'code'} handler={handlerCPF}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <SelectComponent name={'organism'} handler={handlerCPF} options={optionOrganism}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'duration'} handler={handlerCPF}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextFieldComponent name={'priceHT'} handler={handlerCPF}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogCPF(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => saveCPF()} disabled={loading}/>
                </DialogActions>
            </Dialog>
        </>
    )
}
