const ChartColorOpacity = [
    'rgba(54, 162, 235, 0.7)',
    'rgba(204, 101, 254, 0.7)',
    'rgba(255, 206, 86, 0.7)',
    'rgba(234, 225, 223, 0.7)',
    'rgba(239, 148, 108, 0.7)',
    'rgba(196, 167, 125, 0.7)',
    'rgba(123, 158, 168, 0.7)',
    'rgba(255, 99, 132, 0.7)',
    'rgba(179, 239, 178, 0.7)',
    'rgba(111, 208, 140, 0.7)'
];

export default ChartColorOpacity;