
import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {makeStyles} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import {getRoutePathname} from "../../../Config/Route";
import TitleComponent from "../../../Component/TitleComponent";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Fade from "@material-ui/core/Fade";
import SelectComponent from "../../../Component/SelectComponent";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import TextFieldComponent from '../../../Component/TextFieldComponent';

function FormationGroupProperty(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Saisissez l\'année des propriétés.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Choisissez le groupe de formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        timeYear: {
            name: 'timeYear',
            label: 'Temps de formation',
            textHelper: 'Choisissez le temps de formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        priceByHour: {
            name: 'priceByHour',
            label: 'Prix par heure',
            textHelper: 'Choisissez le prix par heure.',
            type: 'float',
            defaultValue: '',
            options: {validation: ['required']}
        }
    });
    const [loading, setLoading] = React.useState(false);
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                Api.post({
                    route: 'administrator_formation_group_property_edit',
                    params: {id: props.match.params.id},
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'administrator_formation_group_property_add',
                    data: handler.getData()
                }, (response) => {
                    console.log(response);
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('administrator_formation_group_property', {id: response.data.id}));
                        dispatch(
                            SnackbarOpen({
                                text: 'Propriétés créé avec succès.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'select_formation_groups',
                data: {'isFull': true}
            },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });
    }, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer les propriétés',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_formation_group_property_list'), label: 'Liste des propriétés de groupe de formation'}
                ]
            });
            setIsEdition(true);

            Api.get({
                    route: 'administrator_formation_group_property_get',
                    params: {id: props.match.params.id}
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('administrator_formation_group_property_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setBreadcrumbs({
                title: 'Ajouter des propriétés de groupe de formation',
                context: 'Administrateur',
                description: '',
                links: [
                    {path: getRoutePathname('administrator_formation_group_property_list'), label: 'Liste des propriétés de groupe de formation'}
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);
    
    React.useEffect(() => {
        dispatch(TableReload('administrator_formation_group_property_list'));
    }, [form.formationGroup.value]);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Fade in={isReadyContent} {...{timeout: 500}}>
                <div>
                    <ShadowBoxComponent className={classes.shadowBox}>
                        <TitleComponent title={isEdition ? 'Formulaire d\'édition des propriétés' : 'Formulaire de création des propriétés'}/>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextFieldComponent name={'year'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextFieldComponent name={'timeYear'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                <TextFieldComponent name={'priceByHour'} handler={handler}/>
                            </Grid>
                        </Grid>
                        <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading}/>
                    </ShadowBoxComponent>
                </div>
            </Fade>
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        paddingBottom: 60
    },
    button: {
        margin: 15,
        bottom: 0,
        right: 0,
        position: 'absolute'
    }
});

export default FormationGroupProperty;
