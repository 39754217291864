import React from 'react'
import Api from '../../../../Api';
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { Box, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import TemplatesComponent from '../../../../Component/DraggableListComponent/TemplatesComponent';
import DraggableListComponent from '../../../../Component/DraggableListComponent/DraggableListComponent';
import SweetAlert from "sweetalert-react";
import Question from '../Question/Question';

const HandlerDraggableList = () => {
    const [formTemplate1, setFormTemplate1] = React.useState({
        question: {
            name: 'question',
            title: 'Question',
            textHelper: 'Saisissez une question',
            type: 'text',
            typeInput: 'inputText',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handlerTemplate1 = formHandlerInit(formTemplate1, setFormTemplate1);
    React.useEffect(handlerTemplate1.start, []);
    return { handlerTemplate1 }
}

const Templates = ({ item, dragHandleProps }) => {
    const draggableListHandlers = HandlerDraggableList();
    return (
        <TemplatesComponent
            item={item}
            dragHandleProps={dragHandleProps}
            handlerTemplate={{
                template1: {
                    handler: draggableListHandlers.handlerTemplate1,
                },
            }}
            alertMessage={'Êtes-vous sur de vouloir supprimer cette question ?'}
        />
    )
}

export default function FormationQuestion({ props = {}, setIsOpen }) {
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const draggableListHandlers = HandlerDraggableList();
    const classes = useStyles(screenReducer)();
    const [listQuestionDeleted, setListQuestionDeleted] = React.useState([]);
    const [deleteList, setDeleteList] = React.useState([]);
    const [listQuestion, setListQuestion] = React.useState([]);
    const [dataQuestion, setDataQuestion] = React.useState({ data: {}, order: '', });
    const [loading, setLoading] = React.useState(true);
    const [isTransition, setIsTransition] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const save = (error) => {
        if (!error) {
            setLoading(true)
            Api.post({
                route: 'institution_health_content_manager_formation_formation_question_action',
                data: {
                    list: listQuestion,
                    listDeleted: listQuestionDeleted.filter((value) => !listQuestion.some((item) => item.id === value.id)),
                },
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    getQuestion()
                    dispatch(
                        SnackbarOpen({
                            text: 'Questinnaire enregistrée.',
                            variant: 'success',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const getQuestion = () => {
        if (props.match.params.id) {
            Api.get({
                route: 'institution_health_content_manager_formation_formation_question_list',
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    let data = response.data;
                    setListQuestion(data);
                    setListQuestionDeleted(data);
                } else {
                    setListQuestion([]);
                    setListQuestionDeleted([]);
                }
            })
        }
    }

    React.useEffect(() => getQuestion(), [])
    return (
        <>
            <Box className={classes.subMenu}
                style={{
                    position: 'absolute',
                    transform: 'translate(-50%,-50%)',
                    top: '50%', left: isTransition ? '-50%' : '50%',
                    visibility: isTransition ? 'hidden' : 'visible',
                    opacity: isTransition ? 0 : 1
                }}>
                <DraggableListComponent
                    list={listQuestion}
                    title={'Questionnaire Pre/Post'}
                    templates={Templates}
                    loading={loading}
                    onListChange={(newList) => { setListQuestion(newList) }}
                    setAction={{
                        setList: (newList) => { setListQuestion(newList) },
                    }}
                    handlerTemplates={{
                        template1: draggableListHandlers.handlerTemplate1,
                    }}
                    buttonTransition={{
                        title: 'Voir les réponses',
                        data: (data, order) => { setDataQuestion({ data: data, order: order }) },
                        isTransition: (isActive) => {
                            setIsTransition(isActive);
                            setDeleteList(listQuestionDeleted.filter((value) => !listQuestion.some((item) => item.id === value.id)))
                        }
                    }}
                    titleButtonAdd={'Ajouter une question'}
                    errorListMessage={'Aucune question n\'a été ajoutée'}
                    buttonAllDelete={{
                        title: 'supprimer les questions',
                        alertMessage: 'Êtes-vous sur de vouloir supprimer toute les questions ?',
                    }}
                    saveAction={save}
                    cancelSaveAction={() => {
                        if (JSON.stringify(listQuestion) === JSON.stringify(listQuestionDeleted)) { setIsOpen(false) } else { setShowConfirm(true) }
                    }}
                    buttonStyleOff
                />
            </Box >
            <Box style={{
                width: screenReducer.screen === 'XL' ? 1100 : '90%',
                position: 'absolute',
                transform: 'translate(-50%,-50%)',
                top: '50%', left: !isTransition ? '150%' : '50%',
                visibility: !isTransition ? 'hidden' : 'visible',
                opacity: !isTransition ? 0 : 1,
                height: '90%',
                transitionProperty: 'all', transitionDuration: '.5s',
            }}>
                <Question
                    props={props}
                    list={listQuestion}
                    deletedQuestionList={deleteList}
                    onChange={(newList) => { setListQuestion(newList) }}
                    dataQuestion={dataQuestion}
                    isBack={setIsTransition}
                    loadingQuestion={getQuestion}
                />
            </Box>
            <SweetAlert
                show={showConfirm}
                title={'Le questionnaire a été modifié et n\'a pas été enregistré.'}
                text={'Êtes-vous sûr(e) de vouloir quitter le questionnaire?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => { 
                    setShowConfirm(false)
                    setTimeout(() => setIsOpen(false), 100);
                 }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
const useStyles = (screenReducer) => makeStyles({
    subMenu: {
        width: screenReducer.screen === 'XL' ? 1100 : '90%',
        height: '90%',
        borderRadius: 8,
        overflow: 'hidden',
        flexDirection: screenReducer.screen === 'XL' ? 'row' : 'column',
        display: 'flex',
        margin: 0,
        transitionProperty: 'all', transitionDuration: '.5s',
        boxShadow: '0px 0px 11px #00000082',
    },
});
