import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import { useDispatch } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Fade, Grid, makeStyles, Slide, Zoom } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ButtonComponent from '../../../Component/ButtonComponent';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import moment from 'moment';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import TitleComponent from '../../../Component/TitleComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { TableReload } from '../../../Action/TableAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { getRoutePathname } from '../../../Config/Route';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import SelectComponent from '../../../Component/SelectComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import FilterListIcon from '@material-ui/icons/FilterList';

function AndpcEmailTrackingList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [showDialog, setShowDialog] = React.useState(false);
    const [loadingCheck, setLoadingCheck] = React.useState(false);
    const [responseCheck, setResponseCheck] = React.useState(false);
    const [row, setRow] = React.useState();
    const [showRequestReject, setShowRequestReject] = React.useState(false);
    const [optionOutlookMailAndpcStates, setOptionOutlookMailAndpcStates] = React.useState([]);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "codeFormation",
            label: "Code formation",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom prospect",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStart",
            label: "Début de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEnd",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "profession",
            label: "Profession",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "adress",
            label: "Adresse",
            options: { filter: true, sort: true }
        },
        {
            name: "rpps",
            label: "Numéro RPPS",
            options: { filter: true, sort: true }
        },
        {
            name: "dpcParticipation",
            label: "Participation Agence DPC",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date de réçeption",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    // filter
    const [formSearch, setFormSearch] = React.useState({
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par états.',
            type: 'text',
            defaultValue: 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        dispatch(TableReload('financial_management_andpc_email_tracking_list'));
    };

    // Action
    const getAction = (row) => {
        return (
            <div style={{ minWidth: 90 }}>
                {(row.stateId === 1 || row.stateId === 2) &&
                    <Tooltip title={'Procédure d\'inscription'} placement="left">
                        <IconButton onClick={() => {
                            setShowDialog(true);
                            setLoadingCheck(true);
                            setRow(row);
                            Api.get({
                                route: 'financial_management_andpc_email_tracking_prospect_check',
                                params: { id: row.id },
                                data: { email: row.email, rpps: row.rpps, codeFormation: row.codeFormation }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        setResponseCheck(response.data);
                                        setLoadingCheck(false);
                                    }
                                    else {
                                        setShowDialog(true);
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                })
                        }}>
                            <PermDataSettingIcon />
                        </IconButton>
                    </Tooltip>
                }
                {(row.stateId === 1) &&
                    <Tooltip title={'Rejeter la demande'} placement="left">
                        <IconButton onClick={() => {
                            setRow(row);
                            setShowRequestReject(true);
                        }}>
                            <NotInterestedIcon />
                        </IconButton>
                    </Tooltip>
                }
                {(row.stateId === 3 || row.stateId === 4 || row.stateId === 5) &&
                    <>
                        <Tooltip title={'Accéder à la fiche client'} placement="left">
                            <IconButton onClick={() => {
                                props.history.push(getRoutePathname('crm_prospect', { id: row.prospect }))
                            }}>
                                <AssignmentIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </div>
        );
    };

    // UseEffect
    React.useEffect(handlerSearch.start, []);
    React.useEffect(() => {

        // get state options
        Api.get({
            route: 'select_outlook_mail_andpc_states'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionOutlookMailAndpcStates(data);
                }
            });

        setBreadcrumbs({
            title: 'Suivi des emails ANDPC',
            context: 'CRM'
        });
        setReadyContent(true);
    }, []);

    // Save
    const saveProspect = () => {
        setLoadingCheck(true);
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'financial_management_andpc_email_tracking_prospect_add',
            params: { id: row.id },
            data: {
                email: row.email,
                rpps: row.rpps,
                name: row.name,
                profession: row.profession,
                phone: row.phone,
                adress: row.adress,
                codeFormation: row.codeFormation
            }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    responseCheck.isProspectExist = true;
                    dispatch(TableReload('financial_management_andpc_email_tracking_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Prospect créé.',
                            variant: 'success',
                        })
                    );
                    row.prospect = response.data.prospect;
                    setTimeout(() => {
                        setLoadingCheck(false);
                    }, 1000);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setShowDialog(false);
                }
            });
    };
    const saveRegistration = () => {

        let sessionStart = moment(row.sessionStart, 'D MMM. YYYY');
        let sessionEnd = moment(row.sessionEnd, 'D MMM. YYYY');

        setLoadingCheck(true);
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'financial_management_andpc_email_tracking_prospect_register',
            params: { id: row.id, prospect: row.prospect },
            data: {
                codeFormation: row.codeFormation,
                sessionStart: sessionStart.format("YYYY-MM-DD"),
                sessionEnd: sessionEnd.format("YYYY-MM-DD")
            }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    responseCheck.isRegistrationProspectExist = true;
                    dispatch(TableReload('financial_management_andpc_email_tracking_list'));
                    row.registration = response.data.registration;
                    dispatch(
                        SnackbarOpen({
                            text: 'Inscription enregistrée.',
                            variant: 'success',
                        })
                    );
                    setTimeout(() => {
                        setLoadingCheck(false);
                    }, 1000);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setShowDialog(false);
                }
            });
    };
    const saveAvailable = (isYes) => {
        setLoadingCheck(true);
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'financial_management_andpc_email_tracking_registration_validate_dpc',
            params: { id: row.id, registration: row.registration },
            data: { available: isYes }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    let text;
                    if (response.data.isAvailable === true) {
                        text = 'Validation acceptée. ';
                        responseCheck.isAvailable = true;
                    }
                    else if (response.data.isAvailable === false) {
                        text = 'Validation rejetée. ';
                    }
                    dispatch(
                        SnackbarOpen({
                            text: `${text} Redirection sur mondpc.fr ...`,
                            variant: 'success',
                        })
                    );
                    setTimeout(() => {
                        if (response.data && response.data.link) {
                            window.open(response.data.link);
                        }
                        setLoadingCheck(false);
                        setShowDialog(false);
                        dispatch(TableReload('financial_management_andpc_email_tracking_list'));
                    }, 2500);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setShowDialog(false);
                }
            });
    };
    const requestReject = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'financial_management_andpc_email_tracking_request_reject',
            params: { id: row.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    dispatch(TableReload('financial_management_andpc_email_tracking_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Demande rejetée, redirection sur mondpc.fr ...',
                            variant: 'success',
                        })
                    );
                    setTimeout(() => {
                        window.open(response.data.link);
                    }, 2500);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            })
    };

    const saveStateDuplicate = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'financial_management_andpc_email_tracking_state_duplicate',
            params: { id: row.id }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    dispatch(TableReload('financial_management_andpc_email_tracking_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                setShowDialog(false);
            })
    }

    // Render
    const RenderDialog = () => {
        if (responseCheck) {
            if (!responseCheck.isProspectExist) {
                return (
                    <div>
                        <TitleComponent title={<div className={classes.titleWithIcon}><span>Ajouter un prospect</span><PersonAddIcon /></div>} />
                        <div className={classes.dialogText}>
                            <p>Ce prospect n'est pas enregistré dans le CRM.</p>
                            <br />
                            <p><span style={{ fontWeight: 600 }}>Nom / Prenom : </span>{row.name}</p>
                            <p><span style={{ fontWeight: 600 }}>Numéro RPPS / IPS : </span>{row.rpps}</p>
                            <p><span style={{ fontWeight: 600 }}>Profession : </span>{row.profession}</p>
                            <p><span style={{ fontWeight: 600 }}>Adresse e-mail : </span>{row.email}</p>
                            {row.phone && <p><span style={{ fontWeight: 600 }}>Téléphone : </span>{row.phone}</p>}
                            {row.adress && <p><span style={{ fontWeight: 600 }}>Adresse : </span>{row.adress}</p>}
                            <br />
                            <p>Voulez-vous créer le client à partir de ces informations ?</p>
                        </div>
                        <div className={classes.buttonContainer}>
                            <ButtonComponent onClick={saveProspect} label={'Oui'} />
                            <ButtonComponent onClick={() => setShowDialog(false)} color={'#5E6E82'} label={'Annuler'} />
                        </div>
                    </div>
                )
            }
            else if ((responseCheck.isProspectExist && !responseCheck.isRegistrationProspectExist)) {
                return (
                    <div>
                        <TitleComponent title={<div className={classes.titleWithIcon}><span>Inscription</span><PersonAddIcon /></div>} />
                        <div className={classes.dialogText}>
                            <p>Le prospect est bien enregistré mais l'inscription est manquante ou incomplète.</p>
                            <br />
                            <p><span style={{ fontWeight: 600 }}>Code formation : </span>{row.codeFormation}</p>
                            <p><span style={{ fontWeight: 600 }}>Nom de la formation : </span>{responseCheck.formationName}</p>
                            <p><span style={{ fontWeight: 600 }}>Début de session : </span>{row.sessionStart}</p>
                            <p><span style={{ fontWeight: 600 }}>Fin de session : </span>{row.sessionEnd}</p>
                            <br />
                            <p>Voulez-vous proçéder à l'inscription ? </p>
                        </div>
                        <div className={classes.buttonContainer}>
                            <ButtonComponent onClick={saveRegistration} label={'Oui'} />
                            <ButtonComponent onClick={() => setShowDialog(false)} color={'#5E6E82'} label={'Annuler'} />
                        </div>
                    </div>
                )
            }
            else if ((responseCheck.isProspectExist && responseCheck.isRegistrationProspectExist && !responseCheck.isAvailable)) {
                return (
                    <div>
                        <TitleComponent title={<div className={classes.titleWithIcon}><span>Validation DPC</span><AssignmentTurnedInIcon /></div>} />
                        <div className={classes.dialogText}>
                            <p>L'inscription du prospect est enregistrée.</p>
                            <p><span style={{ fontWeight: 600 }}>Frais pédagogiques pris en charge par l’Agence du DPC : </span> {row.dpcParticipation.props.children[0]} €</p>
                            <p>Voulez-vous procéder à la validation DPC ?</p>
                        </div>
                        <div className={classes.buttonContainer}>
                            <ButtonComponent onClick={() => saveAvailable(true)} label={'Oui'} />
                            <ButtonComponent onClick={() => saveAvailable(false)} label={'Non'} />
                            <ButtonComponent onClick={() => setShowDialog(false)} color={'#5E6E82'} label={'Annuler'} />
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className={classes.dialogText}>
                        <p>Le client, l'inscription et la validation ANDPC ont déjà été remplis.</p>
                        <p>Vérifier les infos sur la fiche client.</p>
                        <div className={classes.buttonContainer}>
                            <ButtonComponent onClick={saveStateDuplicate} label={'OK'} />
                            <ButtonComponent onClick={() => setShowDialog(false)} color={'#5E6E82'} label={'Annuler'} />
                        </div>
                    </div>
                )
            }
        }
        else {
            return (
                <div className={classes.dialogText}>
                    <p>Trop d'informations manquantes pour ce mail ... </p>
                </div>
            )
        }
    }

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>

            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconFilter} />Filtre</>} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionOutlookMailAndpcStates} onChange={(val) => inputSearchChange('state', val)} />
                            </Grid>
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />

            <TableComponent
                id={'financial_management_andpc_email_tracking_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Liste des emails ANDPC'}
                columns={columns}
                promiseData={(resolve) => {
                    let datas = handlerSearch.getData();
                    Api.get({
                        route: 'financial_management_andpc_email_tracking_list',
                        data: { 
                            state: (datas.state === 'all') ? '' : datas.state
                        }
                    },
                        (response) => {
                            let data = response.data;
                            for (let index in data) {
                                data[index].action = getAction(data[index]);
                                data[index].sessionStart = moment(data[index].sessionStart).format('ll');
                                data[index].sessionEnd = moment(data[index].sessionEnd).format('ll');
                                data[index].createdAt = moment(data[index].createdAt).format('lll');
                                data[index].dpcParticipation = <span style={{ color: '#db770a', fontWeight: 600 }}>{data[index].dpcParticipation} €</span>;
                                let color = '#000';
                                switch (data[index].stateId) {
                                    case 1: // À traiter
                                    case 2: // En cours de traitement
                                        color = '#35A2EB';
                                        break;
                                    case 3: // Traité et ANDPC validé
                                        color = '#28a745';
                                        break;
                                    case 4: // Traité et ANDPC non validé
                                        color = '#dc3545';
                                        break;
                                    case 5: // Réitération
                                        color = '#ad732c';
                                        break;
                                }
                                data[index].state = <span style={{ color: color, width: 85, display: 'block' }}>{data[index].state}</span>;
                            }
                            resolve(data);
                        });
                }}
            />

            <br />

            <Dialog open={showDialog} maxWidth={'xl'} onClose={() => setShowDialog(false)}>
                <DialogTitle style={{ color: '#5E6E82' }}>Procédure d'inscription</DialogTitle>
                <DialogContent style={{ position: 'relative' }}>
                    <div className={classes.loadingContent}>
                        <Fade in={loadingCheck} {...{ timeout: 500 }}>
                            <div className={classes.loaderContent}>
                                <UseAnimations animation={activity} size={40} />
                            </div>
                        </Fade>
                    </div>
                    <Zoom in={!loadingCheck} {...{ timeout: 500 }}>
                        <div>
                            <RenderDialog />
                        </div>
                    </Zoom>
                </DialogContent>
            </Dialog>

            <br />

            {row && <SweetAlert
                show={showRequestReject}
                title={'Rejeter la demande'}
                text={'La participation DPC est de : ' + row.dpcParticipation.props.children[0] + '€\nÊtes-vous sur de vouloir rejeter cette demande ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowRequestReject(false);
                    requestReject();
                }}
                onCancel={() => setShowRequestReject(false)}
            />}
        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
            margin: 5
        }
    },
    loaderContent: {
        width: 40,
        margin: '0px auto 20px auto',
        padding: 10,
        borderRadius: 50,
        boxShadow: '0 5px 10px 0px rgba(0,0,0,0.25)',
    },
    dialogText: {
        fontSize: 15,
        textAlign: 'left',
        fontWeight: 500,
        paddingBottom: 20
    },
    loadingContent: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
    },
    titleWithIcon: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
    },
    iconFilter: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    }
});

export default AndpcEmailTrackingList;
