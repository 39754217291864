import React, { Fragment } from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Drawer as DrawerMUI, makeStyles, Typography } from "@material-ui/core";
import { Delete, NavigateBefore } from "@material-ui/icons";
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import FilterPaginationComponent from '../../../Component/FilterPaginationComponent';
import CommentIcon from '@material-ui/icons/Comment';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import EditorComponent from "../../../Component/EditorComponent";
import Popover from '@material-ui/core/Popover';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import SweetAlert from 'sweetalert-react';
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import { Edit } from "@material-ui/icons";
import { theme } from "../../../App";
import moment from "moment";
import CardCommunityPost from './Component/CardCommunityPost';

function CommunityGroup(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [isGroup, setGroup] = React.useState(false);
    const [isPermission, setPermssion] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loadImg, setLoadImg] = React.useState(false);
    const [isEdition, setIsEdition] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [communityPostId, setCommunityPostId] = React.useState({});
    const [communityGroupsList, setCommunityGroupsList] = React.useState([]);
    const [communityPostsList, setCommunityPostsList] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isDeletedPost, setIsDeletedPost] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const filter = [
        {
            title: 'Postes',
            data: 'isPostProspect',
            type: 'button',
        },
        {
            data: 'title',
            type: 'textField',
        },
    ];

    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre',
            textHelper: 'Saisissez le titre du posts.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        subTitle: {
            name: 'subTitle',
            label: 'Sujet de discussion',
            textHelper: 'Saisissez un sujet de discussion.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });

    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoad(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                Api.post({
                    route: 'learner_community_post_edit',
                    params: { communityPost: communityPostId, communityGroup: props.match.params.communityGroup },
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setIsOpen(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    loadData();
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'learner_community_post_add',
                    data: handler.getData(),
                    params: { communityGroup: props.match.params.communityGroup }
                }, (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setIsOpen(false)
                    if (response.status === 201) {
                        props.history.push(getRoutePathname('learner_community_post', { communityGroup: props.match.params.communityGroup, communityPost: response.data.post }));
                        dispatch(
                            SnackbarOpen({
                                text: 'discussion créé avec succès.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const saveOrDeleted = (isGroup) => {
        if (!isGroup) {
            Api.post({
                route: 'learner_community_group_add',
                data: { communityGroup: props.match.params.communityGroup }
            }, (response) => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    setPermssion(response.data.isPermission)
                    setGroup(true)
                    loadData()
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );

                } else if (response.status === 404) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        } else {
            setShowConfirm(true);
        }
    };

    const loadData = () => {
        setLoad(true)
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'learner_community_group_get',
            params: { communityGroup: props.match.params.communityGroup }
        },
            (response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setLoad(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setCommunityGroupsList(response.data);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_community'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(getRoutePathname('learner_community'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        if (props.match.params.communityGroup) {
            Api.get({
                route: 'learner_community_post_list',
                params: { communityGroup: props.match.params.communityGroup }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        for (let index in data) {
                            data[index].createdAt = moment(data[index].createdAt).format("lll");
                            data[index].isPostProspect = data[index].isPostProspect === true ? 'Mes postes' : 'Autres postes';
                        }
                        setCommunityPostsList(data);
                        setLoad(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else {
                        setCommunityPostsList([]);
                    }
                });
        } else {
            setCommunityPostsList([]);
        }
    }

    const editAction = (id) => {
        if (id && props.match.params.communityGroup) {
            setIsEdition(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.get({
                route: 'learner_community_post_get',
                params: { communityPost: id, communityGroup: props.match.params.communityGroup }
            },
                (response) => {
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            setIsEdition(false);
        }
    }

    const getAction = (row, handleClose) => {
        return (
            <Typography style={{ padding: 8 }}>
                <IconButton className={classes.buttonPostProspect}
                    onClick={() => {
                        editAction(row.id);
                        handler.reset();
                        setIsEdition(true);
                        setIsOpen(true)
                        handleClose()
                        setCommunityPostId(row.id);
                    }}>
                    <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Modifier</span>
                </IconButton>
                <IconButton
                    className={classes.buttonPostProspect}
                    onClick={() => {
                        setActionRow(row);
                        setIsDeletedPost(true);
                        setShowConfirm(true);
                        handleClose();
                    }}>
                    <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0' }} /> <span>Supprimer</span>
                </IconButton>
            </Typography>
        );
    };

    const viewList = (dataList) => {
        return (
            <CardCommunityPost
                getAction={getAction}
                data={dataList}
                isGroup={isGroup}
                propsData={props}
            />
        )
    }

    React.useEffect(handler.start, []);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM' || screenReducer.screen === 'MD'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        setPermssion(communityGroupsList.isPermission);
        setGroup(communityGroupsList.isGroup);
    }, [communityGroupsList.isGroup, communityGroupsList.isPermission]);

    React.useEffect(() => {
        loadData();
        setBreadcrumbs({
            currentPage: 'Groupe',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_community'), label: 'Forum' },
            ]
        });
    }, []);

    return (
        <>
            <BreadcrumbsComponent breadcrumbs={breadcrumbs} {...props} />
            <Box style={{ textAlign: 'center', maxWidth: 1000, margin: 'auto' }}>
                {
                    loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader}>
                                <Box className={classes.loaderContent} style={isSmallView ? { width: 225 } : {}}>
                                    <ShadowBoxComponent>
                                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.loaderLogo} />
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={35} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Box>
                            </Box>
                        </Fade>
                        :
                        <Box>
                            <br />
                            <Box className={classes.boxComponent}>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={() => props.history.push(getRoutePathname('learner_community'))} style={isSmallView ? { width: 'calc(100% - 30px)', marginLeft: 15 } : {}}>
                                    <NavigateBefore className={classes.shadowBoxBackIcon} />
                                    <span className={classes.titleGroup} >Tous les groupes</span>
                                </ShadowBoxComponent>
                                <Fade in={true} {...{ timeout: 500 }}>
                                    <Box>
                                        <ShadowBoxComponent className={classes.shadowBox} style={{ height: isSmallView ? 'auto' : 300, border: '0', boxShadow: 'initial', background: "transparent", }} >
                                            <Grid container spacing={0} style={{ height: '100%' }}>
                                                <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ position: 'relative', }}>
                                                    <Box className={isSmallView ? classes.cardImageXS : classes.cardImage}>
                                                        {!loadImg && <img style={{ zIndex: 1 }} src="/asset/images/preload_image.png" alt={'Image formation'} />}
                                                        <img style={{ zIndex: 0 }} src={communityGroupsList.image ? "/api/learner/home/formation-image/" + communityGroupsList.image : "/asset/images/discussion_group.webp"} alt={'Image formation'} onLoad={() => setLoadImg(true)} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{ position: "relative", }}>
                                                    <Box className={isSmallView ? classes.boxInfoGroupXS : classes.boxInfoGroup}>
                                                        <p className={isSmallView ? classes.boxNameGroupXS : classes.boxNameGroup} style={{ display: 'flex' }}>
                                                            <span>{communityGroupsList.name}</span>
                                                            <sup>{communityGroupsList.organismImg && communityGroupsList.organismImg.length != 0 &&
                                                                <div className={classes.boxOrganism}>
                                                                    <Tooltip title={'Organisme'} placement="center">
                                                                        <IconButton onClick={handleClick}>
                                                                            <GroupWorkIcon style={{ color: "#5E6E82" }} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Popover
                                                                        id={id}
                                                                        open={open}
                                                                        anchorEl={anchorEl}
                                                                        onClose={handleClose}
                                                                        className={classes.popover}
                                                                        anchorOrigin={{
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right',
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    >
                                                                        <Typography>
                                                                            {Object.keys(communityGroupsList.organismImg).map((id, index) =>
                                                                                <img key={index} src={communityGroupsList.organismImg[id] && "/asset/images/" + communityGroupsList.organismImg[id]} className={id != 2 ? classes.iconOrganism : classes.iconOrganism_500x500} style={{ padding: "5px", display: 'block', margin: 'auto', position: "relative" }} />
                                                                            )}
                                                                        </Typography>
                                                                    </Popover>
                                                                </div>
                                                            }</sup>
                                                        </p>
                                                        <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                                            <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> Groupe créer le {moment(communityGroupsList.createdAt).format("lll")}
                                                        </p>
                                                        <p style={{ fontWeight: 400, fontSize: 17, margin: "0", padding: "10px 0" }}>{communityGroupsList.numGroup > 99 ? '99+' : communityGroupsList.numGroup} <span>membres,</span> {communityGroupsList.numPost > 99 ? '99+' : communityGroupsList.numPost} <span>postes</span></p>

                                                        <Grid container spacing={0} style={{ marginTop: isSmallView ? 0 : "32px" }}>
                                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ maxWidth: "max-content" }}>
                                                                <ButtonComponent
                                                                    small={true}
                                                                    className={isGroup ? classes.buttonV2 : classes.buttonV1}
                                                                    style={{ borderRadius: isGroup && isPermission ? "5px 0px 0px 5px" : 5 }}
                                                                    label={isGroup ? 'Quitter' : 'Rejoindre'}
                                                                    onClick={() => { saveOrDeleted(isGroup); setIsDeletedPost(false); }}
                                                                    disabled={loading}
                                                                />
                                                            </Grid>
                                                            {(isGroup && isPermission) &&
                                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ maxWidth: "max-content" }}>
                                                                    <ButtonComponent
                                                                        small={true}
                                                                        className={classes.buttonV1}
                                                                        label={'Créer une discussion'}
                                                                        onClick={() => {
                                                                            setIsOpen(true)
                                                                            setIsEdition(false);
                                                                            handler.reset();
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </ShadowBoxComponent>
                                    </Box>
                                </Fade>
                            </Box>
                            <br />
                            <FilterPaginationComponent
                                data={communityPostsList}
                                title={'Groupe de discussion'}
                                icon={<CommentIcon />}
                                filter={filter}
                                pageNumber={7}
                                viewList={viewList}
                                loading={load}
                                loadData={loadData}
                                error={'Aucun postes'}
                            />
                        </Box>
                }
                <br />
                <Fragment>
                    <DrawerMUI className={classes.subMenu} anchor={'right'} open={isOpen} onClose={() => setIsOpen(false)}>
                        <br />
                        <Grid container spacing={3} style={{ width: '80%', margin: '0 auto', }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p style={{ color: "#5E6E82", fontSize: 33, margin: "auto", fontWeight: "bold", borderLeft: "10px solid", padding: " 10px 30px", }}>{isEdition ? 'Édition de votre groupe de Discussion' : 'Création d\'un groupe de Discussion'}</p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'title'} handler={handler} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <EditorComponent name={'subTitle'} handler={handler} />
                            </Grid>
                        </Grid>
                        <Grid container style={{ width: '100%' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <ButtonComponent color={'#5E6E82'} style={{ width: '50%', padding: 15, borderRadius: 0 }} label={'Annuler'} onClick={() => setIsOpen(false)} disabled={load} />
                                <ButtonComponent label={'Enregistrer'} style={{ width: '50%', padding: 15, borderRadius: 0, backgroundColor: theme.colorPrimary }} onClick={() => { save() }} disabled={load} />
                            </Grid>
                        </Grid>
                    </DrawerMUI>
                </Fragment>

                <br />

                <SweetAlert
                    show={showConfirm}
                    title={isDeletedPost ? 'Supprimer' : 'Quitter'}
                    text={isDeletedPost ? 'Êtes-vous sûr de vouloir supprimer ce poste ?' : 'Êtes-vous sûr de vouloir quitter ce groupe ?'}
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    className={classes.sweetAlert}
                    onConfirm={() => {
                        setShowConfirm(false);
                        if (isDeletedPost) {
                            Api.delete({
                                route: 'learner_community_post_delete',
                                params: { communityPost: actionRow.id, communityGroup: props.match.params.communityGroup }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        loadData()
                                    } else {
                                        props.history.push(getRoutePathname('learner_home'));
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Une erreur inattendue s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        } else {
                            Api.delete({
                                route: 'learner_community_group_delete',
                                params: { id: props.match.params.communityGroup }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        props.history.push(getRoutePathname('learner_community'));
                                    }
                                });
                        }

                    }}
                    onCancel={() => setShowConfirm(false)}
                />
            </Box>
        </>
    );
}

const useStyles = (theme) => makeStyles(({
    boxPosts: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    chipBox: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    chip: {
        margin: '3px 3px 3px 1px',
        fontSize: 7,
        fontWeight: 700,
        color: '#FFFFFF',
        backgroundColor: '#03a9f4',
        '&.MuiChip-sizeSmall': {
            height: 15
        }
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    cardImageXS: {
        height: 270,
        width: '100%',
        maxWidth: 280,
        borderRadius: 10,
        position: 'relative',
        border: '3px solid rgb(223, 223, 223)',
        overflow: 'hidden',
        margin: 'auto',
        '& img': {
            height: '100%',
            width: '100%',
            position: 'absolute',
            display: 'block',
            zIndex: 1
        },
    },
    cardImage: {
        height: '84%',
        width: '100%',
        maxWidth: 280,
        position: 'absolute',
        transform: 'translate(-0%, -50%)',
        borderRadius: 10,
        overflow: 'hidden',
        border: '3px solid rgb(223, 223, 223)',
        top: '50%',
        left: '0%',
        '& img': {
            height: '100%',
            width: '100%',
            position: 'absolute',
            display: 'block',
        },
    },
    boxInfoGroup: {
        padding: "0px 37px",
        textAlign: "left",
        height: "70%",
        position: "absolute",
        transform: "translate(-0%,-50%)",
        top: "50%",
        left: "0%",
        width: "90%",
    },
    boxNameGroup: {
        fontWeight: 700,
        fontSize: 22,
        margin: "0",
        padding: "10px 0 0 0",

        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "98%"
    },
    boxInfoGroupXS: {
        padding: "0px 37px",
        textAlign: "left",
        height: "70%",
        top: "50%",
        left: "0%",
        width: "90%",
    },
    boxNameGroupXS: {
        fontWeight: 700,
        fontSize: 22,
        margin: "0",
        padding: "10px 0 0 0",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "98%"
    },
    cardText: {
        color: '#228B9F',
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    },
    cardHour: {
        position: 'absolute',
        fontWeight: 700,
        backgroundColor: theme.colorPrimary,
        color: '#FFFFFF',
        padding: '5px 5px',
        borderRadius: '0 0 5px 0',
        margin: 0,
        fontSize: 12,
        top: 0,
        left: 0,
        zIndex: 1
    },
    cardHourIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    cardState: {
        position: 'absolute',
        fontWeight: 700,
        color: '#FFFFFF',
        padding: '3px 5px',
        borderRadius: 20,
        margin: 5,
        fontSize: 12,
        bottom: 0,
        right: 0
    },
    cardStateIcon: {
        fontSize: 16,
        verticalAlign: 'bottom'
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonV1: {
        textTransform: "initial",
        fontSize: 12,
        padding: "5px 33px",
        boxShadow: "initial",
        borderRadius: "0px 5px 5px 0px",
        border: "1px solid " + theme.colorPrimary,
        backgroundColor: theme.colorPrimary + "!important",
    },
    buttonV2: {
        textTransform: "initial",
        fontSize: 12,
        padding: "5px 33px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px " + theme.colorPrimary,
        color: theme.colorPrimary
    },
    sweetAlert: {
        '& .sa-icon': {
            margin: '0px auto !important',
        },
        '& .sweet-alert h2': {
            lineHeight: '23px !important',
            margin: "0 !important"
        },
        '& .sweet-alert p br': {
            display: 'none'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: "max-content",
        fontSize: 14,
        textAlign: 'start',
        marginTop: 0,
        padding: 8,
        cursor: 'pointer',
        boxShadow: "0 0 0 0",
        background: "transparent"
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle',
        fontSize: "1.8rem",
        transform: "translate(0, -10%)"
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    buttonPostProspect: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        "& span": {
            justifyContent: 'left'
        }
    },
    titleGroup: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "left",
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    iconOrganism_500x500: {
        width: 47,
        height: "auto",
    },
    iconOrganism: {
        width: 113,
        height: "auto",
    },
    subMenu: {
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 600,
        }
    },
    drawerIconClose: {
        right: 0,
        padding: 12,
        fontSize: 18,
        zIndex: 2,
        cursor: 'pointer'
    },
    boxOrganism: {
        bottom: 4,
        right: 10,
        '& .MuiSvgIcon-root': {
            width: 18,
            height: 18
        },
        '& .MuiButtonBase-root': {
            padding: 4,
            color: 'rgb(123, 123, 123)',
            margin: '0 5px',
        },
    },
    popover: {
        '& .MuiPaper-elevation8': {
            boxShadow: '0px 1px 5px rgba(0,0,0,0.2)',
        },
        '& .MuiTypography-root': {
            padding: 7,
        }
    },
}));

export default CommunityGroup;
