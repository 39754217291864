const ScreenReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ScreenChange':
            return action.data;
        case 'ScreenFull':
            return {...state, full: action.data.full};
        default:
            return state
    }
};

export default ScreenReducer;
