import React from 'react';
import Api from "../../../../Api";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";
import SelectComponent from '../../../../Component/SelectComponent';
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import moment from 'moment';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import CheckboxComponent from '../../../../Component/CheckboxComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { dispatch } from '../../../../App';
import { useSelector } from 'react-redux';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { TableReload } from '../../../../Action/TableAction';
import SweetAlert from "sweetalert-react";

function MultipleAdd(props = {
    onClose: () => {
    }
}) {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [optionEmployees, setOptionEmployees] = React.useState([]);
    const [optionFormation, setOptionFormation] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [isCheckMap, setIsCheckMap] = React.useState(false);
    const [employees, setEmployees] = React.useState([]);
    const [loadingFormFormation, setLoadingFormFormation] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState({ bool: false, text: '' });
    const [optionYears, setOptionYears] = React.useState([
        { value: (parseInt(moment().format('YYYY')) - 1), label: (parseInt(moment().format('YYYY')) - 1) },
        { value: parseInt(moment().format('YYYY')), label: parseInt(moment().format('YYYY')) },
        { value: (parseInt(moment().format('YYYY')) + 1), label: (parseInt(moment().format('YYYY')) + 1) }
    ]);
    const [form, setForm] = React.useState({
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {},
        },
        employees: {
            name: 'employees',
            label: 'Employés',
            textHelper: 'Choisissez les employés.',
            type: 'array',
            defaultValue: [],
            options: { validation: ['required'] }
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: { validation: ['required'], }
        },
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const changeForm = (index, value, formationSessionId = '') => {
        setLoadingFormFormation(true);
        let newForm = { ...handler.form };
        if (index === 'year') {
            newForm['year'].value = value;
            newForm['year'].error = value === '' ? handler.getErrorByField(newForm['formation']) : '';
        }
        if (index === 'formation'){
            newForm['formation'].value = value;
            newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        }
        handler.set(newForm);
        setLoadingFormFormation(false);
        loadSession(fullSessions, formationSessionId);
        loadEmployee();
    };

    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);
        const formationIdTMP = form['formation'].value;
        const year = form['year'].value;
        let newForm = { ...handler.form };
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);

        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                route: 'institution_health_crm_institution_registration_formation_session_formation_list',
                params: { institution: props.match.params.institution, formation: formationIdTMP },
                data: { full: full ? 1 : 0, organism: selectOrganismReducer.organism.value, year: year }
            },
                (response) => {
                    handler.setFormLoading(false);
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                });
        }
        else {
            setOptionsSessionFormation([]);
        }
    };

    const loadEmployee = (employees = []) => {
        const formationIdTMP = form['formation'].value;
        const year = form['year'].value;
        let newForm = { ...handler.form };
        newForm['employees'].value = employees;
        newForm['employees'].error = employees.length !== 0 ? handler.getErrorByField(newForm['employees']) : '';
        handler.set(newForm);
        if (formationIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                    route: 'select_institution_employees',
                    params: { institution: props.match.params.institution },
                    data: { formation: formationIdTMP, year: year }
                },
                (response) => {
                    handler.setFormLoading(false);
                    if (response && response.data) {
                        setOptionEmployees(response.data);
                    }
                });
        }
        else {
            setOptionEmployees([]);
        }
    };

    const changeEmployees = (value) => {
        const employeesInValueNotInEmployees = value.find(item => !employees.includes(item));
        const employeesInEmployeesNotInValue = employees.find(item => !value.includes(item));
        if (employeesInValueNotInEmployees) {
            let newForm = {};
            const addedEmployeeLabel = optionEmployees.find(option => option.value === employeesInValueNotInEmployees)?.label;
            newForm['financeType_' + employeesInValueNotInEmployees] = {
                name: 'financeType_' + employeesInValueNotInEmployees,
                label: 'Type de financement',
                textHelper: 'Saisissez un type de financement.',
                type: 'integer',
                defaultValue: '',
                options: { validation: ['required'] },
                employeeLabel: addedEmployeeLabel,
                employeeId: employeesInValueNotInEmployees,
                nameIsAvailable: 'isAvailable_' + employeesInValueNotInEmployees,
                nameAvailable: 'availableAt_' + employeesInValueNotInEmployees
            };
            newForm['isAvailable_' + employeesInValueNotInEmployees] = {
                name: 'isAvailable_' + employeesInValueNotInEmployees,
                label: 'Disponibilité',
                textHelper: 'Validez la disponibilité de la formation.',
                type: 'boolean',
                defaultValue: false,
                options: {},
            };
            newForm['availableAt_' + employeesInValueNotInEmployees] = {
                name: 'availableAt_' + employeesInValueNotInEmployees,
                label: 'Date de disponibilité',
                textHelper: 'Saisissez une date de disponibilité.',
                type: 'date',
                defaultValue: '',
                options: {},
            };
            setForm({ ...form, ...newForm });
        }
        else if (employeesInEmployeesNotInValue) {
            const { [`financeType_${employeesInEmployeesNotInValue}`]: financeTypeToRemove, ...remainingForm } = form;
            setForm({ ...remainingForm });
        }
        setEmployees(value);
    }

    const save = () => {

        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            let datas = {};
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            let data = handler.getData();

            datas = {
                year: data.year,
                sessionFormation: data.sessionFormation,
                organism: selectOrganismReducer.organism.value,
                employees: data.employees.map((employeeId) => ({
                    id: employeeId,
                    name: form[`financeType_${employeeId}`].employeeLabel,
                    financeType: data[`financeType_${employeeId}`],
                    isAvailable: data[`isAvailable_${employeeId}`] ? true : false,
                    availableAt: data[`availableAt_${employeeId}`] || null,
                })),
            };

            Api.post({
                route: 'institution_health_crm_institution_registration_formation_add',
                data: datas,
                params: {
                    institution: props.match.params.institution,
                    formation: data.formation,
                }
            },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 200) {
                        props.onClose();
                        if (response.data) {
                            if (response.data.errorEmployeesRegistration && response.data.errorEmployeesRegistration.length > 0) {
                                let data = response.data.errorEmployeesRegistration;
                                let text = '';
                                for (let index in data) {
                                    text += 'Nom : ' + data[index].nameEmployee + ' \n ' + 'Message : ' + data[index].message + ' \n\n ';
                                }
                                setShowError({ bool: true, text: text });
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Toutes les inscriptions sont validées.',
                                        variant: 'success',
                                    })
                                );
                            }
                        }
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        if (response.error.message) {
                            dispatch(SnackbarOpen({ text: response.error.message, variant: 'error' }));
                        }
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(TableReload('institution_health_crm_institution_registration_list'));
                });
        }
    };

    React.useEffect(() => {
        handler.setFormLoading(true);

        Api.get({
            route: 'select_finance_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (response.data[index].value !== '8') {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionFinanceType(data);
                }
            });

        Api.get({
            route: 'institution_health_crm_institution_registration_formation_list',
            params: { institution: props.match.params.institution },
            data: { organism: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormation(response.data);
                }
            });

        handler.setFormLoading(false);
    }, []);

    return (
        <>
            <DialogTitle style={{ fontSize: 15 }}>{'Ajouter des inscriptions'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                        <SelectComponent name={'year'} handler={handler} options={optionYears} onChange={(val) => {changeForm('year', val)}}/>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionFormation} onChange={(val) => {changeForm('formation', val)}} />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true} disabled={loadingFormFormation} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <MultipleSelectComponent name={'employees'} handler={handler} options={optionEmployees} onChange={changeEmployees} disabled={loadingFormFormation} />
                    </Grid>
                </Grid>
                {Object.entries(form).filter(([key]) => key.startsWith('financeType')).length > 0 && (
                    <p>Détails par employés sélectionnés :</p>
                )}
                <Grid container spacing={1} style={{ boxShadow: '0px 0px 2px 0px #9E9E9E' }}>
                    {Object.entries(form)
                        .filter(([key]) => key.startsWith('financeType'))
                        .map(([key, value]) => (
                            <Grid item key={key} xs={12} sm={12} md={12} lg={12} style={{ borderBottom: '1px solid #9E9E9E' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={2} lg={2}>
                                        <p>{value.employeeLabel}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={4}>
                                        <SelectComponent name={value.name} handler={handler} options={optionFinanceType} />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={2} lg={2}>
                                        <CheckboxComponent
                                            name={value.nameIsAvailable}
                                            handler={handler}
                                            disabled={!(handler.form['sessionFormation'].value)}
                                            onChange={(val) => {
                                                setIsCheckMap({ ...isCheckMap, [value.nameIsAvailable]: val });
                                                if (val === true) {
                                                    handler.setValue(value.nameAvailable, moment());
                                                }
                                                else if (val === false) {
                                                    handler.setValue(value.nameAvailable, '');
                                                }
                                            }}
                                        />
                                    </Grid>
                                    {isCheckMap[value.nameIsAvailable] &&
                                        <Grid item xs={9} sm={9} md={4} lg={4}>
                                            <DatePickerComponent name={value.nameAvailable} handler={handler} />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={props.onClose} disabled={loading} />
                <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
            </DialogActions>

            <SweetAlert
                show={showError.bool}
                title={'Attention, ces inscriptions n\'ont pas abouti'}
                text={showError.text}
                type={'warning'}
                confirmButtonText={'Ok'}
                onConfirm={() => setShowError({ bool: false, text: '' })}
            />
        </>
    );
}

export default MultipleAdd;
