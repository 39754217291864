const FireworksReducer = (state = {}, action) => {
    switch (action.type) {
        case 'FireworksActivate':
            return {
                ...state,
                isActivated: true,
                text: action.text,
                duration: action.duration
            };
        case 'FireworksDeactivate':
            return {
                ...state,
                isActivated: false
            };
        default:
            return state
    }
};

export default FireworksReducer;
