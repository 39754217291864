import React from "react";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box, makeStyles } from "@material-ui/core";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ButtonComponent from "../../../../Component/ButtonComponent";
import CountUp from "react-countup";


const CardInstitutionGroupComponent = (props) => {
    const classes = useStyles();
    return (
        <ShadowBoxComponent style={{ height: '100%', display: 'grid', padding: 20 }}>
            <Box style={{ height: '100%' }}>
                <h1 style={{ margin: 0, fontSize: 20, display: 'flex', alignItems: 'center', gap: 2 }}><GroupWorkIcon style={{ width: 20, height: 20 }} />Groupement d'établissement</h1>
                <p style={{ margin: 0, fontSize: 15 }}>Liste de groupement d'établissement.</p>
            </Box>
            <Box style={{ height: '100%', textAlign: 'center', display: 'grid', }}>
                <p style={{ margin: 0, fontSize: 15, fontWeight: 'bold' }}>Totale de groupement</p>
                <p style={{ margin: '10px 0', fontSize: 40 }}>{props.data.institutionGroupCount > 9999 ? '+9 999' : <CountUp separator=" " end={props.data.institutionGroupCount ? props.data.institutionGroupCount : 0} />}</p>
            </Box>
            <Box style={{ height: '100%', display: 'flex', }}>
                {props.button.isAccess &&
                    <ButtonComponent
                        style={props.button.style}
                        color={props.button.background} label={props.button.title}
                        onClick={() => { props.button.onClick() }}
                    />
                }
            </Box>
        </ShadowBoxComponent>
    )
}

const useStyles = makeStyles({})

export default CardInstitutionGroupComponent;