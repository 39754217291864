import React from 'react';
import { Switch, Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from "./Component/AppBar";
import Drawer from "./Component/Drawer";
import { useHistory } from 'react-router-dom';
import Api from "../../Api";

export default function MainLayout(props) {
    const classes = useStyles();
    const history = useHistory();

    React.useEffect(() => {
        const unlisten = history.listen((location, action) => {
            Api.cancelAll();
        });

        return () => {
            unlisten();
        };
    }, []);

    return (
        <div className={classes.mainLayout}>
            {/* <== AppBar */}
            <AppBar {...props} />
            {/* AppBar ==> */}

            {/* <== Drawer */}
            {
                <Drawer {...props} />
            }
            {/* Drawer ==> */}

            {/* <== View */}
            <div className={classes.view}>
                <Switch>
                    {
                        props.routes.map((route) => (
                            <Route key={'route_' + route.id} path={route.path} render={props => <route.view {...props} routes={route.routes} />} />
                        ))
                    }
                </Switch>
            </div>
            {/* View ==> */}
        </div>
    );
}

const useStyles = makeStyles({
    mainLayout: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box',
        display: 'flex'
    },
    view: {
        flexGrow: 1,
        overflowX: 'scroll',
        paddingTop: 58,
        boxSizing: 'border-box',
        background: '#EFF2F4',
        '& .MuiOutlinedInput-root': {
            //borderRadius: 20
        },
        '& .MuiFormControl-root': {
            '& .MuiFormLabel-root': {
                fontFamily: 'Trebuchet MS',
                //color: '#516375 !important',
            },
            '& legend': {
                fontFamily: 'Trebuchet MS',
                //color: '#516375 !important',
            },
        },
        '& .Mui-disabled': {
            //color: '#51637585 !important',
        },
        '& .MuiCheckbox-root': {
            //color: '#516375',
            padding: '5px 0px'
        },
        '& .MuiButton-containedPrimary': {
            //backgroundColor: '#516375'
        },
        '& .MuiButton-root': {
            //borderRadius: 9,
            boxShadow: 'none',
        }
    }
});
