import { Box, Checkbox, Fade, IconButton, Tooltip, Zoom, makeStyles, withStyles } from '@material-ui/core';
import { Assignment, CloudDownload, Equalizer, Mail, PersonAddDisabled, Timer, TimerOff } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import React from 'react';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import Api from '../../../Api';
import { dispatch } from '../../../App';
import TableComponent from '../../../Component/TableComponent';
import { getRoutePathname } from '../../../Config/Route';
import { hourBySecondClassic } from "../../../Tool/convert";
import {TableReload} from "../../../Action/TableAction";
import CircularProgress from "@material-ui/core/CircularProgress";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#5f5f5f',
      color: '#FFF',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      maxWidth: 'none',
    },
  }))(Tooltip);

function ProgressUnit(props) {

    const getInfo = (value, type) => {
        let color = '#DADADA';
        let status = '';

        if (value.isCompleted) {
            color = '#28a745';
            status = 'Complété'
        }
        else {
            color = '#dc3545';
            status = 'Non complété'
        }

        if (value.error.length > 0) {
            let foundErrorDate = value.error.some(element => element.includes('date'));
            if (foundErrorDate) {
                color = '#dc3545';
            }
            else {
                color = '#f59912';
            }
            status = 'Erreur potentielle détecté'
        }

        if (props.state === 2 || props.state === 3) {
            color = '#DADADA';
        }

        if (type === 'color') {
            return color;
        }
        else if (type === 'status') {
            return status;
        }
    };

    const getTooltip = (value) => {

        return <div style={{ fontSize: 11 }}>
            <p style={{ fontSize: 13, textAlign: 'center' }}>Unité {value.orderBy}: {value.type}</p>
            <p style={{margin: 2}}>Status: {getInfo(value, 'status')}</p>
            <p style={{margin: 2}}>Complétion : {value.completionCourse} %</p>
            {
                (value.error && value.error.length > 0) &&
                <>
                    {value.error.map((error, i) => {
                        return (
                            <p key={i} style={{ display: 'flex', alignItems: 'center'}}>
                                <WarningIcon color="secondary" fontSize="small" style={{padding: 5}} />
                                <span dangerouslySetInnerHTML={{ __html: error }} />
                            </p>
                        )
                    })}
                </>
            }
        </div>
    }

    return <>
        <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-evenly">
            {(props.value && props.value.length > 0) ?
                props.value.map((prop, i) => (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <HtmlTooltip title={(props.state === 2 || props.state === 3) ? '' : getTooltip(prop)} TransitionComponent={Zoom} arrow key={i}>
                            <span
                                style={{
                                    width: 25,
                                    height: 13,
                                    color: '#fff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    borderRadius: 6,
                                    border: '1px solid' + getInfo(prop, 'color'),
                                    background: getInfo(prop, 'color') + 'e6',
                                    marginRight: 3,
                                    marginBottom: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                {prop.orderBy}
                            </span>
                        </HtmlTooltip>
                        <span style={{fontSize: 11, fontWeight: 600}}>{prop.completionCourse}%</span>
                    </div>

                ))
                :
                <Tooltip title={'Unitées non renseignées'}>
                    <WarningIcon color="secondary" fontSize="small" />
                </Tooltip>
            }
        </Box>
    </>
}

function Registrations(props = {
    reloadList: () => {
    }
}) {
    const classes = useStyles();
    const [registrationCancel, setRegistrationCancel] = React.useState(null);
    const [propsReady, setPropsReady] = React.useState(false);
    const [disabledCheckbox, setDisabledCheckbox] = React.useState(false);

    let columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "finishAtFormat",
            label: "Formation terminée le",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAtFormat",
            label: "Date d\'inscription",
            options: { filter: true, sort: true }
        },
        {
            name: "durationTotalSessionText",
            label: "Temps Total",
            options: { filter: true, sort: true }
        },
        {
            name: "durationTotalText",
            label: "-- Temps --",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "completionByUnitComponent",
            label: "Complétion des unités",
            options: { filter: true, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    if (Object.keys(props.sessionFormation).length > 0) {
        if (props.sessionFormation.registrations.length > 0) {
            let registrations = props.sessionFormation.registrations;
            let processedKeys = new Set();

            for (let index in registrations) {
                let certificates = registrations[index]['offlineCertificate'];
                for (let key in certificates) {
                    if (!processedKeys.has(key)) {
                        columns.splice(columns.length - 1, 0,
                            {
                                name: certificates[key].type,
                                label: "Certificat " + certificates[key].type,
                                options: { filter: true, sort: true }
                            }
                        );
                        processedKeys.add(key);
                    }
                }
            }
        }
    }

    const cancel = () => {
        setRegistrationCancel(null);
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'financial_management_relaunch_registration_cancel',
            params: { registration: registrationCancel }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );
                    props.reloadList();
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    };

    const getAction = (row, sessionFormation) => {
        return (
            <div style={{ width: props.isSuperVision ? 310 : 180 }}>
                <Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname('crm_prospect', { id: row.prospect }), '_blank').focus();
                        }} disabled={row.state === 3}>
                            <Assignment />
                        </IconButton>
                    </span>
                </Tooltip>

                <Tooltip title={'Télécharger l\'attestation de participation'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            const url = Api.route({
                                route: 'financial_management_billing_v2_session_formation_download_participation_certificate',
                                params: { id: props.match.params.id },
                                data: { registration: row.id }
                            });
                            window.open(url, '_blank');
                        }} disabled={row.state === 2 || row.state === 3 || row.state === 7 || !sessionFormation.isInvoice}>
                            <CloudDownload />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Envoyer l\'attestation de participation au client'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            Api.get({
                                route: 'financial_management_billing_v2_session_formation_send_participation_certificate',
                                params: { id: props.match.params.id },
                                data: { registration: row.id }
                            },
                                (response) => {
                                    dispatch(LinearProgressMainLayoutDeactivate());
                                    if (response.status === 200) {
                                        dispatch(
                                            SnackbarOpen({
                                                text: 'Attestation envoyée.',
                                                variant: 'success',
                                            })
                                        );
                                    }
                                    else {
                                        dispatch(
                                            SnackbarOpen({
                                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                variant: 'error',
                                            })
                                        );
                                    }
                                });
                        }} disabled={row.state === 2 || row.state === 3 || row.state === 7 || !sessionFormation.isInvoice || (sessionFormation.isInvoice && row.state !== 4)}>
                            <Mail />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Désinscription'} placement="left">
                    <span>
                        <IconButton
                            onClick={() => { setRegistrationCancel(row.id); }}
                            disabled={row.state === 2 || row.state === 3 || row.state === 4 || moment().isBefore(moment(sessionFormation.startAt, 'DD/MM/YYYY'))}>
                            <PersonAddDisabled />
                        </IconButton>
                    </span>
                </Tooltip>
                {props.isSuperVision &&
                    <>
                        <Tooltip title={'Équilibrer le temps'} placement="left">
                            <span>
                                <IconButton onClick={() => {
                                    dispatch(LinearProgressMainLayoutActivate());
                                    Api.post({
                                        route: 'financial_management_supervision_registration_time_balancing',
                                        params: { id: row.id },
                                    },
                                        (response) => {
                                            dispatch(LinearProgressMainLayoutDeactivate());
                                            if (response.status === 200) {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: 'Temps équilibé.',
                                                        variant: 'success',
                                                    })
                                                );
                                            }
                                            else {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                        variant: 'error',
                                                    })
                                                );
                                            }
                                        });
                                }} disabled={(row.state !== 1 && row.state !== 5 && row.state !== 6) || row.state === 7 || sessionFormation.isInvoice}>
                                    <Timer color='secondary' />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title={'Équilibrer la complétion'} placement="left">
                            <span>
                                <IconButton onClick={() => {
                                    dispatch(LinearProgressMainLayoutActivate());
                                    Api.post({
                                        route: 'financial_management_supervision_registration_completion_balancing',
                                        params: { id: row.id },
                                    },
                                        (response) => {
                                            dispatch(LinearProgressMainLayoutDeactivate());
                                            if (response.status === 200) {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: 'Temps équilibé.',
                                                        variant: 'success',
                                                    })
                                                );
                                            }
                                            else {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                        variant: 'error',
                                                    })
                                                );
                                            }
                                        });
                                }}>
                                    <Equalizer color='secondary' />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title={'Réinitialiser le temps'} placement="left">
                            <span>
                                <IconButton onClick={() => {
                                    dispatch(LinearProgressMainLayoutActivate());
                                    Api.post({
                                        route: 'financial_management_supervision_registration_time_course_clear',
                                        params: { id: row.id },
                                    },
                                        (response) => {
                                            dispatch(LinearProgressMainLayoutDeactivate());
                                            if (response.status === 200) {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: 'Temps équilibé.',
                                                        variant: 'success',
                                                    })
                                                );
                                            }
                                            else {
                                                dispatch(
                                                    SnackbarOpen({
                                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                                        variant: 'error',
                                                    })
                                                );
                                            }
                                        });
                                }}>
                                    <TimerOff color='secondary' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </>
                }
            </div>
        );
    };

    const getCheckbox = (certificate, registration) => {

        return (
            disabledCheckbox ? <CircularProgress size={15} className={classes.circularProgress}/> :
            <Checkbox
                key={certificate.type + new Date().getTime()}
                defaultChecked={!!certificate.isReceived}
                onChange={(event) => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setDisabledCheckbox(true);
                    dispatch(TableReload('financial_management_billing_v2_registration_list'));
                    Api.post({
                        route: 'financial_management_billing_v2_registration_edit',
                        data: {
                            'type': certificate.type,
                            'isReceived': event.target.checked,
                        },
                        params: { registration: registration.id }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                setDisabledCheckbox(false);
                                props.reloadList();
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Modification enregistrée.',
                                        variant: 'success',
                                    })
                                );
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
            />
        );
    };

    React.useEffect(() => {
        setPropsReady(true);
    }, [props.sessionFormation]);

    const getDataTable = (registrations, sessionFormation) => {
        let r = registrations;
        for (let index in r) {
            r[index]['finishAtFormat'] = r[index]['finishAt'] !== '-' ? moment(r[index]['finishAt']).format('lll') : '-';
            r[index]['availableAtFormat'] = r[index]['availableAt'] !== '-' ? moment(r[index]['availableAt']).format('lll') : '-';
            r[index]['durationTotalSessionText'] = <span>{hourBySecondClassic(r[index]['durationTotalSession'])}</span>;
            r[index]['durationTotalText'] = <span>{hourBySecondClassic(r[index]['durationTotal'])}</span>;
            r[index]['completionByUnitComponent'] = <div style={{ width: 180 }}><ProgressUnit value={r[index]['completionByUnit']} state={r[index]['state']} /></div>;

            switch (r[index]['state']) {
                case 1:
                    r[index]['stateText'] = <span style={{ color: '#28A745' }}>Validée</span>;
                    break;
                case 2:
                    r[index]['stateText'] = <span style={{ color: '#AD732C' }}>Annulée</span>;
                    break;
                case 3:
                    r[index]['stateText'] = <span style={{ color: '#DC3545' }}>Supprimée</span>;
                    break;
                case 4:
                    r[index]['stateText'] = <span style={{ color: sessionFormation.state === 2 ? '#35A2EB' : '#9c27b0' }}>{sessionFormation.state === 2 ? 'Facturée totale' : 'Facturée partielle'}</span>;
                    break;
                case 5:
                case 9:
                    r[index]['stateText'] = <span style={{ color: '#000000' }}>Non terminée</span>;
                    break;
                case 6:
                    r[index]['stateText'] = <span style={{ color: '#67327b' }}>Temps manquant</span>;
                    break;
                case 7:
                    r[index]['stateText'] = <span style={{ color: '#ad732c' }}>Non Facturable</span>;
                    break;
                case 8:
                    r[index]['stateText'] = <span style={{ color: '#67327b' }}>CV Temps manquant</span>;
                    break;
            }
            r[index]['action'] = getAction(r[index], sessionFormation);

            let certificates = r[index]['offlineCertificate'];
            for (let key in certificates) {
                r[index][certificates[key].type] = getCheckbox(certificates[key], r[index]);
            }
        }
        return r;
    };

    return (
        <>
            {
                propsReady ?
                    <div style={{ padding: 4, position: 'relative' }}>
                        {(props && props.sessionFormation && props.sessionFormation.registrations) &&
                            <TableComponent
                                id={'financial_management_billing_v2_registration_list'}
                                minHeight={'calc(100vh - 515px)'}
                                title={'Liste des inscriptions'}
                                onBeforeRefresh={props.reloadList}
                                columns={columns}
                                search={false}
                                promiseData={(resolve) => {
                                    if (props.sessionFormation && props.sessionFormation.registrations) {
                                        setTimeout(() => {
                                            resolve(getDataTable(props.sessionFormation.registrations, props.sessionFormation));
                                        }, 50);
                                    }
                                    else {
                                        resolve([])
                                    }
                                }}
                            />}
                        <br />
                    </div>

                    :

                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Box className={classes.loader}>
                            <Box className={classes.loaderContent}>
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={70} />
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
            }


            <SweetAlert
                show={registrationCancel ? true : false}
                title={'Désinscription'}
                text={'Vous êtes sur le point de désinscrire définitivement l\'inscription numéro ' + registrationCancel + '. \n\r Êtes-vous sûr de vouloir continuer ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={cancel}
                onCancel={() => setRegistrationCancel(null)}
            />
        </>
    );
};

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    }
});

export default Registrations;
