import { Box, Checkbox, Dialog, Grid, IconButton, LinearProgress, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TableComponent from '../../../../Component/TableComponent';
import { getRouteByPathname, getRoutePathname } from '../../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import Api from '../../../../Api';
import moment from 'moment';
import { cacheTablePage, cacheTableServer } from '../../../../Cache';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import FilterListIcon from "@material-ui/icons/FilterList";
import { Delete, PersonAddDisabled, Edit, Autorenew } from "@material-ui/icons";
import { Drawer as DrawerMUI } from "@material-ui/core";
import { theme } from '../../../../App';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import { TableReload } from '../../../../Action/TableAction';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SweetAlert from "sweetalert-react";
import MultipleAdd from './MultipleAdd';
import { hasRight } from '../../../../Tool/UserTool';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import RegistrationEdit from './RegistrationEdit';

function LinearProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export default function RegistrationList(props) {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [isScreen, setIsScreen] = React.useState(true);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isRoute, setIsRoute] = React.useState(false);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [optionCommercial, setOptionCommercial] = React.useState([]);
    const [optionState, setOptionState] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Terminer' },
        { value: 2, label: 'Annulée' },
        { value: 3, label: 'Supprimée' },
        { value: 4, label: 'Facturée' },
        { value: 5, label: 'Non terminée' },
        { value: 6, label: 'Pré-inscription' }
    ]);
    const [optionOrigin, setOptionOrigin] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [registrationDelete, setRegistrationDelete] = React.useState();
    const [registrationCancel, setRegistrationCancel] = React.useState();
    const [reRegistrationCancel, setReRegistrationCancel] = React.useState();
    const [showDialogAdd, setShowDialogAdd] = React.useState(false);
    const [rowEdit, setRowEdit] = React.useState(null);
    const screenReducer = useSelector(state => state.ScreenReducer);


    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupName",
            label: "Groupe de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeName",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "commercialName",
            label: "Commercial",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAt",
            label: "Début de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAt",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date création",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAt",
            label: "Date d'inscription",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Date de désinscription",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: false, sort: false }
        },
        {
            name: 'isAvailable',
            label: 'Disponibilité',
            options: { filter: true, sort: true }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "originName",
            label: "Origine de la registration",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    //--------- Form Filter ---------//
    const [formSearch, setFormSearch] = React.useState({
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].formationGroup ? cacheTableServer['institution_health_crm_institution_registration_list'].formationGroup : 'all',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Trier par email.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].email ? cacheTableServer['institution_health_crm_institution_registration_list'].email : '',
            options: {}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].formation ? cacheTableServer['institution_health_crm_institution_registration_list'].formation : 'all',
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].financeType ? cacheTableServer['institution_health_crm_institution_registration_list'].financeType : 'all',
            options: {}
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].commercial ? cacheTableServer['institution_health_crm_institution_registration_list'].commercial : 'all',
            options: {}
        },
        sessionStartAt: {
            name: 'sessionStartAt',
            label: 'Début de session',
            textHelper: 'Trier par début de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].sessionStartAt ? cacheTableServer['institution_health_crm_institution_registration_list'].sessionStartAt : '',
            options: {}
        },
        sessionEndAt: {
            name: 'sessionEndAt',
            label: 'Fin de session',
            textHelper: 'Trier par fin de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].sessionEndAt ? cacheTableServer['institution_health_crm_institution_registration_list'].sessionEndAt : '',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].state ? cacheTableServer['institution_health_crm_institution_registration_list'].state : 'all',
            options: {}
        },
        origin: {
            name: 'origin',
            label: 'Origine',
            textHelper: 'Trier par origine.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].origin ? cacheTableServer['institution_health_crm_institution_registration_list'].origin : 'all',
            options: {}
        }
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_crm_institution_registration_list'] = 0;
        cacheTableServer['institution_health_crm_institution_registration_list'].page = 0;
        cacheTableServer['institution_health_crm_institution_registration_list'][index] = value;
        dispatch(TableReload('institution_health_crm_institution_registration_list'));
    };
    React.useEffect(handlerSearch.start, []);
    //-------------------------------//

    //----------- Render ------------//
    const getAction = (row) => {
        return (
            <Box style={{ minWidth: 90 }}>
                {
                    ((!row.isLock && !row.isdeleted) || (!row.isdeleted && hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN'))) &&
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton onClick={() => {
                            setRowEdit(row);
                        }}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                }
                {
                    (!row.isLock && !row.isdeleted) &&
                    <Tooltip title={'Supprimer'} placement="left">
                        <IconButton onClick={() => setRegistrationDelete(row.id)} disabled={row.isStateInvoiced}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                }
                {
                    (!row.cancelAt && row.isLock && !row.isdeleted) &&
                    <Tooltip title={'Désinscription'} placement="left">
                        <IconButton onClick={() => setRegistrationCancel(row.id)}>
                            <PersonAddDisabled />
                        </IconButton>
                    </Tooltip>
                }
                {
                    (row.cancelAt && row.isLock && !row.isdeleted) &&
                    <Tooltip title={'Annuler la désinscription'} placement="left">
                        <IconButton onClick={() => setReRegistrationCancel(row.id)}>
                            <Autorenew />
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        )
    }

    const getCheckbox = (row) => {
        return (
            <Checkbox
                key={row.id + new Date().getTime()}
                defaultChecked={!!row.availableAt}
                disabled={row.isLock || !row.sessionFormation}
                onChange={(event) => {
                    dispatch(LinearProgressMainLayoutActivate());
                    Api.post({
                        route: 'institution_health_crm_institution_registration_edit',
                        data: {
                            'formation': row.formation,
                            'sessionFormation': row.sessionFormation,
                            'organism': selectOrganismReducer.organism.value,
                            'year': row.year,
                            'isAvailable': event.target.checked,
                            'availableAt': null,
                            'financeType': row.financeType
                        },
                        params: {
                            institution: props.match.params.institution,
                            registration: row.id
                        }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Modification enregistrée.',
                                        variant: 'success',
                                    })
                                );
                            }
                            else if (response.error && response.error.field && response.error.field[0].message) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.field[0].message ? response.error.field[0].message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }

                            dispatch(TableReload('institution_health_crm_institution_registration_list'));
                        });
                }}
            />
        );
    };

    const GetActionDowload = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent color={"#5E6E82"} label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>} onClick={() => setIsOpen(!isOpen)} />
            </Box>
        )
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'email'} handler={handlerSearch} onChange={(val) => inputSearchChange('email', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'financeType'} handler={handlerSearch} options={optionFinanceType} onChange={(val) => inputSearchChange('financeType', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercial} onChange={(val) => inputSearchChange('commercial', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'sessionStartAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionStartAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DatePickerComponent name={'sessionEndAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('sessionEndAt', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'state'} handler={handlerSearch} options={optionState} onChange={(val) => inputSearchChange('state', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'origin'} handler={handlerSearch} options={optionOrigin} onChange={(val) => inputSearchChange('origin', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const table = () => {
        return (
            <>
                <TableComponent
                    id={'institution_health_crm_institution_registration_list'}
                    title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><AssignmentIndIcon style={{ width: 20, height: 20 }} />Inscriptions</h1>}
                    columns={columns}
                    actionFirst={{
                        label: 'Ajouter des inscriptions',
                        onClick: () => { setShowDialogAdd(true); }
                    }}
                    promiseServerData={(resolve, options) => {
                        let data = handlerSearch.getData();
                        let filters = {
                            limit: options.rowsPerPage,
                            offset: options.page * options.rowsPerPage,
                            sortName: options.sortOrder.name,
                            sortDirection: options.sortOrder.direction,
                            formationGroup: (data.formationGroup === 'all' || !data.formationGroup) ? '' : data.formationGroup,
                            commercial: (data.commercial === 'all' || !data.commercial) ? '' : data.commercial,
                            financeType: (data.financeType === 'all' || !data.financeType) ? '' : data.financeType,
                            state: (data.state === 'all' || !data.state) ? '' : data.state,
                            origin: (data.origin === 'all' || !data.origin) ? '' : data.origin,
                            email: data.email ? data.email : '',
                            sessionStartAt: data.sessionStartAt ? data.sessionStartAt : '',
                            sessionEndAt: data.sessionEndAt ? data.sessionEndAt : '',
                            organism: selectOrganismReducer.organism.value
                        };

                        Api.get({
                            route: 'institution_health_crm_institution_registration_list',
                            params: { institution: props.match.params.institution },
                            data: filters
                        },
                            (response) => {
                                if (response.status === 200) {
                                    let data = response.data.data;
                                    for (let index in data) {
                                        data[index].action = getAction(data[index]);
                                        data[index].isAvailable = getCheckbox(data[index]);
                                        data[index].commercialName = data[index].commercialName ? data[index].commercialName : '-';
                                        data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                        data[index].createdAt = data[index].createdAt ? moment(data[index].createdAt.date).format('lll') : '-';
                                        data[index].sessionStartAt = data[index].sessionStartAt ? moment(data[index].sessionStartAt.date).format('ll') : '-';
                                        data[index].sessionEndAt = data[index].sessionEndAt ? moment(data[index].sessionEndAt.date).format('ll') : '-';
                                        data[index].availableAt = data[index].availableAt ? moment(data[index].availableAt.date).format('ll') : '-';
                                        data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt.date).format('lll') : '-';
                                        if (data[index].phone) {
                                            data[index].phone = data[index].phone;
                                        } else if(data[index].mobile){
                                            data[index].phone = data[index].mobile;
                                        } else {
                                            data[index].phone = '-'
                                        }
                                        switch (data[index]['state']) {
                                            case 1:
                                                data[index]['stateText'] = <span style={{ color: '#28A745' }}>Terminer</span>;
                                                break;
                                            case 2:
                                                data[index]['stateText'] = <span style={{ color: '#AD732C' }}>Annulée</span>;
                                                break;
                                            case 3:
                                                data[index]['stateText'] = <span style={{ color: '#DC3545' }}>Supprimée</span>;
                                                break;
                                            case 4:
                                                data[index]['stateText'] = <span style={{ color: '#35A2EB' }}>Facturée</span>;
                                                break;
                                            case 5:
                                                data[index]['stateText'] = <span style={{ color: '#000000' }}>Non terminée</span>;
                                                break;
                                            case 6:
                                                data[index]['stateText'] = <span style={{ color: '#000000' }}>Pré-inscription</span>;
                                                break;
                                        }
                                    }
                                    resolve(data, response.data.count);
                                }
                                else {
                                    resolve([]);
                                }
                            });
                    }}
                    search={false}
                />
            </>
        )
    }
    //--------------------------------//

    //--------Function action---------//
    const remove = () => {
        setRegistrationDelete(null);
        Api.post({
            route: 'institution_health_crm_institution_registration_delete',
            params: {
                institution: props.match.params.institution,
                registration: registrationDelete
            },
            data: { organism: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'L\'inscription a bien été supprimé.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('institution_health_crm_institution_registration_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const cancel = () => {
        setRegistrationCancel(null);
        Api.post({
            route: 'institution_health_crm_institution_registration_cancel',
            params: {
                institution: props.match.params.institution,
                registration: registrationCancel
            },
            data: { organism: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('institution_health_crm_institution_registration_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const reEnrollment = () => {
        setReRegistrationCancel(null);
        Api.post({
            route: 'institution_health_crm_institution_registration_re_enrollment',
            params: {
                institution: props.match.params.institution,
                registration: reRegistrationCancel
            },
            data: { organism: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été réinscrit à la formation.',
                            variant: 'success',
                        })
                    );

                    dispatch(TableReload('institution_health_crm_institution_registration_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    //--------------------------------//

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Inscriptions',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des inscriptions.',
            links: [
                { path: getRoutePathname('institution_health_crm_institution_manager', { institution: props.match.params.institution }), label: 'Tableau de bord' }
            ],
            html: <GetActionDowload />
        });
    }, [isOpen]);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        let currentRoute = getRouteByPathname(props.location.pathname);
        setIsRoute(currentRoute.id === 'institution_health_crm_institution');

        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormationGroup(data);
                }
            });

        Api.get({
            route: 'select_registration_origins'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionOrigin(data);
                }
            });

        Api.get({
            route: 'select_finance_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFinanceType(data);
                }
            });

        Api.get({
                route: 'institution_health_crm_commercial_list',
                data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionCommercial(data);
                }
            });

        setReadyContent(true);
    }, []);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <>
            {isRoute ?
                table()
                :
                <Box className={classes.styleContentView}>
                    <DrawerMUI
                        variant="permanent"
                        className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                        style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                        open={isOpen}>
                        {filter()}
                    </DrawerMUI>
                    <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                        <Box className={classes.TableComponent}>
                            {table()}
                        </Box>
                    </ContentViewComponent>
                </Box>
            }

            <Dialog open={showDialogAdd} fullWidth maxWidth={'xl'} onClose={() => setShowDialogAdd(false)}>
                <MultipleAdd {...props} onClose={() => setShowDialogAdd(false)} />
            </Dialog>

            <Dialog open={rowEdit ? true : false} fullWidth maxWidth={'xl'} onClose={() => setRowEdit(null)}>
                <RegistrationEdit {...props} row={rowEdit} onClose={() => setRowEdit(false)} />
            </Dialog>

            <SweetAlert
                show={registrationDelete ? true : false}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette inscription ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={remove}
                onCancel={() => setRegistrationDelete(null)}
            />

            <SweetAlert
                show={registrationCancel ? true : false}
                title={'Désinscription'}
                text={'Vous êtes sur le point de désinscription définitivement l\'inscription numéro ' + registrationCancel + '. \n\r Êtes-vous sûr de vouloir continuer ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={cancel}
                onCancel={() => setRegistrationCancel(null)}
            />

            <SweetAlert
                show={reRegistrationCancel ? true : false}
                title={'Résinscription'}
                text={'Vous êtes sur de vouloir réinscrire ce client à cette formation ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={reEnrollment}
                onCancel={() => setReRegistrationCancel(null)}
            />
        </>
    )
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = (theme) => makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
}))