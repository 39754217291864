const DrawerMainLayoutReducer = (state = {isOpen: true}, action) => {
    switch (action.type) {
        case 'DrawerMainLayoutOpen':
            return {...state, isOpen: true};
        case 'DrawerMainLayoutClose':
            return {...state, isOpen: false};
        default:
            return state
    }
};

export default DrawerMainLayoutReducer;
