const SchedulerColor = [
    'rgb(255, 99, 132)',
    'rgb(54, 162, 235)',
    'rgb(111, 208, 140)',
    'rgb(204, 101, 254)',
    'rgb(255, 206, 86)',
    'rgb(234, 225, 223)',
    'rgb(239, 148, 108)',
    'rgb(196, 167, 125)',
    'rgb(123, 158, 168)'
];

export default SchedulerColor;