import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch} from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Delete, Edit} from "@material-ui/icons";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import SelectComponent from "../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";
import MultipleSelectComponent from '../../../Component/MultipleSelectComponent';
import SweetAlert from 'sweetalert-react';

export default function Unit(props = {}) {
    const dispatch = useDispatch();
    const [dialog, setDialog] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [row, setRow] = React.useState();
    const [optionTypes, setOptionTypes] = React.useState([
        {value: 'EPP1', label: 'EPP1'},
        {value: 'EPP1_QUIZ', label: 'EPP1_QUIZ'},
        {value: 'EPP2', label: 'EPP2'},
        {value: 'EPP2_QUIZ', label: 'EPP2_QUIZ'},
        {value: 'FC', label: 'FC'},
        {value: 'CV', label: 'CV'},
    ]);
    const [optionChapters, setOptionChapters] = React.useState([]);
    const columns = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'type',
            label: 'Type',
            options: {filter: true, sort: true}
        },
        {
            name: 'orderBy',
            label: 'Ordre',
            options: {filter: true, sort: true}
        },
        {
            name: 'duration',
            label: 'Durée',
            options: {filter: true, sort: true}
        },
        {
            name: 'durationOffline',
            label: 'Durée hors-ligne',
            options: {filter: true, sort: true}
        },
        {
            name: 'chapter',
            label: 'Chapitres',
            options: {filter: true, sort: true}
        },
        {
            name: 'action',
            label: 'Action',
            options: {filter: true, sort: true}
        }
    ];
    const [form, setForm] = React.useState({
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Selectionnez un type d\'unité.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        orderBy: {
            name: 'orderBy',
            label: 'Ordre',
            textHelper: 'Saisissez l\'ordre de l\'unité.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        duration: {
            name: 'duration',
            label: 'Durée',
            textHelper: 'Saisissez la durée de l\'unité.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        durationOffline: {
            name: 'durationOffline',
            label: 'Durée hors-ligne',
            textHelper: 'Saisissez la durée hors-ligne de l\'unité.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        chapter: {
            name: 'chapter',
            label: 'Chapitre',
            textHelper: 'Selectionnez les chapitres pour cette unité.',
            type: 'array',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
                route: 'content_manager_formation_unit_get_formations_chapters',
                params: {formation: props.match.params.id}
            },
            (response) => {
                if (response && response.data) {
                    setOptionChapters(response.data);
                }
            });
    }, []);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        handler.setValue('type', row.type);
                        handler.setValue('orderBy', row.orderBy);
                        handler.setValue('duration', row.duration);
                        handler.setValue('durationOffline', row.durationOffline);
                        handler.setValue('chapter', row.chapterId);
                        setRow(row);
                        setDialog(true);
                    }}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = handler.getData();

            if (row) {
                Api.post({
                        route: 'content_manager_formation_unit_edit',
                        data: data,
                        params: {formation: props.match.params.id, unit: row.id}
                    },
                    (response) => {
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setFormLoading(false);
                        setLoading(false);

                        if (response.status === 200) {
                            dispatch(TableReload('content_manager_formation_unit_list'));
                            setDialog(false);
                            dispatch(
                                SnackbarOpen({
                                    text: 'Unité enregistré.',
                                    variant: 'success',
                                })
                            );
                            handler.reset();
                        }
                        else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
            }
            else {
                Api.post({
                        route: 'content_manager_formation_unit_add',
                        data: data,
                        params: {formation: props.match.params.id}
                    },
                    (response) => {
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setFormLoading(false);
                        setLoading(false);

                        if (response.status === 201) {
                            dispatch(TableReload('content_manager_formation_unit_list'));
                            setDialog(false);
                            dispatch(
                                SnackbarOpen({
                                    text: 'Unité crée.',
                                    variant: 'success',
                                })
                            );
                            handler.reset();
                        }
                        else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
            }
        }
    };

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'orderBy', direction: 'asc'}}
                id={'content_manager_formation_unit_list'}
                title={'Unités'}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une unitée',
                    onClick: () => {
                        handler.reset();
                        setRow(null);
                        setDialog(true);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_formation_unit_list',
                            params: {formation: props.match.params.id}
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].duration = data[index].duration ? data[index].duration : 0;
                                    data[index].durationOffline = data[index].durationOffline ? data[index].durationOffline : 0;
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            }
                            else {
                                resolve([]);
                            }
                        })
                    }
                    else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{row ? 'Éditer une unité' : 'Ajouter une unité'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectComponent name={'type'} handler={handler} options={optionTypes}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'orderBy'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'duration'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'durationOffline'} handler={handler}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <MultipleSelectComponent name={'chapter'} handler={handler} options={optionChapters}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={() => save()} disabled={loading}/>
                </DialogActions>
            </Dialog>

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'\nÊtes-vous sur de vouloir supprimer définitivement cette unité ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_formation_unit_delete',
                        params: {formation: props.match.params.id, unit: row.id}
                    }, (response) => {
                        dispatch(LinearProgressMainLayoutDeactivate());
                        if (response.status === 200) {
                            dispatch(TableReload('content_manager_formation_unit_list'));
                            dispatch(
                                SnackbarOpen({
                                    text: 'Unité supprimé.',
                                    variant: 'success',
                                })
                            );
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    })
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
