import React from 'react';
import {makeStyles} from "@material-ui/core";
import UseAnimations from "react-useanimations";
import facebook from "react-useanimations/lib/facebook";
import twitter from "react-useanimations/lib/twitter";
import linkedin from "react-useanimations/lib/linkedin";
import ParticlesComponent from "../../Component/ParticlesComponent";
import {useSelector} from "react-redux";
import ShadowBoxComponent from "../../Component/ShadowBoxComponent";
import {theme} from "../../App";

function Maintenance() {
    const classes = useStyles();
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);
        }
    }, [screenReducer.screen]);

    return (
        <div className={classes.content}>

            {!isSmallScreen && <ParticlesComponent/>}

            <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox} ${classes.shadowBoxShow}`}>
                <img src={'/asset/images/maintenance.png'} alt="logo" className={classes.image}/>

                <div className={`${isSmallScreen ? classes.shadowBoxSmallContent : classes.shadowBoxContent}`}>
                    Chers utilisateurs,<br/><br/>

                    Le site est temporairement inaccessible en raison d’activités de maintenance planifiées<br/><br/>
                    Merci de votre compréhension.

                    <br/><br/>
                    {theme.mail}<br/>
                    {theme.phone}
                </div>

                <div className={classes.network}>
                    <UseAnimations animation={facebook} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.facebook, "_blank")}/>
                    <UseAnimations animation={twitter} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.twitter, "_blank")}/>
                    <UseAnimations animation={linkedin} size={25} wrapperStyle={{cursor: 'pointer', padding: 7}} onClick={() => window.open(theme.linkedin, "_blank")}/>
                </div>

            </ShadowBoxComponent>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    image: {
        width: 300,
        display: 'block',
        margin: 'auto'
    },
    shadowBox: {
        padding: 25,
        height: 560,
        width: 576,
        left: 'calc(50vw - 288px)',
        top: 'calc(50vh - 260px)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left',
        opacity: 0
    },
    shadowBoxSmall: {
        padding: 25,
        height: '100vh',
        width: '100vw',
        margin: 'auto',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left',
        opacity: 0
    },
    shadowBoxShow: {
        opacity: 1,
    },
    shadowBoxLoginHideLeft: {
        left: -380,
    },
    shadowBoxForgotPasswordHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxForgotPasswordHideLeft: {
        left: -380,
    },
    shadowBoxCodeHide: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxChangePasswordHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxFirstConnectionHideRight: {
        left: 'calc(100vw + 380px)',
    },
    shadowBoxContent: {
        marginTop: '25%',
        transform: 'translateY(calc(-50% + -11px))',
    },
    shadowBoxSmallContent: {
        marginTop: '37vh',
        transform: 'translateY(calc(-35vh + 25px))',
    },
    title: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 500,
        margin: '0 0 5px 0'
    },
    text: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '0 0 5px 0',
        display: 'block'
    },
    link1: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '0 0 27px 0',
        display: 'block',
        cursor: 'pointer'
    },
    link2: {
        color: '#5E6E82',
        textAlign: 'center',
        fontSize: 12,
        margin: '18px 0 0 0',
        display: 'block',
        cursor: 'pointer'
    },
    button: {
        width: '100%'
    },
    network: {
        display: 'flex',
        position: 'absolute',
        bottom: 0,
        justifyContent: 'center',
        width: '100%',
        left: 0
    },
    loaderSecurity: {
        position: 'absolute',
        bottom: 10,
        fontSize: 11,
        textAlign: 'center',
        width: '85%'
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    }
});

export default Maintenance;
