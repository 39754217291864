import React from 'react';
import { makeStyles } from "@material-ui/core";
import ShadowBoxComponent from "./../../../../Component/ShadowBoxComponent";
import TitleComponent from '../../../../Component/TitleComponent';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Zoom from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Slide from "@material-ui/core/Slide";
function Card(props = {}) {
    const classes = useStyles();
    const datas = props.repCard;
    const containerRef = React.useRef(null);
    const [renderTable, setRenderTable] = React.useState(false);
    const [heightText, setHeightText] = React.useState();

    React.useEffect(() => {
        if (datas.length === 0) {
            setHeightText(150)
        }
        else {
            setHeightText(containerRef.current.offsetHeight)
        }
    }, [datas]);
    return (
        <div>
            <Zoom in={true} {...{ timeout: 1500 }}>
                <ShadowBoxComponent>
                    <IconButton
                        className={classes.iconToggle}
                        onClick={() => { setRenderTable(!renderTable) }}
                    >
                        {renderTable ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        {props.title}
                    </IconButton>
                    <TableContainer style={{ visibily: renderTable ? 'visible' : 'hidden', height: !renderTable ? 0 : heightText, overflowY: !renderTable && 'hidden', overflowX: 'hidden', transition: renderTable ? 'all .4s' : 'all .4s ease .3s' }} >
                        <Slide direction="left" in={renderTable} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                            <Table ref={containerRef}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.thSize}>Formation</TableCell>
                                        <TableCell className={classes.th} style={{ fontWeight: 900, }}>Oui</TableCell>
                                        <TableCell className={classes.th} style={{ fontWeight: 900, }}>Non</TableCell>
                                        <TableCell className={classes.th} style={{ fontWeight: 900, }}>Nombre de participants</TableCell>
                                    </TableRow>
                                </TableHead>
                                {datas.length === 0 ?
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colspan={4} className={classes.text}>Aucune information pour ce filtre</TableCell>
                                        </TableRow>
                                    </TableBody> :
                                    <TableBody>
                                        {Object.keys(datas).map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.thSize}>{datas[data]['formation']}</TableCell>
                                                <TableCell className={classes.th}>{datas[data]['scores'][props.type]['true']} %</TableCell>
                                                <TableCell className={classes.th}>{datas[data]['scores'][props.type]['false']} %</TableCell>
                                                <TableCell className={classes.th}>{datas[data]['scores'][props.type]['numberProspect']}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                        </Slide>
                    </TableContainer>
                </ShadowBoxComponent>
            </Zoom>
        </div>
    );
}

const useStyles = makeStyles({
    thSize: { width: '20%', fontWeight: 900, fontSize: 12, padding: 12 },
    td: { width: '8%', fontSize: 12, padding: 12, textAlign: 'center' },
    th: { width: '8%', fontSize: 12, padding: 12, textAlign: 'center' },
    text: { width: '100%', textAlign: 'center', fontSize: 12, padding: 10, margin: 10 },

    iconToggle: {
        marginRight: 10, padding: 0, fontSize: '1.1rem', color: '#5E6E82', cursor: 'default',
        "&:hover": {
            backgroundColor: '#fff',
        },
    }
});

export default Card;