export const AuthenticationLogin = (data) => ({
    type: 'AuthenticationLogin',
    data: data
});

export const AuthenticationLogout = () => ({
    type: 'AuthenticationLogout'
});

export const UpdateAuthentication = (data) => ({
    type: 'UpdateAuthentication',
    data: data
});
