import React from 'react';
import { makeStyles } from "@material-ui/core";
import ShadowBoxComponent from "./../../../../Component/ShadowBoxComponent";
import TitleComponent from '../../../../Component/TitleComponent';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import Zoom from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Slide from "@material-ui/core/Slide";
function Card(props = {}) {
    const classes = useStyles();
    const datas = props.repCard;
    const [renderTable, setRenderTable] = React.useState(false);

    let tableHeight = () => {
        if (datas.length > 0) {
            return 350
        }
        else {
            return 180
        }
    };

    return (
        <div>
            <Zoom in={true} {...{ timeout: 1500 }}>
                <ShadowBoxComponent>
                    <IconButton
                        className={classes.iconToggle}
                        onClick={() => { setRenderTable(!renderTable) }}
                    >
                        {renderTable ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        Moyenne de satisfaction par Formation
                    </IconButton>
                    <TableContainer style={{ visibily: renderTable ? 'visible' : 'hidden', height: !renderTable ? 0 : tableHeight(), overflow: !renderTable && 'hidden', transition: renderTable ? 'all .4s' : 'all .4s ease .3s' }} >
                        <Slide direction="left" in={renderTable} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.thSize}>Nom de la formation</TableCell>
                                        <TableCell className={classes.th}>Nombre de questionnaire rempli</TableCell>
                                        <TableCell className={classes.th}>Durée estimée</TableCell>
                                        <TableCell className={classes.th}>Qualité des informations</TableCell>
                                        <TableCell className={classes.th}>Méthodes Pédagogiques</TableCell>
                                        <TableCell className={classes.th}>Animations et interactivité</TableCell>
                                        <TableCell className={classes.th}>Progression</TableCell>
                                        <TableCell className={classes.th}>Appréciation générale</TableCell>
                                    </TableRow>
                                </TableHead>
                                {datas.length > 0 ?
                                    <TableBody>
                                        {datas.map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.thSize}>{data.name}</TableCell>
                                                <TableCell className={classes.td}>{data.count}</TableCell>
                                                <TableCell className={classes.td}>{data.estimateDuration.toFixed(2)} /5</TableCell>
                                                <TableCell className={classes.td}>{data.qualityInformation.toFixed(2)} /5</TableCell>
                                                <TableCell className={classes.td}>{data.teachingMethod.toFixed(2)} /5</TableCell>
                                                <TableCell className={classes.td}>{data.interactivity.toFixed(2)} /5</TableCell>
                                                <TableCell className={classes.td}>{data.progression.toFixed(2)} /5</TableCell>
                                                <TableCell className={classes.td}>{data.satisfaction.toFixed(2)} %</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody> :
                                    <p className={classes.text}>Aucune information pour ce filtre</p>}
                            </Table>
                        </Slide>
                    </TableContainer>
                </ShadowBoxComponent>
            </Zoom>
        </div>
    );
}

const useStyles = makeStyles({
    thSize: { width: '35%', fontWeight: 900, fontSize: 12, padding: 12 },
    td: { width: '8%', fontSize: 12, padding: 12, textAlign: 'center' },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12, textAlign: 'center' },
    text: { width: '100%', fontWeight: 900, fontSize: 17, padding: 10, margin: 10 },
    
    iconToggle: { marginRight: 10, padding: 0, fontSize: '1.1rem', color: '#5E6E82', cursor: 'default',
    "&:hover": {
        backgroundColor: '#fff',
    }, }
});

export default Card;