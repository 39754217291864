const MediaReducer = (state = {}, action) => {
    switch (action.type) {
        case 'MediaShow':
            return action.data;
        case 'MediaHide':
            return {};
        default:
            return state
    }
};

export default MediaReducer;
