import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import IconButton from "@material-ui/core/IconButton";
import { Edit, Delete } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SweetAlert from 'sweetalert-react';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { TableReload } from "../../../Action/TableAction";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { useDispatch } from "react-redux";

function ProspectHotlineTypeList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setEdition] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [handlerProspectId, sethandlerProspectId] = React.useState();
    const [formProspect, setFormProspect] = React.useState({
        hotlineType: {
            name: 'hotlineType',
            label: 'Type',
            textHelper: 'Saisissez un type de hotline.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "name",
            label: "Nom du type",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const handlerProspect = formHandlerInit(formProspect, setFormProspect);
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Modifier'} placement="left">
                    <IconButton onClick={() => {
                        setDialog(true);
                        setEdition(true);
                        sethandlerProspectId(row.id);
                        handlerProspect.setValue('hotlineType', row.name);
                    }}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };
    const save = () => {
        if (handlerProspect.checkError()) {
            console.log('Error');
        } else {
            handlerProspect.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            if (isEdition) {
                Api.post({
                    route: 'administrator_prospect_hotline_type_edit',
                    params: { id: handlerProspectId },
                    data: handlerProspect.getData(),
                }, (response) => {
                    handlerProspect.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        setDialog(false)
                        dispatch(TableReload('administrator_prospect_hotline_type_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handlerProspect.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                let datas = handlerProspect.getData();
                Api.post({
                    route: 'administrator_prospect_hotline_type_add',
                    data: datas,
                }, (response) => {
                    handlerProspect.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setDialog(false)
                        dispatch(TableReload('administrator_prospect_hotline_type_list'))
                        dispatch(
                            SnackbarOpen({
                                text: 'Type enregistré.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handlerProspect.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    React.useEffect(handlerProspect.start, []);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Liste des Hotline type',
            context: 'Hotline Type Externe',
            description: ''
        });

        setReadyContent(true);
    }, []);

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TableComponent
                        id={'administrator_prospect_hotline_type_list'}
                        title={'Hotline type externe'}
                        columns={columns}
                        actionFirst={{
                            label: 'Ajouter un type de hotline',
                            onClick: () => {
                                setDialog(true)
                                setEdition(false)
                                handlerProspect.setValue('hotlineType', '');
                            }
                        }}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'administrator_prospect_hotline_type_list'
                            },
                                (response) => {
                                    let data = response.data;
                                    for (let index in data) {
                                        data[index].action = getAction(data[index]);
                                    }
                                    resolve(data);
                                });
                        }}
                    />
                </Grid>
            </Grid>

            <br />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15, color: '#5E6E82', }}>Ajouter un type</DialogTitle>
                <DialogContent style={{ minWidth: '30vw', overflowY: 'hidden' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'hotlineType'} handler={handlerProspect} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading} />
                    <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
                </DialogActions>
            </Dialog>

            <br />

            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={actionRow.name + '\nÊtes-vous sur de vouloir supprimer ce type de hotline ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'administrator_prospect_hotline_type_delete',
                        params: { id: actionRow.id }
                    }, (response) => {
                        if (response.status === 200) {
                            dispatch(TableReload('administrator_prospect_hotline_type_list'))
                            dispatch(
                                SnackbarOpen({
                                    text: 'Le type a bien été supprimer.',
                                    variant: 'success',
                                })
                            );
                        } else if (response.status === 404) {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Le type "' + actionRow.name + '" et déjà utiliser.',
                                    variant: 'error',
                                })
                            );
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }}
                onCancel={() => setShowConfirm(false)}
            />

        </ContentViewComponent>
    );
}
const useStyles = makeStyles({});

export default ProspectHotlineTypeList;
